import React, { useEffect, useState } from "react"
import Link from "next/link"
import * as cheerio from "cheerio"
import { useSelector } from "react-redux"

import CartDropDown from "@atoms/dropDown/cartDropdown"
import Button from "@atoms/button"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import RichText from "@atoms/richText"
import Icon from "@atoms/icon"
import { IQuatityButtonCart } from "./_quantityButtonCart.interface"
import { AnchorButton } from "@atoms"

const QuatityButtonCart = ({
  quantityOptions,
  quantityLabels,
  isProductInCart = false,
  handleAddToCart,
  handleLogin,
  isDetailPage = false,
  selectedQty,
  noLoginRequired,
  downloadDetails,
  isfieldQuantityAvailable,
}: IQuatityButtonCart) => {
  const [qty, setQty] = useState<IOption[]>([])
  const [error, setError] = useState<string>("")
  const [isCleared, setIsCleared] = useState<boolean>(false)
  const [anchorMsg, setAnchorMsg] = useState<string>("")
  const [successMsg, setSuccessMsg] = useState<string>("")
  const [cartLink, setCartLink] = useState<string>("/")

  // Handle quantity change
  const onChange = (val: any) => {
    setIsCleared(false)
    setQty(val)
    setError("")
  }

  // Handle button click
  const onClick = () => {
    // Check user login status
    if (user && user.isLoggedIn) {
      if (!qty?.length) {
        setError(quantityLabels.quantityErrorMessage)
        return
      }
      handleAddToCart(Number(qty?.[0]?.value))
      setIsCleared(true)
    } else {
      // Check if no login required for non loggedin users
      if (noLoginRequired) {
        if (!qty?.length) {
          setError(quantityLabels.quantityErrorMessage)
          return
        }
        handleAddToCart(Number(qty?.[0]?.value))
        setIsCleared(true)
      } else {
        if (qty?.length) {
          handleAddToCart(Number(qty?.[0]?.value), true)
        }
        if (handleLogin) handleLogin()
      }
    }
  }

  // Get user state
  const user: any = useSelector((state: UserState) => state.user)

  // Parse HTML for success message and link
  useEffect(() => {
    const parsedHtml = cheerio.load(quantityLabels.addedToCartText)
    const generalText = parsedHtml("p")?.text() ?? ""
    const anchorEle: any = parsedHtml("a")
    const anchorUrl = anchorEle?.[0]?.attribs?.href ?? "/"
    setSuccessMsg(generalText)
    setAnchorMsg(anchorEle?.text() ?? "")
    setCartLink(anchorUrl)
  }, [])

  // Set initial quantity if on detail page
  useEffect(() => {
    if (isDetailPage) {
      const obj = {
        label: selectedQty?.toString(),
        value: selectedQty?.toString(),
      }
      const value = selectedQty ? [obj] : []
      setQty(value)
    }
  }, [selectedQty])

  // Construct success message with link
  const getSuccessMsgWithLink = () => {
    const msg = anchorMsg !== "" ? successMsg?.split(anchorMsg) : []
    if (msg.length === 2) {
      return (
        <span className="success-msg">
          <span>{`${msg[0]} `}</span>
          <Link className="success-msg-link" href={cartLink} legacyBehavior>
            {anchorMsg}
          </Link>
          {msg[1] && <span>{`${msg[1]}`}</span>}
        </span>
      )
    }
    return <RichText className="success-msg" content={quantityLabels.addedToCartText} />
  }

  const getDownloadBtn = () => {
    if (
      downloadDetails?.downloadUrl &&
      ((downloadDetails?.enableDownloadAnonymous === 1 && downloadDetails?.downloadEnabled) ||
        (downloadDetails?.downloadEnabled && user && user?.isLoggedIn))
    ) {
      return (
        <AnchorButton
          className="download-cta"
          href={downloadDetails?.downloadUrl}
          isExternal={true}
          tabindex={0}
          isSecondary
          download={true}>
          {downloadDetails?.downloadLabel}
        </AnchorButton>
      )
    }
  }

  if ((isDetailPage && user && user.isLoggedIn) || !isDetailPage || noLoginRequired) {
    return (
      <div className="quantity-button-cart-container">
        {isfieldQuantityAvailable ? (
          <div className="added-to-cart-text out-of-stock">
            <Icon iconName="errorIcon" width={20} height={20} />
            <RichText className="success-msg" content={quantityLabels.outOfStock} />
          </div>
        ) : (
          <>
            {isProductInCart && !isDetailPage ? (
              <div className="added-to-cart-text">
                <Icon iconName="SuccessIcon" width={20} height={20} />
                {getSuccessMsgWithLink()}
              </div>
            ) : (
              <div className="quantity-button-cart">
                <CartDropDown
                  ariaLabel="product_quantity"
                  options={quantityOptions}
                  name="quantityLabel"
                  isCleared={isCleared}
                  isMulti={false}
                  simpleDropDownLabel
                  onValueChange={onChange}
                  placeHolder={quantityLabels.quantityLabel}
                  shouldToggleOnHover={false}
                  showValueWithPlaceholder={true}
                  intialValue={qty || []}
                />
                <Button tabindex={0} isSecondary onClick={onClick} className="add-to-cart-btn">
                  <p>{quantityLabels.addToCartLabel}</p>
                </Button>
              </div>
            )}
            {error && <RichText className="quantity-error" content={error} />}
          </>
        )}
        <div>{getDownloadBtn()}</div>
      </div>
    )
  }

  return (
    <div className="button-container">
      <Button className="order-now-cta" tabindex={0} onClick={handleLogin} isSecondary>
        {quantityLabels?.orderNowLabel}
      </Button>
    </div>
  )
}

export default QuatityButtonCart
