import { ILanguageOptionsProp } from "./_languageOptions.interface"

const LanguageOptions = (props: ILanguageOptionsProp) => {
  const getClasses = () => {
    const classes = []
    classes.push("language-options")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <ul className={getClasses()}>
      <li className="language-country">{props.country}</li>
      {props.langs}
    </ul>
  )
}

export default LanguageOptions
