import { IRichTextProps } from "./_richText.interface"
import { DOMSanitizer } from "../../../utils/dompurify"
import { trimText } from "@utils/stringparsing"

const RichText = (props: IRichTextProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("richtext")
    props.className && classes.push(props.className)
    return classes.join(" ")
  }
  const textToDisplay = props.characterLimit
    ? trimText(props.content, props.characterLimit)
    : props.content

  return (
    <div
      className={getClasses()}
      dangerouslySetInnerHTML={{ __html: DOMSanitizer(textToDisplay) }}
    />
  )
}

export default RichText
