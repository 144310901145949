import { RichText } from "@atoms"
import { IContentWithScroll } from "./_contentWithScroll.interface"

const ContentWithScroll = ({ content }: IContentWithScroll) => {
  return (
    <div className="scrollable-content">
      {content && <RichText className="scrollable-content-richtext" content={content} />}
    </div>
  )
}

export default ContentWithScroll
