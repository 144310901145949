import { AnchorButton } from "@atoms"
import Loader from "@atoms/loader"
import { useSelector } from "react-redux"
import {
 useContext, useEffect, useMemo, useState,
} from "react"
import ApplicationContext from "@utils/application-context/applicationContext"

const MyConsent = () => {
  const [configData, setConfigData] = useState<any>(undefined)
  const [consentURL, setConsentURL] = useState<string>("")

  const user: any = useSelector((state: UserState) => state.user)
  const applicationConfigContext = useContext(ApplicationContext)

  useMemo(() => {
    const userProfileConcentUrl = user.profile?.response?.profile?.records?.[0]?.PITCHER__Portal_URL__c ?? {}
    setConsentURL(userProfileConcentUrl)
  }, [user])

  useEffect(() => {
    function fetchConfigData() {
      setConfigData(applicationConfigContext.applicationConfiguration.siteConfig)
    }

    fetchConfigData()
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  return (
    <div>
      <Loader display={!configData} />
      {configData?.marketing_consent_title && (
        <h4>{configData?.marketing_consent_title}</h4>
      )}
      {configData?.marketing_consent_description && (
        <p>{configData?.marketing_consent_description}</p>
      )}
      {configData?.marketing_consent_cta_text && consentURL && (
        <AnchorButton tabindex={1} href={consentURL} isExternal={true}>
          {configData?.marketing_consent_cta_text}
        </AnchorButton>
      )}
    </div>
  )
}

export default MyConsent
