import React, { useCallback, useEffect, useState } from "react"
import { Seperator } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import SearchFilterTable from "@organisms/searchFilterTable"
import { Pagination, TitleDescription } from "@molecules"
import { useRouter } from "next/router"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import moment from "moment"
import { getApiDomainAndLang, middlewareGETAPI } from "@utils/baseApi"
import { searchFilterType } from "@organisms/searchFilterTable/_searchFilterTable.interface"
import Loader from "@atoms/loader"
import NUMBERS from "@helpers/constants/numbers"
import EnhancedFilterSearch from "@organisms/enhancedFilterSearch"
import { connect, useDispatch } from "react-redux"
import {
  addUpdateInterfaceSearch,
  updateAllInterfaceSearch,
  updateInterfacePagination,
  updateInterfaceSearchText,
  updateInterfaceSort,
} from "store/actions/InterfaceSearchActionCreators"
import { Dispatch } from "redux"
import {
  ColumnNameType,
  IInterfaceSearchProps,
  SelectWithLabel,
} from "./_interface-search.interface"
import {
  getInterfaceFilterLabelData,
  getFormattedOptions,
  getTableResponse,
  fetchTableData,
  sortDataAsc,
  fetchTableDataInterfaceTwo,
  getInterfaceTwoFilterLabelData
} from "@helpers/dataFunctions/getInterfaceSearchData"
import { ARITHMETIC_KEYS, COLUMN_KEYS, DATE_FORMATS } from "@helpers/constants/generic"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import _ from "lodash"
interface DispatchProps {
  addUpdateInterfaceSearch: (data: any) => void
  updateInterfacePagination: (data: number) => void
  updateInterfaceSearchText: (data: string) => void
  updateAllInterfaceSearch: (data: any, page: number, str: string, sort?: any) => void
  updateInterfaceSort: (data: any) => void
}

type StateTypes = {
  interfacetSearchFilterData: {
    interfaceFilters: any
    currentPage: number
    searchText: string
    sort: {
      column: string
      order: string
    }
  }
}

const sortKeyPair: ColumnNameType = {
  recordNumber: "serial",
  channel: "channel",
  country: "country",
  attributes: "attributes",
  sentToEmailAddress: "email",
  dateOfSubmission: "created",
}
type Props = DispatchProps & StateTypes & IInterfaceSearchProps
const InterfaceSearch = (props: Props) => {
  const { interfacetSearchFilterData } = props
  const { interfaceFilters, currentPage, searchText, sort } = interfacetSearchFilterData
  const [currentSearchText, setCurrentSearchText] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [sortValue, setSortValue] = useState<any>({ value: "", order: 0 })
  const [careersList, setCareersList] = useState<searchFilterType[]>([])
  const [sortedList, setSortedList] = useState<searchFilterType[]>([])
  const [filterDetails, setFilterDetails] = useState<any>({})
  const [resultCount, setResultCount] = useState<number>(0)
  const [localSelectedFilters, setLocalSelectedFilters] = useState<any>(interfaceFilters)
  const [itemsPerPage, setItemsPerPage] = useState<number>(0)
  const [startDate, setStartDate] = useState<string>("")
  const [endDate, setEndDate] = useState<string>("")
  const isMobile = useMediaQuery("(max-width: 991px)")
  const router = useRouter()
  const filterList = sortValue ? sortedList : careersList
  const { lang } = getApiDomainAndLang()

  const fetchData = async (url: string) => {
    const response = await middlewareGETAPI(url)
    return response.response
  }

  const { data: filterResponse, isLoading: isFilterDataLoading } = useSWRRequest(props?.blockUrl, async () => {
    try {
      return await fetchData(props?.blockUrl)
    } catch (error) {
      console.log(error)
    }
  })

  const dispatch: Dispatch<any> = useDispatch()

  const updateInterfaceSearchState: any = useCallback(
    (value: any) => {
      props.addUpdateInterfaceSearch(value)
    },
    [dispatch],
  )
  const updatePaginationState: any = useCallback(
    (value: number) => props.updateInterfacePagination(value),
    [dispatch],
  )

  const updateSearchTextState: any = useCallback(
    (searchText: string) => props.updateInterfaceSearchText(searchText),
    [dispatch],
  )
  const updateAllInterfaceSearchState: any = useCallback(
    (value: any, page: number, searchText: string, sort?: any) =>
      props.updateAllInterfaceSearch(value, page, searchText, sort),
    [dispatch],
  )

  const updateInterfaceSortState: any = useCallback(
    (value: any) => props.updateInterfaceSort(value),
    [dispatch],
  )

  const checkIfValueExistsInQuery = (queryStr: string, value: string) => {
    const selectedIds = queryStr ? queryStr.split(",") : []
    return selectedIds.includes(value)
  }

  const dateURL = () => {
    const startDate = interfaceFilters?.filter(
      (item: SelectWithLabel) => item?.filterName === "field_start_date",
    )?.[0]?.value
    const endDate = interfaceFilters?.filter(
      (item: SelectWithLabel) => item?.filterName === "field_end_date",
    )?.[0]?.value
    const dateWith100YRS = (date: Date, action: string) => {
      const newDate = new Date(date)
      action === ARITHMETIC_KEYS.ADDITION
        ? newDate.setFullYear(newDate.getFullYear() + 100)
        : newDate.setFullYear(newDate.getFullYear() - 100)
      return moment(newDate).locale(lang).format(DATE_FORMATS.YYYY_MM_DD)
    }

    if (startDate) {
      if (endDate) {
        return `&views-filter[created][min]=${moment(startDate).format(
          DATE_FORMATS.YYYY_MM_DD,
        )}&views-filter[created][max]=${moment(endDate).format(DATE_FORMATS.YYYY_MM_DD)}`
      } else {
        return `&views-filter[created][min]=${moment(startDate).format(
          DATE_FORMATS.YYYY_MM_DD,
        )}&views-filter[created][max]=${dateWith100YRS(startDate, ARITHMETIC_KEYS.ADDITION)}`
      }
    } else if (endDate) {
      return `&views-filter[created][min]=${dateWith100YRS(
        endDate,
        ARITHMETIC_KEYS.SUBSTRACTION,
      )}&views-filter[created][max]=${moment(endDate).format(DATE_FORMATS.YYYY_MM_DD)}`
    } else {
      return ""
    }
  }

  const fieldURL = (fieldName: string, filterName: string) => {
    const selectedField = interfaceFilters
      ?.filter((item: SelectWithLabel) => item?.filterName === filterName)?.[0]
      ?.selectOptions?.options?.filter((item: IOption) => !!item?.isSelected)

    const getFieldValue = () => {
      if (selectedField?.length) {
        return selectedField?.map((item: IOption, index: number) => {
          const selectedValue = item?.id as string
          return filterName !== "field_sent_email"
            ? `&views-filter[${fieldName}][${index}]=${selectedValue? encodeURIComponent(selectedValue) : selectedValue}`
            : `&views-filter[${fieldName}]=${selectedValue}`
        })
      } else return []
    }
    const result = getFieldValue() as any[]
    return result?.length ? result?.join("") : ""
  }

  useEffect(() => {
    if (!filterResponse) {
      return
    }
    const fetchFilterDetails = async () => {
      const channelOptions = getFormattedOptions(filterResponse?.filterValues?.channel)
      const countryOptions = getFormattedOptions(filterResponse?.filterValues?.country)
      const attributesOptions = getFormattedOptions(filterResponse?.filterValues?.attributes)
      const sentEmailOptions = getFormattedOptions(filterResponse.filterValues?.sent_to_email_add)
      const configData = await getInterfaceFilterLabelData(filterResponse)

      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        unselectFiltersCTA: configData.clearLabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
      }

      const filtersOptions = [
        {
          label: configData.channelLabel,
          filterName: "field_channel",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(channelOptions),
            ariaLabel: "Select Element",
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
        {
          label: configData.countryLabel,
          filterName: "field_country",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(countryOptions),
            ariaLabel: "Select Element",
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
        {
          label: configData.attributesLabel,
          filterName: "field_attributes",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(attributesOptions),
            ariaLabel: "Select Element",
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
        {
          label: configData.sentEmailLabel,
          filterName: "field_sent_email",
          selectOptions: {
            isCleared: true,
            isMulti: false,
            options: sortDataAsc(sentEmailOptions),
            ariaLabel: configData.sentEmailLabel,
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
      ]
      const startDateValues = {
        label: configData?.startDateLabel,
        placeHolderTxt: configData?.dropdownPlaceHolder,
        filterName: "field_start_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      const endDateValues = {
        label: configData.endDateLabel,
        placeHolderTxt: configData?.dropdownPlaceHolder,
        filterName: "field_end_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      configData?.startDateLabel && filtersOptions.push(startDateValues)
      configData?.endDateLabel && filtersOptions.push(endDateValues)

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          recordNumber: "0",
          channel: "1",
          country: "2",
          attributes: "3",
          sentToEmailAddress: "4",
          dateOfSubmission: "5",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData?.tableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.tableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
      }

      const filterDetailsObj = {
        careerSearchContent: filtersOptions,
        filtersLabels: filterLabelsData,
        displayConfig,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      const updatedSelectOptions = filtersOptions?.map((item) => ({
        ...item,
        selectOptions: {
          ...item.selectOptions,
          options: item.selectOptions.options?.map((val: any) => {
            // @ts-ignore
            return {
              ...val,
              isSelected:
                selectedCategories?.[item?.filterName] &&
                !!checkIfValueExistsInQuery(
                  // @ts-ignore
                  selectedCategories?.[item?.filterName],
                  val?.value?.toString(),
                ),
            }
          }),
        },
      }))
      const sortFilter = {
        column: selectedCategories?.sort_field || "created",
        order: selectedCategories?.sort_order || "DESC",
      }
      updateAllInterfaceSearchState(
        updatedSelectOptions,
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        sortFilter,
      )
      setFilterDetails(filterDetailsObj)
    }
    const fetchFilterDetailsInterfaceTwo = async () => {
      const channelOptions = getFormattedOptions(filterResponse?.filterValues?.channel)
      const countryOptions = getFormattedOptions(filterResponse?.filterValues?.country)
      const sentEmailOptions = getFormattedOptions(filterResponse.filterValues?.sent_to_email_add)
      const configData = await getInterfaceTwoFilterLabelData(filterResponse)

      const filterLabelsData = {
        searchLabel: configData.searchLabel,
        searchPlaceholder: configData.searchPlaceholder,
        SearchCTAText: configData.searchCTALabel,
        unselectFiltersCTA: configData.clearLabel,
        resultsFoundLabel: configData.resultsLabel,
        noResultsFoundLabel: configData.noResultsLabel,
      }

      const filtersOptions = [
        {
          label: configData.channelLabel,
          filterName: "field_channel",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(channelOptions),
            ariaLabel: "Select Element",
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
        {
          label: configData.countryLabel,
          filterName: "field_country",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: sortDataAsc(countryOptions),
            ariaLabel: "Select Element",
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
        {
          label: configData.sentEmailLabel,
          filterName: "field_sent_email",
          selectOptions: {
            isCleared: true,
            isMulti: false,
            options: sortDataAsc(sentEmailOptions),
            ariaLabel: configData.sentEmailLabel,
            name: "select",
            placeHolder: configData?.dropdownPlaceHolder || "",
          },
        },
      ]
      const startDateValues = {
        label: configData?.startDateLabel,
        placeHolderTxt: configData?.dropdownPlaceHolder,
        filterName: "field_start_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      const endDateValues = {
        label: configData.endDateLabel,
        placeHolderTxt: configData?.dropdownPlaceHolder,
        filterName: "field_end_date",
        value: "",
        selectOptions: {
          isCleared: true,
          isMulti: false,
          options: [],
          ariaLabel: "",
          name: "",
          placeHolder: "",
        },
      }
      configData?.startDateLabel && filtersOptions.push(startDateValues)
      configData?.endDateLabel && filtersOptions.push(endDateValues)

      const createTableColumns = (oldObj: any) => {
        const objKeyMap = {
          recordNumber: "0",
          channel: "1",
          country: "2",
          sentToEmailAddress: "3",
          dateOfSubmission: "4",
        }
        const newObj = {}
        Object.entries(objKeyMap).forEach(([key, value]) => {
          // @ts-ignore
          newObj[key] = oldObj[value]
        })
        return newObj
      }
      const allColumns = createTableColumns(configData?.tableColumns)
      const displayConfig = {
        tableColumns: Object.values(configData.tableColumns),
        resultsLabel: configData.resultsLabel,
        noResultsLabel: configData.noResultsLabel,
      }

      const filterDetailsObj = {
        careerSearchContent: filtersOptions,
        filtersLabels: filterLabelsData,
        displayConfig,
        tableColumns: allColumns,
      }
      const selectedCategories = router?.query
      const updatedSelectOptions = filtersOptions?.map((item) => ({
        ...item,
        selectOptions: {
          ...item.selectOptions,
          options: item.selectOptions.options?.map((val: any) => {
            // @ts-ignore
            return {
              ...val,
              isSelected:
                selectedCategories?.[item?.filterName] &&
                !!checkIfValueExistsInQuery(
                  // @ts-ignore
                  selectedCategories?.[item?.filterName],
                  val?.value?.toString(),
                ),
            }
          }),
        },
      }))
      const sortFilter = {
        column: selectedCategories?.sort_field || "created",
        order: selectedCategories?.sort_order || "DESC",
      }
      updateAllInterfaceSearchState(
        updatedSelectOptions,
        Number(selectedCategories?.page ?? 0),
        selectedCategories?.search || "",
        sortFilter,
      )
      setFilterDetails(filterDetailsObj)
    }
    props?.type === "sendout_interface_one"?fetchFilterDetails():fetchFilterDetailsInterfaceTwo()
  }, [filterResponse])

  useEffect(() => {
    const getDefaultTableData = async () => {
      let tableURL: string
      const sortString = `?views-sort[sort_by]=${sort?.column}&views-sort[sort_order]=${sort?.order}`
      const searchUrlStr = searchText
        ? `&views-filter[combine]=${searchText}`
        : ""
      const allFiltersEmpty = props?.type === "sendout_interface_one"?`${dateURL()}${fieldURL(
        "country",
        "field_country",
      )}${fieldURL("channel", "field_channel")}${fieldURL(
        "attributes",
        "field_attributes",
      )}${fieldURL("email", "field_sent_email")}${searchUrlStr}`:`${dateURL()}${fieldURL(
        "country",
        "field_country",
      )}${fieldURL("channel", "field_channel")}${fieldURL("email", "field_sent_email")}${searchUrlStr}`
    
      try {
        if (!careersList?.length && !sortedList?.length && !allFiltersEmpty) {
          tableURL =
            props?.type === "sendout_interface_one"
              ? `jsonapi/views/pim_sendout_interface_one_submissions/submission?views-sort[sort_by]=created&views-sort[sort_order]=${sort?.order}&page=${currentPage}`
              : `jsonapi/views/pim_sendout_interface_two_submissions/submission?views-sort[sort_by]=created&views-sort[sort_order]=${sort?.order}&page=${currentPage}`
        } else {
          const urlValue =
            props?.type === "sendout_interface_one"
              ? "jsonapi/views/pim_sendout_interface_one_submissions/submission"
              : "jsonapi/views/pim_sendout_interface_two_submissions/submission"
          tableURL =
            props?.type === "sendout_interface_one"
              ? `${urlValue}${sortString}${dateURL()}${fieldURL(
                  "country",
                  "field_country",
                )}${fieldURL("channel", "field_channel")}${fieldURL(
                  "attributes",
                  "field_attributes",
                )}${fieldURL("email", "field_sent_email")}${searchUrlStr}&page=${currentPage}`
              : `${urlValue}${sortString}${dateURL()}${fieldURL(
                  "country",
                  "field_country",
                )}${fieldURL("channel", "field_channel")}${fieldURL(
                  "email",
                  "field_sent_email",
                )}${searchUrlStr}&page=${currentPage}`
        }
        const {
          data: tableResponse,
          meta,
          number_of_items_per_page,
        } = await getTableResponse(tableURL)
        if (tableResponse?.length) {
          setIsLoading(false)
          const formatedTableData =
            props?.type === "sendout_interface_one"
              ? fetchTableData(tableResponse, filterResponse?.filterLabels)
              : fetchTableDataInterfaceTwo(tableResponse, filterResponse?.filterLabels)
          setCareersList(formatedTableData)
          setSortedList(formatedTableData)
          setItemsPerPage(Number(number_of_items_per_page) ?? 0)
          setResultCount(meta?.count || 0)
        } else {
          setIsLoading(false)
          setCareersList([])
          setSortedList([])
          setItemsPerPage(0)
          setResultCount(0)
        }
      } catch (err) {
        console.error(err)
      }
    }
    if (!isFilterDataLoading) {
      setIsLoading(true)
      getDefaultTableData()
    }
  }, [isFilterDataLoading, interfaceFilters, searchText, sort, currentPage])


  const handleSearch = (text: string) => {
    updateSearchTextState(text)
  }

  const handleClick = (label: string, value: IOption) => {
    const alteredFilter = interfaceFilters?.map(
      (item: { filterName: string; selectOptions: { options: any[] }; placeHolderTxt: string }) => {
        if (item.filterName === label) {
          if (!item?.placeHolderTxt) {
            return {
              ...item,
              selectOptions: {
                ...item.selectOptions,
                options: item.selectOptions.options?.map((val) => {
                  if (val.id === value?.id) {
                    return {
                      ...val,
                      isSelected: false,
                    }
                  }
                  return val
                }),
              },
            }
          } else {
            item?.filterName === "field_start_date" && setStartDate("")
            item?.filterName === "field_end_date" && setEndDate("")
            return {
              ...item,
              value: "",
            }
          }
        }
        return item
      },
    )
    updateInterfaceSearchState(alteredFilter)
  }
  const handleSortWhole = (key: string) => {
    if (sort?.column === "") {
      updateInterfaceSortState({ column: COLUMN_KEYS[key as keyof typeof COLUMN_KEYS], order: "ASC" })
    } else if (sort?.column && sort?.column === COLUMN_KEYS[key as keyof typeof COLUMN_KEYS]) {
      if (sort?.order === "DESC") {
        updateInterfaceSortState({ column: COLUMN_KEYS[key as keyof typeof COLUMN_KEYS], order: "ASC" })
      } else if (sort?.order === "ASC") {
        updateInterfaceSortState({ column: COLUMN_KEYS[key as keyof typeof COLUMN_KEYS], order: "DESC" })
      }
    } else {
      updateInterfaceSortState({ column: COLUMN_KEYS[key as keyof typeof COLUMN_KEYS], order: "ASC" })
    }
  }

  const handleSortKey = (key: string) => {
    if (sort?.column === "" || sort?.column !== key) {
      updateInterfaceSortState({ column: key, order: sort?.order })
    } else {
      updateInterfaceSortState({ column: "created", order: sort?.order })
    }
  }

  const handleSortOrder = () => {
    updateInterfaceSortState({ column: sort?.column, order: sort.order === "ASC" ? "DESC" : "ASC" })
  }
  
  const checkIfValueExists = (val: any, valArr: any) =>
    valArr.findIndex((item: any) => item.id === val.id) > -1
  const handleAdditionalFilters = (label: string, value: any) => {
    if (isMobile) {
      const alteredFilter = localSelectedFilters?.map(
        (item: { filterName: string; selectOptions: { options: any[] }; placeHolderTxt: string }) => {
          if (item.filterName === label) {
            if (!item?.placeHolderTxt) {
              return {
                ...item,
                selectOptions: {
                  ...item.selectOptions,
                  options: item.selectOptions.options?.map((val) => ({
                    ...val,
                    isSelected: checkIfValueExists(val, value),
                  })),
                },
              }
            }
            else {
              item?.filterName === "field_start_date" && setStartDate(value ?? "")
              item?.filterName === "field_end_date" && setEndDate(value ?? "")
              return {
                ...item,
                value: value ? moment(Date.parse(value))
                  .locale(lang)
                  .format(DATE_FORMATS.YYYY_MM_DDD) : "",
              }
            }
          }
          return item
        },
      )
      setLocalSelectedFilters(alteredFilter)
      updateInterfaceSearchState(alteredFilter)
    } else {
      const alteredFilter = interfaceFilters?.map(
        (item: {
          filterName: string
          selectOptions: { options: any[] }
          placeHolderTxt: string
        }) => {
          if (item.filterName === label) {
            if (!item?.placeHolderTxt) {
              return {
                ...item,
                selectOptions: {
                  ...item.selectOptions,
                  options: item.selectOptions.options?.map((val) => ({
                    ...val,
                    isSelected: checkIfValueExists(val, value),
                  })),
                },
              }
            } else {
              item?.filterName === "field_start_date" && setStartDate(value ?? "")
              item?.filterName === "field_end_date" && setEndDate(value ?? "")
              return {
                ...item,
                value: value ? moment(Date.parse(value))
                  .locale(lang)
                  .format(DATE_FORMATS.YYYY_MM_DDD) : "",
              }
            }
          }
          return item
        },
      )
      updateInterfaceSearchState(alteredFilter)
    }
  }

  
  const fetchAllSelectedFilters = () => {
    const selectedValues = {} as any
    interfaceFilters?.forEach(
      (item: {
        filterName: string
        selectOptions: { options: any[] }
        placeHolderTxt: string
        value: string
      }) => {
        const formattedDate = moment(Date.parse(item?.value))
          .locale(lang)
          .format(DATE_FORMATS.YYYY_MM_DDD)
        selectedValues[`${item.filterName}`] = !item?.placeHolderTxt
          ? item.selectOptions.options
              ?.filter((val) => val.isSelected)
              .map(({ isSelected, ...rest }: any) => rest)
          : item?.value
          ? [{ label: formattedDate, value: formattedDate }]
          : []
      },
    )
    return selectedValues
  }

  const applyAdditionalFilters = () => {
    updateInterfaceSearchState(localSelectedFilters)
  }

  const handlePagination = (offSet: number) => {
    updatePaginationState(offSet/itemsPerPage)
  }

  const clearAllFilters = () => {
    const clearedFilters = interfaceFilters?.map((item: any) => {
      if (!item?.placeHolderTxt) {
        return {
          ...item,
          selectOptions: {
            ...item.selectOptions,
            options: item.selectOptions.options?.map((val: any) => {
              return {
                ...val,
                isSelected: false,
              }
            }),
          },
        }
      } else {
        item.filterName === "field_start_date" && setStartDate("")
        item.filterName === "field_end_date" && setEndDate("")
        return {
          ...item,
          value: "",
        }
      }
    })
    updateAllInterfaceSearchState(clearedFilters, 0, searchText || "")
    setCareersList([])
    setSortedList([])
    setIsLoading(true)
    updateSearchTextState("")
    setCurrentSearchText("")
  }

  const fetLocalSelectedValue = () => {
    const selectedValues = {} as any
    localSelectedFilters?.forEach(
      (item: { filterName: string; selectOptions: { options: any[] }; placeHolderTxt: string; value: string }) => {
        selectedValues[`${item.filterName}`] = !item?.placeHolderTxt ? item.selectOptions.options
          ?.filter((val) => val.isSelected)
          .map(({ isSelected, ...rest }: any) => rest) : item?.value
          ? [{ label: moment(Date.parse(item?.value))
            .locale(lang)
            .format(DATE_FORMATS.YYYY_MM_DDD), value: moment(Date.parse(item?.value))
            .locale(lang)
            .format(DATE_FORMATS.YYYY_MM_DDD) }]
          : []
      },
    )
    
    return selectedValues
  }
  const formatMatchString = (arrayLength: number) =>
    arrayLength > NUMBERS.ZERO
      ? filterDetails?.displayConfig?.resultsLabel.replace("@count_val", arrayLength.toString())
      : filterDetails?.displayConfig?.noResultsLabel

  const resetLocalFilterWithReducer = () => {
    setLocalSelectedFilters(interfaceFilters)
  }
  return (
    <>
      {/* @ts-ignore */}
      {Object.keys(filterDetails).length ? (
        <div className="interfaceSearch scroll-top">
          <EnhancedFilterSearch
            filtersOptions={filterDetails?.careerSearchContent}
            filterLabels={filterDetails.filtersLabels}
            handleSearch={handleSearch}
            isCareerSearch={true}
            handleUnselectAll={clearAllFilters}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={isMobile ? fetLocalSelectedValue() : fetchAllSelectedFilters()}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
            handleRemoveItem={handleClick}
            handleSearchText={setCurrentSearchText}
            noOfResults={resultCount}
            handleSortByKey={handleSortKey}
            handleSortByOrder={handleSortOrder}
            sortList={filterDetails.tableColumns}
            selectedSortValue={sort}
            resetLocalFilterWithReducer={resetLocalFilterWithReducer}
            isDisabled={_.isEqual(interfaceFilters, localSelectedFilters)}
            enableDatePicker={true}
            startDate={startDate}
            endDate={endDate}
          />
          {!(isMobile && !careersList.length) && (
            <Seperator className="interfaceSearch-separator" />
          )}
          {isLoading && (
            <div className="load-container">
              <Loader display={true} />
            </div>
          )}
          {!isLoading &&
            (careersList.length ? (
              <div>
                <SearchFilterTable
                  filterData={filterList}
                  columnsList={filterDetails?.displayConfig.tableColumns}
                  tableColumns={filterDetails.tableColumns}
                  handleSort={handleSortWhole}
                  sortValue={sort}
                  isNotCareerSearch={true}
                />
                <div className="search-filter-pagination">
                  {resultCount > itemsPerPage && (
                    <div className="search-filter-pagination">
                      <Pagination
                        itemsPerPage={itemsPerPage}
                        itemsCount={resultCount}
                        onClick={handlePagination}
                        offSet={currentPage}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <TitleDescription
                title={formatMatchString(sortedList.length) ?? ""}
                description={filterDetails.displayConfig?.noResultsDesc?.value ?? ""}
                searchKey=""
                className="interfaceSearch-no-result"
              />
            ))}
        </div>
      ) : (
        <div className="load-container">
          <Loader display={true} />
        </div>
      )}
    </>
  )
}

const mapDispatch = {
  addUpdateInterfaceSearch: (data: any) => addUpdateInterfaceSearch(data),
  updateInterfacePagination: (data: number) => updateInterfacePagination(data),
  updateInterfaceSearchText: (data: string) => updateInterfaceSearchText(data),
  updateAllInterfaceSearch: (data: any, page: number, str: string, sort?: any) =>
    updateAllInterfaceSearch(data, page, str, sort),
  updateInterfaceSort: (data: any) => updateInterfaceSort(data),
}

const mapState = (state: any) => ({
  interfacetSearchFilterData: state.interfaceReducer,
})

export default connect(mapState, mapDispatch)(InterfaceSearch)
