import { Column, Img } from "@atoms"
import getDirection from "@utils/direction"
import ServiceTeaserCardContent from "../../molecules/serviceTeaserCardContent"
import { IServiceTeaserCardProps } from "./_serviceTeaserCard.interface"

const ServiceTeaserCard = (props: IServiceTeaserCardProps) => {
  const { serviceTeaserCardImage } = props
  const { serviceTeaserCardContent } = props
  return (
    <Column desktop={4} tablet={6} mobile={12} className="service-teaser-card">
      <div className="card" dir={getDirection()}>
        {serviceTeaserCardImage && (
          <Img
            className="service-teaser-card-image"
            type="card"
            styledimage={serviceTeaserCardImage?.styledimage}
            src={serviceTeaserCardImage.src}
            alt={serviceTeaserCardImage.alt}
            width={372}
            height={215}
            islazyloaded={false}
          />
        )}
        <div className="card-body">
          <ServiceTeaserCardContent {...serviceTeaserCardContent} />
        </div>
      </div>
    </Column>
  )
}
export default ServiceTeaserCard
