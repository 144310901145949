import { AnchorButton, RichText } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IOpinionLeadersCardContentProps } from "./_opinionLeadersCardContent.interface"

const OpinionLeadersCardContent = (props: IOpinionLeadersCardContentProps) => (
  <div className="opinionLeadersCardContent">
    {props.heading && <h4 className="opinionLeadersCardContent-header bold">{trimText(props.heading, CHARLIMIT.TITLE)}</h4>}
    {props.content && (
      <RichText
        className="opinionLeadersCardContent-content"
        content={props.content}
        characterLimit={CHARLIMIT.DESCRIPTION}
      />
    )}
    <div className="opinionLeadersCardContent-button">
      {(props.isLinkExternal || props.link) && props.buttonLabel ? (
        <AnchorButton
          tabindex={1}
          isSecondary
          href={props.link}
          isExternal={props.isLinkExternal}
          className="opinionLeadersCardContent-button"
        >
          {props.buttonLabel}
        </AnchorButton>
      ) : (
        <></>
      )}
    </div>
  </div>
)
export default OpinionLeadersCardContent
