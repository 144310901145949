import { BrandingPattern, Container } from "@atoms"
import { ISitemapPage } from "./_sitemapPage.interface"
import Breadcrumbs from "../../organisms/breadcrumbs"
import SitemapContent from "../../organisms/sitemapContent"

const SitemapPage = (props: ISitemapPage) => {
  const { breadcrumbs } = props

  return (
    <>
      <BrandingPattern />
      <Container>
        <Container>
          <Breadcrumbs {...breadcrumbs} />
        </Container>
        <SitemapContent {...props} />
      </Container>
    </>
  )
}

export default SitemapPage
