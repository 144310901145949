const CHARLIMIT = {
  TAXONOMY: 35,
  RATINGS: 4,
  TITLE: 70,
  TITLEMAXLINE: 2,
  DESCRIPTION: 200,
  DESCMAXLINE: 4,
  DATELOCTIME: 20,
  LOCATION: 25,
  TIME: 25,
  PRODSTRENGTH: 30,
  PRODSTRENGTHMAXLINE: 2,
  PACKSIZE: 30,
  PACKSIZEMAXLINE: 2,
  NDCNUMBER: 25,
  NDCNUMBERMAXLINE: 1,
  CTA: 50,
}

export default CHARLIMIT
