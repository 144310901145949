import React from "react"
import QuickLinkCard from "@molecules/quickLinkCard"
import { ICardInfo } from "@molecules/quickLinkCard/_quickLinkCard.interface"
import { IQuickLinksProps } from "./_quickLinks.interface"

const QuickLinks = ({ cardInfo, quickLinkTitle , cardGroupAlignment }: IQuickLinksProps) => (
  <div className="quick-links">
    {quickLinkTitle && <h4 className={`quick-links-title quick-links-${cardGroupAlignment}`}>{quickLinkTitle}</h4>}
    <div className="panel">
      {!!cardInfo?.length && (
        <ul className={`list-item card-group-alignment-${cardGroupAlignment}`}>
          {cardInfo?.map((childItem: ICardInfo, index: number) => (
            <li key={index}>
              <QuickLinkCard cardInfo={childItem} cardAlignment={childItem?.cardAlignment || ""}/> 
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
)

export default QuickLinks
