import { Context, createContext } from "react"
import { IFloatingbanner } from "@molecules/floatingBanner/_floatingBanner.interface"
import { IEyebrowMenuLink } from "@organisms/eyebrowMenu/_eyebrowMenu.interface"

const ConfigurationsContext = createContext({
  floatingBanner: null,
  renderEyebrowMenu: null,
  displayFloatingBanner: true,
  setDisplayFloatingBanner: undefined,
}) as unknown as Context<{
  floatingBanner: IFloatingbanner
  eyebrowMenu: {
    logo: string
    links: IEyebrowMenuLink[]
  }
  displayFloatingBanner: boolean
  setDisplayFloatingBanner: (value: boolean) => void
}>

export default ConfigurationsContext
