let env

export const config = {
  env: `${env}`,
  gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  recapchaKey: process.env.NEXT_PUBLIC_GOOGLE_RECAPCHA,
  onPrem: {
    GET_PROFILE_URL: "/solarapi/get-profile",
    LOGIN_API_URL: "/solarapi/user/login",
    LOGOUT_API_URL: "/solarapi/user/logout",
    LOGOUT_API_URL_SANDOZ_ID: "/solarapi/v2/user-logout",
    GET_SESSION_CSRF_API: "/solarapi/azureb2c/login",
    SELF_REGISTRATION_URL: "/solarapi/user/register",
    INVITED_REGISTRATION_STEP_1_URL: "/solarapi/user/invited-member-validation",
    INVITED_REGISTRATION_STEP_2_URL: "/solarapi/user/invited-member-update",
    UPDATE_PROFILE_API: "/solarapi/user/profile-update",
    PROFESSION_DROPDOWN_URL: "/solarapi/user/picklist",
    LANGUAGE_SWITCHER_URL: "/solarapi/user/language-switcher",
    CHECK_DCR_URL: "/solarapi/user/get-dcr",

    INVITED_REGISTRATION_AZURE_CALLBACK_URL:
      "https://<tenant>.b2clogin.com/<tenant>.onmicrosoft.com/oauth2/v2.0/authorize?p=<custom-policy>&client_id=<client-ID>&nonce=defaultNonce&redirect_uri=<FED-Domain>/invited-registration&scope=openid&response_type=id_token&Version=2&login_hint=<api-email>",

    RATINGS_API_URL: "/solarapi/flagging",
    RATINGS_COUNT_API_URL: "/solarapi/get-count",
    STAR_RATING_COUNT_UPDATE: "/solarapi/ratingvalue",
    DEEP_LINK_API_URL: "/solarapi/deeplink",
    WEBFORM_SUBMIT: "/webform_rest/submit",
    SITEMAP_URL: "/solarapi/sitemap",
    NEWSLETTER_VERIFICATION: "newsletter/token",
    VALIDATE_DOCCHECK_TOKEN: "/solarapi/doccheck/access_token",
  },
}

export const localeArray = [
  "en",
  "bg",
  "cs",
  "da",
  "de",
  "ar",
  "es",
  "fi",
  "fr",
  "ko",
  "ro",
  "ru",
  "th",
  "tr",
  "hr",
  "hu",
  "it",
  "ja",
  "nl",
  "nn",
  "nb",
  "no",
  "sk",
  "el",
  "pl",
  "sr",
  "sv",
  "uk",
  "sl",
  "us-en",
  "th-th",
  "cz-cs",
  "dk-da",
  "eg-en",
  "ro-ro",
  "ru-ru",
  "za-en",
  "es-es",
  "tr-tr",
  "bg-bg",
  "fr-fr",
  "ae-en",
  "dhub",
  "pro",
  "eu-en",
  "hu-hu",
  "si-sl",
  "ch-fr",
  "ch-de",
  "ch-it",
  "it-it",
  "fi-fi",
  "se-se",
  "uk-en",
  "ca-en",
  "ca-fr",
  "nl-nl",
  "at-de",
  "pl-pl",
  "bz-pt",
  "br-pt",
  "gr-el",
  "hr-hr",
  "sk-sk",
  "be-nl",
  "be-fr",
  "hcp",
  "jp-ja",
  "de-de",
  "sa-ar",
  "al-sq",
  "rs-sr",
  "ua-ua",
  "ee-et",
  "is-is",
  "ie-en",
  "lv-lv",
  "lt-lt",
  "mt-en",
  "no-no",
  "pt-pt",
  "vn-vi",
  "be-de",
  "de-en",
  "mt-mt",
  "ba-bs",
  "ba-sr",
  "ba-hr",
  "mk-mk",
  "by-be",
  "kz-kk",
  "uz-uz",
  "cl-es",
  "ec-es",
  "mx-es",
  "by-ru",
  "my-en",
  "sg-en",
  "ph-en",
]
