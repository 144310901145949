import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getTextBlockData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const preFetchedData = args?.[2] // data already fetched

  const textResponse = preFetchedData && Object.keys(preFetchedData)?.length > 0 ? preFetchedData : await getBlockData(url, false, serverData)
  const textBlockData = preFetchedData && Object.keys(preFetchedData)?.length > 0 ? preFetchedData?.data?.block_content : textResponse?.data

  const isParentPaywalled = isPaywallProtected(
    getNestedObject(textBlockData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  const fieldPaywallRoles = getAllRoles(textBlockData)

  const container = isPaywallProtected(textBlockData?.attributes?.field_block_container_style)
    .isPaywallProtectedFlag
    ? null
    : textBlockData?.attributes?.field_block_container_style ?? null

  const alignment = isPaywallProtected(textBlockData?.attributes?.field_text_alignment_style)
    .isPaywallProtectedFlag
    ? null
    : textBlockData?.attributes?.field_text_alignment_style ?? null

  const finePrint = isPaywallProtected(textBlockData?.attributes?.field_fine_print_enable_disable)
    .isPaywallProtectedFlag
    ? null
    : textBlockData?.attributes?.field_fine_print_enable_disable ?? null

  return {
    paragraph: textBlockData?.attributes?.body?.processed ?? null,
    container,
    alignment,
    id: textBlockData?.attributes?.field_text_id ?? null,
    finePrint,
    fieldPaywallRoles,
    isParentPaywalled
  }
}

export default getTextBlockData
