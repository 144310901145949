const SEARCH_FACETS={
ALPHABETHICAL_ORDER:"alphabethical_order",
PROFESSION: "profession",
DISEASE_AREA:"disease_area",
TREATMENT_AREA:"treatment_area",
ACTIVE_INGREDIENT:"active_ingredient",
MEDICATION_TYPE:"medication_type",
EDUCATION_VOCABULARY:"education_vocabulary",
NEWS_CATEGORY:"news_category",
AUDIENCE:"audience",
SPECIALITY_VOCABULARY:"speciality_vocabulary",
ARCHIVE_YEAR:"archive_year",
NEWS_STORIES:"news-stories",
EDUCATION:"education",
EDUCATION_EVENT_TYPE:"education_event_type",
THERAPY_VOCABULARY:"therapy_vocabulary",
EDUCATION_BRAND_GERMANY_ONLY:"education_brand_germany_only",
CREATED:"created",
SELECT_ELEMENT:"Select Element",
SELECT:"select",
EVENTS:"events",
EVENTS_VOCABULARY:"events_vocabulary",
THERAPY:"therapy",
FIELD_DIESASE_AREA_TARGET_ID:"field_disease_area_target_id",
FIELD_TREAMENT_AREA_TARGET_ID:"field_tadg_treatment_area_target_id",
WSI:"wsi"
}

export default SEARCH_FACETS