import isServer from "@utils/fs/isServer"
import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getProductBlockData } from "."
import { getApiDomainAndLang } from "../../utils/baseApi"
import { consoleError } from "../../utils/error"
import getConfigDisplayData from "./getConfigDisplayData"
import NUMBERS from "@helpers/constants/numbers"

const getProductCatalogueData = async (url: string, type: string, serverData?: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getProductCatalogueData.name}.`)
  }
  if (!type) {
    throw consoleError(`Type parameter is undefined in ${getProductCatalogueData.name}.`)
  }
  let isPIMProduct = await getConfigDisplayData()
  isPIMProduct = isPIMProduct?.data?.enable_pim_product

  const allCardsRawData = await getProductBlockData(url, serverData)
  const fieldPaywallRoles = getAllRoles(allCardsRawData)
  const { apiDomain } = getApiDomainAndLang() || ""
  const baseDomain = isServer() ? serverData?.cmsUrl : apiDomain

  const allConfigData = await getConfigDisplayData(serverData)
  let allCardsData: any = []
  allCardsData = allCardsRawData
    ? await Promise.all(
        allCardsRawData?.map(async (rawData: any) => {
          const isChildPaywalled = isPaywallProtected(
            getNestedObject(rawData, "attributes.enable_paywall_block_level"),
          )?.isPaywallProtectedFlag
  
          if (isChildPaywalled) {
            return null
          }
          const src = rawData?.url ? `https://${baseDomain}${rawData?.url}` : null
          const imagePaywallData = isPaywallProtected(rawData?.url)
          const styledImgData = isPaywallProtected(rawData?.image_style_uri)
          return {
            ourProductCardImage: {
              src: imagePaywallData.isPaywallProtectedFlag
                ? getPaywalledImgData(imagePaywallData.errorObj).imageUrl
                : src,
              alt: isPaywallProtected(rawData?.alt_text).isPaywallProtectedFlag
                ? null
                : rawData?.alt_text ?? null,
              styledimage: styledImgData.isPaywallProtectedFlag
                ? getPaywalledImgData(styledImgData.errorObj).imageUrl
                : rawData?.image_style_uri ?? null,
            },
            title: isPaywallProtected(rawData?.category_name).isPaywallProtectedFlag
              ? null
              : rawData?.category_name ?? null,
            buttonLabel: isPaywallProtected(rawData?.cta_text).isPaywallProtectedFlag
              ? null
              : rawData?.cta_text ?? null,
            path: isPaywallProtected(rawData?.path).isPaywallProtectedFlag
              ? null
              : rawData?.path?.replace(/amp;/g, "") ?? null,
          }
        }),
      )
    : []
  let catalogueCTA
  const isPimEnabled = isPIMProduct === NUMBERS.ONE
  catalogueCTA = {
    tabindex: 1,
    children: isPimEnabled ? allConfigData?.data?.pim_product_list_cta_text ?? null : allConfigData?.data?.product_list_cta_text ?? null,
    href: isPimEnabled ? allConfigData?.data?.pim_product_list_cta_link ?? null : allConfigData?.data?.product_list_cta_link ?? null,
    isExternal: false,
    isSecondary: true,
  }

  return {
    data: {
      allCardsData: filterNullValues(allCardsData),
      catalogueCTA,
    },
    type,
    fieldPaywallRoles,
  }
}

export default getProductCatalogueData
