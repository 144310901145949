import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."
import { consoleError } from "../../utils/error"

const getColumnSize = (data: any) => {
  const fieldDisplayVariations = isPaywallProtected(
    getNestedObject(data, "attributes.field_display_variations"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(data, "attributes.field_display_variations") ?? null
  const columnSize = fieldDisplayVariations?.split("_")
  return columnSize && Number(columnSize[0])
}

const getMediaPlayerData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getMediaPlayerData.name}.`)
  const serverData = args?.[3] // accepting serverData only
  const mediaPlayerBlock = await getBlockData(`${url}`, false, serverData)
  const mediaPlayerData = mediaPlayerBlock?.data
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(mediaPlayerData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(mediaPlayerData)
  const src = getNestedObject(mediaPlayerData, "attributes.body.processed") ?? null
  const size = getColumnSize(mediaPlayerData) ?? 12
  const title =
    getNestedObject(mediaPlayerData, "attributes.field_media_container_title.value") ?? null
  const position = isPaywallProtected(
    getNestedObject(mediaPlayerData, "attributes.field_alignment_styles"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(mediaPlayerData, "attributes.field_alignment_styles") ?? null

  return {
    src,
    title,
    position,
    size,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getMediaPlayerData
