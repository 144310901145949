import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getImageMeta } from "."

const getLinkIconData = async (url: string, ...args: any) => {
  const serverData = args?.[3] 
  let menuArray: any = []
  const preFetchedData = args?.[2] 

  let menuIcons =
    preFetchedData && Object.keys(preFetchedData)?.length > 0
      ? preFetchedData
      : await getBlockData(url, false, serverData)

  const isParentPaywalled = isPaywallProtected(
    getNestedObject(menuIcons, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(menuIcons)

  menuIcons = Object.keys(preFetchedData)?.length > 0? menuIcons: menuIcons?.included ?? []
  const menuData = (menuIcons: any) => {
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(menuIcons, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag

    if (isChildPaywalled) {
      return null
    }
    const imagePaywallData = isPaywallProtected(menuIcons?.relationships?.field_menu_icon)
    const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
      ? getPaywalledImgData(imagePaywallData?.errorObj)
      : getImageMeta(menuIcons?.relationships?.field_menu_icon)

    // eslint-disable-next-line no-return-assign
    return {
      linkWithIconContent: {
        styledimage: imageStyledUrl ?? null,
        imgsrc: {
          height: 64,
          width: 64,
          src: imageUrl,
          alt,
        },
        title: isPaywallProtected(menuIcons?.attributes?.field_menu_title).isPaywallProtectedFlag
          ? null
          : menuIcons?.attributes?.field_menu_title ?? "",
        refrenceId: isPaywallProtected(menuIcons?.attributes?.field_reference_id)
          .isPaywallProtectedFlag
          ? null
          : menuIcons?.attributes?.field_reference_id ?? "",
      },
    }
  }
  if (!(Object.keys(preFetchedData)?.length > 0) && menuIcons) {
    menuArray = await Promise.all(
      menuIcons?.map(menuData)
    )  
  }
  else {
    menuArray.push(menuData(menuIcons))
  }
  return {
    linkWithIconContent: Object.keys(preFetchedData)?.length > 0 ? menuArray :filterNullValues(menuArray),
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getLinkIconData