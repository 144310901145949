import * as actionTypes from "../ActionTypes"

export const updateProductFilter = (accordianFilterIndex: number) => {
  const action: CommonAction = {
    type: actionTypes.ACCORDIAN_PRODUCT_FILTER_INDEX,
    accordianProductFilterIndex: accordianFilterIndex,
  }
  return performRequest(action)
}

export const updateProductCatalogTitle = (title: string) => {
  const action: CommonAction = {
    type: actionTypes.PRODUCT_CATALOG_TITLE,
    productCatalogTitle: title,
  }
  return performRequest(action)
}

export const updatePaywallContent = (isPaywalled: boolean) => {
  const action: CommonAction = {
    type: actionTypes.PAYWALL_CONTENT,
    isPaywallContent: isPaywalled,
  }

  return performRequest(action)
}

export const updateReloadContent = (isReloaded: boolean) => {
  const action: CommonAction = {
    type: actionTypes.IS_RELOADED,
    isReloadedContent: isReloaded,
  }

  return performRequest(action)
}

export const resetPaywall3Roles = () => {
  const action: CommonAction = {
    type: actionTypes.RESET_PAYWALL_3_ROLES,
  }

  return performRequest(action)
}
export const updatePaywall3Roles = (paywallRoles: any) => {
  const action: CommonAction = {
    type: actionTypes.SET_PAYWALL_3_ROLES,
    paywall3Roles: paywallRoles,
  }

  return performRequest(action)
}

export const setRefApplicationID = (refAppId: string) => {
  const action: CommonAction = {
    type: actionTypes.SET_REF_APP_ID,
    refAppId,
  }

  return performRequest(action)
}

export const setUserStateGlobally = (loggedIn: boolean, loggedInUserType: string) => {
  const action: CommonAction = {
    type: actionTypes.USER_CATEGORY,
    isLoggedIn: loggedIn,
    userType: loggedInUserType,
    cmsUrl: JSON.parse(sessionStorage.getItem("domainData") as string) ?? "",
    feUrl: JSON.parse(sessionStorage.getItem("feDomainData") as string) ?? "",
    language: JSON.parse(sessionStorage.getItem("domainLang") as string) ?? "",
  }

  return performRequest(action)
}

export const saveApplicationErrorPageData = (language: string, errorPageData: any) => {
  const action: CommonAction = {
    type: actionTypes.APPLICATION_ERROR_PAGE,
    errorPageData,
    language,
  }

  return performRequest(action)
}

const performRequest = (action: UserAction) => {
  return (dispatch: DispatchType) => {
    dispatch(action)
  }
}
