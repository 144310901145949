import NUMBERS from "@helpers/constants/numbers"
import { consoleError } from "../../utils/error"
import getConfigDisplayData from "./getConfigDisplayData"
import { getApi } from "@utils/baseApi"
import { getAllRoles } from "@utils/paywallUtilities"

const getCSVProductlistData = async (url: string, type: string, serverData?: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getCSVProductlistData.name}.`)
  }
  let configData = await getConfigDisplayData(serverData)
  configData = configData?.data

  const renderProductSearchConfig = async () => {
    return {
      hideViewCTA: configData?.product_list_view_cta_toggle !== NUMBERS.ONE,
      labels: {
        list_view_text: configData?.product_list_view_text ?? null,
        grid_view_text: configData?.product_grid_view_text ?? null,
        default_view: configData?.product_list_default_view ?? null,
      },
      title: configData?.product_list_configuration_title_text ?? "",
      cardButtonCTALabel: configData?.product_list_button_label_text ?? null,
      searchLabels: {
        inputDataContent: {
          type: "primary",
          placeholder: configData?.fes_search_cta ?? "",
        },
        buttonText: configData?.product_list_search_cta_text ?? "",
      },
      filterLabels: {
        clearText: configData?.product_list_search_clear_all_text ?? "",
        applyText: configData?.product_list_apply_filter_text ?? "",
        searchCTA: configData?.fes_search_cta ?? "",
        filterText: configData?.fes_cat_text ?? "",
        showMoreText: configData?.fes_show_more_text ?? "",
        showLessText: configData?.fes_show_less_text ?? "",
        textSearchLabel: configData?.fes_text_search_label ?? "",
        availableProductCountText: configData?.fes_available_product_count_text ?? "",
        searchedProductCount: configData?.fes_search_product_count_text ?? "",
        unselectAllItemLabel: configData?.fes_unselect_all_items_label ?? "",
        searchCTAMobile: configData?.fes_search_cta_mobile ?? "",
        searchPlaceholderMobile: configData?.fes_search_cta_placeholder_mobile ?? "",
        catTextMobile: configData?.fes_cat_text_mobile ?? "",
        unselectItems: configData?.fes_unselect_all_items_label_mobile ?? "",
      },
      resultLabels: {
        resultsFoundText: configData?.product_list_search_results_found ?? "",
        noResultsFoundText: configData?.product_list_no_results_found_title ?? "",
      },
      specifications: configData?.show_in_card ?? [],
    }
  }
  const response = await getApi(url, false, serverData)
  const fieldPaywallRoles = getAllRoles(response)

  const productSearchConfig = await renderProductSearchConfig()
  return {
    data: {
      title: productSearchConfig?.title,
      label: productSearchConfig?.labels,
      hideViewCTA: productSearchConfig?.hideViewCTA,
      searchLabels: productSearchConfig?.searchLabels,
      filterLabels: productSearchConfig?.filterLabels,
      resultLabels: productSearchConfig?.resultLabels,
      url,
      buttonLabel: productSearchConfig?.cardButtonCTALabel,
      showInCard: configData?.show_in_card,
    },
    type,
    fieldPaywallRoles,
  }
}

export default getCSVProductlistData
