import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { postApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getStarRatingData = async (url: string, entityId: number, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getStarRatingData.name}.`)
  const serverData = args?.[3] // accepting serverData only
  const starRatingData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(starRatingData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(starRatingData)
  const fieldTitle = getNestedObject(starRatingData, "data.attributes.field_title")
  const isTitleProtected = isPaywallProtected(fieldTitle).isPaywallProtectedFlag
  if (isTitleProtected) {
    return {}
  }
  const apiResponse = await postApi("solarapi/ratingcount", { entity_id: entityId })
  let title: any = ""
  if (apiResponse?.data?.ratingcount > 0) {
    title = isPaywallProtected(apiResponse?.data?.ratings_given).isPaywallProtectedFlag
      ? null
      : apiResponse?.data?.ratings_given ?? null
  } else {
    title = isPaywallProtected(apiResponse?.data?.no_ratings_given).isPaywallProtectedFlag
      ? null
      : apiResponse?.data?.no_ratings_given ?? null
  }
  return {
    avgRating: apiResponse?.data?.ratingaverage ?? 0,
    title: title ?? "",
    isDisabled: false,
    disableLayoutAfterRate: false,
    localStorageName: "localStorageNameStars",
    entityId,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getStarRatingData
