import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import NUMBERS from "@helpers/constants/numbers"
import { consoleError } from "../../utils/error"
import { filterURL, getBlockData, getImageMeta } from "."

const getTeaserFeatureData = async (url: string, ...args: any) => {

  if (!url) {
    throw consoleError(`Teaser Feature Api URL parameter is undefined in ${getTeaserFeatureData.name}.`)
  }
  
  const serverData = args?.[3] // accepting serverData only
  const teaserFeatureRawData = await getBlockData(`${url}`, false, serverData)

  const slide = teaserFeatureRawData?.data ?? {}
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(slide, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(slide)
  const imagePaywallData = isPaywallProtected(getNestedObject(slide, "relationships.field_tf_image"))
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(slide?.relationships.field_tf_image)


  return {
    title: isPaywallProtected(getNestedObject(slide, "attributes.field_tf_title"))
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(slide, "attributes.field_tf_title") ?? null,
    paragraph: getNestedObject(slide, "attributes.body.value") ?? null,
    imageSrc: imageUrl ?? null,
    imageAlt: alt ?? null,
    styledimage: imageStyledUrl ?? null,
    link: filterURL(getNestedObject(slide, "attributes.field_feature_cta.uri")) ?? null,
    linkText: getNestedObject(slide, "attributes.field_feature_cta.title") ?? null,
    gridType: isPaywallProtected(getNestedObject(slide, "attributes.field_grid_type"))
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(slide, "attributes.field_grid_type") ?? null,
    columnDesktop: NUMBERS.SIX,
    fieldPaywallRoles,
    isParentPaywalled,
  }

}

export default getTeaserFeatureData
