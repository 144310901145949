import { LinkWithIconContent } from "@molecules"
import { ILinkWithIconProps } from "./_linkWithIcon.interface"
import { handleClick } from "@utils/navigationHelper"
import { useRouter } from "next/router"
import Link from "next/link"

const LinkWithIcon = ({ linkWithIconContent }: ILinkWithIconProps) => {
  const router = useRouter()
  
  return (
    <div className="link-with-icon">
      <div className="link-with-icon-list">
        {linkWithIconContent &&
          linkWithIconContent.map((menu: any, index: any) => (
            menu?.linkWithIconContent?.refrenceId ? <Link key={`${menu?.linkWithIconContent?.refrenceId}`}
              href={
                menu?.linkWithIconContent?.refrenceId.includes("/") ? menu?.linkWithIconContent?.refrenceId : "javascript:void(0)"
               }
              legacyBehavior>
              <a
                className="link-with-icon-list-button"
                onClick={() => handleClick(router, menu?.linkWithIconContent?.refrenceId)}>
                <div className="link-with-icon-list-item">
                  <LinkWithIconContent
                    imgsrc={menu?.linkWithIconContent?.imgsrc}
                    title={menu?.linkWithIconContent?.title}
                    {...linkWithIconContent}
                  />
                </div>
              </a>
            </Link>
            : <div className="link-with-icon-list-item" key={ index }>
                <LinkWithIconContent
                  imgsrc={menu?.linkWithIconContent?.imgsrc}
                  title={menu?.linkWithIconContent?.title}
                  {...linkWithIconContent}
                />
              </div>
          ))}
      </div>
    </div>
  )
}

export default LinkWithIcon
