import { EVENTS } from "./gtmEvents"

export const dataLayerPush = (eventName: EVENTS, parameters: object) => {
  // TODO: here first check if OneTrust consent givent
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: eventName,
    ...parameters,
  })
}

export const purgeDataLayer = () => {
  window.dataLayer = window.dataLayer || []
  const filteredDataLayer = window.dataLayer.filter((item: any) => {
    const isCustomEvent = Object.values(EVENTS).includes(item.event)
    return !isCustomEvent
  })
  window.dataLayer = [...filteredDataLayer]
}
