export const trimText = (text: string, limit: number) => {
  try {
    const trimmedText = text?.substring(0, limit)
    const checkBreak = trimmedText?.substring(
      0,
      Math.min(trimmedText?.length, trimmedText?.lastIndexOf("")),
    )
    if (limit < text?.length) return checkBreak?.concat(" ...")

    return checkBreak
  } catch (error) {
    return text
  }
}
