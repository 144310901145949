import NUMBERS from "@helpers/constants/numbers"
import router from "next/router"
import {
  ReactNode, createContext, useMemo, useState,
} from "react"
export interface IThemeProvider {
  applicationTheme: string
  pageTheme: string
  updateApplicationTheme: (appTheme: string, pageLevelTheme: string) => void
}

type Props = {
  theme: string
  pageLevelTheme: string
  children: ReactNode
}

const ApplicationThemeContext = createContext<IThemeProvider>({} as IThemeProvider)

function ApplicationThemeProvider(props: Props) {
  const { theme, pageLevelTheme, children } = props

  const [applicationTheme, setApplicationTheme] = useState<string>(theme)
  const [pageTheme, setPageTheme] = useState<string>(pageLevelTheme)

  const updateApplicationTheme = (appTheme: string, pageLevelTheme: string) => {
    if (appTheme) {
      setApplicationTheme(appTheme)
    }
    if (pageLevelTheme) {
      setPageTheme(pageLevelTheme)
      const currentUrl = router.pathname
      const isInPath = currentUrl.includes("preview")
      if (isInPath) {
        setTimeout(() => {
          document.querySelector("main")!.setAttribute("data-theme-color", pageLevelTheme)
        }, NUMBERS.THREE * NUMBERS.THOUSAND)
      }
      else document.querySelector("main")!.setAttribute("data-theme-color", pageLevelTheme)

    } else {
      setPageTheme("")
    }

    document.querySelector("html")!.className = `${appTheme}`
  }

  const applicationThemeProviderValue: IThemeProvider = useMemo(
    () => ({
      applicationTheme,
      pageTheme,
      updateApplicationTheme,
    }),
    [applicationTheme, pageTheme, updateApplicationTheme],
  )

  return (
    <ApplicationThemeContext.Provider value={applicationThemeProviderValue}>
      <div className="application-theme" data-theme={applicationTheme}>{children}</div>
    </ApplicationThemeContext.Provider>
  )
}

export { ApplicationThemeContext, ApplicationThemeProvider }
