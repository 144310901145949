import { IContainerProps } from "./_container.interface"

const Container = (props: IContainerProps) => {
  const { children, className, fluid, narrow, bleed, fineprint } = props

  const getContainerClass = () => {
    const classes = []
    fluid ? classes.push("container-fluid") : classes.push("container")
    narrow && classes.push("container-narrow")
    bleed && classes.push("container-bleed")
    fineprint && classes.push("container-fineprint")
    if (className) classes.push(className)
    return classes.join(" ")
  }

  return <div id= {props?.id} className={`${getContainerClass()} ${className === "heroBannerContent" ? "" : "component-wrapper"}`}>{children}</div>
}

export default Container
