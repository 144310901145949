import getSpecialityVocabData from "./getSpecialityVocabData"

const getSpecialityFilterData = async (serverData?: any) => {
  let specialityData = await getSpecialityVocabData(serverData)
  specialityData = specialityData?.data
  const options: any = []

  specialityData?.map((option: any) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

export default getSpecialityFilterData
