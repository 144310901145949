import { useEffect, useState } from "react"
import { FlexiTabContent, RichText, TabItem } from "@atoms"
import { IFlexiTabsProps } from "./_flexitabs.interface"

const FlexiTabs = (props: IFlexiTabsProps) => {
  const tabContents = props.tabContents ? props.tabContents : []
  const activeItem = props.activeTab
  const { tabHeadItems } = props
  const [selectedItem, setSelectedItem] = useState(activeItem || 0)

  const handleClick = (index: number) => {
    setSelectedItem(index)
    const tabHeader = document.querySelector(".flexi-tab-heads") as HTMLElement
    const navItem = document.querySelectorAll(".nav-item")[index] as HTMLElement
    tabHeader.scrollLeft = navItem.offsetLeft - tabHeader.clientWidth / 2 + navItem.clientWidth / 2;
  }

  useEffect(() => {
    if (activeItem) {
      setSelectedItem(activeItem)
    }
  }, [activeItem])

  return (
    <div className="tab-container flexi-tab">
      <ul className="flexi-tab-heads" role="tablist">
        {tabHeadItems?.map((item: any, index: number) => (
          <TabItem
            itemID={item.itemID}
            referrerId={item?.referrerId}
            key={index}
            active={selectedItem === index}
            onClick={() => handleClick(index)}
            tabDesign={item.tabDesign}
            className="no-breaking-words"
            greyColor>
            <RichText content={`${item.children}`} />
          </TabItem>
        ))}
      </ul>
      <div className="tab-content generic-tab flexi-tab-body" id="">
        {tabContents?.map((item: any, index: number) => (
          <div
            key={item.itemID}
            className={`tab-pane fade ${selectedItem === index ? "show active" : ""}`}
            role="tabpanel">
            <FlexiTabContent content={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default FlexiTabs
