import React, { ChangeEvent, useState } from "react"
import { Button, Column, DropDown, RichText, Row } from "@atoms"
import { getApi, middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import NUMBERS from "@helpers/constants/numbers"
import { EVENTS } from "@utils/gtmEvents"
import { handleDataLayerError } from "@utils/gtmUtilsHelpers"
import { dataLayerPush } from "@utils/gtmutils"
import RadioButton from "@atoms/radioButton"
import { getAssetPrefixUrl } from "@utils/helper"
import { DisplayMessage, InputBoxWithLabel } from "@molecules"
import { IValidationsData, SelectType } from "./_dosageCalculator.interface"

const DosageCalculator = (props: any) => {
  const { fields, notificationMessages } = props
  const handleOnCancel = false
  const [errors, setErrors] = useState<any>({})
  const [formValues, setFormValues] = useState<any>({})
  const [result, setResult] = useState<any>()
  const [formSubmitValues, setFormSubmitValues] = useState<any>({})
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [isFormCleared, setIsFormCleared] = useState(false)
  const [selectedButton, setSelectedButton] = useState(null)
  const [showToast, setShowToast] = useState<"success" | "error" | "">("")
  const [selectedValues, setSelectedValues] = useState<Array<SelectType>>([])
  const formName = fields?.[0]?.formName
  let submitUrl = config.onPrem.WEBFORM_SUBMIT
  const types = ["select", "dropdown", "radios", "button"]

  const checkForError = (fieldValue: any, validations: IValidationsData) => {
    const { mandatory } = validations
    if (fieldValue?.length <= NUMBERS.ZERO && mandatory.required) {
      return mandatory.message
    }
    return null
  }
  const getAllFieldErrors = (e: any) => {
    e.preventDefault()
    const newErrors = { ...errors }
    fields?.forEach((item: any) => {
      const key: string = item.key ?? ""
      const value = formValues[key] ?? ""
      if (item.validations && !errors[key]) {
        const err = checkForError(value, item.validations)
        if (err) {
          newErrors[key] = err
        } else {
          delete newErrors[key]
        }
      }
    })

    const errLength = Object.keys(newErrors).length
    if (errLength) {
      setErrors(newErrors)
      if (errLength && Object.keys(newErrors)?.[0]) {
        return Object.keys(newErrors)?.[0] ?? "window"
      }
      return "window"
    }
    return false
  }

  const handleCancel = (invokeCancel = true) => {
    setShowIndicator(false)
    if (invokeCancel) {
      setFormValues({})
      setFormSubmitValues({})
      setIsFormCleared(true)
      setSelectedButton(null)
      setSelectedValues([])
      const myElement = document.querySelector(".updateColor")
      myElement?.classList.remove("updateColor")
      setTimeout(() => {
        window?.scrollTo({ left: 0, top: 0, behavior: "smooth" })
      }, NUMBERS.HUNDRED)
      setResult(null)
    } else {
      setResult(null)
    }
  }
  const showToastMessage = (val: "success" | "error" | "") => {
    setShowToast(val)
    setTimeout(() => {
      window?.scrollTo({ left: 0, top: 0, behavior: "smooth" })
    }, NUMBERS.HUNDRED)

    setErrors({})
  }
  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    let callCancelHandler = true
    const err = getAllFieldErrors(e)
    if (err) {
      const ele = err !== "window" ? document.getElementById(err) : null
      const leftPos = ele ? ele.offsetLeft : NUMBERS.ZERO
      const topPos =
        ele && ele.offsetTop > NUMBERS.FORTY ? ele.offsetTop - NUMBERS.FORTY : NUMBERS.ZERO
      setTimeout(() => {
        window?.scrollTo({ left: leftPos, top: topPos, behavior: "smooth" })
      }, NUMBERS.HUNDRED)
    } else {
      try {
        setShowIndicator(true)
        const { fail, error, response } = await middlewarePostAPI(submitUrl, {
          webform_id: formName,
          ...formSubmitValues,
        })
        const sid = Object.values(response)
        callCancelHandler = false
        if (fail) {
          showToastMessage("error")
        } else {
          const data = await getApi(`/solarapi/${formName}/${sid}`)
          const results = data.result
          setResult(results)
          setShowIndicator(false)
        }
        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: fail ? `${Object.keys(error?.data?.error)?.join(", ")} missing` ?? "" : "",
          form_status: fail ? "error" : "submitted",
        }
        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)
      } catch (error) {
        setShowIndicator(false)
        if (error) {
          showToastMessage("error")
        }
        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: error,
          form_status: "error",
        }
        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)
        handleDataLayerError(`${formName} form submission failed`, `${formName} Form`)
      }
      // handleOnCancel = true
      // handleCancel(callCancelHandler)
    }
  }
  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement, Element>,
    validations: any,
  ) => {
    e.preventDefault()
    const fieldName = `${e.target.name}`

    const fieldValue = e.target.value.trim()
    const newErrors: any = { ...errors }
    const err = checkForError(fieldValue, validations)
    if (err) {
      newErrors[fieldName] = err
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)
  }
  const getDescriptionAndHelpText = (item: any) => (
    <>
      {item?.description && (
        <div className="description">
          <RichText content={item.description} />
        </div>
      )}
      {item?.helpText && (
        <div className="help-text">
          <RichText content={item.helpText} />
        </div>
      )}
    </>
  )
  const getErrors = (fieldName: string) => {
    return Object.entries(errors)
      .map(([key, value]: any): string => (fieldName === key ? value : ""))
      .join("")
  }
  const handleChange = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fieldName = `${e.target.name}`
    const fieldValue = e.target.value.trim()
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldValue
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    delete errors[fieldName]
  }
  const updateSeletion = (e: any, sectionId: number, option: any, fieldName: string) => {
    const fieldValueArray: any = []
    fieldValueArray.push(option)
    const fieldValue = fieldValueArray.map((a: any) => a.value)
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldValue[NUMBERS.ZERO]
    setFormValues(newFormValues)
    setFormSubmitValues(newSubmitFormValue)
    setSelectedValues(newSubmitFormValue)
    setIsFormCleared(false)
    delete errors[fieldName]
    setTimeout(() => {
      const myElement = document.querySelector(".updateColor")
      myElement?.classList.remove("updateColor")
      e.target.classList.add("updateColor")
      selectedButton === option
        ? e.target.classList.remove("updateColor")
        : e.target.classList.add("updateColor")
    }, NUMBERS.HUNDRED)
  }
  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )
  const handleDropdownChange = (val: any, fieldName: string, isMulti: boolean) => {
    const fieldValue = val.map((a: any) => a.value)
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    const newSubmitFormValue: any = { ...formSubmitValues }
    if (isMulti) {
      newSubmitFormValue[fieldName] = fieldValue
    } else if (fieldValue?.length) {
      newSubmitFormValue[fieldName] = fieldValue[NUMBERS.ZERO]
    } else {
      delete newSubmitFormValue[fieldName]
    }
    setFormValues(newFormValues)
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    delete errors[fieldName]
  }
  const getButtonClass = (key: any, option: any, fieldName: string) => {
    if (selectedValues.length <= key) {
      return "show"
    }
    if (Object.values(selectedValues)[0] === option.value) {
      return "show updateColor"
    }
    return "show"
  }
  const getAnswer = (typeData: any, question: any, key: number) => {
    if (question.type === "button" && question.role === "submit") {
      submitUrl = question.url
    }
    if (typeof typeData) {
      return (
        <>
          {typeData === "select" && !question?.wrapper_attributes.class && (
            <div>
              <div
                className={`input-select${errors[question.index] ? " invalid-select" : ""}`}
                key={question.index}
                id={question.key}>
                {question.label && (
                  <h1>
                    <label
                      htmlFor={`dropdown-${question.key}`}
                      aria-required={question.validations.mandatory}>
                      {question.label}
                      {question.validations.mandatory.required && "*"}
                    </label>
                  </h1>
                )}
                <DropDown
                  id={`dropdown-${question.key}`}
                  ariaLabel={`dropdown-${question.key}`}
                  options={question.options}
                  name={question.key}
                  placeHolder={question.placeHolder}
                  isCleared={isFormCleared}
                  showPlaceholder={!!question.placeholder}
                  intialValue={[]}
                  isRequired={question.validations.mandatory.required || false}
                  onValueChange={(val: any) => handleDropdownChange(val, question.key, false)}
                  isMulti={question.isMulti || false}
                />
                {getDescriptionAndHelpText(question)}
                {errors[question.key] && (
                  <div className="error">
                    <RichText content={errors[question.key]} />
                  </div>
                )}
              </div>
            </div>
          )}
          {question?.type === "textfield" && (
            <div id={question.key}>
              <InputBoxWithLabel
                {...question}
                value={formValues[question.inputField.name]}
                key={key}
                name={question.key}
                onBlurhandler={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                  handleBlur(e, question.validations)
                }
                onChangehandler={handleChange}
                error={getErrors(question.inputField.name)}
                helpDescriptionText={
                  question.description || question.helpText
                    ? getDescriptionAndHelpText(question)
                    : ""
                }
                maxLength={question.maxLength}
              />
            </div>
          )}
          {question?.type === "radios" && (
            <fieldset
              className="input-radio-group"
              aria-labelledby={question.key}
              aria-required={question.validations.mandatory.required}
              key={key}
              id={question.key}>
              <h1>
                <label
                  htmlFor={question.key}
                  aria-required={question.validations.mandatory.required}>
                  {question.label}
                  {question.validations.mandatory.required && "*"}
                </label>
              </h1>
              <div className="radioGroup">
                {question?.options?.map((opt: any) => (
                  <RadioButton
                    label={opt.label}
                    id={`${question.key}-${opt.id}`}
                    value={opt.value}
                    name={question.key}
                    required={question.validations.mandatory.required}
                    key={`${question.key}-${opt.id}`}
                    isChecked={formValues?.[question.key] === opt.value}
                    onChange={(e) => handleChange(e)}
                  />
                ))}
              </div>
              {getDescriptionAndHelpText(question)}
              {errors[question.key] && (
                <div className="error">
                  <RichText content={errors[question.key]} />
                </div>
              )}
            </fieldset>
          )}
          {question?.type === "select" && question?.wrapper_attributes.class && (
            <fieldset
              className="input-radio-group"
              aria-labelledby={question.key}
              aria-required={question.validations.mandatory.required}
              key={key}
              id={question.key}>
              <div key={question.index} id={question.key}>
                {question.label && (
                  <h1>
                    <label
                      htmlFor={`dropdown-${question.key}`}
                      aria-required={question.validations}>
                      {question.label}
                      {question.validations.mandatory.required && "*"}
                    </label>
                  </h1>
                )}
                <div className="buttonsGroup">
                  {question.options?.map((option: any, index: number) => (
                    <Button
                      tabindex={NUMBERS.TWO}
                      isGhost={false}
                      iconPosition="right"
                      key={option.key}
                      onClick={(e) => updateSeletion(e, question.key, option, question.key)}
                      className={getButtonClass(index, option, question.key)}>
                      {option.value}
                    </Button>
                  ))}
                </div>
                {getDescriptionAndHelpText(question)}
                {errors[question.key] && (
                  <div className="error">
                    <RichText content={errors[question.key]} />
                  </div>
                )}
              </div>
            </fieldset>
          )}
          {question?.type === "button" && question.role === "submit" && !result && (
            <Button
              isDisabled={!!Object.keys(errors).length}
              key={key}
              type={question.role}
              tabindex={NUMBERS.TWO}
              className="submit-btn">
              {question?.text ? question?.text : "Submit"}
            </Button>
          )}
          {question?.type === "button" && question.role === "reset" && result && (
            <Button
              key={question.key}
              type={question.role}
              tabindex={NUMBERS.TWO}
              isSecondary={true}
              onClick={(e: any) => handleCancel(true)}
              className="reset-btn">
              {question?.text ? question?.text : "Reset"}
            </Button>
          )}
        </>
      )
    }
  }

  return (
    <div>
      <Row className="dosage-calculator">
        <Column desktop={12} tablet={12} mobile={12}>
          <div>
            {showIndicator && overlayElement()}
            <form className="free-web-form" autoComplete="off" data-testid="free-web-form" onSubmit={handleSubmit}>
              {!!showToast && (
                <DisplayMessage
                  displayMessageType={showToast}
                  displayTxt={notificationMessages?.[`${showToast}_message`] ?? showToast}
                />
              )}
              {fields.map((question: any, index: any) => (
                <div key={index} className="dosage-calculator-block">
                  {question.type === "processed_text" && (
                    <RichText
                      className="dosage-calculator-block-head"
                      content={question.text || ""}
                    />
                  )}
                  {question.type !== "processed_text" && types.includes(question.type) && (
                    <div className="dosage-calculator-block-option">
                      {getAnswer(question.type, question, index)}
                      {types.indexOf(question.type) !== -1 && question.type !== "button" && (
                        <hr className="dosage-calculator-block-line" />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </form>
          </div>
        </Column>
      </Row>
      {result != null && (
        <Row className="dosage-calculator">
          <Column desktop={12} tablet={12} mobile={12}>
            <div className="results">
              <h1>Results</h1>
              {result && <RichText content={result || ""} />}
            </div>
          </Column>
        </Row>
      )}
    </div>
  )
}

export default DosageCalculator
