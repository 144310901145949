import getNestedObject from "@utils/nestedObjects"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData, getTaxonomy } from "."

const getSocialShareCTAData = async (url: string, data?: any) => {
  let ctaBtnData
  if (!data) {
    ctaBtnData = await getBlockData(`${url}`)
  } else {
    ctaBtnData = data
  }
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(ctaBtnData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(ctaBtnData)
  return {
    ctaBtnType: "social_sharing",
    ctaBtnLabel: getNestedObject(ctaBtnData, "attributes.field_social_sharing_text.value") ?? "",
    ctaBtnData:
      (await getTaxonomy(
        getNestedObject(ctaBtnData, "relationships.field_social_platforms.links.related.href") ??
          "",
      )) ?? [],
    pageLinkLabel: getNestedObject(ctaBtnData, "attributes.field_page_link_text.value") ?? "",
    copiedLabel: getNestedObject(ctaBtnData, "attributes.field_copy_icon_text.value") ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getSocialShareCTAData
