import { ISimpleVideoProps } from "./_simpleVideo.interface"

const SimpleVideo = (props: ISimpleVideoProps) => {
    const { autoPlay, controls, loop, muted, poster, videoURL } = props
    const videoId = "simpleVideo_"+Math.floor((Math.random() * 1000) + 1)
    return (
        <>
            <video id={videoId} width="100% "autoPlay={autoPlay} loop={loop} muted={muted} poster={poster} controls={controls}>
                <source src={videoURL} type="video/mp4" />
            </video>
        </>
    )
}

export default SimpleVideo




