import React, { useContext, useEffect, useState } from "react"
import { Column, Icon } from "@atoms"
import ReactPaginate from "react-paginate"
import getDirection from "@utils/direction"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IPaginationProps } from "./_pagination.interface"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"

const Pagination = (props: IPaginationProps) => {
  const {
    itemsCount,
    itemsPerPage,
    itemsPerPageMobile,
    className,
    resetOffset = false,
    offSet = 0,
    cannonicalUrl,
  } = props
  const [configData, setConfigData] = useState<any>({})
  const applicationConfigContext = useContext(ApplicationContext)

  useEffect(() => {
    setConfigData(applicationConfigContext.applicationConfiguration.siteConfig)
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  const isMobile = useMediaQuery("(max-width: 767px)")

  const itemsToRender = isMobile ? itemsPerPageMobile || itemsPerPage : itemsPerPage

  const [itemOffset, setItemOffset] = useState(0)
  const [pageOffset, setPageOffset] = useState(offSet)
  const pageCount = Math.ceil(itemsCount / itemsToRender)

  const paginationRenderFix = () => {
    const targetOffset = 3
    const { product_list_text_for_of = "" } = configData

    if (pageCount > 1) {
      if (isMobile) {
        const targetNode = document.getElementsByClassName(className ?? "pagination-set-sm")[0]
        const targetIndex = targetNode.childElementCount - targetOffset
        targetNode.classList.add("pag-fix")
        const currentPage = itemOffset / itemsToRender + 1
        const currentPageText = `${
          offSet ? offSet + 1 : currentPage
        } <span class="page-count-text-of">${product_list_text_for_of}</span> ${pageCount}`
        targetNode.children[targetIndex].getElementsByTagName(
          "a",
        )[0].innerHTML = `<span class="page-count-text">${currentPageText}</span>`
        targetNode.children[targetIndex].getElementsByTagName("a")[0].style.direction =
          getDirection()
      } else {
        const targetNode = document.getElementsByClassName(className ?? "pagination-set-lg")[0]
        const targetIndex = targetNode.childElementCount - targetOffset
        targetNode.children[targetIndex].getElementsByTagName("a")[0].innerHTML = (
          pageCount - 1
        ).toString()
      }
    }
  }

  const getClasses = ["pagination", isMobile ? "pagination-set-sm" : "pagination-set-lg", className]
    .filter(Boolean)
    .join(" ")

  const onButtonClick = (event: { selected: number }) => {
    const newOffset = (event.selected * itemsToRender) % itemsCount
    setItemOffset(newOffset)
    setPageOffset(event.selected)
    props.onClick && props.onClick(newOffset)
    paginationRenderFix()
    document?.getElementsByClassName("scroll-top")[0]?.scrollIntoView()
  }

  const handlePaginationClick = (e: any) => {
    e?.event?.preventDefault()
  }

  useEffect(() => {
    configData && paginationRenderFix()
  }, [onButtonClick, isMobile])

  useEffect(() => {
    if (resetOffset) {
      setItemOffset(0)
      setPageOffset(0)
      paginationRenderFix()
      document?.getElementsByClassName("scroll-top")[0]?.scrollIntoView()
    }
  }, [resetOffset])

  return (
    <>
      {pageCount > 1 && (
        <Column className="pagination-set" mobile={12} tablet={12} desktop={12}>
          <ReactPaginate
            nextLabel={
              <span className="pagination-nav-button next">
                <span className="rebrandingLabel">{configData?.product_next_cta_label}</span>
                <Icon iconName="ChevronLeftIcon2" />
              </span>
            }
            onPageChange={onButtonClick}
            onClick={handlePaginationClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={2}
            pageCount={pageCount}
            previousLabel={
              <span className="pagination-nav-button">
                <Icon iconName="ChevronLeftIcon2" />
                <span className="rebrandingLabel">{configData?.product_previous_cta_label}</span>
              </span>
            }
            forcePage={pageOffset}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel={`${isMobile ? "" : "..."}`}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName={getClasses}
            activeClassName="active"
            hrefBuilder={(pageNumber) =>
              cannonicalUrl ? cannonicalUrl.replace("pageNo", (pageNumber - 1).toString()) : "#"
            }
            hrefAllControls
          />
        </Column>
      )}
    </>
  )
}

export default Pagination
