import useSWR, { type SWRConfiguration } from "swr"

const config: SWRConfiguration = {
  revalidateOnFocus: false,
}

export default function useSWRRequest(url: string, fetch: () => Promise<any>) {
  const { data, error, isLoading, mutate } = useSWR(url, fetch, config)
  if (error) console.error("failed to load", error)

  return {
    data,
    error,
    isLoading,
    mutate,
  }
}
