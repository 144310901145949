import { Anchor, BrandingPattern, Button, Container, Icon, Row } from "@atoms"
import { CheckoutCard } from "@molecules"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  deleteProductFromCart,
  emptyCart,
  updateProductInCart,
} from "store/actions/WebshopActionCreators"
import { useCallback, useEffect, useState } from "react"
import { Dispatch } from "redux"
import EmptyCart from "@organisms/emptyCart"
import { useRouter } from "next/router"
import Breadcrumbs from "@organisms/breadcrumbs"
import useCheckoutHooks from "@helpers/hooks/cartHooks/useCheckoutHook"
import { DispatchProps, IDisplayProps } from "./_shoppingCartList.interface"

const CartItem = (props: any) => {
  const { detailLabels, productImgUrl, selectedQty, quantityBtnProps, productStyledImgUrl } =
    props?.item

  return (
    <CheckoutCard
      title={detailLabels?.title}
      subTitle={detailLabels?.subTitle ?? ""}
      detailUrl={detailLabels?.detailUrl}
      quantity={selectedQty}
      productImgUrl={productImgUrl}
      productStyledImgUrl={productStyledImgUrl}
      quantityLabel={props?.quantityLabel}
      isEditable={true}
      quantityOptions={quantityBtnProps?.quantityOptions}
      handleUpdateQuantity={props?.handleQuantity}
      handleDeleteProduct={props?.handleDeleteProduct}
      quantityErrorMessage={props?.quantityError}
    />
  )
}

type Props = DispatchProps & IDisplayProps

const ShoppingCartList = (props: Props) => {
  const webshop: any = useSelector((state: any) => state.webshop)
  const [breadcrumbsData, setBreadcrumbsData] = useState<any>()
  const productsInCart = webshop?.productsInCart || []
  const router = useRouter()
  const {
    title,
    empty_cart_title,
    empty_cart_desc,
    back_to_product_list_label,
    back_to_product_list_url,
    cancel_label,
    checkout_cta_label,
    cart_page_label,
    homeTitle,
    homeLink,
    listingPageTitle,
    quantityErrorMessage,
    quantityLabel,
  } = props?.displayLabels
  const dispatch: Dispatch<any> = useDispatch()

  const { getPageBreadcrumbs } = useCheckoutHooks()

  const updateProductInCart: any = useCallback(
    (value: any) => {
      props?.updateProductInCart(value)
    },
    [dispatch],
  )
  const deleteProduct: any = useCallback(
    (value: any) => {
      props?.deleteProductFromCart(value)
    },
    [dispatch],
  )
  const emptyCart: any = useCallback(
    (value: any) => {
      props?.emptyCart(value)
    },
    [dispatch],
  )
  const handleUpdateQuantity = (qty: number, product: any) => {
    updateProductInCart({ ...product, selectedQty: Number(qty) })
  }

  const handleCancel = () => {
    emptyCart()
    window.scrollTo(0, 0)
  }

  const handleGoToCheckout = () => {
    router.push("/order-materials/checkout")
  }

  useEffect(() => {
    const loadBreadcrumbs = async () => {
      if (!homeLink || !back_to_product_list_url) {
        setBreadcrumbsData(null)
        return
      }
      try {
        const breacrumbsData = {
          data: {
            attributes: [
              {
                title: homeTitle,
                link: homeLink,
              },
              {
                title: listingPageTitle,
                link: back_to_product_list_url,
              },
              {
                title: cart_page_label,
              },
            ],
          },
        }
        const breadcrumbs = await getPageBreadcrumbs(breacrumbsData, props?.referer)
        setBreadcrumbsData(breadcrumbs)
      } catch (error) {
        console.log("breadcrumbs could not be fetched", error)
      }
    }

    loadBreadcrumbs()
  }, [])

  const renderBreadcrumbs = (
    <div>
      <BrandingPattern />
      <Breadcrumbs {...breadcrumbsData} />
    </div>
  )

  return (
    <>
      {breadcrumbsData && renderBreadcrumbs}
      <Container>
        <Row isTemplateWrapper>
          <div className="shopping-cart-list">
            <h1 className={productsInCart?.length > 0 ? "title bold" : "empty-cart-title bold"}>
              {title}
            </h1>
            {productsInCart?.length > 0 ? (
              <>
                <div>
                  {productsInCart.map((item: any) => (
                    <div key={item?.productId}>
                      <CartItem
                        item={item}
                        handleQuantity={(qty: number) => handleUpdateQuantity(qty, item)}
                        handleDeleteProduct={() => deleteProduct(item?.productId)}
                        quantityError={quantityErrorMessage}
                        quantityLabel={quantityLabel}
                      />
                    </div>
                  ))}
                </div>
                <div className="bottom-section">
                  <Icon iconName="ArrowLeftIcon" className="arrow-icon" />
                  <Anchor href={back_to_product_list_url} tabindex={0} noUnderline={false}>
                    <span className="text-with-arrow">{back_to_product_list_label}</span>
                  </Anchor>
                  <div className="button-container">
                    <Button
                      tabindex={0}
                      isSecondary
                      className="secondary-btn"
                      onClick={handleCancel}>
                      {cancel_label}
                    </Button>
                    <Button
                      tabindex={1}
                      type="submit"
                      className="primary-btn"
                      isDisabled={productsInCart.some((item: any) => !item.selectedQty)}
                      onClick={handleGoToCheckout}>
                      {checkout_cta_label}
                    </Button>
                  </div>
                  <div />
                </div>
              </>
            ) : (
              <div>
                <EmptyCart
                  empty_cart_title={empty_cart_title}
                  empty_cart_desc={empty_cart_desc}
                  back_to_product_list_label={back_to_product_list_label}
                  back_to_product_list_url={back_to_product_list_url}
                />
              </div>
            )}
          </div>
        </Row>
      </Container>
    </>
  )
}
const mapDispatch = {
  updateProductInCart: (data: any) => updateProductInCart(data),
  deleteProductFromCart: (productId: string) => deleteProductFromCart(productId),
  emptyCart: (data: any) => emptyCart(),
}
export default connect(null, mapDispatch)(ShoppingCartList)
