import { memo, useState } from "react"
import { v4 } from "uuid"
import { IAccordionFlexProps } from "./_accordionFlex.interface"

type AccordianState = {
  selectedIndex?: number
}

type Props = AccordianState & IAccordionFlexProps

const AccordionFlexEnhanced = (props: Props) => {
  const [isActive, setIsActive] = useState(true)
  const accordId = v4()
  return (
    <div className="accordion-flex">
      <div className="accordion" id={props.id ? props.id : `accord-${accordId}`}>
        {props.accordionArray?.map((item: IAccordionFlexProps, index: number) => {
          const uID = v4()
          return (
            item?.selectionCount != "(0)" && <div key={index} id={item?.referrerId} className="accordion-item">
              <h2 className="accordion-header" id={`accord-heading_${index + 1}_${uID}`}>
                <button
                  className={`accordion-button ${index !== props.selectedIndex ? "collapsed" : ""}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index + 1}_${uID}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index + 1}_${uID}`}
                  onClick={() => {
                    props?.resetState("reset")
                    setIsActive(!isActive)
                  }}>
                  <div className="cat-name-count">
                    <span className="cat-name">{item?.title}</span>
                    <span className="cat-selection">{item?.selectionCount}</span>
                  </div>
                </button>
              </h2>
              <div
                id={`collapse${index + 1}_${uID}`}
                className={`accordion-collapse collapse ${
                  index === props.selectedIndex ? "show" : ""
                }`}
                aria-labelledby={`accord-heading_${index + 1}_${uID}`}
                data-bs-parent={props.id ? `#${props.id}` : `#accord-${accordId}`}
                role="region">
                <div className="category-filter">{item?.categoryFilterBox}</div>
                {item?.unselectCat}
                <div className="accordion-body">{item?.content && item.content}</div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default memo(AccordionFlexEnhanced)
