export const getFileType = (url: URL) => { 
    const fileName = url.pathname.split("/").reverse()[0] 
    const fileType = fileName.split(".").reverse()[0] 
    return fileType 
}

const absoluteUrl = /^(data|http|https)?:/i

export const makeOptimizedUrl = (path: string, { ...opts }: any = {}) => {
    let imgUrl = path ?? "http://localhost:4000" 
    const { origin } = globalThis.location || {} 
    const isAbsolute = absoluteUrl.test(path) 
    if (!isAbsolute) { 
        imgUrl = `${origin}${path}` 
    }

    // const imgUrl = `http://localhost:3000/${path}` 

    const baseURL = new URL(imgUrl) 
    const params = new URLSearchParams(baseURL.search) 
    params.set("auto", "webp") 
    // Use the webp format if available 
    const imageFileType = getFileType(baseURL) 
    if (imageFileType === "png") { 
        params.set("format", "png") 
        // use png if webp is not available 
    } else { 
        params.set("format", "pjpg") 
        // Use progressive JPG if webp is not available 
    }

    Object.entries(opts).forEach(([key, value]) => { 
        if (value !== undefined && value !== null && key !== "breakpoint") { 
            // @ts-ignore 
            params.set(key, value) 
        } 
    })

    baseURL.search = params.toString() 
    if (baseURL.origin === origin) { 
        return baseURL.href.slice(baseURL.origin.length) 
    } 
    const imageUrl = `${baseURL.href} ${opts.breakpoint}w`
    return imageUrl
}