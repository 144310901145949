import { filterURL } from "."
import { getApi } from "../../utils/baseApi"
import { consoleError } from "../../utils/error"

const getBreadcrumbs = async (url: string, data?: any, referer?: any) => {
  if (!url && !data) throw consoleError(`URL parameter is undefined in ${getBreadcrumbs.name}.`)

  const breadcrumbsJson = data ?? (await getApi(`/jsonapi/solar/breadcrumb?path=${url}`))
  const breadcrumbsFiltered = breadcrumbsJson?.data?.attributes?.filter(
    (breadcrumb: { title: string; link: string }) => breadcrumb.link !== undefined,
  )

  const breadcrumbs = breadcrumbsFiltered?.map((breadcrumb: { title: string; link: string }) => ({
    title: breadcrumb.title ? breadcrumb.title : null,
    path: breadcrumb.link ? filterURL(breadcrumb.link) : null,
  }))

  const selectedArray = breadcrumbsJson?.data?.attributes?.filter(
    (breadcrumb: { title: string; link: string }) => breadcrumb.link === undefined,
  )

  const selectedTitle = selectedArray?.length > 0 ? selectedArray?.[0]?.title : null

    return {
    breadcrumbs: breadcrumbs ?? null,
    selectedTitle,
    referer: referer ?? null,
  }
}

export default getBreadcrumbs
