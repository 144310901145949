import { IButtonProps } from "./_button.interface"

const Button = ({
  isSecondary,
  icon,
  isGhost,
  className,
  type,
  isDisabled,
  onClick,
  children,
  onBlur,
  iconPosition,
  ariaLabel,
}: IButtonProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("btn")
    isSecondary ? classes.push("btn-secondary") : classes.push("btn-primary")
    icon && classes.push("btn-icon")
    isGhost && classes.push("btn-ghost")
    if (className) classes.push(className)
    return classes.join(" ")
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick && onClick(event)
  }
  const handleBlur = (event: React.FocusEvent<HTMLButtonElement>) => {
    onBlur && onBlur(event)
  }

  return (
    <button
      aria-label={ariaLabel}
      type={type ?? "button"}
      className={getClasses()}
      disabled={isDisabled}
      onBlur={handleBlur}
      onClick={handleClick}>
      {iconPosition != "right" ? icon && <span className="icon">{icon}</span> : null}
      {children}
      {iconPosition === "right" ? icon && <span className="icon icon-right">{icon}</span> : null}
    </button>
  )
}

export default Button
