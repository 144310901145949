import { AnchorButton, RichText, Taxonomy } from "@atoms"
import { IRelatedItemContentProps } from "./_relatedItemContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"
import moment from "moment"
import { getApiDomainAndLang } from "@utils/baseApi"

const RelatedItemContent = ({
  taxonomies,
  heading,
  time,
  text,
  link,
  buttonLabel,
  buttonIcon,
  linkExternal,
}: IRelatedItemContentProps) => {
  const renderTaxonomy = taxonomies ? <Taxonomy taxonomies={taxonomies} /> : null
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  return (
    <div className="related-item-content">
      {renderTaxonomy}
      <div className="related-item-content-bookmark">
        {heading && <h4 className="related-item-content-heading bold">{heading}</h4>}
      </div>
      <div className="related-item-content-time-section">
        <span>{moment(time).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}</span>
      </div>
      {text && <RichText className="related-item-content-content" content={text} />}
      {link && buttonLabel && (
        <div className="related-item-content-button">
          <AnchorButton
            href={link}
            icon={buttonIcon}
            isExternal={linkExternal}
            tabindex={0}
            isSecondary
            download={true}>
            {buttonLabel}
          </AnchorButton>
        </div>
      )}
    </div>
  )
}

export default RelatedItemContent
