import { FormEvent, useContext, useEffect, useMemo, useState } from "react"
import { Button, Column, Container, Img, RichText, Row } from "@atoms"
import { Notification, Tabs } from "@molecules"
import { LOGIN_TYPES } from "@helpers/constants/generic"
import { useRouter } from "next/router"
import { Paywallprops } from "./_paywall.interface"
import AuthForm from "../../molecules/authForm"
import { AuthFormProps } from "@molecules/authForm/_authForm.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import { getAssetPrefixUrl } from "@utils/helper"
import { EVENTS } from "@utils/gtmEvents"

type callBackType = {
  onSubmit: (e: any) => void
  showCredentialsError: boolean
  wrongCredentialsText: string
}
type Props = callBackType & Paywallprops

const Paywall = (props: Props) => {
  const {
    publicTeaserType,
    isOrganism,
    descriptionText,
    specificDescriptionText,
    registerBlock,
    loginBlock,
    form,
    isInvalidToken,
    tokenInvalidMessage,
    showCredentialsError,
    wrongCredentialsText,
  } = props

  const [isInvalidDocCheckEmail, setIsInvalidDocCheckEmail] = useState(false)
  const [isInvalidDocCheckPassword, setIsInvalidDocCheckPassword] = useState(false)
  const router = useRouter()
  const {  authForm } = loginBlock
  const { fields, submitButton, forgotPassword } = authForm
  const { navigationURL, registerDescription, registerButtonText } = registerBlock
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { isSandozIDEnabled = false } = applicationConfiguration
  const { handleLoginRedirection } = useSandozIDAuth(applicationConfiguration)

  const solarLoginForm = {
    ...form?.formLogin,
  }

  const docCheckForm = {
    ...form?.docCheckLoginForm,
  } as AuthFormProps

  useEffect(() => {
    const parentElement = document.getElementsByClassName("paywall")[0]?.parentElement
    if (parentElement && !isOrganism) {
      parentElement.style.position = "relative"
    }
  }, [])

  const selectedTab = useMemo(() => {
    let selectedItem = 0
    if (solarLoginForm.isLoginFormDisabled && docCheckForm.isLoginFormDisabled) {
      selectedItem = 0
    } else if (solarLoginForm.isLoginFormDisabled) {
      selectedItem = 0
    } else {
      selectedItem = 1
    }

    return {
      num: selectedItem,
    }
  }, [props])

  const handleOnForgotPassword = (url: any) => {
    window.location.assign(url)
  }

  const onSubmitLogin = (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    props.onSubmit(e)
  }

  const handleDocCheck = async (e: any) => {
    e.preventDefault()
    // setShowOverlay(true)
    if (router.asPath !== "/") {
      sessionStorage["source"] = router.asPath
    }

    // setTimeout(() => {
    //   setShowOverlay(false)
    // }, 10000)
    // @ts-ignore
    document?.paywall_login_form_doccheck.submit()
    
    // GTM
    /**
     * Stores a login event in sessionStorage.
     * This serializes the login event data and saves it under the key "loginEvent".
     */
    const trackLogInEvent = {
      login_system: LOGIN_TYPES.DOC_CHECK,
    }
    sessionStorage.setItem(
      "loginEvent", // Key under which the event data will be stored
      JSON.stringify({
        event: EVENTS.LOGIN, // The event type
        data: trackLogInEvent, // The login event data
      }),
    )
  }

  const handleChange = (e: any) => {
    e.preventDefault()
  }

  const loginContent = (
    <div>
      <Row className="paywall-cta">
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-register">
          <RichText className="paywall-cta-paragraph" content={registerDescription} />
          <div>
            <Button
              type="button"
              tabindex={0}
              onClick={() => {
                window.location.assign(navigationURL)
              }}>
              {registerButtonText}
            </Button>
          </div>
        </Column>
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-login">
          {/* <RichText className="paywall-cta-paragraph" content={loginDescription} /> */}
          <AuthForm
            isPaywallForm={true}
            fields={fields}
            submitButton={submitButton}
            forgotPassword={forgotPassword}
            onSubmit={onSubmitLogin}
            title={`<h2>${solarLoginForm?.title}<h2/>`}
            wrongCredentialsText={wrongCredentialsText}
            showCredentialsError={showCredentialsError}
            noValidate
            formType={LOGIN_TYPES.SOLAR}
            registerLink={solarLoginForm?.registerLink}
          />
        </Column>
      </Row>

      {isInvalidToken && <Notification notificationType="error" displayTxt={tokenInvalidMessage} />}
      <div />
    </div>
  )
  const docCheckContent = (
    <div>
      <Row className="paywall-cta">
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-login">
          {/* <RichText className="paywall-cta-paragraph" content={loginDescription} /> */}
          <AuthForm
            isPaywallForm={true}
            fields={docCheckForm?.fields}
            submitButton={submitButton}
            forgotPassword={docCheckForm?.forgotPassword}
            title={`<h2>${docCheckForm?.title}<h2/>`}
            wrongCredentialsText={wrongCredentialsText}
            showCredentialsError={showCredentialsError}
            onForgotPassword={handleOnForgotPassword}
            isInvalidPassword={isInvalidDocCheckPassword}
            isInvalidEmail={isInvalidDocCheckEmail}
            noValidate
            formType={LOGIN_TYPES.DOC_CHECK}
            registerLink={docCheckForm?.registerLink}
            onSubmit={handleDocCheck}
            onChange={handleChange}
          />
        </Column>
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-register">
          {/* <RichText className="paywall-cta-paragraph" content={registerDescription} /> */}
          <div className="paywall-dockcheck-image">
            <Img
              src={getAssetPrefixUrl("/assets/images/Vector.svg") as string}
              width={272}
              height={421}
              alt="Vector.svg"
              className="paywall-img"
            />
          </div>
        </Column>
      </Row>

      {isInvalidToken && <Notification notificationType="error" displayTxt={tokenInvalidMessage} />}
      <div />
    </div>
  )
  const sandozIdContent = (
    <div>
      <Row className="paywall-cta paywall-cta-sandozID">
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-register">
          <RichText className="paywall-cta-paragraph" content={registerDescription} />
          <div>
            <Button
              type="button"
              tabindex={0}
              onClick={() => {
                window.location.assign(navigationURL)
              }}>
              {registerButtonText}
            </Button>
          </div>
        </Column>
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-login">
          {/* To be updated with configurable content */}
          <RichText
            className="paywall-cta-paragraph"
            content="<p>Please login to access this page content</p>"
          />
          <div>
            <Button
              type="button"
              tabindex={0}
              onClick={() => {
                handleLoginRedirection()
              }}>
              Login
            </Button>
          </div>
        </Column>
      </Row>

      {isInvalidToken && <Notification notificationType="error" displayTxt={tokenInvalidMessage} />}
    </div>
  )

  const loginTabs = [
    {
      isEnabled: solarLoginForm.isLoginFormDisabled,
      tabName: solarLoginForm?.title,
      itemID: solarLoginForm?.title,
      active: true,
    },
    {
      isEnabled: docCheckForm.isLoginFormDisabled,
      tabName: docCheckForm?.title,
      itemID: docCheckForm?.title,
      active: false,
    },
  ]
  const tabHeadItems = form
    ? loginTabs.map((item: any) =>
        item.isEnabled
          ? {
              isFormDisabled: item.isEnabled,
              children: `${item.tabName}`,
              itemID: item.tabName,
              tabDesign: "wide",
            }
          : {
              isFormDisabled: item.isEnabled,
              children: `${item.tabName}`,
              itemID: null,
              tabDesign: "wide",
            },
      )
    : null
  const tabContents = loginTabs.map((item, index) => {
    if (!item.isEnabled) {
      return <></>
    }

    switch (item.tabName) {
      case docCheckForm.title:
        return docCheckContent
      case solarLoginForm.title:
        return loginContent
      default:
        return <div key={index}>{item.tabName}</div>
    }
  })

  const getLoginContent = () => {
    if (isSandozIDEnabled) {
      return sandozIdContent
    }
    if (tabHeadItems) {
      return (
        <Tabs
          activeItem={selectedTab}
          tabHeadItems={tabHeadItems}
          tabContents={tabContents}
          tabHeadClassName="tabHeadClass"
        />
      )
    }
    return <></>
  }

  return (
    <Container>
      <div
        className={`paywall ${
          publicTeaserType === "enable" ? "paywall-enable" : "paywall-disable"
        }`}>
        <Container narrow className="paywall-publicTeaser">
          {specificDescriptionText ? (
            <>
              <RichText content={specificDescriptionText} /> <hr />{" "}
            </>
          ) : (
            <></>
          )}
          <RichText content={descriptionText} />
        </Container>
        <Container background="grey">{getLoginContent()}</Container>
      </div>
    </Container>
  )
}
export default Paywall
