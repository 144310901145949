import getConfigDisplayData from "./getConfigDisplayData"

const renderEventsFilterConfig = async (serverData?: any) => {
  let eventConfigData = await getConfigDisplayData(serverData)
  eventConfigData = eventConfigData?.data
  return {
    other_category_text: eventConfigData?.event_other_category_text ?? null,
    all_category_text: eventConfigData?.event_all_category_text ?? null,
    from_text: eventConfigData?.event_from_text ?? null,
    search_cta_text: eventConfigData?.event_search_cta_text ?? null,
    search_placeholder_text: eventConfigData?.event_search_placeholder_text ?? null,
    search_label: eventConfigData?.event_what_are_you_looking ?? null,
    search_clear_all_text: eventConfigData?.event_search_clear_all_text ?? null,
    search_results_found: eventConfigData?.event_search_results_found ?? null,
    no_results_found_title: eventConfigData?.events_no_results_found_title ?? null,
    noResultsFoundDescription: eventConfigData?.event_no_results_found_body?.value,
    event_title: eventConfigData?.event_title_text ?? null,
    unSelectItemsLabel: eventConfigData?.search_unselect_items ?? null,
    applyAllfilters: eventConfigData?.event_apply_all_filters ?? null,
    itemsPerPage: eventConfigData?.event_count_for_each_page ?? null,
    dropwdown_placeholder_text: eventConfigData?.event_select_option_text ?? null,
    events_search_default_config: eventConfigData?.events_search_default_config ?? null,
    events_search_facets: eventConfigData?.events_search_facets ?? [],
    event_label_event_type: eventConfigData?.event_label_event_type ?? null,
    event_label_disease_area: eventConfigData?.event_label_disease_area ?? null,
    event_label_treatment_area: eventConfigData?.event_label_treatment_area ?? null,
    event_label_profession: eventConfigData?.event_label_profession ?? null,
    event_label_audience: eventConfigData?.event_label_audience ?? null,
    filter_toggle_label: eventConfigData?.filter_toggle_label ?? null,
  }
}

export default renderEventsFilterConfig
