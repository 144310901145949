import { Column, Img } from "@atoms"
import getDirection from "@utils/direction"
import MaterialTeaserCardContent from "../../molecules/materialTeaserCardContent"
import { IMaterialTeaserCardProps } from "./_materialTeaserCard.interface"

const MaterialTeaserCard = (props: IMaterialTeaserCardProps) => {
  const { materialTeaserCardImage } = props
  const { materialTeaserCardContent } = props
  return (
    <Column desktop={4} tablet={6} mobile={12} className="material-teaser-card">
      <div className="card" dir={getDirection()}>
        {materialTeaserCardImage && (
          <Img
            className="material-teaser-card-image"
            type="article_teaser_support_material"
            styledimage={materialTeaserCardImage?.styledimage}
            src={materialTeaserCardImage.src}
            alt={materialTeaserCardImage.alt}
            width={368}
            height={207}
            islazyloaded={false}
          />
        )}
        <div className="card-body">
          <MaterialTeaserCardContent {...materialTeaserCardContent} />
        </div>
      </div>
    </Column>
  )
}
export default MaterialTeaserCard
