import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { useCallback } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import {
 AnchorButton, Column, RichText, Row,
} from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IServiceTeaserCardContentProps } from "./_serviceTeaserCardContent.interface"
import BookmarkWithButton from "../bookmarkWithButton"

const ServiceTeaserCardContent = ({
  id,
  heading,
  bookmark,
  text,
  link,
  isLinkExternal,
  buttonLabel,
}: IServiceTeaserCardContentProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const handleBookmark = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  return (
    <div className="service-teaser-card-content">
      <Row className="service-teaser-card-content-row">
        <Column desktop={10} tablet={10} mobile={10}>
          {heading && <h4 className="service-teaser-card-content-header bold">{trimText(heading, CHARLIMIT.TITLE)}</h4>}
        </Column>
        <Column desktop={2} tablet={2} mobile={2} className="service-teaser-card-content-icon">
          <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={handleBookmark} />
        </Column>
      </Row>
      {text && <RichText className="service-teaser-card-content-text" content={text} characterLimit={CHARLIMIT.DESCRIPTION} />}
      <div className="service-teaser-card-content-button">
        {link && (
          <AnchorButton href={link} isExternal={isLinkExternal} tabindex={2} isSecondary>
            {buttonLabel}
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

export default ServiceTeaserCardContent
