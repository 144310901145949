import React, { useEffect, useState } from "react"
import NUMBERS from "@helpers/constants/numbers"
import { IloginTimeoutProps } from "./_loginTimeout.interface"

const LoginTimeoutModalContent = ({
  headContent,
  bottomContent,
  countdownTime,
  titleContent,
  pageTitle,
}: IloginTimeoutProps) => {
  const countdownTimeSafe = countdownTime || 1
  const popup_countdown_time = ((countdownTimeSafe >= 0 ? countdownTimeSafe : 1) * 60)
  const [timeLeft, setTimeLeft] = useState(popup_countdown_time - 1)
  useEffect(() => {
     if (timeLeft <= 0) return
    const intervalId = setInterval(() => {
     setTimeLeft(timeLeft - 1)
      switch (timeLeft % 2) {
        case 0:
          document.title = `${titleContent} ${Math.floor(timeLeft / 60)}:${timeLeft % 60 < 10 ? "0" : ""}${timeLeft % 60}`
          break
          default:
          document.title = pageTitle || ""
          break
      }
    }, NUMBERS.THOUSAND)

   return () => clearInterval(intervalId)
  }, [timeLeft])

  return (
    <div className="login-timeout">
      <div className="login-timeout-content">{headContent}</div>
      <div className="login-timeout-timer">
        {Math.floor(timeLeft / 60)}
        :
        {timeLeft % 60 < 10 ? "0" : ""}
        {timeLeft % 60}
      </div>
      <div className="login-timeout-content">{bottomContent}</div>
    </div>
  )
}

export default LoginTimeoutModalContent
