import { Anchor, Column, Img } from "@atoms"
import EventCardVerticalContent from "../../molecules/eventCardVerticalContent"
import { IEventCardVerticalProps } from "./_eventCardVertical.interface"
import { modifyRelatedContentData } from "@utils/relatedContentUtil"

const EventCardVertical = (props: IEventCardVerticalProps) => {
   const eventCardVerticalContentData = props?.relatedContentFlag
     ? modifyRelatedContentData(props.eventCardVerticalContent)
     : props.eventCardVerticalContent
  const { eventCardVerticalImage } = props
  return (
    <Column
      desktop={props.eventCardVDesktopColumn}
      tablet={
        props.eventCardVTabletColumn ? props.eventCardVTabletColumn : props.eventCardVDesktopColumn
      }
      mobile={props.eventCardVMobileColumn ? props.eventCardVMobileColumn : 12}
      className="event-card-vertical">
      <div className="card">
        {eventCardVerticalImage?.src && (
          <div className="image-section">
            <Anchor
              href={props?.eventCardVerticalContent.primaryButtonUrl}
              noUnderline
              tabindex={0}
              isExternal={props?.eventCardVerticalContent.primaryIsExternalUrl}>
              <Img
                className="event-card-vertical-image"
                type="card"
                styledimage={eventCardVerticalImage?.styledimage}
                src={eventCardVerticalImage?.src}
                alt={eventCardVerticalImage?.alt}
                width={368}
                height={220}
                islazyloaded={!props?.relatedContentFlag ?? false}
              />
            </Anchor>
          </div>
        )}
        <div className="card-body">
          <EventCardVerticalContent {...eventCardVerticalContentData} />
        </div>
      </div>
    </Column>
  )
}

export default EventCardVertical
