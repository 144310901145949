import { useEffect, useState } from "react"
import { thirdPartyGETAPI } from "@utils/baseApi"
import { xml2json } from "xml-js"
import getNestedObject from "@utils/nestedObjects"
import { filterByReference } from "@utils/dataModifier"
import { Column } from "@atoms"
import { CompactStockCard } from "@molecules"
import Loader from "@atoms/loader"
import { ICST, IDatum } from "./_compactStockTicker.interface"

const CompactStockTicker = ({ fieldCstKeyAttrValues, cstXMLLink }: ICST) => {
  const [data, setData] = useState<IDatum[]>([])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    const getXMLData = async () => {
      setLoading(true)
      try {
        const xmlData = await thirdPartyGETAPI(cstXMLLink)
        const xmlJsonData = JSON.parse(xml2json(xmlData, { spaces: 2, compact: true }))
        const shareInfoData = getNestedObject(xmlJsonData, "docs.shareinfo.share") || []
        const shareData = Array.isArray(shareInfoData) ? shareInfoData : [shareInfoData]
        setData(filterByReference(shareData, fieldCstKeyAttrValues))
        setLoading(false)
      } catch (err) {
        console.error(err)
        setLoading(false)
      }
    }

    getXMLData()
  }, [])

  const getCSTView = () => {
    return (
      <div className="row compact-stock-ticker">
        {data.map((item: IDatum, index: number) => {
          return (
            <Column
              mobile={12}
              tablet={12}
              desktop={6}
              className="d-flex justify-content-center"
              key={index}>
              <CompactStockCard cardData={item} />
            </Column>
          )
        })}
      </div>
    )
  }

  return (
    <div>
      {isLoading ? (
        <div className="load-container">
          <Loader display={isLoading} />
        </div>
      ) : (
        getCSTView()
      )}
    </div>
  )
}

export default CompactStockTicker
