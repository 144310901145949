import { useRef, useState } from "react"
import { ITooltipProps } from "./_tooltip.interface"
import Icon from "@atoms/icon"


const Tooltip = ({tooltipContent}: ITooltipProps) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tooltipRef = useRef(null)
  const handleMouseEnter = () => {
    setTooltipVisible(true);
  };
  const handleMouseLeave = () => {
    setTooltipVisible(false);
  };

    return(
        <div className="tooltip-trigger" ref={tooltipRef}>
            <div onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave} 
                 >
            <Icon iconName="QuestionmarkIcon" /> 
                {tooltipVisible && (
                    <div className="tooltip-wrapper">
                        <span>{tooltipContent}</span>
                    </div>
                )}
            </div>
        </div>
    )
}
export default Tooltip
