import { Icon } from "@atoms"
import { IContentTypeProps } from "./_contentType.interface"
import IconWithLabel from "../iconWithLabel"

const ContentType = (props: IContentTypeProps) => (
  <div className="education-card-content-podcast">
    {props?.contentType?.toLowerCase() === "podcast" && (
      <IconWithLabel icon={<Icon iconName="PodcastIcon" />}>{props?.contentType}</IconWithLabel>
    )}
    {props?.contentType?.toLowerCase() === "article" && (
      <IconWithLabel icon={<Icon iconName="ResearchIcon" />}>{props?.contentType}</IconWithLabel>
    )}
    {props?.contentType?.toLowerCase() === "research" && (
      <IconWithLabel icon={<Icon iconName="ResearchIcon" />}>{props?.contentType}</IconWithLabel>
    )}
  </div>
)

export default ContentType
