import {RichText, Container} from "@atoms"
import { IEventsTableProps } from "./eventsTable.interface"

const EventsTable = (props: IEventsTableProps) => {
  const { rows } = props
  return (
    <Container fluid className="events-table">
      <>
        {rows.map((row) => (
          <div key={row.title} className="events-table-row">
            <div className="events-table-titles">
              <h4>{row.title}</h4>
              <RichText content={row.subTitle} />
            </div>
            {row.colums.map((column, i) => (
              <RichText className="events-table-column" key={i} content={column} />
            ))}
          </div>
        ))}
      </>
    </Container>
  )
}

export default EventsTable
