import { solarImageConfig, solarImageWidth } from "@utils/imageHelper/imageConfig"
import { useMemo } from "react"

export const useResourceImage = (props: any) => {
    const {
        generateUrl,
        type,
        resource,
    } = props

    const configureImageProperties = (sourceName: string, breakpoint?: Array<number>) => {
        const url = resource[sourceName]
        const url2x = resource[`${sourceName}_2x`]

        const widthAndHeight = Array.from(
            Object.entries(solarImageWidth).filter(([key, value]: any) => key === sourceName),
            ([key, value]) => value
        )?.[0]

        return {
            category: sourceName,
            urls: [url, url2x],
            widthAndHeight,
            breakpoint
        }
    }

    const getSelectedImage = (isSourceUrl: boolean): any => {
        const source = Object.entries(solarImageConfig)
            .filter(([key]: any) => key === type)
            .map(([key, value]: any) => {
                if (isSourceUrl) {
                    return {
                        url: value.imageSource,
                        media: value.media,
                        breakpoint: value?.breakPoints
                    }
                }

                return {
                    url: value.imageSource[value.imageSource?.length - 1],
                    media: value.media,
                    breakpoint: value?.breakPoints[value.imageSource?.length - 1]
                }
            })?.[0]

        if (source) {
            if (isSourceUrl) {
                return (source.url as Array<string>).map((imgName: string, index: number) => {
                    if (index !== source.url.length - 1) {
                        const imageProperties = configureImageProperties(imgName)
                        return {
                            ...imageProperties,
                            media: source.media[index],
                            breakpoint: source.breakpoint[index]
                        }
                    }
                })
    
            } else {
                return configureImageProperties(source.url as string, source.breakpoint)
            }
        }
    }

    const source = useMemo(() => {
        const {
            urls,
            widthAndHeight,
            breakpoint
        } = getSelectedImage(false)

        const srcset = urls.map((img: string, index: number) => {
            if (img) {
                const w = img?.includes("2x") ? widthAndHeight[0]*2 : widthAndHeight[0]
                const h = img?.includes("2x") ? widthAndHeight[1]*2 : widthAndHeight[1]

                const breakPointWidth = breakpoint[index]
                return generateUrl(img)(w, h, breakPointWidth)
            }
        })

        return {
            src: srcset[0],
            srcset: srcset.join(", "),
        }
    }, [resource])

    const sourceImage = useMemo(() => {
        const sourceURL = []
        const generateSource: Array<any> = getSelectedImage(true)
        for (const source of generateSource) {
            if (source) {
                const srcset = source?.urls?.map((img: string, index: number) => {
                    if (img) {
                        const w = img.includes("2x") ? source?.widthAndHeight[0]*2 : source?.widthAndHeight[0]
                        const h = img.includes("2x") ? source?.widthAndHeight[1]*2 : source?.widthAndHeight[1]

                        const breakPointWidth = source.breakpoint[index]
                        return generateUrl(img)(w, h, breakPointWidth)
                    }
                })
    
                sourceURL.push({
                    url: srcset?.join(", "),
                    media: `(min-width: ${source?.media}px)`
                })
            }
        }

        return sourceURL
    }, [resource])

    return {
        src: source.src,
        srcset: source.srcset,
        sourceImage
    }
}