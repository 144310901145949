import { useState } from "react"
import Icon from "@atoms/icon"
import { IFavouriteProps } from "./_favourite.interface"
import { useSelector } from "react-redux"

type FavoriteHandler = {
  favoriteHandlerCallback: (state: any) => void
}

type Props = FavoriteHandler & IFavouriteProps

const FavoriteIcon = (props: Props) => {
  const user: any = useSelector((state: UserState) => state.user)
  const [favouriteState, setFavouriteState] = useState(props.favouriteStatus)

  const getClasses = () => {
    const classes = []
    classes.push("favourite-icon")
    if (props.className) {
      classes.push(props.className)
    }
    return classes.join(" ")
  }

  const favouriteHandler = () => {
    if (favouriteState) {
      setFavouriteState(false)

      props.favoriteHandlerCallback({
        type: "favourite",
        isChecked: true,
      })
    } else {
      if (user && user.isLoggedIn) {
        setFavouriteState(true)
      }
      props.favoriteHandlerCallback({
        type: "favourite",
        isChecked: false,
      })
    }
  }

  return (
    <span className={getClasses()} onClick={favouriteHandler}>
      {favouriteState ? (
        // @ts-ignore
        <Icon iconName="FavouriteIconfilled" favouriteStatus={true} />
      ) : (
        // @ts-ignore
        <Icon iconName="FavouriteIcon" favouriteStatus={false} />
      )}
      <span>{props.totalFavoriteCount !== 0 && props.totalFavoriteCount}</span>
    </span>
  )
}

export default FavoriteIcon
