import React, { useState } from "react"
import { ITimePickerProps } from "./timePicker.interface"
import moment from "moment"

const TimePicker = ({
  className,
  id,
  inputLabel,
  isDisabled,
  name,
  onChange,
}: ITimePickerProps) => {
  const getClasses = () => {
    const classes = [
      "input-time",
      "input-box-with-label",
      className,
      isDisabled ? "input-time-disabled" : "",
    ].filter(Boolean)
    return classes.join(" ")
  }
  const [selectedTime, setSelectedTime] = useState<string>("")

  const handleTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = event.target.value ?? ""
    let formattedTime = ""

    try {
      const parsedTime = moment(newTime, "HH:mm:ss")
      if (parsedTime.isValid()) {
        formattedTime = parsedTime.format("hh:mm:ss A")
        setSelectedTime(newTime)
        onChange && onChange(formattedTime)
      } else {
        console.error("Invalid time format")
      }
    } catch (error) {
      console.error("Error parsing time:", error)
    }
  }

  return (
    <div className={getClasses()}>
      <label className="inputWith-label" htmlFor="timePicker">
        {inputLabel}
      </label>
      <input
        className="input-time-format"
        type="time"
        id={id}
        name={name}
        value={selectedTime}
        onChange={handleTimeChange}
        step={2}
        data-testid="time-input"
      />
    </div>
  )
}

export default TimePicker
