import { RichText } from "@atoms"
import { ILocationGroupData } from "@helpers/dataFunctions/_api.interface"
import groupBy from "@utils/dataModifier"
import React from "react"
import { ICityGroupData, ILocationAddressBlock } from "./_locationAddressBlock.interface"

const LocationAddressBlock = (props: ILocationAddressBlock) => {
  const { addressData, addressLabels } = props

  const groupedData = groupBy<ILocationGroupData>(addressData, "city")
  const groupedDataKeys = Object.keys(groupedData)
  const searchResultsArr: ICityGroupData[] = []
  groupedDataKeys.forEach((key) => {
    const obj = {} as ICityGroupData
    obj.category = key
    obj.results = groupedData[key]
    searchResultsArr.push(obj)
  })

  return (
    <div className="location-address-block">
      {searchResultsArr?.map((item: ICityGroupData, i: number) => (
        <div className="location-address" key={i}>
          <h2>{item.category}</h2>
          <div className="location-address-card">
            {item.results?.map((result: ILocationGroupData, index: number) => (
              <div key={index}>
                <h2 className="location-address-card-title">{result?.fieldOfficeTitle}</h2>
                <RichText className="location-address-detail" content={result?.address} />
                {result?.fieldOfficePhoneNumber && (
                  <p className="location-address-contact">
                    <span>
                      {`${addressLabels.phone}: `}
                    </span>
                    <span className="location-address-contact-number">
                      {" "}
                      {result?.fieldOfficePhoneNumber}
                    </span>
                  </p>
                )}
                {result?.fieldOfficeFax && (
                  <p className="location-address-contact">
                    <span>{`${addressLabels.fax}: `}</span>
                    <span className="location-address-contact-number">{result?.fieldOfficeFax}</span>
                  </p>
                )}
                {result.fieldOfficeWebsite && <RichText content={result?.fieldOfficeWebsite} />}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LocationAddressBlock
