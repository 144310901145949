export const solarImageConfig = {
    hero_banner: {
        imageSource: ["hero", "1_1_large", "4_3_small"],
        media: [991, 767],
        breakPoints: [[1440, 2880], [960, 1920], [380, 760]]
    },
    rotating_hero_banner: {
        imageSource: ["hero", "1_1_large", "4_3_small"],
        media: [991, 767],
        breakPoints: [[1440, 2880], [960, 1920], [380, 760]]
    },
    accordion_with_image: {
        imageSource: ["16_9_small", "16_9_small"],
        media: [744],
        breakPoints: [[640, 1280], [640, 1280]]
    },
    testimonials: {
        imageSource: ["16_9_small", "16_9_small", "16_9_small"],
        media: [744, 320],
        breakPoints: [[640, 1280], [640, 1280], [640, 1280]]
    },
    product_catalogue: {
        imageSource: ["1_1_small", "1_1_small", "1_1_small"],
        media: [744, 320],
        breakPoints: [[320, 640], [320, 640], [320, 640]]
    },
    article_Teaser_product_range_plp: {
        imageSource: ["1_1_small", "1_1_small", "1_1_small"],
        media: [744, 320],
        breakPoints: [[320, 640], [320, 640], [320, 640]]
    },
    therapy_details: {
        imageSource: ["16_9_medium", "16_9_small", "16_9_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [640, 1280], [640, 1280]]
    },
    pdp: {
        imageSource: ["16_9_medium", "16_9_small", "16_9_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [640, 1280], [640, 1280]]
    },
    news_details: {
        imageSource: ["16_9_medium", "16_9_medium", "16_9_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [960, 1920], [640, 1280]]
    },
    teaser_block_carousel: {
        imageSource: ["16_9_medium", "16_9_small", "16_9_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [640, 1280], [640, 1280]]
    },
    media_details_page: {
        imageSource: ["16_9_medium", "16_9_small", "16_9_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [640, 1280], [640, 1280]]
    },
    article_teaser_promote_product_or_service: {
        imageSource: ["teaser_wide", "hero", "1_1_medium"],
        media: [744, 320],
        breakPoints: [[1440, 2880], [1440, 2880], [480, 960]]
    },
    article_teaser_quiz: {
        imageSource: ["teaser_wide", "hero", "1_1_medium"],
        media: [744, 320],
        breakPoints: [[1440, 2880], [1440, 2880], [480, 960]]
    },
    article_teaser_therapy_areas: {
        imageSource: ["teaser_wide", "hero", "1_1_medium"],
        media: [744, 320],
        breakPoints: [[1440, 2880], [1440, 2880], [480, 960]]
    },
    card: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    article_teaser_support_material: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    article_teaser_services_teaser: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    article_teaser_find_interesting: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    image_block: {
        imageSource: ["16_9_large"],
        media: [744, 320],
        breakPoints: [[1920, 3840]]
    },
    article_teaser_latest_articles: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    article_teaser_latest_articles_Fullwidth: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[960, 1920], [380, 760], [380, 760]]
    },
    plp_list: {
        imageSource: ["1_1_small", "1_1_small", "1_1_small"],
        media: [744, 320],
        breakPoints: [[320, 640], [320, 640], [320, 640]]
    },
    job_details_page_right_side_panel_image: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    news_filter_page_banner_card: {
        imageSource: ["4_3_medium", "4_3_medium", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    news_filter_page_small_card: {
        imageSource: ["4_3_small", "4_3_medium", "4_3_small"],
        media: [744, 320],
        breakPoints: [[680, 1360], [680, 1360], [380, 760]]
    },
    table_enhancement: {
        imageSource: ["4_3_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[380, 760], [380, 760], [380, 760]]
    },
    magazine_one_column: {
      imageSource: ["hero", "4_3_medium", "4_3_small"],
      media: [744, 320],
      breakPoints: [[960, 1920], [680, 1360], [380, 760]]
    },
    magazine_two_column: {
        imageSource: ["16_9_small", "4_3_small", "4_3_small"],
        media: [744, 320],
        breakPoints: [[640, 1280], [380, 760], [380, 760]]
    },
}

export const solarImageWidth = {
    "16_9_large": [1920, 1080],
    "16_9_medium": [960, 540],
    "16_9_small": [640, 360],
    "1_1_large": [960, 960],
    "1_1_medium": [480, 480],
    "1_1_small": [320, 320],
    hero: [1440, 540],
    teaser_wide: [1440, 350],
    "4_3_large": [960, 720],
    "4_3_medium": [680, 510],
    "4_3_small": [380, 285]
}
