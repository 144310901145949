import {Button} from "@atoms"
import { IModalProps } from "./_localStorageStateModal.interface"
import { setItemInLocalStorage, getItemFromLocalStorage } from "../../../utils/localstorage"
import ButtonGroup from "../../molecules/button-group"
import ModalPortal from "../../molecules/modalPortal"

const LocalStorageStateModal = (props: IModalProps) => {
  const { title, content, yesButton, noButton, localStorageKey, yesPath, noPath } = props

  if (getItemFromLocalStorage(localStorageKey)) return null

  const modalContent = (
    <div className="modal-content-wrapper">
      <h4>{title}</h4>
      <div className="modal-text">
        {content.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
      <div className="modal-buttons-container">
        <ButtonGroup className="modal-buttons-group">
          <Button tabindex={1} isSecondary onClick={() => handleNoBtton(localStorageKey, noPath)}>
            {noButton}
          </Button>
          <Button tabindex={2} onClick={() => handleYesButton(localStorageKey, yesPath)}>
            {yesButton}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )

  return <ModalPortal modalContent={modalContent} wrapperId="react-portal-wrapper" />
}

const handleYesButton = (localStorageKey: string, path: string) => {
  location.href = path
  setItemInLocalStorage(localStorageKey, "true")
}
const handleNoBtton = (localStorageKey: string, path: string) => {
  location.href = path
  setItemInLocalStorage(localStorageKey, "false")
}

export default LocalStorageStateModal
