import { IButtonProps } from "./_iconButton.interface"

const IconButton = (props: IButtonProps) => {
  const getClasses = () => {
    const classes = ["octagon", "inner"]
    !props.isSecondary && classes.push("btn btn-light")
    props.isSecondary && classes.push("btn btn-primary")
    props.icon && classes.push("btn-icon-circular")
    props.isGhost && classes.push("btn-ghost")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <div className="octagon-container">
      <div className="octagon outer" />
      <button
        className={getClasses()}
        disabled={props.isDisabled}
        onClick={props.onClick}
        aria-label={props.ariaLabel}
        title={props.title}>
        {props.icon}
      </button>
    </div>
  )
}

export default IconButton
