import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getImageMeta } from "."

const getPatientProfileData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let responce = await getBlockData(url, false, serverData)
  responce = responce?.data
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(responce, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(responce)
  const imagePaywallData = isPaywallProtected(responce?.relationships.field_image_persona)
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(responce?.relationships.field_image_persona)

  const readMoreContent = {
    title: isPaywallProtected(responce?.attributes?.field_text_block_title).isPaywallProtectedFlag
      ? null
      : responce?.attributes?.field_text_block_title ?? "",
    description: responce?.attributes?.field_text_block_2?.value ?? "",
    showMoreButtonLabel: isPaywallProtected(responce?.attributes?.field_show_more_cta)
      .isPaywallProtectedFlag
      ? null
      : responce?.attributes?.field_show_more_cta ?? "",
    showLessButtomLabel: isPaywallProtected(responce?.attributes?.field_show_less_cta)
      .isPaywallProtectedFlag
      ? null
      : responce?.attributes?.field_show_less_cta ?? "",
  }

  return {
    titleDetails: {
      title: responce?.attributes?.field_name?.value ?? "",
      subTitle: "*hypothetical case",
      paragraph: responce?.attributes?.body?.value ?? "",
    },
    personalDetails: {
      nameLabel: responce?.attributes?.field_name?.value,
      ageLabel: responce?.attributes?.field_age?.value,
      medicationLabel: responce?.attributes?.field_medication?.value,
      diseaseLabel: responce?.attributes?.field_disease?.value,
    },
    readMoreContent,
    styledimage: imageStyledUrl ?? null,
    desktopImage: {
      src: imageUrl,
      alt,
      width: 468,
      height: 351,
    },
    mobileImage: {
      src: imageUrl,
      alt,
      width: 326,
      height: 246,
    },
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getPatientProfileData
