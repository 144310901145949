import { useState } from "react"
import useMediaQuery from "@helpers/hooks/useMediaQuery"
import { Pagination, Tabs, TitleDescription } from "@molecules"
import { IMediaInterface } from "@organisms/mediaLibrarySearch/_mediaLibrarySearch.interface"
import MediaCard from "@organisms/mediaLibraryCard"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import NUMBERS from "@helpers/constants/numbers"
import getDirection from "@utils/direction"

const MediaLibrarySearchResult = ({
  searchResults = [],
  searchedPhrase,
  itemsPerPage,
  allCardsData,
  mediaLibConfig,
  handleSort,
  trigger,
}: IMediaInterface) => {
  const { GRID_CONFIGURATOR_TEXT, GRID_VIEW, LIST_VIEW } = mediaLibraryConstants
  const defaultView = mediaLibConfig.default_view === GRID_CONFIGURATOR_TEXT ? GRID_VIEW : LIST_VIEW
  const [paginatePages, setPaginatePages] = useState({ start: 0, end: itemsPerPage })
  const [mediaLibraryView, setMediaLibraryView] = useState(defaultView)
  const [resetOffset, setRestOffset] = useState(false)
  const isMobile = useMediaQuery("(max-width: 991px)")

  const viewType = isMobile ? "grid" : mediaLibraryView

  const mediaLabels = {
    old_to_new_text: mediaLibConfig?.old_to_new_text,
    new_to_old_text: mediaLibConfig?.new_to_old_text,
    default_sort: mediaLibConfig?.default_media_sort,
    sort_cta_text: mediaLibConfig?.sort_cta_text,
    list_view_text: mediaLibConfig?.list_view_text,
    grid_view_text: mediaLibConfig?.grid_view_text,
  }

  const tabHeadItems = [
    {
      children: getDirection() === "rtl" ? `(${allCardsData.length}) ${mediaLibConfig.all_category_text}` : `${mediaLibConfig.all_category_text} (${allCardsData.length})`,
      itemID: `${mediaLibConfig.all_category_text}`,
      tabDesign: "wide",
    },
  ].concat(
    searchResults.map((item) => ({
      children: getDirection() === "rtl" ? `(${item.results.length}) ${item.category}` : `${item.category} (${item.results.length})`,
      itemID: item.category,
      tabDesign: "wide",
    })),
  )

  const formatMatchString = () => {
    const matchObj = {
      count_val: allCardsData.length.toString(),
      search_txt: "",
    }
    return mediaLibConfig?.search_results_found
      ?.replaceAll("@", "")
      .replace(/count_val|search_txt/gi, (matched) => matchObj[matched as keyof typeof matchObj])
  }

  const getPagination = (
    itemsRestriction: number,
    resultLength: number,
    paginationHandler: (offSet: number) => void,
    tabName: string,
    index: number,
  ) => (
    <Pagination
      itemsPerPage={itemsRestriction}
      itemsCount={resultLength}
      onClick={paginationHandler}
      resetOffset={resetOffset}
      className={`${tabName}-${index}`}
    />
  )

  const saveIdHandler = () => {
    const changedView = mediaLibraryView === GRID_VIEW ? LIST_VIEW : GRID_VIEW
    setMediaLibraryView(changedView)
    return changedView
  }

  const handlePagination = (offSet: number) => {
    if (offSet === 0) {
      setRestOffset(true)
    } else if (offSet !== 0 && resetOffset) {
      setRestOffset(false)
    }
    setPaginatePages({ start: offSet, end: offSet + itemsPerPage })
  }

  const getAllCardsView = () => (
    <div key={0}>
      <div className={`mediaLibrarySearchResult__searchResultContainer__${viewType}`}>
        {allCardsData.slice(paginatePages.start, paginatePages.end).map((item, index) => (
          <MediaCard key={index} {...item} view={viewType} mediaLibConfig={mediaLibConfig} />
        ))}
      </div>
      {allCardsData.length > itemsPerPage
        && getPagination(itemsPerPage, allCardsData.length, handlePagination, "All", NUMBERS.ZERO)}
    </div>
  )

  const getSearchResultsView = () =>
    searchResults.map((item, index) => (
      <div key={index + 1} className="mediaLibrarySearchResult__resultsCategoryContainer">
        <div key={index} className={`mediaLibrarySearchResult__searchResultContainer__${viewType}`}>
          {item.results.slice(paginatePages.start, paginatePages.end).map((result: any, index: number) => (
            <MediaCard key={index} view={viewType} mediaLibConfig={mediaLibConfig} {...result} />
          ))}
        </div>
        {item.results.length > itemsPerPage
          && getPagination(
            itemsPerPage,
            item.results.length,
            handlePagination,
            item.category,
            index + NUMBERS.ONE,
          )}
      </div>
    ))

  const tabContents = [getAllCardsView()].concat(getSearchResultsView())

  return (
    <div className="mediaLibrarySearchResult__container scroll-top">
      {searchedPhrase && allCardsData.length > NUMBERS.ZERO && (
        <TitleDescription
          title={formatMatchString()}
          description=""
          searchKey={searchedPhrase}
          className="mediaLibrarySearchResult__header"
        />
      )}

      <Tabs
        tabHeadClassName="mediaLibrarySearchResult__tabsContainer"
        tabHeadItems={tabHeadItems}
        tabContents={tabContents}
        isMobileDisplay={!!isMobile}
        onSaveIdHandler={saveIdHandler}
        view={mediaLibraryView}
        handleSort={handleSort}
        label={mediaLabels}
        trigger={trigger}
        hideViewTypeCTA={!mediaLibConfig.show_view_cta}
        hideSortCTA={!mediaLibConfig.show_sort_cta}
        handlePagination={handlePagination}
      />
    </div>
  )
}

export default MediaLibrarySearchResult
