import React, { useEffect, useState } from "react"
import Select, { defaultTheme, StylesConfig } from "react-select"
import { Button, Icon } from "@atoms"
import { ILocationOption } from "@helpers/dataFunctions/_api.interface"
import { ISearchableDropdown } from "./_searchableDropdown.interface"
import DropDownButton from "./_dropdownButton"

const { colors } = defaultTheme

const Svg = (p: any) => (
  <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
)

const SearchIcon = () => (
  <div
    style={{
      color: colors.neutral90,
      height: 24,
      width: 32,
      cursor: "pointer",
    }}>
    <Svg>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Svg>
  </div>
)

const SearchableDropdown = (props: ISearchableDropdown) => {
  const { dropdownData, handleDropdownChange, type, labels, defaultValue } = props
  const [isOpen, setIsOpen] = useState(false)
  const [value, setValue] = useState<ILocationOption | null>(null)
  const selectStyles: StylesConfig<ILocationOption, false> = {
    control: (provided, { isFocused }) => ({
      ...provided,
      minWidth: 240,
      margin: 16,
      marginBottom: 8,
      borderColor: isFocused ? "HSL(207, 95%, 34%)" : "HSL(207, 96%, 19%)",
      boxShadow: isFocused ? "0 0 1px #0460a9" : "none",
      "&:hover": { boxShadow: "0 0 1px #0460a9" },
      cursor: "text",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      div: {
        color: "RGB(2, 55, 97)  !important ",
      },
    }),
    menu: () => ({ boxShadow: "inset 0 1px 0 rgba(0, 0, 0, 0.1)" }),
    menuList: () => ({
      padding: 0,
      marginTop: "8px",
      maxHeight: "264px",
      overflowY: "scroll",
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      color: "#000",
      backgroundColor: isSelected ? "#f6f6f6" : "#ffffff",
      paddingInline: "16px",
      "&:hover": { backgroundColor: "#f6f6f6", cursor: "pointer" },
    }),
  }

  const findDefaultOption = (
    list: ILocationOption[],
    initialVal: string | null | undefined,
    isPlaceHolder = false,
  ) => {
    if (isPlaceHolder) {
      const placeHolder: ILocationOption = {
        id: -999,
        value: -999,
        label: initialVal!,
      }
      return placeHolder
    }
    const idx = list.findIndex((item) => item?.value?.toString() === initialVal)
    return idx !== -1 ? list[idx] : list[0]
  }

  useEffect(() => {
    if (dropdownData.length) {
      const defaultOption = findDefaultOption(dropdownData, defaultValue, props?.isPlaceHolder)
      handleDropdownChange(defaultOption, type)
      setValue(defaultOption)
    }
  }, [])

  return (
    <>
      {labels?.label && <span className="location-filter-dropdown-label">{labels?.label}</span>}
      <DropDownButton
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        target={
          <Button
            isSecondary={true}
            tabindex={1}
            onClick={() => setIsOpen((prev) => !prev)}
            isSelected={isOpen}
            className={`location-filter-dropdown-button${
              props?.dropDownRightAlignment ? " dropdown-right-alignment" : ""
            }${isOpen ? " dropdown-open" : ""}${props?.isSelected ? " dropdown-selected" : ""}`}>
            <>
              <span>{value?.label || ""}</span>
              {isOpen ? <Icon iconName="ChevronUp" /> : <Icon iconName="ChevronDown" />}
            </>
          </Button>
        }>
        <Select
          autoFocus
          backspaceRemovesValue={false}
          components={{ DropdownIndicator: SearchIcon, IndicatorSeparator: null }}
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          menuIsOpen
          onChange={(newValue) => {
            setValue(newValue)
            setIsOpen(false)
            handleDropdownChange(newValue, type)
          }}
          options={dropdownData}
          placeholder={labels.search_placeholder}
          styles={selectStyles}
          tabSelectsValue={false}
          value={value}
          closeMenuOnScroll={false}
          noOptionsMessage={() => (
            <div className="location-filter-dropdown-no-results">{labels.no_results}</div>
          )}
        />
      </DropDownButton>
    </>
  )
}

export default SearchableDropdown
