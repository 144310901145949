import React, { useMemo } from "react"
import { EArticleTeaserImagePosition } from "@organisms/articleTeaser/_article-teaser.interface"
import { Row, Column, Container, Img } from "@atoms"
import { IArticleTeaserFluidProps } from "./_article-teaser-fluid.interface"
import { ArticleTeaserBannerContent } from "@molecules"
import { useMediaQuery } from "@helpers/hooks"

const ArticleTeaserFluid = (props: IArticleTeaserFluidProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  const shouldRenderDesktopImage = useMemo(() => {
    return !isMobile || (isMobile && !props?.articleTeaserImage?.mobileImage?.src)
  }, [props.articleTeaserImage.mobileImage, isMobile])

  const articleTeaserFluidContentData = props.articleTeaserContent
  const isImageRight = props.imagePosition === EArticleTeaserImagePosition.IMAGE_RIGHT
  
  const getClasses = () => {
    if (props.className && !props.subTheme) {
      return `article-teaser-fluid ${props.className} `
    }
    if (props.subTheme) {
      return `article-teaser-subTheme ${props.className}`
    }
    return "article-teaser-fluid "
  }

  const renderImage = useMemo(() => {
      const deskStyleImg = props?.articleTeaserImage?.desktopImage?.styledimage
      const mobStyleImg = props?.articleTeaserImage?.mobileImage?.styledimage
      const imgType = isMobile ? "article_Teaser_product_range_plp" : "article_teaser_support_material"
      const src = (
        Boolean(mobStyleImg)
          ? !props?.articleTeaserImage?.desktopImage?.src?.includes("isPaywall=true")
          : props?.articleTeaserImage?.mobileImage?.src?.includes("isPaywall=true")
      )
        ? props?.articleTeaserImage?.mobileImage?.src
        : props?.articleTeaserImage?.desktopImage?.src
      const alt = Boolean(mobStyleImg) ? props?.articleTeaserImage?.mobileImage?.alt : props?.articleTeaserImage?.desktopImage?.alt
      const { height: desktopHeight, width: desktopWidth } = props?.articleTeaserImage?.desktopImage
      const width = isMobile ? props?.articleTeaserImage?.mobileImage?.width : desktopWidth
      const height = isMobile ? props?.articleTeaserImage?.mobileImage?.height : desktopHeight

    const imgComp = (
      <Column desktop={6} tablet={6} mobile={12} className="article-teaser-fluid-image">
        <Img
          className="article-teaser-image-content"
          type={imgType}
          styledimage={
            !shouldRenderDesktopImage &&
            (Boolean(mobStyleImg) ||
              props?.articleTeaserImage?.mobileImage?.src?.includes("isPaywall=true"))
              ? mobStyleImg
              : deskStyleImg
          }
          src={src ?? null}
          alt={alt ?? ""}
          width={width ?? 358}
          height={height ?? 220}
        />
      </Column>
    )
    if(deskStyleImg || mobStyleImg) {
      return imgComp
    }

    return (
      <Column desktop={6} tablet={6} mobile={12} className="article-teaser-fluid-image">
        <Img 
          className="article-teaser-image-content"
          type="default"
          width={358}
          height={220}
          {...props?.articleTeaserImage?.desktopImage} />
      </Column>
    )
  }, [props?.articleTeaserImage?.desktopImage, props?.articleTeaserImage?.mobileImage, shouldRenderDesktopImage, isMobile])


  return (
    <Container fluid background={props.subTheme ? "subTheme-colBg" : ""}>
      <div className={getClasses()}>
        <Row className="article-teaser-fluid-blockAlign">
        {!isImageRight && props?.articleTeaserImage ? renderImage : <></>}
          <Column desktop={6} tablet={6} mobile={12} className={`article-teaser-fluid-content-${isImageRight ? "left" : "right"}`}>
            <ArticleTeaserBannerContent {...articleTeaserFluidContentData} />
          </Column>
          {isImageRight && props?.articleTeaserImage ? renderImage : <></>}
        </Row>
      </div>
    </Container>
  )
}
export default ArticleTeaserFluid
