import { Container, Icon } from "@atoms"
import AutoSearchBar from "@organisms/autoSearchBar"
import SearchGroup from "../../molecules/search-group"
import { ISearchBarProps } from "./_search-bar.interface"

const SearchBar = (props: ISearchBarProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("search-bar")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }
  const searchData = props.searchContent

  return (
    <div className={getClasses()}>
      <div className={`card ${props?.isTypeAhead ? "overlay" : ""}`}>
       {props?.displayClose && <span> <Icon
            iconName="simplifiedMenuCloseIcon"
            //@ts-ignore
            onClick={() => props?.setIsSearchOpen(false)}
            className="simplifiedHeader-close"
          /></span>}
        <Container>
          {props?.isTypeAhead ? (
            <AutoSearchBar
              inputDataContent={searchData.inputDataContent}
              buttonText={searchData.buttonText}
              handleTextSearch={searchData.handleTextSearch}
              showIconOnCTA={props?.showIconOnCTA ?? false}
            />
          ) : (
            <SearchGroup
              inputDataContent={searchData.inputDataContent}
              buttonText={searchData.buttonText}
              handleTextSearch={searchData.handleTextSearch}
            />
          )}
        </Container>
      </div>
    </div>
  )
}

export default SearchBar
