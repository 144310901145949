import { SearchFilterContent } from "@organisms/searchFilterTable"
import { ISearchFilterCard } from "@organisms/searchFilterTable/_searchFilterTable.interface"

const SearchFilterCardContent = (header: string, details: string | JSX.Element) => (
  <div className="search-filter-card-body-detail">
    <div className="search-filter-card-header">{header}</div>
    <div className="search-filter-card-detail">{details}</div>
  </div>
)

const SearchFilterCard = (props: ISearchFilterCard) => {
const { values, columnsList } = props
  return (
    <div className="search-filter-card card-strech">
      <div className="card">
        <div className="card-body">
          {Object.entries(columnsList).map(([key, value]) => SearchFilterCardContent(value, <SearchFilterContent {...values[key]} />))}
        </div>
      </div>
    </div>
  )
}
export default SearchFilterCard
