import { Column } from "@atoms"
import { ITaxonomyData } from "@helpers/dataFunctions/_api.interface"
import { MagazineCard } from "@organisms"
import { memo } from "react"

type Props = {
  row: number
  cardData: any
  cardImage: any
  taxonomies: ITaxonomyData[]
}

const MagazineRowItem = (props: Props) => {
  const { row, cardData, cardImage, taxonomies } = props
  let headingTagStart
  let headingTagEnds
  if (row > 0 && row <= 6) {
    headingTagStart = `<h${row}>`
    headingTagEnds = `</h${row}>`
  } else {
    headingTagStart = "<p>"
    headingTagEnds = "</p>"
  }

  const magData = {
    id: cardData?.attributes?.drupal_internal__nid ?? "",
    magImage: cardImage,
    isVideo:
      cardData?.attributes?.field_magz_type === "video" ||
      cardData?.attributes?.field_magz_type === "podcast",
    taxonomies,
    href: cardData?.attributes?.path?.alias ?? null,
    title: headingTagStart + cardData?.attributes?.title + headingTagEnds ?? "",
    subtitle: cardData?.attributes?.field_magz_subtitle?.processed ?? null,
    desc: cardData?.attributes?.body?.processed ?? "",
    createdDate: cardData?.attributes?.field_magz_date ?? null,
    time: cardData?.attributes?.field_magz_reading_time?.processed ?? null,
    totalFavoriteCount: 150,
    bookmark: {
      addText: "Add to Bookmark",
      removeText: "Remove from Bookmark",
      bookmarkStatus: false,
      isButton: false,
    },
    row,
  }

  return (
    <>
      {cardData?.type && (
        <Column desktop={Math.round(12 / row)} tablet={row === 3 ? 6 : 12 / row} mobile={12}>
          <MagazineCard {...magData} />
        </Column>
      )}
    </>
  )
}

export default memo(MagazineRowItem)
