import Anchor from "../anchor"
import { filterURL } from "@helpers/dataFunctions"
import { IAnchorButtonProps } from "./_anchorButton.interface"

const AnchorButton = (props: IAnchorButtonProps) => {
  const { download, href, target } = props
  const getClasses = () => {
    const classes = []
    classes.push("btn")
    props.isSecondary ? classes.push("btn-secondary") : classes.push("btn-primary")
    props.icon && classes.push("btn-icon")
    props.isIconRight && classes.push("icon-right")
    props.isGhost && classes.push("btn-ghost")
    if (props.className) {
      classes.push(props.className)
    }
    return classes.join(" ")
  }

  const linkTo = typeof href === "object" ? (href as any)?.fileurl : href

  return (
    <Anchor
      tabindex={props.tabindex}
      className={getClasses()}
      href={linkTo ? filterURL(linkTo) : "javascript:void(0)"}
      isExternal={props.isExternal ? props.isExternal : false}
      onClick={props?.onClick}
      download={download || false}
      target={target}>
      <>
        {!props.isIconRight && props.icon && <span className="icon">{props.icon}</span>}
        {props.children}
        {props.icon && props.isIconRight && <span className="icon">{props.icon}</span>}
      </>
    </Anchor>
  )
}

export default AnchorButton
