import { Container } from "@atoms"
import ProfileCategories from "@organisms/profileCategories"
import { IProfileCategories } from "@organisms/profileCategories/_profileCategories.interface"

const ProfileTemplate = (props: IProfileCategories) => {
    return (
      <Container>
        <ProfileCategories {...props} />
      </Container>
    )
  }

  export default ProfileTemplate
