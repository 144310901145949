import { IStar } from "./_star.interface"

const Star = (props: IStar) => {
  const isDisabled = props.isDisabled || false
  const isHalfFilled = props.isHalfFilled || false
  const isFullFilled = props.isFullFilled || false
  const primeColor = "var(--brand-primary-color-one)"

  const getClasses = () => {
    const classes = []
    if (props.className) classes.push(props.className)
    if (isFullFilled) classes.push("fullfilled")
    return classes.join(" ")
  }

  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      style={{ opacity: isDisabled ? 0.5 : 1 }}
      className={getClasses()}>
      <g id="Rating" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-330.000000, -796.000000)" id="Atoms-/-Rating-/-Filled">
          <g transform="translate(330.000000, 796.000000)">
            <rect id="Rectangle" x="0" y="0" width="24" height="24" />
            <polygon
              id="path2006"
              stroke={primeColor}
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              fill={isFullFilled ? primeColor : "none"}
              points="11.5249624 2 14.3870677 9.12024837 22.0499252 9.63932044 16.155946 14.5589474 18.0297468 22 11.5249624 17.9202483 5.02017739 21.9999997 6.89397883 14.5589475 1 9.63931984 8.66285695 9.12024837"
            />
            {isHalfFilled && (
              <polygon
                id="path2006"
                fill={primeColor}
                points="11.5249624 2 11.5249624 17.9202483 5.02017739 21.9999997 6.89397883 14.5589475 1 9.63931984 8.66285695 9.12024837"
              />
            )}
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Star
