import { IGridProps } from "./_column.interface"

const Column = (props: IGridProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("column")
    if (props.mobile) classes.push(`col-${props.mobile}`)
    if (props.tablet) classes.push(`col-md-${props.tablet}`)
    if (props.desktop) classes.push(`col-lg-${props.desktop}`)
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }
  return <div className={getClasses()}>{props.children}</div>
}

export default Column
