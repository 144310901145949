import InputBox from "@atoms/inputBox"
import React, { useState } from "react"
import { RichText } from "@atoms"
import { Errors, IInputBoxEmailValidationProps } from "./_inputBoxEmailValidation.interface"

const InputBoxEmailValidation = ({
  name,
  value,
  email,
  confirmEmail,
  isRequired,
  isDisabled,
  validationErrorMessage,
  onChange,
  handleError,
  error,
  validations,
  checkForError,
  helpDescriptionText,
}: IInputBoxEmailValidationProps) => {
  const [errors, setErrors] = useState<Errors>({})

  const getClasses = () => {
    const classes: string[] = []
    classes.push("input")
    if (error) {
      classes.push("invalid")
    }
    return classes.join(" ")
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const field = e.target
    const fieldName = `${field.name}`
    const fieldValue = field.value.trim()
    const newErrors: any = { ...errors }
    const errorMsg = checkForError(fieldValue, validations)
    if (errorMsg) {
      if (errorMsg === validations?.mandatory?.message) {
        newErrors[fieldName] =
          fieldName === "confirmEmail" && confirmEmail?.confirmEmailReqMsg
            ? confirmEmail.confirmEmailReqMsg
            : errorMsg
      } else {
        newErrors[fieldName] = errorMsg
      }
    } else {
      delete newErrors[fieldName]
    }
    if (value?.email !== value?.confirmEmail && value.email && value.confirmEmail) {
      newErrors.matchError = validationErrorMessage
    } else {
      delete newErrors.matchError
    }

    setErrors(newErrors)
    if (Object.keys(newErrors).length) {
      handleError(name, Object.values(newErrors).join(" "))
    } else {
      handleError(name, null)
    }

    if (fieldValue.length <= 0) {
      field.classList.add("invalid")
    } else {
      field.classList.remove("invalid")
    }
  }

  const renderLabel = (fieldLabel: string, forField: string) => (
    <label aria-required={isRequired} className="inputBox-withLabel" htmlFor={forField}>
      {fieldLabel}
      {isRequired && "*"}
    </label>
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, parentFieldKey: string) => {
    const fieldKey = e.target.name ?? ""
    const fieldValue = e.target.value ?? ""
    const newValue = { ...value }
    newValue[fieldKey] = fieldValue
    onChange(parentFieldKey, newValue)
  }
  const preventCopyPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault()
  }
  return (
    <>
      <div className="input-box-with-label">
        {email.label && renderLabel(email.label, "emailField")}
        <InputBox
          {...email.inputField}
          className={getClasses()}
          id="emailField"
          name="email"
          type="email"
          value={value?.email ?? ""}
          aria-invalid={errors?.email || errors?.matchError || error}
          onChange={(e) => handleChange(e, email.inputField.name)}
          onBlur={handleBlur}
          onCut={preventCopyPaste}
          onCopy={preventCopyPaste}
          onPaste={preventCopyPaste}
          disabled={isDisabled}
        />
        {errors?.email && <RichText className="error" content={errors?.email} />}
      </div>
      <div className="input-box-with-label">
        {confirmEmail.label && renderLabel(confirmEmail.label, "confirmEmailField")}
        <InputBox
          {...confirmEmail.inputField}
          className={getClasses()}
          id="confirmEmailField"
          name="confirmEmail"
          type="email"
          value={value?.confirmEmail ?? ""}
          aria-invalid={errors?.email || errors?.matchError || error}
          onChange={(e) => handleChange(e, email.inputField.name)}
          onBlur={handleBlur}
          onCut={preventCopyPaste}
          onCopy={preventCopyPaste}
          onPaste={preventCopyPaste}
          disabled={isDisabled}
        />
        {helpDescriptionText || <></>}
        {errors?.confirmEmail && <RichText className="error" content={errors?.confirmEmail} />}
        {errors?.matchError && <RichText className="error" content={validationErrorMessage} />}
        {!Object.keys(errors).length && error && <RichText className="error" content={error} />}
      </div>
    </>
  )
}

export default InputBoxEmailValidation
