import React from "react"
import ComparisonColumn from "@molecules/comparisonColumn"
import { Img, RichText, Row } from "@atoms"
import { IComparisionTableProps } from "./_comparisionTable.interface"

const ComparisonTable = (props: IComparisionTableProps) => {
  const { tableData, discription } = props

  const renderHeader = () => {
    return (
      <>
        <tr>
          <td />
          {tableData?.map((col: any, i: any) =>
            (i <= 3 ? (
              <td key={i}>
                <Img
                  key={i+10}
                  type="table_enhancement"
                  styledimage={col?.styledimage}
                  src={col?.rowimage}
                  alt={col?.imagealt}
                  width={268}
                  height={215}
                />
              </td>
            ) : null))}
        </tr>
        <tr>
          <th />
          {tableData?.map((col: any, i: any) =>
            (i <= 3 ? <th key={i+i}>{col?.coltitle}</th> : null))}
        </tr>
      </>
    )
  }

  return (
    <div className="comparisonTable">
      <Row>
        <div className="comparisonColoumn">
          <table className="table comparisonColoumn-tablediv ">
            <tbody>
              {renderHeader()}

              {tableData?.map((row: any, i: any) =>
                (i <= 4 ? <ComparisonColumn key={i} {...row} /> : null))}
            </tbody>
          </table>
        </div>
        <RichText className="comparisonTable-content" content={discription} />
      </Row>
    </div>
  )
}
export default ComparisonTable
