import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { newsBlockType } from "@helpers/constants/generic"
import { getBlockData, getImageMeta } from "."
import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"

const getSnackbiteData = async (url: string, nid: number, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const hasQueryParams = url.includes("?")
  const rawData = await getBlockData(`${url}${hasQueryParams ? "&" : "?"}views-argument[0]=${nid}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData?.data?.[0], "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const taxonomy = (rawData?.data?.[0]?.relationships?.field_category?.data as Array<any>)?.map((t: any) => t?.meta?.term_name)
  const taxonomies = taxonomy?.map(taxonomyLabel => ({taxonomyLabel: taxonomyLabel}))
  const fieldPaywallRoles = getAllRoles(rawData)
  const fieldImage = rawData?.data?.[0]?.relationships?.field_image_therapy
    ? rawData?.data?.[0]?.relationships?.field_image_therapy
    : rawData?.data?.[0]?.relationships?.field_nas_image
  const imagePaywallData = isPaywallProtected(fieldImage)
  const rawImages = fieldImage?.data ?? []
  const images: any = []

  if (Array.isArray(rawImages)) {
    for (const image of rawImages) {
      const { imageUrl, alt, caption, imageStyledUrl } = getImageMeta({ data: image })

      images.push({
        alt,
        src: imageUrl,
        caption: caption ?? null,
        styledimage: imageStyledUrl ?? null,
      })
    }
  } else {
    const { imageUrl, alt, caption, imageStyledUrl } = getImageMeta({ data: rawImages })
    images.push({
      alt,
      src: imageUrl,
      caption: caption ?? null,
      styledimage: imageStyledUrl ?? null,
    })
  }

  const blockDataType = getNestedObject(rawData?.data?.[0], "type") ?? null
  const dateKey =
    blockDataType === newsBlockType
      ? "attributes.field_news_published_date"
      : "attributes.published_date"
  const publishedDate = isPaywallProtected(getNestedObject(rawData?.data[0], dateKey))
    .isPaywallProtectedFlag
    ? null
    : getNestedObject(rawData?.data?.[0], dateKey)

  const fieldEnableReadMoreCta = isPaywallProtected(
    getNestedObject(rawData?.data?.[0], "attributes.field_enable_read_more_cta"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(rawData?.data?.[0], "attributes.field_enable_read_more_cta")

  const isReadMoreEnabled =
    blockDataType === newsBlockType && fieldEnableReadMoreCta === null
      ? false
      : fieldEnableReadMoreCta ?? true

  const alignment = rawData?.data?.[0]?.attributes?.field_alignment
    ? rawData?.data?.[0]?.attributes?.field_alignment
    : "center"

  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang

  return {
    titleNewsAndStoriesDetails: {
      title: isPaywallProtected(rawData?.data?.[0]?.attributes?.title).isPaywallProtectedFlag
        ? ""
        : rawData?.data?.[0]?.attributes?.title ?? "",
      subTitle: publishedDate ?? "",
      taxonomies: taxonomies ?? null,
      paragraph: rawData?.data?.[0]?.attributes?.body?.value ?? null,
      alignment: isPaywallProtected(rawData?.data?.[0]?.attributes?.field_alignment)
        .isPaywallProtectedFlag
        ? "center"
        : alignment,
    },
    detailNewsAndStories: {
      imageUrl: imagePaywallData?.isPaywallProtectedFlag
        ? [getPaywalledImgData(imagePaywallData?.errorObj)]
        : images,
      readMoretitle: rawData?.data?.[0]?.attributes?.field_read_more_title?.processed ?? null,
      readMore:
        rawData?.data?.[0]?.attributes?.field_read_more?.processed ??
        rawData?.data?.[0]?.attributes?.field_read_more?.value ??
        null,
      showMoreButtonLabel: isPaywallProtected(rawData?.data?.[0]?.attributes?.field_show_more)
        .isPaywallProtectedFlag
        ? null
        : rawData?.data?.[0]?.attributes?.field_show_more ?? null,
      showLessButtomLabel: isPaywallProtected(rawData?.data?.[0]?.attributes?.field_show_less)
        .isPaywallProtectedFlag
        ? null
        : rawData?.data?.[0]?.attributes?.field_show_less ?? null,
      isReadMoreEnabled,
      fieldPaywallRoles,
      isParentPaywalled,
    },
  }
}

export default getSnackbiteData
