import React from "react"

import { AnchorButton, Img } from "@atoms"
import { IImageGridProps } from "./_imageGrid.interface"
import { IImageProps } from "@atoms/img/_img.interface"

const ImageGrid = (props: IImageGridProps) => {
  const { imageData, gridType, downloadCTA } = props
  return (
    <>
      <div className={`image-gallery ${gridType}`}>
        {imageData.map((gridDataItem: IImageProps, key: number) => (
          <div className="image-gallery-item" key={key}>
            <Img
              {...gridDataItem}
              alt={props.isPimProduct ? gridDataItem?.alt_text : gridDataItem?.alt}
              type="plp_list"
              width={200}
              height={200}
              layout="fill"
              isPimProduct
            />
          </div>
        ))}
      </div>
      {downloadCTA && (
        <div className="image-gallery-cta">
          <AnchorButton {...downloadCTA} />
        </div>
      )}
    </>
  )
}
export default ImageGrid
