import React, { useState } from "react"
import { AnchorButton, RichText, Taxonomy } from "@atoms"
import { IArticleTeaserContentProps } from "./_articleTeaserContent.interface"
import StarsRating from "@molecules/starsRating"

const ArticleTeaserContent = ({
  taxonomies,
  heading,
  text,
  link,
  buttonIcon,
  linkExternal,
  buttonLabel,
  avgRating,
}: IArticleTeaserContentProps) => {
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const renderTaxonomy = () => {
    try {
      if (taxonomies && taxonomies.length > 0) {
        return <Taxonomy taxonomies={taxonomies} />
      }
    } catch (error) {
      // Handle error if needed
      return null
    }
  }

  return (
    <div className="article-teaser-content">
      {renderTaxonomy()}
      {heading && <h3 className="article-teaser-content-heading bold">{heading}</h3>}
      {text && <RichText className="article-teaser-content-content" content={text} />}
      {link && (
        <div className="article-teaser-content-button">
          <AnchorButton
            href={link}
            icon={buttonIcon}
            isExternal={linkExternal}
            tabindex={1}
            isSecondary>
            {buttonLabel}
          </AnchorButton>
        </div>
      )}
      {avgRating && (
        <div className="article-teaser-content-star">
          <StarsRating
            avgRating={avgRating}
            setShowLoginPopUp={() => setShowLoginPopUp(true)}
            title=""
          />
        </div>
      )}
    </div>
  )
}

export default ArticleTeaserContent
