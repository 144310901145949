import { Container, GoBackButton, Icon } from "@atoms"
import Link from "next/link"
import { IBreadcrumbsProps } from "./breadcrumbs.interface"
import DYNAMIC_GOBACK_CONSTANTS from "@helpers/constants/dynamicGoBack"

const Breadcrumbs = (props: IBreadcrumbsProps) => {
  const { breadcrumbs, selectedTitle, referer } = props
  const dynamicGoBack = (
    <>
      {referer &&
        referer?.refererUrl !== "" &&
        referer?.goback_details_enable === 1 &&
        (referer?.goback_details_button_position === DYNAMIC_GOBACK_CONSTANTS.TOP ||
          referer?.goback_details_button_position === DYNAMIC_GOBACK_CONSTANTS.BOTH) && (
          <GoBackButton
            refererUrl={referer?.refererUrl ?? document?.referrer}
            goback_details_target_url={referer?.goback_details_target_url}
            goback_details_button_label_text={referer?.goback_details_button_label_text}
            className="top-margin"
          />
        )}
    </>
  )
  return (
    <>
      {dynamicGoBack}
      <Container>
        <div className="breadcrumbs">
          {breadcrumbs?.map((breadcrumb, index) => (
            <p key={breadcrumb.path}>
              <Link href={breadcrumb.path} legacyBehavior>
                <a className={
                  breadcrumb?.path == "route:<nolink>"
                    ? "breadcrumbs-link non-clickable"
                    : "breadcrumbs-link"
                }>{breadcrumb.title}</a>
              </Link>
              {index < breadcrumbs?.length - 1 && (
                <Icon
                  iconName="ChevronRightIcon5"
                  height={18}
                  width={18}
                  className="breadcrumbs-icon"
                />
              )}
            </p>
          ))}
          {selectedTitle && (
            <p className="breadcrumbs-active">
              {breadcrumbs?.length > 0 && (
                <Icon
                  iconName="ChevronRightIcon5"
                  height={18}
                  width={18}
                  className="breadcrumbs-icon"
                />
              )}
              {selectedTitle}
            </p>
          )}
        </div>
      </Container>
    </>
  )
}

export default Breadcrumbs
