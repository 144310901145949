import { Column, RichText, Video } from "@atoms"
import { IVideoWithContentProps } from "./_videoWithContent.interface"

const VideoWithContent = (props: IVideoWithContentProps) => (
  <Column desktop={12} mobile={12} tablet={12} className="videoWithContent">
    <div>
      <Video src={props?.videoUrl} />
      <RichText className="content" content={props.content} />
    </div>
  </Column>
)

export default VideoWithContent
