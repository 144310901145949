import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."

const getProductCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let productCards = await getBlockData(url, false, serverData)
  const fieldPaywallRoles = getAllRoles(productCards)
  if (productCards) {
    productCards = await Promise.all(
      productCards?.data?.map(async (productCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(productCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(productCard?.relationships?.field_product_image)
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta({ data: productCard?.relationships?.field_product_image?.data?.[0] })

        const taxonomies = isPaywallProtected(productCard?.relationships?.field_category)
          .isPaywallProtectedFlag
          ? null
          : await getTaxonomy(
              productCard?.relationships?.field_category?.links?.related?.href,
              true,
              serverData,
            )
        return {
          productCardImage: {
            src: imageUrl ?? null,
            alt: alt ?? null,
            styledimage: imageStyledUrl ?? null,
          },
          productCardContent: {
            id: productCard?.attributes?.drupal_internal__nid ?? "",
            heading: productCard?.attributes?.field_display_title?.value ?? null,
            taxonomies: taxonomies ?? null,
            content: productCard?.attributes?.body?.processed ?? null,
            buttonLabel: isPaywallProtected(productCard?.attributes?.field_more_info)
              .isPaywallProtectedFlag
              ? null
              : productCard?.attributes?.field_more_info ?? null,
            href: filterURL(productCard?.attributes?.field_secondary_cta?.uri) ?? null,
            isLinkExternal:
              isExternalLink(
                productCard?.attributes?.field_secondary_cta?.options?.attributes?.target,
              ) ?? null,
          },
        }
      }),
    )
    return { productCards: filterNullValues(productCards), fieldPaywallRoles }
  }
  return null
}
export default getProductCardData
