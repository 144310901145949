export const getSectionClassName = (cols: any, bgOctagonStyle: string) => {
  let className = `section-bg-wrapper ${bgOctagonStyle}`

  if (cols?.length === 1) {
    if (
      cols?.[0]?.type === "hero_banner" ||
      cols?.[0]?.type === "rotating_hero_banner" ||
      cols?.[0]?.type === "article_teaser_banner" ||
      cols?.[0]?.type === "article_teaser_composition"
    ) {
      className += " only-banner-child"
    }
  } else {
    cols?.map((col: any, index: number) => {
      if (
        col?.type === "hero_banner" ||
        col?.type === "rotating_hero_banner" ||
        col?.type === "article_teaser_banner" ||
        col?.type === "article_teaser_composition"
      ) {
        if (index === 0) {
          className += " first-banner-child"
        } else if (index === cols?.length - 1) {
          className += " last-banner-child"
        } else {
          className += ""
        }
      }
    })
  }

  return className
}
