import getNestedObject from "@utils/nestedObjects"
import { getImageMeta } from "."
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"

const getBuyNowData = async (data: any) => {
  let listData = data?.data ?? {}
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(listData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(listData)
  let listDataIncluded = data?.included?.filter(
    (blocks: any) => blocks?.type === "paragraph--link_logo_paragraph",
  )
  let arr: any = []
  let containerAlignment = getNestedObject(listData, "attributes.field_container_alignment") ?? ""
  listDataIncluded?.map((list: any) => {
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(list, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag
    const siteUrl = getNestedObject(list, "attributes.field_external_link_url.uri")
    const { imageUrl, alt, imageStyledUrl, width, height } = getImageMeta(
      list.relationships.field_logo,
    )
    const siteDesc = getNestedObject(list, "attributes.field_information.processed") ?? ""
    const obj = isChildPaywalled ? null : { siteUrl, imageUrl, alt, imageStyledUrl, width, height, siteDesc }
    arr.push(obj)
  })

  return {
    buttonLabel: getNestedObject(listData, "attributes.info"),
    eCommerceSites: arr,
    containerAlignment,
    isParentPaywalled,
    fieldPaywallRoles
  }
}

export default getBuyNowData
