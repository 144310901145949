import { Icon, Seperator } from "@atoms"
import Loader from "@atoms/loader"
import { ILanguageSwitcherPopupProps } from "./_languageSwitcherPopup.interface"
import Tabs from "../../molecules/tabs"
import { useState } from "react"

const LanguageSwitcherPopup = (props: ILanguageSwitcherPopupProps) => {
  const { loading, tabsData, isMicrositeEnabled, micrositeL1List, selectedItem, onSelect } = props
  const [selectedOption, setSelectedOption] = useState<string | null>(selectedItem ?? null)

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option)
    if (onSelect) {
      onSelect(option)
    }
  }

  if (!props.display) {
    return <></>
  }

  const getOverlayData = () => {
    if (!isMicrositeEnabled) {
      return <Tabs {...tabsData} />
    }
    const filteredList = micrositeL1List?.filter((country: any) => country.title !== selectedItem)

    return (
      <ul className="language-switcher-country">
        {filteredList?.map((country: any, index: number) => (
          <li key={index} onClick={() => handleOptionSelect(country.title)}>
            <a className="language-switcher-country-list" href={country.uri}>
              {country.title}
            </a>
          </li>
        ))}
      </ul>
    )
  }

  return (
    <div className="language-switcher-popup">
       {props?.displayClose && <span> <Icon
            iconName="simplifiedMenuCloseIcon"
            //@ts-ignore
            onClick={() => props?.setIsLangSwitcherActive(false)}
            className="simplifiedHeader-close"
          /></span>}
      <h3 className="language-switcher-title bold">{props.title}</h3>
      <Seperator className={props.divider.className} />
      {loading ? <Loader display={true} /> : getOverlayData()}
    </div>
  )
}

export default LanguageSwitcherPopup
