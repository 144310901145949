import React, { memo, useEffect, useState, useContext } from "react"
import { Anchor, Button, Input, Recaptcha, RichText } from "@atoms"
import { LOGIN_TYPES } from "@helpers/constants/generic"
import { AuthFormProps } from "./_authForm.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const AuthForm = (props: AuthFormProps) => {
  const [token, setToken] = useState<string | null>(null)
  const [formValidated, setFormValidated] = useState(false)
  const [inputValidated, setInputValidated] = useState(false)
  const {
    fields,
    instruction,
    submitButton,
    cancelButton,
    forgotPassword,
    registerLink,
    title,
    recaptchaSiteKey,
    onSubmit,
    onCancel,
    onForgotPassword,
    noValidate,
    wrongCredentialsText,
    deactivatedUserMessage,
    showCredentialsError,
    showDeactivatedUserMsg,
    isInvalidEmail,
    isInvalidPassword,
    onChange,
    inputs,
    isPaywallForm,
    formType,
  } = props

  const [doccheckVariables, setDoccheckVariables] = useState(Object)
  const applicationConfigContext = useContext(ApplicationContext)

  useEffect(() => {
    formType === LOGIN_TYPES.DOC_CHECK && setDoccheckVariables({
      DOCCHECK_LOGINID: applicationConfigContext?.applicationConfiguration?.siteConfig?.doccheck_login_id,
      DOCCHECK_LOGINFORMURL: `https://${applicationConfigContext?.applicationConfiguration?.siteConfig?.doccheck_base_domain}`,
    })
      
  }, [formType])

  const validateInputs = () => {
    const form: any = document.getElementById(
      isPaywallForm ? `paywall_login_form_${formType?.toLowerCase()}` : `login_form_${formType?.toLowerCase()}`,
    )
    if (form) {
      const valid = Array.from(form?.elements)
        .map((element) => {
          const inputElement = element as HTMLInputElement
          return inputElement.required ? inputElement.validity.valid : true
        })
        .every((isValid) => isValid)
      valid ? setInputValidated(true) : setInputValidated(false)
    }
  }

  useEffect(() => {
    if (recaptchaSiteKey) {
      return inputValidated && token ? setFormValidated(true) : setFormValidated(false)
    }
    return inputValidated ? setFormValidated(true) : setFormValidated(false)
  }, [token, inputValidated, formValidated])
 
  return (
    <form
      method={formType === LOGIN_TYPES.DOC_CHECK ? "post" : undefined}
      action={formType === LOGIN_TYPES.DOC_CHECK ? doccheckVariables.DOCCHECK_LOGINFORMURL : ""}
      id={isPaywallForm ? `paywall_login_form_${formType?.toLowerCase()}` : `login_form_${formType?.toLowerCase()}`}
      name={isPaywallForm ? `paywall_login_form_${formType?.toLowerCase()}` : `login_form_${formType?.toLowerCase()}`}
      noValidate={noValidate}
      className="authform"
      onSubmit={(e) => onSubmit?.(e)}>
      {title && <RichText className="authform-title" content={title} />}
      {fields &&
        fields.map((field, index) => (
          <div key={index + (formType ?? "")} className="authform-field">
            <Input
              className="authform-field-input"
              id={field.type}
              type={field.type}
              disabled={field.disabled}
              required={field.required}
              label={field.label}
              placeholder={field.placeholder}
              icon={field.icon}
              name={field?.name || field.label}
              value={inputs?.label}
              onChange={onChange}
              onKeyUp={validateInputs}
              data-testid="authform-field-input"
            />
            {field.helpText && isInvalidEmail && field.type === "email" && (
              <p
                className="authform-helpText"
                dangerouslySetInnerHTML={{ __html: field.helpText }}
              />
            )}
            {field.helpText && isInvalidPassword && field.type === "password" && (
              <p className="authform-helpText">{field.helpText}</p>
            )}
          </div>
        ))}
      {formType !== LOGIN_TYPES.DOC_CHECK && !isPaywallForm && forgotPassword && (
        <div className="">
          <div role="dialog" className="authform-forgotPassword">
            <Anchor
              href="#"
              onClick={() => onForgotPassword(forgotPassword?.url)}
              noUnderline={!forgotPassword.underline}
              tabindex={0}>
              {forgotPassword.text}
            </Anchor>
          </div>
        </div>
      )}

      {formType === LOGIN_TYPES.DOC_CHECK && (forgotPassword || registerLink) && (
        <div role="dialog" className="authform-forgotPassword">
          {forgotPassword && (
            <span className="authform-forgotPassword-anchor1">
              <Anchor
                href={forgotPassword?.url}
                isExternal={true}
                noUnderline={!forgotPassword.underline}
                tabindex={0}>
                {forgotPassword.text}
              </Anchor>
            </span>
          )}

          <span>|</span>

          {registerLink && (
            <span className="authform-forgotPassword-anchor2">
              <Anchor
                isExternal={true}
                href={registerLink?.url}
                noUnderline={!registerLink.underline}
                tabindex={0}>
                {registerLink.text}
              </Anchor>
            </span>
          )}
        </div>
      )}
      {instruction && <h4 className="authform-instruction">{instruction}</h4>}
      {recaptchaSiteKey && (
        <div className="authform-recaptcha">
          <Recaptcha sitekey={recaptchaSiteKey} onChange={(e) => setToken(e)} />
        </div>
      )}
      {showCredentialsError && wrongCredentialsText && (
        <RichText content={wrongCredentialsText} className="authform-wrongCredentials" />
      )}
      {showDeactivatedUserMsg && deactivatedUserMessage && (
        <RichText content={deactivatedUserMessage} className="authform-deactivatedUser" />
      )}
      <div className="authform-submit">
        {cancelButton && (
          <Button
            className="authform-cancel"
            onClick={onCancel}
            isSecondary
            type="button"
            tabindex={1}>
            {cancelButton.text}
          </Button>
        )}
        {submitButton && (
          <Button type="submit" tabindex={2} isDisabled={!formValidated}>
            {submitButton.text}
          </Button>
        )}
      </div>

      {formType === LOGIN_TYPES.DOC_CHECK && (
        <>
          <input type="hidden" name="login_id" value={doccheckVariables?.DOCCHECK_LOGINID} />
          <input type="hidden" name="redirect_uri" value={window.location.href} />
        </>
      )}
    </form>
  )
}

export default memo(AuthForm)
