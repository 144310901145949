import { Column, Img } from "@atoms"
import getDirection from "@utils/direction"
import InterestingTeaserCardContent from "../../molecules/interestingTeaserCardContent"
import { IInterestingTeaserCardProps } from "./_interestingTeaserCard.interface"

const InterestingTeaserCard = (props: IInterestingTeaserCardProps) => {
  const { interestingTeaserCardImage } = props
  const { interestingTeaserCardContent } = props
  return (
    <Column desktop={4} tablet={4} mobile={12} className="interesting-teaser-card">
      <div className="card" dir={getDirection()}>
        {interestingTeaserCardImage.src ? (
          <Img
            className="interesting-teaser-card-image"
            type="card"
            styledimage={interestingTeaserCardImage.styledimage}
            src={interestingTeaserCardImage.src}
            alt={interestingTeaserCardImage.alt}
            width={368}
            height={215}
            islazyloaded={false}
          />
        ) : (
          <div className="img-placeholder"> </div>
        )}
        <div className="card-body">
          <InterestingTeaserCardContent {...interestingTeaserCardContent} />
        </div>
      </div>
    </Column>
  )
}
export default InterestingTeaserCard
