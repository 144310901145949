import React from "react"
import getDecisionTreeData from "@helpers/dataFunctions/getDecisionTreeData"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import Loader from "@atoms/loader"
import { DecisionTreeNode } from "@molecules"


const DecisionTree = ({ url }: any) => {
  const { data, isLoading } = useSWRRequest(url, async () => {
    try {
      const res = await getDecisionTreeData(url)
      return res
    } catch (error) {}
  })

  if (isLoading) {
    return <Loader display={isLoading} />
  }

  return data ? <DecisionTreeNode {...data} depth={0} /> : <></>
}

export default DecisionTree
