import { useImage } from "@helpers/hooks/customImageHooks/useImage"
import ResourceImage from "./resource-image"

type Props = {
  alt: string | undefined
  type: string | undefined
  height?: number | string
  width?: number | string
  resource: any
  onError?: () => void
  onLoad?: (event: any) => void
  islazyloaded?: boolean
}

const CustomImage = (props: Props) => {
  const { type, alt, height, resource, width, onError, onLoad, islazyloaded = true } = props

  const imageProps = useImage({
    onError,
    onLoad,
    width,
    height,
  })

  const {
    handleError,
    handleImageLoad,
    resourceWidth: imageWidth,
    resourceHeight: imageHeight,
  } = imageProps

  return (
    <ResourceImage
      alt={alt}
      className=""
      type={type}
      handleError={handleError}
      handleLoad={handleImageLoad}
      height={imageHeight}
      resource={resource}
      width={imageWidth}
      islazyloaded={islazyloaded ?? false}
    />
  )
}

export default CustomImage
