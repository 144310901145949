import RichText from "@atoms/richText"
import React from "react"
import { ICheckboxProps } from "./checkbox.interface"

const CheckBox = ({
  required,
  value,
  id,
  label,
  isDisabled,
  isChecked,
  error,
  className,
  ...rest
}: ICheckboxProps) => {
  const errorContent = error ? `${error}` : ""

  const getClasses = () => {
    const classes = []
    classes.push("form-check")
    if (className) classes.push(className)
    return classes.join(" ")
  }

  return (
    <>
      <div className={getClasses()}>
        <input
          {...rest}
          className="form-check-input"
          type="checkbox"
          required={required}
          value={value}
          id={id}
          disabled={isDisabled}
          checked={isChecked}
        />
        <label className="form-check-label" htmlFor={id}>
          {label}
        </label>
      </div>
      <div className="error">
        <RichText content={errorContent} />
      </div>
    </>
  )
}

export default CheckBox
