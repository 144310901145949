import React, { useEffect } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import HeroBanner from "../heroBanner"
import { IRotatingHeroBanner } from "./_rotatingHeroBanner.interface"
import { IHeroBanner } from "../heroBanner/_heroBanner.interface"
import NUMBERS from "../../../helpers/constants/numbers"

const getHeroBanners = (slidesInfo: IHeroBanner[]) => {
  const filteredSlides = slidesInfo.filter((slide) => slide.uploadType !== "video")

  return filteredSlides.map((slide, index) => {
    return <HeroBanner {...slide} key={index} />
  })
}

const RotatingHeroBanner = ({
  slidesInfo,
  duration = NUMBERS.TWO_THOUSAND,
  className,
}: IRotatingHeroBanner) => {
  const getClasses = () => {
    const classes = ["rotating-banner-container", className].filter(Boolean)
    return classes.join(" ")
  }
  const rotatingBannerID = `rhb_${Math.floor(Math.random() * 1000 + 1)}`
  const settings = {
    dots: true,
    slidesToShow: NUMBERS.ONE,
    slidesToScroll: NUMBERS.ONE,
    arrow: false,
    infinite: true,
    accessibility: true,
    autoplay: (slidesInfo?.length || NUMBERS.ZERO) > NUMBERS.ONE,
    autoplaySpeed: duration,
    pauseOnHover: true,
    rtl: false,
  }

  useEffect(() => {
    const rotatingBannerContainer = document.getElementById(rotatingBannerID)
    const slickDots = rotatingBannerContainer?.querySelector(".slick-dots")
    const newParent = rotatingBannerContainer?.querySelector(".heroCarouselIndicator")
    if (newParent && slickDots && slidesInfo.length > NUMBERS.ONE) {
      const hasReferenceField = slidesInfo.some((slide) => slide.referenceField)
      const slickDotsElement = slickDots as HTMLElement
      slickDotsElement.style.marginTop = hasReferenceField ? "-11.5rem" : "0"
      newParent.appendChild(slickDotsElement)
    }
  }, [slidesInfo])

  return (
    <div className={getClasses()} id={rotatingBannerID}>
      <Slider {...settings}>{getHeroBanners(slidesInfo)}</Slider>
      <div className="container heroCarouselIndicator" />
    </div>
  )
}

export default RotatingHeroBanner
