import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getApi } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"
import { getAllRoles } from "@utils/paywallUtilities"
import { getBlockData, getTaxonomy } from "."
import { ILocationGroupData, ILocationOption } from "./_api.interface"
import getConfigDisplayData from "./getConfigDisplayData"

const getLocationInfo = async (location: any, serverData?: any): Promise<ILocationGroupData> => {
  let city =
    (await getTaxonomy(
      getNestedObject(location, "relationships.field_office_city.links.related.href") ?? "",
      false,
      serverData,
    )) ?? null
  // city = Array.isArray(city) ? city?.[0] : city

  let country =
    (await getTaxonomy(
      getNestedObject(location, "relationships.field_office_country.links.related.href") ?? "",
      true,
      serverData,
    )) ?? null
  // country = Array.isArray(country) ? country?.[0] : country
  const divisions = await getTaxonomy(
    getNestedObject(location, "relationships.field_office_division.links.related.href") ?? "",
    true,
    serverData,
  )
  let results: any = []
  if (divisions.length) {
    results = divisions.map((division: any) => ({
      address:
        getNestedObject(location, "attributes.body.processed") ??
        getNestedObject(location, "attributes.body.value") ??
        null,
      fieldOfficeFax: getNestedObject(location, "attributes.field_office_fax.value") ?? "",
      fieldOfficePhoneNumber:
        getNestedObject(location, "attributes.field_office_phone_number.value") ?? "",
      fieldOfficeTitle: getNestedObject(location, "attributes.field_office_title.value") ?? "",
      fieldOfficeWebsite: getNestedObject(location, "attributes.field_office_website.value") ?? "",
      city: city ?? "",
      country: country ?? "",
      division,
      divisionName: division?.taxonomyID,
      countryName: country?.[0].taxonomyID ?? null,
    }))
  }

  return results
}

const getLocationsConfig = async (serverData?: any) => {
  let locationData = await getConfigDisplayData(serverData)
  locationData = locationData?.data
  return {
    country_dropdown: {
      label: getNestedObject(locationData, "loc_country_label"),
      search_placeholder: getNestedObject(locationData, "loc_search_country_placeholder"),
      default: null,
      no_results: getNestedObject(locationData, "loc_dd_no_results_found"),
    },
    division_dropdown: {
      label: getNestedObject(locationData, "loc_division_label"),
      search_placeholder: getNestedObject(locationData, "loc_search_division_placeholder"),
      default: getNestedObject(locationData, "loc_default_division_text"),
      no_results: getNestedObject(locationData, "loc_dd_no_results_found"),
    },
    no_results_text: getNestedObject(locationData, "loc_no_results_found"),
    results_found_text: getNestedObject(locationData, "loc_search_results_found"),
    default_country: getNestedObject(locationData, "default_country"),
    address_labels: {
      fax: getNestedObject(locationData, "loc_fax_label"),
      phone: getNestedObject(locationData, "loc_ph_label"),
    },
    display_division_dropdown: getNestedObject(locationData, "loc_division_display") === 1,
  }
}

const getAllLocationList = async (type: string, serverData?: any) => {
  const allLocationDivision = await getApi(
    `/jsonapi/taxonomy_term/location_${type}`,
    true,
    serverData,
  )
  const allLocationDivisionData = allLocationDivision?.data ?? []

  return allLocationDivisionData.map((item: any) => {
    const divisionObj = {} as ILocationOption
    divisionObj.id = item?.attributes?.drupal_internal__tid
    divisionObj.value = item?.attributes?.drupal_internal__tid
    divisionObj.label = item?.attributes?.name
    return divisionObj
  })
}

const getLocationData = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getLocationData.name}.`)
  }
  const serverData = args?.[3] // accepting serverData only
  const [locationListing, locationConfig, allCountry, allDivision] = await Promise.all([
    getBlockData(`${url}`, false, serverData),
    getLocationsConfig(serverData),
    getAllLocationList("country", serverData),
    getAllLocationList("division", serverData),
  ])
  const locationData = locationListing?.data ?? []
  const fieldPaywallRoles = getAllRoles(locationData)
  const locationGroupData = await Promise.all(
    locationData.map(async (location: any) => getLocationInfo(location, serverData)),
  )
  return {
    locationConfig,
    allCountry,
    allDivision,
    locationGroupData: locationGroupData.flat(NUMBERS.ONE),
    fieldPaywallRoles,
  }
}

export default getLocationData
