import React from "react"
import { Anchor, Img } from "@atoms"
import { URL_TARGET } from "@helpers/constants/generic"
import { IMicroFooterLink, IMicrositeLinks } from "./_micrositeLinks.interface"

const MicrositeLinks = ({ microData, title }: IMicrositeLinks) => (
  <div className="microsite-wrapper">
    <div className="title-section">
      <span className="microsite-title">{title}</span>
    </div>
    <ul className="microsite-list">
      {microData?.map((childItem: IMicroFooterLink, index: any) => (
        <li key={index} style={{}}>
          <Anchor
            className="microsite-item"
            href={childItem.link}
            isExternal={childItem?.urlTarget === URL_TARGET.BLANK}
            tabindex={index}
            noUnderline={true}
            key={`a-${index}`}>
            <Img
              src={childItem.imgSrc}
              alt={childItem.alt}
              width={childItem?.width}
              height={childItem?.height}
              type="icon"
            />

            <p className="plain-text">{childItem.plainText}</p>
          </Anchor>
        </li>
      ))}
    </ul>
  </div>
)

export default MicrositeLinks
