import { isPaywallProtected } from "@utils/paywallUtilities"
import getArchieveYearData from "./getArchieveYearData"

const getEventFilterFromData = async (serverData?: any) => {
  let eventFormData = await getArchieveYearData(serverData)
  eventFormData = eventFormData?.data
  const options: any = []

  eventFormData?.map((option: any, key: number) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: isPaywallProtected(option?.attributes?.name).isPaywallProtectedFlag
        ? ""
        : option?.attributes?.name,
      label: isPaywallProtected(option?.attributes?.name).isPaywallProtectedFlag
        ? ""
        : option?.attributes?.name,
    })
  })
  return options
}

export default getEventFilterFromData
