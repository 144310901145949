import { AnchorButton, Column, RichText } from "@atoms"
import { IArticleBannerContentProps } from "./_articleBannerContent.interface"
import { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"

const ArticleTeaserBannerContent = (props: IArticleBannerContentProps) => {
  const { applicationTheme } = useContext(ApplicationThemeContext)
  const { bannerContentValue } = props
  const getClasses = () => {
    let themeClass = ""
    let themeFont = ""
    let contentAlignment = bannerContentValue
    if (
      applicationTheme === "rebranding" ||
      applicationTheme === "rebranding-japan" ||
      applicationTheme === "jubbonti" ||
      applicationTheme === "wyost" ||
      applicationTheme === "biosimilarsinbone" ||
      applicationTheme === "vagidonna"
    ) {
      if (props?.isOverlay === "on" && props?.isOverlayFontType === "light") {
        themeClass = "text-overlay-dark"
      } else if (props?.isOverlay === "on" && props?.isOverlayFontType === "dark") {
        themeClass = "text-overlay"
      }
      themeFont = props?.isOverlayFontType === "light" ? "font-light" : "font-dark"
    } 
    return `article-banner-content ${themeClass} ${themeFont} ${contentAlignment}`
  }
  return (
    <Column
      className={getClasses()}
      mobile={12}
      tablet={
        applicationTheme === "rebranding" ||
        applicationTheme === "rebranding-japan" ||
        applicationTheme === "jubbonti" ||
        applicationTheme === "wyost" ||
        applicationTheme === "biosimilarsinbone" ||
        applicationTheme === "vagidonna"
          ? 8
          : 12
      }
      desktop={
        applicationTheme === "rebranding" ||
        applicationTheme === "rebranding-japan" ||
        applicationTheme === "jubbonti" ||
        applicationTheme === "wyost" ||
        applicationTheme === "biosimilarsinbone" ||
        applicationTheme === "vagidonna"
          ? 6
          : 10
      }>
      {props.heading && <h2 className="article-banner-content-heading bold">{props.heading}</h2>}
      {props.content && (
        <RichText className="article-banner-content-paragraph" content={props.content} />
      )}
      <div className="button-container" >
      {props.link && (
        <AnchorButton
          className="article-banner-content-button"
          tabindex={1}
          href={props.link}
          isExternal={props.linkExternal}>
          {props.buttonLabel}
        </AnchorButton>
      )}
      {props.link2 && (
        <AnchorButton
          className="article-banner-content-button2"
          tabindex={2}
          href={props.link2}
          isExternal={props.linkExternal2 || false}
          >
          {props.buttonLabel2}
        </AnchorButton>
      )}
      
      </div>
    </Column>
  )
}

export default ArticleTeaserBannerContent
