import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Button, RichText } from "@atoms"
import SCOUT_BUTTON_STATE from "@helpers/constants/scoutButton"
import { IPopup } from "@molecules/popup/_popup.interface"
import { IArticleTeaserBannerProps } from "@organisms/articleBanner/_article-banner.interface"
import LoginPopup from "@organisms/loginPopup"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getAssetPrefixUrl, getProfileDetails } from "@utils/helper"
import { IEventCardScoutButtonProps } from "./_eventCardScoutButton.interface"
import { ILoginFormDataProps } from "@templates/layout/_layout.interface"
import { useRouter } from "next/router"
import { postApi } from "@utils/baseApi"

const EventCardScoutButton = (props: IEventCardScoutButtonProps) => {
  const [buttonState, setButtonState] = useState<string>(props?.buttonState)
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const router = useRouter()

  useEffect(() => {
    setButtonState(props?.buttonState)
  }, [props?.buttonState])

  const getClasses = (classname: string) => {
    const classes = [classname, props?.className].filter(Boolean)

    return classes.join(" ")
  }

  const user = useSelector((state: UserState) => state?.user) // Redux hook for getting user data from the store
  const userProfileData = getProfileDetails(user) // Function to get profile details from the user data

  // Props for the login popup component
  const PopupProps: IPopup = {
    showPopup: showLoginPopUp,
    showOverlay: showLoginPopUp,
    children: "",
    spinnerIcon: getAssetPrefixUrl("/assets/icons/spinner.svg"),
    closeIcon: {
      url: getAssetPrefixUrl("/assets/icons/close.svg"),
      altText: "close icon",
    },
    handlePopupShow: setShowLoginPopUp,
  }

  const { applicationHeaderData } = useContext(ApplicationContext) // Context for application header
  const loginFormProps: ILoginFormDataProps = applicationHeaderData?.headerData?.loginFormData // Login form data from context
  const bannerCTAProps: IArticleTeaserBannerProps = loginFormProps?.banner // Banner cta props

  // Function to handle the forgot password action
  const handleForgotPassword = () => {
    setShowLoginPopUp(false)
  }

  // Function to handle the login confirmation action
  const handleConfirmation = () => {
    setShowLoginPopUp(false)
  }

  // Function to handle the register action
  const handleClick = async (apiUrl: string) => {
    const data: any = {
      SCT_Attendee_Account__c: userProfileData?.scout_id,
      SCT_Event_Management__c: props?.eventId,
    }
    if (buttonState === SCOUT_BUTTON_STATE.INVITED && props?.invited?.attendeeAccId) {
      data.SCT_Attendee_Account__Id = props.invited.attendeeAccId
    }
    const response = await postApi(apiUrl, data)
    if (response?.button_state) {
      setButtonState(response?.button_state)
      router.reload()
    }
  }

  // Function to render the scout buttons based on button state
  const showScoutButtons = () => {
    switch (buttonState) {
      case SCOUT_BUTTON_STATE.REGISTER:
        return (
          <Button
            className={getClasses("scoutButton")}
            tabindex={1}
            onClick={
              user && user.isLoggedIn
                ? () => handleClick(props?.register?.apiUrl)
                : () => setShowLoginPopUp(true)
            }>
            {props?.register?.buttonCta}
          </Button>
        )
      case SCOUT_BUTTON_STATE.REGISTERED:
        return <RichText className={getClasses("scoutButtonMessage")} content={props?.registered?.message} />
      case SCOUT_BUTTON_STATE.INVITED:
        return (
          <>
            <RichText className={getClasses("scoutButtonMessage")} content={props?.invited?.message} />
            <Button
              tabindex={1}
              className={getClasses("scoutButton")}
              onClick={() => handleClick(props?.invited?.apiUrlAccept)}>
              {props?.invited?.buttonCtaAccept}
            </Button>
            <Button
              tabindex={2}
              className={getClasses("scoutButton")}
              isSecondary
              onClick={() => handleClick(props?.invited?.apiUrlReject)}>
              {props?.invited?.buttonCtaReject}
            </Button>
          </>
        )
      case SCOUT_BUTTON_STATE.NO_ACCESS:
        return <></>
      default:
        return <></>
    }
  }

  if (!buttonState) {
    return <></>
  }

  return (
    <React.Fragment key={props?.eventId}>
      {showScoutButtons()}
      <LoginPopup
        popup={PopupProps}
        form={loginFormProps}
        bannerCTA={bannerCTAProps}
        showPopup={showLoginPopUp}
        handlePopupShow={(popupShow, event: any) => {
          setShowLoginPopUp(popupShow)
        }}
        handleForgotPasswordShow={handleForgotPassword}
        loginConfirmation={handleConfirmation}
      />
    </React.Fragment>
  )
}

export default EventCardScoutButton
