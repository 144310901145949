import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import getConfigDisplayData from "./getConfigDisplayData"
import getSearchFacetsData from "./getSearchFacetsData"
import SEARCH_FACETS from "@helpers/constants/searchFacets"

const getEducationCategoryFilterData = async (serverData?: any) => {
  let CategoryData = await getConfigDisplayData(serverData)
  CategoryData = CategoryData?.data

  if (CategoryData) {
    const CategoryLabelData = {
      searchLabel: CategoryData.education_what_are_you_looking,
      searchPlaceholder: CategoryData.education_search_placeholder_text,
      searchCTAText: CategoryData.education_search_cta_text,
      AlphaLabel: CategoryData.education_all_category_text,
      FormLabel: CategoryData.education_from_text,
      clearLabel: CategoryData.education_search_clear_all_text,
      other_category_text: CategoryData.education_other_category_text,
      education_title: CategoryData.education_configuration_title_text,
      resultsFoundText: CategoryData.education_search_results_found,
      noResultsFoundText: CategoryData.educations_no_results_found_title,
      noResultsFoundDescription: CategoryData.education_no_results_found_body?.value,
      unSelectItemsLabel: CategoryData.search_unselect_items,
      applyAllfilters: CategoryData.education_apply_all_filters,
      itemsPerPage: CategoryData.education_count_for_each_page,
      dropwdown_placeholder_text: CategoryData?.education_select_option_text ?? "",
      education_search_default_config: CategoryData?.education_search_default_config ?? "",
      education_search_facets: CategoryData?.education_search_facets ?? [],
      education_label_profession: CategoryData?.education_label_profession ?? "",
      education_label_education_type: CategoryData?.education_label_education_type ?? "",
      education_label_event_type: CategoryData?.education_label_event_type ?? "",
      education_label_therapeutic_area: CategoryData?.education_label_therapeutic_area ?? "",
      education_label_disease_area: CategoryData?.education_label_disease_area ?? "",
      education_label_treatment_area: CategoryData?.education_label_treatment_area ?? "",
      education_label_audience: CategoryData?.education_label_audience ?? "",
      education_medication_type: CategoryData?.education_medication_type ?? "",
      education_education_brand: CategoryData?.education_education_brand ?? "",
      filter_toggle_label: CategoryData?.filter_toggle_label ?? "",
    }
    return CategoryLabelData
  }

  return {
    searchLabel: "",
    searchPlaceholder: "",
    searchCTAText: "",
    AlphaLabel: "",
    FormLabel: "",
    clearLabel: "",
    other_category_text: "",
    education_title: "",
    resultsFoundText: "",
    noResultsFoundText: "",
    noResultsFoundDescription: "",
    unSelectItemsLabel: "",
    applyAllfilters: "",
    itemsPerPage: "",
    dropwdown_placeholder_text: "",
    education_search_default_config: "",
    filter_toggle_label: "",
  }
}

const getFormattedObj = (objectList: any[]) => {
  const options: any = []
  objectList?.map((option: any, key: number) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

export const getFormattedEduCardsData = async (url: any) => {
  let educationCards = await getBlockData(url)
  const totalCount = Number(educationCards?.meta?.count) ?? 0
  const itemsPerPage = Number(educationCards?.number_of_items_per_page) ?? 0
  educationCards = educationCards?.data
  const fieldPaywallRoles = getAllRoles(educationCards)
  educationCards =
    educationCards && educationCards.length
      ? await Promise.all(
          educationCards?.map(async (eduCard: any) => {
            const isChildPaywalled = isPaywallProtected(
              getNestedObject(eduCard, "attributes.enable_paywall_block_level"),
            )?.isPaywallProtectedFlag
    
            if (isChildPaywalled) {
              return null
            }
            const imagePaywallData = isPaywallProtected(eduCard?.relationships?.field_image)
            const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
              ? getPaywalledImgData(imagePaywallData?.errorObj)
              : getImageMeta(eduCard?.relationships?.field_image)

            const taxonomies = await getTaxonomy(
              eduCard?.relationships?.field_category?.links?.related?.href,
              true,
            )
            const cardType = await getTaxonomy(
              eduCard?.relationships?.field_event_type?.links?.related?.href,
              true,
            )
            const specialityId =
              eduCard?.relationships?.field_specialty?.data?.[0]?.meta
                ?.drupal_internal__target_id ?? null

            const createdDate = moment(eduCard?.attibutes?.created).format("YYYY") ?? null
            return {
              educationCardContent: {
                id: eduCard?.attributes?.drupal_internal__nid ?? "",
                taxonomies: taxonomies ?? null,
                heading: isPaywallProtected(eduCard?.attributes?.title).isPaywallProtectedFlag
                  ? null
                  : eduCard?.attributes?.title ?? null,
                text: eduCard?.attributes?.field_body?.processed
                  ? eduCard?.attributes?.field_body?.processed
                  : null,
                time: isPaywallProtected(eduCard?.attributes?.field_time).isPaywallProtectedFlag
                  ? null
                  : eduCard?.attributes?.field_time ?? null,
                contentType: cardType?.[0]?.taxonomyLabel ?? null,
                buttonLabel: eduCard?.attributes?.field_primary_cta_link?.title
                  ? eduCard?.attributes?.field_primary_cta_link?.title
                  : null,
                buttonUrl: eduCard?.attributes?.field_primary_cta_link?.uri
                  ? filterURL(eduCard?.attributes?.field_primary_cta_link?.uri)
                  : null,
                bookmark: eduCard?.attributes?.status ? eduCard?.attributes?.status : null,
                buttonUrlExternal: eduCard?.attributes?.field_primary_cta_link?.options?.attributes
                  ?.target
                  ? isExternalLink(
                      eduCard?.attributes?.field_primary_cta_link?.options?.attributes?.target,
                    )
                  : null,
              },
              specialityId,
              createdDate: isPaywallProtected(eduCard?.attibutes?.created).isPaywallProtectedFlag
                ? null
                : createdDate,
              imageSrc: imageUrl ?? null,
              imgAlt: alt ?? null,
              styledimage: imageStyledUrl ?? null,
            }
          }),
        )
      : []
  return {
    educationCards: filterNullValues(educationCards),
    totalCount,
    itemsPerPage,
    fieldPaywallRoles,
  }
}

const getEducationCardsData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const EducationCategoryFilterData = await getEducationCategoryFilterData(serverData)
  const searchFacetData = await getSearchFacetsData(EducationCategoryFilterData, SEARCH_FACETS?.EDUCATION)
  const educationAdvanceFilterData = {
    advanceFilterData: {
      filtersOptions: searchFacetData,
      filtersLabels: {
        searchLabel: EducationCategoryFilterData?.searchLabel ?? "",
        searchPlaceholder: EducationCategoryFilterData?.searchPlaceholder ?? "",
        SearchCTAText: EducationCategoryFilterData?.searchCTAText ?? "",
        unselectFiltersCTA: EducationCategoryFilterData?.clearLabel ?? "",
        resultsFoundLabel: EducationCategoryFilterData?.resultsFoundText ?? "",
        noResultsFoundLabel: EducationCategoryFilterData?.noResultsFoundText ?? "",
        noResultsFoundDescription: EducationCategoryFilterData?.noResultsFoundDescription ?? "",
        applyFiltersCTA: EducationCategoryFilterData?.applyAllfilters ?? "",
        unSelectItemsLabel: EducationCategoryFilterData?.unSelectItemsLabel ?? "",
        enableToggle: EducationCategoryFilterData?.education_search_default_config,
        filterIconName: EducationCategoryFilterData?.filter_toggle_label ?? "",
      },
    },
    filterTitle: EducationCategoryFilterData?.education_title ?? "",
  }

  return {
    educationAdvanceFilterData,
  }
}

export default getEducationCardsData
