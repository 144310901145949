import { Column, Img } from "@atoms"
import getDirection from "@utils/direction"
import OpinionLeadersCardContent from "../../molecules/opinionLeadersCardContent"
import { IOpinionLeadersCardProps } from "./_opinionLeadersCard.interface"

const OpinionLeadersCard = (props: IOpinionLeadersCardProps) => {
  const { opinionLeadersCardImage } = props
  const { opinionLeadersCardContent } = props
  return (
    <Column desktop={4} tablet={4} mobile={12} className="opinion-leaders-card">
      <div className="card" dir={getDirection()}>
        {opinionLeadersCardImage.src ? (
          <Img
            type="card"
            styledimage={opinionLeadersCardImage?.styledimage}
            src={opinionLeadersCardImage.src}
            alt={opinionLeadersCardImage.alt}
            width={368}
            height={220}
            className="opinion-leaders-card-image"
          />
        ) : (
          <div className="img-placeholder"> </div>
        )}
        <div className="card-body">
          <OpinionLeadersCardContent {...opinionLeadersCardContent} />
        </div>
      </div>
    </Column>
  )
}
export default OpinionLeadersCard
