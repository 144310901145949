import getBreadcrumbs from "@helpers/dataFunctions/getBreadcrumbs"
import getGenericWebFormData from "@helpers/dataFunctions/getGenericWebFormData"
import { useCallback } from "react"

const useCheckoutHooks = () => {
  const fetchBillingFormFields = useCallback(async () => {
    const billingFormFields = await getGenericWebFormData("/solarapi/checkout_form/fields")
    return billingFormFields
  }, [])

  const getPageBreadcrumbs = async (breadcrumbObj: any,referer?:any) => {
    const breadcrumbs = await getBreadcrumbs("", breadcrumbObj,referer)
    return breadcrumbs
  }

  return {
    fetchBillingFormFields,
    getPageBreadcrumbs,
  }
}

export default useCheckoutHooks
