import { Img } from "@atoms"
import { ISmartImageProps } from "./_smartImage.interface"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"
import { useMemo } from "react"

const SmartImage = (props: ISmartImageProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)")

  const shouldRenderDesktopImage = useMemo(() => {
    return !isMobile || (isMobile && !props?.mobileImage?.src)
  }, [props.mobileImage, isMobile])

  const imageComp = useMemo(() => {
    if (shouldRenderDesktopImage && props.desktopImage && props.desktopImage.styledimage) {
      const { height: desktopHeight, width: desktopWidth, ...rest } = props.desktopImage
      const width = isMobile ? props?.mobileImage?.width : desktopWidth // send mobile height for hero banner when rendering desktop images
      const height = isMobile ? props?.mobileImage?.height : desktopHeight
      return (
        <Img
          type="hero_banner"
          styledimage={props?.desktopImage?.styledimage}
          {...rest}
          width={width}
          height={height}
        />
      )
    }

    if (!shouldRenderDesktopImage && isMobile && props.mobileImage?.src) {
      return <Img type="hero_banner" {...props.mobileImage} />
    }
    return <Img type="hero_banner" {...props?.desktopImage} />
  }, [props.desktopImage, props.desktopImage.styledimage, props.mobileImage, shouldRenderDesktopImage, isMobile])

  return imageComp ?? <></>
}

export default SmartImage
