import { RichText, } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IFeatureCardContentProps } from "./_featureCardContent.interface"

const FeatureCardContent = ({ title, description, cardAlignment }: IFeatureCardContentProps) => {
  return (
    <div className={"feature-card-content " + cardAlignment}>
      {title && <h4 className="feature-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>}
      {description && (
        <div className="feature-card-desc">
          <RichText content={description} characterLimit={CHARLIMIT.DESCRIPTION} />
        </div>
      )}
    </div>
  )
}

export default FeatureCardContent
