import moment from "moment"
import { IMediaCardProps } from "@organisms/mediaLibraryCard/_mediaCard.interface"
import { Anchor, AnchorButton, Icon, RichText, Taxonomy } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import { convertFromBytes } from "utils/mediaLibrary"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { getApiDomainAndLang } from "@utils/baseApi"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"

const MediaCardContent = ({
  view,
  fieldMediaSpecialty,
  title,
  changedDate,
  body,
  readMore,
  mediaLibConfig,
  downloadFileLink,
  fileSize,
  taxonomies,
}: IMediaCardProps) => {
  const { download_text_cta: downloadTextCTA, read_more_text_cta: readMoreTextCTA } = mediaLibConfig
  const isGrid = view === mediaLibraryConstants.GRID_VIEW
  const getClasses = (nameOfClass: string) =>
    isGrid ? nameOfClass : `${nameOfClass} ${nameOfClass}-list`
  const { lang } = getApiDomainAndLang()
  const { applicationConfiguration } = useContext(ApplicationContext)

  return (
    <div className={getClasses("media-lib-card-content")}>
      <div className="media-lib-card-content-container">
        {fieldMediaSpecialty && (
          <div className="media-lib-card-content-card-title">
            {/* <Taxonomy>{trimText(fieldMediaSpecialty, CHARLIMIT.TAXONOMY)}</Taxonomy> */}
            <Taxonomy taxonomies={taxonomies} />
          </div>
        )}

        <Anchor href={readMore} tabindex={0}>
          <h4 className={getClasses("media-lib-card-content-header bold")}>
            {trimText(title, CHARLIMIT.TITLE)}
          </h4>
        </Anchor>
        {changedDate && (
          <h5 className="media-lib-card-content-time-section">
            {moment(Date.parse(changedDate)).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}
          </h5>
        )}
        {body && (
          <RichText
            className={getClasses("media-lib-card-content-text")}
            content={body || ""}
            characterLimit={CHARLIMIT.DESCRIPTION}
          />
        )}
        {downloadFileLink && fileSize && (
          <h5 className="media-lib-card-content-file-size">
            <div>{`${downloadFileLink.split(".").pop()?.toUpperCase()}, `}</div>
            <div>{convertFromBytes(fileSize)}</div>
          </h5>
        )}
      </div>
      <div className={getClasses("media-lib-card-content-button")}>
        {downloadFileLink && fileSize && (
          <AnchorButton
            href={downloadFileLink}
            className="dwnld"
            isExternal={true}
            tabindex={2}
            icon={<Icon iconName="DownloadIcon" />}
            isSecondary
            download={true}>
            {downloadTextCTA}
          </AnchorButton>
        )}
        {readMore && (
          <AnchorButton
            href={readMore}
            isExternal={false}
            className="read-more"
            tabindex={2}
            isSecondary>
            {readMoreTextCTA}
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

export default MediaCardContent
