import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData } from "."
import getAnchorCTAData from "./getAnchorCTAData"
import getCommonCTAData from "./getCommonCTAData"
import getCTAButtonGroupData from "./getCTAButtonGroupData"
import getSocialShareCTAData from "./getSocialShareCTAData"
import getNestedObject from "@utils/nestedObjects"

const BUTTON_TYPES = {
  COMMON: "block_content--common_cta",
  ANCHOR: "block_content--anchor_cta",
  SOCIAL_SHARE: "block_content--social_sharing",
  CTA_BUTTON: "block_content--cta_button",
}

const getButtonGroup = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let rawData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  rawData = rawData?.included

  if (!rawData) {
    return null
  }
  
  const buttons = await Promise.all(
    rawData.map(async (button: any) => {
      switch (button.type) {
        case BUTTON_TYPES.COMMON:
          const commonCtaBtn = await getCommonCTAData("", { data: button })
          return commonCtaBtn?.isParentPaywalled ? null : commonCtaBtn
        case BUTTON_TYPES.ANCHOR:
          const anchorCtaBtn = await getAnchorCTAData("", { data: button })
          return anchorCtaBtn?.isParentPaywalled ? null : anchorCtaBtn
        case BUTTON_TYPES.SOCIAL_SHARE:
          const socialShareCta = await getSocialShareCTAData("", button)
          return socialShareCta?.isParentPaywalled ? null : socialShareCta
        case BUTTON_TYPES.CTA_BUTTON:
          const btnGroupCta = await getCTAButtonGroupData("", button)
          return btnGroupCta?.isParentPaywalled ? null : btnGroupCta

        default:
          return null
      }
    }),
  )
  return {
    buttons: buttons.filter(Boolean), // filter all non-null values
    isParentPaywalled,
    fieldPaywallRoles,
  }
}

export default getButtonGroup
