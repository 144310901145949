import * as actionType from "../ActionTypes"

const initialUsers: any = {
  profile: undefined,
  user: undefined,
  isLoggedIn: false
}

const UserReducer = (state: any = initialUsers, action: UserAction): any => {
  switch (action.type) {
    case actionType.USER_DATA:
      return {
        ...state,
        user: action.user,
        isLoggedIn: true,
      }

    case actionType.RESET_USER:
      return {
        ...state,
        user: action.user,
        profile: undefined,
        isLoggedIn: false,
      }

    case actionType.SET_USER_PROFILE:
      return {
        ...state,
        profile: action.profile
      }

    case actionType.GET_USER_PROFILE:
      return state

    default:
      return state
  }
}

export default UserReducer
