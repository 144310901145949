import { ModalHeader, ModalBody, ModalFooter } from "@atoms"
import { ModalPortal } from "@molecules"
import { IExternalLinkModalProps } from "./_externalLinkModal.interface"

const ExternalLinkModal = (props: IExternalLinkModalProps) => {
  const { header, content, footer, onClose = () => null, onAction = () => null } = props
  const handleClose = () => {
    onClose()
  }
  const handleAction = () => {
    onAction()
  }

  const modalContent = (
    <>
      <ModalHeader {...header} isHCP onClose={handleClose} />
      <ModalBody {...content} />
      <ModalFooter {...footer} onClose={handleClose} onAction={handleAction} />
    </>
  )

  return <ModalPortal modalContent={modalContent} wrapperId="react-portal-wrapper" />
}

export default ExternalLinkModal
