import { getApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"

const getDecisionTreeData = async (url: string) => {
    if (!url) throw consoleError(`URL parameter is undefined in ${getDecisionTreeData.name}.`)
    
    const response = await getApi(url)

    const displayLabels = {
        startBtn: response?.attr?.start_btn_text || "",
        backBtn: response?.attr?.back_btn_text || "",
    }
    const treeData = response?.decision_tree ? Object.values(response?.decision_tree)?.[0] : response?.decision_tree

    return {
        displayLabels,
        treeData
    }
}

export default getDecisionTreeData