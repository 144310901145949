import React from "react"
import IFRAME_CONSTANTS from "@helpers/constants/iframeWrapper"
import { Iframe } from "@atoms"
import { IIframeWrapper } from "./_iframeWrapper.interface"

const IframeWrapper = ({ iframeURL, width, height, title }: IIframeWrapper) => {

  return (
    <Iframe
      iframeURL={iframeURL}
      width={width || IFRAME_CONSTANTS.WIDTH}
      height={height || IFRAME_CONSTANTS.HEIGHT}
      title={title}
      scroll={true}
    />
  )
}
export default IframeWrapper
