import * as actionTypes from "../ActionTypes"

export const updateMagazineTaxonomy = (magTaxonomy: Array<any>) => {
    const action: any = {
        type: actionTypes.ADD_MAGAZINE_TAXONOMY,
        taxonomy: magTaxonomy
    }
    return performRequest(action)
}

export const setMagazineLayout = (layout: Array<string>, initialItemToLoad: number) => {
    const action: any = {
        type: actionTypes.MAGAZINE_LAYOUT,
        layout,
        initialItemToLoad,
    }
    return performRequest(action)
}

export const setMagazineData = (magazineDataWithLayout: Array<any>) => {
    const action: any = {
        type: actionTypes.MAGAZINE_DATA,
        magazineDataSource: magazineDataWithLayout
    }
    return performRequest(action)
}

export const setMagazineTempDataSource = (magazineArray: Array<any>) => {
    const action: any = {
        type: actionTypes.MAGAZINE_TEMP_DATA,
        magazineTempDataSource: magazineArray
    }
    return performRequest(action)
}

export const clearMagazineTempDataSource = () => {
    const action: any = {
        type: actionTypes.CLEAR_MAGAZINE_TEMP_DATA,
        magazineTempDataSource: []
    }
    return performRequest(action)
}

export const setMagazineParentTab = (parentTabTitle: string) => {
    const action: any = {
        type: actionTypes.MAGAZINE_PARENT_TAB,
        parentTab: parentTabTitle
    }
    return performRequest(action)
}

export const setMagazineChildTab = (childTabTitle: string) => {
    const action: any = {
        type: actionTypes.MAGAZINE_CHILD_TAB,
        childTab: childTabTitle
    }
    return performRequest(action)
}

const performRequest = (action: UserAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}