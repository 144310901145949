import getNestedObject from "@utils/nestedObjects"
import { consoleError } from "@utils/error"
import { URL_TARGET } from "@helpers/constants/generic"
import { filterNullValues, getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData } from "."

const getQuickLinkData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getQuickLinkData.name}.`)
  const serverData = args?.[3] // accepting serverData only
  const rawData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const quickLinkTitle = getNestedObject(rawData?.data, "attributes.field_qc_title.processed") ?? ""
  const cardGroupAlignment = getNestedObject(rawData?.data,"attributes.field_card_alignment_style") ?? "left"
  let cardInfo = []
  if (rawData !== undefined && rawData.included && Array.isArray(rawData.included)) {
    cardInfo = await Promise.all(
      rawData?.included?.map(async (card: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(card, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        return {
          field_quick_link_card_title:
          getNestedObject(card, "attributes.field_quick_link_card_title.value") ?? "",
        body:
          getNestedObject(card, "attributes.body.processed") ??
          getNestedObject(card, "attributes.body.value") ??
          null,
        field_card_link: filterURL(getNestedObject(card, "attributes.field_card_link.uri")) ?? "",
        field_card_title: getNestedObject(card, "attributes.field_card_link.title") ?? "",
        field_link_target:
          filterURL(getNestedObject(card, "attributes.field_card_link.options.attributes.target")) ??
          URL_TARGET.SELF,
        cardAlignment: getNestedObject(card, "attributes.field_text_alignment_style") ?? "left",
        referenceId: getNestedObject(card, "attributes.field_reference_id") ?? ""
        }
      }),
    )
  }

  return {
    cardInfo: filterNullValues(cardInfo),
    quickLinkTitle,
    fieldPaywallRoles,
    cardGroupAlignment,
    isParentPaywalled,
  }
}

export default getQuickLinkData
