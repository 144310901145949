import React, { useEffect, useState } from "react"
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css"
import { Calendar, DayValue } from "@hassanmojab/react-modern-calendar-datepicker"
import { Column } from "@atoms"
import moment from "moment"
import { EventDatePickerProps } from "./_event-datepicker.interface"
import EventCardHorizontal from "../eventCardHorizontal"
import { IEventCardHorizontalProps } from "../eventCardHorizontal/_eventCardHorizontal.interface"
import CarouselWrapper from "../carouselWrapper"
import { getApiDomainAndLang } from "@utils/baseApi"

const EventDatePicker = (props: EventDatePickerProps) => {
  const defaultValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  }
  const [selectedDay, setSelectedDay] = useState<DayValue>(defaultValue)
  const [selectedEvents, setSelectedEvents] = useState<IEventCardHorizontalProps[]>([])
  const { lang } = getApiDomainAndLang()

  useEffect(() => {
    if (selectedDay) {
      const { day, month, year } = selectedDay
      const filteredEvents = props.events.filter(
        (event) =>
          event?.eventCardHorizontalContent?.startDate?.substring(
            0,
            event?.eventCardHorizontalContent?.startDate?.indexOf("T"),
          ) === `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`,
      )
      setSelectedEvents(filteredEvents)
    }
    if (!selectedDay) {
      const { day, month, year } = extractDMY(
        moment().locale(lang).format("YYYY-MM-DDTHH:mm:ss+00:00"),
      )
      const today = { day, month, year }
      setSelectedDay(today)
    }
  }, [selectedDay])
  const extractDMY = (date: string) => {
    const dateObj = new Date(date)
    const year = dateObj.getFullYear()
    const month = dateObj.getMonth() + 1
    const day = dateObj.getDate()
    const eventDay = { day, month, year }

    return {
      ...eventDay,
      className:
        JSON.stringify(selectedDay) === JSON.stringify(eventDay)
          ? "event-selected"
          : "event-unselected",
    }
  }

  const renderEvents = () => {
    if (selectedEvents.length > 0) {
      return selectedEvents?.map((event, key) => (
        <EventCardHorizontal
          key={key}
          eventCardHorizontalContent={event.eventCardHorizontalContent}
          eventCardHorizontalImage={event.eventCardHorizontalImage}
          eventCardHDesktopColumn={12}
        />
      ))
    }
  }

  return (
    <>
      <Column desktop={4} tablet={12} mobile={12}>
        <div className="event-datepicker">
          <Calendar
            calendarClassName="custom-cal"
            value={selectedDay}
            onChange={(date: DayValue) => setSelectedDay(date)}
            calendarTodayClassName="current-date"
            customDaysClassName={props.events.map((event, key) =>
              extractDMY(event.eventCardHorizontalContent.startDate),
            )}
          />
        </div>
      </Column>
      <Column desktop={8} tablet={12} mobile={12} className="event-datepicker-carousel">
        {selectedEvents.length > 0 ? (
          <CarouselWrapper
            className="event-datepicker-carousel"
            slideToShow={props.slideToShow}
            slideToShowTablet={1}>
            {renderEvents()}
          </CarouselWrapper>
        ) : (
          <div className="no-events">No events found ...</div>
        )}
      </Column>
    </>
  )
}

export default EventDatePicker
