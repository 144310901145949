import {
 Column, Icon, Img, Video,
} from "@atoms"
import getDirection from "@utils/direction"
import { memo, useState } from "react"
import VideoPlayerModal from "@organisms/videoPlayerModal"
import VideoTeaserCardContent from "../../molecules/videoTeaserCardContent"
import { IVideoTeaserCardProps } from "./_videoTeaserCard.interface"

const VideoTeaserCard = (props: IVideoTeaserCardProps) => {
  const [showModal, setShowModal] = useState(false)
  const onClose = () => {
    setShowModal(false)
  }
  const videoTeaserCardContentData = props.videoTeaserCardContent

  return (
    <Column desktop={4} tablet={4} mobile={12} className="video-teaser-card">
      <div className="card" dir={getDirection()}>
        {props?.video_configuration ? (
          <>
            <div className="video-teaser-card-image-wrapper" onClick={() => setShowModal(true)}>
              {props?.image?.src && (
                <Img
                  type="card"
                  styledimage={props?.image?.styledimage}
                  src={props?.image?.src}
                  alt={props?.image?.alt}
                  width={372}
                  height={215}
                  className="video-teaser-card-image"
                />
              )}
              <div className="overlay-icon" onClick={() => setShowModal(true)}>
                <Icon iconName="PlayIcon" />
              </div>
            </div>

            {showModal && (
              <VideoPlayerModal
                titleText={videoTeaserCardContentData?.heading}
                src={props?.videoUrl}
                onClose={onClose}
              />
            )}
          </>
        ) : (
          <Video src={props?.videoUrl} key={videoTeaserCardContentData.id} />
        )}

        <VideoTeaserCardContent {...videoTeaserCardContentData} />
      </div>
    </Column>
  )
}
export default memo(VideoTeaserCard)
