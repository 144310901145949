import { IFlexiTabContentProps } from "./_flexitabcontent.inferface"
import Flexible from "../../templates/flexible"

const FlexiTabContent = (props: IFlexiTabContentProps) => {
  return (
    <div className="flexi-tab-content">
      <Flexible {...props.content} isPaywall3Hidden={true} />
    </div>
  )
}

export default FlexiTabContent
