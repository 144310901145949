import { Img } from "@atoms"
import BARCODE_CONSTANT from "@helpers/constants/barcode"
import { IBarcodeContent } from "./_barcodeContent.interface"

const BarcodeContent = ({ label, value, barcode_image, isPimProduct }: IBarcodeContent) => {
  // Check if it's not a PIM product
  if (!isPimProduct) {
    // If value is not present or empty, do not render anything
    if (!value?.trim()) {
      return null
    }
    // Render barcode information for non-PIM products
    return (
      <div className="barcode">
        {label && <h5 className="barcode-title">{label}</h5>}
        {value && <p className="barcode-desc">{value}</p>}
        {barcode_image && (
          <Img
            src={barcode_image.barcode_uri}
            width={BARCODE_CONSTANT.IWIDTH}
            height={BARCODE_CONSTANT.IHEIGHT}
            alt={barcode_image.barcode_alt}
          />
        )}
      </div>
    )
  }

  // Check for PIM products without barcode image
  if (!barcode_image) {
    // If value is not present or empty, do not render anything
    return value && value?.trim() ? (
      <div className="barcode">
        {label ? (
          <h5 className="barcode-title">{label}</h5>
        ) : (
          <h5 className="barcode-title">&nbsp;</h5>
        )}
        <p className="barcode-desc">{value}</p>
      </div>
    ) : (
      <></>
    )
  }

  // Render barcode image for PIM products with barcode
  return barcode_image?.barcode_uri ? (
    <div className="barcode">
      <Img
        src={barcode_image.barcode_uri}
        width={BARCODE_CONSTANT.IWIDTH}
        height={BARCODE_CONSTANT.IHEIGHT}
        alt={barcode_image.barcode_alt}
      />
    </div>
  ) : (
    <></>
  )
}

export default BarcodeContent
