import { IProfilePattern } from "./_profilepattern.interface"

const ProfilePattern = (props: IProfilePattern) => {
  const getClasses = () => {
    const classes = []
    classes.push("profile-infographics")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <div className="pattern-bg">
      <div className={getClasses()} />
    </div>
  )
}

export default ProfilePattern
