import { Anchor, Column, Img } from "@atoms"
import TherapyCardContent from "../../molecules/therapyCardContent"
import { ITherapyCardProps } from "./_therapyCard.interface"
import { modifyRelatedContentData } from "@utils/relatedContentUtil"

const TherapyCard = (props: ITherapyCardProps) => {
  const therapyCardData = props?.relatedContentFlag
    ? modifyRelatedContentData(props?.therapyCardContent)
    : props?.therapyCardContent
  return (
    <Column
      desktop={props.relatedContentFlag ? 12 : 4}
      tablet={props.relatedContentFlag ? 12 : 6}
      mobile={12}
      className="therapy-area-card">
      <div className="card text-center">
        {props?.image?.src && (
          <div className="image-section">
            <Anchor
              noUnderline
              tabindex={0}
              href={props?.therapyCardContent.buttonUrl}
              isExternal={props?.therapyCardContent.isButtonUrlExternal}>
              <Img
                type="card"
                styledimage={props?.image?.styledimage}
                src={props?.image?.src}
                alt={props?.image?.alt}
                width={368}
                height={220}
                className="card-img-top"
                islazyloaded={!props?.relatedContentFlag ?? false}
              />
            </Anchor>
          </div>
        )}
        <div className="card-body text-center">
          <TherapyCardContent {...therapyCardData} />
        </div>
      </div>
    </Column>
  )
}
export default TherapyCard
