import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  userDetailsFilters: [] as any,
  currentPage: 0,
  searchText: "",
  sort: {
    column: "",
    order: "ASC",
  },
}

const UserDetailSearchReducer = (state: any = initialFilterData, action: UserDetailsFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_USER_DETAILS_FILTERS:
      return {
        ...state,
        userDetailsFilters: action.userDetailsFilters,
        currentPage: 0,
        sort: {
          column: "",
          order: "ASC",
        },
      }
      case actionType.UPDATE_USER_DETAILS_PAGE_NUMBER:
        return {
          ...state,
          currentPage: action.currentPage,
        }
      case actionType.UPDATE_USER_DETAILS_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          currentPage: 0,
          sort: {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_ALL_USER_DETAILS_FILTERS:
        return {
          ...state,
          userDetailsFilters: action.userDetailsFilters,
          currentPage: action.currentPage,
          searchText: action.searchText,
          sort: action?.sort || {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_USER_DETAILS_SORT:
      return {
        ...state,
        sort: action.sort,
      }
    default:
      return state
  }
}

export default UserDetailSearchReducer