import RichText from "@atoms/richText"
import { IModalBodyProp } from "./_modalBody.interface"

const ModalBody = ({ content, className = "", isRichText = false }: IModalBodyProp) => {
  const getClasses = () => {
    const classes = ["modal-body", className].filter(Boolean)
    return classes.join(" ")
  }

  if (isRichText) {
    return <RichText content={content as string} className={getClasses()} />
  }

  return <div className={getClasses()}>{content}</div>
}

export default ModalBody
