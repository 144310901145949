import {
  filterNullValues,
  getAllRoles,
  isPaywallProtected,
} from "@utils/paywallUtilities"
import { getBlockData } from "."
import getNestedObject from "@utils/nestedObjects"
import NUMBERS from "@helpers/constants/numbers"
import { consoleError } from "@utils/error"
import getArticleTeaserCompositionData from "./getArticleTeaserCompositionData"
import getRotatingHeroBannerData from "./getRotatingHeroBannerData"
import getLinkIconData from "./getLinkIconData"
import { getApi } from "@utils/baseApi"
import getProductTeaserCardData from "./getProductTeaserCardData"

enum CAROUSEL_BLOCK_TYPE {
  HERO_BANNER = "hero_banner",
  ARTICLE_TEASER_COMPOSITION_BANNER = "article_teaser_composition",
  MENU_LINK_WITH_ICON = "menu_link_with_icon",
  PRODUCT_TEASER_CARDS = "pim_product_teaser",
}

const getFlexibleCarouselData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getFlexibleCarouselData.name}.`)
  }
  let imageCarouselData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(imageCarouselData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(imageCarouselData)
  const duration = Number(
    getNestedObject(imageCarouselData, "data.attributes.field_slide_duration") ?? NUMBERS.ZERO,
  )

  const itemPerSlide = Number(
    getNestedObject(imageCarouselData, "data.attributes.field_number_of_items_per_slide_") ??
      NUMBERS.ONE,
  )

  const carouselDisplayType = imageCarouselData?.data?.attributes?.field_display_type
  const carouselBlockType = imageCarouselData?.data?.attributes?.field_block_type

  const rotatingHeroBanerData = async (slide: any) => {
    try {
      const slideData = slide
      let rotatingHeroBannerData = slide
        ? await getRotatingHeroBannerData("url", 0, 0, slideData)
        : null
      let result
      if (rotatingHeroBannerData) {
        result = rotatingHeroBannerData?.slidesInfo
      }
      return result
    } catch (error) {
      console.error("Error in articlecomData:", error)
      throw error
    }
  }

  const articleCompositionData = async (slide: any) => {
    try {
      const slideData = { data: slide }
      let articleTeaserCompositionData = slide
        ? await getArticleTeaserCompositionData("url", 0, 0, slideData)
        : null
      const result = { data: {} }
      if (articleTeaserCompositionData) {
        result.data = articleTeaserCompositionData
      }
      return result
    } catch (error) {
      console.error("Error in articlecomData:", error)
      throw error
    }
  }

  const menulinkData = async (slide: any) => {
    try {
      const slideData = slide
      let menuLinkIconData = slide ? await getLinkIconData("url", 0, 0, slideData) : null
      const result = { data: {} }
      if (menuLinkIconData) {
        result.data = menuLinkIconData
      }
      return result
    } catch (error) {
      console.error("Error in menulinkdata:", error)
      throw error
    }
  }

  const productTeaserCardsData = async (slide: any) => {
    try {
      const slideData = slide
      let pimProductTeaserData = slide
        ? await getProductTeaserCardData(
            slideData,
            imageCarouselData?.data?.attributes?.enable_paywall_block_level,
          )
        : null

      return pimProductTeaserData
    } catch (error) {
      console.error("Error in pimProductTeaserData:", error)
      throw error
    }
  }

  const getSlidesForProductTeaser = async (slidesData: any) => {
    const object = await getApi(slidesData?.restapi_endpoint, false)
    return object
  }

  const getSlidesInfoForProductTeaser = async (slides: any) => {
    const slidesForProductTeaser = [] as any[]
    slides?.map((item: any) => {
      slidesForProductTeaser?.push(item)
    })
    const productTeaserArray = await productTeaserCardsData(slidesForProductTeaser) ?? []
    let filteredProducts = productTeaserArray.filter(Boolean)
    return filteredProducts && filteredProducts?.map((item: any) => {
      return {
        blockdata: item,
        duration: Number(
          getNestedObject(imageCarouselData, "data.attributes.field_duration") ?? NUMBERS.ZERO,
        ),
        carouselDisplayType,
        carouselBlockType,
        itemPerSlide: Number(
          getNestedObject(imageCarouselData, "data.attributes.field_number_of_items_per_slide_") ??
            NUMBERS.ZERO,
        ),
      }
    })
  }

  const slides =
    carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
      ? imageCarouselData?.data?.relationships?.field_pim_product_teaser?.data ?? []
      : imageCarouselData?.included ?? []
  const getRotatingData = await rotatingHeroBanerData(imageCarouselData)
  const slidesInfo =
    carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
      ? await Promise.all(slides?.map(async (item: any) => await getSlidesForProductTeaser(item)))
      : await Promise.all(
          slides.map(async (slide: any) => {
            const isChildPaywalled = isPaywallProtected(
              getNestedObject(slide, "attributes.enable_paywall_block_level"),
            )?.isPaywallProtectedFlag

            if (isChildPaywalled) {
              return null
            }
            const { ...isImageClickableData } = await rotatingHeroBanerData(slide)

            let blockData

            switch (carouselBlockType) {
              case CAROUSEL_BLOCK_TYPE.HERO_BANNER:
                blockData = await rotatingHeroBanerData(slide)
                break
              case CAROUSEL_BLOCK_TYPE.ARTICLE_TEASER_COMPOSITION_BANNER:
                blockData = await articleCompositionData(slide)
                break
              case CAROUSEL_BLOCK_TYPE.MENU_LINK_WITH_ICON:
                blockData = await menulinkData(slide)
                break
              default:
                throw new Error("Invalid carousel block type")
            }

            return {
              blockdata: blockData,
              eventBannerContent: null,
              uploadType:
                getNestedObject(slide, "attributes.field_hero_banner_upload_type") ?? null,
              referenceField:
                getNestedObject(slide, "attributes.field_reference_field.value") ?? null,
              duration: Number(
                getNestedObject(imageCarouselData, "data.attributes.field_duration") ??
                  NUMBERS.ZERO,
              ),
              isImageClickable: isImageClickableData?.isImageClickable ?? null,
              carouselDisplayType,
              carouselBlockType,
              itemPerSlide: Number(
                getNestedObject(
                  imageCarouselData,
                  "data.attributes.field_number_of_items_per_slide_",
                ) ?? NUMBERS.ZERO,
              ),
            }
          }),
        )

  return {
    duration,
    carouselDisplayType,
    carouselBlockType,
    itemPerSlide,
    slidesInfo:
      CAROUSEL_BLOCK_TYPE.HERO_BANNER === carouselBlockType
        ? getRotatingData
        : carouselDisplayType === CAROUSEL_BLOCK_TYPE.PRODUCT_TEASER_CARDS
        ? await getSlidesInfoForProductTeaser(filterNullValues(slidesInfo))
        : filterNullValues(slidesInfo),
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getFlexibleCarouselData
