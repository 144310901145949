import { RotatingTab, Tabs } from "@molecules"
import { IMagazineRotatingTabsProps } from "./_magazineRotatingTabs.interface"

const MagazineRotatingTabs = ({
  tabContent,
  setMagazineParentTab,
  setMagazineChildTab,
}: IMagazineRotatingTabsProps) => {
  const selectedTabCallBack = (title: string) => {
    setMagazineParentTab(title)
  }

  const selectedRotatatingTabCallback = (title: string) => {
    setMagazineChildTab(title)
  }

  const magRotaingContent = (rawData: any) => {
    if (rawData) {
      return rawData.map((tabData: any, index: number) => (
        <RotatingTab
          key={`ic-${index}`}
          links={tabData?.links}
          selectedRotatatingTabCallback={selectedRotatatingTabCallback}
        />
      ))
    }
  }

  return (
    <div className="magazine-tabs-container">
      <Tabs
        tabHeadItems={tabContent?.tabHeadItems}
        activeItem={tabContent?.activeItem}
        tabContents={magRotaingContent(tabContent?.tabContents)}
        selectedTabCallBack={selectedTabCallBack}
      />
    </div>
  )
}

export default MagazineRotatingTabs
