import { Anchor, Icon } from "@atoms"
import { handleDataLayerNavigationHeader } from "@utils/gtmUtilsHelpers"
import React, { useEffect, useRef, useState } from "react"
import Carousel from "react-simply-carousel"
import { useMediaQuery } from "@helpers/hooks"
import { IEyebrowMenuProps } from "./_eyebrowMenu.interface"

const EyebrowMenu = ({ logo, links, topPosition, scrollAmount }: IEyebrowMenuProps) => {
  const refMenuLinks = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const scrollVal = scrollAmount ?? 0

  const setArrowsState = () => {
    const menuLinks = refMenuLinks.current as any | null
    if (menuLinks) {
      setShowRightArrow(
        menuLinks.clientWidth + menuLinks.scrollLeft < menuLinks.scrollWidth - scrollVal / 2,
      )
      setShowLeftArrow(menuLinks.scrollLeft > 0)
    }
  }

  const scroll = (direction: "left" | "right") => {
    const menuLinks = refMenuLinks.current as any | null
    if (menuLinks) {
      menuLinks.scrollLeft += direction === "left" ? -scrollVal : scrollAmount
      setArrowsState()
    }
  }

  useEffect(() => {
    setArrowsState()
    window.addEventListener("resize", setArrowsState)
  }, [])

  const [activeSlide, setActiveSlide] = useState(0)

  const isMobile = useMediaQuery("(max-width: 767px)")

  return (
    <div className="eyebrow-menu" style={{ top: `${topPosition}px` }}>
      <div className="eyebrow-menu-container">
        {logo && <img src={logo} alt="logo" className="eyebrow-menu-logo" />}
        {!isMobile ? (
          <>
            {showLeftArrow && (
              <div className="eyebrow-menu-button-container">
                <div className="eyebrow-menu-bg eyebrow-menu-bg-left" />
                <button
                  className="btn btn-icon btn-icon-left"
                  onClick={() => {
                    scroll("left")
                  }}
                  type="button"
                  aria-label="Scroll left">
                  <Icon iconName="ChevronLefttIcon" />
                </button>
              </div>
            )}

            <div className="eyebrow-menu-links" ref={refMenuLinks} onScroll={setArrowsState}>
              {links?.map((item, index) => (
                <Anchor
                  tabindex={index}
                  noUnderline
                  key={`${item.name}${index}`}
                  isExternal={item.type == "externalLink"}
                  href={item.type === "email" ? `mailto:${item.href}` : item.href}
                  dataLayerOnClick={() => handleDataLayerNavigationHeader(item.name)}>
                  {item.name}
                </Anchor>
              ))}
            </div>
            {showRightArrow && (
              <div className="eyebrow-menu-button-container">
                <div className="eyebrow-menu-bg" />
                <button
                  className="btn btn-icon"
                  onClick={() => {
                    scroll("right")
                  }}
                  type="button"
                  aria-label="Scroll right">
                  <Icon iconName="ChevronRightIcon2" />
                </button>
              </div>
            )}
          </>
        ) : (
          <Carousel
            containerProps={{
              style: { width: "100%", justifyContent: "flex-end", flexFlow: "inherit",},
            }}
            preventScrollOnSwipe
            swipeTreshold={60}
            activeSlideIndex={activeSlide}
            activeSlideProps={{
              style: {},
            }}
            onRequestChange={setActiveSlide}
            forwardBtnProps={{
              "aria-label": "scroll right",
              children: <Icon iconName="ChevronRightIcon2" />,
              style: {
                width: 20,
                height: 20,
                minWidth: 20,
                marginLeft: 9,
                alignSelf: "center",
              },
              show: activeSlide < links.length - 1,
            }}
            backwardBtnProps={{
              "aria-label": "scroll left",
              children: <Icon iconName="ChevronLefttIcon" />,
              style: {
                width: 20,
                height: 20,
                minWidth: 20,
                marginRight: 16,
                alignSelf: "center",
              },
              show: activeSlide > 0,
            }}
            itemsToShow={1}
            speed={400}
            infinite={false}
            disableNavIfEdgeVisible={false}
            disableNavIfEdgeActive={false}>
            {links?.map((item, index) => (
              <Anchor
                className="eyebrow-menu-container-anchor"
                tabindex={index}
                noUnderline
                key={`${item.name}${index}`}
                isExternal={item.type == "externalLink"}
                href={item.type === "email" ? `mailto:${item.href}` : item.href}
                dataLayerOnClick={() => handleDataLayerNavigationHeader(item.name)}>
                {item.name}
              </Anchor>
            ))}
          </Carousel>
        )}
      </div>
    </div>
  )
}
export default EyebrowMenu
