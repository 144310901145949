import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { addRatings } from "store/actions/RatingsActionCreators"
import { Anchor, AnchorButton, RichText, SpecificationItem, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import BookmarkWithButton from "../bookmarkWithButton"
import { IProductCardContentProps } from "./_productCardContent.interface"

const ProductCardContent = (props: IProductCardContentProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const productBookMark = (state: any) => {
    // add id details into store
    updateRatings({
      id: props?.id,
      state,
    })
  }

  return (
    <div className="productCardContent">
      <div className="productCardContent-top">
        <span>
          {props?.taxonomies && props?.taxonomies?.length > 0 && (
            <Taxonomy taxonomies={props.taxonomies} />
          )}
        </span>
        {props?.relatedContent ? (
          <></>
        ) : (
          <BookmarkWithButton
            id={props.id}
            bookmarkStatus={false}
            isButton={false}
            addText=""
            removeText=""
            bookmarkCallback={productBookMark}
          />
        )}
      </div>
      {props?.heading && (
        <Anchor href={props?.href ?? ""} tabindex={0} noUnderline isExternal={props.isLinkExternal}>
          <h4 className="productCardContent-header bold">
            {trimText(props.heading, CHARLIMIT.TITLE)}
          </h4>
        </Anchor>
      )}
      {typeof props?.content === "string" ? (
        <RichText
          className="productCardContent-content"
          content={props.content}
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      ) : (
        <div className="productCardContent-content">
          {props?.content?.map((item: any, index: number) => (
            <>
              <SpecificationItem key={index} {...item} />
              {index < props?.content?.length - 1 && item?.description && (
                <div className="productCardContent-separator" />
              )}
            </>
          ))}
        </div>
      )}
      <div className="productCardContent-group">
      {props?.basisTextPath && (
          <AnchorButton
            tabindex={1}
            isSecondary
            className="productCardContent-button-basis-text"
            href={props?.basisTextPath}
            isExternal={props?.isLinkExternal}>
            {props?.basisTextLabel}
          </AnchorButton>
        )}
        {props?.attachmentPath && (
          <AnchorButton
            tabindex={1}
            isSecondary
            className="productCardContent-button-basis-text"
            href={props?.attachmentPath}
            isExternal={props?.isLinkExternal}>
            {props?.attachmentLabel}
          </AnchorButton>
        )}
        {props?.href && (
          <AnchorButton
            tabindex={1}
            isSecondary={typeof props?.content === "string" ? true : false}
            className="productCardContent-button"
            href={props.href}
            isExternal={props?.isLinkExternal}>
            {props?.buttonLabel}
          </AnchorButton>
        )}
        
      </div>
    </div>
  )
}
export default ProductCardContent
