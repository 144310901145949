import { EVENTS } from "./gtmEvents"
import { dataLayerPush } from "./gtmutils"

export const handleDataLayerNavigationFooter = (title: string, childTitle?: string) => {
  const linkText = childTitle || title
  const dataLayerNavigationObj = {
    link_text: linkText || null,
    navigation_level_1: title,
    navigation_level_2: childTitle,
    navigation_level_3: null,
    navigation_level_4: null,
  }
  dataLayerPush(EVENTS.NAVIGATION_FOOTER, dataLayerNavigationObj)
}

export const handleDataLayerNavigationMenu = (
  title: string,
  childTitle?: string,
  subChildTitle?: string,
  grandChildTitle?: string,
) => {
  const linkText = grandChildTitle || subChildTitle || childTitle || title
  const dataLayerNavigationObj = {
    link_text: linkText || null,
    navigation_level_1: title || null,
    navigation_level_2: childTitle || null,
    navigation_level_3: subChildTitle || null,
    navigation_level_4: grandChildTitle || null,
  }
  dataLayerPush(EVENTS.NAVIGATION_MENU, dataLayerNavigationObj)
}

export const handleDataLayerNavigationHeader = (title: string) => {
  const linkText = title
  const dataLayerNavigationObj = {
    link_text: linkText || null,
    navigation_level_1: title === "logo" ? "/" : linkText,
    navigation_level_2: null,
    navigation_level_3: null,
    navigation_level_4: null,
  }
  dataLayerPush(EVENTS.NAVIGATION_HEADER, dataLayerNavigationObj)
}

export const handleDataLayerError = (message: string, formType: string) => {
  const errorObj = {
    error_type: message,
    page_referrer: window.location.href,
    form_type: formType,
  }

  dataLayerPush(EVENTS.ERROR, errorObj)
}
