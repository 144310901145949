import { NextRouter } from "next/router"

export const handleClick = (router: NextRouter, id: any) => {
  setTimeout(() => {
    const updateUrl = (id: string) => {
      if (id.includes("acc") || id.includes("tab")) {
        const newId = id.slice(1)
        const hasQuery = location.href?.includes("?")
        let baseUrl = hasQuery ? location.href.split("&")[0] : location.pathname
        baseUrl = baseUrl.includes("#") ? baseUrl.split("#")[0] : baseUrl
        const url = hasQuery ? `${baseUrl}&${newId}` : `${baseUrl}${id}`

        router.push(url, undefined, { shallow: true })
      } else if(id.includes("#")) {
        const hasQuery = location.href?.includes("?")
        let baseUrl = hasQuery ? location.href.split("#")[0] : location.pathname
        baseUrl = baseUrl.includes("&") ? baseUrl.split("&")[0] : baseUrl
        const url = `${baseUrl}${id}`

        router.push(url, undefined, { shallow: true })
      }
    }

    if (!id?.includes("/")) {
      updateUrl(id)
    }
  }, 50)
}

export default handleClick
