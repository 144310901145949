import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import moment from "moment"
import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getMediaFile } from "@utils/mediaLibrary"
import { getBlockData, getFileDetails, getImageMeta, getTaxonomy } from "."
import { ISlideRelatedItemInfo } from "./_api.interface"
import renderMediaLibraryConfig from "./getMediaLibraryConfig"
import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"

const getSlideInfo = async (slide: any, serverData?: any): Promise<ISlideRelatedItemInfo | null> => {
  const isChildPaywalled = isPaywallProtected(
    getNestedObject(slide, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  if (isChildPaywalled) {
    return null
  }
  const imagePaywallData = isPaywallProtected(
    getNestedObject(slide, "relationships.field_media_image"),
  )
  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang

  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(slide?.relationships?.field_media_image)

  const taxonomies = await getTaxonomy(
    slide?.relationships?.field_media_specialty?.links?.related?.href,
    false,
    serverData,
  )

  const mediaLibConfig = await renderMediaLibraryConfig(serverData)

  const downloadUUID = getNestedObject(slide, "relationships.field_media_download.data.id")
  const downloadType = getNestedObject(slide, "relationships.field_media_download.data.type")

  const fileType = downloadType?.replace("media--", "")

  const mediaFile = getMediaFile(fileType)

  const { downloadFileLink } = await getFileDetails(downloadUUID, fileType, mediaFile, serverData)

  const heading = isPaywallProtected(getNestedObject(slide, "attributes.title"))
    .isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.title") ?? null

  const time = isPaywallProtected(getNestedObject(slide, "attributes.published_date"))
    .isPaywallProtectedFlag
    ? ""
    : moment(getNestedObject(slide, "attributes.published_date")).locale(language).format("MMMM DD, YYYY")

  return {
    relatedItemContent: {
      taxonomies: taxonomies ?? "",
      heading,
      text:
        getNestedObject(slide, "attributes.body.processed") ??
        getNestedObject(slide, "attributes.body.value") ??
        null,
      buttonLabel: mediaLibConfig?.download_text_cta ?? "",
      linkExternal: true,
      link: downloadFileLink ?? "",
      time,
    },
    relatedItemImage: {
      alt,
      src: imageUrl ?? "",
      styledimage: imageStyledUrl ?? null,
    },
  }
}

const getRelatedItemsData = async (
  url: string,
  nid?: number,
  targetid?: number,
  compData?: any,
  serverData?: any,
) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getRelatedItemsData.name}.`)
  }

  const hasQueryParams = url.includes("?")

  const { data } = await getBlockData(
    `${url}${hasQueryParams ? "&" : "?"}views-argument[0]=${targetid}&views-argument[1]=${nid}`,
    false,
    serverData,
  )
  const fieldPaywallRoles = getAllRoles(data)

  const mediaLibConfigData = await renderMediaLibraryConfig(serverData)

  const slidesInfo = await Promise.all(
    data.map(async (slide: any) => getSlideInfo(slide, serverData)),
  )

  return {
    relatedItemsTitle: mediaLibConfigData?.related_items_title,
    slidesInfo: filterNullValues(slidesInfo),
    fieldPaywallRoles,
  }
}

export default getRelatedItemsData
