import { useCallback, useEffect, useState } from "react"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { updateProductFilter } from "store/actions/CommonActionCreators"
import {
 Button, CheckBox, Column, DropDown, RichText, Row,
} from "@atoms"
import { useRouter } from "next/router"
import AdvanceSearchFilterBar from "../../molecules/advanceSearchOptions"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"
import { IAdvancedSearchFilterWithAccordionProps } from "./_advanceSearchFilterWithAccordion.interface"
import AccordionFlex from "../accordionFlex"
import SearchGroup from "../../molecules/search-group"

const AdvanceSearchFilterWithAccordion = (props: IAdvancedSearchFilterWithAccordionProps) => {
  const {
    searchFieldData,
    clearFilterText,
    labelItemsSelected,
    handleSearch,
    filterData,
    applyFiltertext,
    handleAdditionalFilters,
    applyAdditionalFilters,
    searchArgs,
    currentSearchText = "",
    handleSearchText,
  } = props

  const [displayFilters, setDisplayFilters] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const [searchText, setSearchText] = useState<string>(currentSearchText)
  const [allIds, setAllIds] = useState<Array<string>>([])
  const [selectedOptions, setSelectedOptions] = useState<object>({})

  const selectedFilterIndex: any = useSelector(
    (state: any) => state?.common?.accordianProductFilterIndex,
    shallowEqual,
  )
  const dispatch: Dispatch<any> = useDispatch()

  const router = useRouter()
  const isMobile = useMediaQuery("(max-width: 991px)")

  const updateProductFilterState: any = useCallback(
    (value: any) => dispatch(updateProductFilter(value)),
    [dispatch],
  )

  const handleClear = () => {
    setIsCleared(!isCleared)

    // save product filter state in store
    updateProductFilterState(0)

    router.push(
      {
        pathname: window.location.pathname,
        query: { category: "", search: searchText },
      },
      undefined,
      { shallow: true },
    )
  }
  const handleFilterBtnToggle = (x: boolean) => {
    setDisplayFilters(x)
  }

  const formatMatchString = (arrayLength: number) =>
    props?.resultLabels?.resultsFoundText.replace("@product_list_count_val", arrayLength.toString())

  const handleApply = () => {
    applyAdditionalFilters(searchText)
    handleFilterBtnToggle(false)
  }

  const handleCheck = (e: React.FormEvent<HTMLInputElement>, label: string, item: any) => {
    let tempIds = [...allIds]
    const isChecked = (e.target as any)?.checked

    if (isChecked && !tempIds.includes(item.id)) {
      tempIds = [...tempIds, item.id]
    } else {
      tempIds = allIds.filter((id) => id !== item.id)
    }
    handleSearch(searchText)

    // save product filter state in store
    filterData.forEach((filterItem: any, index: number) => {
      if (filterItem.label === label) {
        updateProductFilterState(index)
      }
    })

    router.push(
      {
        pathname: window.location.pathname,
        query: { category: tempIds.join(","), search: searchText },
      },
      undefined,
      { shallow: true },
    )
  }

  const handleApplySearch = (text: string) => {
    setSearchText(text)
    handleSearch(text)
  }

  const updateSearchText = (text: string) => {
    handleSearchText?.(text)
    setSearchText(text)
  }

  useEffect(() => {
    const selectedCategories = router?.query?.category || ""
    // @ts-ignore
    const categoryList = selectedCategories?.split(",")
    const selectedValues = filterData?.reduce((acc, { label, taxonomies }) => {
      return { ...acc, [label]: taxonomies.filter((item: any) => categoryList.includes(item?.id)) }
    }, {} as any)
    setSelectedOptions(selectedValues)
    setAllIds(categoryList)
  }, [router?.query])

  const accordionArgs = {
    accordionArray: filterData?.map((item: any) => {
      return {
        title: item.label,
        content: item.taxonomies?.map((taxonomy: any) => (
          <CheckBox
            key={taxonomy?.id}
            value={taxonomy?.id}
            label={taxonomy?.label}
            isChecked={allIds.includes(taxonomy?.id)}
            onChange={(isChecked: any) => handleCheck(isChecked, item.label, taxonomy)}
          />
        )),
      }
    }),
  }

  const applyButton = (
    <div className="search-filter-bar-hcontrol search-filter-bar-modal-element-applyBox">
      <Button type="button" className="search-button" tabindex={1} onClick={handleApply}>
        {applyFiltertext}
      </Button>
    </div>
  )

  return (
    <>
      {!isMobile && (
        <div className="advanceSearchFilterWithAccordion-sidebar scroll-top">
          <SearchGroup
            {...searchArgs}
            handleTextSearch={handleSearch}
            handleTextChange={updateSearchText}
            currentSearchText={currentSearchText}
            buttonType="button"
            className="advanceSearchFilterWithAccordion-search"
          />
          <div className="group-result-and-filter">
            <h5 className="advanceSearchFilterWithAccordion-resultcount">
              {formatMatchString(props?.resultLength)}
            </h5>
            <span className="search-filter-bar-modal-element-filter-clear advanceSearchFilterWithAccordion-clearFilter">
              <span aria-hidden="true" onClick={handleClear}>
                {clearFilterText}
              </span>
            </span>
          </div>
          <div className="advanceSearchFilterWithAccordion-accordion">
            <AccordionFlex selectedIndex={selectedFilterIndex} {...accordionArgs} />
          </div>
        </div>
      )}
      {isMobile && (
        <div className="search-filter-section scroll-top">
          <Row
            className={`search-filter-clear ${!displayFilters && isMobile ? "hideFilters" : ""}`}
          >
            <Column
              desktop={12}
              tablet={12}
              mobile={12}
              className="search-filter-clear-container search-filter-top"
            >
              <span className="search-filter-bar-modal-overlay">&nbsp;</span>
              <div className="search-filter-bar-modal-element">
                {filterData?.map(({ label, taxonomies }) => (
                  <div
                    key={label}
                    className={`search-filter-bar-element ${
                      !displayFilters && isMobile ? "hideFilters" : ""
                    }`}
                  >
                    <RichText className="title" content={label} />
                    <DropDown
                      options={taxonomies.map((item: any) => ({
                        id: item?.id,
                        value: item?.id,
                        label: item?.label,
                      }))}
                      name={label}
                      ariaLabel={label}
                      // @ts-ignore
                      intialValue={selectedOptions?.[label] || []}
                      isMulti
                      labelItemsSelected={labelItemsSelected}
                      onValueChange={(val) => handleAdditionalFilters(label, val)}
                      isCleared={isCleared}
                    />
                  </div>
                ))}
                {applyButton}
                <div className="search-filter-bar-modal-element-filter-clear">
                  <span aria-hidden="true" onClick={handleClear}>
                    {clearFilterText}
                  </span>
                </div>
              </div>
            </Column>
          </Row>
          <Row className="search-filter-action">
            <Column desktop={12} tablet={12} mobile={12}>
              <AdvanceSearchFilterBar
                {...searchFieldData}
                labelItemsSelected={labelItemsSelected}
                handleFilterBtnToggle={handleFilterBtnToggle}
                handleSearch={handleApplySearch}
                isCleared={isCleared}
                handleAdditionalFilters={handleAdditionalFilters}
                currentSearchText={searchText}
                handleSearchText={handleSearchText}
              />
              <h5 className="advanceSearchFilterWithAccordion-resultcount">
                {formatMatchString(props?.resultLength)}
              </h5>
            </Column>
          </Row>
        </div>
      )}
    </>
  )
}

export default AdvanceSearchFilterWithAccordion
