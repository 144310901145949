import { getBlockData } from "."
import { consoleError } from "../../utils/error"
import getCSVProductlistData from "./getCSVProductListData"
// import getProductlistData from "./getProductListData"
import getProductCatalogueData from "./getProductCatalogueData"

const getRESTApiData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getRESTApiData.name}.`)

  const serverData = args?.[3] // accepting serverData only
  const rawRestData = await getBlockData(url, false, serverData)
  let blockdata = null
  const pageType = rawRestData?.included?.[0]?.attributes?.name
  const blockUrl = rawRestData?.data?.attributes?.field_rest_data_api_url

  switch (pageType) {
    case "product_listing":
    case "pim_product_listing":
      blockdata = await getCSVProductlistData(blockUrl, pageType, serverData)
      break
    case "product_catalogue":
      blockdata = await getProductCatalogueData(blockUrl, pageType, serverData)
      break
    case "simple_product":
    case "category_banner":
    case "pim_family_page":
      blockdata = { blockUrl, type: pageType }
      break
    default:
      blockdata = { blockUrl, type: pageType }
      break
  }
  return blockdata
}

export default getRESTApiData
