import { Anchor, DropDown, Icon, Img, RichText } from "@atoms"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import { getAssetPrefixUrl } from "@utils/helper"
import Link from "next/link"
import { useMediaQuery } from "@helpers/hooks"
import { ICheckoutCard } from "./_checkoutCard.interface"

const CheckoutCard = (props: ICheckoutCard) => {
  const {
    title,
    subTitle,
    detailUrl,
    productImgUrl,
    quantity,
    quantityLabel,
    editLabel,
    isEditable,
    onEditUrl,
    quantityOptions = [],
    handleUpdateQuantity,
    handleDeleteProduct,
    quantityErrorMessage,
    productStyledImgUrl,
  } = props

  const isMobile = useMediaQuery("(max-width: 991px)")

  const handleOnchange = (val: any) => {
    handleUpdateQuantity?.(val?.[0]?.value)
  }

  const fetchInitialValue = () => {
    return quantityOptions?.filter((item: IOption) => Number(item?.value) === quantity)
  }
  return (
    <div className="checkoutcard">
      <div className="checkoutcard--head">
        <div className="image-section">
          <Anchor
            href={detailUrl}
            tabindex={0}
            noUnderline={true}
            className="checkoutcard--head-image">
            <Img
              type="product_catalogue"
              styledimage={productStyledImgUrl}
              src={productImgUrl || getAssetPrefixUrl("/assets/images/Placeholder.png")}
              alt="product-image"
              height={100}
              width={100}
            />
          </Anchor>
        </div>
        <div className="checkoutcard--head-container">
          <div className="checkoutcard--head-container-box">
            {subTitle && (
              <span className="checkoutcard--head-container-box-subtitle">{subTitle}</span>
            )}
            <Anchor href={detailUrl} tabindex={0} noUnderline={true}>
              {isMobile ? (
                <h4 className="checkoutcard--head-container-box-title bold">{title}</h4>
              ) : (
                <h3 className="checkoutcard--head-container-box-title bold">{title}</h3>
              )}
            </Anchor>
          </div>
          {isEditable && (
            <div
              className={
                quantity
                  ? "checkoutcard--head-button-container"
                  : "checkoutcard--head-button-container no-quantity"
              }>
              <div className="quantity-button-cart">
                <DropDown
                  ariaLabel="product_quantity"
                  options={quantityOptions}
                  name="quantityLabel"
                  isCleared={false}
                  isMulti={false}
                  simpleDropDownLabel
                  onValueChange={handleOnchange}
                  placeHolder={quantityLabel}
                  intialValue={fetchInitialValue()}
                  shouldToggleOnHover={false}
                  showValueWithPlaceholder={true}
                />
                {!quantity && (
                  <RichText className="quantity-error" content={quantityErrorMessage || ""} />
                )}
              </div>
              <div
                onClick={handleDeleteProduct}
                aria-hidden="true"
                role="button"
                tabIndex={0}
                className="close-icon">
                <Icon iconName="CloseCircle" width={40} height={40} />
              </div>
            </div>
          )}
          {!isEditable && (
            <div className="checkoutcard--head-container-grid">
              <div className="checkoutcard--head-container-grid-quantity">
                <span className="checkoutcard--quantitylabel">
                  {quantityLabel}
                  {": "}
                </span>
                <span className="checkoutcard--quantityvalue">{quantity}</span>
              </div>
              <Link href={onEditUrl ?? "/"} legacyBehavior>
                <a href={onEditUrl ?? "/"} className="checkoutcard--head-container-grid-edit-link">
                  <span className="edit-label">{editLabel}</span>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default CheckoutCard
