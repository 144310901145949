import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"
import getNestedObject from "@utils/nestedObjects"
import {
  filterNullValues,
  getAllRoles,
  getPaywalledImgData,
  isPaywallProtected,
} from "@utils/paywallUtilities"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import { EVENT_TYPE } from "@helpers/constants/generic"
import { getScoutEventData } from "./getScoutEventData"

const getEventCalendarData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let eventCalendarData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(eventCalendarData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  eventCalendarData = eventCalendarData?.data ?? []
  const fieldPaywallRoles = getAllRoles(eventCalendarData)
  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang

  let eventCards: any = []
  eventCards = await Promise.all(
    eventCalendarData?.map(async (eventCard: any, key: number) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(eventCard, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(eventCard?.relationships?.field_event_banner)
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(eventCard?.relationships?.field_event_banner)

      const taxonomies = await getTaxonomy(
        eventCard?.relationships?.field_category?.links?.related?.href,
        true,
        serverData,
      )
      const startDate =
        moment().locale(language).format(eventCard?.attributes?.field_event_dates?.value) ?? null
      const endDate =
        moment().locale(language).format(eventCard?.attributes?.field_event_dates?.end_value) ??
        null

      const eventType = eventCard?.attributes?.field_event_options ?? null
      const scoutData = eventType === EVENT_TYPE.SCOUT_EVENT ? getScoutEventData(eventCard) : null

      return {
        eventCardHorizontalContent: {
          id: eventCard?.attributes?.drupal_internal__nid ?? "",
          taxonomies: taxonomies ?? null,
          heading: isPaywallProtected(eventCard?.attributes?.title).isPaywallProtectedFlag
            ? null
            : eventCard?.attributes?.title ?? null,
          text: eventCard?.attributes?.body?.value ?? null,
          primaryButtonLabel: eventCard?.attributes?.field_primary_cta_link?.title ?? null,
          primaryButtonUrl: filterURL(eventCard?.attributes?.field_primary_cta_link?.uri) ?? null,
          primaryIsExternalUrl:
            isExternalLink(
              eventCard?.attributes?.field_primary_cta_link?.options?.attributes?.target,
            ) ?? null,
          secondaryButtonLabel: eventCard?.attributes?.field_secondary_cta_link?.title ?? null,
          secondaryButtonUrl:
            filterURL(eventCard?.attributes?.field_secondary_cta_link?.uri) ?? null,
          secondaryIsExternalUrl:
            isExternalLink(
              eventCard?.attributes?.field_secondary_cta_link?.options?.attributes?.target,
            ) ?? null,
          startDate: isPaywallProtected(eventCard?.attributes?.field_event_dates)
            .isPaywallProtectedFlag
            ? null
            : startDate,
          endDate: isPaywallProtected(eventCard?.attributes?.field_event_dates)
            .isPaywallProtectedFlag
            ? null
            : endDate,
          contentText: isPaywallProtected(eventCard?.attributes?.field_location)
            .isPaywallProtectedFlag
            ? null
            : eventCard?.attributes?.field_location ?? null,
          time: isPaywallProtected(eventCard?.attributes?.field_duration).isPaywallProtectedFlag
            ? null
            : eventCard?.attributes?.field_duration ?? null,
          scoutData,
          eventType,
        },
        eventCardHorizontalImage: {
          src: imageUrl ?? null,
          alt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
        },
        eventCardHDesktopColumn: 12,
        eventCardHTabletColumn: 12,
        eventCardHMobileColumn: 12,
      }
    }),
  )
  return {
    events: filterNullValues(eventCards) ?? null,
    slideToShow: 1,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getEventCalendarData
