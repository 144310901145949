import { getApi } from "@utils/baseApi"
import getNestedObject from "@utils/nestedObjects"
import NUMBERS from "@helpers/constants/numbers"
import { filterURL } from "."

export const formatModalContent = (popupContent: any) => {
  return {
    header: {
      titleText: getNestedObject(popupContent, "field_modal_title"),
    },
    content: {
      content: getNestedObject(popupContent, "body"),
      isRichText: true,
    },
    footer: {
      closeBtn: {
        tabindex: NUMBERS.TWO,
        children: getNestedObject(popupContent, "field_ms_cta.title"),
        isSecondary: true,
        href: "javascript:void(0);",
      },
      actionBtn: {
        tabindex: NUMBERS.ONE,
        children: getNestedObject(popupContent, "field_mp_cta.title"),
      },
    },
    enabled: getNestedObject(popupContent, "status"),
  }
}

const getInternalNavData = async (siteConfig: any) => {
  try {
    const popupData = await getApi("solarapi/internal_nav_link_popup")
    let links: any = []
    popupData?.data?.map((item: any) => {
      item.map((childItem: any) => {
        childItem?.block_content?.map((childData: any) => {
          const popupData = formatModalContent(childData)
          links.push({ url: filterURL(childItem?.URL), type: childItem?.type, popup_data: popupData })
        })
      })
    })
    return {
      links,
      enabled: siteConfig?.int_nav_popup_status === 1 ?? false
    }
  } catch (error) {
    throw error
  }
}

export default getInternalNavData
