import getNestedObject from "@utils/nestedObjects"
import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData, getImageMeta } from "."

const getAccordionData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const accordionRawData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(accordionRawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(accordionRawData)
  const referrerId = getNestedObject(accordionRawData, "data.attributes.field_referrer_id") ?? ""
  if (accordionRawData && accordionRawData !== undefined && accordionRawData?.included && Array.isArray(accordionRawData?.included)) {
    const accordionArray = await Promise.all(
      accordionRawData?.included?.map(async (data: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(data, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        let content
        const title =
          getNestedObject(data, "attributes.field_reference_title.value") ??
          getNestedObject(data, "attributes.field_accordion_card_title.value") ??
          ""
        const referrerId = getNestedObject(data, "attributes.field_referrer_id") ?? ""
        content =
          getNestedObject(data, "attributes.body.processed") ??
          getNestedObject(data, "attributes.body.value") ??
          ""
        const refInfo =
          getNestedObject(data, "attributes.field_reference_info.processed") ??
          getNestedObject(data, "attributes.field_reference_info.value") ??
          ""
        content = content.concat(refInfo)
        const { isPaywallProtectedFlag, errorObj } = isPaywallProtected(
          getNestedObject(data, "relationships.field_accordion_image"),
        )
        const image = isPaywallProtectedFlag
          ? getPaywalledImgData(errorObj)
          : getImageMeta(data?.relationships?.field_accordion_image)

        const type = data.type ?? null

        return {
          title,
          content,
          type,
          image,
          referrerId,
        }
      }),
    )
    return {
      accordionArray: filterNullValues(accordionArray) ?? null,
      accordionWithImage: true,
      fieldPaywallRoles,
      isParentPaywalled,
      referrerId,
    }
  }
  return { accordionArray: null, accordionWithImage: true, fieldPaywallRoles }
}

export default getAccordionData
