import React from "react"
import { IImageProps } from "@atoms/img/_img.interface"
import { Column, Img, Row } from "@atoms"
import DetailBlockContent from "../../molecules/DetailBlockContent"
import CarouselWrapper from "../carouselWrapper"
import { IDetailBlockProps } from "./_detailBlock.interface"

const DetailBlock = (props: IDetailBlockProps) => {
  const { id, carouselData, contentData, isLandscape } = props
  return (
    <div>
      <Row className="detail-block">
        <Column desktop={6} tablet={12} mobile={12}>
          <CarouselWrapper slideToShow={1} slideToShowTablet={1} className="detail-block-wrapper">
            {carouselData?.map((image: IImageProps, index: number) => (
              <Img
                type="teaser_block_carousel"
                alt={image?.alt}
                src={image?.src}
                key={index}
                width={isLandscape ? 390 : 368}
                height={isLandscape ? 280 : 354}
                styledimage={image?.styledimage}
              />
            ))}
          </CarouselWrapper>
        </Column>
        <Column desktop={6} tablet={12} mobile={12} className="detail-block--container">
          <DetailBlockContent {...contentData} nodeId={id} />
        </Column>
      </Row>
    </div>
  )
}

export default DetailBlock
