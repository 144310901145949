import { AnchorButton, QuatityButtonCart, ShopCardDescription } from "@atoms"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext, useEffect, useState } from "react"
import { IShopCard } from "./_shopCard.interface"
import { useSelector } from "react-redux"
import { IDownloadDetails } from "@atoms/quantityButtonCart/_quantityButtonCart.interface"

const ShopCard = (props: IShopCard) => {
  const {
    isProductInCart,
    productImgUrl,
    productStyledImgUrl,
    productAltText,
    detailLabels,
    quantityBtnProps,
    quantityLabels,
    viewType,
    addToCartEnabled,
    handleLoginTrigger,
    handleAddToCart,
    fieldQuantityAvailable,
    isfieldQuantityAvailable,
  } = props

  // State to store configuration data
  const [configData, setConfigData] = useState<any>(null)

  const { applicationConfiguration } = useContext(ApplicationContext)
  const user: any = useSelector((state: UserState) => state.user)
  const downloadDetails: IDownloadDetails = {
    enableDownloadAnonymous: props?.enableDownloadAnonymous,
    downloadEnabled: props?.downloadEnabled,
    downloadUrl: props?.downloadUrl,
    downloadLabel: props?.downloadLabel,
  }

  // Fetch the config data on component mount
  useEffect(() => {
    const fetchConfigData = () => {
      setConfigData(applicationConfiguration.siteConfig)
    }
    fetchConfigData()
  }, [applicationConfiguration.siteConfig])

  const downloadWSI = () => {
    if (
      downloadDetails?.downloadUrl && ((props?.enableDownloadAnonymous === 1 && props?.downloadEnabled) ||
      (props?.downloadEnabled && user && user?.isLoggedIn))
    ) {
      return (
        <AnchorButton
          className="download-cta-wsi"
          href={props?.downloadUrl??""}
          isExternal={true}
          tabindex={0}
          isSecondary
          download={true}>
          {props?.downloadLabel}
        </AnchorButton>
      )
    }
  }
  return (
    <div className={`shop-card shop-card-${viewType}`}>
      <ShopCardDescription
        {...detailLabels}
        type={viewType}
        productImgUrl={productImgUrl}
        productStyledImgUrl={productStyledImgUrl}
        productAltText={productAltText}
      />
      {configData?.webstore_enable_non_loggedin_users && isfieldQuantityAvailable ? (
        <QuatityButtonCart
          {...quantityBtnProps}
          quantityLabels={quantityLabels}
          isProductInCart={isProductInCart}
          handleAddToCart={handleAddToCart}
          noLoginRequired={true}
          downloadDetails={downloadDetails}
          isfieldQuantityAvailable={isfieldQuantityAvailable}
        />
      ) : (
        <>
          {configData?.webstore_enable_non_loggedin_users &&
          quantityBtnProps?.quantityOptions?.length > 0 &&
          addToCartEnabled ? (
            <QuatityButtonCart
              {...quantityBtnProps}
              quantityLabels={quantityLabels}
              isProductInCart={isProductInCart}
              handleAddToCart={handleAddToCart}
              noLoginRequired={true}
              downloadDetails={downloadDetails}
              isfieldQuantityAvailable={isfieldQuantityAvailable}
            />
          ) : quantityBtnProps?.quantityOptions?.length > 0 && addToCartEnabled ? (
            <QuatityButtonCart
              {...quantityBtnProps}
              quantityLabels={quantityLabels}
              isProductInCart={isProductInCart}
              handleAddToCart={handleAddToCart}
              handleLogin={handleLoginTrigger}
              downloadDetails={downloadDetails}
              isfieldQuantityAvailable={isfieldQuantityAvailable}
            />
          ) : quantityBtnProps?.quantityOptions?.length === 0 ? (
            downloadWSI()
          ) : null}
        </>
      )}
    </div>
  )
}
export default ShopCard
