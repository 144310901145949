import { Container, Icon, Img } from "@atoms"
import React, { useState } from "react"
import WebStoreImageModal from "@molecules/webStoreImagModal"
import { IWebStoreImageProps } from "./_webStoreImageBlock.interface"

const WebStoreImageBlock = (imgInfo: IWebStoreImageProps) => {
  const [showModal, setShowModal] = useState(false)
  const toggleModalShow = () => {
    setShowModal(!showModal)
  }

  return (
    <Container>
      <div className="webstore-image">
        <div className="webstore-image-image">
          <div onClick={toggleModalShow}>
            {imgInfo && (
              <>
                <Img
                  className="webstore-image-image-content"
                  src={imgInfo?.src || ""}
                  alt={imgInfo?.alt}
                  styledimage={imgInfo?.imageStyledUrl}
                  width={584}
                  height={584}
                  type="product_catalogue"
                />
                {imgInfo?.src && (
                  <div className="overlay-icon" onClick={toggleModalShow}>
                    <Icon iconName="glassIcon" />
                  </div>
                )}
              </>
            )}
            {showModal && <WebStoreImageModal {...imgInfo} onClose={toggleModalShow} />}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default WebStoreImageBlock
