import {filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected} from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import {filterURL, getBlockData, getImageMeta, isExternalLink} from "."

const getInterestingCardsData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let contentCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(contentCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(contentCards)
  contentCards = contentCards?.included ?? []
  contentCards = await Promise.all(
    contentCards?.map(async (contentCard: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(contentCard, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }

      const imagePaywallData = isPaywallProtected(contentCard?.relationships?.field_article_image)
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(contentCard?.relationships?.field_article_image)

      return {
        interestingTeaserCardImage: {
          src: imageUrl,
          alt,
          styledimage: imageStyledUrl ?? null,
        },
        interestingTeaserCardContent: {
          id: contentCard?.attributes?.drupal_internal__nid ?? null,
          heading: isPaywallProtected(contentCard?.attributes?.title).isPaywallProtectedFlag
            ? null
            : contentCard?.attributes?.title ?? "",
          text: contentCard?.attributes?.body?.processed
            ? contentCard?.attributes?.body?.processed
            : null,
          buttonLabel: contentCard?.attributes?.field_secondary_cta?.title
            ? contentCard?.attributes?.field_secondary_cta?.title
            : null,
          link: contentCard?.attributes?.field_secondary_cta?.uri
            ? filterURL(contentCard?.attributes?.field_secondary_cta?.uri)
            : null,
          numberOfFavorite: "200",
          isLinkExternal: contentCard?.attributes?.field_secondary_cta?.options?.attributes?.target
            ? isExternalLink(
                contentCard?.attributes?.field_secondary_cta?.options?.attributes?.target,
              )
            : null,
        },
      }
    }),
  )
  return {
    contentCards: filterNullValues(contentCards) ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }

}

export default getInterestingCardsData
