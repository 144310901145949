import { IBrandingPattern } from "./_brandingpattern.interface"

const BrandingPattern = (props: IBrandingPattern) => {
  const getClasses = () => {
    const classes = []
    classes.push("pattern-blue-carbon")
    if (props.isGrey) classes.push("grey")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <div className="pattern-bg">
      <div className={getClasses()} />
    </div>
  )
}

export default BrandingPattern
