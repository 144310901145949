import { Anchor, Column, Img } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import getDirection from "@utils/direction"
import ProductCardContent from "../../molecules/productCardContent"

import { IProductCardProps } from "./_productCard.interface"
import { modifyRelatedContentData } from "@utils/relatedContentUtil"

const ProductCard = ({
  className,
  withBorder,
  productCardImage,
  productCardContent,
  desktopColumns = 3,
  tabletColumns = 6,
  view,
  relatedContentFlag,
}: IProductCardProps) => {
  const productCardData = relatedContentFlag
    ? modifyRelatedContentData(productCardContent)
    : productCardContent
  const getClasses = () => {
    const classes = ["productCard"]
    if (className) {
      classes.push(className)
    }
    if (view === mediaLibraryConstants.GRID_VIEW) {
      classes.push("card-list-view")
    } else {
      classes.push("card-grid-view")
    }

    return classes.join(" ")
  }

  return (
    <Column desktop={desktopColumns} tablet={tabletColumns} mobile={12} className={getClasses()}>
      <div className={`${withBorder ? "border-card" : "card"}`} dir={getDirection()}>
        {productCardImage?.src && (
          <div className="image-section">
            <Anchor
              noUnderline
              tabindex={0}
              href={productCardContent?.href ?? ""}
              isExternal={productCardContent.isLinkExternal}>
              <Img
                type="plp_list"
                styledimage={ productCardImage?.src ? null : productCardImage?.styledimage}
                src={productCardImage.src || ""}
                alt={productCardImage.alt || ""}
                width={240}
                height={240}
                className="productCard-image"
                islazyloaded={false}
              />
            </Anchor>
          </div>
        )}
        <div className="card-body">
          <ProductCardContent {...productCardData} />
        </div>
      </div>
    </Column>
  )
}
export default ProductCard
