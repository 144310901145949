import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import { consoleError } from "../../utils/error"
import moment from "moment"
import { EVENT_TYPE } from "@helpers/constants/generic"
import { getScoutEventData } from "./getScoutEventData"

const getHeroBannerData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only

  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getHeroBannerData.name}.`)
  }

  const hasQueryParams = url.includes("?")
  const heroData = await getBlockData(
    `${url}${hasQueryParams ? "&" : "?"}include=field_reference_tags`,
    false,
    serverData,
  )

  let heroBannerVideoURL = ""

  const isParentPaywalled = isPaywallProtected(
    getNestedObject(heroData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  const fieldPaywallRoles = getAllRoles(heroData)
  const imagePaywallData = isPaywallProtected(
    getNestedObject(heroData, "data.relationships.field_hero_banner_desktop_image"),
  )
  const imageMobilePaywallData = isPaywallProtected(
    getNestedObject(heroData, "data.relationships.field_hero_banner_mobile_image"),
  )

  const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(heroData?.data?.relationships?.field_hero_banner_desktop_image)

  const {
    imageUrl: mobileImageUrl,
    alt: mobileImageAlt,
    imageStyledUrl: mobileImageStyledUrl,
  } = imageMobilePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imageMobilePaywallData?.errorObj)
    : getImageMeta(heroData?.data?.relationships?.field_hero_banner_mobile_image)

  const uploadType =
    getNestedObject(heroData, "data.attributes.field_hero_banner_upload_type") ?? null
  if (uploadType === "video") {
    heroBannerVideoURL =
      getNestedObject(heroData, "data.attributes.field_hero_banner_video_url") ?? null
  }

  if (heroData?.data?.attributes?.field_banner_type === "Event Banner") {
  const taxonomies = isPaywallProtected(heroData?.data?.relationships?.field_reference_tags)
      .isPaywallProtectedFlag
      ? null
      : await getTaxonomy(heroData?.data?.relationships?.field_reference_tags?.links?.related?.href,
        true,
        serverData
      )  
      const eventType = heroData?.data?.attributes?.field_event_options ?? null
      const scoutData = eventType === EVENT_TYPE.SCOUT_EVENT ? getScoutEventData(heroData?.data) : null

    return {
      isEventBanner: true,
      uploadType,
      heroBannerContent: null,
      eventBannerContent: {
        taxonomies: taxonomies ?? null,
        heading: getNestedObject(heroData, "data.attributes.field_hero_banner_title.value") ?? null,
        isOverlay: getNestedObject(heroData, "data.attributes.field_overlay_on_off") ?? null,
        isOverlayFontType:
          getNestedObject(heroData, "data.attributes.field_font_dark_light") ?? null,
        time: getNestedObject(heroData, "data.attributes.field_duration.value") ?? null,
        contentText: getNestedObject(heroData, "data.attributes.field_location.value") ?? null,
        buttonLabel:
          getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link.title") ?? null,
        banner_cta_url:
          filterURL(getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link.uri")) ??
          null,
        date: isPaywallProtected(getNestedObject(heroData, "data.attributes.field_banner_date"))
          .isPaywallProtectedFlag
          ? null
          : moment(
              getNestedObject(heroData, "data.attributes.field_banner_date"),
              "Do MMMM YYYY",
            ).format("DD MMMM YYYY") ?? null,
        calendarDate: isPaywallProtected(
          getNestedObject(heroData, "data.attributes.field_right_banner_date"),
        ).isPaywallProtectedFlag
          ? null
          : getNestedObject(heroData, "data.attributes.field_right_banner_date") ?? null,
        isExternalLink: getNestedObject(
          heroData,
          "data.attributes.field_hero_banner_cta_link.options.attributes.target",
        )
          ? isExternalLink(
              getNestedObject(
                heroData,
                "data.attributes.field_hero_banner_cta_link.options.attributes.target",
              ),
            )
          : null,
        scoutData,
        eventType,
      },

      heroBannerImage: {
        desktopImage: {
          src: imageUrl ?? null,
          alt: alt ?? null,
          width: 1440,
          height: 540,
          styledimage: imageStyledUrl ?? null,
        },
        mobileImage: {
          src: mobileImageUrl ?? null,
          alt: mobileImageAlt ?? null,
          width: 390,
          height: 240,
          styledimage: mobileImageStyledUrl ?? null,
        },
      },
      fieldPaywallRoles,
    }
  }
  return {
    isEventBanner: false,
    referenceField:
      getNestedObject(heroData, "data.attributes.field_reference_field.processed") ?? null,
    heroBannerContent: {
      title: getNestedObject(heroData, "data.attributes.field_hero_banner_title.value") ?? null,
      paragraph: getNestedObject(heroData, "data.attributes.body.processed") ?? null,
      isOverlay: getNestedObject(heroData, "data.attributes.field_overlay_on_off") ?? null,
      isOverlayFontType: getNestedObject(heroData, "data.attributes.field_font_dark_light") ?? null,
      titleSuperscript:
        getNestedObject(heroData, "data.attributes.field_title_superscript.processed") ?? null,
      linkText:
        getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link.title") ?? null,
      link:
        filterURL(getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link.uri")) ??
        null,
      isExternalLink: getNestedObject(
        heroData,
        "data.attributes.field_hero_banner_cta_link.options.attributes.target",
      )
        ? isExternalLink(
            getNestedObject(
              heroData,
              "data.attributes.field_hero_banner_cta_link.options.attributes.target",
            ),
          )
        : null,
      cta_two_label:
        getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link_2.title") ?? null,
      cta_two_uri:
        filterURL(getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link_2.uri")) ??
        null,
      cta_two_externalLink: getNestedObject(
        heroData,
        "data.attributes.field_hero_banner_cta_link_2.options.attributes.target",
      )
        ? isExternalLink(
            getNestedObject(
              heroData,
              "data.attributes.field_hero_banner_cta_link_2.options.attributes.target",
            ),
          )
        : null,
      cta_three_label:
        getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link_3.title") ?? null,
      cta_three_uri:
        filterURL(getNestedObject(heroData, "data.attributes.field_hero_banner_cta_link_3.uri")) ??
        null,
      cta_three_externalLink: getNestedObject(
        heroData,
        "data.attributes.field_hero_banner_cta_link_3.options.attributes.target",
      )
        ? isExternalLink(
            getNestedObject(
              heroData,
              "data.attributes.field_hero_banner_cta_link_3.options.attributes.target",
            ),
          )
        : null,
    },
    eventBannerContent: null,
    heroBannerImage: {
      desktopImage: {
        src: imageUrl ?? null,
        alt: alt ?? null,
        width: 1440,
        height: 540,
        styledimage: imageStyledUrl ?? null,
      },
      mobileImage: {
        src: mobileImageUrl ?? null,
        alt: mobileImageAlt ?? null,
        width: 390,
        height: 240,
        styledimage: mobileImageStyledUrl ?? null,
      },
    },
    heroBannerVideoURL,
    uploadType,
    heroBannerVideoFallBackImage:
      getNestedObject(
        heroData,
        "data.relationships.field_hero_banner_fallback_image.data.meta.image_style.src",
      ) ?? "",

    fieldPaywallRoles,
    isParentPaywalled,
    isImageClickable: getNestedObject(heroData, "data.attributes.field_image_clickable") ?? null,
  }
}

export default getHeroBannerData