import Link from "next/link"
import { useEffect, useState } from "react"
import * as cheerio from "cheerio"

import { Icon, RichText } from "@atoms"
import { IWebstoreDetailContentProps } from "./_webstoreDetailContent.interface"

const WebstoreDetailContent = ({
  heading,
  subheading,
  contentTitle,
  content,
  articleNo,
  articleLabel,
  showSuccess,
  successMsgWithLink,
}: IWebstoreDetailContentProps) => {
  const [anchorMsg, setAnchorMsg] = useState<string>("")
  const [successMsg, setSuccessMsg] = useState<string>("")
  const [cartLink, setCartLink] = useState<string>("/")

  // Parse HTML for success message and link
  useEffect(() => {
    const parsedHtml = cheerio?.load(successMsgWithLink)
    const generalText = parsedHtml("p")?.text() ?? ""
    const anchorEle: any = parsedHtml("a")
    const anchorUrl = anchorEle?.[0]?.attribs?.href ?? "/"
    setSuccessMsg(generalText)
    setAnchorMsg(anchorEle?.text() ?? "")
    setCartLink(anchorUrl)
  }, [])

  // Construct success message with link
  const getSuccessMsgWithLink = () => {
    const successMsgParts = anchorMsg !== "" ? successMsg?.split(anchorMsg) : []
    if (successMsgParts.length === 2) {
      return (
        <span className="success-msg">
          <span>{`${successMsgParts[0]} `}</span>
          <Link className="success-msg-link" href={cartLink} legacyBehavior>
            {anchorMsg}
          </Link>
          {successMsgParts[1] && <span>{`${successMsgParts[1]}`}</span>}
        </span>
      )
    }
    return <RichText className="success-msg" content={successMsgWithLink} />
  }

  return (
    <div className="webstore-detail">
      {/* Render success message if showSuccess is true */}
      {showSuccess && (
        <div className="added-to-cart-text">
          <Icon iconName="SuccessIcon" />
          {getSuccessMsgWithLink()}
        </div>
      )}
      <div className="webstore-detail-grey ">
        <span>{subheading?.taxonomyLabel}</span>
      </div>
      <div className="webstore-detail-heading">
        {heading && (
          <span className="webstore-detail-heading">
            <h1>{heading}</h1>
          </span>
        )}
      </div>
      {contentTitle && (
        <RichText className="webstore-detail-content-title" content={contentTitle} />
      )}
      {content && <RichText className="webstore-detail-content" content={content} />}

      <div className="webstore-detail-grey">
        <p>{articleLabel}</p>
      </div>
      <span className="webstore-detail-grey-article">
        <p>{articleNo}</p>
      </span>
    </div>
  )
}

export default WebstoreDetailContent
