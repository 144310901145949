import React from "react"
import { ModalPortal } from "@molecules"
import { ModalBody, ModalFooter, ModalHeader } from "@atoms"
import LoginTimeoutModalContent from "@molecules/loginTimeoutContent"
import { IloginTimeoutProps } from "../../molecules/loginTimeoutContent/_loginTimeout.interface"

const LoginTimeOutModal = (props: IloginTimeoutProps) => {
  const {
 handleContinueSession = () => null, handleLogout = () => null, loginPopupData, pageTitle,
} = props
  const handleAction = () => {
    handleLogout()
  }

  const footer = {
    className: "modal-buttons-container",
    actionBtn: {
      tabindex: 1,
      children: loginPopupData.login_timeout_popup_stay_login_cta_label,
    },
    closeBtn: {
      tabindex: 2,
      children: loginPopupData.login_timeout_popup_logout_cta_label,
      href: "/",
      isSecondary: "isSecondary",
    },
  }

  const handleClose = () => {
    handleContinueSession()
  }
  const modalContent = (
    <div>
      <ModalHeader
        className="login-timeout-header"
        isLoginTimeout={true}
        titleText={loginPopupData.login_timeout_popup_title}
      />
      <ModalBody
        className="login-timeout-body"
        content={
          <LoginTimeoutModalContent
            headContent={loginPopupData.login_timeout_popup_countdown_text_above}
            countdownTime={parseInt(loginPopupData.login_timeout_popup_countdown_time)}
            bottomContent={loginPopupData.login_timeout_popup_countdown_text_below}
            titleContent={loginPopupData.login_timeout_browser_tab_title}
            pageTitle={pageTitle}
          />
        }
      />
      <ModalFooter
        {...footer}
        onClose={handleAction}
        onAction={handleClose}
        className="login-timeout-footer"
      />
    </div>
  )

  return <ModalPortal modalContent={modalContent} wrapperId="react-portal-wrapper" className="loginTimerContainer" />
}
export default LoginTimeOutModal
