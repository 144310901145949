import { ApplicationData } from "./applicationData"

export const ApplicationReducer = (state: any, appData: ApplicationData) => {
  switch (appData.actionType) {
    case "ADD":
      return {
        ...state,
        pageData: appData.applicationPageData,
      }

    case "APP_CONFIG":
      return {
        ...state,
        isBookmarkEnabled: appData.isBookmarkEnabled,
        isFavouriteEnabled: appData.isFavouriteEnabled,
        isRatingEnabled: appData.isRatingEnabled,
        isLanguageEnabled: appData.isLanguageEnabled,
        isRightPositionEnabled: appData.isRightPositionEnabled,
        tooltipText: appData.tooltipText,
        loginTimeoutData: appData.loginTimeoutData,
        postcodeSearchData: appData.postcodeSearchData,
        paywallNotice: appData.paywallNotice,
        video_configuration: appData.video_configuration,
        wave1_product_data: appData.wave1_product_data,
        flash_num_msg: appData.flash_num_msg,
        error_codes: appData?.error_codes,
        doccheck_user_name: appData.doccheck_user_name,
        siteConfig: appData?.siteConfig,
        cart_data: appData?.cart_data,
        webstore_data: appData?.webstore_data,
        newsletter_form_data: appData?.newsletter_form_data,
        isWebStoreEnabled: appData?.isWebStoreEnabled,
        isLoadingCompleted: true,
        isPimProduct: appData?.isPimProduct,
        isSandozIDEnabled: appData?.isSandozIDEnabled,
        sandozRedirectUrl: appData?.sandozRedirectUrl,
        sandozLogoutUrl: appData?.sandozLogoutUrl,
        isGlobalSite: appData?.isGlobalSite,
        barlinechart_download_cta_text: appData?.barlinechart_download_cta_text,
        barlinechart_clear_cta_text: appData?.barlinechart_clear_cta_text,
      }

    case "APP_HEADER":
      return {
        ...state,
        headerData: appData.headerData,
      }

    case "COOKIE_CONSENT":
      return {
        ...state,
        cookieId: appData.cookieId,
      }

    case "APP_COMPONENTS":
      let tempComps = state?.appComponents || []
      let currComp = tempComps?.find((data: any) => data?.pageAlias === appData?.componentConfig?.pageAlias)
      if (currComp) {
        const idx = tempComps?.indexOf(currComp)
        currComp = {
          header: appData?.componentConfig?.header ?? currComp?.header,
          footer: appData?.componentConfig?.footer ?? currComp?.footer,
          banners: appData?.componentConfig?.banners ?? currComp?.banners
        }
        tempComps = tempComps.splice(idx, 1, currComp)
      } else {
        tempComps?.push(appData.componentConfig)
      }
      return {
        ...state,
        appComponents: tempComps,
      }

    default:
      return state
  }
}
