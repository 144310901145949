import { useSelector, useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { useCallback, useEffect } from "react"
import { updatePaywall3Roles, updatePaywallContent } from "store/actions/CommonActionCreators"
import { getAccordionCardData } from "@helpers/dataFunctions/getAccordionAdvancedData"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import { v4 } from "uuid"
import { getAllFleixbleBlocksData } from "@helpers/flexibleApi"
import Loader from "@atoms/loader"
import { Icon } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import Flexible from "../../templates/flexible"
import { AccordionAdvanceContent } from "./_accordionAdvancedContent.interface"

const AccordionAdvancedContentBlocks = ({ cardId, cardContent }: any) => {
  /** calling swr here to get flexible child component data of single tab */
  const { data: cardData, isLoading } = useSWRRequest(cardId, async () => {
    try {
      const response = await getAllFleixbleBlocksData(cardContent)

      return { blocks: response }
    } catch (error) {
      console.error("accordion error", error)
    }
  })
  return (
    <>
      {isLoading ? (
        <div className="load-container">
          <Loader display={true} />
        </div>
      ) : (
        <Flexible {...cardData} isPaywall3Hidden={true} />
      )}
    </>
  )
}
const scrollToID = (id: any) => {
  setTimeout(() => {
    if (id) {
      const element = document?.getElementById(id)
      const headerOffset = 150
      const elementPosition = element?.getBoundingClientRect().top ?? NUMBERS.ZERO
      const offsetPosition = elementPosition + window.scrollY - headerOffset

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }, NUMBERS.HUNDRED)
}
const AccordionAdvancedContent = ({
  accordionBehavior,
  accordionExperience,
  cardInitState,
  accordId,
  id,
  index,
  cardUrl,
  cardId,
}: AccordionAdvanceContent) => {
  const user: any = useSelector((state: any) => state?.user)
  const dispatch: Dispatch<any> = useDispatch()
  const updateAccordionPaywall3Roles: any = useCallback(
    (value: any) => dispatch(updatePaywall3Roles(value)),
    [dispatch],
  )
  const updateAccordionPaywallContent: any = useCallback(
    (value: any) => dispatch(updatePaywallContent(value)),
    [dispatch],
  )
  /** calling swr here to get parent tab data with titles of accordion */
  const { data: cardData, isLoading } = useSWRRequest(cardUrl, async () => {
    try {
      const response = await getAccordionCardData(cardUrl)
      return response
    } catch (error) {
      console.error("accordion error", error)
    }
  })

  useEffect(() => {
    if (!isLoading && cardData?.fieldPaywallRoles.length && !user?.isLoggedIn) {
        updateAccordionPaywall3Roles(Array.from(new Set(cardData?.fieldPaywallRoles)))
        updateAccordionPaywallContent(true)
      }
  }, [isLoading, cardData, user])

  const uID = v4()
  const state =
    accordionBehavior === "Expand selected"
      ? parseInt(cardInitState) === 0
      : accordionBehavior === "Collapse all"
  const attributes =
    accordionExperience === "One" ? { "data-bs-parent": id ? `#${id}` : `#accord-${accordId}` } : ""
  return (
    <>
      {!cardData?.isParentPaywalled && (
        <div key={index} className="accordion-item" id={cardData?.referrerId}>
          <h3 className="accordion-header" id={`accord-heading_${index + 1}_${uID}`}>
            <button
              className={`accordion-button ${state ? "collapsed" : ""}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index + 1}_${uID}`}
              aria-controls={`collapse${index + 1}_${uID}`}
              onClick={() => scrollToID(`accord-heading_${index + 1}_${uID}`)}>
              <span> {cardData?.title} </span>{" "}
              <p className="accordionIcon">
                <Icon iconName="RightExpandIcon2" />
              </p>
            </button>
          </h3>
          <div
            id={`collapse${index + 1}_${uID}`}
            className={`accordion-collapse collapse ${state ? "" : "show"}`}
            data-exper={accordionExperience}
            aria-labelledby={`accord-heading_${index + 1}_${uID}`}
            {...attributes}
            role="region">
            <div className="accordion-body">
              {!isLoading && cardData?.content && (
                <AccordionAdvancedContentBlocks cardId={cardId} cardContent={cardData?.content} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default AccordionAdvancedContent
