import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getInlineQuotationData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let InlineQuotationData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(InlineQuotationData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(InlineQuotationData)
  InlineQuotationData = InlineQuotationData?.data
  return {
    title: InlineQuotationData?.attributes?.field_title_inline?.value ?? "",
    description: InlineQuotationData?.attributes?.field_description?.value ?? "",
    reference: InlineQuotationData?.attributes?.field_reference?.value ?? "",
    quote: InlineQuotationData?.attributes?.field_quote?.value ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getInlineQuotationData
