import { IButtonProps } from "./_textButton.interface"

const TextButton = (props: IButtonProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("btn-text")
    props.secondary ? classes.push("btn-secondary") : classes.push("btn-primary")
    props.icon && classes.push("btn-icon")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <button className={getClasses()} disabled={props.isDisabled} onClick={props.onClick}>
      {props.icon && <span className="icon">{props.icon}</span>}
      {props.children}
    </button>
  )
}

export default TextButton
