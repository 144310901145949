import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData } from "."

const getMicrositeEyebrowMenuData = async (url: string) => {
  let micrositeEyebrowMenuData = await getBlockData(url)
  const links: any = []
  let logo
  
  getNestedObject(micrositeEyebrowMenuData, "data.relationships.field_eyebrow_menu_links.data")?.map((item: any) => {
    micrositeEyebrowMenuData?.included?.map((menus: any) => {if (item?.meta?.drupal_internal__target_id === menus?.attributes?.drupal_internal__id && menus?.type == "paragraph--eyebrow_menu_links") {
        links.push({
          type: menus?.attributes?.field_eyebrow_menu_link_type,
          name: menus?.attributes?.field_title,
          href: filterURL(getNestedObject(menus, "attributes.field_link.uri")) || menus?.attributes?.field_email || menus?.attributes?.field_external_link?.uri || menus?.attributes?.field_component_id
        })
      }
      else if (menus?.type == "file--file") {
        logo = getNestedObject(menus,"attributes.uri.url")
      }
    })
  })
  return {
    links,
    logo
  }
}

export default getMicrositeEyebrowMenuData
