import React from "react"
import { Icon, ModalBody, ModalHeader } from "@atoms"
import { ModalPortal, SocialShareModalContent } from "@molecules"
import { ISocialShareModalProps } from "./_socialShareModal.interface"


const SocialShareModal = ({
  modalTitle,
  sharingPlatforms,
  pageLinkLabel,
  copiedLabel,
  onClose,
}: ISocialShareModalProps) => {
  const modalContent = (
    <div className="social-share-modal">
      <ModalHeader titleText={modalTitle} closeIcon={<Icon iconName="CloseIcon2" />} onClose={onClose} />
      <ModalBody
        content={
          <SocialShareModalContent
            sharingPlatforms={sharingPlatforms}
            pageLinkLabel={pageLinkLabel}
            copiedLabel={copiedLabel}
          />
        }
      />
    </div>
  )

  return <ModalPortal modalContent={modalContent} wrapperId="react-portal-wrapper" className="social-sharing" />
}

export default SocialShareModal
