import React, { useState } from "react"
import { Button } from "@atoms"
import Loader from "@atoms/loader"
import { fetchTabContent } from "@helpers/dataFunctions/getFlexibleTabsData"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import ButtonGroup from "@molecules/button-group"
import { Flexible } from "@templates"
import { IDecisionTreeNodeProps } from "./_decisionTreeNode.interface"

const DecisionTreeNode = ({ displayLabels, treeData, depth, handleBack, handleStart }: IDecisionTreeNodeProps) => {
  const { tid, content, children } = treeData || {}
  const [selectedChild, setSelectedChild] = useState<string | null>(null)
  const childNodes = children ? Object.values(children)?.sort((c1: any, c2: any) => c1?.weight - c2?.weight) : []

  const { data, isLoading } = useSWRRequest(`${tid}-content`, async () => {
    try {
      const flexContent = content?.block_content?.length ? content?.block_content : []
      const response = await fetchTabContent(flexContent)
      return response || {}
    } catch (error) {
      console.error("Error in fetching node content: ", error)
    }
  })

  const backBtn = (
    <Button
      tabindex={0}
      isSecondary
      onClick={() => {
        handleBack && handleBack()
      }}>
      {displayLabels?.backBtn}
    </Button>
  )
  const startBtn = (
    <Button
      tabindex={0}
      isSecondary
      onClick={() => {
        handleStart && handleStart()
      }}>
      {displayLabels?.startBtn}
    </Button>
  )

  const getNodeContent = () =>
    isLoading ? (
      <Loader display={isLoading} />
    ) : (
      <div className="decision-tree-node--content">
        {data?.blocks?.length !== 0 && <Flexible {...data} />}
      </div>
    )

  const handleBackAction = () => {
    setSelectedChild(null)
    document?.getElementById(tid)?.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className="decision-tree-node" key={tid} id={tid}>
      {depth === 0 && <h3 className="decision-tree-node--title">{content?.button_label}</h3>}
      {getNodeContent()}
      {!isLoading && (
        <ButtonGroup className="decision-tree-node--button-grp">
          {depth > 0 && !selectedChild ? backBtn : <></>}
          {
            <>
              {childNodes?.map((item: any, index: number) => (
                <Button
                  key={`${tid}-${index}`}
                  tabindex={index}
                  isDisabled={!!selectedChild && selectedChild !== item?.tid}
                  onClick={() => setSelectedChild(item?.tid)}>
                  {item?.content?.button_label}
                </Button>
              ))}
            </>
          }
          {depth > 1 && !selectedChild ? startBtn : <></>}
        </ButtonGroup>
      )}
      <div>
        {selectedChild &&
          childNodes?.map((item: any, index: number) =>
            item?.tid === selectedChild ? (
              <DecisionTreeNode
                key={`${item?.tid}-${index}`}
                displayLabels={displayLabels}
                treeData={item}
                depth={depth + 1}
                handleBack={() => handleBackAction()}
                handleStart={() => (depth === 0 ? handleBackAction() : handleStart && handleStart())}
              />
            ) : (
              <></>
            ),
          )}
      </div>
    </div>
  )
}

export default DecisionTreeNode
