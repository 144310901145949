import Link from "next/link"
import { NavItemProps } from "./_nav-item.interface"

const NavItem = (props: NavItemProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("nav-item")
    if (props.active) classes.push("active")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <li className={getClasses()}>
      <Link href={props.href} legacyBehavior>
        <a className="nav-link">{props.children}</a>
      </Link>
    </li>
  )
}

export default NavItem
