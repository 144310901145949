import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData } from "."

const getCTAButtonGroupData = async (url: string, data?: any) => {
  let ctaBtnData
  if (!data) {
    ctaBtnData = await getBlockData(`${url}`)
  } else {
    ctaBtnData = data
  }
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(ctaBtnData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(ctaBtnData)
  return {
    ctaBtnType: "cta_button",
    ctaBtnData: {
      fieldButtonAlignment: isPaywallProtected(
        getNestedObject(ctaBtnData, "attributes.field_button_alignment"),
      ).isPaywallProtectedFlag
        ? ""
        : getNestedObject(ctaBtnData, "attributes.field_button_alignment") ?? "",
      fieldButtonUri: filterURL(getNestedObject(ctaBtnData, "attributes.field_cta_url.uri")) ?? "",
      referenceId: getNestedObject(ctaBtnData, "attributes.field_reference_id") ?? "",
      iconAlignment: getNestedObject(ctaBtnData, "attributes.field_icon_alignment") ?? "",
      iconType: getNestedObject(ctaBtnData, "attributes.field_icon_type") ?? "",
    },
    ctaBtnLabel: getNestedObject(ctaBtnData, "attributes.field_cta_url.title") ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getCTAButtonGroupData
