import { AnchorButton, Column, Container, RichText } from "@atoms"
import { IHeroBannerContentProps } from "./_heroBannerContent.interface"
import { useContext, useMemo } from "react"
import * as cheerio from "cheerio"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { DOMSanitizer } from "@utils/dompurify"

const HeroBannerContent = ({
  link,
  isExternalLink,
  linkText,
  title,
  paragraph,
  isOverlay,
  isOverlayFontType,
  cta_two_label,
  cta_two_uri,
  cta_two_externalLink,
  cta_three_label,
  cta_three_uri,
  cta_three_externalLink,
  titleSuperscript,
}: IHeroBannerContentProps) => {
  const { applicationTheme } = useContext(ApplicationThemeContext)
  const heroBannerTitle = useMemo(() => {
    /**
     * In case super script is not available on title
     */
    if (title) {
      return <h1 className="heroBannerContent-title heroBannerContent-break-word">{title}</h1>
    }

    /**
     * In case the title is having special chars like <sup> or inverse triangle (ASCII chars)
     */
    if (titleSuperscript) {
      const parsedHtml = cheerio.load(titleSuperscript, { xmlMode: false })
      const htmlTitleElement: any = parsedHtml("p")?.html()
      return <h1 className="heroBannerContent-title heroBannerContent-break-word" dangerouslySetInnerHTML={{ __html: DOMSanitizer(htmlTitleElement) }} />
    }

  }, [title, titleSuperscript])

  let themeClass = ""
  let themeFont = ""
  const getClasses = () => {
    let handleWidth = ""
    if (
      applicationTheme === "rebranding" ||
      applicationTheme === "rebranding-japan" ||
      applicationTheme === "jubbonti" ||
      applicationTheme === "wyost" ||
      applicationTheme === "biosimilarsinbone" ||
      applicationTheme === "hexal" ||
      applicationTheme === "vagidonna"
    ) {
      if (isOverlay === "on" && isOverlayFontType === "light") {
        themeClass = "text-overlay-dark"
      } else if (isOverlay === "on" && isOverlayFontType === "dark") {
        themeClass = "text-overlay"
      }
      themeFont = isOverlayFontType === "light" ? "font-light" : "font-dark"
    }
    try {
      if (themeClass !== "") {
        title?.split(" ")?.map((text: string) => {
          text.length > 14 ? (handleWidth = "extended-width") : ""
        })
      }
    } catch (error) {
      handleWidth = ""
    }
    return `heroBannerContent-textdata ${themeClass} ${themeFont} ${handleWidth}`
  }

  const renderAnchor = () => {
    return (
      <>
        {link && (
          <AnchorButton
            href={`${link}`}
            isExternal={isExternalLink ?? false}
            tabindex={0}
            isSecondary={false}
            className="heroBannerContent-grp">
            {`${linkText}`}
          </AnchorButton>
        )}
        {cta_two_uri && (
          <AnchorButton
            href={`${cta_two_uri}`}
            isExternal={cta_two_externalLink ?? false}
            tabindex={0}
            isSecondary={false}
            className="heroBannerContent-grp">
            {`${cta_two_label}`}
          </AnchorButton>
        )}
        {cta_three_uri && (
          <AnchorButton
            href={`${cta_three_uri}`}
            isExternal={cta_three_externalLink ?? false}
            tabindex={0}
            isSecondary={false}
            className="heroBannerContent-grp">
            {`${cta_three_label}`}
          </AnchorButton>
        )}
      </>
    )
  }

  const renderAnchorOverly = () => (
    <div>{renderAnchor()}</div>
  )

  const renderShader = () => {
    let shader: any = ""
    try {
      if (applicationTheme === "hexal" && themeClass !== "") {
        shader = <div className="heroBannerContent-shader" />
      }
      if (applicationTheme !== "hexal") {
        shader = <div className="heroBannerContent-shader" />
      }
    } catch (err) {
      shader = ""
    }
    return shader
  }

  return (
    <Container className="heroBannerContent">
      <Column
        mobile={12}
        tablet={
          applicationTheme === "rebranding" ||
          applicationTheme === "rebranding-japan" ||
          applicationTheme === "jubbonti" ||
          applicationTheme === "wyost" ||
          applicationTheme === "biosimilarsinbone" ||
          applicationTheme === "vagidonna"
            ? 6
            : 4
        }
        desktop={
          applicationTheme === "rebranding" ||
          applicationTheme === "rebranding-japan" ||
          applicationTheme === "jubbonti" ||
          applicationTheme === "wyost" ||
          applicationTheme === "biosimilarsinbone" ||
          applicationTheme === "vagidonna"
            ? 5
            : 4
        }
        className={getClasses()}>

        <>{heroBannerTitle && <>{heroBannerTitle}</>}</>
        <div className="heroBannerContent-text-style">
          {paragraph ? <RichText className="heroBannerContent-text" content={paragraph} /> : null}
        </div>

        <>{isOverlay === "on" ? renderAnchorOverly() : renderAnchor()}</>
      </Column>

      {applicationTheme !== "rebranding" &&
      applicationTheme !== "rebranding-japan" &&
      applicationTheme !== "jubbonti" &&
      applicationTheme !== "wyost" &&
      applicationTheme !== "biosimilarsinbone" &&
      applicationTheme !== "vagidonna" ? (
        renderShader()
      ) : (
        <></>
      )}
    </Container>
  )
}

export default HeroBannerContent
