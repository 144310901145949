import { useState } from "react"
import {
 Column, Icon, Img, RichText, Row,
} from "@atoms"
import { v4 } from "uuid"
import { IAccordionWithImageProps } from "./_accordionWithImage.interface"

const AccordionWithImage = (props: IAccordionWithImageProps) => {
  const [isActive, setIsActive] = useState(true)
  const accordId = v4()
  return (
    <div id = {props.referrerId} className="custom-accordion">
      <div className="accordion" id={props.id ? props.id : `accord-${accordId}`}>
        {props.accordionArray?.map((item: IAccordionWithImageProps, index: number) => {
          const uID = v4()

          return (
            <div key={index} id={item?.referrerId} className="accordion-item accordion-with-image">
              <div className="accordion-header bold" id={`accord-heading_${index + 1}_${uID}`}>
                <button
                  className={`accordion-button ${index !== 0 ? "collapsed" : ""}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index + 1}_${uID}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index + 1}_${uID}`}
                  onClick={() => {
                    setIsActive(!isActive)
                  }}
                >
                  <h5>
                    {item?.title}
                  </h5>
                  <p className="accordionIcon">
                    <Icon iconName="RightExpandIcon2" width={32} height={32} />
                  </p>
                </button>
              </div>
              <div
                id={`collapse${index + 1}_${uID}`}
                className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                aria-labelledby={`accord-heading_${index + 1}_${uID}`}
                data-bs-parent={props.id ? `#${props.id}` : `#accord-${accordId}`}
                role="region"
              >
                <div className="accordion-body">
                  <Row>
                    <Column
                      mobile={12}
                      tablet={item?.image?.imageUrl ? 6 : 12}
                      desktop={item?.image?.imageUrl ? 6 : 12}
                      className="accordion-with-image-content"
                    >
                      <div>{item?.content && <RichText content={item?.content} />}</div>
                    </Column>
                    {item?.image?.imageUrl && (
                      <Column mobile={12} tablet={6} desktop={6}>
                        <div className="accordion-with-image-block">
                          <Img
                            type="accordion_with_image"
                            styledimage={item?.image?.imageStyledUrl}
                            src={item?.image?.imageUrl}
                            alt={item?.image?.alt}
                            width={640}
                            height={360}
                          />
                        </div>
                      </Column>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default AccordionWithImage
