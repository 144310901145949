import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "@utils/nestedObjects"

const formatTableData = async (
  data: any,
  isColumnSticky: boolean,
  isRowSticky: boolean,
  cellAlignment: string,
  imageArray: any
) => {
  return Object.keys(data)
    .filter((key) => key !== "caption")
    .map((key) => {
      const { weight, ...rest } = data[key]
      const rowContent = Object.keys(rest).map((innerKey) => {
        const isImageField = !!rest?.[innerKey]?.image_path
        const content = isImageField ? rest?.[innerKey]?.processed_text : rest?.[innerKey]?.value
        const imgData = {} as any
        imgData.src = rest?.[innerKey]?.image_path ?? null
        imgData.image_style_uri = rest?.[innerKey]?.image_style_uri ?? null
        if (key === "0") {
          imageArray.push(imgData)
        }
        return {
          isFroozen: innerKey === "0" && isColumnSticky,
          content,
          cellAlignment,
          isImageField,
          imgData,
        }
      })
      const isHead = key === "0"
      const isFroozen = key === "0" && isRowSticky
      return { rowContent, weight, isHead, isFroozen }
    })
}

const getSolarTableData = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getSolarTableData.name}.`)
  }

  const serverData = args?.[3] // accepting serverData only
  let rawData = await getBlockData(`${url}`, false, serverData) ?? {}
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const tableData = rawData?.data?.attributes?.field_table_structure?.value ?? {}
  const isColumnSticky = rawData?.data?.attributes?.field_first_column_sticky ?? false
  const isRowSticky = rawData?.data?.attributes?.field_first_row_sticky ?? false
  const cellAlignment = rawData?.data?.attributes?.field_cell_content_alignment ?? ""
  const customRowCount = rawData?.data?.attributes?.field_custom_row_count ?? null
  const defaultRowCount = rawData?.data?.attributes?.field_default_row_count ?? "all_rows"
  const isArrowEnabled = rawData?.data?.attributes?.field_enable_arrow ?? false
  const tableAlignment = rawData?.data?.attributes?.field_table_alignment ?? "left"
  const tableTitle = rawData?.data?.attributes?.field_table_title?.value ?? ""
  const tableCaption = rawData?.data?.attributes?.field_table_structure?.caption ?? ""
  const imageArray: never[] = []
  const formattedTableData = await formatTableData(
    tableData,
    isColumnSticky,
    isRowSticky,
    cellAlignment,
    imageArray,
  )

  return {
    formattedTableData,
    customRowCount,
    defaultRowCount,
    isArrowEnabled,
    tableAlignment,
    imageArray,
    tableTitle,
    isColumnSticky,
    isRowSticky,
    isParentPaywalled,
    fieldPaywallRoles,
    tableCaption
  }
}

export default getSolarTableData
