export const convertFromBytes = (bytes: number) => {
  if (bytes === 0) return "0 B"
  const k = 1000
  const dm = 2
  const sizes = ["B", "Kb", "Mb", "Gb"]
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`
}

export const getMediaFile = (type: string) => {
  switch (type) {
    case "media_document":
      return "field_media_file"

    case "image":
      return "field_media_image"

    case "media_audio":
      return "field_media_audio_file"

    case "media_video":
      return "field_media_video_file"

    default:
      return ""
  }
}
