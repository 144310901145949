import { Anchor, AnchorButton, RichText, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { ITherapyCardContentProps } from "./_therapyCardContent.interface"

const TherapyCardContent = (props: ITherapyCardContentProps) => (
  <div className="therapy-card-content">
    {props?.taxonomies && props?.relatedContent && <Taxonomy taxonomies={props.taxonomies} />}
    {props?.heading && (
      <Anchor
        href={props?.buttonUrl}
        noUnderline
        tabindex={0}
        isExternal={props?.isButtonUrlExternal}>
        <h4 className="therapy-card-content-card-title">
          {trimText(props?.heading, CHARLIMIT.TITLE)}
        </h4>
      </Anchor>
    )}
    {props?.text && (
      <RichText
        className="therapy-card-content-card-text"
        content={props?.text}
        characterLimit={CHARLIMIT.DESCRIPTION}
      />
    )}
    <div className="therapy-card-content-button">
      {props.buttonUrl && (
        <AnchorButton
          href={props.buttonUrl}
          isExternal={props.isButtonUrlExternal}
          tabindex={2}
          isSecondary>
          {props.buttonLabel}
        </AnchorButton>
      )}
    </div>
  </div>
)

export default TherapyCardContent
