import Flexible from "./flexible"
import FourZeroFourPage from "./fourzerofourpage"
import Layout from "./layout"
import SearchResultPage from "./searchResultPage"
import SitemapPage from "./sitemapPage"
import ProfileTemplate from "./profile"

export {
 Flexible, FourZeroFourPage, Layout, SearchResultPage, SitemapPage, ProfileTemplate,
}
