import {getAllRoles, getPaywalledImgData, isPaywallProtected} from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import {filterURL, getBlockData, getImageMeta} from "."

const getGoogleMapData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const mapData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(mapData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(mapData)
  const imagePaywallData = isPaywallProtected(mapData?.data?.relationships?.field_google_image)
  const {imageUrl, alt, imageStyledUrl} = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(mapData?.data?.relationships?.field_google_image)

  return {
    image: imageUrl ?? null,
    alt: alt ?? null,
    styledimage: imageStyledUrl ?? null,
    href: mapData?.data?.attributes?.field_button?.uri
      ? filterURL(mapData?.data?.attributes?.field_button?.uri)
      : null,
    fieldPaywallRoles,
    isParentPaywalled,
  }

}

export default getGoogleMapData
