import {Column} from "@atoms"
import ArticleTeaserCompositionContent from "../../molecules/articleTeaserContent"
import { IArticleTeaserGroupProps } from "./_article-teaser-group.interface"

const ArticleTeaserGroup = (props: IArticleTeaserGroupProps) => {
  const articleTeaserSingleContentData = props.articleTeaserSingleContentGroup

  if (articleTeaserSingleContentData && 
      articleTeaserSingleContentData?.length <= 0) {
    return <></>
  }

  return (
    <>
      {articleTeaserSingleContentData && articleTeaserSingleContentData.map((teaserDetails, key) => (
        <Column
          key={key}
          mobile={12}
          tablet={6}
          desktop={6}
          className="articleTeaserGroup-teaserCol">
          <ArticleTeaserCompositionContent key={key} {...teaserDetails} />
        </Column>
      ))}
    </>
  )
}
export default ArticleTeaserGroup
