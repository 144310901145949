import { AnchorButton, Column, Img, RichText, Row } from "@atoms"
import { IErrorFourZeroFourProps } from "./_errorfourzerofour.interface"

const ErrorFourZeroFour = ({ imgsrc, cta, header, paragraph }: IErrorFourZeroFourProps) => (
  <Row className="four-zero-four">
    <Column mobile={12} tablet={6} desktop={6} className="four-zero-four-img">
      {imgsrc.src && (
        <Img
          className={imgsrc?.className}
          src={imgsrc?.src}
          alt={imgsrc?.alt}
          height={"416"}
          width={"400"}
          type="icon"
        />
      )}
    </Column>
    <Column mobile={12} tablet={6} desktop={6} className="four-zero-four-txt">
      <div className="four-zero-four-txt-wrapper">
        {header && <h1>{header}</h1>}
        {paragraph && <RichText content={paragraph} />}
        {cta.href && (
          <AnchorButton href={cta.href} tabindex={cta.tabindex} isExternal={false}>
            {cta.children}
          </AnchorButton>
        )}
      </div>
    </Column>
  </Row>
)

export default ErrorFourZeroFour
