import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"

const getStockTickerData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getStockTickerData.name}.`)
  const serverData = args?.[3] // accepting serverData only

  const stockTickerData = await getBlockData(`${url}`, false, serverData)

  const slide = stockTickerData?.data ?? {}
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(slide, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(slide)

  return {
    iframeURL: getNestedObject(slide, "attributes.field_iframe_raw_url.uri") ?? null,
    width: getNestedObject(slide, "attributes.field_width") ?? null,
    height: getNestedObject(slide, "attributes.field_height") ?? null,
    title: getNestedObject(slide, "attributes.field_header_title.value") ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getStockTickerData
