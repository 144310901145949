import { useRef, useState } from "react"
import { Button, Column, RichText } from "@atoms"
import { IReadMoreContentProps } from "./_readMoreContent.interface"

const ReadMoreContent = ({
  title,
  description,
  showMoreButtonLabel,
  showLessButtomLabel,
}: IReadMoreContentProps) => {
  const readMoreTitle = title || " "
  const readMoreText = description || " "
  const [isReadMore, setIsReadMore] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
    if (isReadMore) {
      ref?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }

  const regex = /<p>(.*?)<\/p>/
  const corresp = regex.exec(readMoreText)
  const firstParagraph = corresp ? corresp[0] : ""
  return (
    <Column desktop={12} mobile={12} tablet={12} className="readMorecontent">
      <div>
        {readMoreText && (
          <div className="content" ref={ref}>
            <div className="text-block">
              <h5>{readMoreTitle}</h5>
              {!isReadMore ? (
                <RichText content={firstParagraph} />
              ) : (
                <RichText content={readMoreText} />
              )}

              {showMoreButtonLabel && showLessButtomLabel && (
                <Button className="btn" onClick={toggleReadMore} tabindex={1} isSecondary={true}>
                  {!isReadMore ? showMoreButtonLabel : showLessButtomLabel}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Column>
  )
}
export default ReadMoreContent
