/* eslint-disable react/jsx-key */
import { Column, Img, Row } from "@atoms"
import { TestimonialCardContent } from "@molecules"
import { CarouselWrapper } from "@organisms"
import getDirection from "@utils/direction"
import NUMBERS from "@helpers/constants/numbers"
import { ITestimonialProps, TSlidesInfo } from "./_testimonial.interface"

const TestimonialSlide = ({
  logoImageUrl,
  logoImageAlt,
  alt,
  image,
  styledimage,
  className,
  ...rest
}: TSlidesInfo) => (
  <Row key={rest.testimonialContent.name} className={`testimonial ${className}`}>
    <Column mobile={12} tablet={12} desktop={6} className="testimonial-content-left">
      <TestimonialCardContent
        {...rest.testimonialContent}
        logoImageUrl={logoImageUrl}
        logoImageAlt={logoImageAlt}
      />
    </Column>
    <Column mobile={12} tablet={12} desktop={6} className="testimonial-content-right">
      <Img
        className="testimonial-image-content"
        type="testimonials"
        styledimage={styledimage}
        alt={alt}
        src={image}
        width="548"
        height="370"
      />
    </Column>
  </Row>
)

const Testimonial = ({ slidesInfo }: ITestimonialProps) => (
  <CarouselWrapper
    className="testimonial-caraousel"
    slideToShow={1}
    slideToShowTablet={1}
    dir={getDirection()}>
    {slidesInfo.map((slides: TSlidesInfo, key: number) => (
      <TestimonialSlide key={key}
        {...slides}
        className={slidesInfo.length === NUMBERS.ONE ? "single-slide" : ""}
      />
    ))}
  </CarouselWrapper>
)

export default Testimonial
