import { BrandingPattern, Container } from "@atoms"
import { useContext, useEffect, useState } from "react"
import ApplicationContext from "@utils/application-context/applicationContext"
import { ISearchResultPageProps } from "./_searchResultPage.interface"
import GlobalSearchResult from "../../organisms/globalSearchResult"
import Breadcrumbs from "../../organisms/breadcrumbs"
import TitleDescription from "../../molecules/title-description"

const SearchResultPage = (props: ISearchResultPageProps) => {
  const [resultData, setResultData] = useState<any>({})
  const applicationConfigContext = useContext(ApplicationContext)

  useEffect(() => {
    const renderNoResultText = async () => {
      const noResultData = applicationConfigContext.applicationConfiguration.siteConfig
      setResultData(
      {
        global_no_result_text: noResultData?.search_no_results_text_global ?? null,
      },
)
    }
    renderNoResultText()
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  const { breadcrumbs, searchedPhrase, countResults } = props
  return (
    <>
      <BrandingPattern />
      <main className="globalSearchResult">
        <Container>
          <Breadcrumbs {...breadcrumbs} />
        </Container>
        {countResults ? (
          <GlobalSearchResult {...props} />
        ) : (
          <Container>
            <TitleDescription
              title={resultData.global_no_result_text}
              searchKey={searchedPhrase.toString()}
            />
          </Container>
        )}
      </main>
    </>
  )
}

export default SearchResultPage
