import { RichText } from "@atoms"
import InputBox from "@atoms/inputBox"
import React, { useState } from "react"
import {
  Errors,
  IInputBoxPasswordValidationProps,
  PasswordValidation,
} from "./_inputBoxPasswordValidation.interface"

const InputBoxPasswordValidation = ({
  type,
  icon,
  label,
  inputField,
  passwordValidationErrorMessage,
}: IInputBoxPasswordValidationProps) => {
  const [showPassword, setShowPassword] = useState(false)

  const [errors, setErrors] = useState<Errors>({})

  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const passwordValidationMessage = passwordValidationErrorMessage
  const [passwordValidationStr, setPasswordValidation] = useState<PasswordValidation>({
    length: `<li>${passwordValidationMessage[0]}</li>`,
    upperCase: `<li>${passwordValidationMessage[1]}</li>`,
    lowerCase: `<li>${passwordValidationMessage[2]}</li>`,
    number: `<li>${passwordValidationMessage[3]}</li>`,
    specialChar: `<li>${passwordValidationMessage[4]}</li>`,
  })

  errors.password = Object.values(passwordValidationStr).join("")

  const handlePassword = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const fieldValue = (e.target as HTMLInputElement).value.trim()

    const passwordValidation: PasswordValidation = {}

    if (fieldValue.length < 8) {
      passwordValidation.length = `<li>${passwordValidationMessage[0]}</li>`
    } else {
      passwordValidation.length = `<li class="valid">${passwordValidationMessage[0]}</li>`
    }
    if (!/[A-Z]/.test(fieldValue)) {
      passwordValidation.upperCase = `<li>${passwordValidationMessage[1]}</li>`
    } else {
      passwordValidation.upperCase = `<li class="valid">${passwordValidationMessage[1]}</li>`
    }
    if (!/[a-z]/.test(fieldValue)) {
      passwordValidation.lowerCase = `<li>${passwordValidationMessage[2]}</li>`
    } else {
      passwordValidation.lowerCase = `<li class="valid">${passwordValidationMessage[2]}</li>`
    }
    if (!/[0-9]/.test(fieldValue)) {
      passwordValidation.number = `<li>${passwordValidationMessage[3]}</li>`
    } else {
      passwordValidation.number = `<li class="valid">${passwordValidationMessage[3]}</li>`
    }
    if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(fieldValue)) {
      passwordValidation.specialChar = `<li>${passwordValidationMessage[4]}</li>`
    } else {
      passwordValidation.specialChar = `<li class="valid">${passwordValidationMessage[4]}</li>`
    }

    setPasswordValidation(passwordValidation)
    errors.password = Object.values(passwordValidation).join("")
  }

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = `${e.target.name}`
    const fieldValue = e.target.value.trim()
    const newErrors: any = { ...errors }

    if (!fieldValue) {
      newErrors[fieldName] = `Please enter your ${fieldName}.`
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)
    const field = e.target
    if (fieldValue.length <= 0) {
      field.classList.add("invalid")
    } else {
      field.classList.remove("invalid")
    }
  }

  const handleConfirmPassword = () => {
    if (password !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match."
    } else {
      errors.confirmPassword = ""
    }

    if (Object.keys(errors).length) {
      setErrors(errors)
    }
  }

  const getClasses = () => {
    const classes: string[] = []
    classes.push("input")
    inputField?.disabled ? classes.push("disabled") : classes.push("enable")

    return classes.join(" ")
  }

  const getType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password"
    }
    return type
  }

  const renderWithIcon = () => (
    <span onClick={() => setShowPassword(!showPassword)} className="inputBox-withIcon">
      {showPassword ? <img src={icon?.iconOpen} /> : <img src={icon?.iconClose} />}
      {icon?.message ? <span className="tooltiptext">{icon.message}</span> : null}
    </span>
  )
  const renderLabel = () => (
    <label aria-required={inputField.required} className="inputBox-withLabel">
      {label}
      {inputField.required && "*"}
    </label>
  )
  const confirmationError = errors.confirmPassword ? errors.confirmPassword : ""
  return (
    <div className="input-box-with-label">
      {label && renderLabel()}
      {icon && renderWithIcon()}
      {inputField.name === "password" ? (
        <>
          <InputBox
            {...inputField}
            className={getClasses()}
            type={getType()}
            value={password}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
              setPassword(e.target.value)
            }
            onBlur={handleBlur}
            onKeyUp={handlePassword}
          />
          <RichText
            className="error"
            content={`${errors.password}` && `<ul class="validation">${errors.password}</ul>`}
          />
        </>
      ) : (
        <>
          <InputBox
            {...inputField}
            className={getClasses()}
            type={getType()}
            value={confirmPassword}
            onChange={(e: { target: { value: React.SetStateAction<string> } }) =>
              setConfirmPassword(e.target.value)
            }
            onBlur={handleBlur}
            onKeyUp={handleConfirmPassword}
          />
          <RichText className="error" content={confirmationError} />
        </>
      )}
    </div>
  )
}

export default InputBoxPasswordValidation
