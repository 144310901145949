import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  interfaceFilters: [] as any,
  currentPage: 0,
  searchText: "",
  sort: {
    column: "created",
    order: "DESC",
  },
}

const InterfaceSearchReducer = (state: any = initialFilterData, action: InterfaceSearchAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_INTERFACE_SEARCH:
      return {
        ...state,
        interfaceFilters: action.interfaceFilters,
        currentPage: 0,
        sort: {
          column: "created",
          order: "DESC",
        },
      }
      case actionType.UPDATE_INTERFACE_PAGE_NUMBER:
        return {
          ...state,
          currentPage: action.currentPage,
        }
      case actionType.UPDATE_INTERFACE_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          currentPage: 0,
          sort: {
            column: "created",
            order: "DESC",
          },
        }
      case actionType.UPDATE_ALL_INTERFACE_SEARCH:
        return {
          ...state,
          interfaceFilters: action.interfaceFilters,
          currentPage: action.currentPage,
          searchText: action.searchText,
          sort: action?.sort || {
            column: "created",
            order: "DESC",
          },
        }
      case actionType.UPDATE_INTERFACE_SORT:
      return {
        ...state,
        sort: action.sort,
      }
    default:
      return state
  }
}

export default InterfaceSearchReducer
