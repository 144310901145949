import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"
import { getApi } from "@utils/baseApi"

const getIframeWrapperData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getIframeWrapperData.name}.`)
  const serverData = args?.[3] // accepting serverData only

  const iframeWrapperData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(iframeWrapperData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const field = iframeWrapperData?.data ?? {}
  const fieldPaywallRoles = getAllRoles(field)

  const iframeUrlData = await getApi(`${iframeWrapperData?.data?.relationships?.field_iframe_url?.links?.related?.href}`, true, serverData)

  return {
    iframeURL: getNestedObject(iframeUrlData?.data, "attributes.field_iframe_url.uri") ?? null,
    width: getNestedObject(field, "attributes.field_iframe_width") ?? null,
    height: getNestedObject(field, "attributes.field_iframe_height") ?? null,
    title: getNestedObject(field, "attributes.field_iframe_header_title") ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getIframeWrapperData