import { ApplicationThemeProvider } from "@utils/application-theme-context/applicationThemeContext"
import { ReactNode, useEffect, useState } from "react"

type Props = {
    type: string
    children: ReactNode
}

type ThemeType = {
    themeType: string
}

const Theme = ({ type, children }: Props) => {
    const [theme] = useState<ThemeType>({ themeType: type })
    
    useEffect(() => {
        document.querySelector("html")!.className = `${theme.themeType}`
    }, [theme])

    return (
        <ApplicationThemeProvider theme={theme.themeType} pageLevelTheme={""}>
            {children}
        </ApplicationThemeProvider>
    )
}

export default Theme