import { useCallback } from "react"
import { Anchor, Column, Img } from "@atoms"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { addRatings } from "store/actions/RatingsActionCreators"
import getDirection from "@utils/direction"
import { INewsStoriesCardProps } from "./_newsStoriesCard.interface"
import NewsStoriesCardContent from "../../molecules/newsStoriesCardContent"
import { modifyRelatedContentData } from "@utils/relatedContentUtil"

const NewsStoriesCard = ({
  styledimage,
  imageSrc,
  imgAlt,
  newsStoriesContent,
  relatedContentFlag = false,
}: INewsStoriesCardProps) => {
  const newsStoriesCardData = relatedContentFlag
    ? modifyRelatedContentData(newsStoriesContent)
    : newsStoriesContent

  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const newsBookMark = (state: any) => {
    // add id details into store
    updateRatings({
      id: newsStoriesCardData.id,
      state,
    })
  }

  return (
    <Column
      desktop={relatedContentFlag ? 12 : 4}
      tablet={relatedContentFlag ? 12 : 6}
      mobile={12}
      className="news-stories-card">
      <div className="card" dir={getDirection()}>
        <div className="card-image">
          {imageSrc && (
            <div className="image-section">
              {!newsStoriesContent?.buttonUrl ? (
                <Img
                  type="card"
                  styledimage={styledimage}
                  src={imageSrc}
                  alt={imgAlt}
                  width={368}
                  height={220}
                  islazyloaded={false}
                />
              ) : (
                <Anchor
                  noUnderline
                  href={newsStoriesContent?.buttonUrl}
                  tabindex={0}
                  isExternal={newsStoriesContent?.isButtonUrlExternal}>
                  <Img
                    type="card"
                    styledimage={styledimage}
                    src={imageSrc}
                    alt={imgAlt}
                    width={368}
                    height={220}
                    islazyloaded={false}
                  />
                </Anchor>
              )}
            </div>
          )}
        </div>
        <div className="card-body">
          <NewsStoriesCardContent {...newsStoriesCardData} bookmarkCallback={newsBookMark} />
        </div>
      </div>
    </Column>
  )
}
export default NewsStoriesCard
