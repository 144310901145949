import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData, getImageMeta } from "."
import getNestedObject from "@utils/nestedObjects"
import { URL_TARGET } from "@helpers/constants/generic"

const getContactCardsData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let contactCardsData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(contactCardsData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const cardGroupAlignment =
    contactCardsData?.data?.attributes?.field_card_alignment_style ?? "left"

  contactCardsData = contactCardsData?.included ?? []
  const fieldPaywallRoles = getAllRoles(contactCardsData)

  const imagePaywallData = isPaywallProtected(
    contactCardsData?.relationships?.field_article_tc_image,
  )

  contactCardsData = contactCardsData?.map((contactCard: any) => {
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(contactCard, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag

    if (isChildPaywalled) {
      return null
    }
    const { imageUrl, alt, imageStyledUrl, width, height } = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(contactCard?.relationships?.field_cc_small_upload_image)

    return {
      contactCardImage: {
        src: imageUrl ?? null,
        alt: alt ?? null,
        imageStyledUrl: imageStyledUrl ?? null,
        width: width ?? null,
        height: height ?? null,
      },
      title: contactCard?.attributes?.field_cc_small_title?.processed
        ? contactCard?.attributes?.field_cc_small_title?.processed
        : null,
      designation: contactCard?.attributes?.field_cc_small_designation
        ? contactCard?.attributes?.field_cc_small_designation
        : null,
      paragraph: contactCard?.attributes?.body?.processed
        ? contactCard?.attributes?.body?.processed
        : null,
      address: contactCard?.attributes?.field_cc_small_address
        ? contactCard?.attributes?.field_cc_small_address
        : null,
      phone: isPaywallProtected(contactCard?.attributes?.field_cc_small_mobile_number)
        .isPaywallProtectedFlag
        ? ""
        : contactCard?.attributes?.field_cc_small_mobile_number ?? null,
      email: isPaywallProtected(contactCard?.attributes?.field_cc_small_email)
        .isPaywallProtectedFlag
        ? ""
        : contactCard?.attributes?.field_cc_small_email ?? null,
      fax: isPaywallProtected(contactCard?.attributes?.field_cc_small_fax_number)
        .isPaywallProtectedFlag
        ? ""
        : contactCard?.attributes?.field_cc_small_fax_number ?? null,
      ctaLink: filterURL(getNestedObject(contactCard, "attributes.field_cc_small_cta_link.uri")) ?? "",
      ctaTitle: getNestedObject(contactCard, "attributes.field_cc_small_cta_link.title") ?? "",
      ctaTarget:
          filterURL(getNestedObject(contactCard, "attributes.field_cc_small_cta_link.options.attributes.target")) ??
          URL_TARGET.SELF,
      cardAlignment: contactCard?.attributes?.field_text_alignment_style ?? "left",
    }
  })
  return {
    contactCardsData: filterNullValues(contactCardsData) ?? [],
    cardGroupAlignment,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getContactCardsData
