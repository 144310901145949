import { getApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"

const getMagazineMasonryData = async (url: string) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getMagazineMasonryData.name}.`)
  }
  let blockData = await getApi(`/jsonapi${url}`)
  const fieldPaywallRoles = getAllRoles(blockData)
  const magPageSize = getNestedObject(blockData, "magz_items_count_per_load") || 9
  blockData = blockData?.data
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(blockData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const magazineData = {
    masonryId: blockData?.id || "",
    layout: getNestedObject(blockData, "attributes.field_magazine_masonry_page_layo")?.split("-") ?? [],
    enableLoadMore: getNestedObject(blockData, "attributes.field_enable_load_more_button") ?? false,
    loadMoreLabel: getNestedObject(blockData, "attributes.field_load_more_button_label") ?? "",
    initialItemLoadForMagazine: magPageSize,
    fieldPaywallRoles,
    isParentPaywalled,
  }

  return magazineData
}

export default getMagazineMasonryData
