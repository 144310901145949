import { Paywallprops } from "@organisms/paywall/_paywall.interface"
import { getApi } from "@utils/baseApi"
import { getAssetPrefixUrl } from "@utils/helper"

const getPaywallData = async (siteConfig: any,  ...args: any): Promise<Paywallprops> => {
  const serverData = args?.[3] // accepting serverData only
  const response = await getApi("/webform_rest/gesund_signin/fields?_format=json", true, serverData)

  // @todo - read common config data
  // const configData = await getConfigDisplayData()
  const notificationMessages = siteConfig?.flash_num_msg?.filter((item: any) => item?.title == "token_expiry") ?? null

  return {
    publicTeaserType: "enable",
    tokenInvalidMessage: notificationMessages?.[0]?.error_message ?? null,
    isOrganism: true,
    specificDescriptionText: "",
    descriptionText: response?.hcp_text?.["#text"] ?? null,
    registerBlock: {
      registerTitle: "Register with your email",
      registerDescription: response?.register_now_text?.["#text"] ?? null,
      registerButtonText: response?.register_now?.["#title"] ?? null,
      navigationURL: response?.register_link?.["#default_value"] ?? "",
    },
    loginBlock: {
      loginTitle: "Sign in with your Gesund account",
      loginDescription: response?.signin_text?.["#text"] ?? null,
      authForm: {
        isPaywallForm: true,
        forgotPassword: {
          text: response?.forgot_password_?.["#title"] ?? null,
          url: "#",
          underline: false,
        },
        submitButton: {
          text: response?.actions?.["#submit__label"] ?? null,
          url: "#",
        },
        fields: [
          {
            type: "email",
            label: response?.username?.["#title"],
            placeholder: response?.username?.["#title"] ?? null,
            required: true,
            helpText: "Email format is not correct",
          },
          {
            type: "password",
            label: response?.password?.["#title"] ?? null,
            placeholder: response?.password?.["#placeholder"] ?? null,
            required: true,
            icon: {
              iconOpen: getAssetPrefixUrl("/assets/icons/view.svg"),
              iconClose: getAssetPrefixUrl("/assets/icons/view_close.svg"),
            },
          },
        ],
      },
    },
  }
}

export default getPaywallData
