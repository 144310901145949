import { ILabelProps } from "./_label.interface"

const Label = (props: ILabelProps) => {
  const { children, className } = props

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label htmlFor="name" className={className}>{children}</label>
  )
}

export default Label
