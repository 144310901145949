import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { AnchorButton, Column, FavoriteIcon, RichText, Row } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IInterestingTeaserCardContentProps } from "./_interestingTeaserCardContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const InterestingTeaserCardContent = ({
  id,
  heading,
  text,
  link,
  isLinkExternal,
  buttonLabel
}: IInterestingTeaserCardContentProps) => {

  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  let totalFavoriteCount = 0
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const applicationConfigContext = useContext(ApplicationContext)


  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => Number(f.node_id) === id)

  useMemo(() => {

    for (const iterator in userSpecificFav) {

      if (Number(userSpecificFav[iterator].node_id) === id) {

        setIsFavouriteSelected(true)

      }

    }

  }, [userSpecificFav])

  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  const favouriteHandler = (state: any) => {

    updateRatings({
      id,
      state
    })

  }

  return (
    <div className="interesting-teaser-card-content">
      <Row>
        <Column
          desktop={9}
          tablet={9}
          mobile={9}
        >
          {heading && (
            <h4 className="interesting-teaser-card-content-header bold">
              {trimText(heading, CHARLIMIT.TITLE)}
            </h4>
          )}
        </Column>
        <Column
          desktop={3}
          tablet={3}
          mobile={3}
          className="interesting-teaser-card-content-icon"
        >
          <span>
            {(heading || link || text) && applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
              <FavoriteIcon
                id={id}
                totalFavoriteCount={totalFavoriteCount}
                favouriteStatus={isFavouriteSelected}
                favoriteHandlerCallback={favouriteHandler}
              />
            )}
          </span>
        </Column>
      </Row>
      {text && (
        <RichText
          className="interesting-teaser-card-content-text"
          content={text}
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      )}
      {/* Not in use, use getAPi count to get count of favorite */}
      {/* <div>
        {numberOfFavorite && (
          <div className="interesting-teaser-card-content-favorite-section">
            <span>
              <FavouriteIconfilled />
            </span>
            <span>{numberOfFavorite}</span>
          </div>
        )}
      </div> */}
      {link && (
        <div className="interesting-teaser-card-content-button">
          <AnchorButton
            tabindex={2}
            isSecondary
            href={link}
            isExternal={isLinkExternal}
          >
            {buttonLabel}
          </AnchorButton>
        </div>
      )}
    </div>
  )

}

export default InterestingTeaserCardContent
