import { PAGE_NID_FILTER, SECTION_LAYOUT, STICKY_STYLE } from "@helpers/constants/generic"
import NUMBERS from "@helpers/constants/numbers"
import { uniqueArray } from "@utils/helper"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."
import { getRegionNumber } from "./getFlexibleTabsData"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import isServer from "@utils/fs/isServer"

const notAllowedBlocks = [
  "accordion",
  "common_tab_group",
  "disclaimer_modal",
  "invited_registration",
  "mega_menu",
  "mega_menu_content",
  "page_not_found",
  "product_details_tabs",
  "download",
  "tabs",
  "flex_accordion",
]
/** calling getAccordionCardData to get flexible content of accordion */
export const fetchTabContent = (layoutBuilder: any) => {
  const allComponents: any = []

  layoutBuilder?.forEach((layout: any) => {
    const column = layout?.layout_id
    const columnWidths = layout?.layout_settings?.column_widths
    const background = Array.isArray(layout?.layout_settings?.layout_builder_styles_style)
      ? layout?.layout_settings?.layout_builder_styles_style?.filter(
          (sty: any) => !STICKY_STYLE?.includes(sty) && sty !== "",
        )?.[0]
      : null
    const stickyStyle =
      Array.isArray(layout?.layout_settings?.layout_builder_styles_style) &&
      layout?.layout_settings?.layout_builder_styles_style
    const components = layout?.components
    const sectionComponents: unknown[] = []

    components?.forEach((component: any) => {
      const type = getNestedObject(component, "configuration.type") || ""
      const uuid = component?.configuration?.uuid
      const url = component?.configuration?.url
      const label = component?.configuration?.label
      const region = component?.region
      const weight = component?.weight

      if (!notAllowedBlocks.includes(type)) {
        sectionComponents.push({
          column: column ?? null,
          background: background ?? null,
          type: type ?? null,
          uuid: uuid ?? null,
          url: url ?? null,
          label: label ?? null,
          region: getRegionNumber(region) ?? null,
          weight: weight ?? NUMBERS.ZERO,
          stickyStyle,
          columnWidths,
        })
      }
    })
    if (column === SECTION_LAYOUT.ONE_COL) {
      allComponents.push(
        sectionComponents?.sort(
          (comp1: any, comp2: any) => (comp1?.weight || 0) - (comp2?.weight || 0),
        ),
      )
    } else {
      allComponents.push(sectionComponents)
    }
  })
  return uniqueArray(allComponents)
}

/** calling getAccordionCardData to get child tab content of accordion */
export const getAccordionCardData = async (url: string) => {
  const accordionCardData = await getBlockData(url)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(accordionCardData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(accordionCardData)
  const tabContent = fetchTabContent(accordionCardData?.data?.attributes?.layout_builder__layout)

  return {
    title: accordionCardData?.data?.attributes?.field_flex_accordion_card_title?.value,
    content: tabContent,
    isParentPaywalled,
    fieldPaywallRoles,
    referrerId: accordionCardData?.data?.attributes?.field_referrer_id,
  }
}
/** calling getAccordionAdvancedData to get parent container of accordion */
const getAccordionAdvancedData = async (url: string) => {
  const accordionAdvancedData = await getBlockData(url)
  let nidFilter: any = null
  if (!isServer()) {
    const nid = sessionStorage?.getItem("nid")
    nidFilter =  nid ? JSON.parse(nid) : null
  }
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(accordionAdvancedData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(accordionAdvancedData)
  const accordionArray =
    accordionAdvancedData?.data?.relationships?.field_flex_accordion_items?.data?.map(
      (data: any) => {
        return {
          cardInitState: data?.meta?.expand,
          cardUrl: `/block_content/flex_accordion_card/${data.id}${nidFilter ? `?${PAGE_NID_FILTER}${nidFilter}` : ""}`,
          cardId: data.id,
        }
      },
    )
  return {
    accordionArray,
    accordionBehavior: accordionAdvancedData?.data?.attributes?.field_flex_configuration,
    accordionExperience: accordionAdvancedData?.data?.attributes?.field_flex_experience,
    referrerId: accordionAdvancedData?.data?.attributes?.field_referrer_id,
    isParentPaywalled,
    fieldPaywallRoles,
  }
}
export default getAccordionAdvancedData
