import { getBlockData, getImageMeta } from "../../../helpers/dataFunctions"

export const getFourOFourData = async (url: string) => {
  const fourOFourPageData = await getBlockData(url)
  const { imageUrl, alt, imageStyledUrl } = getImageMeta(
    fourOFourPageData?.data?.relationships?.field_error_image,
  )
  return {
    title: fourOFourPageData?.data?.attributes?.field_error_title?.value
      ? fourOFourPageData?.data?.attributes?.field_error_title?.value
      : null,
    desc: fourOFourPageData?.data?.attributes?.body?.value
      ? fourOFourPageData?.data?.attributes?.body?.value
      : null,
    ctaText: fourOFourPageData?.data?.attributes?.field_error_primary_button?.title
      ? fourOFourPageData?.data?.attributes?.field_error_primary_button?.title
      : null,
    ctaLink: fourOFourPageData?.data?.attributes?.field_error_primary_button?.uri
      ? fourOFourPageData?.data?.attributes?.field_error_primary_button?.uri
      : null,
    image: imageUrl ? imageUrl : null,
    styledimage: imageStyledUrl ?? null,
    imageAlt: alt ? alt : null,
  }
}
