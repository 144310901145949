import React from "react"
import getDirection from "@utils/direction"
import { IVideoBanner } from "./_videoBanner.interface"
import { SmartImage } from "@molecules"
import VideoBannerContent from "@molecules/videoBannerContent"

const VideoBanner = ({ videoBannerContent, videoBannerImage, internalLayout,reference }: IVideoBanner) => {
  const getClasses = () => {
    const classes = []
    classes.push("videoBanner")
    if (internalLayout) {
      classes.push("internal-layout")
    }
    return classes.join(" ")
  }

  return (
    <div className={getClasses()} dir={getDirection()} ref={reference}> 
      {videoBannerImage && (
        <div className="videoBanner-wrapper">
          <div className="videoBanner-image">
            <SmartImage {...videoBannerImage} />
          </div>
          {videoBannerContent ? <VideoBannerContent {...videoBannerContent} /> : <></>}
        </div>
      )}
    </div>
  )
}
export default VideoBanner
