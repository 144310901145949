import React, { useRef, useState } from "react"
import { useRouter } from "next/router"
import { handleDataLayerNavigationMenu } from "@utils/gtmUtilsHelpers"
import { getDomainLang } from "@utils/helper"
import { Anchor, Column, Container, Icon, Img, Row } from "@atoms"
import MenuImage from "@molecules/menuImage"
import { IMenuImageProps } from "../menuImage/_menuImage.interface"
import {
  IHeaderChildProps,
  IHeaderGrandChildProps,
  IHeaderMenuProps,
  IHeaderSubChildProps,
} from "./_headerMenu.interface"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"

enum MENU_COLUMN {
  FIRST = 1,
  SECOUND = 2,
  THIRD = 3,
}

const HeaderMenu = (props: IHeaderMenuProps) => {
  const parentRef = useRef<any>(null)
  const childMenuRef = useRef<any>([])
  const grandchildMenuRef = useRef<any>([])

  const [height, setHeight] = useState(0)
  const isMobile = useMediaQuery("(max-width: 767px)")
  const router = useRouter()
  const [closeMenu, setCloseMenu] = useState(false)
  const setBlur = (e: any) => {
    const elem = e.target as HTMLElement
    elem.blur()
    elem.parentElement?.blur()
    const body = document.querySelector("body") as HTMLElement
    body.blur()
    body.focus()
    setHeight(0)
  }
  const getRefDetails = (columnNumber: number, index: number) => {
    let ref
    switch (columnNumber) {
      case MENU_COLUMN.FIRST:
        ref = parentRef.current
        break

      case MENU_COLUMN.SECOUND:
        ref =
          index >= childMenuRef?.current?.length
            ? childMenuRef?.current?.[childMenuRef?.current?.length - 1]
            : childMenuRef?.current?.[index]
        break

      case MENU_COLUMN.THIRD:
        ref = grandchildMenuRef?.current?.[index]
        break
    }

    return ref
  }

  const mouseHoverState = (index: number, isHover: boolean, child: any) => {
    if (isHover && (child?.subChild || child?.grandChild)) {
      const firstColumnItems = parentRef?.current?.children?.length
      const SecondColumnItems = getRefDetails(MENU_COLUMN.SECOUND, index)?.children?.length ?? 0
      const thirdColumnItems = getRefDetails(MENU_COLUMN.THIRD, index)?.children?.length ?? 0

      const data = [
        {
          column: 1,
          child: firstColumnItems,
        },
        {
          column: 2,
          child: SecondColumnItems,
        },
        {
          column: 3,
          child: thirdColumnItems,
        },
      ]

      const childs = data.map((object) => object.child)
      const max = Math.max(...childs)
      const object: any = data.find((element) => element.child === max)
      const ref = getRefDetails(object?.column, index)

      const listArray = ref ? [...ref?.childNodes] : []
      const listHeight = listArray.reduce((result: number, item: any) => {
        return result + item.offsetHeight
      }, 160)

      let height = ref?.current?.clientHeight
      height = height < 500 ? 0 : listHeight
      setHeight(height)
    } else {
      setHeight(0)
    }
  }

  const getClasses = () => {
    const classes = []
    classes.push("header-list-item")
    const domailLang = getDomainLang()
    if (domailLang.length > 0) {
      classes.push(domailLang)
    }
    return classes.join(" ")
  }

  return (
    <div
      className={getClasses()}
      onClick={(e) => {
        setCloseMenu(true)
        setBlur(e)
      }}
      onMouseLeave={() => {
        setCloseMenu(false)
        setHeight(0)
      }}>
      <div
        className={
          router.asPath === props.link+"/" && router.pathname !== "/"
            ? (props?.link === "route:<nolink>" ? " header-list-item-underline active non-clickable-item" :"header-list-item-underline active")
            : (props?.link === "route:<nolink>" ? "header-list-item-underline non-clickable-item" :"header-list-item-underline")
        }>
        <Anchor
          className={`${props?.link === "route:<nolink>" ? "head-title non-clickable-item" : "head-title"} ${props?.distinctive? "distinctive" : ""}`}
          href={props.link}
          tabindex={0}
          noUnderline={true}
          target={props?.targetType}
          dataLayerOnClick={() => handleDataLayerNavigationMenu(props.title)}>
            <Img type="icon" image_style_uri={props?.icon_uri} src={props?.icon_uri || ""} alt={props.title} width={20} height={20}/>
            <p className="bold">{props.title}</p>
        </Anchor>

        {!!props?.child && (
          <div
            className={`menuContainer ${
              closeMenu && !isMobile ? "menuContainer-close" : "menuContainer-open"
            }`}>
            <Container>
              <div className="megamenu" style={{ height: height === 0 ? "500" : height }}>
                <ul className="child" ref={parentRef}>
                  {/* <li data-theme-hover={props?.subTheme}>
                  <div className="menu-title">
                    <div className="titleSpace">
                      <Anchor
                        href={props.link}
                        tabindex={0}
                        noUnderline={true}
                        dataLayerOnClick={() => handleDataLayerNavigationMenu(props.title)}
                      >
                        <p className="title bold">
                          {props.title}
                        </p>
                      </Anchor>
                    </div>
                  </div>
                </li> */}
                  {props.child?.map((child: IHeaderChildProps, index: number) => (
                    <li
                      key={index}
                      data-theme-hover={child?.subTheme}
                      onMouseEnter={(e) => mouseHoverState(index, true, child)}
                      onMouseLeave={(e) => mouseHoverState(index, false, child)}>
                      <div className={child?.link == "route:<nolink>" ? "menuSpace non-clickable" : "menuSpace"}>
                        {child.subChild ? (
                          <div className={child?.link == "route:<nolink>" ? "non-clickable" : ""}>
                            <Anchor
                              className={`${child?.distinctive? "distinctive" : ""}`}
                              href={child.link}
                              tabindex={0}
                              noUnderline={true}
                              target={child?.targetType}
                              dataLayerOnClick={() =>
                                handleDataLayerNavigationMenu(props.title, child.title)
                              }>
                              <Img type="icon" image_style_uri={child?.icon_uri} src={child?.icon_uri || ""} alt={props.title} width={24} height={24}/>
                              <p className="title bold">{child.title}</p>
                              <p className="MenuIndicator">
                                <Icon iconName="ChevronRightBlue" />
                              </p>
                            </Anchor>
                          </div>
                        ) : (
                          <div className={child?.link == "route:<nolink>" ? "noLink non-clickable" : "noLink"}>
                            <Anchor
                            className={`${child?.distinctive? "distinctive" : ""}`}
                              href={child.link}
                              tabindex={0}
                              noUnderline={true}
                              target={child?.targetType}
                              dataLayerOnClick={() =>
                                handleDataLayerNavigationMenu(props.title, child.title)
                              }>
                              <Img type="icon" image_style_uri={child?.icon_uri} src={child?.icon_uri || ""} alt={props.title} width={24} height={24}/>
                              <p className="title bold">{child.title}</p>
                            </Anchor>
                          </div>
                        )}
                      </div>

                      {child.subChild && (
                        <ul
                          className="subList"
                          ref={(element) => (childMenuRef.current[index] = element)}>
                          {child.subChild?.map(
                            (subChildItem: IHeaderSubChildProps, _iterator: number) => (
                              <li
                                key={_iterator}
                                data-theme-hover={subChildItem?.subTheme}
                                onMouseEnter={(e) => mouseHoverState(_iterator, true, subChildItem)}
                                onMouseLeave={(e) =>
                                  mouseHoverState(_iterator, false, subChildItem)
                                }>
                                <div className={subChildItem?.link == "route:<nolink>"
                                          ? "menuSpace non-clickable"
                                          : "menuSpace"}>
                                  {subChildItem.grandChild ? (
                                    <div className={subChildItem?.link == "route:<nolink>" ? "non-clickable" : ""}>
                                      <Anchor
                                      className={`${subChildItem?.distinctive? "distinctive" : ""}`}
                                        href={subChildItem.link}
                                        tabindex={0}
                                        noUnderline={true}
                                        target={subChildItem?.targetType}
                                        dataLayerOnClick={() =>
                                          handleDataLayerNavigationMenu(
                                            props.title,
                                            child.title,
                                            subChildItem.title,
                                          )
                                        }>
                                        <Img type="icon" image_style_uri={subChildItem?.icon_uri} src={subChildItem?.icon_uri || ""} alt={subChildItem.title} width={24} height={24}/>
                                        <p className="title">{subChildItem.title}</p>
                                        <p className="MenuIndicator">
                                          <Icon iconName="ChevronRightBlue" />
                                        </p>
                                      </Anchor>
                                    </div>
                                  ) : (
                                    <div className={
                                      subChildItem?.link == "route:<nolink>"
                                        ? "noLink non-clickable"
                                        : "noLink"
                                    }>
                                      <Anchor
                                        className={`${subChildItem?.distinctive? "distinctive" : ""}`}
                                        href={subChildItem.link}
                                        tabindex={0}
                                        noUnderline={true}
                                        target={subChildItem?.targetType}
                                        dataLayerOnClick={() =>
                                          handleDataLayerNavigationMenu(
                                            props.title,
                                            child.title,
                                            subChildItem.title,
                                          )
                                        }>
                                          <Img type="icon" image_style_uri={subChildItem?.icon_uri} src={subChildItem?.icon_uri || ""} alt={subChildItem.title} width={24} height={24}/>
                                        <p className="title">{subChildItem.title}</p>
                                      </Anchor>
                                    </div>
                                  )}
                                </div>

                                {subChildItem.grandChild && (
                                  <ul
                                    className="subList"
                                    ref={(element) =>
                                      (grandchildMenuRef.current[_iterator] = element)
                                    }>
                                    {subChildItem.grandChild?.map(
                                      (grandChildItem: IHeaderGrandChildProps, idx: number) => (
                                        <li key={idx}>
                                          <div className={grandChildItem?.link == "route:<nolink>"
                                          ? "menuSpace non-clickable"
                                          : "menuSpace"}>
                                            <div
                                              className={
                                                grandChildItem?.link == "route:<nolink>"
                                                  ? "noLink non-clickable"
                                                  : "noLink"
                                              }>
                                              <Anchor
                                                className={`${grandChildItem?.distinctive? "distinctive" : " "}`}
                                                href={grandChildItem.link}
                                                tabindex={0}
                                                noUnderline={true}
                                                target={grandChildItem?.targetType}
                                                dataLayerOnClick={() =>
                                                  handleDataLayerNavigationMenu(
                                                    props.title,
                                                    child.title,
                                                    subChildItem.title,
                                                    grandChildItem.title,
                                                  )
                                                }>
                                                <Img type="icon" className="menu_icon"  image_style_uri={grandChildItem?.icon_uri} src={grandChildItem?.icon_uri || ""} alt={grandChildItem.title} width={24} height={24}/>
                                                <p className="title">{grandChildItem.title}</p>
                                              </Anchor>
                                            </div>
                                          </div>
                                        </li>
                                      ),
                                    )}
                                  </ul>
                                )}
                              </li>
                            ),
                          )}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="product-list">
                <Row>
                  <Column mobile={1} tablet={1} desktop={9}>
                    <span />
                  </Column>
                  <Column mobile={1} tablet={1} desktop={3} className="products">
                    {props.menuImageData.map((menuImage: IMenuImageProps, _key: number) => (
                      <MenuImage
                        imageLink={menuImage.imageLink}
                        title={menuImage.title}
                        paragraph={menuImage.paragraph}
                        redirectLink={menuImage.redirectLink}
                        key={_key}
                        alt={menuImage.alt}
                      />
                    ))}
                  </Column>
                </Row>
              </div>
            </Container>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderMenu
