import * as actionType from "../ActionTypes"

const initialProductsInCart: any = {
  webshopFilters: [] as any,
  currentPage: 0,
  searchText: "",
  productsInCart: [],
  productsInCartTemp: [],
}

const WebshopReducer = (state: any = initialProductsInCart, action: WebshopAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_WEBSHOP_FILTERS:
      return {
        ...state,
        webshopFilters: action.webshopFilters,
        currentPage: 0,
      }
    case actionType.UPDATE_WEBSHOP_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case actionType.UPDATE_WEBSHOP_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
        currentPage: 0,
      }
    case actionType.UPDATE_ALL_WEBSHOP_FILTERS:
      return {
        ...state,
        webshopFilters: action.webshopFilters,
        currentPage: action.currentPage,
        searchText: action.searchText,
      }
    case actionType.ADD_PRODUCT_TO_CART:
      return {
        ...state,
        productsInCart: [...state.productsInCart, action.product],
      }
    case actionType.UPDATE_PRODUCT_IN_CART: {
      const newProdList = state.productsInCart.map((p: any) => {
        if (p.productId === action.product.productId) return action.product
        return p
      })
      return {
        ...state,
        productsInCart: newProdList,
      }
    }
    case actionType.DELETE_PRODUCT_FROM_CART: {
      const newProdList = state.productsInCart.filter((p: any) => p.productId !== action.productId)
      return {
        ...state,
        productsInCart: newProdList,
      }
    }
    case actionType.EMPTY_CART: {
      return {
        ...state,
        productsInCart: [],
        productsInCartTemp: [],
      }
    }
    case actionType.ADD_PRODUCT_TO_CART_TEMPORARY: {
      return {
        ...state,
        productsInCartTemp: [...state.productsInCartTemp, action.product],
      }
    }
    case actionType.ADD_PRODUCT_TO_CART_FROM_TEMP: {
      return {
        ...state,
        productsInCart: [...state.productsInCart, ...state.productsInCartTemp],
        productsInCartTemp: [],
      }
    }
    default:
      return state
  }
}

export default WebshopReducer
