import React, { useEffect, useRef, useState } from "react"
import UpdateProfileForm from "@organisms/updateProfileForm"
import ProfileBookmarksCategory from "@molecules/profileBookmarksCategory"
import { useSelector } from "react-redux"
import { ChangePassword, Icon } from "@atoms"
import MyConsent from "@organisms/MyConsent"
import { usePreviousRoute } from "@helpers/hooks/usePreviousRoute"
import { encodeString } from "@utils/sha256"
import { loadGTMPageView } from "@utils/helper"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { IProfileCategories } from "./_profileCategories.interface"
import Tabs from "../../molecules/tabs"
import { TextBlock } from "@molecules"
import GenericWebForm from "@organisms/genericWebForm"
import PROFILE_TAB_TYPES from "@helpers/constants/profile"

const ProfileCategories = ({
  profileContent = [],
  className,
  scrollAmount = 24,
  profileForm,
  labels,
  show_in_card,
}: IProfileCategories) => {
  const { registerFormData } = profileForm
  const user: any = useSelector((state: UserState) => state.user)

  const getClasses = () => {
    const classes = ["profile-categories", className].filter(Boolean)
    return classes.join(" ")
  }

  const tabHeadItems = profileContent.map((item) => ({
    children: `${item.tabName}`,
    itemID: item.tabType,
    tabDesign: "wide",
  }))

  // ********* GTM data Layer *************
  const referrer = usePreviousRoute()
  useEffect(() => {
    const page_location = window.location.href
    const page_referrer = referrer && `${window.location.origin}${referrer}`

    const userdata = user?.user?.response?.session_data?.user_data?.current_user?.name
    const userRole = user?.profile?.response?.profile?.records?.[0]?.SCT_Primary_Specialty__c
    const pageReadyObj = {
      page_location,
      ...(page_referrer && { page_referrer }),
      user_id: userdata ? encodeString(userdata) : "",
      login_status: user?.isLoggedIn,
      roles: userRole,
      area_of_expertise: userRole,
      page_title: document?.title ?? "",
      page_referrer: document?.referrer ?? "",
    } as { [key: string]: string }

    loadGTMPageView().then((isLoaded: boolean) => {
      if (isLoaded) {
        dataLayerPush(EVENTS.PAGE_READY, pageReadyObj)
      }
    })
  }, [document])
  // ******** GTM Layer end ***************

  const tabContents = profileContent.map((item, index) => {
    switch (item.tabType) {
      case labels.bookmarks:
      case PROFILE_TAB_TYPES.BOOKMARKS:
        return <ProfileBookmarksCategory key={index} labels={labels} show_in_card={show_in_card} />
      case PROFILE_TAB_TYPES.FAVOURITES:
        return <></>
      case labels.basicInformation:
        return <UpdateProfileForm key={index} {...registerFormData} />
      case labels.basicFlexibleInformation:
      case PROFILE_TAB_TYPES.CUSTOM_BLOCK:
        return <TextBlock key={index} {...item.content} />
      case labels.password_title:
        return <ChangePassword {...labels?.change_password} />
      case PROFILE_TAB_TYPES.WEBFORM:
        return <GenericWebForm key={index} {...item.content} />
      case PROFILE_TAB_TYPES.CONSENT:
      case labels.consent:
        return <MyConsent />
      default:
        return <div key={index}>{item.tabType}</div>
    }
  })

  const refTabLinks = useRef(null)
  const [showLeftArrow, setShowLeftArrow] = useState(false)
  const [showRightArrow, setShowRightArrow] = useState(false)

  const setArrowsState = () => {
    const tabLinks = refTabLinks.current as any | null
    if (tabLinks) {
      setShowRightArrow(
        // tabLinks.clientWidth + tabLinks.scrollLeft < tabLinks.scrollWidth - scrollAmount / 2,
        false,
      )
      setShowLeftArrow(tabLinks.scrollLeft > 0)
    }
  }

  const scroll = (direction: "left" | "right") => {
    const tabLinks = refTabLinks.current as any | null
    if (tabLinks) {
      tabLinks.scrollLeft += direction === "left" ? -scrollAmount : scrollAmount
      setArrowsState()
    }
  }

  useEffect(() => {
    setArrowsState()
    window.addEventListener("resize", setArrowsState)
  }, [])

  return (
    user &&
    user.isLoggedIn && (
      <div className={getClasses()}>
        {showLeftArrow && (
          <button
            type="button"
            onClick={() => {
              scroll("left")
            }}
            aria-label="Scroll left">
            <Icon iconName="ChevronUp1" />
          </button>
        )}

        <div ref={refTabLinks}>
          <Tabs
            tabHeadItems={tabHeadItems}
            tabContents={tabContents}
            tabHeadClassName="tabHeadClass"
          />
        </div>

        {showRightArrow && (
          <button
            type="button"
            onClick={() => {
              scroll("right")
            }}
            aria-label="Scroll right">
            <Icon iconName="ChevronDown1" />
          </button>
        )}
      </div>
    )
  )
}

export default ProfileCategories
