import React from "react"
import { IDropDownButton } from "./_searchableDropdown.interface"

const Menu = (props: any) => <div className="location-filter-dropdown-menu" {...props} />

const Blanket = (props: any) => (
  <div
    className="location-filter-dropdown-blanket"
    {...props}
  />
)

const DropDownButton = ({ children, isOpen, target, onClose }: IDropDownButton) => (
  <div className="location-filter-dropdown">
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
)

export default DropDownButton
