import React from "react"
import { Anchor, Column, Icon, Img, RichText, AnchorButton } from "@atoms"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import { useMediaQuery } from "@helpers/hooks"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import IconWithLabel from "../../molecules/iconWithLabel"
import { IContactCardProps } from "./_contactCard.interface"
import { URL_TARGET } from "@helpers/constants/generic"

const ContactCard = ({
  contactCardImage,
  title,
  designation,
  paragraph,
  address,
  phone,
  email,
  cardAlignment,
  fax,
  ctaLink,
  ctaTitle,
  ctaTarget,
}: IContactCardProps) => {
  const isMobile = useMediaQuery("(max-width: 991px)")
  return (
    <Column desktop={4} tablet={6} mobile={12} className="contact-card card-strech">
      <div className={"card " + cardAlignment}>
        {contactCardImage && (
          <Img
            styledimage={contactCardImage?.styledimage}
            src={contactCardImage?.src}
            alt={contactCardImage?.alt}
            width={contactCardImage?.width}
            height={contactCardImage?.height}
            islazyloaded={false}
          />
        )}
        <div className="card-body">
          {title && (
            <h3 className={`contact-card-title bold ${isMobile ? "contact-card-header" : ""}`}>
              {trimText(title, CHARLIMIT.TITLE)}
            </h3>
          )}
          {designation && <p>{designation}</p>}
          {paragraph && (
            <RichText
              className="contact-card-text"
              content={paragraph}
              characterLimit={CHARLIMIT.DESCRIPTION}
            />
          )}
        </div>
        <div className="card-footer">
          <div className="contact-card-icon-spacing">
            {address ? (
              <IconWithLabel
                icon={<Icon iconName="homeIcon" width={24} height={24} />}
                cardAlignment={cardAlignment || ""}>
                <Anchor
                  className="contact-card-anchor"
                  href={`tel:${address}`}
                  tabindex={1}
                  isExternal={true}
                  noUnderline={true}>
                  {address}
                </Anchor>
              </IconWithLabel>
            ) : (
              <span className="contact-card-address hidden">-</span>
            )}
          </div>
          <div className="contact-card-icon-spacing">
            {phone ? (
              <IconWithLabel
                icon={<Icon iconName="phoneIcon" width={24} height={24} />}
                cardAlignment={cardAlignment || ""}>
                <Anchor
                  className="contact-card-anchor phone"
                  href={`tel:${phone}`}
                  tabindex={1}
                  isExternal={true}
                  onClick={() => {
                    const trackTelephoneClickEvent = {
                      telephone_number: `${phone}`,
                    }
                    dataLayerPush(EVENTS.TELEPHONE, trackTelephoneClickEvent)
                  }}
                  noUnderline={true}>
                  {phone}
                </Anchor>
              </IconWithLabel>
            ) : (
              <span className="contact-card-phone hidden">-</span>
            )}
          </div>
          <div className="contact-card-icon-align">
            {email ? (
              <IconWithLabel
                icon={<Icon iconName="mailIcon" width={24} height={24} />}
                cardAlignment={cardAlignment || ""}>
                <Anchor
                  className="contact-card-anchor email"
                  href={`mailto:${email}`}
                  tabindex={1}
                  isExternal={true}
                  onClick={() => {
                    const trackEmailClickEvent = {
                      mail_to_address: `${email}`,
                    }
                    dataLayerPush(EVENTS.MAIL_TO_CLICK, trackEmailClickEvent)
                  }}
                  noUnderline={false}>
                  {email}
                </Anchor>
              </IconWithLabel>
            ) : (
              <span className="contact-card-ancho hidden">-</span>
            )}
          </div>
          <div className="contact-card-icon-spacing">
            {fax ? (
              <IconWithLabel
                icon={<Icon iconName="faxIcon" width={24} height={24} />}
                cardAlignment={cardAlignment || ""}>
                <Anchor
                  className="contact-card-anchor fax"
                  href={`fax:${fax}`}
                  tabindex={1}
                  isExternal={true}
                  onClick={() => {
                    const trackFaxClickEvent = {
                      fax: `${fax}`,
                    }
                    dataLayerPush(EVENTS.FAX, trackFaxClickEvent)
                  }}
                  noUnderline={true}>
                  {fax}
                </Anchor>
              </IconWithLabel>
            ) : (
              <span className="contact-card-fax hidden">-</span>
            )}
          </div>
          {ctaLink && ctaTarget && (
            <AnchorButton
              className="contact-card-cta"
              href={ctaLink}
              tabindex={1}
              isExternal={ctaTarget === URL_TARGET.BLANK}>
              {ctaTitle}
            </AnchorButton>
          )}
        </div>
      </div>
    </Column>
  )
}

export default ContactCard
