import React, { useEffect, useState } from "react"
import { SearchGroup, TitleDescription } from "@molecules"
import getMediaLibData from "@helpers/dataFunctions/getMediaLibData"
import MediaLibrarySearchResult from "@organisms/medialIbrarySearchResult"
import { Container } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import Loader from "@atoms/loader"
import { IMediaInterface, IMediaLibraryInterface } from "./_mediaLibrarySearch.interface"
import router from "next/router"

const setDefaultSort = (mediaData: IMediaInterface) => {
  if (mediaData.mediaLibConfig.default_media_sort === mediaData.mediaLibConfig.new_to_old_text) {
    mediaData.allCards.sort((a, b): number => Date.parse(b.changedDate) - Date.parse(a.changedDate))
    return [...mediaData.allCards]
  }

  mediaData.allCards.sort((a, b): number => Date.parse(a.changedDate) - Date.parse(b.changedDate))
  return [...mediaData.allCards]
}

const setDefaultSearchResultSort = (mediaData: IMediaInterface) => {
  mediaData.searchResults.forEach((item) => {
    item.results.sort((a, b): number => Date.parse(b.changedDate) - Date.parse(a.changedDate))
    return item
  })
  return [...mediaData.searchResults]
}

const MediaLibrarySearch = ({ mediaData }: IMediaLibraryInterface) => {
  const [textSearch, setTextSearch] = useState("")
  const [trigger, setTrigger] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [allCardsData, setAllCardsData] = useState(() => setDefaultSort(mediaData))
  const [searchResultsData, setSearchResultsData] = useState(() =>
    setDefaultSearchResultSort(mediaData),
  )
  const queryParams = router?.query?.search?.toString()

  useEffect(() => {
    const fetchData = async () => {
      if (queryParams) {
        setIsLoading(true)
        const searchDataResult = await getMediaLibData(
          `/views/media_library_search/media_library_search?search-media=${queryParams}`,
        )
        setTrigger((value) => value + 1)
        setAllCardsData([...searchDataResult.allCards])
        setSearchResultsData([...searchDataResult.searchResults])
        setTextSearch(queryParams)
        setIsLoading(false)
      }
    }
    fetchData()
  }, [])

  const handleTextSearch = async (text: string, e?: React.MouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    setIsLoading(true)
    const searchDataResult = await getMediaLibData(
      `/views/media_library_search/media_library_search?search-media=${text}`,
    )
    setTrigger((value) => value + 1)
    setAllCardsData([...searchDataResult.allCards])
    setSearchResultsData([...searchDataResult.searchResults])
    setTextSearch(text)
    setIsLoading(false)
  }

  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: mediaData?.mediaLibConfig.search_placeholder_text,
    },
    buttonText: mediaData?.mediaLibConfig.search_cta_text,
    handleTextSearch,
  }

  const handleSort = (data: string) => {
    if (data === mediaData.mediaLibConfig.new_to_old_text) {
      allCardsData.sort((a, b): number => Date.parse(b.changedDate) - Date.parse(a.changedDate))
      mediaData.searchResults.forEach((item) => {
        item.results.sort((a, b): number => Date.parse(b.changedDate) - Date.parse(a.changedDate))
        return item
      })
    } else {
      allCardsData.sort((a, b): number => Date.parse(a.changedDate) - Date.parse(b.changedDate))
      mediaData.searchResults.forEach((item) => {
        item.results.sort((a, b): number => Date.parse(a.changedDate) - Date.parse(b.changedDate))
        return item
      })
    }
    setSearchResultsData([...searchResultsData])
    setAllCardsData([...allCardsData])
    return data
  }

  const formatMatchString = () =>
    mediaData?.mediaLibConfig?.no_results_found_title?.replace("@search_txt", "")
  const getMediaView = () =>
    allCardsData.length > NUMBERS.ZERO ? (
      <MediaLibrarySearchResult
        {...mediaData}
        handleSort={handleSort}
        allCardsData={allCardsData}
        searchResults={searchResultsData}
        searchedPhrase={textSearch}
        trigger={trigger}
      />
    ) : (
      <Container>
        <TitleDescription
          title={formatMatchString()}
          description={mediaData?.mediaLibConfig?.no_results_found_body}
          searchKey={textSearch.toString()}
        />
      </Container>
    )

  return (
    <>
      <SearchGroup
        inputDataContent={searchBarConfiguration.inputDataContent}
        buttonText={searchBarConfiguration.buttonText}
        handleTextSearch={handleTextSearch}
        isDisabled={true}
        isMedia={true}
      />
      {isLoading ? (
        <div className="search-loader">
          <Loader display={isLoading} />
        </div>
      ) : (
        getMediaView()
      )}
    </>
  )
}

export default MediaLibrarySearch
