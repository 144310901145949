import * as actionTypes from "../ActionTypes"

export const addUpdateAdvanceFilter = (advanceFilters: any) => {
    const action: AdvanceFilterAction = {
        type: actionTypes.ADD_UPDATE_FILTERS,
        advanceFilters,
    }
    return performRequest(action)
}
export const updatePagination = (currentPage: number) => {
    const action: AdvanceFilterAction = {
        type: actionTypes.UPDATE_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updateAdvanceSearchText = (searchText: string) => {
    const action: AdvanceFilterAction = {
        type: actionTypes.UPDATE_ADVANCE_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllAdvanceFilters = (advanceFilters: any, currentPage: number, searchText: string, blockType: string) => {
    const action: AdvanceFilterAction = {
        type: actionTypes.UPDATE_ADVANCE_FILTERS,
        advanceFilters,
        currentPage,
        searchText,
        blockType,
    }
    return performRequest(action)
}

const performRequest = (action: AdvanceFilterAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}
