import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { Anchor, AnchorButton, Column, FavoriteIcon, Icon, RichText, Row, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IEducationCardContentProps } from "./_educationCardContent.interface"
import IconWithLabel from "../iconWithLabel"
import BookmarkWithButton from "../bookmarkWithButton"
import ContentType from "../contentType"
import ApplicationContext from "@utils/application-context/applicationContext"

const EducationCardContent = ({
  id,
  taxonomies,
  bookmark,
  heading,
  text,
  contentType,
  time,
  buttonUrl,
  buttonUrlExternal,
  buttonLabel,
}: IEducationCardContentProps) => {
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const applicationConfigContext = useContext(ApplicationContext)

  const handleRatings = (state: any) => {
    // add id details into store
    updateRatings({
      id,
      state,
    })
  }

  let totalFavoriteCount = 0
  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }
  return (
    <div className="education-card-content">
      <Row>
        <Column desktop={8} tablet={8} mobile={8} className="education-card-content-taxonomy">
          {taxonomies && <Taxonomy taxonomies={taxonomies} limitChar={16} />}
        </Column>
        <Column desktop={4} tablet={4} mobile={4} className="education-card-content-icon-container">
          {bookmark && (
            <div className="education-card-content-icon">
              <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={handleRatings} />
            </div>
          )}
          <div className="education-card-content-icon">
            <div className="education-card-content-favorite-section">
              <span>
                {applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                  <FavoriteIcon
                    favouriteStatus={isFavouriteSelected}
                    id={id}
                    totalFavoriteCount={totalFavoriteCount}
                    favoriteHandlerCallback={favouriteHandler}
                  />
                )}
              </span>
            </div>
          </div>
        </Column>
      </Row>

      {buttonUrl ? (
        <Anchor href={buttonUrl} tabindex={0} noUnderline isExternal={buttonUrlExternal}>
          <h4 className="education-card-content-header bold">
            {trimText(heading, CHARLIMIT.TITLE)}
          </h4>
        </Anchor>
      ) : (
        <h4 className="education-card-content-header bold">{trimText(heading, CHARLIMIT.TITLE)}</h4>
      )}

      <RichText
        className="education-card-content-text"
        content={text}
        characterLimit={CHARLIMIT.DESCRIPTION}
      />
      <div>
        {contentType && <ContentType contentType={contentType} />}
        <div className="education-card-content-time-section">
          {time && (
            <IconWithLabel icon={<Icon iconName="TimeIcon" />}>
              <span>{time}</span>
            </IconWithLabel>
          )}
        </div>
      </div>
      <div className="education-card-content-button">
        {buttonUrl && (
          <AnchorButton href={buttonUrl} isExternal={buttonUrlExternal} tabindex={2} isSecondary>
            {buttonLabel}
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

export default EducationCardContent
