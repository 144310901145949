import CryptoJS from "crypto-js"

function encodeString(string: string) {
  return CryptoJS.SHA256(string).toString()
}

function decodeString(encodedString: string) {
  return CryptoJS.SHA256(encodedString).toString()
}

export { encodeString, decodeString }
