import React, { useEffect, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { ICustomItemRendererProps, IDropDownProps, IOption, IOptions } from "./_dropDown.interface"

const CustomItemRenderer = ({ checked, option, onClick, disabled }: ICustomItemRendererProps) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <label className="container">
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className="checkmark" />
    </label>
    <span>{option.label}</span>
  </div>
)
const ProductDropDown = ({
  options,
  isMulti,
  isCleared,
  simpleDropDownLabel,
  placeHolder,
  onValueChange,
  isDisabled,
  intialValue = [],
  clearCatFlag,
  setClearCatFlag,
  catTextMobile,
  ...rest
}: IDropDownProps) => {
  const [selOption, setSelOption] = useState<IOptions>(intialValue)

  useEffect(() => {
    if (clearCatFlag) {
      setSelOption(intialValue)
      setClearCatFlag(false)
    }
  }, [clearCatFlag])

  const changeSelected = (value: any) => {
    if (isMulti) {
      setSelOption(value)
      onValueChange?.(value)
    } else {
      const previoslySelected = [...selOption]
      const newSelected =
        previoslySelected.length > 0
          ? value.filter((item: IOption) => item.value !== previoslySelected[0].value)
          : [...value]

      setSelOption(newSelected)
      onValueChange?.(newSelected)
    }
  }
  const customValueRenderer = (selectedOptions: any[], _options: any[]) => {
    switch (selectedOptions.length) {
      case 0:
        return simpleDropDownLabel ? placeHolder : catTextMobile
      case 1:
        return selectedOptions[0]?.label
      case 2:
        return `${selectedOptions[0]?.label},${selectedOptions[1]?.label}`
      default:
        return (
          <span>
            {`${selectedOptions[0]?.label},${selectedOptions[1]?.label},${selectedOptions[2]?.label}(${selectedOptions.length})`}
          </span>
        )
    }
  }


  useEffect(() => {
    setSelOption([])
  }, [isCleared])

  return (
    <MultiSelect
      options={options}
      value={intialValue}
      onChange={changeSelected}
      labelledBy="Select"
      ItemRenderer={CustomItemRenderer}
      hasSelectAll={false}
      disableSearch={true}
      shouldToggleOnHover={false}
      overrideStrings={{ selectAll: "All" }}
      valueRenderer={customValueRenderer}
      disabled={isDisabled}
      closeOnChangedValue={!isMulti}
      {...rest}
    />
  )
}

export default ProductDropDown
