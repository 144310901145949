import {
 Column, Icon, Row, Video,
} from "@atoms"
import { COLUMN_POSITION } from "@helpers/constants/generic"
import { IMediaPlayerProps } from "./_mediaPlayer.interface"

const MediaPlayer = ({
 position, size, src, title, closeIcon, onClose,
}: IMediaPlayerProps) => {
  return (
    <Row className={`${COLUMN_POSITION[position as keyof typeof COLUMN_POSITION]} media-player`}>
      <Column mobile={12} tablet={size} desktop={size}>
        <div className="media-player-header">
          {title && <span className="media-player-title">{title}</span>}
          {closeIcon && (
            <span className="media-player-close" onClick={onClose} tabIndex={0} onKeyDown={(e)=> {if(e.key == "Enter") {onClose()}}}>
              <Icon iconName="CloseFloatingbanner" />
            </span>
          )}
        </div>
        <Video src={src} />
      </Column>
    </Row>
  )
}

export default MediaPlayer
