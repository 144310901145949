import { Img, RichText } from "@atoms"
import { ITableData } from "./_tableData.interface"

const TableData = (props: ITableData) => {
  const {content, isHeadData, isFroozen, cellAlignment, className, isImageField, imgData} = props

  const getClasses = () => {
    const classes = []
    isHeadData ? classes.push("solar-tbl-head-data") : classes.push("solar-tbl-data")
    classes.push(`solar-tbl-cell-${cellAlignment}`)
    if(cellAlignment) classes.push("global-level-cell")
    if (className) classes.push(className)
    if (isFroozen) classes.push("sticky")
    return classes.join(" ")
  }

  const getCellContent = () => {
    return (
      <>
        {isImageField && (
          <Img
            type="table_enhancement"
            styledimage={imgData?.image_style_uri}
            src={imgData?.src}
            alt={imgData?.alt ?? ""}
            width={268}
            height={150}
          />
        )}
        <RichText content={content} />
      </>
    )
  }

  return (
    <>
    {isHeadData ? 
    <th className={getClasses()}>
      <RichText content={content} />
    </th> : 
    <td className={getClasses()}>
      {getCellContent()}
    </td>}
    </>
    
  )
}

export default TableData
