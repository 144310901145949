import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import {
  EArticleTeaserCompositionType,
  EArticleTeaserContainerDefault,
  EArticleTeaserImagePosition,
} from "@organisms/articleTeaser/_article-teaser.interface"
import getNestedObject from "@utils/nestedObjects"
import { hexalSubThemeConfig } from "@utils/hexalSubThemeUtil"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."

const getArticleTeaserCompositionData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const preFetchedData = args?.[2]
  
  const isDeepLinkURL = url.includes("deeplink_token")
  let articleTeaserCards = preFetchedData && Object.keys(preFetchedData)?.length > 0 ? preFetchedData : await getBlockData(url, false, serverData)

  const isParentPaywalled = isPaywallProtected(
    getNestedObject(articleTeaserCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(articleTeaserCards)
  const subTheme = articleTeaserCards?.included?.filter(
    (items: any) => items.type === "taxonomy_term--microsites",
  )
  articleTeaserCards = articleTeaserCards?.data
  const imagePaywallData = isPaywallProtected(
    articleTeaserCards?.relationships?.field_article_tc_image,
  )

  const subThemeId = getNestedObject(
    articleTeaserCards,
    "relationships.field_sub_theme.data.meta.drupal_internal__target_id",
  )

  const subThemeValue = subTheme?.map((data: any) => {
    if (data?.attributes?.drupal_internal__tid === subThemeId) {
      return data?.attributes?.name
    }
  })

  const { imageUrl, imageStyledUrl, alt, caption, width, height } = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(articleTeaserCards?.relationships?.field_article_tc_image)

  const taxonomies = await getTaxonomy(
    articleTeaserCards?.relationships?.field_article_tc_tag?.links?.related?.href,
    false,
    serverData,
  )

  const mobileImagePaywallData = isPaywallProtected(
    articleTeaserCards?.relationships?.field_article_tc_mobile_image,
  )
  
  const {
    imageUrl: mobileImageUrl,
    imageStyledUrl: mobileImageStyledUrl,
    alt: mobileImageAlt,
    caption: mobileCaption,
    width: mobileWidth,
    height: mobileHeight,
  } = mobileImagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(mobileImagePaywallData?.errorObj)
    : getImageMeta(articleTeaserCards?.relationships?.field_article_tc_mobile_image)

  return {
    articleTeaserContent: {
      taxonomies: taxonomies ?? null,
      heading: articleTeaserCards?.attributes?.field_article_tc_title?.processed ?? null,
      text: articleTeaserCards?.attributes?.body?.processed ?? null,
      buttonLabel: articleTeaserCards?.attributes?.field_article_tc_cta?.title ?? null,
      link: filterURL(articleTeaserCards?.attributes?.field_article_tc_cta?.uri) ?? null,
      linkExternal:
        isExternalLink(
          articleTeaserCards?.attributes?.field_article_tc_cta?.options?.attributes?.target,
        ) ?? null,
    },
    articleTeaserImage: {
      desktopImage: {
        src: imageUrl ?? null,
        styledimage: imageStyledUrl ?? null,
        alt: alt ?? null,
        width: width ?? 358,
        height: height ?? 220
      },
      mobileImage: {
        src: mobileImageUrl ?? null,
        styledimage:  mobileImageStyledUrl ?? null,
        alt: mobileImageAlt ?? null,
        width: mobileWidth ?? 358,
        height: mobileHeight ?? 220
      },
    },
    compositionType: isPaywallProtected(articleTeaserCards?.attributes?.field_composition_dropdown)
      .isPaywallProtectedFlag
      ? null
      : articleTeaserCards?.attributes?.field_composition_dropdown ??
        EArticleTeaserCompositionType.ARTICLE_TEASER,
    imagePosition: isPaywallProtected(articleTeaserCards?.attributes?.field_article_tc_display_type)
      .isPaywallProtectedFlag
      ? null
      : articleTeaserCards?.attributes?.field_article_tc_display_type ??
        EArticleTeaserImagePosition.IMAGE_LEFT,
    containerDefault: isPaywallProtected(articleTeaserCards?.attributes?.field_block_container)
      .isPaywallProtectedFlag
      ? null
      : articleTeaserCards?.attributes?.field_block_container ??
        EArticleTeaserContainerDefault.CONTAINER_NARROW,
    className: articleTeaserCards?.attributes?.field_invert_the_color ? "inverted" : "",
    // @ts-ignore
    subTheme: hexalSubThemeConfig?.[subThemeValue] ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getArticleTeaserCompositionData
