const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  FOURTEEN: 14,
  SIXTEEN: 16,
  FIFTEEN: 15,
  NINETEEN: 19,
  TWENTY_TWO: 22,
  THIRTY: 30,
  HUNDRED: 100,
  FORTY: 40,
  THREEHUNDRED: 300,
  THOUSAND: 1000, // LOGIN INTERVAL FOR EVERY SECOND
  TWO_THOUSAND: 2000, // default rotating hero banner slide duration
  FIVE_THOUSAND: 5000,
  SCROLL_AMOUNT: 144, // default scroll amount for eyebrow menu
  TEN_THOUSAND: 10000, // timeout for toast message
  SIXTY_THOUSAND: 60000, // LOGIN TIMEOUT FALLBACK TIME
  NEWSLETTER_SIGNUP_VALUE: "-9999", // Don't change it
  REFRESH_INTERVAL: 3600000, // Do not change
}

export default NUMBERS
