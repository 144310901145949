import { useState } from "react"
import { Icon, RichText } from "@atoms"
import { v4 } from "uuid"
import { IAccordionProps } from "./_accordion.interface"

const Accordion = (props: IAccordionProps) => {
  const [isActive, setIsActive] = useState(true)
  const accordId = v4()
  return (
    <div className="custom-accordion">
      <div className="accordion" id={props.id ? props.id : `accord-${accordId}`}>
        {props.accordionArray?.map((item: IAccordionProps, index: number) => {
          const uID = v4()
          return (
            <div key={index} id={item?.referrerId} className="accordion-item">
              <div className="accordion-header" id={`accord-heading_${index + 1}_${uID}`}>
                <button
                  className={`accordion-button ${index !== 0 ? "collapsed" : ""}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse${index + 1}_${uID}`}
                  aria-expanded="true"
                  aria-controls={`collapse${index + 1}_${uID}`}
                  onClick={() => {
                    setIsActive(!isActive)
                  }}
                >
                  <h5>
                    {item?.title}
                  </h5>
                  <p className="accordionIcon">
                    <Icon iconName="RightExpandIcon2" />
                  </p>
                </button>
              </div>
              <div
                id={`collapse${index + 1}_${uID}`}
                className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                aria-labelledby={`accord-heading_${index + 1}_${uID}`}
                data-bs-parent={props.id ? `#${props.id}` : `#accord-${accordId}`}
                role="region"
              >
                <div className="accordion-body">
                  {item?.content && <RichText content={item?.content} />}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default Accordion
