export const getScoutEventData = (data: any) => {
  if (!data) return null
  return {
    buttonState: data?.attributes?.event_button?.button_state ?? null,
    register: {
      buttonCta: data?.attributes?.event_button?.register?.button_cta ?? null,
      apiUrl: data?.attributes?.event_button?.register?.api_url ?? null,
    },
    registered: {
      message: data?.attributes?.event_button?.registered?.message ?? null,
    },
    invited: {
      message: data?.attributes?.event_button?.invited?.message ?? null,
      buttonCtaAccept: data?.attributes?.event_button?.invited?.button_cta_accept ?? null,
      buttonCtaReject: data?.attributes?.event_button?.invited?.button_cta_reject ?? null,
      apiUrlAccept: data?.attributes?.event_button?.invited?.api_url_accept ?? null,
      apiUrlReject: data?.attributes?.event_button?.invited?.api_url_reject ?? null,
      attendeeAccId: data?.attributes?.event_button?.invited?.SCT_Attendee_Account__Id ?? null,
    },
    noAccess: {
      buttonCta: data?.attributes?.event_button?.no_access?.button_cta ?? null,
      apiUrl: data?.attributes?.event_button?.no_access?.api_url ?? null,
    },
    eventId: data?.attributes?.field_event_id ?? "",
  }
}
