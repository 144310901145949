import { Button, Icon, Row } from "@atoms"
import { MagazineRotatingTabs } from "@organisms"
import useMagazine from "@helpers/hooks/magazineHooks/useMagazine"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext, useEffect, useMemo, useState } from "react"
import Loader from "@atoms/loader"
import { connect } from "react-redux"
import {
  clearMagazineTempDataSource,
  setMagazineChildTab,
  setMagazineData,
  setMagazineLayout,
  setMagazineParentTab,
  setMagazineTempDataSource,
  updateMagazineTaxonomy,
} from "store/actions/MagazineActionCreators"
import _ from "lodash"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import getMagazineMasonryData from "@helpers/dataFunctions/getMagazineMasonryData"
import { updatePaywall3Roles, updatePaywallContent } from "store/actions/CommonActionCreators"

type IMagazine = {
  url: string
  magazine: any
  user: any
  setMagazineLayout: (layout: Array<string>, initialLoad: number) => void
  updateMagazineTaxonomy: (taxonomy: Array<any>) => void
  updateMagazineDataSource: (magazineData: Array<any>) => void
  updateMagazineTempDataSource: (magazineArray: Array<any>) => void
  clearMagazineTempDataSource: () => void
  setMagazineParentTab: (parentTab: string) => void
  setMagazineChildTab: (childTab: string) => void
}

interface DispatchProps {
  updatePaywallContent: (isPaywall: boolean) => void
  updatePaywall3Roles: (paywallRoles: any) => void
}

type Props = IMagazine & DispatchProps
const MagazineLayout = (props: Props) => {
  const { applicationConfiguration } = useContext(ApplicationContext)

  const [magazineArray, setMagazineArray] = useState<Array<any>>([])
  const [isTabChanged, setIsTabChanged] = useState<boolean>(false)

  const [magTaxonomies, setMagTaxonomies] = useState<any>()
  const [pageIndex, setPageIndex] = useState(0)
  const [isFetchingMagazingData, setIsFetchingMagazingData] = useState<boolean>(false)

  const {
    url,
    magazine,
    user,
    setMagazineLayout,
    updateMagazineDataSource,
    updateMagazineTempDataSource,
    clearMagazineTempDataSource,
    setMagazineParentTab,
    setMagazineChildTab,
    updatePaywallContent,
    updatePaywall3Roles,
  } = props

  const { data, isLoading: isInitDataLoading } = useSWRRequest(url, async () => {
    try {
      return await getMagazineMasonryData(url)
    } catch (error) {
      console.log(error)
    }
  })

  useMemo(() => {
    if(!isInitDataLoading && data.fieldPaywallRoles) {
      const paywallRoles = data?.fieldPaywallRoles ?? []
      if (paywallRoles?.length && !user?.isLoggedIn) {
        updatePaywall3Roles(Array.from(new Set(paywallRoles)))
        updatePaywallContent(true)
      }
    }
  }, [isInitDataLoading, data, user])

  const {
    isLoading,
    isPaginatedLoading,
    magazineDataSource,
    getMagazineTaxonomy,
    getMagazineData,
    updateMagazineWithLayout,
    createMagazineWithLayout,
    formatMagazineTaxonomy,
    totalCount,
    magazineTaxonomy,
  } = useMagazine()

  useEffect(() => {
    if (!isInitDataLoading && data?.masonryId) {
      getMagazineTaxonomy(data.masonryId)
    }
  }, [isInitDataLoading, data])

  const setSelectedById = (data: any, id: string) => {
    data?.forEach((container: any) => {
      container?.links?.forEach((link: any) => {
        link.selected = link.id === id
      })
    })
    return data
  }

  useEffect(() => {
    if (!isInitDataLoading) {
      setMagazineLayout(data.layout, data.initialItemLoadForMagazine)
      if (magazineTaxonomy) {
        const magazineTaxonomies = formatMagazineTaxonomy(magazineTaxonomy)
        let parentTab = ""
        let childTab = ""
        if (magazine.childTab === "" && magazine.parentTab === "" && magazineTaxonomies) {
          parentTab = magazineTaxonomies?.tabHeadItems?.[0]?.id
          childTab = "All"
        } else if (magazine.parentTab === "" && magazine.childTab !== "") {
          parentTab = magazineTaxonomies?.tabHeadItems?.[0]?.id
          childTab = magazine.childTab
        } else if (magazine.parentTab !== "" && magazine.childTab === "") {
          parentTab = magazine.parentTab
          childTab = "All"
        } else {
          parentTab = magazine.parentTab
          childTab = magazine.childTab
        }

        const selectedParentTab = _.findIndex(
          magazineTaxonomies.tabHeadItems,
          (item: any) => {
            return item.id === parentTab
          },
          0,
        )
        magazineTaxonomies.activeItem = { num: selectedParentTab }
        magazineTaxonomies.tabContents = setSelectedById(magazineTaxonomies.tabContents, childTab)
        setMagTaxonomies(magazineTaxonomies)
        fetchMagazineData(pageIndex, parentTab, childTab)
      }
    }
  }, [magazineTaxonomy, magazine.parentTab, magazine.childTab, pageIndex, isInitDataLoading])

  useEffect(() => {
    if (magazineDataSource.length && magazine.layout) {
      const data = updateMagazineWithLayout(magazine.layout, magazineDataSource)

      // save all Magazine array to redux
      updateMagazineTempDataSource(data)

      // save current tab magazine data
      updateMagazineDataSource(data)
    }
  }, [magazineDataSource])

  useMemo(async () => {
    let layoutWithNewMagazineData = []

    if (isTabChanged) {
      clearMagazineTempDataSource()
      updateMagazineTempDataSource(magazine.magazineDataSource)
      layoutWithNewMagazineData = await createMagazineWithLayout(
        magazine.magazineDataSource,
        magazine.layout,
        pageIndex
      )
    } else {
      layoutWithNewMagazineData = await createMagazineWithLayout(
        magazine.magazineTempDataSource,
        magazine.layout,
        pageIndex
      )
    }

    setMagazineArray(layoutWithNewMagazineData)

  }, [magazine.magazineDataSource])

  const fetchMagazineData = (pageNumber: number, parentTab: string, childTab: string) => {
    if (!isFetchingMagazingData) {
      getMagazineData(
        pageNumber,
        parentTab,
        childTab,
        magazine.initialItemToLoad,
        setIsFetchingMagazingData,
      )
    }
  }

  const loadMore = () => {
    const value = pageIndex + 1
    setIsTabChanged(false)
    setPageIndex(value)
  }

  const checkParentTabChange = (title: string) => {
    setMagazineParentTab(title)
    setMagazineChildTab("")
    setPageIndex(0)
    setIsTabChanged(true)
  }

  const checkChildTabChange = (title: string) => {
    setMagazineChildTab(title)
    setPageIndex(0)
    setIsTabChanged(true)
  }

  return (
    <>
      <Row>
        <MagazineRotatingTabs
          tabContent={magTaxonomies}
          setMagazineParentTab={(title: string) => checkParentTabChange(title)}
          setMagazineChildTab={(title: string) => checkChildTabChange(title)}
        />
      </Row>

      {!isLoading && magazineArray.length && magazineTaxonomy ? (
        <>
          {!data?.isParentPaywalled && (
            <div className="mag-container">
              {magazineArray.map((row: any) => row)}

              {!isPaginatedLoading &&
              (pageIndex + 1) * Number(magazine.initialItemToLoad) < totalCount ? (
                <Row className="mag-load-more">
                  <Button
                    className="mag-load-more-btn"
                    type="button"
                    isSecondary
                    icon={<Icon iconName="RefreshIcon" />}
                    iconPosition="right"
                    isDisabled={isPaginatedLoading}
                    onClick={() => loadMore()}
                    tabindex={1}>
                    {`${applicationConfiguration?.siteConfig?.magz_load_more_cta_label}`}
                  </Button>
                </Row>
              ) : (
                <Loader display={isPaginatedLoading} />
              )}
            </div>
          )}
        </>
      ) : (
        <Loader display={isLoading} />
      )}
    </>
  )
}

const mapDispatch = {
  updateMagazineTaxonomy: (taxonomy: Array<any>) => updateMagazineTaxonomy(taxonomy),
  setMagazineLayout: (layout: Array<string>, initialLoad: number) =>
    setMagazineLayout(layout, initialLoad),
  updateMagazineDataSource: (magazineDataWithLayout: Array<any>) =>
    setMagazineData(magazineDataWithLayout),
  setMagazineParentTab: (parentTab: string) => setMagazineParentTab(parentTab),
  setMagazineChildTab: (parentTab: string) => setMagazineChildTab(parentTab),
  updateMagazineTempDataSource: (magazineArray: Array<any>) => setMagazineTempDataSource(magazineArray),
  clearMagazineTempDataSource: () => clearMagazineTempDataSource(),
  updatePaywallContent: (isPaywall: boolean) => updatePaywallContent(isPaywall),
  updatePaywall3Roles: (paywallRoles: any) => updatePaywall3Roles(paywallRoles),
}

const mapState = (state: any) => ({
  magazine: state.magazine,
  user: state.user,
})

// export default MagazineLayout
export default connect(mapState, mapDispatch)(MagazineLayout)
