import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  poDetailFilters: [] as any,
  currentPage: 0,
  searchText: "",
  sort: {
    column: "",
    order: "ASC",
  },
}

const PODetailSearchReducer = (state: any = initialFilterData, action: PODetailFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_PO_DETAIL_FILTERS:
      return {
        ...state,
        poDetailFilters: action.poDetailFilters,
        currentPage: 0,
        sort: {
          column: "",
          order: "ASC",
        },
      }
      case actionType.UPDATE_PO_DETAIL_PAGE_NUMBER:
        return {
          ...state,
          currentPage: action.currentPage,
        }
      case actionType.UPDATE_PO_DETAIl_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          currentPage: 0,
          sort: {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_ALL_PO_DETAIL_FILTERS:
        return {
          ...state,
          poDetailFilters: action.poDetailFilters,
          currentPage: action.currentPage,
          searchText: action.searchText,
          sort: action?.sort || {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_PO_DETAIL_SORT:
      return {
        ...state,
        sort: action.sort,
      }
    default:
      return state
  }
}

export default PODetailSearchReducer