import styled from "styled-components"

  const BackgroundImage = styled.div.attrs({
    className: "section-bg-img",
    })<{ bgimg: string, opacity?: string }>`
    opacity: ${props => props?.opacity || "1" };
    background-image: url(${props => props.bgimg || null });
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: -1;`

export default BackgroundImage