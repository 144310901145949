import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData } from "."
import { IRightSidePanelInfo } from "./_api.interface"

const getPanelInfo = async (listItem: any, rightPanelPath: any[]): Promise<IRightSidePanelInfo> => {
  const link_title = getNestedObject(listItem, "meta.override")
  const link_id = getNestedObject(listItem, "id")
  const link_path = rightPanelPath.find((item: { id: string }) => item.id === link_id)
  const linkPath = getNestedObject(link_path, "attributes.path.alias") ?? ""
  const isChildPaywalled = isPaywallProtected(
    getNestedObject(link_path, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  return {
    referenceTitle: isChildPaywalled ? null : link_title,
    referencePath: isChildPaywalled ? null : linkPath,
  }
}

const getRightSidePanelData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getRightSidePanelData.name}.`)
  const serverData = args?.[3] // accepting serverData only
  const rawData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const rightPanelTitle = getNestedObject(rawData?.data, "attributes.field_rsp_title.processed")
  const rightPanelLinks =
    getNestedObject(rawData?.data, "relationships.field_reference_link.data") ?? []
  const rightPanelPath = rawData?.included ?? []

  const rightSidePanelInfo = await Promise.all(
    rightPanelLinks.map(async (listItem: any) => getPanelInfo(listItem, rightPanelPath)),
  )

  return {
    rightPanelTitle,
    rightSidePanelInfo,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getRightSidePanelData
