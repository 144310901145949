import * as actionTypes from "../ActionTypes"

export const addUpdateInterfaceSearch = (interfaceFilters: any) => {
    const action: InterfaceSearchAction = {
        type: actionTypes.ADD_UPDATE_INTERFACE_SEARCH,
        interfaceFilters,
    }
    return performRequest(action)
}
export const updateInterfacePagination = (currentPage: number) => {
    const action: InterfaceSearchAction = {
        type: actionTypes.UPDATE_INTERFACE_PAGE_NUMBER,
        currentPage,
    }
    return performRequest(action)
}

export const updateInterfaceSearchText = (searchText: string) => {
    const action: InterfaceSearchAction = {
        type: actionTypes.UPDATE_INTERFACE_SEARCH_TEXT,
        searchText,
    }
    return performRequest(action)
}

export const updateAllInterfaceSearch = (interfaceFilters: any, currentPage: number, searchText: string, sort?: any) => {
    const action: InterfaceSearchAction = {
        type: actionTypes.UPDATE_ALL_INTERFACE_SEARCH,
        interfaceFilters,
        currentPage,
        searchText,
        sort,
    }
    return performRequest(action)
}

export const updateInterfaceSort = (sort: any) => {
    const action: InterfaceSearchAction = {
        type: actionTypes.UPDATE_INTERFACE_SORT,
        sort,
    }
    return performRequest(action)
}

const performRequest = (action: InterfaceSearchAction) => {
    return (dispatch: DispatchType) => {
        dispatch(action)
    }
}
