import { memo, useEffect, useMemo, useRef, useState } from "react"
import * as cheerio from "cheerio"
import { IVideoProps } from "./_video.interface"

const Video = (props: IVideoProps) => {
    const { src } = props

    if (!src) return <></>

    const videoRef = useRef<any>(null)
    const [playerURL, setPlayerURL] = useState<string>()
    const [UNIQUE_OBJ_ID, setUNIQUE_OBJ_ID] = useState<string>()
    const [playerConfig, setPlayerConfig] = useState<any>()
    const [playerType, setPlayerType] = useState<any>(false)
    const [playerListId, setPlayerListId] = useState<string>()

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.innerHTML = ""
        }

        const parsedHtml = cheerio.load(src, { xmlMode: false })
        const htmlElement: any = parsedHtml("script")

        if (htmlElement.length === 2) {
            try {
                const player = htmlElement[0].attribs.src
                setPlayerURL(player)

                let videoTypeSrc = player.indexOf("embedIframeJs")
                let playerConfiguration
                if (videoTypeSrc !== -1 && videoTypeSrc !== null) {
                    try {
                        playerConfiguration = JSON.parse((htmlElement[1].children[0].data.split("(")[1]).split(")")[0])
                        setPlayerType(true)
                        setPlayerConfig(playerConfiguration)
                        setUNIQUE_OBJ_ID(playerConfiguration.targetId)
                    } catch (error) {
                        console.error("can not parse player configuration")
                    }
                } else {
                    try {
                        const playerString = (htmlElement[1].children[0].data.split("= ")[1]).split(";")[0]
                        playerConfiguration = JSON.parse(playerString.replace(/,([ |\t|\n]+[\}|\]|\)])/g, "$1"))

                        const playlistString = (htmlElement[1].children[0].data.split("playlistId: \"")[1]).split("\"")[0]

                        setPlayerListId(playlistString)
                        setPlayerType(false)
                        setPlayerConfig(playerConfiguration)
                        setUNIQUE_OBJ_ID(playerConfiguration.targetId)
                    } catch (error) {
                        console.error("can not parse player configuration")
                    }
                }
            } catch (error) {
                console.error("can not parse player configuration")
            }

        }
    }, [src])

    useMemo(() => {
        if (videoRef.current && playerConfig) {
            const videoNode = document.createElement("div")
            videoNode.id = UNIQUE_OBJ_ID as string
            if (!playerType) {
                videoNode.className = "kaltura_video_container"
            }
            videoNode.style.minHeight = "300"
            videoRef.current!.appendChild(videoNode)

            const videoPlayerNode = document.createElement("script")
            videoPlayerNode.src = playerURL as string
            videoPlayerNode.onload = () => {
                if (playerType) {
                    // @ts-ignore
                    kWidget.embed(playerConfig)
                } else {
                    // @ts-ignore
                    var kalturaPlayer = PathKalturaPlayer.setup(playerConfig);
                    kalturaPlayer.loadMedia({
                        playlistId: playerListId
                    });
                }
            }
            videoRef.current!.appendChild(videoPlayerNode)
        }
    }, [playerConfig, videoRef, playerType, playerListId])

    return (
        <div ref={videoRef} />
    )
}

export default memo(Video)