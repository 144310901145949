import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { Column, FavoriteIcon, Icon, RichText, Row, Taxonomy } from "@atoms"
import moment from "moment"
import { IEventCardHorizontalContentProps } from "./_eventCardHorizontalContent.interface"
import BookmarkWithButton from "../bookmarkWithButton"
import { getApiDomainAndLang } from "@utils/baseApi"
import ApplicationContext from "@utils/application-context/applicationContext"

const EventCardHorizontalContent = (props: IEventCardHorizontalContentProps) => {
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const applicationConfigContext = useContext(ApplicationContext)
  const { applicationConfiguration } = useContext(ApplicationContext)

  const id = props?.id
  const bookmarkHandler = (state: any) => {
    // add id details into store
    updateRatings({
      id,
      state,
    })
  }

  let totalFavoriteCount = 0
  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  const extractDate = (date: string) => {
    const { lang } = getApiDomainAndLang()

    return moment(date).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
  }

  return (
    <div className="event-card-horizontal-content">
      <div>
        <Row>
          <Column desktop={6} tablet={6} mobile={6}>
            {props.taxonomies && <Taxonomy taxonomies={props.taxonomies} />}
          </Column>
          <Column desktop={6} tablet={6} mobile={6} className="event-card-horizontal-content-icon">
            <Row>
              <Column
                desktop={4}
                tablet={4}
                mobile={4}
                className="event-card-horizontal-content-bookmark">
                <BookmarkWithButton
                  id={props.id}
                  {...props.bookmark}
                  bookmarkCallback={bookmarkHandler}
                />
              </Column>
              <Column
                desktop={8}
                tablet={8}
                mobile={8}
                className="event-card-horizontal-content-icon">
                <div>
                  <div className="event-card-horizontal-content-favorite-section">
                    <span>
                      {applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                        <FavoriteIcon
                          favouriteStatus={isFavouriteSelected}
                          id={id}
                          totalFavoriteCount={totalFavoriteCount}
                          favoriteHandlerCallback={favouriteHandler}
                        />
                      )}
                    </span>
                    {/* <span className="event-card-horizontal-content-favorite-section-number">{totalFavoriteCount !== 0 && totalFavoriteCount}</span> */}
                  </div>
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
      </div>
      <h4 className="event-card-horizontal-content-header bold">{props.heading}</h4>
      <RichText className="event-card-horizontal-content-text" content={props.text} />
      <div className="event-card-horizontal-content-bottom">
        <div className="event-card-horizontal-content-event-date">
          {extractDate(props.startDate)}
        </div>
        <div className="event-card-horizontal-content-line-divider" />
        <div className="event-card-horizontal-content-bottom-icon">
          <Icon iconName="mapIcon" />
        </div>
        <div>{props.contentText}</div>
      </div>
      <div className="event-card-horizontal-content-time-section">
        <Icon iconName="TimeIcon" />
        <div className="event-card-horizontal-content-time-section-time">{props.time}</div>
      </div>
    </div>
  )
}

export default EventCardHorizontalContent
