import React from "react"
import { AnchorButton, Row, Column, RichText } from "@atoms"
import { IFeatureCardContentProps } from "@molecules/featureCardContent/_featureCardContent.interface"
import FeatureCardContent from "@molecules/featureCardContent"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { IFeatureCardsProps } from "./_featureCards.interface"


const FeatureCards = ({ allFeatureCards, ctaLabel, ctaLink, linkIsExternal }: IFeatureCardsProps) => {
  if(allFeatureCards.length > 1) {
    return (
      <div className="feature-cards-wrapper multi">
        <Row className="feature-cards">
          {allFeatureCards && allFeatureCards?.map((card: IFeatureCardContentProps, _index: number) => (
            <Column desktop={4} tablet={4} mobile={12} key={_index}>
              <FeatureCardContent {...card} />
            </Column>
          ))}
        </Row>
        <Row className="feature-cards-action">
          <Column mobile={12} tablet={12} desktop={12}>
            {ctaLink && ctaLabel && (<AnchorButton isSecondary={true} tabindex={0} href={ctaLink} isExternal={linkIsExternal}>
              {ctaLabel}
            </AnchorButton>)}
          </Column>
        </Row>
      </div>
    )
  } else {
    return (
      <div className="feature-cards-wrapper single">
        <Row className="feature-cards">
          {allFeatureCards && allFeatureCards?.map((card: IFeatureCardContentProps, _index: number) => (
            <Column desktop={12} tablet={12} mobile={12} key={_index}>
              <div className="feature-card-content">
                {card?.title && (<div className="feature-card-title">{trimText(card.title, CHARLIMIT.TITLE)}</div>)}
                {card?.description && (
                  <div className="feature-card-desc">
                    <RichText content={card?.description} characterLimit={CHARLIMIT.DESCRIPTION} />
                    {ctaLink && ctaLabel && (<AnchorButton isSecondary={true} tabindex={0} href={ctaLink} isExternal={linkIsExternal}>
                      {ctaLabel}
                    </AnchorButton>)}
                  </div>
                )}
              </div>
            </Column>
          ))}
        </Row>
      </div>
    )
  }
}

export default FeatureCards
