import { filterURL, getBlockData } from "."
import getNestedObject from "@utils/nestedObjects"
import getGenericWebFormData from "./getGenericWebFormData"

const getPinProtectionData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const pinProtectionData = await getBlockData(url, false, serverData)
  let data: any = []
  data = pinProtectionData?.included || []

  const fieldTitle = pinProtectionData?.data?.attributes?.field_pp_title?.value ?? null
  const fieldSubtitle = pinProtectionData?.data?.attributes?.field_pp_subtitle?.value ?? null
  const sectionTitle = pinProtectionData?.data?.attributes?.field_cta_section_label?.value ?? null

  const webFormData = await Promise.all(
    data.map(async (url: any) => {
      const formUrl =
        getNestedObject(url, "relationships.field_webform.data.webform_restpath") ?? null
      const id =
        getNestedObject(url, "relationships.field_webform.data.meta.drupal_internal__target_id") ??
        null
      const buttonLabels = getNestedObject(url, "attributes.field_cta_label") ?? null
      const redirectionUrl =
        filterURL(getNestedObject(url, "attributes.field_redirection_url.uri")) ?? null
      const webFormContent = formUrl ? await getGenericWebFormData(formUrl) : null

      return {
        buttonLabels,
        id,
        redirectionUrl,
        webFormContent,
      }
    }),
  )

  return {
    fieldTitle,
    fieldSubtitle,
    sectionTitle,
    webFormData,
  }
}

export default getPinProtectionData
