import { Row } from "@atoms"
import { getImageMeta, getTaxonomy } from "@helpers/dataFunctions"
import MagazineRowItem from "@organisms/magazineLayout/magazineRowItem"
import { getApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"
import { prepareMagazineWithLayout } from "@utils/helper"
import { useCallback, useState } from "react"
import { v4 } from "uuid"

const useMagazine = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isPaginatedLoading, setIsPaginatedLoading] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [magazineDataSource, setMagazineDataSource] = useState<Array<any>>([])
  const [magazineTaxonomy, setMagazineTaxonomy] = useState<any>()

  // call taxonomy API
  const getMagazineTaxonomy = useCallback(async (magazineId: number) => {
    try {
      const taxonomyData = await getApi(
        `/restapi/magazine/categories?masonry_uuid=${magazineId}`,
      )

      // taxonomyData?.category_details.sort((a: any, b: any) =>
      //   Number(a.order) > Number(b.order) ? 1 : -1,
      // )
      if (taxonomyData) {
        setMagazineTaxonomy(taxonomyData)
      }
    } catch (error) {
      consoleError(`failed to fetch magazine taxonomy! - ${error}`)
    }
  }, [])

  // call magazine data for layout
  const getMagazineData = useCallback(
    async (
      pageNumber: number,
      parentTab: string,
      childTab: string,
      itemPerPage: number,
      setIsFetchingMagazingData: any,
    ) => {
      try {
        setIsFetchingMagazingData(true)
        pageNumber > 0 ? setIsPaginatedLoading(true) : setIsLoading(true)
        const magazineData = await getApi(
          `/jsonapi/views/magz_listing/magazine?page=${pageNumber}&items_per_page=${itemPerPage}&views-filter[field_magz_tabs_target_id]=${parentTab}&views-filter[field_magz_topics_target_id]=${childTab}`,
        )
        if (magazineData && magazineData?.data) {
          setMagazineDataSource(magazineData.data)
        }
        if (magazineData && magazineData?.meta?.count) {
          setTotalCount(Number(magazineData.meta.count))
        }
        pageNumber > 0 ? setIsPaginatedLoading(false) : setIsLoading(false)
        setIsFetchingMagazingData(false)
      } catch (error) {
        pageNumber > 0 ? setIsPaginatedLoading(false) : setIsLoading(false)
        setIsFetchingMagazingData(false)
        consoleError(`failed to fetch magazine data! - ${error}`)
      }
    },
    [],
  )

  const updateMagazineWithLayout = (layout: Array<string>, dataSource: Array<any>) => {
    let rowData: Array<any> = []
    let row1Data: Array<any> = []
    let row2Data: Array<any> = []
    let row3Data: Array<any> = []
    let magazineDataWithLayout: Array<any>

    try {
      layout.forEach((row: string) => {
        if (row === "1") {
          const startIndex = rowData.length
          row1Data = prepareMagazineWithLayout(startIndex, startIndex + 1, row, dataSource)
          rowData = rowData.concat(row1Data)
        }

        if (row === "2") {
          const startIndex = rowData.length
          row2Data = prepareMagazineWithLayout(startIndex, startIndex + 2, row, dataSource)
          rowData = rowData.concat(row2Data)
        }

        if (row === "3") {
          const startIndex = rowData.length
          row3Data = prepareMagazineWithLayout(startIndex, startIndex + 3, row, dataSource)
          rowData = rowData.concat(row3Data)
        }
      })
    } catch (error) {
      consoleError("Failed to add layout in Magazine data array!")
    } finally {
      magazineDataWithLayout = rowData
    }
    return magazineDataWithLayout
  }

  const createMagazineWithLayout = async (
    magazineData: Array<any>,
    layout: Array<string>,
    pageIndex: number,
  ) => {
    const row1Data: Array<any> = []
    const row2Data: Array<any> = []
    const row3Data: Array<any> = []
    const magazineRowData: Array<any> = []

    if (!magazineData.length) {
      return magazineRowData
    }
    pageIndex > 0 ? setIsLoading(false) : setIsLoading(true)
    setIsPaginatedLoading(true)
    await Promise.all(
      magazineData.map(async (data: any, index: number) => {
        const layout = data?.col?.split(" - ")
        const row = Number(layout[0])

        const { imageUrl, alt, imageStyledUrl } = getImageMeta(
          data?.relationships?.field_magz_image,
        )
        const taxonomies = await getTaxonomy(
          data?.relationships?.field_magz_topics?.links?.related?.href,
        )

        const magImage = {
          src: imageUrl,
          alt: alt || "",
          styledimage: imageStyledUrl ?? null,
        }

        const colData = (
          <MagazineRowItem
            row={row}
            cardData={data}
            cardImage={magImage}
            taxonomies={taxonomies}
            key={index}
          />
        )

        if (row === 1) {
          row1Data.push(colData)
        }
        if (row === 2) {
          row2Data.push(colData)
        }
        if (row === 3) {
          row3Data.push(colData)
        }
        row1Data.sort((a: any, b: any) => Number(a.key) - Number(b.key))
        row2Data.sort((a: any, b: any) => Number(a.key) - Number(b.key))
        row3Data.sort((a: any, b: any) => Number(a.key) - Number(b.key))
      }),
    )
    setIsLoading(false)
    setIsPaginatedLoading(false)
    let layoutBaseOnPage: Array<any> = layout
    if (pageIndex === 0) {
      layoutBaseOnPage = layout
    } else {
      for (let index = 0; index < pageIndex; index++) {
        layoutBaseOnPage = layoutBaseOnPage.concat(layout)
      }
    }

    layoutBaseOnPage.forEach((row: string, key: number) => {
      const uniqueKey = v4()
      if (row === "1") {
        const row1 = (
          <Row className="mag-row row-one" key={`row-${key}-${uniqueKey}`}>
            {row1Data.slice(0, 1).map((d) => (
              <>{d}</>
            ))}
          </Row>
        )
        magazineRowData.push(row1)

        // remove items from array if length is more
        if (row1Data.length >= 0) {
          row1Data.splice(0, 1)
        }
      }

      if (row === "2") {
        const uniqueKey = v4()
        const row2 = (
          <Row className="mag-row row-two" key={`row-${key}-${uniqueKey}`}>
            {row2Data.slice(0, 2).map((d) => (
              <>{d}</>
            ))}
          </Row>
        )
        magazineRowData.push(row2)

        // remove items from array if length is more
        if (row2Data.length >= 1) {
          row2Data.splice(0, 2)
        }
      }

      if (row === "3") {
        const uniqueKey = v4()
        const row3 = (
          <Row className="mag-row row-three" key={`row-${key}-${uniqueKey}`}>
            {row3Data.slice(0, 3).map((d) => (
              <>{d}</>
            ))}
          </Row>
        )
        magazineRowData.push(row3)

        // remove items from array if length is more
        if (row3Data.length >= 2) {
          row3Data.splice(0, 3)
        }
      }
    })

    return magazineRowData
  }

  const formatMagazineTaxonomy = (magazineTaxData: any) => {
    const magTabHead: any = []
    const magTabContent: any = []
    // magazineTaxData?.category_details.sort((a: any, b: any) =>
    //   Number(a.order) > Number(b.order) ? 1 : -1,
    // )
    if (magazineTaxData?.category_details) {
      const allText = magazineTaxData?.sub_category_all_label ?? ""
      magazineTaxData.category_details.forEach((tabHeadItem: any) => {
        magTabHead.push({
          children: tabHeadItem?.label,
          id: tabHeadItem?.tid?.toString(),
          itemID: `pills-${tabHeadItem?.tid}`,
          active: false,
          tabDesign: "pills",
        })
        // tabHeadItem?.sub_category_details.sort((o: any, p: any) =>
        //   Number(o.order) > Number(p.order) ? 1 : -1,
        // )
        if (tabHeadItem?.sub_category_details) {
          const subTaxonomy: any = [
            {
              name: allText,
              id: "All",
              selected: true,
            },
          ]
          tabHeadItem.sub_category_details.forEach((tabContentItem: any) => {
            subTaxonomy.push({
              name: tabContentItem?.label,
              id: tabContentItem.tid,
              selected: false,
            })
          })
          magTabContent.push({ links: subTaxonomy })
        }
      })
    }
    return {
      tabHeadItems: magTabHead,
      tabContents: magTabContent,
      tabContentClassName: "generic-tab",
      tabContentID: "pills-tabContent",
      activeItem: {},
    }
  }

  return {
    isLoading,
    isPaginatedLoading,
    magazineDataSource,
    getMagazineTaxonomy,
    getMagazineData,
    updateMagazineWithLayout,
    createMagazineWithLayout,
    formatMagazineTaxonomy,
    totalCount,
    magazineTaxonomy,
  }
}

export default useMagazine
