import ScrollToTop from "@atoms/scrollToTop"
import Theme from "@atoms/theme"
import NUMBERS from "@helpers/constants/numbers"
import LoginTimeOut from "@organisms/loginTimeout/"
import { getApiDomainAndLang, refreshAccessToken } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"
import { getAssetPrefixUrl } from "@utils/helper"
import { Router, useRouter } from "next/router"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { ReactNode, useCallback, useEffect, useLayoutEffect } from "react"
import { Provider, useDispatch, useSelector, useStore } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { wrapper } from "store/store"
import { setApplicationID } from "store/actions/SessionCreators"
import { Dispatch } from "redux"

type Props = {
  applicationId: string
  theme: string
  children: ReactNode
}

NProgress.configure({ showSpinner: false })

Router.events.on("routeChangeStart", () => NProgress.start())
Router.events.on("routeChangeComplete", () => NProgress.done())
Router.events.on("routeChangeError", () => NProgress.done())

const fetcher = (url: string) => fetch(url).then((res) => res.json())

const Adapter = (props: Props) => {
  const { children, theme, applicationId } = props
  const store = useStore()
  const router = useRouter()

  // update application ID to state
  const appSession = useSelector((state: any) => state.appSession)
  const dispatch: Dispatch<any> = useDispatch()
  const updateApplicationID = useCallback(
    (value: any) => dispatch(setApplicationID(value)),
    [dispatch],
  )

  useLayoutEffect(() => {
    if (applicationId && !appSession?.applicationId) {
      updateApplicationID(applicationId)
    }
  }, [])

  /**
   * Call CMS token API periodically (1hr has been configured)
   * so API will not have 401 status
   */
  let url
  const { feDomain } = getApiDomainAndLang()
  if (!isServer()) {
    const { location } = window
    const isLocalhost = !!location.hostname.includes("localhost")

    // extract CMS path
    const path = feDomain?.includes("/") ? feDomain?.split("/")?.[1] : ""
    const configureUrl = path ? `/api/renew-token/?ccll=${path}` : "/api/renew-token/"

    url = getAssetPrefixUrl(configureUrl, isLocalhost)
  } else {
    url = getAssetPrefixUrl("/api/renew-token/", false)
  }
  useEffect (()=> {
    try {
      // to get token expiry time from session Storage
      let tokenExpiry = NUMBERS.REFRESH_INTERVAL
      if (sessionStorage.getItem("tokenExpiry")) {
        tokenExpiry =
          (JSON.parse(sessionStorage.getItem("tokenExpiry") as string) -
          NUMBERS.THREEHUNDRED) * NUMBERS.THOUSAND
      }
      let tokenRefreshTime = tokenExpiry
      let tokenRefreshTimer
  
      //timeout to refresh the token before the token expiry
      const resetRefreshTimer = (tokenRefreshTime: number) => {
        tokenRefreshTimer = setTimeout(() => {
          try {
            const { feDomain } = getApiDomainAndLang()
            refreshAccessToken(feDomain)
              .then(() => {
                resetRefreshTimer(tokenRefreshTime)
              })
              .catch((error) => {
                console.error("Error refreshing access token:", error)
              })
          } catch (error: any) {
            console.error("Error refreshing access token:", error)
          }
        }, tokenRefreshTime)
      }
      resetRefreshTimer(tokenRefreshTime)
    } catch (error: any) {
      console.error("Error refreshing access token:", error)
    }
  }, [])

  // ******** end ********

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={(store as any).__persistor}>
        <Theme type={theme}>
          {children}
          <ScrollToTop />
          <LoginTimeOut />
        </Theme>
      </PersistGate>
    </Provider>
  )
}

export default wrapper.withRedux(Adapter)
