import * as actionType from "../ActionTypes"

const initialISIBanner: any = {
  isiBannerData: []
}

const ISIBannerReducer = (state: any = initialISIBanner, action: ISIBannerAction): any => {
  switch (action.type) {
    case actionType.ADD_ISI_BANNER:
      return {
        ...state,
        isiBannerData: action.isiBannerData,
      }

    default:
      return state
  }
}

export default ISIBannerReducer
