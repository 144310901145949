import React, {useState} from "react"
import {Anchor, Icon, IconButton} from "@atoms"
import getNestedObject from "@utils/nestedObjects"
import copyToClipboard from "@utils/clipboardActions"
import NUMBERS from "@helpers/constants/numbers"
import {IShareItem, ISocialShareModalContentProps} from "./_socialShareModalContent.interface"

const PLATFORMS = {
  FACEBOOK: {
    URL: "https://www.facebook.com/sharer/sharer.php?u=",
    ICON: <Icon iconName="Facebook" />
  },
  TWITTER: {
    URL: "https://twitter.com/intent/tweet?text=",
    ICON: <Icon iconName="Twitter" />
  },
  EMAIL: {
    URL: "mailto:?subject=Link%20from%20Sandoz&body=",
    ICON: <Icon iconName="mailIcon" />
  },
  LINKEDIN: {
    URL: "https://www.linkedin.com/shareArticle?mini=true&url=",
    ICON: <Icon iconName="LinkedIn" />
  },
  WHATSAPP: {
    URL: "https://api.whatsapp.com/send?text=",
    ICON: <Icon iconName="Whatsapp" />
  },
  "GOOGLE+": {
    URL: "https://plus.google.com/share?url=",
    ICON: <Icon iconName="GooglePlus" />
  },
  PINTEREST: {
    URL: "https://pinterest.com/pin/create/button/?url=",
    ICON: <Icon iconName="Pinterest" />
  }
}

const ShareItem = ({
 urlToShare, platformURL, tabindex, platform, icon
}: IShareItem) => (
    <Anchor
        href={`${platformURL}${urlToShare}`}
        tabindex={tabindex}
        isExternal
        noUnderline
        aria-label={platform}
    >
        <IconButton
            tabindex={tabindex}
            icon={icon}
            ariaLabel={platform}
        />
        <p className="social-share-content__platforms-item__label">
            {platform}
        </p>
    </Anchor>
)

const getShareItem = (urlToShare: string, platform: string, i: number) => {

  const platformName = platform.replaceAll(" ", "").toUpperCase()
  const baseShareUrl = getNestedObject(PLATFORMS, `${platformName}.URL`)
  const platformIcon = getNestedObject(PLATFORMS, `${platformName}.ICON`)
  if (!baseShareUrl || !platformIcon) {

 return null

}
  return (
      <ShareItem
          urlToShare={urlToShare}
          platformURL={baseShareUrl}
          tabindex={i}
          platform={platform}
          icon={platformIcon}
      />
  )

}

const SocialShareModalContent = ({
  sharingPlatforms,
  pageLinkLabel,
  copiedLabel
}: ISocialShareModalContentProps) => {

  const [copied, setCopied] = useState(false)
  const urlToShare = window.location.href

  const copyUrl = async () => {

    await copyToClipboard(urlToShare)
    setCopied(true)
    setTimeout(() => setCopied(false), NUMBERS.TWO_THOUSAND)

}

  return (
      <div className="social-share-content">
          <div className="social-share-content__platforms">
              {sharingPlatforms?.map((platform: any, i: number) => (
                  <div
                      className="social-share-content__platforms-item"
                      key={i}
                  >
                      {getShareItem(urlToShare, platform?.taxonomyLabel, i)}
                  </div>
        ))}
          </div>
          <span className="social-share-content__title">
              {pageLinkLabel}
          </span>
          <div className="copy-link">
              <p>
                  {urlToShare}
              </p>
              <div
                  className="copy-tooltip"
                  onClick={copyUrl}
              >
                  <span className="copy-tooltip-icon">
                      {copied ? <Icon iconName="Copied" /> : <Icon iconName="Copy" />}
                  </span>
                  {copied && <span className="copy-tooltip-text">
                      {copiedLabel}
                  </span>}
              </div>
          </div>
      </div>
  )

}

export default SocialShareModalContent
