import React, {
 MouseEvent, useEffect, useRef, useState,
} from "react"
import { useRouter } from "next/router"
import {
 Anchor, Container, Icon, Img,
} from "@atoms"
import { IStickyMenuProps } from "./_stickyMenu.interface"
import StickyItemMenu from "../../molecules/stickyItemMenu"

import {
  IStickyChildProps,
  IStickyItemMenuProps,
} from "../../molecules/stickyItemMenu/_stickyItemMenu.interface"
import SearchBar from "../searchBar"
import { useOutsideClick } from "../../../helpers/hooks/useOutsideClick"

const StickyMenu = ({
 topSection, megaMenu, topValue, searchData,
}: IStickyMenuProps | any) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const stickyDivRef = useRef(null)

  const router = useRouter()

  useEffect(() => {
    resetMobileMenu()
    setMenuOpen(false)
  }, [router.query])

  const HeaderMobileMenu = () => (
    <div className="header-menu-mobile">
      <button
        className="navbar-toggler collapsed mobile-close-icon"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarHeader"
        aria-controls="navbarHeader"
        aria-expanded="false"
        aria-label="Toggle navigation"
        ref={refMenuButton}
        onClick={() => (clickOutsideMenuDone = false)}
      >
        {!menuOpen && (
          <Icon iconName="HamburgerIcon1" onClick={() => setMenuOpen(true)} className="hamburger-icon" />
        )}
        {menuOpen && <Icon iconName="Close1" onClick={() => setMenuOpen(false)} />}
      </button>
      <span className="navbar-toggler collapsed mobile-close-icon">
        {!isSearchOpen && <Icon iconName="Search1" onClick={() => setIsSearchOpen(true)} className="search-icon" />}
        {isSearchOpen && <Icon iconName="Close1" onClick={() => setIsSearchOpen(false)} />}
        {" "}
      </span>
    </div>
  )
  const AccordionItems = (childItem: IStickyChildProps) => (
    <div className="accordion-item" key={childItem.childKey}>
      <div className="accordion-header" id={`flush-heading_${childItem._key}${childItem.childKey}`}>
        {childItem.subChild ? (
          <>
            <Anchor href={childItem.link} tabindex={0} noUnderline={true}>
              {childItem?.title}
            </Anchor>
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#flush-collapse_${childItem._key}${childItem.childKey}`}
              aria-expanded="false"
              aria-controls={`flush-collapse_${childItem._key}${childItem.childKey}`}
            />
          </>
        ) : (
          <Anchor
            href={childItem.link}
            tabindex={0}
            noUnderline={true}
            className=" collapsed menu-link"
          >
            {childItem?.title}
          </Anchor>
        )}
      </div>
      <div
        id={`flush-collapse_${childItem._key}${childItem.childKey}`}
        className="accordion-collapse collapse"
        aria-labelledby={`flush-heading_${childItem._key}${childItem.childKey}`}
        data-bs-parent={`#accordionFlush${childItem._key}`}
      >
        <div className="accordion-body subchild">
          <div
            className="accordion accordion-flush menu-child"
            id={`accordionFlush${childItem._key}${childItem.childKey}`}
          >
            <div className="accordion-item">
              <div className="accordion-header remove-for-now">
                <Anchor
                  href={childItem.link}
                  tabindex={0}
                  noUnderline={true}
                  className="accordion-button collapsed menu-link"
                >
                  {childItem?.title}
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const handleTextSearch = (text: string, e?: MouseEvent) => {
    if (e) e.preventDefault()
    if (text.length > 0) {
      if (router.pathname === "/search") {
        router.push({ pathname: "/search", query: { search: `${text}` } }, undefined, {
          shallow: true,
        })
      } else {
        router.push({ pathname: "/search", query: { search: `${text}` } })
      }
    }
  }

  const resetMobileMenu = () => {
    if (refMenuButton.current) {
      refMenuButton.current.className = "navbar-toggler accordion-button collapsed"
    }
    if (refMenu.current) refMenu.current.className = "collapse"
    setMenuOpen(false)
    clickOutsideMenuDone = false
  }
  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: searchData?.placeholder ?? "",
    },
    buttonText: searchData?.buttonText ?? "",
    handleTextSearch,
  }

  let clickOutsideMenuDone = false

  const setMobileMenuState = () => {
    if (clickOutsideMenuDone) {
      resetMobileMenu()
    }
  }

  const handleClickOutsideMenu = () => {
    clickOutsideMenuDone = true
    setMobileMenuState()
  }

  const refMenu = useOutsideClick(handleClickOutsideMenu)

  const handleClickOutsideMenuButton = () => {
    setMobileMenuState()
  }
  const refMenuButton = useOutsideClick(handleClickOutsideMenuButton)

  const handleClickOutsideSearch = () => {
    setIsSearchOpen(false)
  }
  const refSearch = useOutsideClick(handleClickOutsideSearch)

  return (
    <header className="header stickyMenu" ref={stickyDivRef} style={{ top: topValue }}>
      <Container>
        <nav className="navbar navbar-expand-lg">
          <div className="header-parent">
            <div className="sticky-logo">
              <Anchor tabindex={0} href={topSection?.logo?.href}>
                <Img
                  src={topSection?.logo?.src}
                  width={167}
                  height={88}
                  alt={topSection?.logo?.alt}
                  type="icon"
                />
              </Anchor>
            </div>
            <div className="header-menu-section header-menu-desktop">
              <div className="navbar-nav">
                {megaMenu?.map((menuItem: IStickyItemMenuProps, index: number) => (
                  <StickyItemMenu
                    title={menuItem.title}
                    link={menuItem.link}
                    child={menuItem.child}
                    key={index}
                  />
                ))}
                <div className="header-list-item search">
                  {!isSearchOpen && <Icon iconName="Search1" onClick={() => setIsSearchOpen(true)} />}
                  {isSearchOpen && <Icon iconName="Close1" onClick={() => setIsSearchOpen(false)} />}
                </div>
              </div>
            </div>
            <HeaderMobileMenu />
          </div>
        </nav>
        <div className="collapse" id="navbarHeader" ref={refMenu}>
          <div className="accordion accordionFlush" id="accordionFlushHeaderMobile">
            {megaMenu?.map((menu_item: IStickyItemMenuProps, key: number) => (
              <div className="accordion-item" key={key}>
                <div className="accordion-header" id={`flush-heading_${key}`}>
                  {menu_item.child ? (
                    <>
                      <Anchor tabindex={0} noUnderline={true} href={menu_item.link}>
                        {menu_item?.title}
                      </Anchor>
                      <button
                        className="accordion-button collapsed "
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapse_${key}`}
                        aria-expanded="false"
                        aria-controls={`flush-collapse_${key}`}
                      />
                    </>
                  ) : (
                    <Anchor
                      tabindex={0}
                      noUnderline={true}
                      href={menu_item.link}
                      className=" collapsed menu-link"
                    >
                      {menu_item?.title}
                    </Anchor>
                  )}
                </div>
                <div
                  id={`flush-collapse_${key}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading_${key}`}
                  data-bs-parent="#accordionFlushHeaderMobile"
                >
                  <div className="accordion-body child">
                    <div
                      className="accordion accordion-flush menu-child"
                      id={`accordionFlush${key}`}
                    >
                      <div className="accordion-item">
                        <div className="accordion-header remove-for-now">
                          <Anchor
                            tabindex={0}
                            noUnderline={true}
                            href={menu_item.link}
                            className="accordion-button collapsed menu-link menu-head"
                          >
                            {menu_item?.title}
                          </Anchor>
                        </div>
                      </div>
                      {menu_item.child?.map((Item: IStickyChildProps, index: number) => (
                        <AccordionItems key={index} {...Item} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Container>
      {isSearchOpen && (
        <div ref={refSearch}>
          <SearchBar
            isTypeAhead={true}
            searchContent={searchBarConfiguration}
            className="header-modal"
          />
        </div>
      )}
    </header>
  )
}
export default StickyMenu
