export const hexalSubThemeConfig = {
    Cardiovascular: "sub-theme-one",
  "Schmerz/Reuma/Osteoporose/Pain": "sub-theme-two",
  Endocrinology: "sub-theme-three",
  "Stomach/Intestine": "sub-theme-four",
  "Respiratory/Allergy": "sub-theme-five",
  "Nephrology/Urology": "sub-theme-six",
  Gynecology: "sub-theme-seven",
  "HIV/Antiinfectives": "sub-theme-eight",
  Diabetes: "sub-theme-nine",
  Dermatology: "sub-theme-ten",
  Oncology: "sub-theme-eleven",
  "Autoimmune/Chronic Inflammatory Diseases": "sub-theme-twelve",
  "ZNS/Allergies/Addiction Medicine": "sub-theme-thirteen",
  "Multiple Sclerosis": "sub-theme-fourteen",
  "Thyroid Guide": "sub-theme-fifteen",
  "Theme 5": "sub-theme-sixteen",
  "Theme 12": "sub-theme-seventeen",
  "Tyruko Europe": "tyruko-europe",
}
