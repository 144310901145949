import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { useCallback, useContext } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { AnchorButton, Column, Icon, RichText, Row, Taxonomy } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import { IDetailBlockContentProps } from "./_detailBlockContent.interface"
import BookmarkWithButton from "../bookmarkWithButton"
import ApplicationContext from "@utils/application-context/applicationContext"
import moment from "moment"
import { getApiDomainAndLang } from "@utils/baseApi"

const DetailBlockContent = (props: IDetailBlockContentProps) => {
  const { nodeId, bookMarkData } = props
  const isMobile = useMediaQuery("(max-width: 767px)")
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  const handleRatings = (state: any) => {
    // add id details into store
    if (nodeId) {
      updateRatings({
        id: nodeId,
        state,
      })
    } else {
      console.log("Node id is not found!")
    }
  }

  return (
    <div className="defaultCardContent">
      <Row>
        <Column desktop={6} tablet={6} mobile={12}>
          <>
            {props.taxonomies && (
              <div className="defaultCardContent-taxonomy">
                <Taxonomy taxonomies={props.taxonomies} className="detail-block--taxonomy" />
              </div>
            )}
            {isMobile && (
              <div className="defaultCardContent-bookmark mobile-only">
                {bookMarkData && (
                  <BookmarkWithButton
                    id={nodeId}
                    bookmarkStatus={bookMarkData.bookmarkStatus}
                    isButton={false}
                    addText=""
                    removeText=""
                    bookmarkCallback={handleRatings}
                  />
                )}
              </div>
            )}
          </>
        </Column>
        <>
          {!isMobile && (
            <Column desktop={6} tablet={6} mobile={6}>
              <div className="defaultCardContent-bookmark">
                {bookMarkData && (
                  <BookmarkWithButton
                    id={nodeId}
                    bookmarkStatus={bookMarkData.bookmarkStatus}
                    isButton={false}
                    addText=""
                    removeText=""
                    bookmarkCallback={handleRatings}
                  />
                )}
              </div>
            </Column>
          )}
        </>
      </Row>
      {props.heading && (
        <div className="head-label">
          <RichText className="title" content={props.heading} />
        </div>
      )}

      {props.date && <h3 className="date">{moment(props.date).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}</h3>}

      {props.content && (
        <RichText className="content" content={props.content} characterLimit={500} />
      )}

      {props.buttonUrl && (
        <AnchorButton
          isExternal={props.isButtonUrlExternal ?? false}
          href={props.buttonUrl}
          tabindex={1}
          isSecondary
          icon={<Icon iconName="DownloadIcon" />}>
          {props.buttonLabel}
        </AnchorButton>
      )}
    </div>
  )
}
export default DetailBlockContent
