import { useContext, useEffect, useState } from "react"
// import Img from "@atoms/img"
import ApplicationContext from "@utils/application-context/applicationContext"
import Icon from "@atoms/icon"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { IScrollToTopProps } from "./scrollToTop.interface"

const ScrollToTop = ({ className }: IScrollToTopProps) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { pageTheme } = useContext(ApplicationThemeContext)
  const isIconRightAligned = applicationConfiguration?.isRightPositionEnabled
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset
      setIsVisible(scrollTop > 10)
    }
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const getClasses = () => {
    const classes = ["scroll-to-top"]
    if (className) classes.push(className)
    isIconRightAligned ? classes.push("right") : classes.push("left")
    return classes.join(" ")
  }

  const getScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <div id="scrollToTop" data-theme-color={pageTheme}>
      {isVisible && (
        <div className={getClasses()}>
          <button className="icon" onClick={getScrollToTop}>
            {/* <Img
              src="/assets/icons/chevron-double-scrolled.svg"
              alt="tooltip"
              height={48}
              width={48}
              type="icon"
              className="round"
            /> */}
            <Icon iconName="chevronDouble" alt="tooltip" height={48} width={48} className="round" />
          </button>

          <div className="scroll-to-top-tooltip">
            <span className="scroll-to-top-tooltip-text">
              {applicationConfiguration?.tooltipText}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

export default ScrollToTop
