import NUMBERS from "@helpers/constants/numbers"
import getNestedObject from "@utils/nestedObjects"
import getConfigDisplayData from "./getConfigDisplayData"

const renderMediaLibraryConfig = async (serverData?: any) => {
  let rawMediaData = await getConfigDisplayData(serverData)
  rawMediaData = rawMediaData?.data
  if (rawMediaData) {
    return {
      show_view_cta: getNestedObject(rawMediaData, "media_view_cta_toggle") === NUMBERS.ONE,
      show_sort_cta: getNestedObject(rawMediaData, "media_sort_cta_toggle") === NUMBERS.ONE,
      list_view_text: getNestedObject(rawMediaData, "list_view_text"),
      grid_view_text: getNestedObject(rawMediaData, "grid_view_text"),
      default_view: getNestedObject(rawMediaData, "default_view"),
      sort_cta_text: getNestedObject(rawMediaData, "sort_cta_text"),
      new_to_old_text: getNestedObject(rawMediaData, "new_to_old_text"),
      old_to_new_text: getNestedObject(rawMediaData, "old_to_new_text"),
      default_media_sort: getNestedObject(rawMediaData, "default_media_sort"),
      search_cta_text: getNestedObject(rawMediaData, "search_cta_text"),
      search_placeholder_text: getNestedObject(rawMediaData, "search_placeholder_text"),
      search_results_found: getNestedObject(rawMediaData, "search_results_found"),
      no_results_found_title: getNestedObject(rawMediaData, "no_results_found_title"),
      no_results_found_body: getNestedObject(rawMediaData, "no_results_found_body.value"),
      all_category_text: getNestedObject(rawMediaData, "all_category_text"),
      download_text_cta: getNestedObject(rawMediaData, "download_text_cta"),
      read_more_text_cta: getNestedObject(rawMediaData, "read_more_text_cta"),
      related_items_title: getNestedObject(rawMediaData, "media_related_items_title"),
    }
  }
  return {
    show_view_cta: false,
    show_sort_cta: false,
    list_view_text: "",
    grid_view_text: "",
    default_view: "",
    sort_cta_text: "",
    new_to_old_text: "",
    old_to_new_text: "",
    default_media_sort: "",
    search_cta_text: "",
    search_placeholder_text: "",
    search_results_found: "",
    no_results_found_title: "",
    no_results_found_body: "",
    all_category_text: "",
    download_text_cta: "",
    read_more_text_cta: "",
    related_items_title: "",
  }
}

export default renderMediaLibraryConfig
