import { InputBoxProps } from "./_inputBox.interface"

const InputBox = (props: InputBoxProps) => {
  const getClasses = () => {
    const classes = ["inputBox", props.className].filter(Boolean)
    return classes.join(" ")
  }
  return <input {...props} className={getClasses()} />
}
export default InputBox
