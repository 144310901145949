import {Container, RichText} from "@atoms"
import {IReferenceTextProps} from "./_referenceText.interface"

const ReferenceText = ({ title, description }: IReferenceTextProps) => {

  return (
      <Container>
        <div className="reference-text">
            {title && <h3 className="bold">
                {title}
            </h3>}
            {description && <RichText content={description} />}
        </div>
      </Container>
    )

}

export default ReferenceText
