import { Anchor, AnchorButton, Column, RichText, Row, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { INewsStoriesContentProps } from "./_newsStoriesCardContent.interface"
import BookmarkWithButton from "../bookmarkWithButton"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"
import moment from "moment"
import { getApiDomainAndLang } from "@utils/baseApi"
import NUMBERS from "@helpers/constants/numbers"

type BookmarkCallback = {
  id: number
  bookmarkCallback: (state: boolean) => void
}

type Props = BookmarkCallback & INewsStoriesContentProps

const NewsStoriesCardContent = ({
  id,
  taxonomies,
  bookmark,
  heading,
  date,
  text,
  buttonUrl,
  isButtonUrlExternal,
  buttonLabel,
  bookmarkCallback,
}: Props) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  return (
    <div className="news-card-content">
      <Row>
        <Column desktop={10} tablet={10} mobile={10}>
          {taxonomies && <Taxonomy taxonomies={taxonomies} limitChar={NUMBERS.TWELVE} />}
        </Column>
        <Column desktop={2} tablet={2} mobile={2} className="news-card-content-icon">
          {bookmark && (
            <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={bookmarkCallback} />
          )}
        </Column>
      </Row>
      {heading &&
        (!buttonUrl? (
          <h4 className="news-card-content-header bold">{heading}</h4>
        ) : (
          <Anchor href={buttonUrl} tabindex={0} noUnderline isExternal={isButtonUrlExternal}>
            <h4 className="news-card-content-header bold">{heading}</h4>
          </Anchor>
        ))}
      <div className="news-card-content-time-section">
        {date && (
          <span>
            {moment(date).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}
          </span>
        )}
      </div>
      {text && (
        <RichText
          content={text}
          className="news-card-content-text"
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      )}

      <div className="news-card-content-button">
        {buttonUrl && buttonLabel && (
          <AnchorButton tabindex={2} isSecondary href={buttonUrl} isExternal={isButtonUrlExternal}>
            {buttonLabel}
          </AnchorButton>
        )}
      </div>
    </div>
  )
}

export default NewsStoriesCardContent
