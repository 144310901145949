const ICON_CARD_CONSTANTS = {
    GRID: "grid",
    PARAGRAPH: "paragraph",
    MINILIST: "minilist",
    LEFT: "left",
    ABOVE: "above",
    ONE_COLUMN: "one_column",
    TWO_COLUMN: "two_column",
    THREE_COLUMN: "three_column",
   }

export default ICON_CARD_CONSTANTS