import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."
import getFormattedISIData from "./getISIData"

enum BANNER_TYPE {
  LEGAL_BANNER = "legal_banner",
  DISCLAIMER_BANNER = "disclaimer_banner",
  ISI_BANNER = "isi_banner",
}

enum ISI_BANNER_VERSION {
  ISI_BANNER_1 = "0",
  ISI_BANNER_2 = "1",
}

const getMicroSiteBannerData = async (url: string) => {
  let microSiteBannerData = await getBlockData(url)
  microSiteBannerData = microSiteBannerData?.data

  const bannerType = microSiteBannerData?.attributes?.field_page_banner_type ?? ""
  const isiBannerVersion = microSiteBannerData?.attributes?.field_version_of_isi_banner ?? ""

  if (bannerType === BANNER_TYPE.LEGAL_BANNER) {
    return {
      bannerModuleData: {
        title:
          getNestedObject(microSiteBannerData, "attributes.field_legal_sticky_text.value") || null,
        nonStickyTitle:
          getNestedObject(microSiteBannerData, "attributes.field_legal_nonsticky_text.value") ||
          null,
        expandedCoverageNumber:
          Number(getNestedObject(microSiteBannerData, "attributes.field_legal_screen_ratio")) ||
          null,
        legalBannerMobileCtaText:
          getNestedObject(microSiteBannerData, "attributes.field_legal_mobile_view_more_cta") ??
          null,
        bannerType,
      },
    }
  } else if (bannerType === BANNER_TYPE.DISCLAIMER_BANNER) {
    return {
      bannerModuleData: {
        disclaimerBannerText:
          getNestedObject(microSiteBannerData, "attributes.field_disclaimer_banner_text.value") ??
          null,
        disclaimerBannerScreenOccupancy:
          getNestedObject(microSiteBannerData, "attributes.field_disclaimer_screen_ratio") ?? null,
        bannerType,
      },
    }
  } else if (
    bannerType === BANNER_TYPE.ISI_BANNER &&
    isiBannerVersion === ISI_BANNER_VERSION.ISI_BANNER_1
  ) {
    return {
      bannerModuleData: {
        title: getNestedObject(microSiteBannerData, "attributes.field_isi_banner_title") || null,
        copyrightBottomText:
          getNestedObject(
            microSiteBannerData,
            "attributes.field_isi_banner_copyright_text.value",
          ) || null,
        section1: {
          richText:
            getNestedObject(microSiteBannerData, "attributes.field_isi_banner_indications.value") ||
            null,
        },
        section2: {
          richText:
            getNestedObject(microSiteBannerData, "attributes.field_isi_banner_warning.value") ||
            null,
        },
        section3: {
          richText:
            getNestedObject(
              microSiteBannerData,
              "attributes.field_isi_warning_precautions.value",
            ) || null,
        },
        accordionData: [
          {
            title:
              getNestedObject(microSiteBannerData, "attributes.field_isi_accordion_title") || null,
            content:
              getNestedObject(
                microSiteBannerData,
                "attributes.field_isi_accordion_description.value",
              ) || null,
          },
        ],
        expandedCoverageNumber:
          Number(
            getNestedObject(microSiteBannerData, "attributes.field_isi_banner_max_coverage"),
          ) || null,
        bannerType,
        isiBannerVersion
      },
    }
  } else if (
    bannerType === BANNER_TYPE.ISI_BANNER &&
    isiBannerVersion === ISI_BANNER_VERSION.ISI_BANNER_2
  ) {
    const isiData = getFormattedISIData(microSiteBannerData?.attributes?.isi_banner_details)
    return {
      bannerModuleData: {
        ...isiData,
        bannerType,
        isiBannerVersion
      },
    }
  }
}

export default getMicroSiteBannerData
