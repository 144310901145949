import React from "react"
import { handleDataLayerNavigationFooter } from "@utils/gtmUtilsHelpers"
import { Anchor, Img } from "@atoms"
import { ISocialIconProps } from "./_socialIcon.interface"

function SocialIcon(props: ISocialIconProps) {
  const { socialIconData, followUs } = props

  return (
    <div className="footer-socialIcon">
      {followUs && (
        <div className="footer-socialIcon-follow">
          <span>{followUs}</span>
        </div>
      )}
      <div className="footer-socialIcon-icon">
        {socialIconData?.length ? (
          socialIconData.map((icon, key: number) => (
            <Anchor
              key={key + 2}
              href={icon.link}
              tabindex={0}
              noUnderline={true}
              aria-label={icon.altText}
              isExternal={true}
              dataLayerOnClick={() => handleDataLayerNavigationFooter(icon.title)}>
              <Img alt="logo" src={icon.logoUrl} width={24} height={24} type="icon" />
            </Anchor>
          ))
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default SocialIcon
