import React from "react"
import Icon from "@atoms/icon"
import { IChipsProps } from "./_chips.interface"

const Chips = ({ content, handleClick }: IChipsProps) => (
  <div>
    <div className="chip">
      <div className="chip-content">{content}</div>
      <div aria-hidden="true" className="chip-close" onClick={handleClick}>
        <span className="chip-svg">
          <Icon iconName="Close2" />
        </span>
      </div>
    </div>
  </div>
)

export default Chips
