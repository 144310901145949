import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Icon, RichText } from "@atoms"
import { INotificationProps } from "./_notification.interface"

const Notification = (props: INotificationProps) => {
  const defaultTimer = 10000 // 10 seconds
  const displayMessage = <RichText content={props.displayTxt || ""} />
  const iconSet = {
    success: <Icon iconName="SuccessIcon" />,
    error: <Icon iconName="errorIcon" />,
    warn: <Icon iconName="WarningIcon" />,
    info: true,
    default: false,
  }
  const notificationTypes = {
    success: toast.success,
    error: toast.error,
    warn: toast.warn,
    info: toast.info,
    default: toast,
  }
  const notify = notificationTypes[props.notificationType || "default"]
  const customIcon = iconSet[props.notificationType || "default"]
  notify(displayMessage, { icon: customIcon, toastId: `${notify}`, onClose: () => props.onClose && props.onClose() })
  return (
    <ToastContainer
      position={props.position || "top-right"}
      autoClose={props.autoCloseTimer || defaultTimer}
      hideProgressBar={true}
      closeOnClick={true}
      pauseOnHover={false}
      newestOnTop={false}
      draggable={false}
      rtl={false}
      theme="colored"
      className="flash-container"
      toastClassName="flash-notify"
      bodyClassName="container"
      pauseOnFocusLoss
    />
  )
}

export default Notification
