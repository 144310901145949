import Image from "next/image"

import { getFileTypeExtension, isAnimatedImage } from "@helpers/dataFunctions"
import NUMBERS from "@helpers/constants/numbers"
import CustomImage from "@molecules/customImage"
import { IImageProps } from "./_img.interface"

const Img = (props: IImageProps) => {
  const regex = /\.(jpg|jpeg|JPG|JPEG|png|gif|svg)(\?.*)?$/
  const isValidImage = regex.test(props?.src)
  if (!isValidImage) {
    return <></>
  }

  const absoluteUrl = /^(data|http|https)?:/i
  const isLocal = !absoluteUrl.test(props?.src)
  const isPaywallImg = props?.src?.indexOf("isPaywall") > NUMBERS.ZERO

  const imageLoadhandler = (event: any) => {
    props.onLoad && props.onLoad()
  }

  const customLoader = ({ src, width, quality }: any) => {
    return `${src}?w=${width}&q=${quality || 75}`
  }

  const renderNonStyledImage = (img: IImageProps, isLocalFile: boolean) => {
    // for animation capability
    const imgUrl = isPaywallImg ? img?.src?.split("?")?.[0] : img?.src
    if (
      getFileTypeExtension(imgUrl) === "svg" &&
      !isLocalFile &&
      img?.type !== "icon" &&
      !isPaywallImg
    ) {
      const divStyle = {
        width: "100%",
        height: img?.height || undefined,
        overflow: "hidden",
      }
      return (
        <div style={{ ...divStyle }}>
          <object
            data={img.src}
            type="image/svg+xml"
            style={{ maxInlineSize: "100%" }}
            aria-label={props.alt}>
            <img {...img} />
          </object>
        </div>
      )
    }
    if (getFileTypeExtension(imgUrl) === "svg" && isLocalFile && img?.type === "default") {
      const divStyle = {
        width: "100%",
        height: img?.height || undefined,
        overflow: "hidden",
      }
      return (
        <div style={{ ...divStyle }}>
          <object
            data={img.src}
            type="image/svg+xml"
            style={{ maxInlineSize: "100%" }}
            aria-label={props.alt}>
            <img {...img} />
          </object>
        </div>
      )
    }
    return <Image {...props} loader={customLoader} style={{ maxInlineSize: "100%" }} />
  }

  if (
    (props?.styledimage || props?.image_style_uri) &&
    props?.type !== "icon" &&
    props?.type !== "default" &&
    props?.type !== "image_block" &&
    !isLocal &&
    !isAnimatedImage(props?.src)
  ) {
    if (props.isPimProduct) {
      return (
        <figure>
          <CustomImage
            alt={props?.alt}
            type={props.type}
            height={props?.height}
            width={props?.width}
            resource={props?.styledimage || props?.image_style_uri}
            onLoad={imageLoadhandler}
            islazyloaded={props?.islazyloaded ?? false}
          />
          {props.title && <figcaption className="img_caption">{props.title}</figcaption>}
        </figure>
      )
    }

    if (!props.caption) {
      return (
        <CustomImage
          alt={props?.alt}
          type={props.type}
          height={props?.height}
          width={props?.width}
          resource={props?.styledimage || props?.image_style_uri}
          onLoad={imageLoadhandler}
          islazyloaded={props?.islazyloaded ?? false}
        />
      )
    }
    return (
      <figure>
        <CustomImage
          alt={props?.alt}
          type={props.type}
          height={props?.height}
          width={props?.width}
          resource={props?.styledimage || props?.image_style_uri}
          onLoad={imageLoadhandler}
          islazyloaded={props?.islazyloaded ?? false}
        />
        <figcaption className="img_caption">{props.caption}</figcaption>
      </figure>
    )
  }

  if (!props.caption) {
    if (props.type === "logo") {
      return (
        <Image
          alt={props?.alt}
          layout="fill"
          src={props?.src}
          objectFit="contain"
          loader={customLoader}
        />
      )
    }

    return renderNonStyledImage(props, isLocal)
  }

  return (
    <figure>
      {renderNonStyledImage(props, isLocal)}
      <figcaption className="img_caption">{props.caption}</figcaption>
    </figure>
  )
}

export default Img