import React from "react"
import { ModalBody } from "@atoms"
import { MediaPlayer, ModalPortal } from "@molecules"
import { IVideoPlayerModalProps } from "./_videoPlayerModal.interface"

const VideoPlayerModal = ({ src, titleText, onClose }: IVideoPlayerModalProps) => {
  const modalContent = (
    <div>
      <ModalBody
        content={
          <MediaPlayer
            src={src}
            size={12}
            position="centre"
            title={titleText ?? ""}
            closeIcon={true}
            onClose={onClose}
          />
        }
      />
    </div>
  )

  return <ModalPortal className="video-modal" modalContent={modalContent} wrapperId="react-portal-wrapper" />
}

export default VideoPlayerModal