import { useEffect } from "react"
import { switchTheme } from "../../../utils/campaignBuilder"
import { IThemeSwitcher } from "./_themeSwitcher.interface"

const ThemeSwitcher = (props: IThemeSwitcher) => {
  useEffect(() => {
    if (props.currentTheme) switchTheme(props.currentTheme)
  }, [props.currentTheme])

  return <>{props.children}</>
}

export default ThemeSwitcher
