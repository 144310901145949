import RichText from "@atoms/richText"
import { ISpecificationItem } from "./_specificationitem.interface"

const SpecificationItem = (props: ISpecificationItem) => {
  const { title, description, className = "", full_row_display } = props
  const getClasses = () => {
    const classes =
      full_row_display == 1
        ? ["specification-item productCardListViewContent-padding row-display"]
        : ["specification-item productCardListViewContent-padding"]
    if (className) {
      classes.push(className)
    }
    return classes.join(" ")
  }

  // Function to remove "P" tags from the content
  const removePTag = (inputString: string): string => {
    // Create a temporary element (a div) to hold the HTML content
    const tempElement = document.createElement("div")
    // Set the HTML content of the div with the input string
    tempElement.innerHTML = inputString
    // Remove any existing <p> tags
    const pTags = tempElement.getElementsByTagName("p")
    while (pTags.length > 0) {
      const parent = pTags[0].parentNode as Node
      if (parent) {
        parent.replaceChild(pTags[0].firstChild as Node, pTags[0])
      }
    }
    // Get the cleaned HTML content without <p> tags
    const cleanedHTML: string = tempElement.innerHTML
    return cleanedHTML
  }

  return (
    <>
      {description && description?.trim() && (
        <div className={`${getClasses()}`}>
          {title ? (
            <h5 className="specification-title">{title}</h5>
          ) : (
            <h5 className="specification-title">&nbsp;</h5>
          )}
          <RichText
            className={
              full_row_display == 1 ? "specification-desc full-desc" : "specification-desc"
            }
            content={removePTag(description)}
          />
        </div>
      )}
    </>
  )
}

export default SpecificationItem
