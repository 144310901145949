
import { Container, Icon, NavItem } from "@atoms"
import { ISitemapContent } from "./_sitemapContent.interface"

const SitemapContent = (props: ISitemapContent) => {
  const { sitemapData: data = [] } = props
  const getClasses = () => {
    const classes = []
    classes.push("sitemapContent")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <Container className={getClasses()}>
      <div className="sitemapContent__header">
        <h3>{data.subtitle}</h3>
        <h3>{data.title}</h3>
        <p>{data.description}</p>
      </div>
      <div className="sitemapContent__categories">
        {data.pageHeaders.map((item: any, i: number) => (
          <div key={i} className="sitemapContent__category">
            <div className="sitemapContent__category-header">
              <h4>{item.text}</h4>
              {item.headersContent.length > 0 && <Icon iconName="CollapseUpIcon" />}
            </div>
            <div className="sitemapContent__subpages">
              {item.headersContent.map((subitem: any, j: number) => (
                <NavItem href={subitem.link} key={j} className="sitemapContent__navLink">
                  {subitem.text}
                </NavItem>
              ))}
            </div>
          </div>
        ))}
      </div>
    </Container>
  )
}

export default SitemapContent
