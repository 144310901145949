import { getMediaFile } from "@utils/mediaLibrary"
import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData, getFileDetails, getImageMeta, getTaxonomy } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"
import NUMBERS from "@helpers/constants/numbers"

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)

const getQuantityOps = (
  type: string,
  listStr?: string,
  min?: number,
  max?: number,
  incVal?: number,
) => {
  let opts: any = []
  if (type === "fixed_quantity_values") {
    opts = listStr?.split(",")
  } else if (min && max && incVal) {
    opts = arrayRange(min, max, incVal)
  }
  return opts.map((opt: string) => ({
    label: opt,
    value: opt,
  }))
}

const getWebStoreDetails = async (url: string, nid: number, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getWebStoreDetails.name}.`)
  }
  const serverData = args?.[3] // accepting serverData only
  const hasQueryParams = url.includes("?")
  let { data } = await getBlockData(
    `${url}${hasQueryParams ? "&" : "?"}views-argument[0]=${nid}`,
    false,
    serverData,
  )
  data = data?.[0]
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(data, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(data)
  const imagePaywallData = isPaywallProtected(data?.relationships?.field_upload_images)
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta({ data: data?.relationships?.field_upload_images?.data?.[0] })

  const imgInfo = { src: imageUrl, alt, imageStyledUrl }
  const downloadUUID = getNestedObject(data, "relationships.field_file_upload.data.id")
  const downloadType = getNestedObject(data, "relationships.field_file_upload.data.type")
  const fileType = downloadType?.replace("media--", "")
  const mediaFile = getMediaFile(fileType)
  const { downloadFileLink } = await getFileDetails(downloadUUID, fileType, mediaFile, serverData)

  const taxonomyData = await getTaxonomy(
    getNestedObject(data, "relationships.field_category.links.related.href"),
    false,
    serverData,
  )

  let fieldQuantityType,
    fieldFixedValues,
    fieldMinQuantity,
    fieldMaxQuantity,
    fieldIncrementalValue,
    addToCartEnabled

  if (data?.attributes?.role_level_wsi_details) {
    fieldQuantityType = isPaywallProtected(
      data?.attributes?.role_level_wsi_details?.rl_quantity_type,
    ).isPaywallProtectedFlag
      ? null
      : data?.attributes?.role_level_wsi_details?.rl_quantity_type

    fieldFixedValues = isPaywallProtected(data?.attributes?.role_level_wsi_details.rl_fixed_value)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.role_level_wsi_details?.rl_fixed_value

    fieldMinQuantity = isPaywallProtected(data?.attributes?.role_level_wsi_details?.rl_min_quantity)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.role_level_wsi_details?.rl_min_quantity

    fieldMaxQuantity = isPaywallProtected(data?.attributes?.role_level_wsi_details?.rl_max_quantity)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.role_level_wsi_details?.rl_max_quantity

    fieldIncrementalValue = isPaywallProtected(
      data?.attributes?.role_level_wsi_details?.rl_incremental_value,
    ).isPaywallProtectedFlag
      ? null
      : data?.attributes?.role_level_wsi_details?.rl_incremental_value

    addToCartEnabled = isPaywallProtected(
      getNestedObject(data, "attributes.role_level_wsi_details.rl_enable_quantity_and_add_to_cart"),
    ).isPaywallProtectedFlag
      ? null
      : getNestedObject(
          data,
          "attributes.role_level_wsi_details.rl_enable_quantity_and_add_to_cart",
        )
  } else {
    fieldQuantityType = isPaywallProtected(data?.attributes?.field_quantity_type)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.field_quantity_type

    fieldFixedValues = isPaywallProtected(data?.attributes?.field_fixed_values)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.field_fixed_values

    fieldMinQuantity = isPaywallProtected(data?.attributes?.field_min_quantity)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.field_min_quantity

    fieldMaxQuantity = isPaywallProtected(data?.attributes?.field_max_quantity)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.field_max_quantity

    fieldIncrementalValue = isPaywallProtected(data?.attributes?.field_incremental_value)
      .isPaywallProtectedFlag
      ? null
      : data?.attributes?.field_incremental_value

    addToCartEnabled = isPaywallProtected(
      getNestedObject(data, "attributes.field_enable_quantity_and_add_to"),
    ).isPaywallProtectedFlag
      ? null
      : getNestedObject(data, "attributes.field_enable_quantity_and_add_to")
  }

  return {
    imgInfo,
    content: {
      notificationType: "success",
      notificationText: "The item was added to your shopping cart.",
      subheading: taxonomyData?.[0] ? taxonomyData?.[0]?.taxonomyLabel : "",
      heading: isPaywallProtected(getNestedObject(data, "attributes.title")).isPaywallProtectedFlag
        ? ""
        : getNestedObject(data, "attributes.title") ?? null,
      content: isPaywallProtected(getNestedObject(data, "attributes.body.processed"))
        .isPaywallProtectedFlag
        ? ""
        : getNestedObject(data, "attributes.body.processed") ?? null,
      articleLabel: isPaywallProtected(data?.attributes?.field_title_article_no?.processed)
        .isPaywallProtectedFlag
        ? null
        : data?.attributes?.field_title_article_no?.processed ?? null,
      articleNo: isPaywallProtected(data?.attributes?.field_description_article_no)
        .isPaywallProtectedFlag
        ? null
        : data?.attributes?.field_description_article_no,
      link: "/",
      linkExternal: true,
      buttonLabel: "DownloadLabel",
    },
    productId: data?.id,
    quantityBtnProps: {
      quantityOptions: getQuantityOps(
        fieldQuantityType,
        fieldFixedValues,
        fieldMinQuantity,
        fieldMaxQuantity,
        fieldIncrementalValue,
      ),
    },
    isfieldQuantityAvailable: (getNestedObject(data, "attributes.field_quantity_available") <= NUMBERS.ZERO ) ? true : false,
    fieldQuantityAvailable: addToCartEnabled
      ? isPaywallProtected(getNestedObject(data, "attributes.field_quantity_available"))
          .isPaywallProtectedFlag
        ? null
        : getNestedObject(data, "attributes.field_quantity_available") ?? ""
      : 0,
    downloadUrl: downloadFileLink ?? "#",
    downloadEnabled: isPaywallProtected(getNestedObject(data, "attributes.field_enable_download"))
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(data, "attributes.field_enable_download"),
    addToCartEnabled,
    detailUrl: getNestedObject(data, "attributes.path.alias") ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getWebStoreDetails
