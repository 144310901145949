import { FocusEvent, FormEvent, useEffect, useMemo, useState } from "react"
import ProfilePattern from "@atoms/profilepattern"
import InputBoxWithLabel from "@molecules/inputBoxWithLabel"
import InputBoxPasswordValidation from "@molecules/inputBoxPasswordValidation"
import { Button, CheckBox, Column, Icon, Recaptcha, RichText, Row } from "@atoms"
import { useSelector } from "react-redux"
import useUserProfile from "@helpers/hooks/useUserProfile"
import { getAssetPrefixUrl } from "@utils/helper"
import { middlewarePostAPI } from "@utils/baseApi"
import getNestedObject from "@utils/nestedObjects"
import { config } from "@utils/baseApi/config.external"
import { handleDataLayerError } from "@utils/gtmUtilsHelpers"
import { Errors, IUpdateProfileFormProps, concents } from "./_updateProfileForm.interface"
import { IInputBoxWithLabelProps } from "@molecules/inputBoxWithLabel/_inputBoxWithLabel.interface"
import _ from "lodash"

const UserProfileForm = (props: IUpdateProfileFormProps) => {
  const {
    title,
    topDescription,
    fields,
    submitButton,
    cancelButton,
    changeButton,
    info_box,
    legal_consent,
    recaptchaSiteKey,
    error_box,
  } = props

  enum Action {
    EDIT = "edit",
    VIEW = "view",
  }

  const [formValues, setFormValues] = useState<any>({})
  const [intialValues, setIntialFormValues] = useState<any>({})
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false)
  const [isPicklistUpdated, setIsPicklistUpdated] = useState<boolean>(false)

  const [mode, setMode] = useState<string>(Action.VIEW)

  const user: any = useSelector((state: UserState) => state.user)

  const [errors, setErrors] = useState<Errors>()
  const [isPending, setIsPending] = useState(false)
  const [pendingMessage, setPendingMessage] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isDisableChangeButton, setIsDisableChangeButton] = useState<boolean>(false)
  const { checkForDCR } = useUserProfile()

  useMemo(async () => {
    fields.forEach((f: any) => {
      if (f.type === "dropDown" && f.label === "Profession") {
        f.dropDownOption.options = []
      }
    })
    fields.forEach((f: any) => {
      if (f.type === "dropDown" && f.label === "Speciality") {
        f.dropDownOption.options = []
      }
    })

    const listOfProfession = await middlewarePostAPI(config.onPrem.PROFESSION_DROPDOWN_URL, {
      type: "Type",
    })
    const listOfSpeciality = await middlewarePostAPI(config.onPrem.PROFESSION_DROPDOWN_URL, {
      type: "SCT_Primary_Specialty__c",
    })

    if (!listOfProfession.fail) {
      fields.forEach((f: any) => {
        if (f.type === "dropDown" && f?.dropDownOption?.name?.includes("profession")) {
          const valueArray = (
            (listOfProfession?.response as any).list_data.values as Array<any>
          ).map((v: any, index: number) => {
            return {
              id: index,
              isDisabled: false,
              isSelected: false,
              label: v.label,
              value: v.value,
            }
          })
          f.dropDownOption.options = [...valueArray]
        }
      })
    }

    if (!listOfSpeciality.fail) {
      fields.forEach((f: any) => {
        if (f.type === "dropDown" && f?.dropDownOption?.name?.includes("speciality")) {
          const valueArray = (
            (listOfSpeciality?.response as any).list_data.values as Array<any>
          ).map((v: any, index: number) => {
            return {
              id: index,
              isDisabled: false,
              isSelected: false,
              label: v.label,
              value: v.value,
            }
          })
          f.dropDownOption.options = [...valueArray]
        }
      })

      // Identify to check pincklist data source available or not
      setIsPicklistUpdated(true)
    }
  }, [fields])

  const copyOfFields: IInputBoxWithLabelProps[] = useMemo(() => JSON.parse(JSON.stringify(fields)), [fields, isPicklistUpdated])

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, errorMessage: string) => {
    const fieldName = `${e.target.name}`
    const fieldRequired = e.target.required
    const isCheckBox = e.target.type === "checkbox"
    const fieldValue = isCheckBox && e.target?.checked ? "true" : e.target.value.trim()
    const newErrors: any = { ...errors }

    if (!fieldValue && fieldRequired === true) {
      newErrors[fieldName] = errorMessage
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)

    const field = e.target
    if (fieldValue.length <= 0 && fieldRequired === true) {
      field.classList.add("invalid")
    } else {
      field.classList.remove("invalid")
    }
  }

  const recaptchaOnChangeHandler = (token: string | null) => {
    if (token) {
      setRecaptchaVerified(true)
    }
  }

  const handleSubmit = async (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    e.preventDefault()
    
    const dropdownItems = fields.filter(
      (field) => field.type === "dropDown" && field.inputField.required,
    )
    const newErrors: any = { ...errors }

    dropdownItems.forEach((item) => {
      const fieldName = item?.dropDownOption?.name || ""
      const fieldValue = formValues[fieldName]
      if (!fieldValue) {
        newErrors[fieldName] = item?.validationErrorMessage
        const element = document.getElementById(fieldName)

        if (fieldValue === "") {
          element?.classList.add("invalid")
        } else {
          element?.classList.remove("invalid")
        }
      } else {
        delete newErrors[fieldName]
      }
    })

    legal_consent?.forEach((item: any) => {
      const fieldName = item?.id || ""
      const fieldValue = formValues[fieldName] || ""
      if (!fieldValue && item?.required) {
        newErrors[fieldName] = item?.validationErrorMessage
        const element = document.getElementById(fieldName)
        if (fieldValue === "") {
          element?.classList.add("invalid")
        } else {
          element?.classList.remove("invalid")
        }
      } else {
        delete newErrors[fieldName]
      }
    })

    const captchaElement = document.getElementsByClassName("profile-recaptcha")

    if (!isRecaptchaVerified) {
      captchaElement[0].classList.add("invalid")
    } else {
      captchaElement[0].classList.remove("invalid")
    }

    if (Object.keys(newErrors).length > 0 || isRecaptchaVerified === false) {
      setErrors(newErrors)
      return
    }

    setErrors({})

    const {
      first_name,
      last_name,
      email,
      title : translatedTitle,
      professional_number_hpcsa_or_bapc_p_,
      speciality: translatedSpeciality,
      profession: translatedProfession,
      phone,
      birthdate,
    } = formValues || {}

    const engTranslatedDropdownValues = {
      title: "",
      profession: "",
      speciality: ""
    } as any

    try {
      copyOfFields.map((f) => {
        if (f?.inputField?.name === "title") {
          const value: string = f.dropDownOption?.options?.filter((d) => d?.label === translatedTitle)?.[0]?.value as string
          engTranslatedDropdownValues.title = value
        }

        if (f?.inputField?.name === "profession") {
          const value: string = f.dropDownOption?.options?.filter((d) => d?.label === translatedProfession)?.[0]?.value as string
          engTranslatedDropdownValues.profession = value
        }

        if (f?.inputField?.name === "speciality") {
          const value: string = f.dropDownOption?.options?.filter((d) => d?.label === translatedSpeciality)?.[0]?.value as string
          engTranslatedDropdownValues.speciality = value
        }
      })
    } catch (error) {
      console.error("Falied to transform translated text for Speciality & profession")
    }

    const requestData = {
      email,
      title : engTranslatedDropdownValues.title,
      firstName: first_name,
      lastName: last_name,
      phone: phone || null,
      birthdate: birthdate || null,
      profession: engTranslatedDropdownValues.profession,
      speciality: engTranslatedDropdownValues.speciality,
      professionalNum: professional_number_hpcsa_or_bapc_p_,
      practice: formValues["practice_pharmacy_hospital_established_medical_name"]
    }

    if(formValues.hasOwnProperty("my_profile_basic_information--marketing_consent")){
      const emailConsentObj =  { emailConsent: formValues["my_profile_basic_information--marketing_consent"] === "true" ? 1 : 0 }
      _.merge(requestData, emailConsentObj);
    }
    if(formValues.hasOwnProperty("my_profile_basic_information--confirmation_for_hcp")){
      const termsObj = { terms: formValues["my_profile_basic_information--confirmation_for_hcp"] === "true" ? 1 : 0 }
      _.merge(requestData, termsObj);
    }
    if(formValues.hasOwnProperty("my_profile_basic_information--consent_for_tu_pp")){
      const heatlthCareConfimationObj = { heatlthCareConfimation: formValues["my_profile_basic_information--consent_for_tu_pp"] === "true" ? 1 : 0 }
      _.merge(requestData, heatlthCareConfimationObj);
    }

    try {
      setShowIndicator(true)
      const response = await middlewarePostAPI(config.onPrem.UPDATE_PROFILE_API, requestData)
      if (response.fail) {
        setShowIndicator(false)
        setErrorMessage(error_box || "")
        handleDataLayerError("Profile form submission failed", "Profile Form")
        return
      }

      const errorCode = getNestedObject(response, "response.register_data.error_code") ?? ""
      const results = getNestedObject(response, "response.register_data.results") ?? ""
      if (errorCode) {
        setShowIndicator(false)
        setErrorMessage(error_box || "")
        handleDataLayerError("Profile form submission failed", "Profile Form")
        return
      }

      const responseMessage = `${info_box} ${results?.[0]?.id}`
      setPendingMessage(responseMessage)
      setIsPending(true)
      setMode(Action.VIEW)

      // DCR check
      checkForDCRUpdate()
    } catch (error) {
      console.log(error)
    }
    setShowIndicator(false)
  }

  useEffect(() => {
    if (errors && Object.entries(errors).length !== 0) {
      setErrorMessage(error_box || "")
    } else {
      setErrorMessage("")
    }
  }, [errors])

  const handleChange = (e: React.FocusEvent<HTMLInputElement>) => {
    const isCheckBox = e.target.type === "checkbox"
    const fieldName = `${e.target.name}`
    const fieldValue = isCheckBox && e.target?.checked ? "true" : e.target.value.trim()
    legal_consent?.map((legal: concents) => {
      if (legal.id === fieldName) {
        legal.isChecked = Boolean(fieldValue)
      }
    })
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
  }

  const getDropdownHandler = (value: any, name: string) => {
    const newFormValues: any = { ...formValues }
    newFormValues[name] = value ?? ""
    setFormValues(newFormValues)
  }

  useEffect(() => {
    if (!user || !user.isLoggedIn || !isPicklistUpdated) {
      return
    }

    const userProfileDetails = user.profile?.response?.profile?.records?.[0] ?? {}
    /**
     * get translated value for profession
     */
    fields.map((f) => {
      if (f?.inputField?.name === "title") {
        const translatedSalutation = f.dropDownOption?.options?.filter((d) =>
          d?.value === userProfileDetails?.Salutation ?
            d.isSelected = true :
            d.isSelected = false
        )?.[0]?.label
        f.dropDownOption?.options?.map(d => d.value = d?.label)
        // update the Salutation/title fourcefully
        userProfileDetails.Salutation = translatedSalutation
      }

      if (f?.inputField?.name === "profession") {
        const translatedProfession = f.dropDownOption?.options?.filter((d) =>
          d.value === userProfileDetails?.Type ?
            d.isSelected = true :
            d.isSelected = false
        )?.[0]?.label
        f.dropDownOption?.options?.map(d => d.value = d?.label)
        // update the profession fourcefully
        userProfileDetails.Type = translatedProfession
      }


      if (f?.inputField?.name === "speciality") {
        const translatedSpeciality = f.dropDownOption?.options?.filter((d) =>
          d.value === userProfileDetails?.SCT_Primary_Specialty__c ?
            d.isSelected = true :
            d.isSelected = false
        )?.[0]?.label
        f.dropDownOption?.options?.map(d => d.value = d?.label)

        // update the speciality fourcefully
        userProfileDetails.SCT_Primary_Specialty__c = translatedSpeciality
      }
    })

    const fieldMap = {
      FirstName: "first_name",
      LastName: "last_name",
      PersonEmail: "email",
      Salutation: "title",
      BillingState: "region",
      SCT_National_ID__c: "professional_number_hpcsa_or_bapc_p_",
      SCT_Primary_Specialty__c: "speciality",
      Type: "profession",
      SCT_Primary_Parent__r: "practice_pharmacy_hospital_established_medical_name",
      Phone: "phone",
      PersonBirthdate: "birthdate",
    }

    for (const [field, key] of Object.entries(fieldMap)) {
      const userProfileValue = userProfileDetails[field]
      const userSavedValue =
        typeof userProfileValue === "object" ? userProfileValue?.Name : userProfileValue
      const formValue = userSavedValue ?? ""
      formValues[key] = formValue
    }

    setFormValues(formValues)
    setIntialFormValues(formValues)

    // DCR check
    checkForDCRUpdate()
  }, [user, isPicklistUpdated])

  useMemo(() => {
    if (!user || !user.isLoggedIn) {
      return
    }
    const userProfileDetails = user.profile?.response?.profile ?? {}
    if (userProfileDetails && legal_consent) {
      const newFormValues: any = { ...formValues }
      legal_consent.forEach((legal: any) => {
        if (legal?.id === "my_profile_basic_information--consent_for_tu_pp") {
          legal.isChecked = !!parseInt(userProfileDetails?.terms)
        }
        if (legal?.id === "my_profile_basic_information--confirmation_for_hcp") {
          legal.isChecked = !!parseInt(userProfileDetails?.hcp_confirmation)
        }
        newFormValues[legal?.id] = legal.isChecked
      })
      setFormValues(newFormValues)
    }
  }, [legal_consent, user])

  const checkForDCRUpdate = () => {
    // check for DCR
    const userProfileDetails = user.profile?.response?.profile?.records?.[0] ?? {}

    if (userProfileDetails?.PersonEmail) {
      checkForDCR(userProfileDetails.PersonEmail).then((dcr: any) => {
        if (dcr.status) {
          setMode(Action.VIEW)
          setIsDisableChangeButton(true)

          const responseMessage = `${info_box} ${dcr.dcrId}`
          setPendingMessage(responseMessage)
          setIsPending(true)
        }
      })
    }
  }

  return (
    user &&
    user.isLoggedIn &&
    Object.keys(formValues).length > 0 && (
      <div className="profile-section">
        {showIndicator && overlayElement()}
        <Row>
          <Column mobile={12} tablet={12} desktop={6} className="form-section">
            <form onSubmit={handleSubmit}>
              <div className="profile-description">
                {title && <RichText className="profile-title" content={title || ""} />}
                {topDescription && (
                  <RichText className="top-description" content={topDescription} />
                )}
                {isPending && pendingMessage ? (
                  <div className="profile-pending-msg">
                    <div>
                      <Icon iconName="WarningIcon" />
                    </div>
                    <div>{pendingMessage}</div>
                  </div>
                ) : null}

                {errorMessage ? (
                  <div className="profile-error-msg">
                    <div>
                      <Icon iconName="WarningIcon" />
                    </div>
                    <div>{errorMessage}</div>
                  </div>
                ) : null}
              </div>

              <div className="profile-fileds">
                {fields &&
                  fields.map((field: any, index) =>
                    field.type == "password" ? (
                      <InputBoxPasswordValidation
                        {...field}
                        key={index}
                        name={field.inputField.name}
                        value={formValues[field.inputField.name]}
                        onblurHandler={(e: FocusEvent<HTMLInputElement, Element>) =>
                          handleBlur(e, field.validationErrorMessage)
                        }
                        error={
                          errors &&
                          Object.entries(errors)
                            .map(([key, value]: any): string =>
                              field.inputField.name === key ? value : "",
                            )
                            .join("")
                        }
                      />
                    ) : (
                      field.inputField.name && (
                        <InputBoxWithLabel
                          {...field}
                          key={index}
                          name={field.inputField.name}
                          value={formValues[field.inputField.name]}
                          disabled={mode === Action.VIEW}
                          onChangehandler={handleChange}
                          dropDownShowPlaceholder={true}
                          optionText={fields[index]?.dropDownOption?.optionText || ""}
                          onBlurhandler={(e: FocusEvent<HTMLInputElement, Element>) =>
                            handleBlur(e, field.validationErrorMessage)
                          }
                          error={
                            errors &&
                            Object.entries(errors)
                              .map(([key, value]: any): string =>
                                field.inputField.name === key ? value : "",
                              )
                              .join("")
                          }
                          getDropdownInfo={getDropdownHandler}
                        />
                      )
                    ),
                  )}
              </div>

              {legal_consent && mode === Action.EDIT && (
                <div className="profile-concents">
                  {legal_consent.map((_consent, index) => (
                    _consent?.id && <CheckBox
                      label={<RichText content={_consent.label} />}
                      id={_consent.id}
                      value=""
                      key={index}
                      name={_consent.id}
                      isChecked={_consent.isChecked}
                      onChange={(e: FocusEvent<HTMLInputElement, Element>) => {
                        handleBlur(e, "")
                        handleChange(e)
                      }}
                      error={
                        errors &&
                        Object.entries(errors)
                          .map(([key, value]: any): string => (_consent.id === key ? value : ""))
                          .join("")
                      }
                    />
                  ))}
                </div>
              )}
              {recaptchaSiteKey && mode === Action.EDIT && (
                <div className="profile-recaptcha">
                  <Recaptcha sitekey={recaptchaSiteKey} onChange={recaptchaOnChangeHandler} />
                </div>
              )}

              <div className="profile-submit">
                {mode === Action.VIEW && changeButton && (
                  <Button
                    className="submit-button"
                    type="button"
                    tabindex={2}
                    isDisabled={isDisableChangeButton}
                    onClick={() => setMode(Action.EDIT)}>
                    {changeButton.text}
                  </Button>
                )}

                {mode === Action.EDIT && submitButton && (
                  <Button
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
                    }}
                    className="submit-button"
                    type="submit"
                    tabindex={2}
                    isDisabled={false}>
                    {submitButton.text}
                  </Button>
                )}
                {mode === Action.EDIT && cancelButton && (
                  <Button
                    type="button"
                    tabindex={2}
                    onClick={() => {
                      setMode(Action.VIEW)
                      setFormValues(intialValues)
                      setErrors({})
                    }}>
                    {cancelButton.text}
                  </Button>
                )}
              </div>
            </form>
          </Column>
          <Column mobile={0} tablet={0} desktop={6} className="pattern-img">
            <ProfilePattern />
          </Column>
        </Row>
      </div>
    )
  )
}
export default UserProfileForm
