import React, {useMemo} from "react"
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Colors,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js"
import { Chart } from "react-chartjs-2"
import { IBarLineChart } from "./_barLineChart.interface"
import { _DeepPartialObject } from "chart.js/dist/types/utils"

ChartJS.register(
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Title,
  Colors,
  LinearScale,
  CategoryScale,
)

const BarLineChart  = (props: IBarLineChart) => {
 
const options : _DeepPartialObject<ChartOptions<"bar">> = useMemo(() => {
  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "nearest",
      axis: "y",
      intersect: true,
    },
    stacked: true,
    plugins: {
      title: {
        position: "top",
        display: true,
        text: props.labels.displayLabels?.maintitle || "",
      },
      legend: {
        display: true,
        labels: {
          // color: "rgb(255, 99, 132)",
        },
        position: "bottom",
      },
      colors: {
        forceOverride: true
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: props.labels.displayLabels?.righttitle || "",
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "left" as const,
        title: {
          display: true,
          text: props.labels.displayLabels?.lefttitle || "",
        },
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  }
}, [props.labels])
  return (
    <div className="bar-line-chart-container">
      <Chart id="canvas-id"  options={options} data={props.chartData} type="bar" />
    </div>
  )
}

export default BarLineChart
