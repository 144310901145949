import { RichText } from "@atoms"
import { TitleDescriptionProps } from "./_title-description.interface"

const TitleDescription = (props: TitleDescriptionProps) => {
  const { descriptionFormat = "html" } = props
  const getClasses = () => {
    const classes = ["text-description", props?.className].filter(Boolean)
    return classes.join(" ")
  }
  const descriptionText = descriptionFormat === "string" ? (
    <p className="text-description-description">{props?.description}</p>
    ) : (
      <RichText className="text-description-description" content={props?.description ?? ""} />
    )
  return (
    <div className={getClasses()}>
      <h2 className="text-description-heading bold">
        {props?.title}
        {" "}
        {props?.searchKey && (
          <span className="text-description-search-keyword " dir="ltr">
            {`“${props?.searchKey}”`}
          </span>
        )}
      </h2>
      {props?.description && descriptionText}
    </div>
  )
}
export default TitleDescription
