import React from "react"
import NUMBERS from "@helpers/constants/numbers"
import { IRadioButtonProps } from "./radioButton.interface"

const RadioButton = ({
  required,
  value,
  id,
  label,
  isDisabled,
  isChecked,
  tabIndex = NUMBERS.ZERO,
  ...rest
}: IRadioButtonProps) => {
  return (
    <div className="form-radio">
      <input
        {...rest}
        tabIndex={tabIndex}
        className="form-radio-input"
        type="radio"
        required={required}
        value={value}
        id={id}
        disabled={isDisabled}
        checked={isChecked}
        aria-checked={isChecked}
        aria-labelledby={id}
      />
      <label className="form-radio-label" htmlFor={id}>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
