import Modal from "react-modal"
import { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { Icon } from "@atoms"
import { IPopup } from "./_popup.interface"

const Popup = (props: IPopup) => {
  const {
    showPopup,
    spinnerIcon,
    headerText,
    children,
    handlePopupShow,
    showOverlay,
    className,
  } = props
  const { pageTheme } = useContext(ApplicationThemeContext)

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={spinnerIcon} alt="" />
      </div>
    </div>
  )
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      handlePopupShow?.(false, event)
    }
  }

  const contentElement = () => (
    <Modal
      isOpen={showPopup}
      shouldCloseOnOverlayClick={true}
      contentLabel="Authentication popup"
      className={`popup ${headerText && "popup-content-gap"} ${className || ""}`}
      overlayClassName="popup-overlay"
      ariaHideApp={false}
    >
      <div className={`popup-header ${headerText && "popup-header-titleEnabled"}`} data-theme-color={pageTheme}>
        <h4 className="popup-header-text">{headerText}</h4>
        <div
          className="popup-close-button"
          role="button"
          tabIndex={0}
          onClick={(e) => handlePopupShow?.(false, e)}
          onKeyDown={handleKeyDown}
        >
          <Icon iconName="closeIcon3" className="popup-close-button-img" width={16} height={16} />
        </div>
      </div>
      <div data-theme-color={pageTheme}>
        {children}
      </div>
    </Modal>
  )

  return (
    <>
      {showOverlay && overlayElement()}
      {contentElement()}
    </>
  )
}
export default Popup
