import { ISeperatorProps } from "./_seperator.interface"

const Seperator = (props: ISeperatorProps) => {
  const getClasses = () => {
    const classes = []
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <hr className={getClasses()} />
  )
}

export default Seperator
