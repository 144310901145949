import { Anchor, Column, Img, RichText } from "@atoms"

import { IMenuImageProps } from "./_menuImage.interface"

const MenuImage = (props: IMenuImageProps) => (
  <Column mobile={8} tablet={4} desktop={12}>
    <Anchor href={props.redirectLink} tabindex={0} noUnderline={true}>
      <Img alt={props.alt} src={props.imageLink} width={114} height={113} type="default" />
      <div>
        <span className="title">{props.title}</span>
        <RichText content={props.paragraph} characterLimit={70} className="product-description" />
      </div>
    </Anchor>
  </Column>
)

export default MenuImage
