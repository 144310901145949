import React from "react"
import { Anchor } from "@atoms"
import { handleDataLayerNavigationFooter } from "@utils/gtmUtilsHelpers"
import { IFooterNavigation } from "./_footerNavigation.interface"

const FooterNavigation = (props: IFooterNavigation) => (
  <div className="list-item">
    <h5 className="title bold">{props.title}</h5>
    <ul className="list-unstyled">
      {props.child?.map((ChildItem, index) => (
        <li key={index}>
          <Anchor
            href={ChildItem.link}
            tabindex={0}
            noUnderline={true}
            key={`a=${index}`}
            target={ChildItem?.targetType}
            dataLayerOnClick={() => handleDataLayerNavigationFooter(props.title, ChildItem.title)}>
            <h6>{ChildItem.title}</h6>
          </Anchor>
        </li>
      ))}
    </ul>
  </div>
)

export default FooterNavigation
