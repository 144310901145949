import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  blockType: "",
  advanceFilters: [] as any,
  currentPage: 0,
  advanceSearchText: "",
}

const AdvanceFilterReducer = (state: any = initialFilterData, action: AdvanceFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_FILTERS:
      return {
        ...state,
        advanceFilters: action.advanceFilters,
        currentPage: 0,
      }
    case actionType.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.currentPage,
      }
    case actionType.UPDATE_ADVANCE_SEARCH_TEXT:
      return {
        ...state,
        advanceSearchText: action.searchText,
        currentPage: 0,
      }
    case actionType.UPDATE_ADVANCE_FILTERS:
      return {
        ...state,
        blockType: action.blockType,
        advanceFilters: action.advanceFilters,
        currentPage: action.currentPage,
        advanceSearchText: action.searchText,
      }
    default:
      return state
  }
}

export default AdvanceFilterReducer
