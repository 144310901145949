import * as actionTypes from "../ActionTypes"

export const addUpdateWebshopFilter = (webshopFilters: any) => {
  const action: WebshopAction = {
    type: actionTypes.ADD_UPDATE_WEBSHOP_FILTERS,
    webshopFilters,
  }
  return performRequest(action)
}
export const updateWebshopPagination = (currentPage: number) => {
  const action: WebshopAction = {
    type: actionTypes.UPDATE_WEBSHOP_PAGE_NUMBER,
    currentPage,
  }
  return performRequest(action)
}

export const updateWebshopSearchText = (searchText: string) => {
  const action: WebshopAction = {
    type: actionTypes.UPDATE_WEBSHOP_SEARCH_TEXT,
    searchText,
  }
  return performRequest(action)
}

export const updateAllWebshopFilters = (
  webshopFilters: any,
  currentPage: number,
  searchText: string,
) => {
  const action: WebshopAction = {
    type: actionTypes.UPDATE_ALL_WEBSHOP_FILTERS,
    webshopFilters,
    currentPage,
    searchText,
  }
  return performRequest(action)
}

export const addProductToCart = (product: any, isTemp = false) => {
  const action: WebshopAction = {
    type: isTemp ? actionTypes.ADD_PRODUCT_TO_CART_TEMPORARY : actionTypes.ADD_PRODUCT_TO_CART,
    product,
  }
  return performRequest(action)
}

export const updateProductInCart = (product: any) => {
  const action: WebshopAction = {
    type: actionTypes.UPDATE_PRODUCT_IN_CART,
    product,
  }

  return performRequest(action)
}

export const deleteProductFromCart = (productId: any) => {
  const action: WebshopAction = {
    type: actionTypes.DELETE_PRODUCT_FROM_CART,
    productId,
  }

  return performRequest(action)
}

export const emptyCart = () => {
  const action: WebshopAction = {
    type: actionTypes.EMPTY_CART,
  }
  return performRequest(action)
}

export const addProductToCartFromTemp = () => {
  const action: WebshopAction = {
    type: actionTypes.ADD_PRODUCT_TO_CART_FROM_TEMP,
  }
  return performRequest(action)
}

const performRequest = (action: WebshopAction) => {
  return (dispatch: DispatchType) => {
    dispatch(action)
  }
}
