import { Anchor, AnchorButton, Column, Img, RichText } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { COLUMN_DESKTOP_LAYOUT, COLUMN_TABLET_LAYOUT } from "@helpers/constants/generic"
import ICON_CARD_CONSTANTS from "@helpers/constants/iconCard"
import { useMediaQuery } from "@helpers/hooks"
import { ButtonGroup } from "@molecules"
import getDirection from "@utils/direction"
import React from "react"
import { trimText } from "../../../utils/stringparsing"
import { IIconcardData } from "./_iconCard.interface"

const IconCard = ({
  imageSrc,
  imageAlt,
  title,
  paragraph,
  link,
  linkIsExternal,
  linkText,
  columnTablet,
  columnDesktop,
  cardAlignment,
  subtitle,
  view,
  layout,
  secondaryLinkText,
  secondaryLink,
  secondaryLinkIsExternal = false,
  bulletPointsAlignment,
}: IIconcardData) => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  const isTablet = useMediaQuery("(max-width: 991px)")
  const isGrid: boolean = view === ICON_CARD_CONSTANTS.GRID
  const isParagraph: boolean = view === ICON_CARD_CONSTANTS.PARAGRAPH
  const isMinilist: boolean = view === ICON_CARD_CONSTANTS.MINILIST
  const isLeft: boolean = bulletPointsAlignment === ICON_CARD_CONSTANTS.LEFT
  const isAbove: boolean = bulletPointsAlignment === ICON_CARD_CONSTANTS.ABOVE
  const getClassNameForIcon = () => {
    const classes = []
    if (isGrid) classes.push("icon-card-left-grid")
    if (isParagraph) classes.push("icon-card-left-para")
    if ((isGrid || isParagraph) && isLeft) classes.push("auto-width")

    return classes.join(" ")
  }

  const getClassNameForCard = () => {
    const classes = []
    if (isParagraph && isLeft) classes.push("card-info left-aligned-para")
    else if (isGrid && isLeft) classes.push("card-info left-aligned-grid")
    else if (isMinilist && isLeft) classes.push("card-info")

    return classes.join(" ")
  }
  let size
  if (isMobile) {
    size = 40
  } else if (isTablet) {
    size = 48
  } else {
    size = isMinilist ? 24 : 64
  }

  return (
    <>
      {isAbove && (
        <Column
          desktop={
            !isGrid
              ? 12
              : columnDesktop ||
                COLUMN_DESKTOP_LAYOUT[layout as keyof typeof COLUMN_DESKTOP_LAYOUT] ||
                4
          }
          tablet={
            !isGrid
              ? 12
              : columnTablet ||
                COLUMN_TABLET_LAYOUT[layout as keyof typeof COLUMN_TABLET_LAYOUT] ||
                6
          }
          mobile={12}
          className="icon-card">
          <div className={"card " + cardAlignment} dir={getDirection()}>
            <div className="card-body">
              <span className="icon-card-icon">
                {imageSrc && (
                  <Img src={imageSrc} width={size} height={size} alt={imageAlt} type="icon" />
                )}
              </span>
              {subtitle && !isMinilist && <h4 className="icon-card-subtitle">{subtitle}</h4>}
              {title && !isMinilist && (
               link ? <Anchor
                  href={link}
                  tabindex={0}
                  noUnderline={true}
                  title={trimText(title, CHARLIMIT.TITLE)}>
                  <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
                </Anchor>
                : <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
              )}
              {paragraph && (
                <RichText
                  className="card-text icon-card-desc"
                  content={paragraph}
                  characterLimit={CHARLIMIT.DESCRIPTION}
                />
              )}
            </div>
            {isGrid && (
              <ButtonGroup className={"card-footer icon-card-custom " + cardAlignment}>
                <>
                  {link && (
                    <AnchorButton tabindex={0} href={link} isExternal={linkIsExternal}>
                      {linkText}
                    </AnchorButton>
                  )}
                  {secondaryLink && (
                    <AnchorButton
                      isSecondary={true}
                      tabindex={0}
                      href={secondaryLink}
                      isExternal={secondaryLinkIsExternal}>
                      {secondaryLinkText}
                    </AnchorButton>
                  )}
                </>
              </ButtonGroup>
            )}
            {isParagraph && (
              <div className="card-footer-above-para">
                <ButtonGroup className={cardAlignment}>
                  <>
                    {link && (
                      <AnchorButton tabindex={0} href={link} isExternal={linkIsExternal}>
                        {linkText}
                      </AnchorButton>
                    )}
                    {secondaryLink && (
                      <AnchorButton
                        isSecondary={true}
                        tabindex={0}
                        href={secondaryLink}
                        isExternal={secondaryLinkIsExternal}>
                        {secondaryLinkText}
                      </AnchorButton>
                    )}
                  </>
                </ButtonGroup>
              </div>
            )}
          </div>
        </Column>
      )}
      {isLeft && (
        <Column
          desktop={
            !isGrid
              ? 12
              : columnDesktop ||
                COLUMN_DESKTOP_LAYOUT[layout as keyof typeof COLUMN_DESKTOP_LAYOUT] ||
                4
          }
          tablet={
            !isGrid
              ? 12
              : columnTablet ||
                COLUMN_TABLET_LAYOUT[layout as keyof typeof COLUMN_TABLET_LAYOUT] ||
                6
          }
          mobile={12}
          className="icon-card">
          <Column
            desktop={!isGrid ? 1 : 3}
            tablet={!isGrid ? 2 : 3}
            mobile={!isGrid ? 1 : 3}
            className={
              isMinilist
                ? "icon-card-left-minilast"
                : isParagraph && isTablet
                ? `${getClassNameForIcon()} auto-width`
                : `${getClassNameForIcon()}`
            }>
            <span>
              {imageSrc && (
                <Img src={imageSrc} width={size} height={size} alt={imageAlt} type="icon" />
              )}
            </span>
          </Column>
          <Column
            desktop={!isGrid ? 11 : 9}
            tablet={!isGrid ? 10 : 9}
            mobile={!isGrid ? 11 : 9}
            className={getClassNameForCard()}>
            <div className={!isMinilist ? "card-body-left" : "card-body-left-minilist"}>
              {subtitle && !isMinilist && <span className="icon-card-subtitle">{subtitle}</span>}
              {title && !isMinilist && (
                link ? <Anchor
                  href={link}
                  tabindex={0}
                  noUnderline={true}
                  title={trimText(title, CHARLIMIT.TITLE)}>
                  <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
                </Anchor>
                : <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
              )}
              {paragraph && (
                <RichText
                  className="card-text icon-card-desc"
                  content={paragraph}
                  characterLimit={CHARLIMIT.DESCRIPTION}
                />
              )}
            </div>
            {!isMinilist ? (
              <ButtonGroup className={isParagraph ? "card-footer-left-para" : "card-footer-left"}>
                <>
                  {link && (
                    <AnchorButton tabindex={0} href={link} isExternal={linkIsExternal}>
                      {linkText}
                    </AnchorButton>
                  )}
                  {secondaryLink && (
                    <AnchorButton
                      isSecondary={true}
                      tabindex={0}
                      href={secondaryLink}
                      isExternal={secondaryLinkIsExternal}>
                      {secondaryLinkText}
                    </AnchorButton>
                  )}
                </>
              </ButtonGroup>
            ) : (
              <></>
            )}
          </Column>
        </Column>
      )}
      {!isAbove && !isLeft && (
        <Column
          desktop={columnDesktop || 4}
          tablet={columnTablet || 6}
          mobile={12}
          className="icon-card">
          <div className={"card " + cardAlignment} dir={getDirection()}>
            <div className="card-body">
              <span className="icon-card-icon">
                {imageSrc && (
                  <Img src={imageSrc} width={size} height={size} alt={imageAlt} type="icon" />
                )}
              </span>
              {subtitle && <h4 className="icon-card-subtitle">{subtitle}</h4>}
              {title && (
                link ? 
                  <Anchor
                    href={link}
                    tabindex={0}
                    noUnderline={true}
                    title={trimText(title, CHARLIMIT.TITLE)}>
                    <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
                  </Anchor>
                  : <h4 className="icon-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
              )}
              {paragraph && (
                <RichText
                  className="card-text icon-card-desc"
                  content={paragraph}
                  characterLimit={CHARLIMIT.DESCRIPTION}
                />
              )}
            </div>
            {link || secondaryLink ? (
              <ButtonGroup className={"card-footer icon-card-custom " + cardAlignment}>
                <>
                  {link && (
                    <AnchorButton tabindex={0} href={link} isExternal={linkIsExternal}>
                      {linkText}
                    </AnchorButton>
                  )}
                  {secondaryLink && (
                    <AnchorButton
                      isSecondary={true}
                      tabindex={0}
                      href={secondaryLink}
                      isExternal={secondaryLinkIsExternal}>
                      {secondaryLinkText}
                    </AnchorButton>
                  )}
                </>
              </ButtonGroup>
            ) : (
              <></>
            )}
          </div>
        </Column>
      )}
    </>
  )
}

export default IconCard
