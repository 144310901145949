import React, { useContext, useEffect, useState } from "react"
import { Icon, RichText } from "@atoms"
import ConfigurationsContext from "@utils/ConfigurationsContext"
import { IFloatingbanner } from "./_floatingBanner.interface"

const FloatingBanner = (props: IFloatingbanner): JSX.Element | null => {
  const [showBanner, setShowBanner] = useState<boolean>(true)
  const { children, id, onClose } = props
  const configurationContext = useContext(ConfigurationsContext)
  const { setDisplayFloatingBanner } = configurationContext

  useEffect(() => {
    const shouldShowBanner = localStorage.getItem("showBanner")
    if (shouldShowBanner === "false") {
      setShowBanner(false)
      setDisplayFloatingBanner(false)
    }
  }, [])

  const handleCloseBanner = () => {
    localStorage.setItem("showBanner", "false")
    setShowBanner(false)
    setDisplayFloatingBanner(false)
    onClose && onClose()
  }

  if (!showBanner) return null

  return (
    <div className="floatingBanner" id={id}>
      <div className="banner">
        <div className="container">
          <span className="close-button" onClick={handleCloseBanner}>
            <Icon iconName="CloseFloatingbanner" />
          </span>
          <RichText content={`<div class='content'>${children}</div>`} />
        </div>
      </div>
    </div>
  )
}

export default FloatingBanner
