import { Button, Icon } from "@atoms"
import { useEffect, useRef, useState } from "react"
import ButtonGroup from "@molecules/button-group"
import { IOption } from "@atoms/dropDown/_dropDown.interface"
import NUMBERS from "@helpers/constants/numbers"
import { IWebFormButtonProps } from "./_webFormButton.interface"

const WebFormButton = ({
  options,
  onSelect,
  isCleared,
  isDisabled,
  isMulti = false,
  initialValue = [],
}: IWebFormButtonProps) => {
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(initialValue)

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }
  }, [initialValue, selectedOptions])

  const handleOptionClick = (option: IOption) => {
    let tempSelectedVal = [...selectedOptions]
    if (selectedOptions.find((item: IOption) => item.id === option.id)) {
      tempSelectedVal = selectedOptions.filter((item: IOption) => item.id !== option.id)
    } else {
      tempSelectedVal = [...tempSelectedVal, option]
    }
    onSelect(tempSelectedVal)
    setSelectedOptions(tempSelectedVal)
  }

  const isSelected = (option: IOption) => {
    return !!selectedOptions.find((item: IOption) => item.id === option.id)
  }
  const getButtonClass = (option: IOption) => {
    if (isMulti) {
      return `button show ${isSelected(option) ? "selected" : ""}`
    }
    if (selectedOptions.length === NUMBERS.ZERO || isSelected(option)) {
      return "show"
    }
    return "hide"
  }

  useEffect(() => {
    if (!isFirstRender.current && isCleared) {
      setSelectedOptions([])
    }
  }, [isCleared])

  return (
    <div className="webform-button">
      <ButtonGroup className="webform-button--group">
        {options?.map((option: IOption) => (
          <Button
            tabindex={0}
            key={option.id}
            isDisabled={isDisabled}
            icon={
              isSelected(option) ? (
                <Icon iconName="CloseFloatingbanner" height={14} width={14} />
              ) : (
                false
              )
            }
            isGhost={false}
            onClick={() => handleOptionClick(option)}
            className={getButtonClass(option)}
            iconPosition="right">
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

export default WebFormButton
