import { Button, Icon } from "@atoms"
import { useEffect, useState } from "react"
import IconWithLabel from "../../molecules/iconWithLabel"
import { AddToCalendarProps } from "./_add-to-calendar.interface"

const AddToCalendar = (props: AddToCalendarProps) => {
  const [isActive, setIsActive] = useState(false)
  const { eventTitle, eventDescription, location, btnLabel } = props
  const startDate = props?.startDate?.substring(0, props.startDate.indexOf("+"))
  const endDate = props?.endDate?.substring(0, props.endDate.indexOf("+"))
  useEffect(() => {
    const isActivehandler = () => setIsActive(false)
    window.addEventListener("click", isActivehandler)
    return () => {
      window.removeEventListener("click", isActivehandler)
    }
  })

  const formatDate = (date: string, service: string): string => {
    if (!date) return ""
    switch (service) {
      case "google":
      case "yahoo":
      case "ics":
        return date?.replace(/-|:|\.\d+/g, "")
      case "outlook":
      case "office":
        return date?.replaceAll(":", "%3A")
      default:
        return ""
    }
  }

  const formattedStartDate = formatDate(startDate, "ics") || ""
  const formattedEndDate = formatDate(endDate, "ics") || ""
  const eventTitled = eventTitle || ""
  const eventDescribed = eventDescription || ""
  const locationdescribed = location || ""

  const generateICS = () => {
    const icsContent = [
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `DTSTART:${formattedStartDate}`,
      `DTEND:${formattedEndDate}`,
      `SUMMARY:${eventTitled}`,
      `DESCRIPTION:${eventDescribed}`,
      `LOCATION:${locationdescribed}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n")

    return encodeURI(`data:text/calendar;charset=utf8,${icsContent}`)
  }

  const handleDropdownClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setIsActive(!isActive)
  }

  const buttonMenus = [
    {
      link: `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${formatDate(
        startDate,
        "google",
      )}%2F${formatDate(
        endDate,
        "google",
      )}&details=${eventDescription}&text=${eventTitle}&location=${location}`,
      icon: <Icon iconName="GoogleCalendarIcon" />,
      children: "Google",
    },
    {
      link: generateICS(),
      icon: <Icon iconName="iCalendarIcon" />,
      children: "iCalendar",
      download: true,
    },
    {
      link: `https://outlook.office.com/calendar/0/deeplink/compose?allday=false&body=${eventDescription}&enddt=${formatDate(
        endDate,
        "office",
      )}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${formatDate(
        startDate,
        "office",
      )}&subject=${eventTitle}`,
      icon: <Icon iconName="Office365Icon" />,
      children: "Office 365",
    },
    {
      link: `https://outlook.live.com/calendar/0/deeplink/compose?allday=false&body=${eventDescription}&enddt=${formatDate(
        endDate,
        "outlook",
      )}&location=${location}&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${formatDate(
        startDate,
        "outlook",
      )}&subject=${eventTitle}`,
      icon: <Icon iconName="OutlookIcon" />,
      children: "Outlook.com",
    },
    {
      link: `https://calendar.yahoo.com/?desc=${eventDescription}&dur=&et=${formatDate(
        startDate,
        "yahoo",
      )}&in_loc=${location}&st=${formatDate(startDate, "yahoo")}&title=${eventTitle}&v=60`,
      icon: <Icon iconName="YahooIcon" />,
      children: "Yahoo",
    },
  ]

  return (
    <div className="addToCalendar">
      <Button
        onClick={(e: React.MouseEvent<HTMLElement>) => handleDropdownClick(e)}
        className="addToCalendar-button"
        icon={<Icon iconName="CalendarIcon1" />}
        isSecondary={true}
        tabindex={0}>
        {btnLabel}
      </Button>
      <div
        className={`addToCalendar-buttonMenus ${
          isActive ? "addToCalendar-buttonMenus--active" : ""
        }`}>
        {buttonMenus.map((option, key) => (
          <a
            target="_blank"
            key={key}
            href={option.link}
            download={option.download}
            rel="noreferrer">
            <div className="addToCalendar-buttonMenusOpt">
              <IconWithLabel icon={option.icon}>{option.children}</IconWithLabel>
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default AddToCalendar
