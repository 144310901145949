import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData, getImage, getTaxonomy, isExternalLink } from "."

const getOtherNewsStories = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let newsData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(newsData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(newsData)
  const title = newsData?.data?.attributes?.field_other_nas_block_title?.value
  newsData =
    newsData?.included &&
    (await Promise.all(
      newsData?.included?.map(async (news: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(news, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(news?.relationships?.field_nas_image)
        const imageUUID = news?.relationships?.field_nas_image?.data?.id
        const { imageUrl, imageStyledUrl, alt, width, height} = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : await getImage(imageUUID, "", "", serverData)
        const taxonomies = isPaywallProtected(news?.relationships?.field_category)
          .isPaywallProtectedFlag
          ? ""
          : await getTaxonomy(
              news?.relationships?.field_category?.links?.related?.href,
              true,
              serverData,
            )

        return {
          suggestedNewsAndStoriesTitle: title,
          articleTeaserContent: {
            taxonomies: taxonomies ?? null,
            heading: isPaywallProtected(news?.attributes?.title).isPaywallProtectedFlag
              ? null
              : news?.attributes?.title ?? null,
            text: news?.attributes?.body?.processed ?? null,
            buttonLabel: news?.attributes?.field_cta_nas?.title ?? null,
            link: filterURL(news?.attributes?.field_cta_nas?.uri) ?? null,
            linkExternal: news?.attributes?.field_cta_nas?.options?.attributes?.target
              ? isExternalLink(news?.attributes?.field_cta_nas?.options?.attributes?.target)
              : null,
          },
          articleTeaserImage: {
            desktopImage : {
              alt: alt ?? null,
              src: imageUrl ?? null,
              styledimage: imageStyledUrl ?? null,
              width: width ?? 496,
              height: height ?? 372,
            }
          },
        }
      }),
    ))
    
  return {
    newsData: filterNullValues(newsData) ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getOtherNewsStories
