import { useResourceImage } from "@helpers/hooks/customImageHooks/useResourceImage"
import { generateUrl } from "@utils/imageHelper/imageUtils"
import React from "react"

const ResourceImage = (props: any) => {
  const {
    alt,
    type,
    className,
    handleError,
    handleLoad,
    height,
    resource,
    width,
    islazyloaded = true,
  } = props

  const resourceProps = useResourceImage({
    generateUrl,
    type,
    resource,
  })

  const { src, srcset, sourceImage } = resourceProps

  const dimensionAttributes: any = {}
  if (typeof height !== "undefined") {
    if (
      type.includes("hero_banner") ||
      type.includes("rotating_hero_banner") ||
      type.includes("table_enhancement")
    ) {
      dimensionAttributes["height"] = `${height}px`
    } else {
      dimensionAttributes["height"] = "auto"
    }
  }
  if (typeof width !== "undefined") {
    if (!type.includes("plp_list")) {
      dimensionAttributes["width"] = "100%"
    }
  }
  dimensionAttributes["objectFit"] = "cover"

  return (
    <picture>
      {sourceImage.map((sImg: any, i: number) => (
        <source media={sImg?.media} srcSet={sImg?.url} key={i} />
      ))}

      <img
        loading={islazyloaded ? "lazy" : "eager"}
        decoding="async"
        style={dimensionAttributes}
        alt={alt}
        className={className}
        onError={handleError}
        onLoad={handleLoad}
        src={src}
        srcSet={srcset}
        width={width}
      />
    </picture>
  )
}

export default ResourceImage
