import getNestedObject from "@utils/nestedObjects"
import getConfigDisplayData from "./getConfigDisplayData"

export const getFormattedObj = (objectList: any[]) => {

  const options: any = []
  objectList?.map((option: any, key: number) => {

    const feildMappings = option?.attributes?.field_mapping && option?.attributes?.field_mapping.length
        ? option?.attributes?.field_mapping[0]
        : {}
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: feildMappings?.answer ?? ""
    })

})
  return options

}

export const getJobFilterLabelData = async () => {

  let CategoryData = await getConfigDisplayData()
  CategoryData = CategoryData?.data
  if (CategoryData) {
    const CategoryLabelData = {
      searchLabel: getNestedObject(CategoryData, "job_search_label"),
      searchPlaceholder: getNestedObject(CategoryData, "job_search_placeholder_txt"),
      functionLabel: getNestedObject(CategoryData, "job_function_label"),
      functionPlaceholderText: getNestedObject(CategoryData, "job_function_placeholder_txt"),
      locationLabel: getNestedObject(CategoryData, "job_loc_label"),
      locationPlaceholderText: getNestedObject(CategoryData, "job_location_placeholder_txt"),
      searchCTALabel: getNestedObject(CategoryData, "job_search_cta_label"),
      divisionLabel: getNestedObject(CategoryData, "job_division_dd_label"),
      datePostedLabel: getNestedObject(CategoryData, "job_date_posted_dd_label"),
      datePostedOptions: getNestedObject(CategoryData, "job_date_posted_options"),
      clearLabel: getNestedObject(CategoryData, "job_clear_all_filter_label"),
      defaultDivisionId: getNestedObject(CategoryData, "job_default_division"),
      tableColumns: getNestedObject(CategoryData, "job_listing_column_options"),
      resultsLabel: getNestedObject(CategoryData, "job_search_results_found"),
      noResultsLabel: getNestedObject(CategoryData, "job_no_results_found_title"),
      noResultsDesc: getNestedObject(CategoryData, "job_no_results_found_body"),
      sortByLabel: getNestedObject(CategoryData, "job_sort_by_txt"),
      applyFiltersCTALabel: getNestedObject(CategoryData, "job_apply_filters_text"),
      showFiltersCTALabel: getNestedObject(CategoryData, "job_show_filters_text"),
      unSelectItemsLabel: getNestedObject(CategoryData, "search_unselect_items"),
      paginationCount: getNestedObject(CategoryData, "job_count_for_each_page"),
      dopdwonPlaceHolder: getNestedObject(CategoryData, "job_select_option_text"),
      displayDivision: getNestedObject(CategoryData, "job_division_display") === 1,
      filter_toggle_label: getNestedObject(CategoryData, "filter_toggle_label"),
    }
    return CategoryLabelData

}

  return {
    searchLabel: "",
    searchPlaceholder: "",
    functionLabel: "",
    functionPlaceholderText: "",
    locationLabel: "",
    locationPlaceholderText: "",
    searchCTALabel: "",
    divisionLabel: "",
    datePostedLabel: "",
    datePostedOptions: "",
    clearLabel: "",
    defaultDivisionId: "",
    tableColumns: "",
    resultsLabel: "",
    noResultsLabel: "",
    noResultsDesc: "",
    sortByLabel: "",
    applyFiltersCTALabel: "",
    showFiltersCTALabel: "",
    unSelectItemsLabel: "",
    paginationCount: 0,
    dopdwonPlaceHolder: "",
    displayDivision: false,
    filter_toggle_label: "",
  }

}

export const getPostedDatesOptions = (map: any) => {

  return Object.entries(map)?.map(([key, value]) => ({id: key, value, label: value})) ?? []

}

export const fetchTableData = (response: any, jobLocationData: any) => {
  const tableData =
    response?.data?.map((item: any) => {
      const jobLocationId =
        item.relationships.field_job_country?.data?.[0]?.meta?.drupal_internal__target_id ?? ""
      const locationname =
        jobLocationId && jobLocationData?.length
          ? jobLocationData.filter((location: any) => location.id === jobLocationId)
          : []
      const jobTitle = item?.attributes?.path?.alias ?? ""
      return {
        jobTitle: {
          title: item?.attributes?.field_job_title ?? "",
          desc: `${item.attributes.field_job_employment_type ?? ""}, ${
            item.attributes.field_job_type ?? ""
          }`,
          type: "text",
          link: `/careers/career-search/job-details/${item?.attributes?.field_job_id}/${jobTitle}`
        },
        business: {
          title: "",
          link: "",
          desc: item?.attributes?.field_job_business_unit ?? "",
          type: "text",
        },
        location: {
          title: "",
          link: "",
          desc: locationname.length ? locationname[0].label : "",
          type: "text",
        },
        site: {
          title: "",
          link: "",
          desc: item.attributes.field_job_work_location ?? "",
          type: "text",
        },
        datePosted: {
          title: "",
          link: "",
          type: "date",
          desc: item.attributes.field_job_posted_date ?? "",
        },
      }
    }) ?? {}
  return tableData
}

export const sortDataAsc = (data: any) =>
  data.sort((a: any, b: any) => a.label.localeCompare(b.label))

const getJobSearchData = (data: any) => {

  return {}

}

export default getJobSearchData
