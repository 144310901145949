import * as actionType from "../ActionTypes"

const initialRatings: any = {
  ratings: undefined,
  liveCount: undefined,
}

const RatingsReducer = (state: any = initialRatings, action: RatingsAction): any => {
  switch (action.type) {
    case actionType.ADD_RATINGS:
    case actionType.RESET_RATINGS:
      return {
        ...state,
        ratings: action.ratings,
      }

    case actionType.RATINGS_COUNT:
      return {
        ...state,
        liveCount: action.liveCount
      }

    default:
      return state
  }
}

export default RatingsReducer
