import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getPrintButtonData = async (url: string) => {
  let printButtonData = await getBlockData(url)
  printButtonData = printButtonData?.data
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(printButtonData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(printButtonData)

  return {
    title: printButtonData?.attributes?.field_button_title ?? "",
    alignment: printButtonData?.attributes?.field_button_alignment ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getPrintButtonData