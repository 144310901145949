import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getAnchorCTAFileUrl, getBlockData } from "."

const getAnchorCTAData = async (url: string, data?: any) => {
  let ctaBtnData
  if (!data) {
    ctaBtnData = await getBlockData(url)
  } else {
    ctaBtnData = data
  }
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(ctaBtnData)
  const buttonType = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.field_anchor_button_type"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(ctaBtnData, "data.attributes.field_anchor_button_type")
  const buttonTitle = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.field_button_title"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(ctaBtnData, "data.attributes.field_button_title")
  const fileId = getNestedObject(ctaBtnData, "data.id")
  const fileUrl = fileId ? await getAnchorCTAFileUrl(fileId) : null

  if (buttonType === "print" || buttonType === "download" || buttonType === "link") {
    return {
      ctaBtnType: buttonType ?? null,
      ctaBtnData: {
        tabindex: 1,
        children: buttonTitle ?? null,
        isSecondary: true,
        href: !(buttonType === "link")
          ? fileUrl
          : getNestedObject(ctaBtnData, "data.attributes.field_link.uri"),
      },
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  return null
}

export default getAnchorCTAData
