import BarcodeContent from "@atoms/barcodeContent"
import { IBarcodeCollectionProps } from "./_barcodeCollection.interface"

const BarcodeCollection = ({ specifications, type, isPimProduct }: IBarcodeCollectionProps) => {
  // Check if there are specifications to display
  if (!specifications || specifications.length === 0 || !Array.isArray(specifications)) {
    // If no specifications, return null to render nothing
    return null
  }

  return (
    <div className="barcode-data-section">
      <h3 className="barcode-label">{type}</h3>
      <div className="barcode-collection">
        {specifications?.map((item, index) => (
          <BarcodeContent key={index} {...item} isPimProduct={isPimProduct} />
        ))}
      </div>
    </div>
  )
}

export default BarcodeCollection
