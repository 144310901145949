import React from "react"
import { Anchor, Icon, RichText } from "@atoms"
import { URL_TARGET } from "@helpers/constants/generic"
import CHARLIMIT from "@helpers/constants/charLimit"
import { ICardInfo, IQuickLinkCardProps } from "./_quickLinkCard.interface"
import { handleClick } from "@utils/navigationHelper"
import { useRouter } from "next/router"

const QuickLinkContent = ({ field_quick_link_card_title, body, field_card_title, cardAlignment }: ICardInfo) => (
  <div className="quick-link-content-container">
    {field_quick_link_card_title && (
      <h3 className={"text-description-heading bold " +cardAlignment}>{field_quick_link_card_title}</h3>)}
    {body && (
      <RichText className={"text-description-description " + cardAlignment} content={body} characterLimit={CHARLIMIT.DESCRIPTION} />
    )}
    {field_card_title && (
    <div className="list-links">
      <span className="list-links-text bold">{field_card_title}</span>
      <div className="list-links-icon">
        <Icon iconName="RightExpandIcon1" />
      </div>
    </div>
    )}
  </div>
)

const QuickLinkCard = ({ cardInfo, cardAlignment }: IQuickLinkCardProps) => {
  const router = useRouter()

  return (
  <Anchor
    className={"quick-link-card " + cardAlignment}
    href={cardInfo?.field_card_link === "route:<nolink>" ? "javascript:void(0)" : cardInfo?.field_card_link}
    tabindex={1}
    noUnderline={true}
    isExternal={cardInfo?.field_link_target === URL_TARGET.BLANK}
    onClick={() => handleClick(router, cardInfo?.referenceId || cardInfo?.field_card_link)}>
    <hr className="list-item-bar" />
    <QuickLinkContent {...cardInfo} cardAlignment={cardAlignment || ""} />
  </Anchor>
  )
}

export default QuickLinkCard
