import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Anchor, Button, Column, Container, Icon, Row } from "@atoms"
import useMediaQuery from "@helpers/hooks/useMediaQuery"
import { useOutsideClick } from "@helpers/hooks/useOutsideClick"
import { IStickyChildProps, IStickyItemMenuProps } from "./_stickyItemMenu.interface"

const StickyItemMenu = (props: IStickyItemMenuProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  const router = useRouter()
  const [closeMenu, setCloseMenu] = useState(false)
  const [closeIcon, setCloseIcon] = useState("arrow-down")

  const closeMenuItem = (e: any) => {
    setCloseMenu(!closeMenu)
    if (closeMenu === false) {
      e.preventDefault()
      return false
    }
  }

  useEffect(() => {
    closeMenu ? setCloseIcon("arrow-up") : setCloseIcon("arrow-down")
  }, [closeMenu])

  useEffect(() => {
    resetMobileMenu()
    setCloseMenu(false)
  }, [router.query])

  const resetMobileMenu = () => {
    if (refMenuButton.current) refMenuButton.current.className = "stickyMenu-item accordion-button"
  }

  const getMenuList = () =>
    props.child?.map((child: IStickyChildProps, index) => (
      <li key={`menu-${child.title}-${index}`}>
        <div className="menuSpace">
          {child.subChild ? (
            <div>
              <Anchor href={child.link} tabindex={0} noUnderline={true}>
                <p className="title"> {child.title}</p>
                <p className="MenuIndicator">
                  <Icon iconName="ChevronRightBlue" />
                </p>
              </Anchor>
            </div>
          ) : (
            <div className="noLink">
              <Anchor href={child.link} tabindex={0} noUnderline={true}>
                <span className="title"> {child.title}</span>
              </Anchor>
            </div>
          )}
        </div>
      </li>
    ))

  const handleClickOutsideMenuButton = () => {
    setCloseMenu(false)
  }
  const refMenuButton = useOutsideClick(handleClickOutsideMenuButton)
  return (
    <div
      className="stickyMenu-item menuContainer-close"
      data-bs-toggle="menuContainer-open"
      data-bs-target="#navbarHeader"
      aria-controls="navbarHeader"
      aria-expanded="false"
      aria-label="Toggle navigation"
      onClick={closeMenuItem}
      ref={refMenuButton}>
      <div
        className={
          router.pathname == props.link
            ? "stickyMenu-item-underline active"
            : "stickyMenu-item-underline"
        }>
        <div className="stickyMenu-logo">
          {!props.child?.length ? (
            <Anchor className="head-title" href={props.link} tabindex={0} noUnderline={true}>
              <p>{props.title}</p>
            </Anchor>
          ) : (
            <Button className="head-title" tabindex={0}>
              <p>{props.title}</p>
              <span className={closeIcon} />
            </Button>
          )}
        </div>

        {props.child && (
          <div
            className={`menuContainer ${
              closeMenu && !isMobile ? "menuContainer-open" : "menuContainer-close"
            }`}>
            <Container>
              <div className="megamenu">
                <ul className="child">{getMenuList()}</ul>
              </div>

              <div className="product-list">
                <Row>
                  <Column mobile={1} tablet={1} desktop={9}>
                    <span />
                  </Column>
                </Row>
              </div>
            </Container>
          </div>
        )}
      </div>
    </div>
  )
}
export default StickyItemMenu
