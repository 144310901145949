import BrandingPattern from "@atoms/brandingpattern"
import Container from "@atoms/container"
import Img from "@atoms/img"
import ErrorFourZeroFour from "@organisms/errorfourzerofour"
import FooterLite from "@organisms/footerLite"
import isServer from "@utils/fs/isServer"
import { getAssetPrefixUrl } from "@utils/helper"
import { useEffect, useState } from "react"
import styles from "./_maintenance.module.scss"

type Props = {
    errorMessage: string
}

const Maintenance = ({ errorMessage }: Props) => {
    const [isShowError, setIsShowError] = useState<boolean>(false)
    const errorData = {
        imgsrc: {
            src: "/assets/images/Error-image.png",
            alt: "Page not found"
        },
        cta: {
            tabindex: 1,
            children: "Go to Homepage",
            href: "/",
            isExternal: false
        },
        header: "Something went wrong.",
        paragraph: "<p>Sorry for the error. Please bear with us.</p>\r\n",
    }
    const footerData = {
        footerBottomSection: {
            logoLink: "/assets/images/Brand_Logo/Sandoz_Logo_White2.svg",
            footer_logo_link: "/",
            footer_logo_alt: "logo alt text",
            paragraph: "",
            copyRightText: "<p>© "+ new Date().getFullYear()+" Sandoz Group AG</p>",
            footerText: "",
            date: "",
        },

        footerSecondaryMenu: [],
        liteFooterFlag: 1,
    }

    const logoPath = "/assets/images/Brand_Logo/Sandoz_Logo_Blue.svg"

    if (!isServer()) {
        sessionStorage.clear()
        localStorage.clear()
    }

    useEffect(() => {
        const showError = async () => {
            const { location } = window
            const isLocalhost = !!location.hostname.includes("localhost")
            const url = getAssetPrefixUrl("/api/data-provider/?id=show-error", isLocalhost)
            const response = await fetch(url)
            const { show } = await response.json()

            setIsShowError(show)
        }

        showError()
    }, [])

    return (
        <main className={styles.maintanence}>
            <header className="maintanence-header">
                <div className="maintanence-identity">
                    <Img src={logoPath} alt="logo" height={26} width={155} type="icon" />
                </div>
            </header>
            <BrandingPattern />
            <Container className={styles.content}>
                <div className="maintanence-wrapper">
                    <ErrorFourZeroFour {...errorData} />
                    <div style={{display: "none"}}>{isShowError ? errorMessage : ""}</div>
                </div>
            </Container>
            <footer>
                <FooterLite {...footerData} />
            </footer>
        </main>
    )
}

export default Maintenance