import { Star } from "@atoms"
import { MouseEvent, useEffect, useState } from "react"
import NUMBERS from "@helpers/constants/numbers"
import { middlewarePostAPI } from "@utils/baseApi"
import Notification from "@molecules/notification"
import { useSelector } from "react-redux"
import { EVENTS } from "@utils/gtmEvents"
import { dataLayerPush } from "@utils/gtmutils"
import { config } from "@utils/baseApi/config.external"
import { IStarsRating } from "./_starsRating.interface"

type ILoginProps = {
  setShowLoginPopUp: (val: boolean) => void
}
type Props = IStarsRating & ILoginProps
const StarsRating = (props: Props) => {
  const {
    isDisabled = false,
    localStorageName: name = "defaultStarsRatingName",
    disableLayoutAfterRate = false,
    avgRating,
    setShowLoginPopUp,
    entityId,
    title,
  } = props
  const arr = [0, 1, 2, 3, 4]
  const [rating, setRating] = useState(avgRating)
  const [hoverRating, setHoverRating] = useState(rating)
  const [isRated, setIsRated] = useState(false)
  const [mousePos, setMousePos] = useState({ x: 0 })
  const [showToast, setShowToast] = useState<"success" | "error" | "">("")
  const [tempRating, setTempRating] = useState<number | null>(null)
  const user: any = useSelector((state: UserState) => state.user)

  const getClasses = () => {
    const classes = []
    classes.push("starsRating")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  useEffect(() => {
    setHoverRating(rating)
    if (rating > 0 && disableLayoutAfterRate) setIsRated(true)
    if (rating === 0 || !disableLayoutAfterRate) setIsRated(false)
  }, [rating, disableLayoutAfterRate])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const localValue = Number(localStorage.getItem(name))
      if (localValue) {
        setRating(localValue)
        localStorage.removeItem(name)
      }
    }
  }, [])

  useEffect(() => {
    if (user?.isLoggedIn && tempRating !== null) {
      submitRating(tempRating)
    }
  }, [user])

  const isFullFilled = (index: number) => hoverRating > index + 0.5
  const isHalfFilled = (index: number) => hoverRating > index && hoverRating <= index + 0.5
  const resetHoverRating = () => setHoverRating(rating)

  const handleHoverRating = (e: MouseEvent) => {
    if (isDisabled) return
    setMousePos({ x: e.clientX })
    const left = document?.getElementById("starsRating")?.getBoundingClientRect().left
    const width = document?.getElementById("starsRating")?.getBoundingClientRect().width
    if (left && width) setHoverRating(((mousePos.x - left) / width) * arr.length)
  }
  const showToastMessage = (msg: "success" | "error") => {
    setShowToast(msg)
    setTimeout(() => {
      setShowToast("")
    }, 10000)
  }

  const submitRating = async (ratingVal: number) => {
    //    const userId = getNestedObject(user, "user.response.session_data.user_data.current_user.uid")
    const { fail } = await middlewarePostAPI(config.onPrem.STAR_RATING_COUNT_UPDATE, {
      // uid: userId,
      entity_id: entityId,
      value: ratingVal,
    })
    setRating(ratingVal)
    const trackRateContentEvent = {
      article_title: title,
      article_rating: ratingVal,
    }

    dataLayerPush(EVENTS.RATE_CONTENT, trackRateContentEvent)
    if (fail) {
      showToastMessage("error")
    } else {
      showToastMessage("success")
      localStorage.removeItem(name)
    }
  }

  const handleRating = async () => {
    const calculatedRate = Math.ceil(hoverRating * 2) / 2

    if (user?.isLoggedIn) {
      await submitRating(calculatedRate)
    } else {
      setShowLoginPopUp(true)
      setTempRating(calculatedRate)
      localStorage.setItem(name, JSON.stringify(calculatedRate === 0 ? 0.5 : calculatedRate))
    }
  }

  return (
    <div className="starsRating-wrapper">
      {title && (
          <h3 className="starsRating-title bold">{title}</h3>)}
          <div
            className={getClasses()}
            onMouseLeave={resetHoverRating}
            onMouseMove={handleHoverRating}
            onClick={handleRating}
            id="starsRating"
            data-testid="starsRating">
            {arr.map((_, i) => (
              <Star
                key={i}
                isDisabled={isDisabled || isRated}
                isFullFilled={isFullFilled(i)}
                isHalfFilled={isHalfFilled(i)}
              />
            ))}
          </div>
          <div className="starsRating-data">
            {rating}/{NUMBERS.FIVE}
          </div>
      
      {!!showToast && <Notification notificationType={showToast} displayTxt={showToast} />}
    </div>
  )
}

export default StarsRating
