import * as actionType from "../ActionTypes"

const initialMagazineData: any = {
  taxonomy: undefined,
  layout: [],
  initialItemToLoad: 0,
  magazineDataSource: [],
  parentTab: "",
  childTab: "",
  loadMoreBtnTxt: "",
  magazineTempDataSource: []
}

const MagazineReducer = (state: any = initialMagazineData, action: MagazineAction): any => {
  switch (action.type) {
    case actionType.ADD_MAGAZINE_TAXONOMY:
      return {
        ...state,
        taxonomy: action.taxonomy,
      }

    case actionType.MAGAZINE_LAYOUT:
      return {
        ...state,
        layout: action.layout,
        initialItemToLoad: action.initialItemToLoad,
        loadMoreBtnTxt: action.loadMoreBtnTxt
      }

    case actionType.MAGAZINE_DATA:
      return {
        ...state,
        magazineDataSource: action.magazineDataSource
      }

    case actionType.MAGAZINE_TEMP_DATA:
        let magArray = []
        if (state.magazineTempDataSource !== undefined) {
          magArray = [...state.magazineTempDataSource, action.magazineTempDataSource]
        } else {
          magArray = action.magazineTempDataSource as Array<any>
        }

        return {
          ...state,
          magazineTempDataSource: magArray.flat()
        }

    case actionType.CLEAR_MAGAZINE_TEMP_DATA:
      return {
        ...state,
        magazineTempDataSource: []
      }

    case actionType.MAGAZINE_PARENT_TAB:
      return {
        ...state,
        parentTab: action.parentTab
      }

    case actionType.MAGAZINE_CHILD_TAB:
      return {
        ...state,
        childTab: action.childTab
      }

    default:
      return state
  }
}

export default MagazineReducer
