import {
  getBannerModuleData,
  getExternalLinkModalContent,
  getEyebrowMenuData,
  getFloatingBannerData,
  getFloatingModuleData,
  getFooterBottomSection,
  getFooterMenu,
  getFooterMicrosite,
  getFooterSecondaryMenu,
  getHeaderData,
  getHeaderLightMenu,
  getHeaderMegaMenu,
  getLiteFooterData,
  getLoginFormData,
  getLoginTimeoutModalContent,
  getRenderHcpModalPopUp,
} from "@templates/layout/_layout.data"
import getCountryAndBrandSwitcherData from "@helpers/dataFunctions/getCountryAndBrandSwitcherData"
import {
  getCartPageLabels,
  getLoginTimeoutData,
  getNewsLetterVerification,
  getPostcodeSearchData,
  getWave1ProductDataLabels,
  getWebstoreLabels,
} from "@utils/siteconfig_utils"
import { useCallback, useState } from "react"
import getInternalNavData from "@helpers/dataFunctions/getInternalNavPopupData"

type PageData = {
  headerMegaMenu?: any
  floatingBanner?: any
  footerNavigation?: any
  footerSecondaryMenu?: any
  footerBottomSection?: any
  externalLinkModal?: any
  floatingModule?: any
  footerMicrosite?: any
  liteFooter?: any
  loginTimeoutModal: any
  video_configuration?: any
  bannerModuleData?: any
  countryAndBrandSwitcherData?: any
  internalModalData?: any
}

const usePageReady = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [pageData, setPageData] = useState<PageData>()
  const [applicationConfig, setApplicationConfig] = useState<any>({
    isBookmarkEnabled: false,
    isFavouriteEnabled: false,
    isRatingEnabled: false,
    isLanguageEnabled: false,
    isRightPositionEnabled: true,
    tooltipText: "",
    login_timeout_enable: true,
    video_configuration: false,
    isWebStoreEnabled: false,
    isLoading: false,
    isPimProduct: false,
    isSandozIDEnabled: false,
    sandozRedirectUrl: "",
    barlinechart_download_cta_text: "",
    barlinechart_clear_cta_text: "",
  })
  const [appHeaderData, setAppHeaderData] = useState<any>()

  const fetchHeaderData = useCallback(
    async (applicationHeaderData: any, setApplicationHeaderData: any, siteConfig: any) => {
      if (applicationHeaderData?.headerData) {
        setPageData(applicationHeaderData?.headerData)
        return
      }

      try {
        const headerTopSection = getHeaderData(siteConfig)
        const loginFormData = await getLoginFormData(siteConfig)
        const eyebrowMenuData = getEyebrowMenuData(siteConfig)
        const hcpModal = getRenderHcpModalPopUp(siteConfig)
        const headerLightMenu = await getHeaderLightMenu()

        const response = {
          headerTopSection,
          loginFormData,
          eyebrowMenuData,
          hcpModal,
          headerLightMenu,
        }

        setAppHeaderData(response)
        setApplicationHeaderData({
          actionType: "APP_HEADER",
          headerData: response,
        })
      } catch (error) {
        console.log("Failed to load the app header content: ", error)
      }
    },
    [],
  )

  const fetchCMSData = useCallback(
    async (applicationPageData: any, setApplicationPageData: any, siteConfig: any) => {
      setIsLoading(true)

      if (applicationPageData?.pageData) {
        setIsLoading(false)
        setPageData(applicationPageData?.pageData)
        return
      }

      try {
        const headerMegaMenu = await getHeaderMegaMenu()
        const footerNavigation = await getFooterMenu()
        const footerSecondaryMenu = await getFooterSecondaryMenu()
        const liteFooter = await getLiteFooterData(siteConfig)
        const floatingBanner = getFloatingBannerData(siteConfig)
        const footerBottomSection = getFooterBottomSection(siteConfig)
        const externalLinkModal = getExternalLinkModalContent(siteConfig)
        const floatingModule = getFloatingModuleData(siteConfig)
        const footerMicrosite = getFooterMicrosite(siteConfig)
        const loginTimeoutModal = getLoginTimeoutModalContent(siteConfig)
        const bannerModuleData = getBannerModuleData(siteConfig)
        const countryAndBrandSwitcherData = await getCountryAndBrandSwitcherData(siteConfig)
        const internalModalData = await getInternalNavData(siteConfig)

        const response = {
          headerMegaMenu,
          floatingBanner,
          footerNavigation,
          footerSecondaryMenu,
          footerBottomSection,
          externalLinkModal,
          floatingModule,
          footerMicrosite,
          liteFooter,
          loginTimeoutModal,
          bannerModuleData,
          countryAndBrandSwitcherData,
          internalModalData
        }

        setPageData(response)
        setApplicationPageData({
          actionType: "ADD",
          applicationPageData: response,
        })
      } catch (error) {
        console.log("Failed to load the page ready content: ", error)
      }
      setIsLoading(false)
    },
    [],
  )

  const fetchApplicationConfiguration = useCallback(
    async (applicationConfiguration: any, setApplicationConfiguration: any, siteConfig: any) => {
      if (
        Object.keys(applicationConfiguration).length &&
        applicationConfiguration.isLoadingCompleted
      ) {
        setApplicationConfig(applicationConfiguration)
        return
      }

      try {
        const {
          bookmark,
          rating,
          favourite,
          language_switcher,
          scroll_to_top_hover_text,
          cta_position_target,
          content_protection_cta,
          content_protection_description,
          register_link,
          register_text,
          login_text,
          video_configuration,
          flash_num_msg,
          doccheck_user_name,
          webstore_list_enable_webstore,
          content_protection_position,
          enable_pim_product,
          enable_azure_login_link,
          azure_login_link,
          barlinechart_download_cta_text,
          barlinechart_clear_cta_text,
          enable_global_site,
          azure_logout_link,
          error_codes,
        } = siteConfig

        const wave1_product_data = getWave1ProductDataLabels(siteConfig)
        const cart_data = getCartPageLabels(siteConfig)
        const webstore_data = getWebstoreLabels(siteConfig)
        const login_timeout_data = getLoginTimeoutData(siteConfig)
        const newsletter_form_data = getNewsLetterVerification(siteConfig)
        const postcode_search_data = getPostcodeSearchData(siteConfig)

        const response = {
          isBookmarkEnabled: bookmark === 1,
          isFavouriteEnabled: favourite === 1,
          isRatingEnabled: rating === 1,
          isLanguageEnabled: language_switcher === 1,
          isRightPositionEnabled: cta_position_target === "right",
          tooltipText: scroll_to_top_hover_text,
          paywallNotice: {
            ctaLabel: content_protection_cta,
            description: content_protection_description?.value,
            registerLink: register_link,
            registerText: register_text,
            loginText: login_text,
          },
          video_configuration: video_configuration === 1,
          wave1_product_data,
          flash_num_msg,
          doccheck_user_name,
          siteConfig,
          cart_data,
          isWebStoreEnabled: webstore_list_enable_webstore === 1,
          content_protection_position,
          isLoading: true,
          isPimProduct: enable_pim_product === 1,
          isSandozIDEnabled: enable_azure_login_link === 1,
          sandozRedirectUrl: azure_login_link,
          sandozLogoutUrl: azure_logout_link,
          isGlobalSite: enable_global_site === 1,
          barlinechart_download_cta_text,
          barlinechart_clear_cta_text,
          error_codes,
          ...applicationConfiguration,
        }

        setApplicationConfig(response)
        setApplicationConfiguration({
          actionType: "APP_CONFIG",
          isBookmarkEnabled: response.isBookmarkEnabled,
          isFavouriteEnabled: response.isFavouriteEnabled,
          isRatingEnabled: response.isRatingEnabled,
          isLanguageEnabled: response.isLanguageEnabled,
          isRightPositionEnabled: response?.isRightPositionEnabled,
          tooltipText: response?.tooltipText,
          loginTimeoutData: login_timeout_data,
          postcodeSearchData: postcode_search_data,
          paywallNotice: response?.paywallNotice,
          video_configuration: response?.video_configuration,
          wave1_product_data: response?.wave1_product_data,
          flash_num_msg: response?.flash_num_msg,
          error_codes: response?.error_codes,
          doccheck_user_name: response?.doccheck_user_name,
          siteConfig: response?.siteConfig,
          cart_data: response?.cart_data,
          webstore_data,
          newsletter_form_data,
          isWebStoreEnabled: response?.isWebStoreEnabled,
          isLoading,
          isPimProduct: response?.isPimProduct,
          isSandozIDEnabled: response?.isSandozIDEnabled,
          sandozRedirectUrl: response?.sandozRedirectUrl,
          sandozLogoutUrl: response?.sandozLogoutUrl,
          isGlobalSite: response?.isGlobalSite,
          barlinechart_download_cta_text: response?.barlinechart_download_cta_text,
          barlinechart_clear_cta_text: response?.barlinechart_clear_cta_text,
        })
      } catch (error) {
        console.log("Variables are not set for bookmarks, ratings and fav!")
      }
    },
    [],
  )

  return {
    isLoading,
    pageData,
    applicationConfig,
    appHeaderData,
    fetchHeaderData,
    fetchCMSData,
    fetchApplicationConfiguration,
  }
}

export default usePageReady
