import { ISnackBiteProps } from "./_snackbite.interface"
import TextBlock from "../../molecules/textBlock"
import SliderWithContent from "../../organisms/sliderWithContent"
import moment from "moment"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useContext } from "react"
import { getApiDomainAndLang } from "@utils/baseApi"

const SnackBite = (props: ISnackBiteProps) => {
  const { titleNewsAndStoriesDetails, detailNewsAndStories } = props
  const { subTitle, ...rest } = titleNewsAndStoriesDetails
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()
  
  return (
    <>
      <TextBlock 
      subTitle={subTitle ? moment(subTitle).locale(lang).format(applicationConfiguration?.siteConfig?.date_format) : ""} 
      {...rest} />
      <SliderWithContent {...detailNewsAndStories} />
    </>
  )
}

export default SnackBite
