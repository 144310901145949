import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import {
 filterURL, getBlockData, getImageMeta, isExternalLink,
} from "."

const getServiceTeaserCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let serviceCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(serviceCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(serviceCards)
  serviceCards = getNestedObject(serviceCards, "included") ?? []

  if (serviceCards) {
    serviceCards = await Promise.all(
      serviceCards?.map(async (contentCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(contentCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(
          getNestedObject(contentCard, "relationships.field_article_image"),
        )

        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(contentCard.relationships.field_article_image)

        return {
          serviceTeaserCardImage: {
            src: imageUrl ?? null,
            alt: alt ?? null,
            styledimage: imageStyledUrl ?? null,
          },
          serviceTeaserCardContent: {
            id: getNestedObject(contentCard, "attributes.drupal_internal__nid") ?? null,
            heading: isPaywallProtected(getNestedObject(contentCard, "attributes.title"))
              .isPaywallProtectedFlag
              ? null
              : getNestedObject(contentCard, "attributes.title") ?? null,
            text: getNestedObject(contentCard, "attributes.body.processed") ?? null,
            buttonLabel:
              getNestedObject(contentCard, "attributes.field_secondary_cta.title") ?? null,
            link:
              filterURL(getNestedObject(contentCard, "attributes.field_secondary_cta.uri")) ?? null,
            bookmarkIcon: isPaywallProtected(getNestedObject(contentCard, "attributes.status"))
              .isPaywallProtectedFlag
              ? null
              : getNestedObject(contentCard, "attributes.status") ?? null,
            isLinkExternal: getNestedObject(
              contentCard,
              "attributes.field_secondary_cta.options.attributes.target",
            )
              ? isExternalLink(
                  getNestedObject(
                    contentCard,
                    "attributes.field_secondary_cta.options.attributes.target",
                  ),
                )
              : null,
          },
        }
      }),
    )
    return {
      serviceCards: filterNullValues(serviceCards) ?? null,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }

  return null
}

export default getServiceTeaserCardData
