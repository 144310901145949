import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getApi } from "@utils/baseApi"
import { filterURL, getBlockData, getImage, getImageMeta } from "."
import { PAGE_NID_FILTER } from "@helpers/constants/generic"

const _processCardData = async (url: string, cardType: string, serverData?: any) => {
  let blockCards = await getApi(url, false, serverData)
  const fieldPaywallRoles = getAllRoles(blockCards)
  const cardAlignment = blockCards?.data?.attributes?.field_text_alignment_style ?? "left"
  blockCards = blockCards?.data

  switch (cardType) {
    case "icon_cards": {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(blockCards, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(blockCards?.relationships?.field_icon)
      const icoImgUUID = blockCards?.relationships?.field_icon?.data?.id
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : await getImage(icoImgUUID, "", "", serverData)
      return {
        title: blockCards?.attributes?.field_icontitle?.value ?? null,
        paragraph: blockCards?.attributes?.body?.value ?? null,
        imageSrc: imageUrl ?? null,
        imageAlt: alt ?? null,
        styledimage: imageStyledUrl ?? null,
        link: filterURL(blockCards?.attributes?.field_button?.uri) ?? null,
        linkText: blockCards?.attributes?.field_button?.title ?? null,
        cardType,
        cardAlignment,
        fieldPaywallRoles
      }
    }
    case "teaser_feature_block": {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(blockCards, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(blockCards?.relationships?.field_tf_image)
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(blockCards?.relationships?.field_tf_image)
      return {
        title: isPaywallProtected(blockCards?.attributes?.field_tf_title).isPaywallProtectedFlag
          ? null
          : blockCards?.attributes?.field_tf_title ?? null,
        paragraph: blockCards?.attributes?.body?.value ?? null,
        imageSrc: imageUrl ?? null,
        imageAlt: alt ?? null,
        styledimage: imageStyledUrl ?? null,
        link: filterURL(blockCards?.attributes?.field_feature_cta?.uri) ?? null,
        linkText: blockCards?.attributes?.field_feature_cta?.title ?? null,
        gridType: isPaywallProtected(blockCards?.attributes?.field_grid_type).isPaywallProtectedFlag
          ? null
          : blockCards?.attributes?.field_grid_type ?? null,
        cardType,
        cardAlignment,
        fieldPaywallRoles
      }
    }
    case "teaser_block_card": {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(blockCards, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(blockCards?.relationships?.field_tbc_image)
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(blockCards?.relationships?.field_tbc_image)
      let anchorTargetField = false
      blockCards?.attributes?.field_tbc_cta?.options?.attributes?.target === "_blank"
        ? (anchorTargetField = true)
        : (anchorTargetField = false)

      return {
        title: blockCards?.attributes?.field_tbc_title?.value ?? null,
        paragraph: blockCards?.attributes?.body?.value ?? null,
        imageSrc: imageUrl ?? null,
        imageAlt: alt ?? null,
        styledimage: imageStyledUrl ?? null,
        link: filterURL(blockCards?.attributes?.field_tbc_cta?.uri) ?? null,
        linkText: blockCards?.attributes?.field_tbc_cta?.title ?? null,
        linkIsExternal: anchorTargetField ?? false,
        cardType,
        cardAlignment,
        imageCtaUrl: filterURL(blockCards?.attributes?.field_tca_image_link?.uri) ?? null,
        isImageClickable: blockCards?.attributes?.field_image_clickable ?? false,
        fieldPaywallRoles,
      }
    }
    case "teaser_snacbite": {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(blockCards, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(blockCards?.relationships?.field_ts_image)
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(blockCards?.relationships?.field_ts_image)
      return {
        title: isPaywallProtected(blockCards?.attributes?.field_ts_title).isPaywallProtectedFlag
          ? null
          : blockCards?.attributes?.field_ts_title ?? null,
        description:
          blockCards?.attributes?.body?.processed ?? blockCards?.attributes?.body?.value ?? null,
        imageSrc: imageUrl ?? null,
        imageAlt: alt ?? null,
        styledimage: imageStyledUrl ?? null,
        imgCaption: isPaywallProtected(blockCards?.attributes?.field_caption).isPaywallProtectedFlag
          ? null
          : blockCards?.attributes?.field_caption ?? null,
        cardType,
        cardAlignment,
        fieldPaywallRoles,
      }
    }
    default: {
      return null
    }
  }
}

const _getCardData = async (cardType: string, ID: string, serverData?: any) => {
  let blockCards
  if (
    cardType === "teaser_feature_block" ||
    cardType === "icon_cards" ||
    cardType === "teaser_block_card" ||
    cardType === "teaser_snacbite"
  ) {
    blockCards = await _processCardData(`/jsonapi/block_content/${cardType}/${ID}`, cardType, serverData)
    return blockCards
  }
  if (cardType === "teaser_description_card") {
    let blockCards = await getApi(`/jsonapi/block_content/${cardType}/${ID}`, true, serverData)
    const fieldPaywallRoles = getAllRoles(blockCards)
    blockCards = blockCards?.data
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(blockCards, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag

    if (isChildPaywalled) {
      return null
    }
    const cardAlignment = blockCards?.attributes?.field_text_alignment_style ?? ""
    return {
      title: isPaywallProtected(blockCards?.attributes?.field_teaser_title).isPaywallProtectedFlag
        ? null
        : blockCards?.attributes?.field_teaser_title ?? null,
      description: blockCards?.attributes?.field_teaser_group_description?.value ?? null,
      cardAlignment,
      fieldPaywallRoles
    }
  }
  return null
}

const getTeaserBlockData = async (url: string, ...args: any) => {
  const pageNid = args?.[0]
  const serverData = args?.[3] // accepting serverData only
  const teaserBlockData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(teaserBlockData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(teaserBlockData)
  let teaserBlockRawData
  const allCards: any = []
  const displayOption = isPaywallProtected(teaserBlockData?.data?.attributes?.field_tb_type)
    .isPaywallProtectedFlag
    ? null
    : teaserBlockData?.data?.attributes?.field_tb_type
  const cardType = isPaywallProtected(teaserBlockData?.data?.attributes?.field_teaser_card_type)
    .isPaywallProtectedFlag
    ? null
    : teaserBlockData?.data?.attributes?.field_teaser_card_type ?? null
  const ctaLabel = teaserBlockData?.data?.attributes?.field_tb_cta?.title ?? null
  const ctaLink = filterURL(teaserBlockData?.data?.attributes?.field_tb_cta?.uri) ?? null
  const cardCountInDesk = isPaywallProtected(
    teaserBlockData?.data?.attributes?.field_teaser_block_type_for_mobi,
  ).isPaywallProtectedFlag
    ? 0
    : teaserBlockData?.data?.attributes?.field_teaser_block_type_for_mobi ?? 3
  const cardCountInTab = isPaywallProtected(
    teaserBlockData?.data?.attributes?.field_teaser_block_type_for_tabl,
  ).isPaywallProtectedFlag
    ? 0
    : teaserBlockData?.data?.attributes?.field_teaser_block_type_for_tabl ?? 3

  if (cardType === "teaser_description_card") {
    teaserBlockRawData =
      teaserBlockData?.data?.relationships?.field_teaser_block_card_group?.data ?? []
  } else {
    teaserBlockRawData = teaserBlockData?.data?.relationships?.field_tb_reference?.data ?? []
  }
  for (const teaserBlock of teaserBlockRawData) {
    allCards.push(
      await _getCardData(
        cardType,
        `${teaserBlock?.id}${pageNid ? `?${PAGE_NID_FILTER}${pageNid}` : ""}`,
        serverData,
      ),
    )
  }

  if (cardType === "teaser_description_card") {
    return {
      displayOption,
      cardType,
      teaserData: [
        {
          allFeatureCards: filterNullValues(allCards),
          ctaLink,
          ctaLabel,
          linkIsExternal: false,
        },
      ],
      columnTablet: 12,
      columnDesktop: 12,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  if (cardType === "teaser_snacbite") {
    const columnTablet = Math.round(12 / cardCountInTab) ?? 12
    const columnDesktop = Math.round(12 / cardCountInDesk) ?? 12
    return {
      displayOption,
      cardType,
      teaserData: filterNullValues(allCards),
      columnTablet,
      columnDesktop,
      cardCountInDesk: cardCountInDesk ?? 1,
      cardCountInTab: cardCountInTab ?? 1,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  if (cardType === "teaser_block_card") {
    let columnTablet
    let columnDesktop
    if (displayOption === "carousel" ?? displayOption === "tile") {
      columnTablet = Math.round(12 / cardCountInTab) ?? 4
      columnDesktop = Math.round(12 / cardCountInDesk) ?? 4
    } else {
      columnTablet = 6
      columnDesktop = 6
    }
    return {
      displayOption,
      cardType,
      teaserData: filterNullValues(allCards),
      columnTablet,
      columnDesktop,
      cardCountInDesk: cardCountInDesk ?? 3,
      cardCountInTab: cardCountInTab ?? 3,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  if (cardType === "icon_cards") {
    let columnTablet
    let columnDesktop
    if (displayOption === "carousel") {
      columnTablet = Math.round(12 / cardCountInTab) ?? 4
      columnDesktop = Math.round(12 / cardCountInDesk) ?? 4
    } else if (displayOption === "rail") {
      columnTablet = 4
      columnDesktop = 6
    } else {
      columnTablet = 6
      columnDesktop = 6
    }

    return {
      displayOption,
      cardType,
      teaserData: filterNullValues(allCards),
      columnTablet,
      columnDesktop,
      cardCountInDesk,
      cardCountInTab,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  if (cardType === "teaser_feature_block") {
    let columnTablet
    let columnDesktop
    if (displayOption === "carousel") {
      columnTablet = Math.round(12 / cardCountInTab) ?? 4
      columnDesktop = Math.round(12 / cardCountInDesk) ?? 4
    } else {
      columnTablet = 6
      columnDesktop = 6
    }

    return {
      displayOption,
      cardType,
      teaserData: filterNullValues(allCards),
      columnTablet,
      columnDesktop,
      cardCountInDesk,
      cardCountInTab,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }
  return null
}

export default getTeaserBlockData
