import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { connect, useSelector } from "react-redux"
import { handleDataLayerError } from "@utils/gtmUtilsHelpers"
import { AuthForm, Notification, Popup, Tabs } from "@molecules"
import { getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import { getApplicationPathFromURL } from "@utils/session-util/sessionHandler"
import { config } from "@utils/baseApi/config.external"
import { addUser, resetUser, setUserProfile } from "store/actions/ActionCreators"
import { setUserStateGlobally } from "store/actions/CommonActionCreators"
import useUserProfile from "@helpers/hooks/useUserProfile"
import { EVENTS } from "@utils/gtmEvents"
import { encodeString } from "@utils/sha256"
import Router, { useRouter } from "next/router"
import ArticleTeaserBanner from "@organisms/articleBanner"
import { LOGIN_TYPES } from "@helpers/constants/generic"
import { IArticleTeaserBannerProps } from "@organisms/articleBanner/_article-banner.interface"
import { Button, Column, Icon, RichText } from "@atoms"
import { ILoginPopup } from "./_loginPopup.interface"
import AES from "crypto-js/aes"
import { clearSession, setSession } from "store/actions/SessionCreators"
import { getAssetPrefixUrl } from "@utils/helper"
import ApplicationContext from "@utils/application-context/applicationContext"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"

interface DispatchProps {
  addUserData: (user: any) => void
  resetUserData: (user: any) => void
  setUserProfile: (profile: any) => void
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) => void
  setSession: (session: string, csrf: string) => void
  clearSession: () => void
}

type Props = DispatchProps & ILoginPopup

const LoginPopup = (props: Props) => {
  const {
    showPopup,
    form,
    bannerCTA,
    popup,
    handlePopupShow,
    loginConfirmation,
    addUserData,
    resetUserData,
    setUserProfile,
    setUserStateGlobally,
    logoutNotification,
    setSession,
    clearSession,
  } = props

  const router = useRouter()

  const { wrongCredentialsText, emailIncorrectFormat, passwordIncorrect, deactivatedUserMessage } = form?.formLogin || {}

  const solarLoginForm = {
    ...form?.formLogin,
  }

  const docCheckForm = {
    ...form?.docCheckLoginForm,
  }

  const [showOverlay, setShowOverlay] = useState(false)
  const [showCredentialsError, setShowCredentialsError] = useState(false)
  const [isInvalidEmail, setIsInvalidEmail] = useState(false)
  const [isInvalidPassword, setIsInvalidPassword] = useState(false)
  const [isInvalidDocCheckEmail, setIsInvalidDocCheckEmail] = useState(false)
  const [isInvalidDocCheckPassword, setIsInvalidDocCheckPassword] = useState(false)
  const [showDocCheckCredentialsError, setShowDocCheckCredentialsError] = useState(false)
  const { fetchUserProfile } = useUserProfile()
  const paywalledContent: any = useSelector((state: any) => state.common.paywallContent)
  const { applicationConfiguration } = useContext(ApplicationContext)
  const [isSandozEnabled, setIsSandozEnabled] = useState<boolean>(false)
  const { handleLoginRedirection } = useSandozIDAuth(applicationConfiguration)
  const [isDeactivatedUser, setIsDeactivatedUser] = useState(false)

  const applicationId: any = useSelector((state: any) => state.appSession.applicationId)

  useEffect(() => {
    const setInitState = async () => {
      const sandozIdFlag: boolean = applicationConfiguration.isSandozIDEnabled || false
      setIsSandozEnabled(sandozIdFlag)
    }

    if (applicationConfiguration) {
      setInitState()
    }
  }, [applicationConfiguration])

  const emailValidation = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
  const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/i

  useEffect(() => {
    if (!showPopup) {
      setIsInvalidEmail(false)
      setIsInvalidPassword(false)
      setShowCredentialsError(false)
      setIsInvalidDocCheckEmail(false)
      setIsInvalidDocCheckPassword(false)
      setShowDocCheckCredentialsError(false)
      setIsDeactivatedUser(false)
    }
  }, [showPopup])

  const selectedTab = useMemo(() => {
    let selectedItem = 0
    if (solarLoginForm.isLoginFormDisabled && docCheckForm.isLoginFormDisabled) {
      selectedItem = 0
    } else if (solarLoginForm.isLoginFormDisabled) {
      selectedItem = 0
    } else {
      selectedItem = 1
    }

    return {
      num: selectedItem,
    }
  }, [props])

  const handleChange = (e: any) => {
    e.preventDefault()
  }

  const handleDocCheck = async (e: any) => {
    e.preventDefault()

    setShowOverlay(true)

    setTimeout(() => {
      setShowOverlay(false)
    }, 10000)

    try {
      // @ts-ignore
      document?.login_form_doccheck.submit()
      
      // GTM
      /**
       * Stores a login event in sessionStorage.
       * This serializes the login event data and saves it under the key "loginEvent".
       */
      const trackLogInEvent = {
        login_system: LOGIN_TYPES.DOC_CHECK,
      }    
      sessionStorage.setItem(
        "loginEvent", // Key under which the event data will be stored
        JSON.stringify({
          event: EVENTS.LOGIN, // The event type
          data: trackLogInEvent, // The login event data
        }),
      )
    } catch (error) {
      console.error("Doccheck from is not available!")
    }
  }

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()
    setIsInvalidEmail(false)
    setIsInvalidPassword(false)
    setShowCredentialsError(false)
    setIsDeactivatedUser(false)

    const email = e.target?.elements?.email?.value
    const password = e.target?.elements?.password?.value

    const validEmail = emailValidation.test(email)
    const validPassword = passwordValidation.test(password)

    if (validEmail && validPassword) {
      // Here place to send a POST request to login
      // now hardcoded to false to show error messages
      setShowOverlay(true)
      const body = {
        username: email,
        password,
      }

      const responseLogin: any = await middlewarePostAPI(config.onPrem.LOGIN_API_URL, body)

      setShowOverlay(false)
      if (!responseLogin.fail) {
        // set user details in cookie
        const csrfToken = responseLogin?.response?.session_data?.csrf_token
        const sessionId = responseLogin?.response?.session_data?.hashedSessionId

        const updateLoginData = {
          ...responseLogin,
          loginType: LOGIN_TYPES.SOLAR,
        }

        addUserData(updateLoginData)
        setUserStateGlobally(true, LOGIN_TYPES.SOLAR)

        /**
         * save sessionId to cookie & redux (without saving any storage)
         * set httpOnly cookie
         */
        setSession(sessionId, csrfToken)
        const salt = process.env.USER_COOKIE_SALT as string
        const isLocalhost = !!location.hostname.includes("localhost")
        const url = getAssetPrefixUrl(
          `/api/data-provider/?id=user&appid=${applicationId}`,
          isLocalhost,
        )
        const { feDomain } = getApiDomainAndLang()

        const response = await fetch(url, {
          headers: {
            sessionId: AES.encrypt(sessionId, salt).toString(),
            csrfToken: AES.encrypt(csrfToken, salt).toString(),
            domainPath: getApplicationPathFromURL(),
            fedomain: feDomain,
          },
        })
        await response.json()

        handlePopupShow(false)
        setShowCredentialsError(false)
        loginConfirmation(true)

        // fetch user profile
        const profileResponse = await fetchUserProfile()
        if (!profileResponse.fail) {
          setUserProfile(profileResponse)
        } else {
          if (profileResponse.code === 415) {
            clearSession()
            resetUserData({})
            setUserStateGlobally(false, "")
            return
          }

          console.log("Failed to fetch profile response!")
        }

        // GTM
        /**
         * Stores a login event in sessionStorage.
         * This serializes the login event data and saves it under the key "loginEvent".
         */
        const trackLogInEvent = {
          user_id: encodeString(email),
          login_system: LOGIN_TYPES.SOLAR,
        }
        sessionStorage.setItem(
          "loginEvent", // Key under which the event data will be stored
          JSON.stringify({
            event: EVENTS.LOGIN, // The event type
            data: trackLogInEvent, // The login event data
          }),
        )

        // reload in case of paywalled content
        setTimeout(() => {
          // if (paywalledContent) {
            Router.reload()
          // }
        }, 3000)
      } else {
        resetUserData(responseLogin)
        setShowCredentialsError(true)
        setUserStateGlobally(false, "")

        // GTM
        handleDataLayerError(wrongCredentialsText ?? "", "Login Form")

        if (responseLogin.code === 403) {
          if (responseLogin.error.code === 404) {
            try {
              const customParam: string = btoa(JSON.stringify({ email: responseLogin.error.email }))
              const pageUrl = applicationConfiguration?.siteConfig?.flexible_registration_enabled === 1 ? "my-registration" : "sign-up"
              router.push(`/${pageUrl}#id_token=${responseLogin.error.azure_id_token}.${customParam}`)
            } catch (error) {
              console.log(error)
              router.push("/")
            }
          }
          if (responseLogin.error.code === 413) {
            setShowCredentialsError(false)
            setIsDeactivatedUser(true)
          }
        }
      }
    }

    if (!validEmail) {
      setIsInvalidEmail(true)
      // GTM error
      handleDataLayerError(emailIncorrectFormat ?? "", "Login Form")
    }
    if (!validPassword) {
      setIsInvalidPassword(true)

      // GTM error
      handleDataLayerError(passwordIncorrect ?? "", "Login Form")
    }
  }

  const handleOnForgotPassword = (url: any) => {
    handlePopupShow(false)
    window.location.assign(url)
  }

  /**
   * @todo Read both the text from CMS
   * Please enable doc check, if and only if it is enabled from CMS
   */

  const loginTabs = [
    {
      isEnabled: solarLoginForm.isLoginFormDisabled as boolean,
      tabName: solarLoginForm.title,
      ref: useRef(null),
      content: {},
    },
    {
      isEnabled: docCheckForm.isLoginFormDisabled as boolean,
      tabName: docCheckForm.title,
      ref: useRef(null),
      content: {},
    },
  ]

  const tabHeadItems = loginTabs.map((item: any) =>
    item.isEnabled
      ? {
          isFormDisabled: item.isEnabled,
          children: `${item.tabName}`,
          itemID: item.tabName,
          tabDesign: "wide",
        }
      : {
          isFormDisabled: item.isEnabled,
          children: `${item.tabName}`,
          itemID: null,
          tabDesign: "wide",
        },
  )

  const renderAuthForm = (
    formTitle: string | undefined,
    formFields: any,
    formSubmitButton: any,
    formCancelButton: any,
    formForgotPassword: any,
    handleFormSubmit: any,
    handleForgotPassword: any,
    isInvalidEmail: any,
    isInvalidPassword: any,
    showCredentialsError: any,
    isDeactivatedUser: any,
    handleChange: any,
    isLogoutNotificationVisible: any,
    bannerCTA: IArticleTeaserBannerProps | undefined,
    formType: any,
    registerLink: any,
    isEnabled: boolean,
  ) => {
    if (!isEnabled) {
      return <></>
    }
    return (
      <div className="login-popup-content" key={formType}>
        <div className="login-popup-authform">
          <AuthForm
            isPaywallForm={false}
            title={formTitle}
            wrongCredentialsText={wrongCredentialsText}
            showDeactivatedUserMsg={isDeactivatedUser}
            deactivatedUserMessage={deactivatedUserMessage}
            fields={formFields}
            submitButton={formSubmitButton}
            cancelButton={formCancelButton}
            forgotPassword={formForgotPassword}
            registerLink={registerLink}
            onSubmit={handleFormSubmit}
            onForgotPassword={handleForgotPassword}
            isInvalidEmail={isInvalidEmail}
            isInvalidPassword={isInvalidPassword}
            showCredentialsError={showCredentialsError}
            onChange={handleChange}
            noValidate
            formType={formType}
          />
        </div>
        {!(formType === LOGIN_TYPES.DOC_CHECK) && bannerCTA && !isLogoutNotificationVisible && (
          <div className="login-popup-bannerContainer">
            <ArticleTeaserBanner
              className="login-popup-banner"
              articleBannerImage={bannerCTA.articleBannerImage}
              banner={bannerCTA.banner}
            />
          </div>
        )}
        {formType === LOGIN_TYPES.DOC_CHECK && !isLogoutNotificationVisible ? (
          <div className="login-popup-bannerContainer">
            <img
              src={registerLink?.image?.url}
              alt={registerLink?.image?.alt}
              width="452"
              height="296"
            />
          </div>
        ) : null}
      </div>
    )
  }

  const tabContents = loginTabs.map((item, index) => {
    switch (item.tabName) {
      case docCheckForm.title:
        return renderAuthForm(
          "",
          docCheckForm?.fields,
          docCheckForm.submitButton,
          docCheckForm.cancelButton,
          docCheckForm.forgotPassword,
          handleDocCheck,
          handleOnForgotPassword,
          isInvalidDocCheckEmail,
          isInvalidDocCheckPassword,
          showDocCheckCredentialsError,
          isDeactivatedUser,
          handleChange,
          logoutNotification,
          bannerCTA,
          LOGIN_TYPES.DOC_CHECK,
          docCheckForm.registerLink,
          item.isEnabled,
        )
      case solarLoginForm.title:
        return renderAuthForm(
          "",
          solarLoginForm.fields,
          solarLoginForm.submitButton,
          solarLoginForm.cancelButton,
          solarLoginForm.forgotPassword,
          handleOnSubmit,
          handleOnForgotPassword,
          isInvalidEmail,
          isInvalidPassword,
          showCredentialsError,
          isDeactivatedUser,
          handleChange,
          logoutNotification,
          bannerCTA,
          LOGIN_TYPES.SOLAR,
          solarLoginForm.registerLink,
          item.isEnabled,
        )
      default:
        return <div key={index}>{item.tabName}</div>
    }
  })

  const contentElement = () => {
    if (isSandozEnabled) {
      return (
        <Column mobile={12} tablet={6} desktop={6} className="paywall-cta-login">
          {/* To be updated with configurable content */}
          <RichText
            className="paywall-cta-paragraph"
            content="<p>Please login to access this page content</p>"
          />
          <div>
            <Button
              type="button"
              tabindex={0}
              onClick={() => {
                handleLoginRedirection()
              }}>
              {solarLoginForm?.submitButton?.text || "Login"}
            </Button>
          </div>
        </Column>
      )
    }
    return (
      <div className="spaceTop">
        <Tabs
          activeItem={selectedTab}
          tabHeadItems={tabHeadItems}
          tabContents={tabContents}
          tabHeadClassName="tabHeadClass"
        />
      </div>
    )
  }

  return (
    form?.formLogin && (
      <>
        {isInvalidPassword && (
          <Notification notificationType="error" displayTxt={passwordIncorrect} />
        )}
        {isInvalidEmail && (
          <Notification notificationType="error" displayTxt={emailIncorrectFormat} />
        )}
        {showCredentialsError && (
          <Notification notificationType="error" displayTxt={wrongCredentialsText} />
        )}
        {isDeactivatedUser && (
          <Notification notificationType="error" displayTxt={deactivatedUserMessage} />
        )}
        <Popup
          showPopup={showPopup}
          showOverlay={showOverlay}
          closeIcon={popup.closeIcon}
          spinnerIcon={popup.spinnerIcon}
          className={`login-popup ${popup.className ?? ""}`}
          handlePopupShow={handlePopupShow}>
          <>
            {logoutNotification && (
              <div className="logoutNotification">
                {" "}
                <span className="iconSpacing">
                  <Icon iconName="InfoIcon1" />
                </span>
                <div className="logoutInfo">{logoutNotification}</div>
              </div>
            )}
          </>
          {contentElement()}
        </Popup>
      </>
    )
  )
}

const mapDispatch = {
  addUserData: (user: any) => addUser(user),
  resetUserData: (user: any) => resetUser(user),
  setUserProfile: (profile: any) => setUserProfile(profile),
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) =>
    setUserStateGlobally(loggedIn, loggedInType),
  setSession: (session: string, csrf: string) => setSession(session, csrf),
  clearSession: () => clearSession(),
}

export default connect(null, mapDispatch)(LoginPopup)
