import ArticleTeaserBannerContent from "../../molecules/articleBannerContent"
import SmartImage from "../../molecules/smartImage"
import { IArticleTeaserBannerProps } from "./_article-banner.interface"
import { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"

const ArticleTeaserBanner = ({ banner, articleBannerImage }: IArticleTeaserBannerProps) => {
  const { applicationTheme } = useContext(ApplicationThemeContext)

  return (
    <div className={`article-banner ${banner?.className}`}>
      {articleBannerImage && (
        <div className="article-banner-image">
          <SmartImage {...articleBannerImage} />
          {banner?.disableShader ||
          applicationTheme === "rebranding" ||
          applicationTheme === "rebranding-japan" ||
          applicationTheme === "jubbonti" ||
          applicationTheme === "wyost" ||
          applicationTheme === "biosimilarsinbone" ||
          applicationTheme === "vagidonna" ? (
            <></>
          ) : (
            <div className="article-banner-content-shader" />
          )}
        </div>
      )}
      <div className="article-banner-content-wrapper container">
        <ArticleTeaserBannerContent className="article-banner-content" {...banner} />
      </div>
    </div>
  )
}

export default ArticleTeaserBanner
