import { Img } from "@atoms"
import { ILinkWithIconContentProps } from "./_linkWithIconContent.interface"


const LinkWithIconContent = ({ imgsrc, title }: ILinkWithIconContentProps) => (
  <div className="link-with-icon-content">
    {imgsrc && <Img className="link-with-icon-content-image" {...imgsrc} type="icon" />}
    <div className="link-with-icon-content-title">{title}</div>
  </div>
)

export default LinkWithIconContent
