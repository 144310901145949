import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."
import { getApiDomainAndLang } from "@utils/baseApi"
import moment from "moment"
import { renderMicroFooterMainMenu, renderMicroFooterSecondaryMenu } from "@templates/layout/_layout.data"

enum FOOTER_TYPE {
  NORMAL = "normal",
  LITE = "lite",
}

const getFooterLiteData = (data: any, liteFooterMenuData: any, liteFooterFlag: Boolean) => {
  const { lang } = getApiDomainAndLang()
  let today = new Date().toLocaleDateString(lang)
  today = moment(today).locale(lang).format("D.M.Y")
  const footerBottomSection = {
    footer_logo_link: "",
    logoLink: data.logoLink || null,
    footer_logo_alt: data.footer_logo_alt || "",
    paragraph: data.paragraph || null,
    copyRightText: data.copyRightText || null,
    footerText: data.footerText || null,
    date: `<p>${today}</p>` || null,
  }
  const footerSecondaryMenu: any = []
  liteFooterMenuData?.map((item: any) => {
    footerSecondaryMenu.push({
      title: item?.title,
      link: item?.link,
      targetType: item?.targetType,
    })
  })
  return {
    footerBottomSection,
    footerSecondaryMenu,
    liteFooterFlag,
  }
}

const getMicrositeFooter = (data: any) => {
  const microFooterFlag = data?.data?.attributes?.field_enable_microsite_footer ?? ""
  const microFooterTitle = data?.data?.attributes?.field_microsites_section_title ?? ""
  const microlinkData =
    data?.included?.filter((item: any) => item?.type === "paragraph--microsite_footer_logo") || []
  const microData: any = []
  microlinkData?.map((microdata: any) => {
    microData.push({
      imgSrc: microdata?.relationships?.field_microsite_logo?.data?.meta?.absolute_uri ?? "",
      alt: microdata?.attributes?.field_microsite_logo_alt_text ?? "",
      plainText: microdata?.attributes?.field_microsite_logo_plain_text ?? "",
      link: microdata?.attributes?.field_microsite_logo_link ?? "",
      height: microdata?.attributes?.field_microsite_logo_height,
      width: microdata?.attributes?.field_microsite_logo_width,
      urlTarget: microdata?.attributes?.field_microsite_link_target,
    })
  })
  return {
    microFooterData: microData,
    microFooterFlag,
    microFooterTitle,
  }
}

const getMicrositeFooterData = async (url: string) => {
  let micrositeFooterData = await getBlockData(url)
  const micrositeMainFooterData = micrositeFooterData?.data
  
  const footerType = micrositeMainFooterData?.attributes?.field_footer_type ?? ""
  const footerMainMenu =
    micrositeMainFooterData?.relationships?.field_footer_menu?.data?.meta?.menu_details ??
    ""
  const footerSecondaryData =
    micrositeMainFooterData?.relationships?.field_footer_secondary_menu?.data?.meta?.menu_details ??
    ""

  const logos =
    micrositeFooterData?.included?.filter(
      (item: any) => item?.type === "paragraph--social_logos",
    ) || []

  let socialIconData: any = []
  logos?.map((socialLogoData: any) => {
    socialIconData.push({
      logo:
        getNestedObject(socialLogoData, "relationships.field_social_logo.data.meta.absolute_uri") ||
        "",
      logoUrl:
        getNestedObject(socialLogoData, "relationships.field_social_logo.data.meta.absolute_uri") ||
        "",
      altText: getNestedObject(socialLogoData, "attributes.field_social_logo_alt_text") || "",
      link: getNestedObject(socialLogoData, "attributes.field_social_logo_link") || "",
      title: getNestedObject(socialLogoData, "attributes.field_social_logo_title") || "",
    })
  })

  const footerBottomSection = {
    logoLink:
      getNestedObject(
        micrositeMainFooterData,
        "relationships.field_footer_site_logo.data.meta.absolute_uri",
      ) || "",
    paragraph:
      getNestedObject(micrositeMainFooterData, "attributes.field_footer_site_description.value") ||
      "",
    copyRightText:
      getNestedObject(micrositeMainFooterData, "attributes.field_copyright_text.value") || "",
    footerText:
      getNestedObject(micrositeMainFooterData, "attributes.field_footer_secondary_text.value") ||
      "",
    socialIconData: socialIconData.filter((item: any) => item.logoUrl !== ""),
    follow_us: getNestedObject(micrositeMainFooterData, "attributes.field_follow_us") || "",
  }

  const footerNavigation = await renderMicroFooterMainMenu(footerMainMenu)
  const liteFooterFlag = footerType === FOOTER_TYPE.LITE

  const footerMicrosite = getMicrositeFooter(micrositeFooterData)

  const liteFooter = getFooterLiteData(footerBottomSection, footerNavigation, liteFooterFlag)

  return {
    footerType,
    footerBottomSection,
    footerNavigation,
    footerSecondaryMenu: await renderMicroFooterSecondaryMenu(footerSecondaryData),
    footerMicrosite,
    liteFooter,
  }
}

export default getMicrositeFooterData
