import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Icon, IconButton, Row } from "@atoms"
import getDirection from "@utils/direction"

const CarouselWrapper = (props: any) => {
  const parentClass = props.className
  const NextArrow = (nextArrowProps: any) => {
    const {
 className, style, onClick, className2,
} = nextArrowProps
    return (
      <div
        className={`carouselWrapper ${className} ${className2}`}
        style={{ ...style }}
        onClick={onClick}
      >
        <div className="slider-container-right-arrow-box" aria-label="next-btn">
          <IconButton tabindex={0} icon={<Icon iconName="ArrowRightIcon" />} ariaLabel="carousel button" />
        </div>
      </div>
    )
  }

  const PrevArrow = (prevArrowProps: any) => {
    const {
 className, style, onClick, className2,
} = prevArrowProps
    return (
      <div className={`${className} ${className2}`} style={{ ...style }} onClick={onClick}>
        <div className="slider-container-left-arrow-box" aria-label="left-btn">
          <IconButton tabindex={0} icon={<Icon iconName="ArrowLeftIcon" />} ariaLabel="carousel button" />
        </div>
      </div>
    )
  }

  const settings = {
    dots: true,
    slidesToShow: props?.slideToShow || 3,
    slidesToScroll: props?.slideToShow || 1,
    prevArrow: getDirection() === "rtl" ? <NextArrow /> : <PrevArrow />,
    nextArrow: getDirection() === "rtl" ? <PrevArrow /> : <NextArrow />,
    arrow: false,
    infinite: props?.infinite ?? true,
    swipeToSlide: true,
    accessibility: true,
    rtl: getDirection() === "rtl",

    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: props?.slideToShow || 3,
          slidesToScroll: props?.slideToShow || 3,
          dots: true,
          arrow: false,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: props?.slideToShowTablet || 2,
          slidesToScroll: props?.slideToShowTablet || 2,
          dots: true,
          arrow: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrow: false,
        },
      },
    ],
  }

  const isEnabled = parseInt(props?.children?.length) > parseInt(props.slideToShow)
  const isSingleSlide = props.slideToShow === 1

  if (isEnabled) {
    return (
      <div
        className={`slider-container ${parentClass || ""} ${
          isSingleSlide ? "single" : ""
        } is-enabled`}
      >
        <Slider {...settings}>{props?.children}</Slider>
      </div>
    )
  }

  return <Row>{props?.children}</Row>
}

export default CarouselWrapper
