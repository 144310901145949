import React, { useState } from "react"
import Icon from "@atoms/icon"
import { InputProps } from "./_input.interface"

const Input = (props: InputProps) => {
  const {
    ariaLabel,
    type,
    label,
    disabled,
    placeholder,
    icon,
    required = false,
    setTextInput,
    onChange,
    value,
    name,
    useStateValue = true,
    ...rest
  } = props
  const [showPassword, setShowPassword] = useState(false)
  const [val, setVal] = useState(value)
  const getClasses = () => {
    const classes: string[] = []
    classes.push("input")
    props.secondary ? classes.push("secondary") : classes.push("primary")
    if (props.className) {
      classes.push(props.className)
    }
    return classes.join(" ")
  }
  const getType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password"
    }
    return type
  }

  const onChangeHandler = (e: any) => {
    setVal(e.target.value)
    if (typeof onChange === "function") {
      onChange(e)
    }
    if (typeof setTextInput === "function" && type !== "filter") {
      setTextInput(e.target.value)
    }
  }
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      setShowPassword(!showPassword)
    }
  }

  const renderInput = (
    <input
      {...rest}
      className={getClasses()}
      tabIndex={0}
      type={getType()}
      required={required}
      placeholder={placeholder}
      name={name}
      value={useStateValue ? val : value}
      disabled={disabled}
      onChange={onChangeHandler}
      autoComplete="off"
      aria-labelledby={ariaLabel}
    />
  )

  const renderWithIcon = () => (
    <span
      role="switch"
      aria-checked="false"
      tabIndex={1}
      onClick={() => setShowPassword(!showPassword)}
      onKeyDown={handleKeyDown}
      className="input-withIcon">
      {showPassword ? <Icon iconName="viewIcon" /> : <Icon iconName="viewCloseIcon" />}
    </span>
  )

  const renderLabel = () => (
    <label
      aria-required={required}
      htmlFor={props?.id || label}
      className="input-withLabel"
      id={ariaLabel}>
      {label}
      {required && " *"}
    </label>
  )

  return (
    <>
      {label && renderLabel()}
      <span className="input-withIcon-group">
        {icon && renderWithIcon()}
        {renderInput}
      </span>
    </>
  )
}

export default Input
