import getRelatedItemsData from "@helpers/dataFunctions/getRelatedItemsData"
import getMediaLibData from "@helpers/dataFunctions/getMediaLibData"
import getMediaData from "@helpers/dataFunctions/getMediaLibraryDetailsData"
import getLocationData from "@helpers/dataFunctions/getLocationData"
import getPaywallData from "@helpers/dataFunctions/getPaywallData"
import getCompactStockTickerData from "@helpers/dataFunctions/getCompactStockTickerData"
import getCommonSearch from "@helpers/dataFunctions/getCommonSearch"
import getQuickLinkCardData from "@helpers/dataFunctions/getQuickLinkCardData"
import getGenericWebFormData from "@helpers/dataFunctions/getGenericWebFormData"
import getLatestNewsStoriesListData from "@helpers/dataFunctions/getLatestNewsStoriesListData"
import getMediaPlayerData from "@helpers/dataFunctions/getMediaPlayerData"
import getNewsLetterSignUp from "@helpers/dataFunctions/getNewsLetterSignUp"
import { TDataFunctionType, rest } from "./_flexibleApi.interface"
import getHeroBannerData from "../dataFunctions/getHeroBannerData"
import getArticleBannerData from "../dataFunctions/getArticleBannerData"
import getContactCardsData from "../dataFunctions/getContactCardsData"
import getEducationCardsData from "../dataFunctions/getEducationCardsData"
import getProductCardData from "../dataFunctions/getProductCardData"
import getArticleTeaserCompositionData from "../dataFunctions/getArticleTeaserCompositionData"
import getIconCardData from "../dataFunctions/getIconCardData"
import getTextBlockData from "../dataFunctions/getTextBlockData"
import getVideoCardData from "../dataFunctions/getVideoCardData"
import getSupportMaterialCardData from "../dataFunctions/getSupportMaterialCardData"
import getOpinionLeadersCardData from "../dataFunctions/getOpinionLeadersCardData"
import getGoogleMapData from "../dataFunctions/getGoogleMapData"
import getArticleTeaserGroupData from "../dataFunctions/getArticleTeaserGroupData"
import getServiceTeaserCardData from "../dataFunctions/getServiceTeaserCard"
import getScheduleData from "../dataFunctions/getScheduleData"
import getAccordionData from "../dataFunctions/getAccordionData"
import getEventVerticalCardData from "../dataFunctions/getEventVerticalCardData"
import getEventCalendarData from "../dataFunctions/getEventCalendarData"
import getInterestingCardsData from "../dataFunctions/getInterestingCardsData"
import getNewsStoriesListData from "../dataFunctions/getNewsStoriesListData"
import getDetailBlock from "../dataFunctions/getDetailBlock"
import getTherapyCards from "../dataFunctions/getTherapyCards"
import getSnackbiteData from "../dataFunctions/getSnackbiteData"
import getProductListCardData from "../dataFunctions/getProductListCardData"
import getButtonGroup from "../dataFunctions/getButtonGroup"
import getFlexibleTabsData from "../dataFunctions/getFlexibleTabsData"
import getRotatingHeroBannerData from "../dataFunctions/getRotatingHeroBannerData"
import getOtherNewsStories from "../dataFunctions/getOtherNewsStories"
import getQuickLinkData from "../dataFunctions/getQuickLinksData"
import getTestimonialData from "../dataFunctions/getTestimonialData"
import getRightSidePanelData from "../dataFunctions/getRightSidePanelData"
import getEventsFilterConfig from "../dataFunctions/getEventsFilterConfig"
import getStockTickerData from "../dataFunctions/getStockTickerData"
import getEventFilterFromData from "../dataFunctions/getEventFilterFromData"
import getImageBlockData from "../dataFunctions/getImageBlockData"
import getSpecialityFilterData from "../dataFunctions/getSpecialityFilterData"
import getWebFormData from "../dataFunctions/getWebFormData"
import getTeaserBlockData from "../dataFunctions/getTeaserBlockData"
import getRESTApiData from "../dataFunctions/getRestAPIData"
import getCompareTableData from "../dataFunctions/getCompareTableData"
import getStarRatingData from "../dataFunctions/getStarRatingData"
import getInlineQuotationData from "../dataFunctions/getInlineQuotations"
import getLinkIconData from "../dataFunctions/getLinkIconData"
import getPatientProfileData from "../dataFunctions/getPatientProfileData"
import getTeaserFeatureData from "../dataFunctions/getTeaserFeatureData"
import getWebStoreDetails from "../dataFunctions/getWebStoreDetails"
import getEmbedMapData from "@helpers/dataFunctions/getEmbedMapData"
import getSolarTableData from "@helpers/dataFunctions/getSolarTableData"
import getPrintButtonData from "@helpers/dataFunctions/getPrintButtonData"
import getIframeWrapperData from "@helpers/dataFunctions/getIframeWrapperData"
import getMicrositeFooterData from "@helpers/dataFunctions/getMicrositeFooterData"
import getMicroSiteBannerData from "@helpers/dataFunctions/getMicroSiteBannerData"
import getMicrositeEyebrowMenuData from "@helpers/dataFunctions/getMicrositeEyebrowMenuData"
import getPinProtectionData from "@helpers/dataFunctions/getPinProtectionData"
import getMsHeaderTopData from "@helpers/dataFunctions/getMicrositeHeaderTopData"
import getFlexibleCarouselData from "@helpers/dataFunctions/getFlexibleCarouselData"

const getBlockData = async (
  blockType: string,
  url: string,
  blockBackground: string,
  nid: number,
  targetid: number,
  data: any = {},
  serverData?: any,
) => {
  const blockDataFunction: TDataFunctionType = {
    rotating_hero_banner: getRotatingHeroBannerData,
    hero_banner: getHeroBannerData,
    quick_links: getQuickLinkData,
    quick_link_card: getQuickLinkCardData,
    testimonial: getTestimonialData,
    media_library_detail: getMediaData,
    media_library_search: getMediaLibData,
    location_list: getLocationData,
    article_teaser_banner: getArticleBannerData,
    contact_cards: getContactCardsData,
    education_overview_block: getEducationCardsData, //  advance filter
    productcards: getProductCardData,
    video_cards: getVideoCardData,
    article_teaser_composition: getArticleTeaserCompositionData,
    icon_cards_group: getIconCardData,
    text: getTextBlockData,
    support_material_teaser: getSupportMaterialCardData,
    opinion_leaders_teaser: getOpinionLeadersCardData,
    google_map: getGoogleMapData,
    article_teaser: getArticleTeaserGroupData,
    service_teaser_cards: getServiceTeaserCardData,
    interesting_cards: getInterestingCardsData,
    schedule_tabs: getScheduleData,
    accordion: getAccordionData,
    latest_nas: getLatestNewsStoriesListData,
    o_nas: getNewsStoriesListData, //  advance filter
    event_horizontal_cards: getEventCalendarData,
    event_vertical_cards: getEventVerticalCardData, //  advance filter
    education_product_card: getDetailBlock,
    therapy_cards: getTherapyCards, //  advance filter
    snackbite: getSnackbiteData,
    therapy_snackbite: getSnackbiteData,
    common_tab_group: getFlexibleTabsData,
    productlistcardblock: getProductListCardData, //  advance filter
    button_group: getButtonGroup,
    other_news_and_stories: getOtherNewsStories,
    product_card: getDetailBlock,
    right_side_panel: getRightSidePanelData,
    media_related_items: getRelatedItemsData,
    news_search: () => {
      return { url }
    },
    events_filter: getEventsFilterConfig,
    share_monitor: getStockTickerData,
    events_filter_form: getEventFilterFromData,
    image: getImageBlockData,
    speciality_filter: getSpecialityFilterData,
    sandoz_contact_us: getWebFormData,
    teaser_block: getTeaserBlockData,
    rest_data_export_link: getRESTApiData,
    paywall: getPaywallData,
    table_reference_column: getCompareTableData,
    star_rating: getStarRatingData,
    post_code_look_up: () => {
      return { url }
    },
    content_block_inline_quotation: getInlineQuotationData,
    menu_link_with_icon_group: getLinkIconData,
    persona_patient_profile: getPatientProfileData,
    job_search: () => {
      return {}
    },
    compact_stock_ticker: getCompactStockTickerData,
    search_bar: getCommonSearch,
    teaser_feature_block: getTeaserFeatureData,
    webform_block: getGenericWebFormData,
    related_content: (url) => {
      return { url }
    },
    flex_accordion: (url: string) => {
      return { url }
    },
    media_container: getMediaPlayerData,
    webstore_dp: getWebStoreDetails,
    webstore_card_list: (url: string) => {
      return { url }
    },
    newsletter_signup: getNewsLetterSignUp,
    map_inframe_integration: getEmbedMapData,
    solar_table_block: getSolarTableData,
    print_version_configuration: getPrintButtonData,
    buy_now_button_block: (url: string) => {
      return { url }
    },
    chart: (url: string) => {
      return { url }
    },
    video_gallery: (url: string) => {
      return { url }
    },
    quiz: (url: string) => {
      return { url }
    },
    iframe_block: getIframeWrapperData,
    footer: getMicrositeFooterData,
    eyebrow_menu: getMicrositeEyebrowMenuData,
    pin_protection: getPinProtectionData,
    isi_legal_disc_banner: getMicroSiteBannerData,
    header: getMsHeaderTopData,
    carousel: getFlexibleCarouselData,
    magazine_masonry_component: (url: string) => {
      return { url }
    },
    decision_tree: (url: string) => {
      return { url }
    }
  }

  const optionalParameters: rest = [nid, targetid, data, serverData]

  return blockType in blockDataFunction
    ? {
        type: blockType,
        background: blockBackground,
        data: await blockDataFunction[blockType as keyof TDataFunctionType](
          url,
          ...optionalParameters
        ),
      }
    : {}
}

export default getBlockData
