import { Button, Icon, RichText } from "@atoms"
import { useContext, useEffect } from "react"
import { IPaywallNotification } from "./_paywallNotification.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"

const PaywallNotification = ({ paywallNotice, roles, loginHandler }: IPaywallNotification) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { handleLoginRedirection } = useSandozIDAuth(applicationConfiguration)
  useEffect(() => {
    const loginLink = document.getElementById("login-paywall-notice")
    if (loginLink) {
      loginLink.onclick = () => handleClick()
    }
  }, [])
  const handleClick = () => {
    if (applicationConfiguration?.isSandozIDEnabled) {
      handleLoginRedirection()
    } else {
      loginHandler()
    }
  }
  const loginLink = `<span id='login-paywall-notice'><a class="anchor" role="button">${paywallNotice.loginText}</a></span>`
  const registerLink = `<a class="anchor" role="button" href=${paywallNotice.registerLink}>${paywallNotice.registerText}</a>`

  const noticeTxt = paywallNotice.description
    .replace("@[LOGIN_LINK]@", loginLink)
    .replace("@[REGISTER_LINK]@", registerLink)
    .replace("@[ROLES]@", roles)

  return (
    <div className="notifications">
      <div className="notifications-container">
        <div className="notifications-desc">
          <span className="notifications-container-icon">
            <Icon iconName="NotifyIcon" />
          </span>
          <div className="notifications-container-richtext">
            <RichText content={noticeTxt} />
          </div>
        </div>
        <Button tabindex={0} isSecondary={false} onClick={handleClick}>
          {paywallNotice.ctaLabel}
        </Button>
      </div>
    </div>
  )
}

export default PaywallNotification
