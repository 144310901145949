export const modifyRelatedContentData = (inputObj: any) => {
  if (inputObj.hasOwnProperty("taxonomyLabel")) {
    const { taxonomyLabel, ...rest } = inputObj
    return {
      ...rest,
      taxonomies: [
        {
          taxonomyLabel: inputObj?.taxonomyLabel,
        },
      ],
    }
  } else {
    return inputObj
  }
}
