import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getEmbedMapData = async (url: string, data?: any) => {
  let mapData = await getBlockData(url)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(mapData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(mapData)
  mapData = mapData?.data

  if (
    !mapData ||
    !mapData?.attributes?.field_google_iframe_url ||
    mapData?.attributes?.field_google_iframe_url?.length === 0
  ) {
    return {
      mapUrl: "",
      height: mapData?.attributes?.field_height_of_map ?? "",
      width: mapData?.attributes?.field_width_of_map ?? "",
    }
  }

  return {
    mapUrl: mapData?.attributes?.field_google_iframe_url?.[0] ?? "",
    height: mapData?.attributes?.field_height_of_map ?? "",
    width: mapData?.attributes?.field_width_of_map ?? "",
    isParentPaywalled,
    fieldPaywallRoles,
  }
}

export default getEmbedMapData
