import * as actionType from "../ActionTypes"

const initialProductFilter: any = {
  productFilter: [],
  searchText: "",
  order: "ASC",
  currentPage: 0,
}

const ProductReducer = (state: any = initialProductFilter, action: ProductFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_PRODUCT_FILTER:
      return {
        ...state,
        productFilter: action.productFilter,
        currentPage: 0,
      }

    case actionType.SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: action.searchText,
        currentPage: 0,
      }

    case actionType.SET_ORDER:
      return {
        ...state,
        order: action.order,
      }

    case actionType.SET_PAGE_NUMBER:
      return {
        ...state,
        currentPage: action.currentPage,
      }

    default:
      return state
  }
}

export default ProductReducer
