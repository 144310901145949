import React, { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { getAssetPrefixUrl } from "@utils/helper"
import Img from "@atoms/img"
import { ILoaderprops } from "./_loader.interface"

const Loader = (props: ILoaderprops) => {
  const { applicationTheme } = useContext(ApplicationThemeContext)

  const getLoadingIcon = (theme: string) => {
    if (
      theme === "rebranding" ||
      theme === "rebranding-japan" ||
      theme === "jubbonti" ||
      theme === "wyost" ||
      theme === "biosimilarsinbone" ||
      theme === "vagidonna"
    )
      return getAssetPrefixUrl("/assets/icons/Loader-rebranding.png")
    else return getAssetPrefixUrl("/assets/icons/loadingSpinner.gif")
  }

  return (
    <>
      {props.display === true ? (
        <span className="loading-spinner">
          <Img
            src={getLoadingIcon(applicationTheme)}
            alt="loader"
            height={50}
            width={50}
            type="icon"
          />
        </span>
      ) : null}
    </>
  )
}

export default Loader
