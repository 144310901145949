import { useRouter } from "next/router"

import { Anchor, Icon, IconButton, Img, RichText } from "@atoms"
import { IShopDescription } from "./_shopCardDescription.interface"

const ShopCardDescription = (props: IShopDescription) => {
  const router = useRouter()
  const {
    title,
    subTitle,
    description,
    detailUrl,
    type,
    productImgUrl,
    productStyledImgUrl,
    productAltText,
  } = props
  const getClasses = () => {
    const classes = ["shopcard-description"]
    if (type) {
      classes.push(`shopcard-description-${type}`)
    }
    return classes.join(" ")
  }
  const handleNavigation = () => {
    router.push(detailUrl)
  }
  return (
    <div className={getClasses()}>
      <div className={`image-order-material-${type}`}>
      {(productStyledImgUrl || productImgUrl) && (
        <Anchor
          href={detailUrl}
          tabindex={0}
          noUnderline={true}
          className="shopcard-description--image"
          title="shopcard-description">
          <Img
            type="product_catalogue"
            styledimage={productStyledImgUrl}
            src={productImgUrl ?? ""}
            alt={productAltText}
            height={366}
            width={366}
          />
        </Anchor>
      )}
      </div>
      <div className="shopcard-description--head-box">
        <div className="shopcard-description--head">
          {(productStyledImgUrl || productImgUrl) && (
            <Anchor
              href={detailUrl}
              tabindex={0}
              noUnderline={true}
              className="shopcard-description--head-image">
              <Img
                type="product_catalogue"
                styledimage={productStyledImgUrl}
                src={productImgUrl ?? ""}
                alt="product-image"
                height={50}
                width={50}
              />
            </Anchor>
          )}
          <div className="shopcard-description--container">
            {subTitle && (
              <span className="shopcard-description--subtitle">{subTitle}</span>
            )}
            <Anchor href={detailUrl} tabindex={0} noUnderline={true}>
              <h4 className="shopcard-description--title">{title}</h4>
            </Anchor>
          </div>
          <div className="shopcard-description--button" aria-label="shopcard button">
            <IconButton
              tabindex={0}
              icon={<Icon iconName="ArrowRightIcon" />}
              aria-label="shopcard button"
              onClick={handleNavigation}
              title="shopcard button"
            />
          </div>
        </div>
        {description && <RichText className="shopcard-description--desc" content={description} />}
      </div>
    </div>
  )
}
export default ShopCardDescription
