import { DropDown } from "@atoms"
import { IDropDownProps } from "@atoms/dropDown/_dropDown.interface"

const SimpleDropDown = (props: IDropDownProps) => (
  <div className="simpleDropDown">
    <DropDown {...props} />
  </div>
)

export default SimpleDropDown
