import { createContext } from "react"
import { ApplicationData } from "./applicationData"

export interface IContextProvider {
    applicationPageData: ApplicationData
    setApplicationPageData: (arg: ApplicationData) => void

    applicationConfiguration: ApplicationData
    setApplicationConfiguration: (arg: ApplicationData) => void

    applicationHeaderData: ApplicationData
    setApplicationHeaderData: (arg: ApplicationData) => void

    applicationComponentsData: ApplicationData
    setApplicationComponentsData: (arg: ApplicationData) => void
}

const ApplicationContext = createContext<IContextProvider>({} as IContextProvider)
export default ApplicationContext