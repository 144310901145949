import { ErrorBoundary } from "react-error-boundary"
import { ReactNode, useEffect, useState } from "react"
import { useErrorPageData } from "@helpers/hooks/useErrorPageData"
import FourZeroFour from "../../components/templates/fourzerofourpage"
import * as Sentry from "@sentry/nextjs"

type ErrorBoundaryProps = {
  children: ReactNode
  onCatch?: (error: Error, info: { componentStack: string }) => void
}

export const PageNotAvailable = () => {
  const [errorData, setErrorData] = useState<any>()
  const { fetchErrorPageData } = useErrorPageData()

  useEffect(() => {
    fetchErrorPageData().then((data: any) => {
      if (data) {
        const errorObj = {
          seo: data.seoData,
          errorData: {
            imgsrc: {
              src: data.apiFourOFourData?.image as string,
              alt: data.apiFourOFourData?.imageAlt,
            },
            cta: {
              tabindex: 1,
              children: data.apiFourOFourData?.ctaText,
              href: data.apiFourOFourData?.ctaLink,
              isExternal: false,
            },
            header: data.apiFourOFourData?.title,
            paragraph: data.apiFourOFourData?.desc,
          },
        }

        setErrorData(errorObj)
        Sentry.captureException(errorObj)
      }
    })
  }, [])

  return <>{errorData && <FourZeroFour currentTheme="default" {...errorData} />}</>
}

function ErrorFallback({ error }: { error: Error }) {
  if (process.env.NEXT_PUBLIC_ENV === "stage" || process.env.NEXT_PUBLIC_ENV === "prod") {
    return <PageNotAvailable />
  }
  Sentry.captureException(error)
  return (
    <div>
      <h2>Something went wrong:</h2>
      <p>{error.message}</p>
    </div>
  )
}

export default function AppErrorBoundary({ children, onCatch }: ErrorBoundaryProps) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onCatch}>
      {children}
    </ErrorBoundary>
  )
}
