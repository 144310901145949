import { useContext, useEffect, useState } from "react"
import { Button, Container } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IGlobalSearchResultProps } from "./_globalSearchResult.interface"
import Tabs from "../../molecules/tabs"
import Pagination from "../../molecules/pagination"
import QuickLinkCard from "@molecules/quickLinkCard"

const GlobalSearchResult = (props: IGlobalSearchResultProps) => {
  const { searchResults = [], searchedPhrase, itemsPerPage, countResults } = props
  const [selectedItem, setSelectedItem] = useState({ num: 0 })
  const [paginatePages, setPaginatePages] = useState({ start: 0, end: itemsPerPage })

  const applicationConfigContext = useContext(ApplicationContext)

  const handlePagination = (offSet: number) => {
    setPaginatePages({ start: offSet, end: offSet + itemsPerPage })
  }

  const handleClick = (index: number) => {
    setSelectedItem({ num: index })
  }

  const [searchData, setSearchData] = useState<any>({})

  useEffect(() => {
    const renderSearchText = async () => {
      const searchResultData = applicationConfigContext.applicationConfiguration.siteConfig
      setSearchData({
        search_go_to_result_global_text: searchResultData?.search_go_to_result_global_text ?? null,
        search_show_more_results_text: searchResultData?.search_show_more_results_text ?? null,
        search_results_for: searchResultData?.search_results_for ?? null,
        search_all: searchResultData?.search_all ?? null,
      })
    }
    renderSearchText()
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  const searchedPhraseNotCapital = searchedPhrase.toLowerCase()
  const regexp = new RegExp(searchedPhraseNotCapital, "g")
  const searchedPhraseCapital =
    searchedPhrase.charAt(0).toUpperCase() + searchedPhrase.slice(1).toLowerCase()
  const regexp2 = new RegExp(searchedPhraseCapital, "g")

  const formatStr = (str: string) => ({
    __html: String(str)
      .replaceAll(
        regexp,
        `<span class="globalSearchResult__spanSearchedPhrase">${searchedPhraseNotCapital}</span>`,
      )
      .replaceAll(
        regexp2,
        `<span class="globalSearchResult__spanSearchedPhrase">${searchedPhraseCapital}</span>`,
      ),
  })

  const tabHeadItems = [
    {
      children: `${searchData.search_all} (${countResults})`,
      itemID: searchData.search_all,
      tabDesign: "wide",
    },
  ].concat(
    searchResults.map((item) => ({
      children: `${item.category} (${item.results.length})`,
      itemID: item.category,
      tabDesign: "wide",
    })),
  )

  const tabContents = [
    <div key={0}>
      {searchResults?.map((item, index) => (
        <div key={item?.category} className="globalSearchResult__resultsCategoryContainer">
          <h2 className="globalSearchResult__resultsCategory">
            {`${item?.category} (${item?.results?.length})`}
          </h2>
          {item?.results?.slice(0, 4)?.map((result, indexResult) => (
            <div key={indexResult} className="globalSearchResult__searchResultContainer">
              <h3
                className="globalSearchResult__searchResultHeader"
                dangerouslySetInnerHTML={formatStr(result?.articleTitle)}
              />
              <p
                className="globalSearchResult__searchResultDesc"
                dangerouslySetInnerHTML={formatStr(result?.articleDesc)}
              />
              <QuickLinkCard cardInfo={{
                field_card_title: searchData?.search_go_to_result_global_text,
                field_card_link: result?.path,
                field_link_target: "_self"
              }}/>
            </div>
          ))}
          {item?.results?.length > NUMBERS.FOUR && (
            <Button
              tabindex={0}
              isSecondary
              className="globalSearchResult__searchResultButton padding-button"
              onClick={() => handleClick(index + 1)}>
              {searchData?.search_show_more_results_text}
            </Button>
          )}
        </div>
      ))}
    </div>,
  ].concat(
    searchResults?.map((item, index) => (
      <div key={index + 1} className="globalSearchResult__resultsCategoryContainer">
        {/* <h4 className="globalSearchResult__resultsCategory">{`${item.category} (${item.results.length})`}</h4> */}
        {item?.results?.slice(paginatePages?.start, paginatePages?.end)?.map((result, i) => (
          <div key={i} className="globalSearchResult__searchResultContainer">
            <h3
              className="globalSearchResult__searchResultHeader"
              dangerouslySetInnerHTML={formatStr(result?.articleTitle)}
            />
            <p
              className="globalSearchResult__searchResultDesc"
              dangerouslySetInnerHTML={formatStr(result?.articleDesc)}
            />
            <QuickLinkCard cardInfo={{
              field_card_title: searchData?.search_go_to_result_global_text,
              field_card_link: result?.path,
              field_link_target: "_self"
            }}/>
          </div>
        ))}
        {item?.results?.length > 10 && (
          <Pagination
            className={`pagination-item-${index}`}
            itemsPerPage={itemsPerPage}
            itemsCount={item?.results?.length}
            onClick={handlePagination}
          />
        )}
      </div>
    )),
  )

  return (
    <Container>
      <div className="globalSearchResult__container">
        <h1 className="globalSearchResult__header">
          {countResults} {searchData?.search_results_for}
          <span className="globalSearchResult__searchedPhrase">“{searchedPhrase}”</span>
        </h1>
        <Tabs
          tabHeadClassName="globalSearchResult__tabsContainer"
          tabHeadItems={tabHeadItems}
          tabContents={tabContents}
          activeItem={selectedItem}
        />
      </div>
    </Container>
  )
}

export default GlobalSearchResult
