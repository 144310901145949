import React from "react"
import { IFloatingContent } from "./_floatingContent.interface"
import RichText from "@atoms/richText"
import { Accordion } from "@molecules"

const FloatingContent = ({
  title,
  description,
  border,
  isAccordian,
  className,
  megaTitle,
}: IFloatingContent) => {
  return (
    <div className="floating-container">
      {megaTitle && <div> <RichText className="floatingContent-title" content={megaTitle} /></div>}
      <div className={border ? "floatingContent fine-print" : "floatingContent"}>
        {!isAccordian ? (
          <>
            {title && (
              <RichText
                className={border ? "fine-print-title" : "floatingContent-title"}
                content={title?.content}
              />
            )}
            {description && (
              <RichText
                className={"floatingContent-desc " + className}
                content={description?.content}
              />
            )}
          </>
        ) : (
          <Accordion accordionArray={[{ title: title?.content, content: description?.content }]} />
        )}
      </div>
    </div>
  )
}

export default FloatingContent
