import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Icon, RichText } from "@atoms"
import { Accordion } from "@molecules"
import { useMediaQuery, useOutsideClick } from "@helpers/hooks"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import { IFloatingModuleLegacy,IFloatingModuleLegacyData } from "./_floatingModuleLegacy.interface"

const FloatingModuleLegacy = ({ data, className }: IFloatingModuleLegacy) => {
  const {
    title,
    section1,
    section2,
    section3,
    copyrightBottomText,
    accordionData,
    expandedCoverageNumber,
  }: IFloatingModuleLegacyData = data || {}
  const currentPath = window.location.href
  const [isExpanded, setIsExpanded] = useState(false)
  const [isManualExpand, setIsManualExpand] = useState(false)
  const [isOutsideViewAtTheBottom, setIsOutsideViewAtTheBottom] = useState(false)
  const router = useRouter()
  const headerRef=useRef<any>(null)
  const isMobile = useMediaQuery("(max-width: 991px)")
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 991px)")

  let isAccordianDataAvailable = true
  accordionData?.forEach((a) => {
    isAccordianDataAvailable = a.title !== null && a.content !== null ? true : false
  })

  const headerHeight=()=>{
    if(isTablet) return headerRef?.current?.clientHeight - 8
    if(isMobile) return headerRef?.current?.clientHeight - 2
    return headerRef?.current?.clientHeight - 20
  }
  const getClasses = () => {
    const classes: string[] = ["floating-module-legacy"]
    if (className) classes.push(className)
    return classes.join(" ")
  }

  const handleClickOutside = () => {
    const path = window.location.href
    setIsManualExpand(false)
    if (!path.includes("#isi")) {
      setIsExpanded(false)
    }
  }
  const handleIsExpanded = () => {
    setIsManualExpand(true)
    if (isExpanded && currentPath.includes("#isi")) {
      router.push(
        {
          pathname: window.location.pathname,
        },
        undefined,
        { shallow: true },
      )
    }
    setIsExpanded(!isExpanded)
  }
  const refExpanded = useOutsideClick(handleClickOutside)

  useEffect(() => {
    const html = document.querySelector("html")
    if (html && isExpanded) html.classList.add("hiddenScroll")
    if (html && !isExpanded) html.classList.remove("hiddenScroll")
  }, [isExpanded])

  const { ref, inView, entry } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (entry) {
      if (entry.boundingClientRect.bottom < 0) {
        setIsOutsideViewAtTheBottom(true)
      } else {
        setIsOutsideViewAtTheBottom(false)
      }
    }
  }, [inView])

  useEffect(() => {
    if (!isManualExpand) {
      const isExpandedVal = currentPath.includes("#isi")
      if (!isExpanded) {
        setIsExpanded(isExpandedVal)
      }
    }
  })

  return (
    <>
      {(inView && !isExpanded) || isOutsideViewAtTheBottom ? null : (
        <div
          ref={refExpanded}
          style={{ height: isExpanded ? `${expandedCoverageNumber || "75"}vh` : "196px" }}
          className="floating-module-legacy sticky show">
          <div ref={headerRef} className="header" >
            <div
              className={`header-content floating-module-legacy-width ${
                isExpanded && "floating-module-legacy-width-expand"
              }`}>
              <div>{title}</div>
              <div className="header-icon-container">
                {isExpanded ? (
                  <Icon iconName="ChevronDown" className="header-icon" onClick={handleIsExpanded} />
                ) : (
                  <Icon iconName="ChevronUp" className="header-icon" onClick={handleIsExpanded} />
                )}
              </div>
            </div>
          </div>

          {!isExpanded ? (
            <div className="text text-sticky">
              <div className="text-2-columns floating-module-legacy-width">
                {section1?.richText && 
                  <div className="text-section1">
                    <RichText content={section1?.richText} />
                  </div>
                }

                {section2?.richText && 
                  <div className="text-section2">
                    <RichText content={section2?.richText} />
                  </div>
                }
              </div>
              <div className="text-section-accordion floating-module-legacy-width">
                {section3?.richText && <RichText content={section3?.richText} />}
                {isAccordianDataAvailable && <Accordion accordionArray={accordionData} />}
                {copyrightBottomText && <RichText content={copyrightBottomText} />}
              </div>
            </div>
          ) : (
            <div className="text text-sticky sticky expanded floating-module-legacy-width floating-module-legacy-width-expand"  style={{height: `calc(100% - ${headerHeight()}px)`}}>
              {section1?.richText && 
                <div className="text-section1">
                  <RichText content={section1?.richText} />
                </div>
              }
              {section2?.richText &&
                <div className="text-section2">
                  <RichText content={section2?.richText} />
                </div>
              }
              <div className="text-section-accordion">
                {section3?.richText && <RichText content={section3?.richText} />}
                {isAccordianDataAvailable && <Accordion accordionArray={accordionData} />}
                {copyrightBottomText && <RichText content={copyrightBottomText} />}
              </div>
            </div>
          )}
        </div>
      )}
      <div className={getClasses()} ref={ref}>
        <div className="header">
          <div className="header-content floating-module-legacy-width floating-module-legacy-width--static">
            <div>{title}</div>
          </div>
        </div>
        <div className="text">
          {section1?.richText && 
            <div className="text-section1 floating-module-legacy-width floating-module-legacy-width--static">
              <RichText content={section1?.richText} />
            </div>
          }

          {section2?.richText && 
            <div className="text-section2 no-space">
              <div className="floating-module-legacy-width">
                <RichText content={section2?.richText} />
              </div>
            </div>
          }

          <div className="text-section-accordion floating-module-legacy-width floating-module-legacy-width--static">
            {section3?.richText && <RichText content={section3?.richText} />}
            {isAccordianDataAvailable && <Accordion accordionArray={accordionData} />}
            {copyrightBottomText && <RichText content={copyrightBottomText} />}
          </div>
        </div>
      </div>
    </>
  )
}

FloatingModuleLegacy.propTypes = {
  data: PropTypes.object,
}

export default FloatingModuleLegacy