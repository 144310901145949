import { getPageBlocks } from "@helpers/dataFunctions"
import { getFourOFourData } from "@organisms/errorfourzerofour/_errorfourzerofour.data"
import { useCallback } from "react"

export const useErrorPageData = () => {
  const fetchErrorPageData = useCallback(async () => {
    const apiUrl = "/jsonapi/page/<lang>/error-404"
    const { pageBlocks, seoData, currentTheme } = await getPageBlocks(apiUrl)
    const apiFourOFourData = await getFourOFourData(pageBlocks?.page_not_found?.url)

    const isNullishObj: any = Object.values(apiFourOFourData).every((value) => {
      if (value === null) {
        return true
      }
    })

    return isNullishObj
      ? null
      : {
          apiFourOFourData,
          seoData,
          currentTheme,
        }
  }, [])

  return {
    fetchErrorPageData,
  }
}
