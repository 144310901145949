import NUMBERS from "@helpers/constants/numbers"

export function groupBy<T>(collection: T[], key: keyof T) {
  const groupedResult = collection.reduce((previous, current) => {
    if (!previous[current[key]]) {
      previous[current[key]] = [] as T[]
    }
    previous[current[key]].push(current)
    return previous
  }, {} as any)
  return groupedResult
}

export const getColsByPercentage = (percent: number) => {
  return percent ? Math.round((percent / NUMBERS.HUNDRED) * NUMBERS.TWELVE) : NUMBERS.TWELVE
}

export const filterByReference = (arr1: any, arr2: any) => {
  let res = []
  res = arr1.filter((el: any) => {
    return arr2.find((element: any) => {
      return element.question === el.name._text && element.answer === el.exchange._text
    })
  })
  return res
}

export default groupBy
