import { Icon, RichText } from "@atoms"
import { IDisplayMessageProps } from "./_displayMessage.interface"

const DisplayMessage = ({ displayMessageType, displayTxt, className }: IDisplayMessageProps) => {
  const message = <RichText content={displayTxt || ""} />
  const iconSet = {
    success: <Icon iconName="SuccessIcon1" />,
    error: <Icon iconName="WarningIcon1" />,
    warning: <Icon iconName="WarningIcon" />,
  }

  return (
    <div className={`form-msg form-${displayMessageType}-msg ${className ?? ""}`}>
      <span className="form-msg-icon">{iconSet[displayMessageType]}</span>
      {message}
    </div>
  )
}

export default DisplayMessage
