import { Dispatch } from "redux"
import { useDispatch } from "react-redux"
import { useCallback, useContext, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import {
  Anchor,
  AnchorButton,
  Column,
  Icon,
  Img,
  RichText,
  Row,
  SpecificationItem,
  Taxonomy,
} from "@atoms"
import { ISpecificationItem } from "@atoms/specificationitem/_specificationitem.interface"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import BookmarkWithButton from "../bookmarkWithButton"
import { IProductCardListViewContentProps } from "./_productCardListViewContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import AccordionFlexEnhanced from "@organisms/accordionFlexEnhanced"
import ButtonGroup from "@molecules/button-group"
import { useMediaQuery } from "@helpers/hooks"

const ProductCardListViewContent = ({
  id,
  productListViewImage,
  className,
  taxonomies,
  heading,
  specifications,
  href,
  hrefFamily,
  productLinkText,
  familyLinkText,
  isLinkExternal,
  buttonLabel,
  productCardImage,
  bookmark,
  description,
  relatedContentFlag,
  view,
  pimCTADisplay,
  pimCTADetails,
  basisCTALabel,
  basisTextPath,
  attachmentLabel,
  attachmentPath
}: IProductCardListViewContentProps) => {
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])
  const {
    applicationConfiguration: { isPimProduct = false },
  } = useContext(ApplicationContext)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const isMobile = useMediaQuery("(max-width: 991px)")

  const handleBookmark = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  const productId: number = parseInt(id)

  const getClasses = () => {
    const classes = ["card product-card"]
    if (className) {
      classes.push(className)
    }
    return classes.join(" ")
  }

  const showMore = (resetState?: string) => {
    const val = resetState ? false : !isExpanded
    setIsExpanded(val)
  }

  const buttonGroupCTA = (props: any) => (
    <ButtonGroup className="btn_group">
      {props &&
        props?.map((btn: any, index: number) => {
          return (
            <AnchorButton
              key={index}
              tabindex={1}
              href={btn?.url}
              icon={<Icon iconName="DownloadIcon" />}
              className="product-list-cta"
              download={true}
              isExternal={false}
              isSecondary>
              {btn?.label}
            </AnchorButton>
          )
        })}
    </ButtonGroup>
  )
  
  return (
    <div className={getClasses()}>
      <div className="card-body">
        <Row className="productCardListViewContent-image-wrapper">
          <div className="productCardListViewContent-image-view">
            {!productCardImage?.styledimage && !productCardImage?.src ? (
              <></>
            ) : (
              <Anchor href={href ?? ""} tabindex={0} noUnderline isExternal={isLinkExternal}>
                <Img
                  type="plp_list"
                  styledimage={(productCardImage?.src && isPimProduct) ? null : productCardImage.styledimage}
                  src={productCardImage.src ?? ""}
                  alt={productCardImage.alt ?? ""}
                  width={236}
                  height={236}
                  className="productCardListViewContent-image"
                  islazyloaded={!relatedContentFlag}
                />
              </Anchor>
            )}
          </div>
          <Column className="productCardListViewContent-taxo" desktop={10} tablet={10} mobile={10}>
            <>{taxonomies && <Taxonomy itemsToDisplay={2} taxonomies={taxonomies} />}</>
          </Column>
          <Column className="productCardListViewContent-cta" desktop={2} tablet={2} mobile={2}>
            {relatedContentFlag ? (
              <></>
            ) : (
              <BookmarkWithButton id={productId} {...bookmark} bookmarkCallback={handleBookmark} />
            )}
            <span className="productCardListViewContent-arrow">
              {href && !isPimProduct && (
                <Anchor
                  href={href}
                  tabindex={1}
                  noUnderline={true}
                  className="productCardListViewContent-arrow-link product-detail-link list"
                  isExternal={isLinkExternal}>
                  <span>
                    <Icon iconName="ArrowForward1" />
                  </span>
                </Anchor>
              )}
            </span>
          </Column>
        </Row>
        <Row className="productCardListViewContent-header-wrapper">
          <Column
            desktop={12}
            tablet={12}
            mobile={12}
            className="productCardListViewContent-header-block">
            <>
              {heading && (
                <Anchor noUnderline href={href ?? ""} tabindex={0} isExternal={isLinkExternal}>
                  <h3 className="productCardListViewContent-header bold">
                    {trimText(heading, CHARLIMIT.TITLE)}
                  </h3>
                </Anchor>
              )}
            </>
          </Column>
        </Row>
        <Row className="productCardListViewContent-spec-wrapper">
          <Column desktop={8} tablet={8} mobile={12} className="productCardListViewContent-column">
            {!productCardImage?.styledimage && !productCardImage?.src ? (
              <></>
            ) : (
              <div className="productCardListViewContent-image-padding">
                <Img
                  className="productCardListViewContent-list-image"
                  type="plp_list"
                  styledimage={productCardImage.styledimage}
                  src={productCardImage.src ?? ""}
                  alt={productCardImage.alt ?? ""}
                  width={80}
                  height={60}
                />
              </div>
            )}

            <>
              {description && (
                <p className="productCardListViewContent-desc">
                  <RichText content={description} characterLimit={CHARLIMIT.DESCRIPTION} />
                </p>
              )}
            </>
            <div className="productCardListViewContent-card">
              {!productCardImage?.styledimage && !productCardImage?.src ? (
                <></>
              ) : (
                <div className="list-img">
                  <Img
                    type="plp_list"
                    styledimage={ (productCardImage.src && isPimProduct) ? null : productCardImage.styledimage}
                    src={productCardImage.src}
                    alt={productCardImage.alt ?? ""}
                    width={56}
                    height={56}
                  />
                </div>
              )}

              {specifications?.length ? (
                specifications?.map((spec: ISpecificationItem, index: number) => (
                  <SpecificationItem
                    key={index}
                    className="productCardListViewContent-padding plp-view"
                    {...spec}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </Column>
          <>
            {isPimProduct && (
              <Column
                desktop={4}
                tablet={4}
                mobile={12}
                className="productCardListViewContent-bottom-link">
                <div className="productCardListViewContent-arrow-bottom">
                  {href &&
                    (typeof pimCTADisplay?.listing_page_leaflet_ctas_flag === "undefined" ||
                    (pimCTADetails?.length === 0 &&
                      pimCTADisplay.listing_page_leaflet_ctas_flag === 1)||
                      (pimCTADetails?.length === 0 &&
                        pimCTADisplay.listing_page_leaflet_ctas_flag === 0)) && (
                      <Anchor
                        href={href}
                        tabindex={1}
                        noUnderline={true}
                        className="productCardListViewContent-arrow-link product-detail-link"
                        isExternal={isLinkExternal}>
                        <span className="product-detail-link-text">
                          {isPimProduct ? productLinkText : buttonLabel}
                        </span>
                        <span>
                          <Icon iconName="ArrowForward1" />
                        </span>
                      </Anchor>
                    )}

                  {hrefFamily &&
                  isPimProduct &&
                  (typeof pimCTADisplay?.listing_page_leaflet_ctas_flag === "undefined" || (pimCTADetails?.length === 0 &&
                    pimCTADisplay.listing_page_leaflet_ctas_flag === 1)||
                    (pimCTADetails?.length === 0 &&
                      pimCTADisplay.listing_page_leaflet_ctas_flag === 0)) ? (
                    <Anchor
                      href={hrefFamily}
                      tabindex={1}
                      noUnderline={true}
                      className="productCardListViewContent-arrow-link product-detail-link"
                      isExternal={isLinkExternal}>
                      <span className="product-detail-link-text">{familyLinkText}</span>
                      <span>
                        <Icon iconName="ArrowForward1" />
                      </span>
                    </Anchor>
                  ) : (
                    <></>
                  )}
                  {basisTextPath &&
                  isPimProduct &&
                  (typeof pimCTADisplay?.listing_page_leaflet_ctas_flag === "undefined" || (pimCTADetails?.length === 0 &&
                    pimCTADisplay?.listing_page_leaflet_ctas_flag === 1)||
                    (pimCTADetails?.length === 0 &&
                      pimCTADisplay?.listing_page_leaflet_ctas_flag === 0)) ? (
                    <Anchor
                      href={basisTextPath}
                      tabindex={1}
                      noUnderline={true}
                      className="productCardListViewContent-arrow-link product-detail-link"
                      isExternal={isLinkExternal}>
                      <span className="product-detail-link-text">{basisCTALabel}</span>
                      <span>
                        <Icon iconName="ArrowForward1" />
                      </span>
                    </Anchor>
                  ) : (
                    <></>
                  )}
                  {attachmentPath &&
                  isPimProduct &&
                  (typeof pimCTADisplay?.listing_page_leaflet_ctas_flag === "undefined" || (pimCTADetails?.length === 0 &&
                    pimCTADisplay?.listing_page_leaflet_ctas_flag === 1)||
                    (pimCTADetails?.length === 0 &&
                      pimCTADisplay?.listing_page_leaflet_ctas_flag === 0)) ? (
                    <Anchor
                      href={attachmentPath}
                      tabindex={1}
                      noUnderline={true}
                      className="productCardListViewContent-arrow-link product-detail-link"
                      isExternal={isLinkExternal}>
                      <span className="product-detail-link-text">{attachmentLabel}</span>
                      <span>
                        <Icon iconName="ArrowForward1" />
                      </span>
                    </Anchor>
                  ) : (
                    <></>
                  )}
                </div>
              </Column>
            )}
          </>
        </Row>
        {isPimProduct &&
          view !== "grid" &&
          pimCTADisplay?.listing_page_leaflet_ctas_flag === 1 &&
          pimCTADetails?.length !== 0 && (
            <Row>
              <Column
                desktop={pimCTADetails && pimCTADetails?.length < 4 ? 8 : 12}
                tablet={pimCTADetails && pimCTADetails?.length < 4 ? 8 : 12}
                mobile={12}
                className="productCardListViewContent-column-button">
                {buttonGroupCTA(pimCTADetails)}
              </Column>
              <>
                {isPimProduct && (
                  <Column
                    desktop={pimCTADetails &&pimCTADetails?.length < 4 ? 4 : 12}
                    tablet={pimCTADetails && pimCTADetails?.length < 4 ? 4 : 12}
                    mobile={12}
                    className="productCardListViewContent-center-link">
                    <div className="productCardListViewContent-arrow-bottom">
                      {href && (
                        <Anchor
                          href={href}
                          tabindex={1}
                          noUnderline={true}
                          className="productCardListViewContent-arrow-link product-detail-link"
                          isExternal={isLinkExternal}>
                          <span className="product-detail-link-text">
                            {isPimProduct ? productLinkText : buttonLabel}
                          </span>
                          <span>
                            <Icon iconName="ArrowForward1" />
                          </span>
                        </Anchor>
                      )}

                      {hrefFamily && isPimProduct ? (
                        <Anchor
                          href={hrefFamily}
                          tabindex={1}
                          noUnderline={true}
                          className="productCardListViewContent-arrow-link product-detail-link"
                          isExternal={isLinkExternal}>
                          <span className="product-detail-link-text">{familyLinkText}</span>
                          <span>
                            <Icon iconName="ArrowForward1" />
                          </span>
                        </Anchor>
                      ) : (
                        <></>
                      )}

                      {basisTextPath ? (
                        <Anchor
                          href={basisTextPath}
                          tabindex={1}
                          noUnderline={true}
                          className="productCardListViewContent-arrow-link product-detail-link"
                          isExternal={isLinkExternal}>
                          <span className="product-detail-link-text">{basisCTALabel}</span>
                          <span>
                            <Icon iconName="ArrowForward1" />
                          </span>
                        </Anchor>
                      ) : (
                        <></>
                      )}
                      {attachmentPath ? (
                        <Anchor
                          href={attachmentPath}
                          tabindex={1}
                          noUnderline={true}
                          className="productCardListViewContent-arrow-link product-detail-link"
                          isExternal={isLinkExternal}>
                          <span className="product-detail-link-text">{attachmentLabel}</span>
                          <span>
                            <Icon iconName="ArrowForward1" />
                          </span>
                        </Anchor>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Column>
                )}
              </>
            </Row>
          )}
        <Row>
          <Column
            desktop={12}
            tablet={12}
            mobile={12}
            className="productCardListViewContent-column">
            <div className="productCardListViewContent-button">
              {pimCTADetails?.length &&
              pimCTADisplay &&
              pimCTADisplay.leaflet_cta_accordion_flag === 1 ? (
                <AccordionFlexEnhanced
                  selectedIndex={productId}
                  resetState={showMore}
                  accordionArray={[
                    {
                      title: isMobile
                        ? pimCTADisplay.leaflet_cta_accordion_label_mobile
                        : pimCTADisplay?.leaflet_cta_accordion_label_desktop,
                      content: <>{buttonGroupCTA(pimCTADetails)}</>,
                    },
                  ]}
                />
              ) : (
                buttonGroupCTA(pimCTADetails)
              )}
              {href && (
                <AnchorButton tabindex={1} href={href} isExternal={isLinkExternal}>
                  {isPimProduct ? productLinkText : buttonLabel}
                </AnchorButton>
              )}
              {hrefFamily && isPimProduct ? (
                <AnchorButton
                  tabindex={1}
                  isSecondary
                  href={hrefFamily}
                  isExternal={isLinkExternal}>
                  {familyLinkText}
                </AnchorButton>
              ) : (
                <></>
              )}
              {basisTextPath && isPimProduct ? (
                <AnchorButton
                  tabindex={1}
                  isSecondary
                  href={basisTextPath}
                  isExternal={isLinkExternal}>
                  {basisCTALabel}
                </AnchorButton>
              ) : (
                <></>
              )}
              {attachmentPath && isPimProduct ? (
                <AnchorButton
                  tabindex={1}
                  isSecondary
                  href={attachmentPath}
                  isExternal={isLinkExternal}>
                  {attachmentLabel}
                </AnchorButton>
              ) : (
                <></>
              )}
            </div>
          </Column>
        </Row>
      </div>
    </div>
  )
}
export default ProductCardListViewContent
