import { useState } from "react"
import { Column, Row } from "@atoms"
import AdvanceSearchFilterBar from "../../molecules/advanceSearchOptions"
import { IAdvanceSearchFilterProps } from "./_advanceSearchBar.interface"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"

const AdvanceSearchFilter = (props: IAdvanceSearchFilterProps) => {
  const [displayFilters, setDisplayFilters] = useState(false)
  const [isCleared, setIsCleared] = useState(false)
  const {
    advanceFilterData,
    filterTitle,
    filterText,
    isCareerSearch,
    labelItemsSelected,
    handleSearch,
    setClear,
    applyFiltertext,
    clearAllText,
    handleAdditionalFilters,
    applyAdditionalFilters,
    selectedFilters = {},
    currentSearchText = "",
  } = props
  const isMobile = useMediaQuery("(max-width: 991px)")
  const handleClear = () => {
    setIsCleared(!isCleared)
    setClear?.(isCleared)
  }
  const handleFilterBtnToggle = (x: boolean) => {
    setDisplayFilters(x)
  }

  const isCareerSearchClass = isCareerSearch ? "anchor-no-underline" : "anchor"

  return (
    <div className="search-filter-section scroll-top">
      {filterTitle && (
        <Row className="search-filter-head">
          <Column
            desktop={12}
            tablet={12}
            mobile={12}
            className="search-filter-head-container search-filter-top">
            {filterTitle ? (
              <div className="filter-title">
                <h4>{filterTitle}</h4>
                <p>{filterText} </p>
              </div>
            ) : (
              <></>
            )}
          </Column>
        </Row>
      )}
      <Row
        className={`search-filter-clear ${
          !displayFilters && isMobile && isCareerSearch ? "hideFilters" : ""
        }`}>
        <Column
          desktop={12}
          tablet={12}
          mobile={12}
          className="search-filter-clear-container search-filter-top">
          {clearAllText ? (
            <div className="filter-clear">
              <span
                tabIndex={0}
                role="button"
                className={isCareerSearchClass}
                onClick={handleClear}>
                {clearAllText}
              </span>
            </div>
          ) : (
            <></>
          )}
        </Column>
      </Row>
      <Row className="search-filter-action">
        <Column desktop={12} tablet={12} mobile={12}>
          <AdvanceSearchFilterBar
            {...advanceFilterData}
            labelItemsSelected={labelItemsSelected}
            handleFilterBtnToggle={handleFilterBtnToggle}
            handleSearch={handleSearch}
            isCleared={isCleared}
            isCareerSearch={!!isCareerSearch}
            handleClear={handleClear}
            applyFiltertext={applyFiltertext}
            clearAllText={clearAllText}
            handleAdditionalFilters={handleAdditionalFilters}
            selectedFilters={selectedFilters}
            applyAdditionalFilters={applyAdditionalFilters}
            currentSearchText={currentSearchText}
          />
        </Column>
      </Row>
    </div>
  )
}

export default AdvanceSearchFilter
