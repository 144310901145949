import { consoleError } from "@utils/error"
import { config } from "@utils/baseApi/config.external"
import NUMBERS from "@helpers/constants/numbers"
import { getApi } from "@utils/baseApi"
import { IWebFormData } from "./_api.interface"
import { getTaxonomy } from "."

const _createTopicsOptions = async (topicsData: any, serverData: any) => {
  const topicsOptions = topicsData?.topics
    ? Object?.entries(topicsData.topics).map(([optionKey, optionValue], index) => ({
        id: optionKey,
        value: optionKey,
        label: optionValue,
        isDisabled: false,
        isSelected: false,
        key: optionKey,
      })) ?? []
    : []

  if (topicsOptions.length === 0) {
    const taxonomies = await getTaxonomy("/jsonapi/taxonomy_term/topics", true, serverData)
    const filteredOptions: any[] = []

    if (taxonomies && Array.isArray(taxonomies)) {
      taxonomies?.forEach((option: any, key: number) => {
        filteredOptions.push({
          id: option.taxonomyID,
          value: option.taxonomyID,
          label: option.taxonomyLabel,
          isDisabled: false,
          isSelected: false,
          key: option.taxonomyID,
        })
      })
      return filteredOptions
    }
  }

  return topicsOptions
}

const getNewsLetterSignUp = async (url: string, ...optionalParameters: any[]) => {
  const topicsData = optionalParameters?.[2] // this is to get data
  const serverData = optionalParameters?.[3] // accepting serverData only

  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getNewsLetterSignUp.name}.`)
  }

  const rawNewsLetterSignUpForm = await getApi(url, false, serverData)

  const notificationMessages = {
    error_message: rawNewsLetterSignUpForm?.error_message?.["#message_message"] || "",
  }

  const allButtonLabel = rawNewsLetterSignUpForm?.topics?.["#default_value"]?.[0] || ""

  const topicsOptions = await _createTopicsOptions(topicsData, serverData)

  if (topicsOptions.length > 1 && allButtonLabel !== "") {
    topicsOptions.unshift({
      id: NUMBERS.NEWSLETTER_SIGNUP_VALUE,
      value: allButtonLabel,
      label: allButtonLabel,
      isDisabled: false,
      isSelected: false,
      key: NUMBERS.NEWSLETTER_SIGNUP_VALUE,
    })
  }

  topicsOptions?.sort((a, b) => a?.label?.localeCompare(b?.label))

  const getSectionValues = (val: IWebFormData | any) => {
    switch (val["#type"]) {
      case "select":
        return [
          {
            type: "text",
            title: val["#description"] ?? "",
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
            label: val["#title"] ?? "",
          },
          {
            type: val["#type"],
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
            placeholder: val["#empty_option"] ?? "",

            options:
              Object.entries(val["#options"] || {}).map(([key, value], index) => ({
                key,
                value,
                id: index,
                label: value,
              })) ?? [],
            multiple: val["#multiple"] ?? false,
            class: val["#attributes"]?.class.includes("custom-button-element") || false,
            label: val["#title"] ?? "",
          },
        ]
      case "textfield":
      case "tel":
      case "email": {
        const data = [
          {
            type: "text",
            title: val["#description"] ?? "",
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
          },
          {
            type: val["#type"] ?? "text",
            label: val["#title"] ?? "",
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
            validations: {
              mandatory: {
                required: val["#required"] ?? false,
                message: val["#required_error"] ?? "",
              },
              pattern: {
                required: !!val["#pattern"] ?? false,
                regEx: val["#pattern"] ?? "",
                message: val["#pattern_error"] ?? "",
              },
              minLength: {
                required: val["#counter_minimum"] ?? false,
                length: val["#counter_minimum"] ?? null,
                message: val["#counter_maximum_message"] ?? "",
              },
            },
            validationErrorMessage: val["#required_error"] ?? "",
            maxLength: val["#maxlength"] || val["#counter_maximum"] || null,
            inputField: {
              name: val["#webform_key"],
              placeholder: val["#placeholder"] ?? "",
              required: val["#required"] ?? "",
            },
          },
        ]
        return data
      }
      case "checkbox": {
        const data = {
          type: val["#type"],
          label: val["#description"] ?? "",
          id: val["#webform_id"],
          value: val["#description"] ?? "",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
          isDisabled: false,
          isChecked: false,
          isRequired: val["#required"] ?? false,
        }
        return data
      }
      case "webform_actions":
        return {
          text: val["#submit__label"] ?? "",
          url: val["#submit_url"] ?? "#",
          type: "button",
          role: "submit",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
        }
      case "label": {
        const data = {
          text: val["#title"] ?? "",
          url: "#",
          type: "button",
          role: "reset",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
        }
        return data
      }

      case "webform_term_select": {
        return [
          {
            type: "text",
            title: val["#description"] ?? "",
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
          },
          {
            type: val["#type"],
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
            options: topicsOptions,
            multiple: val["#multiple"] ?? false,
          },
        ]
      }
      case "custom_recaptcha_element": {
        const data = {
          type: val["#type"] ?? "",
          title: val["#title"] ?? "",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
          recaptchaSiteKey: config?.recapchaKey ?? "",
          isDisabled: val["#enable_captcha"] === NUMBERS.ZERO,
        }
        return data
      }

      default:
        return { type: val["#type"] ?? "text" }
    }
  }

  if (!rawNewsLetterSignUpForm) {
    return []
  }

  const objectArray = Object.entries(rawNewsLetterSignUpForm).reduce((accumulator, [, value]) => {
    if (value && typeof value === "object") {
      const returnValue = getSectionValues(value)
      if (Object.keys(value).includes("#attributes")) {
        // @ts-ignore
        value["#attributes"]?.class?.forEach((item) => {
          if (item.indexOf("custom-element-step") >= 0) {
            const attributeKey = item
            // @ts-ignore
            if (!accumulator[attributeKey]) {
              // @ts-ignore
              accumulator[attributeKey] = []
            }
            // @ts-ignore
            accumulator[attributeKey].push(returnValue)
          }
        })
      }
    }
    return accumulator
  }, {})

  const webformData: any[] = []
  Object.values(objectArray).forEach((item) => {
    if (Array.isArray(item)) {
      webformData.push(item.flat())
    } else {
      return webformData.push(item)
    }
  })

  return { sections: webformData, notificationMessages }
}

export default getNewsLetterSignUp
