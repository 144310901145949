import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getScheduleData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let scheduleData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(scheduleData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(scheduleData)
  scheduleData = isPaywallProtected(scheduleData?.data?.attributes?.field_tabs)
    .isPaywallProtectedFlag
    ? []
    : scheduleData?.data?.attributes?.field_tabs ?? []
  return {
    scheduleData,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getScheduleData
