import { useContext, useEffect, useState } from "react"
import { Button, Input } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import ApplicationContext from "@utils/application-context/applicationContext"
import { ISearchGroupProps } from "./_search.interface"
import { useRouter } from "next/router"

const SearchGroup = (props: ISearchGroupProps) => {
  const {
    inputDataContent,
    className,
    buttonType,
    handleTextSearch,
    handleTextChange = (text: string) => null,
    currentSearchText = "",
    inputLabel,
    customButtonText,
  } = props
  const [textInput, setTextInput] = useState<string>(currentSearchText)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const applicationConfigContext = useContext(ApplicationContext)
  const router = useRouter()
  const queryParams = router?.query?.search?.toString()

  useEffect(() => {
    if (textInput?.trim().length < NUMBERS.THREE) {
      setIsButtonDisabled(true)
    } else {
      setIsButtonDisabled(false)
    }
  }, [textInput])

  useEffect(() => {
    setTextInput(currentSearchText)
  }, [currentSearchText])

  useEffect(() => {
    handleTextChange(textInput)
  }, [textInput])

  useEffect(() => {
    if (queryParams && props.isMedia) {
      setTextInput(queryParams)
    }
  }, [queryParams])

  const [searchData, setSearchData] = useState<any>({})
  useEffect(() => {
    const renderSearchText = () => {
      const searchDataText = applicationConfigContext.applicationConfiguration.siteConfig
      setSearchData({
        search_title: customButtonText || (searchDataText?.search_title ?? null),
      })
    }
    renderSearchText()
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  const handleSearch = (e: any) => {
    if (props.isMedia) {
      const currentUrl=router.pathname
      const queryParams={...router.query,search:textInput}
      router.push({pathname:currentUrl,query:queryParams},undefined,{shallow:true})
    }
    handleTextSearch(textInput, e)
    const trackSearchEvent = {
      search_term: textInput,
    }
    dataLayerPush(EVENTS.SEARCH, trackSearchEvent)
  }

  return (
    <div className={`search-group-no-padding ${className}`}>
      <form className="search-group" onSubmit={handleSearch}>
        <label id="search" htmlFor="search" aria-label="search">
          {" "}
          {inputLabel}
        </label>
        <Input
          id="search"
          className={`search-group-input-align search-box ${
            textInput === "" ? "search-group-input-align search-box-searchicon" : ""
          }`}
          value={textInput}
          type="search"
          useStateValue={false}
          placeholder={inputDataContent?.placeholder}
          setTextInput={setTextInput}
        />
        <div className="search-group-space-between" />
        <Button
          className="search-group-button-align"
          tabindex={1}
          type={buttonType || "submit"}
          onClick={handleSearch}
          isDisabled={isButtonDisabled}>
          {searchData.search_title}
        </Button>
      </form>
    </div>
  )
}

export default SearchGroup
