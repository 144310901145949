import { Button, Column, Container, Row } from "@atoms"
import { connect } from "react-redux"
import { isExternalLink } from "@helpers/dataFunctions"
import { useContext, useEffect, useState } from "react"
import { resetUser, setUserProfile } from "store/actions/ActionCreators"
import { getRatingsCount, resetRatings } from "store/actions/RatingsActionCreators"
import { getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import useUserProfile from "@helpers/hooks/useUserProfile"
import { config } from "@utils/baseApi/config.external"
import moment from "moment"
import ProductCardListViewContent from "@molecules/productCardListViewContent"
import { IProductCardListViewContentProps } from "@molecules/productCardListViewContent/_productCardListViewContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IMagazineCardProps } from "@organisms/magazineCard/_magazineCard.interface"
import {
  CarouselWrapper,
  EducationCard,
  EventCardVertical,
  MagazineCard,
  NewsStoriesCard,
} from "@organisms"
import { IEventCardVerticalProps } from "../../organisms/eventCardVertical/_eventCardVertical.interface"
import { IEducationCardProps } from "../../organisms/educationCard/_educationCard.interface"
import { INewsStoriesCardProps } from "../../organisms/newsStoriesCard/_newsStoriesCard.interface"
import { IProfileBookmarksCategory } from "./_profileBookmarksCategory.interface"
import { setUserStateGlobally } from "store/actions/CommonActionCreators"

interface DispatchProps {
  resetRatings: (ratings: any) => void
  setUserProfile: (profile: any) => void
  resetUserAndProfile: (user: any) => void
  updateRatingsCount: (ratings: any) => void
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) => void
}

type StateTypes = {
  user: any
  ratings: any
}

type Props = DispatchProps & StateTypes & IProfileBookmarksCategory

const ProfileBookmarksCategory = (props: Props) => {
  const {
    ratings,
    resetUserAndProfile,
    updateRatingsCount,
    resetRatings,
    user,
    setUserProfile,
    labels,
    show_in_card,
    setUserStateGlobally,
  } = props

  const userBookMarks = user.profile?.response?.bookmark || []

  const [eventsData, setEventsData] = useState<any>([])
  const [newsAndStoriesData, setNewsAndStoriesData] = useState<any>([])
  const [productsData, setProductsData] = useState<any>([])
  const [educationsData, setEducationsData] = useState<any>([])
  const [showAllEducation, setShowAllEducation] = useState<boolean>(false)
  const [showAllEvents, setShowAllEvents] = useState<boolean>(false)
  const [showAllProducts, setShowAllProducts] = useState<boolean>(false)
  const [showAllNews, setShowAllNews] = useState<boolean>(false)
  const [magazineData, setMagazineData] = useState<any>([])
  const [showAllMagazine, setShowAllMagazine] = useState<boolean>(false)
  const [configData, setConfigData] = useState<any>({})
  const applicationConfigContext = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  const isPimProduct: boolean | undefined =
    applicationConfigContext?.applicationConfiguration?.isPimProduct

  // / *********** Ratings check ***************
  const { fetchUserProfile, fetchUserRatingsCount } = useUserProfile()

  const getUserRatingsCount = async () => {
    const countResponse = await fetchUserRatingsCount()
    if (!countResponse.fail) {
      updateRatingsCount(countResponse.response)
    } else {
      console.log("Failed to fetch ratings count!")
    }
  }

  const updateBookMarks = async (id: number, type: string, state: boolean) => {
    const ratingsResponse = await middlewarePostAPI(config.onPrem.RATINGS_API_URL, {
      entity_id: id,
      entity_type: "path_alias",
      flag_id: type,
      //  uid: user?.user?.response?.session_data?.user_data?.current_user?.uid,
      flag_action: !state ? "flag" : "unflag",
    })

    // remove ratings data from store
    resetRatings({ id: undefined })

    if (!ratingsResponse.fail) {
      // success update card & update user profile
      console.log("Rating added successfully!")
      const profileResponse = await fetchUserProfile()
      if (!profileResponse.fail) {
        setUserProfile(profileResponse)
        getUserRatingsCount()
      } else {
        resetUserAndProfile({})
        setUserStateGlobally(false, "")
      }
    } else {
      // bookmark failed
      console.log("Failed to rate!")
    }
  }
  // get specification data  after comapre with configuration  data
  const getSpecification = (bookmark: any) => {
    const specifications = []
    for (let j = 0; j < show_in_card.length; j++) {
      if (bookmark[show_in_card[j]] !== null && bookmark[show_in_card[j]] !== undefined) {
        specifications.push({
          title: bookmark[show_in_card[j]]?.title ?? null,
          description: bookmark[show_in_card[j]]?.vid ?? null,
        })
      }
    }
    return specifications
  }
  useEffect(() => {
    if (ratings.ratings && ratings.ratings?.id) {
      if (user && user.isLoggedIn) {
        // do rating API call
        updateBookMarks(
          ratings.ratings?.id,
          ratings.ratings?.state?.type,
          ratings.ratings?.state.isChecked,
        )
      } else {
        // show login popup
      }
    }
  }, [ratings])
  // ****************** Ratings end ***********

  useEffect(() => {
    setConfigData(applicationConfigContext.applicationConfiguration.siteConfig)
  }, [applicationConfigContext.applicationConfiguration.siteConfig])

  useEffect(() => {
    const filteredBookmarks: { [key: string]: any[] } = {
      Event: [],
      "CSV Product": [],
      "News and Stories": [],
      "PIM CSV Product": [],
      Education: [],
      Magazine: [],
    }
    userBookMarks.forEach((bookmark: any) => {
      const { type } = bookmark
      filteredBookmarks[type]?.push(bookmark)
    })

    const eventsBookMarkData = filteredBookmarks.Event.map((bookmark: any) => ({
      eventCardVerticalContent: {
        id: parseInt(bookmark.node_id, 10),
        text: bookmark.body,
        heading: bookmark.title,
        startDate: bookmark.event_start_date,
        endDate: bookmark.event_start_date,
        contentText: bookmark.location,
        primaryButtonLabel: bookmark.cta_lable,
        primaryButtonUrl: bookmark.primery_link,
        primaryIsExternalUrl: isExternalLink(bookmark.primery_link),
        secondaryButtonLabel: bookmark.secondary_link_cta,
        secondaryButtonUrl: bookmark.secondary_link,
        secondaryIsExternalUrl: isExternalLink(bookmark.secondary_link),
        taxonomyLabel: bookmark.tag,
        time: bookmark.duration,
        bookmark: true,
      },
      eventCardVerticalImage: {
        src: bookmark.image,
      },
      eventCardVDesktopColumn: 12,
    }))
    const productsBookMarkData = filteredBookmarks["CSV Product"].map((bookmark: any) => ({
      id: parseInt(bookmark.node_id, 10) ?? null,
      taxonomyLabel: bookmark?.product_catgeogry?.[0]?.vid ?? null,
      heading: bookmark?.product_title ?? "",
      description: bookmark?.body ?? "",
      prodInfoTaxonomies: [],
      packInfoTaxonomies: [],
      productListViewImage: {
        src: bookmark?.image ?? null,
        alt: bookmark?.image_alt ?? "",
      },
      productCardImage: {
        src: bookmark?.image ?? null,
        alt: bookmark?.image_alt ?? "",
      },
      specifications: getSpecification(bookmark),
      buttonLabel: configData?.data?.product_list_button_label_text ?? null,
      isLinkExternal: isExternalLink(bookmark.path_alias),
      href: bookmark?.path_alias || null,
      bookmark: true,
    }))

    const pimProductsBookMarkData = filteredBookmarks["PIM CSV Product"].map((bookmark: any) => ({
      id: parseInt(bookmark.node_id, 10) ?? null,
      taxonomyLabel: bookmark?.product_catgeogry?.[0]?.vid ?? null,
      heading: bookmark?.product_title ?? "",
      description: bookmark?.body ?? "",
      prodInfoTaxonomies: [],
      packInfoTaxonomies: [],
      productListViewImage: {
        src: bookmark?.image && bookmark?.image[0]?.src,
        alt: bookmark?.image && bookmark?.image[0]?.alt,
      },
      productCardImage: {
        src: bookmark?.image && bookmark?.image[0]?.src,
        alt: bookmark?.image && bookmark?.image[0]?.alt,
      },
      specifications: getSpecification(bookmark),
      buttonLabel: configData?.data?.product_list_button_label_text ?? null,
      isLinkExternal: isExternalLink(bookmark.path_alias),
      href: bookmark?.path_alias || null,
      bookmark: true,
    }))

    const newsAndStoriesBookMarkData = filteredBookmarks["News and Stories"].map(
      (bookmark: any) => ({
        imageSrc: bookmark.image,
        newsStoriesContent: {
          id: parseInt(bookmark.node_id, 10),
          text: bookmark.body,
          heading: bookmark.title,
          buttonLabel: bookmark.cta_lable,
          buttonUrl: bookmark.path_alias,
          bookmark: {
            addText: "Add Bookmark",
            removeText: "Remove Bookmark",
            bookmarkStatus: false,
            isButton: false,
          },
          isButtonUrlExternal: isExternalLink(bookmark.path_alias),
          taxonomies: {
            taxonomyLabel: bookmark.Specialty || bookmark.tag,
          },
          date: bookmark.created,
        },
      }),
    )

    const educationsBookMarkData = filteredBookmarks.Education.map((bookmark: any) => ({
      educationCardContent: {
        id: parseInt(bookmark.node_id, 10),
        taxonomyLabel: bookmark.tag,
        heading: bookmark.title,
        text: bookmark.body,
        time: bookmark.duration,
        contentType: bookmark.tag,
        buttonLabel: bookmark.cta_lable,
        buttonUrl: bookmark.path_alias,
        bookmark: true,
        buttonUrlExternal: true,
      },
      imageSrc: bookmark.image,
      imgAlt: "sss",
    }))

    const magazineBookMarkData = filteredBookmarks.Magazine.map((bookmark: any) => ({
      id: parseInt(bookmark.node_id, 10),
      magImage: {
        src: bookmark.image,
        alt: bookmark.title,
      },
      isVideo: bookmark.magz_type,
      taxonomy: bookmark.taxonomy,
      href: bookmark.path_alias,
      title: bookmark.title,
      subtitle: bookmark.subtitle,
      // desc: bookmark.desc,
      desc: "",
      createdDate: moment(bookmark.magz_date).locale(lang).format("YYYY-MM-DDTHH:mm:ss+00:00"),
      time: bookmark.time,
      bookmark: {
        addText: "Add to Bookmark",
        removeText: "Remove from Bookmark",
        bookmarkStatus: true,
        isButton: false,
      },
    }))

    setEventsData(eventsBookMarkData)
    setNewsAndStoriesData(newsAndStoriesBookMarkData)
    setEducationsData(educationsBookMarkData)
    setProductsData(isPimProduct ? pimProductsBookMarkData : productsBookMarkData)
    setMagazineData(magazineBookMarkData)
  }, [userBookMarks, user, configData])

  const getClasses = () => {
    const classes = []
    classes.push("profileBookmarksCategory")
    return classes.join(" ")
  }

  return (
    <Container className={getClasses()}>
      <h1 className="section-heading">{labels.myBookMarks}</h1>

      {eventsData && eventsData.length > 0 && (
        <Row className="rowProfile">
          <h2 className="header">
            {labels.events} ({eventsData.length})
          </h2>

          {eventsData?.length > 3 && !showAllEvents ? (
            <>
              <CarouselWrapper slideToShow={3}>
                {eventsData.map((cards: IEventCardVerticalProps, _key: number) => (
                  <EventCardVertical key={_key} {...cards} />
                ))}
              </CarouselWrapper>
              <div className="show-all">
                <Button
                  className="show-more"
                  onClick={() => setShowAllEvents(true)}
                  tabindex={1}
                  isSecondary={true}>
                  Show More
                </Button>
              </div>
            </>
          ) : (
            <div className="events-grid">
              {" "}
              {eventsData.map((cards: IEventCardVerticalProps, _key: number) => (
                <EventCardVertical key={_key} {...cards} />
              ))}
            </div>
          )}
        </Row>
      )}

      {productsData && productsData.length > 0 && (
        <Row className="rowProfile">
          <h2 className="header">
            {labels.products} ({productsData.length})
          </h2>
          {productsData.length > 3 && !showAllProducts ? (
            <>
              <CarouselWrapper slideToShow={3}>
                {productsData?.map(
                  (productCards: IProductCardListViewContentProps, _key: number) => (
                    <ProductCardListViewContent key={_key} {...productCards} />
                  ),
                )}
              </CarouselWrapper>
              <div className="show-all">
                <Button
                  className="show-more"
                  onClick={() => setShowAllProducts(true)}
                  tabindex={1}
                  isSecondary={true}>
                  Show More
                </Button>
              </div>
            </>
          ) : (
            <div className="events-grid">
              {productsData?.map((cards: IProductCardListViewContentProps, _key: number) => (
                <ProductCardListViewContent key={_key} {...cards} />
              ))}
            </div>
          )}
        </Row>
      )}

      {newsAndStoriesData && newsAndStoriesData.length > 0 && (
        <Row className="rowProfile">
          <h2 className="header">
            {labels.newsandStories} ({newsAndStoriesData.length})
          </h2>
          {newsAndStoriesData?.length > 3 && !showAllNews ? (
            <>
              <CarouselWrapper slideToShow={3}>
                {newsAndStoriesData?.map((cards: INewsStoriesCardProps, _key: number) => (
                  <NewsStoriesCard key={_key} {...cards} />
                ))}
              </CarouselWrapper>
              <div className="show-all">
                <Button
                  className="show-more"
                  onClick={() => setShowAllNews(true)}
                  tabindex={1}
                  isSecondary={true}>
                  Show More
                </Button>
              </div>
            </>
          ) : (
            <>
              {newsAndStoriesData?.map((cards: INewsStoriesCardProps, _key: number) => (
                <NewsStoriesCard key={_key} {...cards} />
              ))}
            </>
          )}
        </Row>
      )}

      {educationsData && educationsData.length > 0 && (
        <Row className="rowProfile">
          <h2 className="header">
            {labels.education} ({educationsData.length})
          </h2>
          {educationsData?.length > 3 && !showAllEducation ? (
            <>
              <CarouselWrapper slideToShow={3}>
                {educationsData?.map((cards: IEducationCardProps, _key: number) => (
                  <EducationCard key={_key} {...cards} />
                ))}
              </CarouselWrapper>
              <div className="show-all">
                <Button
                  className="show-more"
                  onClick={() => setShowAllEducation(true)}
                  tabindex={1}
                  isSecondary={true}>
                  Show More
                </Button>
              </div>
            </>
          ) : (
            <>
              {educationsData?.map((cards: IEducationCardProps, _key: number) => (
                <EducationCard key={_key} {...cards} />
              ))}
            </>
          )}
        </Row>
      )}

      {magazineData && magazineData.length > 0 && (
        <Row className="rowProfile">
          <h2 className="header">
            {labels.magazine} ({magazineData.length})
          </h2>
          {magazineData?.length > 3 && !showAllMagazine ? (
            <>
              <CarouselWrapper slideToShow={3}>
                {magazineData?.map((cards: IMagazineCardProps, _key: number) => (
                  <Column
                    desktop={4}
                    tablet={6}
                    mobile={12}
                    className="profile-bookmark-mag-card"
                    key={_key}>
                    <MagazineCard {...cards} />
                  </Column>
                ))}
              </CarouselWrapper>
              <div className="show-all">
                <Button
                  className="show-more"
                  onClick={() => setShowAllMagazine(true)}
                  tabindex={1}
                  isSecondary={true}>
                  Show More
                </Button>
              </div>
            </>
          ) : (
            <>
              {magazineData?.map((cards: IMagazineCardProps, _key: number) => (
                <Column
                  desktop={4}
                  tablet={6}
                  mobile={12}
                  className="profile-bookmark-mag-card"
                  key={_key}>
                  <MagazineCard {...cards} />
                </Column>
              ))}
            </>
          )}
        </Row>
      )}
    </Container>
  )
}

const mapDispatch = {
  resetRatings: (ratings: any) => resetRatings(ratings),
  setUserProfile: (profile: any) => setUserProfile(profile),
  resetUserAndProfile: (user: any) => resetUser(user),
  updateRatingsCount: (ratings: any) => getRatingsCount(ratings),
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) =>
    setUserStateGlobally(loggedIn, loggedInType),
}

const mapState = (state: any) => ({
  user: state.user,
  ratings: state.ratings,
})

export default connect(mapState, mapDispatch)(ProfileBookmarksCategory)
