import { Button, Column, Container, RichText, Taxonomy } from "@atoms"
import { useContext, useMemo, useState } from "react"
import * as cheerio from "cheerio"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { DOMSanitizer } from "@utils/dompurify"
import { IVideoBannerContentProps } from "./_videoBannerContent.interface"
import VideoPlayerModal from "@organisms/videoPlayerModal"

const VideoBannerContent = ({
  title,
  paragraph,
  isOverlay,
  isOverlayFontType,
  taxonomyLabel,
  cta_video_label,
  cta_video_src,
  cta_audio_src,
  cta_audio_label,
  titleSuperscript,
  hide_video_tags,
}: IVideoBannerContentProps) => {
  const [showModal, setShowModal] = useState<string | boolean>(false)

  const onClose = () => {
    setShowModal(false)
  }

  const { applicationTheme } = useContext(ApplicationThemeContext)
  const videoBannerTitle = useMemo(() => {
    /**
     * In case super script is not available on title
     */
    if (title) {
      return <h1 className="videoBannerContent-title">{title}</h1>
    }

    /**
     * In case the title is having special chars like <sup> or inverse triangle (ASCII chars)
     */
    if (titleSuperscript) {
      const parsedHtml = cheerio.load(titleSuperscript, { xmlMode: false })
      const htmlTitleElement: any = parsedHtml("p")?.html()
      return (
        <h1
          className="videoBannerContent-title"
          dangerouslySetInnerHTML={{ __html: DOMSanitizer(htmlTitleElement) }}
        />
      )
    }
  }, [title, titleSuperscript])

  let themeClass = ""
  let themeFont = ""
  const getClasses = () => {
    let handleWidth = ""
    if (
      applicationTheme === "rebranding" ||
      applicationTheme === "rebranding-japan" ||
      applicationTheme === "jubbonti" ||
      applicationTheme === "wyost" ||
      applicationTheme === "biosimilarsinbone" ||
      applicationTheme === "hexal" ||
      applicationTheme === "vagidonna"
    ) {
      if (isOverlay === "on" && isOverlayFontType === "light") {
        themeClass = "text-overlay-dark"
      } else if (isOverlay === "on" && isOverlayFontType === "dark") {
        themeClass = "text-overlay"
      }
      themeFont = isOverlayFontType === "light" ? "font-light" : "font-dark"
    }
    try {
      if (themeClass !== "") {
        title?.split(" ")?.map((text: string) => {
          text.length > 14 ? (handleWidth = "extended-width") : ""
        })
      }
    } catch (error) {
      handleWidth = ""
    }
    return `videoBannerContent-textdata ${themeClass} ${themeFont} ${handleWidth}`
  }

  const renderAnchor = () => {
    return (
      <div className="btn-grp">
        {cta_video_src && (
          <Button
            tabindex={0}
            isSecondary={false}
            className="videoBannerContent-grp"
            onClick={() => setShowModal("video")}>
            {`${cta_video_label}`}
          </Button>
        )}
        {cta_audio_src && (
          <Button
            tabindex={0}
            isSecondary={false}
            className="videoBannerContent-grp"
            onClick={() => setShowModal("audio")}>
            {`${cta_audio_label}`}
          </Button>
        )}
      </div>
    )
  }

  const renderShader = () => {
    let shader: any = ""
    try {
      if (applicationTheme === "hexal" && themeClass !== "") {
        shader = <div className="videoBannerContent-shader" />
      }
      if (applicationTheme !== "hexal") {
        shader = <div className="videoBannerContent-shader" />
      }
    } catch (err) {
      shader = ""
    }
    return shader
  }

  return (
    <Container className="videoBannerContent">
      <Column
        mobile={12}
        tablet={
          applicationTheme === "rebranding" ||
          applicationTheme === "rebranding-japan" ||
          applicationTheme === "jubbonti" ||
          applicationTheme === "wyost" ||
          applicationTheme === "biosimilarsinbone" ||
          applicationTheme === "vagidonna"
            ? 6
            : 4
        }
        desktop={
          applicationTheme === "rebranding" ||
          applicationTheme === "rebranding-japan" ||
          applicationTheme === "jubbonti" ||
          applicationTheme === "wyost" ||
          applicationTheme === "biosimilarsinbone" ||
          applicationTheme === "vagidonna"
            ? 5
            : 4
        }
        className={getClasses()}>
        {!hide_video_tags ? (
          <>
            {taxonomyLabel && (
              <Taxonomy taxonomies={taxonomyLabel} className="videoBannerContent-taxonomy" />
            )}
          </>
        ) : (
          <></>
        )}
        <div className="videoBannerContent-text-style">
         <>{videoBannerTitle && <>{videoBannerTitle}</>}</>

          {paragraph ? <RichText className="videoBannerContent-text" content={paragraph} /> : null}
          {renderAnchor()}
          {showModal && (
            <VideoPlayerModal
              titleText={title}
              src={showModal == "video" ? cta_video_src : cta_audio_src}
              onClose={onClose}
            />
          )}
        </div>
      </Column>

      {applicationTheme !== "rebranding" &&
      applicationTheme !== "rebranding-japan" &&
      applicationTheme !== "jubbonti" &&
      applicationTheme !== "wyost" &&
      applicationTheme !== "biosimilarsinbone" &&
      applicationTheme !== "vagidonna" ? (
        renderShader()
      ) : (
        <></>
      )}
    </Container>
  )
}

export default VideoBannerContent
