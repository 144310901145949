import { Anchor, Column, Img, Video } from "@atoms"
import { IEducationCardProps } from "./_educationCard.interface"
import EducationCardContent from "../../molecules/educationCardContent"
import { modifyRelatedContentData } from "@utils/relatedContentUtil"

const EducationCard = (props: IEducationCardProps) => {
  const educationCardData = props?.relatedContentFlag
    ? modifyRelatedContentData(props?.educationCardContent)
    : props?.educationCardContent
  return (
    <Column
      desktop={props.relatedContentFlag ? 12 : 4}
      tablet={props.relatedContentFlag ? 12 : 6}
      mobile={12}
      className="education-card">
      <div className="card">
        {props?.imageSrc && (
          <div className="image-section">
            <Anchor
              href={props?.educationCardContent.buttonUrl}
              tabindex={0}
              noUnderline
              isExternal={props?.educationCardContent.buttonUrlExternal}>
              <Img
                type="card"
                styledimage={props?.styledimage}
                src={props?.imageSrc}
                alt={props?.imgAlt}
                width={368}
                height={220}
                islazyloaded={!props?.relatedContentFlag ?? false}
              />
            </Anchor>
          </div>
        )}
        {props?.videoUrl && <Video src={props?.videoUrl} />}

        <div className="card-body">
          <EducationCardContent {...educationCardData} />
        </div>
      </div>
    </Column>
  )
}
export default EducationCard
