import { Anchor, Img } from "@atoms"
import MediaCardContent from "@molecules/mediaCardContent"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import { IMediaCardProps } from "./_mediaCard.interface"

const MediaCard = (props: IMediaCardProps) => {
  const { className, view, imageUrl, alt, readMore } = props
  const getClasses = () => {
    const classes = ["card"]
    if (className) classes.push(className)
    if (view === mediaLibraryConstants.GRID_VIEW) classes.push("card-grid")
    else classes.push("card-list")
    if (!imageUrl) {
      classes.push("no-image")
    }
    return classes.join(" ")
  }
  return (
    <div className={getClasses()}>
      {imageUrl && (
        <div className="media-card-image">
          <Anchor href={readMore} tabindex={0}>
            <Img src={imageUrl} width={426} height={284} alt={alt} />
          </Anchor>
        </div>
      )}
      <div className="card-body">
        <MediaCardContent {...props} />
      </div>
    </div>
  )
}

export default MediaCard
