import { IIconWithLabelProps } from "./_iconWithLabel.interface"

const IconWithLabel = (props: IIconWithLabelProps) => (
  <div className={"icon-with-label " + props.cardAlignment}>
    {props.icon && <span className="icon-with-label-icon">{props.icon}</span>}
    {props.children && <span className="icon-with-label-text">{props.children}</span>}
  </div>
)

export default IconWithLabel
