import { DropDown, RichText } from "@atoms"
import InputBox from "@atoms/inputBox"
import React, { useEffect, useRef, useState } from "react"
import { IInputBoxWithLabelProps } from "./_inputBoxWithLabel.interface"

const InputBoxWithLabel = ({
  type,
  icon,
  inputField,
  label,
  error,
  dropDownOption,
  onChangehandler,
  onBlurhandler,
  value = "",
  disabled = false,
  getDropdownInfo,
  helpDescriptionText = "",
  name = "",
  dropDownShowPlaceholder,
  optionText,
  ...rest
}: IInputBoxWithLabelProps) => {
  const [showPassword, setShowPassword] = useState(false)
  const [val = value, setVal] = useState(value)
  const isFirstRender = useRef(true)

  const getClasses = () => {
    const classes: string[] = []
    classes.push("input")
    inputField?.disabled ? classes.push("disabled") : classes.push("enable")
    if (error) {
      classes.push("invalid")
    }
    return classes.join(" ")
  }

  const getType = () => {
    if (type === "password") {
      return showPassword ? "text" : "password"
    }
    return type
  }

  const getDefaultOptions = (defaultValue: string) => {
    return defaultValue
      ? [
          {
            label: defaultValue,
            value: defaultValue,
          },
        ]
      : []
  }

  const renderWithIcon = () => (
    <span onClick={() => setShowPassword(!showPassword)} className="inputBox-withIcon">
      {showPassword ? <img src={icon?.iconOpen} /> : <img src={icon?.iconClose} />}
      {icon?.message ? <span className="tooltiptext">{icon.message}</span> : null}
    </span>
  )

  const renderLabel = () => (
    <label
      aria-required={inputField.required}
      className="inputBox-withLabel"
      htmlFor={name ?? "inputBox-label"}>
      {label}
      {inputField.required && "*"}
    </label>
  )

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return // 👈️ return early if first render
    }
    setVal(value)
  }, [value])

  const handleOnChange = (e: any) => {
    if (type === "dropDown") {
      const value = e
      const name = dropDownOption?.name ?? ""
      setVal(value)
      getDropdownInfo && getDropdownInfo(value?.[0]?.value, name)
      return
    }
    const { value } = e.target
    setVal(value)
    if (typeof onChangehandler === "function") {
      onChangehandler(e)
    }
  }

  const errorContent = error ? `${error}` : ""
  const defaultValues = getDefaultOptions(value)

  return (
    <div className="input-box-with-label">
      {label && renderLabel()}
      {icon && renderWithIcon()}
      {type === "dropDown" ? (
        <>
          <DropDown
            id={inputField.id}
            ariaLabel={dropDownOption?.ariaLabel || ""}
            options={dropDownOption?.options || []}
            name={dropDownOption?.name || ""}
            isCleared={false}
            isDisabled={disabled}
            intialValue={defaultValues}
            onValueChange={handleOnChange}
            isMulti={false}
            placeHolder={dropDownOption?.ariaLabel || "Select Option"}
            showPlaceholder={dropDownShowPlaceholder || false}
            optionText={optionText || ""}
          />
          <div className="error">
            <RichText content={errorContent} />
          </div>
        </>
      ) : (
        <>
          <InputBox
            {...inputField}
            value={val}
            disabled={disabled}
            className={getClasses()}
            type={getType()}
            onChange={handleOnChange}
            onBlur={onBlurhandler}
            id={name ?? "inputBox-label"}
            {...rest}
          />
          {helpDescriptionText || <></>}
          {errorContent && (
            <div className="error">
              <RichText content={errorContent} />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default InputBoxWithLabel
