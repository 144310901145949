import { getApiDomainAndLang } from "@utils/baseApi"
import moment from "moment"
import { ICSTCardInfo } from "./_compactStockCard.interface"
import { useContext } from "react"
import ApplicationContext from "@utils/application-context/applicationContext"

const CompactStockCard = ({ cardData }: ICSTCardInfo) => {
  const { exchange, latestquote, currency, date, time } = cardData

  const isPositive = Math.sign(parseFloat(cardData["change-amount"]._text)) === 1
  const { lang } = getApiDomainAndLang()
  const { applicationConfiguration } = useContext(ApplicationContext)

  return (
    <div className="compact-stock-ticker-card">
      {exchange && <div className="compact-stock-ticker-card-exchange">{exchange._text}</div>}
      {latestquote && <div className="compact-stock-ticker-card-quote">{latestquote._text}</div>}
      {currency && <div className="compact-stock-ticker-card-currency">{currency._text}</div>}
      {cardData["change-amount"] && cardData["change-percentage"] && (
        <div
          className={`compact-stock-ticker-card-change ${
            isPositive ? "positiveColor" : "negativeColor"
          }`}>
          <span className="stock-indicator" />
          {`${cardData["change-amount"]._text} (${cardData["change-percentage"]._text})`}
        </div>
      )}
      {date && (
        <div className="compact-stock-ticker-card-date">
          {`as of ${moment(date._text).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}`}
        </div>
      )}
      {time && (
        <div className="compact-stock-ticker-card-time">
          {`${time._text} ${time._attributes.timezone}`}
        </div>
      )}
    </div>
  )
}

export default CompactStockCard
