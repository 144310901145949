import { useEffect, useState } from "react"
import {
 Button, DropDown, Icon, IconButton, RichText, SearchBox,
} from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { ISearchFilterBarProps } from "./_advanceSearchOptions.interface"
import useMediaQuery from "../../../helpers/hooks/useMediaQuery"

const AdvanceSearchFilterBar = ({
  filtersOptions,
  buttonText,
  labelItemsSelected,
  handleSearch,
  isCareerSearch = false,
  handleClear,
  applyFiltertext,
  clearAllText,
  handleFilterBtnToggle,
  isCleared,
  handleAdditionalFilters,
  applyAdditionalFilters,
  selectedFilters = {},
  currentSearchText = "",
  handleSearchText,
  inputLabel,
  searchPlaceholderMobile,
}: ISearchFilterBarProps) => {
  const [displayFilters, setDisplayFilters] = useState(false)
  const [searchText, setSearchText] = useState(currentSearchText)

  useEffect(() => {
    setSearchText(currentSearchText)
  }, [currentSearchText])
  const handleSearchTextChanges = (text: string) => {
    setSearchText(text)
    handleSearchText?.(text)
  }
  const handleFilterToggle = () => {
    if (handleFilterBtnToggle) handleFilterBtnToggle(!displayFilters)
    setDisplayFilters(!displayFilters)
  }

  const handleClick = () => {
    handleSearch(searchText)
  }
  const isMobile = useMediaQuery("(max-width: 991px)")
  const searchButton = (
    <div className="search-filter-bar-hcontrol search-filter-bar-searchBox">
      <Button
        type="button"
        className="search-button"
        tabindex={1}
        onClick={handleClick}
        icon={
          !isCareerSearch && searchText?.trim().length < NUMBERS.THREE ? (
            <Icon iconName="SearchIconDissabled" />
          ) : (
            <Icon iconName="SearchIcon1" />
          )
        }
        iconPosition="right"
        isDisabled={
          isCareerSearch
            ? searchText?.trim().length < NUMBERS.THREE && searchText !== ""
            : searchText?.trim().length < NUMBERS.THREE
        }
      >
        {buttonText}
      </Button>
    </div>
  )

  const searchBar = (
    <SearchBox
      currentSearchText={searchText}
      placeholderText={searchPlaceholderMobile ?? ""}
      setSearchText={handleSearchTextChanges}
      handleSearch={handleSearch}
      isCleared={isCleared}
      useStateVal={false}
    />
  )

  const applyButton = (
    <div className="search-filter-bar-hcontrol search-filter-bar-modal-element-applyBox">
      <Button
        type="button"
        className="search-button"
        tabindex={1}
        onClick={() => applyAdditionalFilters?.(searchText)}
      >
        {applyFiltertext}
      </Button>
    </div>
  )

  useEffect(() => {
    if (isCleared && !isCareerSearch) {
      handleSearch("")
    }
  }, [isCleared])

  return (
    <form className="search-filter-bar" onSubmit={(e) => e.preventDefault()}>
      {!isMobile && isCareerSearch && (
        <div className="search-filter-bar-searchBox-container">
          <RichText className="title" content="Search Job" />
          <div className="search-filter-bar-hcontrol search-filter-bar-searchBox-left">
            {searchBar}
          </div>
        </div>
      )}

      {(!isCareerSearch || (isCareerSearch && !isMobile))
        && filtersOptions?.map(({ label, filterName = "", selectOptions }, index) => (
          <div
            key={label}
            className={`search-filter-bar-element ${
              !displayFilters && isMobile ? "hideFilters" : ""
            }`}
          >
            <RichText className="title" content={label} />
            <DropDown
              {...selectOptions}
              labelItemsSelected={labelItemsSelected}
              isCleared={isCleared}
              intialValue={selectedFilters?.[filterName] ?? []}
              onValueChange={(val) =>
                handleAdditionalFilters?.(filterName || `index${index + 1}`, val)}
            />
          </div>
        ))}

      {isMobile && isCareerSearch && displayFilters && (
        <div className="search-filter-bar-modal-element">
          {filtersOptions?.map(({ label, filterName = "", selectOptions }, index) => (
            <div
              key={label}
              className={`search-filter-bar-element ${
                !displayFilters && isMobile ? "hideFilters" : ""
              }`}
            >
              <RichText className="title" content={label} />
              <DropDown
                {...selectOptions}
                labelItemsSelected={labelItemsSelected}
                isCleared={isCleared}
                intialValue={selectedFilters?.[filterName] ?? []}
                onValueChange={(val) =>
                  handleAdditionalFilters?.(filterName || `index${index + 1}`, val)}
              />
            </div>
          ))}
          {applyButton}
          <div className="search-filter-bar-modal-element-filter-clear">
            <span aria-hidden="true" onClick={handleClear}>
              {clearAllText}
            </span>
          </div>
        </div>
      )}

      {isMobile && (
        <div className="search-filter-bar-searchContainer">
          {inputLabel && (
            <label id="search" className="search-label-prod" htmlFor="search">
              {" "}
              {inputLabel}
            </label>
          )}
          <div className="search-filter-bar-hcontrol search-filter-bar-searchBox">
            {searchBar}
            <IconButton
              tabindex={0}
              onClick={handleFilterToggle}
              className="search-filter-bar-filter-icon"
              icon={<Icon iconName="FilterIcon" />}
            />
          </div>
          {searchButton}
        </div>
      )}
      {!isMobile && !isCareerSearch && (
        <>
          <div className="search-filter-bar-hcontrol search-filter-bar-searchBox">{searchBar}</div>
          {searchButton}
        </>
      )}
      {!isMobile && isCareerSearch && searchButton}
    </form>
  )
}

export default AdvanceSearchFilterBar
