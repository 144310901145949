import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getCommonCTAData = async (url: string, data?: any) => {
  let ctaBtnData
  if (!data) {
    ctaBtnData = await getBlockData(`${url}`)
  } else {
    ctaBtnData = data
  }
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(ctaBtnData)

  const ctaType = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.field_common_cta_type"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(ctaBtnData, "data.attributes.field_common_cta_type")

  const addText = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.field_add_cta_text.value"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(ctaBtnData, "data.attributes.field_add_cta_text.value")

  const removeText = isPaywallProtected(
    getNestedObject(ctaBtnData, "data.attributes.field_remove_cta_text.value"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(ctaBtnData, "data.attributes.field_remove_cta_text.value")

  if (ctaType === "bookmark") {
    return {
      ctaBtnType: ctaType,
      ctaBtnData: {
        bookmarkStatus: false,
        isButton: true,
        addText,
        removeText,
      },
      isParentPaywalled,
      fieldPaywallRoles,
    }
  }
  if (ctaType === "calendar") {
    return {
      ctaBtnType: ctaType,
      ctaBtnLabel: addText,
      ctaBtnData: {
        eventTitle: "",
        startDate: "",
        endDate: "",
        eventDescription: "",
        location: "",
      },
      isParentPaywalled,
      fieldPaywallRoles,
    }
  }
  if (ctaType === "medicalinfo" || ctaType === "favourite") {
    return {
      ctaBtnType: ctaType,
      ctaBtnLabel: addText,
      fieldPaywallRoles,
      isParentPaywalled,
    }
  }

  return null
}

export default getCommonCTAData
