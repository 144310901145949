import React from "react"
import DatePicker, { DayValue } from "@hassanmojab/react-modern-calendar-datepicker"
import { IDatePickerProps } from "./_datePicker.interface"
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css"

const DatePickerOne = ({
  value,
  className,
  dataPlaceHolder,
  minDate,
  maxDate,
  isDisabled,
  setValue,
  name,
}: IDatePickerProps) => {
  const getClasses = () => {
    const classes = ["input-date", className, isDisabled ? "input-date-disabled" : ""].filter(
      Boolean,
    )
    return classes.join(" ")
  }

  const inputDatepickerFormat = (date: { year: any; month: any; day: any }) => {
    const getTwodigitValue = (val: number) => {
      return val?.toString().length === 1 ? `0${val}` : val
    }
    return date ? `${date.year}-${getTwodigitValue(date.month)}-${getTwodigitValue(date.day)}` : ""
  }

  const datePickerDateFormat = (date: any) => {
    let dateVal = null as DayValue
    if (date) {
      const dates = date ? date.split("-") : []
      const [year = "", month = "", day = ""] = dates
      dateVal = { year: Number(year), month: Number(month), day: Number(day) }
    }
    return dateVal
  }

  const resetBtnDetails=(
    <div className="reset-button">
      <span
        tabIndex={0}
        role="button"
        onClick={() => {
          setSelectedDay(null)
        }}>
        Reset
      </span>
    </div>
  )
  const ResetButton = () => resetBtnDetails

  const setSelectedDay = (date: any) => {
    setValue(inputDatepickerFormat(date))
  }
  return (
    // @ts-ignore
    <DatePicker
      name={name ?? "date-field"}
      value={datePickerDateFormat(value)}
      onChange={setSelectedDay}
      minimumDate={datePickerDateFormat(minDate)}
      maximumDate={datePickerDateFormat(maxDate)}
      inputPlaceholder={dataPlaceHolder}
      colorPrimary="#000"
      renderFooter={() => <ResetButton />}
      calendarPopperPosition="bottom"
      inputClassName={getClasses()}
    />
  )
}

export default DatePickerOne
