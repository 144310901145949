import {filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected} from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import {filterURL, getBlockData, getImageMeta, isExternalLink} from "."

const getOpinionLeadersCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let opinionCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(opinionCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  opinionCards = opinionCards?.included ?? []
  const fieldPaywallRoles = getAllRoles(opinionCards)
  opinionCards = await Promise.all(opinionCards?.map(async (opinionCard: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(opinionCard, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(opinionCard?.relationships?.field_article_image)
      const {imageUrl, alt, imageStyledUrl} = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(opinionCard?.relationships?.field_article_image)


      return {
        opinionLeadersCardImage: {
          src: imageUrl ?? null,
          alt: alt ?? null,
          styledimage: imageStyledUrl ?? null
        },
        opinionLeadersCardContent: {
          heading: isPaywallProtected(opinionCard?.attributes?.title).isPaywallProtectedFlag
            ? null
            : opinionCard?.attributes?.title ?? null,
          content: opinionCard?.attributes?.body?.processed
            ? opinionCard?.attributes?.body?.processed
            : null,
          buttonLabel: opinionCard?.attributes?.field_secondary_cta?.title
            ? opinionCard?.attributes?.field_secondary_cta?.title
            : null,
          link: opinionCard?.attributes?.field_secondary_cta?.uri
            ? filterURL(opinionCard?.attributes?.field_secondary_cta?.uri)
            : null,
          isLinkExternal: opinionCard?.attributes?.field_secondary_cta?.options?.attributes?.target
            ? isExternalLink(opinionCard?.attributes?.field_secondary_cta?.options?.attributes?.target)
            : null
        }
      }

}))
  return {
    opinionCards: filterNullValues(opinionCards) ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }

}

export default getOpinionLeadersCardData
