import React, { useContext, useEffect, useRef, useState } from "react"
import { Icon } from "@atoms"
import { MultiSelect } from "react-multi-select-component"
import { ICustomItemRendererProps, IDropDownProps, IOption, IOptions } from "./_dropDown.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const CustomItemRenderer = ({ checked, option, onClick, disabled }: ICustomItemRendererProps) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <label className="container">
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className="checkmark" />
    </label>
    <span>{option.label}</span>
  </div>
)

const DropDown = ({
  options,
  isMulti,
  isCleared,
  simpleDropDownLabel,
  placeHolder,
  optionText,
  onValueChange,
  isDisabled,
  intialValue = [],
  showPlaceholder,
  ariaLabel,
  shouldToggleOnHover = true,
  showValueWithPlaceholder = false,
  resetWithInitVal = false,
  ...rest
}: IDropDownProps) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  const selectPlaceholder =
    applicationConfiguration?.siteConfig?.webstore_list_filters_placeholder_text
  const [selOption, setSelOption] = useState<IOptions>(intialValue)
  const [isUpdated, setIsUpdated] = useState<boolean>(false)
  const [isEllipsisVisible, setIsEllipsisVisible] = useState<boolean>(false)

  const isFirstRender = useRef(true)
  const drpDwnRef = useRef<any>()

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    }

    if (selOption.length === 0 && intialValue.length > 0 && !isUpdated) {
      setSelOption(intialValue)
      setIsUpdated(true)
    }
  }, [intialValue, selOption])

  useEffect(() => {
    onValueChange && onValueChange(selOption)
    const drpDwnHeadValue = drpDwnRef.current.querySelector("div.dropdown-heading-value")
    const hasEllipsis = isEllipsisActive(drpDwnHeadValue)
    setIsEllipsisVisible(hasEllipsis)
  }, [selOption])

  useEffect(() => {
    const resetVal = resetWithInitVal && Array.isArray(intialValue) ? intialValue : []
    setSelOption(resetVal)
  }, [isCleared])

  const changeSelected = (value: any) => {
    if (isMulti) {
      setSelOption(value)
      onValueChange?.(value)
    } else {
      const previoslySelected = [...selOption]
      const newSelected =
        previoslySelected.length > 0
          ? value.filter((item: IOption) => item.value !== previoslySelected[0].value)
          : [...value]
      setSelOption(newSelected)
      onValueChange?.(newSelected)
    }
  }

  const customValueRenderer = (selectedOptions: any[], _options: any[]) => {
    switch (selectedOptions.length) {
      case 0:
        return simpleDropDownLabel || showPlaceholder ? (
          <p>{placeHolder}</p>
        ) : (
          <p>{selectPlaceholder}</p>
        )
      case _options.length:
        return "All"
      default:
        return selectedOptions.map((u: any) => u.label).join(", ")
    }
  }

  const isEllipsisActive = (e: any) => {
    return (e.offsetWidth < e.scrollWidth)
  }

  const clearIconDetail = (
    <div>
      {isEllipsisVisible && <span>{` (${selOption?.length})`}</span>}
      <Icon iconName="CrossIcon" />
    </div>
  )
  const CustomClearIcon = () => clearIconDetail

  return (
    <div ref={drpDwnRef}>
      <span
        id={ariaLabel ?? "input_select"}
        aria-hidden="true"
        className="item-renderer-hidden-label">
        {simpleDropDownLabel || showPlaceholder ? placeHolder : selectPlaceholder}
      </span>
      <MultiSelect
        options={options}
        value={selOption}
        onChange={changeSelected}
        labelledBy={ariaLabel ?? "input_select"}
        ItemRenderer={CustomItemRenderer}
        hasSelectAll={isMulti}
        disableSearch={true}
        shouldToggleOnHover={shouldToggleOnHover}
        overrideStrings={{ selectAll: "All" }}
        valueRenderer={customValueRenderer}
        disabled={isDisabled}
        closeOnChangedValue={!isMulti}
        ClearIcon={<CustomClearIcon />}
        ClearSelectedIcon={isDisabled ? null :<CustomClearIcon />}
        {...rest}
      />
    </div>
  )
}

export default DropDown