import { NavBarProps } from "./_nav-bar.interface"

const NavBar = (props: NavBarProps) => (
  <nav className="navbar navbar-expand-lg navbar-light bg-light">
    <div className="collapse navbar-collapse">
      <ul className="navbar-nav mr-auto">{props.children}</ul>
    </div>
  </nav>
)

export default NavBar
