import { getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { ITaxonomyData } from "./_api.interface"
import { filterURL } from "."

const getProductTeaserCardData = async (data: any, isPaywallEnabled: boolean) => {
  const getTaxonomies = (taxonomies: string[], id: string) => {
    const taxonomyArray = [] as ITaxonomyData[]
    taxonomies?.map((item: string) => {
      const object = {
        taxonomyLabel: item,
      }
      item && taxonomyArray.push(object)
    })
    return taxonomyArray
  }

  const getContent = (body: any) => {
    const contentArray = [] as any[]
    Object?.entries(body)?.forEach(([key, value]) => {
      const object = {
        title: key?.includes("NA") ? "" : key,
        description: isPaywallProtected(value).isPaywallProtectedFlag ? null : value ?? null,
      }
      value && typeof value === "string" && contentArray.push(object)
    })
    return contentArray
  }

  if (data?.length) {
    const isChildPaywalled = isPaywallProtected(isPaywallEnabled)?.isPaywallProtectedFlag

    if (isChildPaywalled) {
      return null
    }
    return data?.map((item: any) => {
      const imagePaywallData = isPaywallProtected(item?.not_configurable?.field_primaryproductimage)
      const { src, image_style_uri, alt } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : item?.not_configurable?.field_primaryproductimage || {}
      return item?.paywall ? null : {
        productCardImage: {
          src: src ?? null,
          alt: alt ?? null,
          styledimage: image_style_uri ?? null,
        },
        productCardContent: {
          id: isPaywallProtected(item?.not_configurable?.nid).isPaywallProtectedFlag
            ? null
            : item?.not_configurable?.nid ?? "",
          heading: isPaywallProtected(item?.not_configurable?.product_title).isPaywallProtectedFlag
            ? null
            : item?.not_configurable?.product_title ?? null,
          taxonomies: isPaywallProtected(item?.not_configurable?.tags).isPaywallProtectedFlag
            ? null
            : getTaxonomies(item?.not_configurable?.tags, item?.not_configurable?.nid) ?? null,
          content: isPaywallProtected(item?.configurable).isPaywallProtectedFlag
            ? null
            : getContent(item?.configurable) ?? null,
          buttonLabel: isPaywallProtected(item?.not_configurable?.pdt_details_cta)
            .isPaywallProtectedFlag
            ? null
            : item?.not_configurable?.pdt_details_cta?.label ?? null,
          href: filterURL(item?.not_configurable?.pdt_details_cta?.path) ?? null,
          basisTextLabel: isPaywallProtected(item?.not_configurable?.basis_text_anchored_link)
            .isPaywallProtectedFlag
            ? null
            : item?.not_configurable?.basis_text_anchored_link?.label ?? null,
          basisTextPath: filterURL(item?.not_configurable?.basis_text_anchored_link?.path) ?? null,
          attachmentLabel: isPaywallProtected(item?.not_configurable?.attachment_section_link)
            .isPaywallProtectedFlag
            ? null
            : item?.not_configurable?.attachment_section_link?.label ?? null,
          attachmentPath: filterURL(item?.not_configurable?.attachment_section_link?.path) ?? null,
        },
      }
    })
  }
  return []
}

export default getProductTeaserCardData
