import getNestedObject from "@utils/nestedObjects"
import { consoleError } from "@utils/error"
import { URL_TARGET } from "@helpers/constants/generic"
import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData } from "."

const getQuickLinkCardData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getQuickLinkCardData.name}.`)
  const serverData = args?.[3] // accepting serverData only
  const { data } = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(data, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(data)
  const cardInfo = {
    field_quick_link_card_title:
      getNestedObject(data, "attributes.field_quick_link_card_title.value") ?? "",
    body:
      getNestedObject(data, "attributes.body.processed") ??
      getNestedObject(data, "attributes.body.value") ??
      null,
    field_card_link: filterURL(getNestedObject(data, "attributes.field_card_link.uri")) ?? "",
    field_card_title: getNestedObject(data, "attributes.field_card_link.title") ?? "",
    field_link_target:
      filterURL(getNestedObject(data, "attributes.field_card_link.options.attributes.target")) ??
      URL_TARGET.SELF,
  }

  return {
    cardInfo,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getQuickLinkCardData
