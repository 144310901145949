import React, { useEffect, useState } from "react"
import { Button, Icon } from "@atoms"
import mediaLibraryConstants from "@helpers/constants/mediaLibrary"
import { IViewSortOptionProps } from "./_viewAndSortOptions.interface"

const ViewAndSortOption = ({
  label,
  onSaveIdHandler,
  view,
  handleSort,
  isMobileDisplay,
  hideViewTypeCTA = true,
  hideSortCTA = true,
  setOrderSortValue,
  defaultSort,
  isOrderByVisible = false,
}: IViewSortOptionProps) => {
  const defaultView = "ASC"
  const [isOpen, setIsOpen] = useState(false)
  const [sortValue, setSortValue] = useState<string | undefined>("")
  const [sortView, setSortView] = useState(defaultSort ?? defaultView)
  const showViewTypeButton = !isMobileDisplay && !hideViewTypeCTA

  const option = [label?.old_to_new_text, label?.new_to_old_text]
  useEffect(() => {
    setSortValue(label?.default_sort)
  }, [])

  const handleList = ({ target }: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = target as HTMLInputElement
    setSortValue(value)
    setIsOpen(!isOpen)
    handleSort?.(value)
  }

  const handleOrderSort = () => {
    const changedView = sortView === "ASC" ? "DESC" : "ASC"
    setSortView(changedView)
    setOrderSortValue && setOrderSortValue(changedView)
  }

  return (
    <div className="modal-buttons-group">
      {showViewTypeButton && (
        <Button
          className="btn-style"
          tabindex={1}
          isSecondary
          onClick={() => onSaveIdHandler?.()}
          icon={view === mediaLibraryConstants.GRID_VIEW ? <Icon iconName="ListView" /> : <Icon iconName="GridView" />}
        >
          {view === mediaLibraryConstants.GRID_VIEW ? label?.list_view_text : label?.grid_view_text}
        </Button>
      )}
      {!hideSortCTA && (
        <div className="dropdown">
          <Button
            tabindex={2}
            onClick={() => setIsOpen(!isOpen)}
            isSecondary
            icon={<Icon iconName="Sort" />}
            className="btn-style"
          >
            {label?.sort_cta_text}
          </Button>
          {isOpen && (
            <>
              <div className="dropdown-content">
                {option.map((item) => (
                  <button
                    type="button"
                    key={item}
                    className="list-group-item"
                    value={item}
                    onClick={handleList}
                  >
                    <span className={item === sortValue ? "checkMark" : "noCheckMark"}>
                      {item === sortValue ? <Icon iconName="CheckMark" /> : ""}
                    </span>
                    <span>{item}</span>
                  </button>
                ))}
              </div>
              <div className="dropdown-background" onClick={() => setIsOpen(false)} />
            </>
          )}
        </div>
      )}
      {(showViewTypeButton || isOrderByVisible) && (
        <Button
          ariaLabel="sort"
          className="sort-btn-style"
          tabindex={1}
          isSecondary
          onClick={() => handleOrderSort()}
          icon={sortView === "ASC" ? <Icon iconName="AtoZSort" /> : <Icon iconName="ZtoASort" />}
        />
      )}
    </div>
  )
}

export default ViewAndSortOption
