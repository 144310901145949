import React from "react"
import STOCK_TICKER_CONSTANTS from "@helpers/constants/stockTicker"
import { Iframe } from "@atoms"
import { IStockTicker } from "./_stockTicker.interface"

const StockTicker = ({ iframeURL, width, height, title }: IStockTicker) => {
  return (
    <Iframe
      iframeURL={iframeURL}
      width={width || STOCK_TICKER_CONSTANTS.WIDTH}
      height={height || STOCK_TICKER_CONSTANTS.HEIGHT}
      title={title}
      classes="stock-ticker"
      scroll={true}
    />
  )
}
export default StockTicker
