import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."

const getVideoCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let videoTeaserCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(videoTeaserCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(videoTeaserCards)
  if (videoTeaserCards) {
    videoTeaserCards = videoTeaserCards?.included ?? []
    videoTeaserCards = await Promise.all(
      videoTeaserCards?.map(async (videoTeaserCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(videoTeaserCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(
          videoTeaserCard?.relationships?.field_thumbnail_image,
        )

        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(videoTeaserCard?.relationships?.field_thumbnail_image)

        const taxonomies = await getTaxonomy(
          videoTeaserCard?.relationships?.field_video_reference_tags?.links?.related?.href,
          false,
          serverData,
        )

        return {
          videoTeaserCardContent: {
            id: videoTeaserCard?.attributes?.drupal_internal__id ?? null,
            taxonomies: taxonomies ?? null,
            heading: videoTeaserCard?.attributes?.field_title_video_cards?.value ?? null,
            text: videoTeaserCard?.attributes?.body?.value ?? null,
            time: videoTeaserCard?.attributes?.field_duration_video?.value ?? null,
            buttonLabel: videoTeaserCard?.attributes?.field_secondary_button?.title ?? null,
            buttonUrl: filterURL(videoTeaserCard?.attributes?.field_secondary_button?.uri) ?? null,
            isButtonUrlExternal:
              isExternalLink(
                videoTeaserCard?.attributes?.field_secondary_button?.options?.attributes?.target,
              ) ?? null,
          },
          videoUrl: getNestedObject(videoTeaserCard, "attributes.body.processed_text") ?? null,
          image: {
            src: imageUrl ?? null,
            alt: alt ?? null,
            styledimage: imageStyledUrl ?? null,
          },
        }
      }),
    )
  }

  return {
    videoTeaserCards: filterNullValues(videoTeaserCards) ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getVideoCardData
