import { IImageProps } from "@atoms/img/_img.interface"
import { Button, Column, Img, RichText } from "@atoms"
import { useRef, useState } from "react"
import CarouselWrapper from "../carouselWrapper"
import { ISliderWithContentProps } from "./_sliderWithContent.interface"

const SliderWithContent = ({
  readMoretitle,
  readMore,
  className,
  imageUrl,
  showMoreButtonLabel,
  showLessButtomLabel,
  imageHeight,
  isReadMoreEnabled,
}: ISliderWithContentProps) => {
  const readMoreTitle = readMoretitle || " "
  const readMoreText = readMore || " "
  const [isReadMore, setIsReadMore] = useState(isReadMoreEnabled)
  const ref = useRef<null | HTMLDivElement>(null)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
    if (isReadMore) {
      ref?.current?.scrollIntoView({ behavior: "smooth" })
    }
  }

  const regex = /<.+p>(.*?)<\/.*p>/
  const corresp = regex.exec(readMoreText)
  const firstParagraph = corresp ? corresp[0] : ""
  return (
    <Column desktop={12} mobile={12} tablet={12} className="sliderWithContent">
      <div>
        <CarouselWrapper slideToShow={1} slideToShowTablet={1} slide className={className || ""}>
          {imageUrl?.map((image: IImageProps, index: number) => (
            <Img
              type="therapy_details"
              styledimage={image?.styledimage}
              alt={image.alt}
              src={image.src}
              key={index}
              width={1000}
              height={imageHeight || 500}
              caption={image.caption}
            />
          ))}
        </CarouselWrapper>
        {readMoreText && (
          <div className="content" ref={ref}>
            <div className="text-block">
              <h5>{readMoreTitle}</h5>
              {isReadMore ? (
                <RichText content={firstParagraph} />
              ) : (
                <RichText content={readMoreText} />
              )}
              {(showMoreButtonLabel || showLessButtomLabel) && isReadMoreEnabled && (
                <Button
                  className="show-more"
                  onClick={toggleReadMore}
                  tabindex={1}
                  isSecondary={true}>
                  {isReadMore ? showMoreButtonLabel : showLessButtomLabel}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </Column>
  )
}
export default SliderWithContent
