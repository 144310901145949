import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData, getImageMeta, isExternalLink } from "."
import getNestedObject from "@utils/nestedObjects"

const getIconCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let iconCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(iconCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(iconCards)  
  const cardGroupAlignment = iconCards?.data?.attributes?.field_card_alignment_style ?? ""
  const view = iconCards?.data?.attributes?.field_cards_view ?? "grid"
  const layout = iconCards?.data?.attributes?.field_cards_layout?.replace("-", "_") ?? "three_column"
  const bulletPointsAlignment = iconCards?.data?.attributes?.field_bullet_points_alignment ?? "above"
  iconCards = iconCards?.included ?? []
  iconCards = iconCards?.filter((blocks: any) => blocks?.type === "block_content--icon_cards")
  if (iconCards) {
    iconCards = await Promise.all(
      iconCards?.map(async (iconCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(iconCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = iconCard?.relationships?.field_bullet_point_icon?.data?.meta
          ?.absolute_uri
          ? isPaywallProtected(iconCard?.relationships?.field_bullet_point_icon)
          : isPaywallProtected(iconCard?.relationships?.field_icon)
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : iconCard?.relationships?.field_bullet_point_icon?.data?.meta?.absolute_uri
          ? getImageMeta(iconCard?.relationships?.field_bullet_point_icon)
          : getImageMeta(iconCard?.relationships?.field_icon)

        return {
          title: iconCard?.attributes?.field_icontitle?.value
            ? iconCard?.attributes?.field_icontitle?.value
            : null,
          paragraph: iconCard?.attributes?.body?.processed
            ? iconCard?.attributes?.body?.processed
            : null,
          imageSrc: imageUrl ?? null,
          imageAlt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
          linkText: iconCard?.attributes?.field_button?.title
            ? iconCard?.attributes?.field_button?.title
            : null,
          link: iconCard?.attributes?.field_button?.uri
            ? filterURL(iconCard?.attributes?.field_button?.uri)
            : null,
          linkIsExternal: iconCard?.attributes?.field_button?.options?.attributes?.target
            ? isExternalLink(iconCard?.attributes?.field_button?.options?.attributes?.target)
            : null,
          cardAlignment: cardGroupAlignment || iconCard?.attributes?.field_text_alignment_style || "left",
          subtitle: iconCard?.attributes?.field_icon_subtitle?.value ?? null,
          secondaryLinkText: iconCard?.attributes?.field_secondary_button?.title
            ? iconCard?.attributes?.field_secondary_button?.title
            : null,
          secondaryLink: iconCard?.attributes?.field_secondary_button?.uri
            ? filterURL(iconCard?.attributes?.field_secondary_button?.uri)
            : null,
          secondaryLinkIsExternal: iconCard?.attributes?.field_secondary_button?.options?.attributes
            ?.target
            ? isExternalLink(
                iconCard?.attributes?.field_secondary_button?.options?.attributes?.target,
              )
            : null,
        }
      }),
    )
    return {
      iconCards: filterNullValues(iconCards) ?? null,
      cardGroupAlignment,
      fieldPaywallRoles,
      view: view ?? null,
      layout: layout ?? null,
      bulletPointsAlignment: bulletPointsAlignment ?? null,
      isParentPaywalled,
    }
  }
  return {
    iconCards: filterNullValues(iconCards) ?? null,
    cardGroupAlignment,
    fieldPaywallRoles,
    view: view ?? null,
    layout: layout ?? null,
    bulletPointsAlignment: bulletPointsAlignment ?? null,
    isParentPaywalled,
  }
}

export default getIconCardData
