import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  poListFilters: [] as any,
  currentPage: 0,
  searchText: "",
  sort: {
    column: "",
    order: "ASC",
  },
}

const POListSearchReducer = (state: any = initialFilterData, action: POListFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_PO_LIST_FILTERS:
      return {
        ...state,
        poListFilters: action.poListFilters,
        currentPage: 0,
        sort: {
          column: "",
          order: "ASC",
        },
      }
      case actionType.UPDATE_PO_LIST_PAGE_NUMBER:
        return {
          ...state,
          currentPage: action.currentPage,
        }
      case actionType.UPDATE_PO_LIST_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          currentPage: 0,
          sort: {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_ALL_PO_LIST_FILTERS:
        return {
          ...state,
          poListFilters: action.poListFilters,
          currentPage: action.currentPage,
          searchText: action.searchText,
          sort: action?.sort || {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_PO_LIST_SORT:
      return {
        ...state,
        sort: action.sort,
      }
    default:
      return state
  }
}

export default POListSearchReducer
