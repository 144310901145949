import { useCallback, useMemo, useState } from "react"
 
export const UNCONSTRAINED_SIZE_KEY = "default"
 
export const useImage = (props: any) => {
    const { onError, onLoad, width, height } = props
    const [isLoaded, setIsLoaded] = useState(false)
    const [hasError, setHasError] = useState(false)
 
    const handleImageLoad = useCallback((event: any) => {
        setIsLoaded(true)
 
        if (typeof onLoad === "function") {
            onLoad(event)
        }
    }, [onLoad])
 
    const handleError = useCallback(() => {
        setHasError(true)
 
        if (typeof onError === "function") {
            onError()
        }
    }, [onError])
 
    // Use the unconstrained / default entry in widths.
    const resourceWidth = useMemo(() => {
        if (width) {
            return width
        }
    }, [width])
 
    const resourceHeight = useMemo(() => {
        if (height) {
            return height
        } else {
            return undefined
        }
    }, [height, resourceWidth])
 
    return {
        handleError,
        handleImageLoad,
        hasError,
        isLoaded,
        resourceWidth,
        resourceHeight
    }
}