import { Column, Container, Row, SEO } from "@atoms"
import { LOGIN_TYPES, SECTION_LAYOUT, STICKY_STYLE } from "@helpers/constants/generic"
import NUMBERS from "@helpers/constants/numbers"
import getPaywallData from "@helpers/dataFunctions/getPaywallData"
import { useMediaQuery } from "@helpers/hooks"
import { usePreviousRoute } from "@helpers/hooks/usePreviousRoute"
import useUserProfile from "@helpers/hooks/useUserProfile"
import { StarsRating } from "@molecules"
import { IPopup } from "@molecules/popup/_popup.interface"
import { LoginPopup, Paywall, PaywallNotification } from "@organisms"
import { IArticleTeaserBannerProps } from "@organisms/articleBanner/_article-banner.interface"
import { Paywallprops } from "@organisms/paywall/_paywall.interface"
import ApplicationContext from "@utils/application-context/applicationContext"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import { getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { getColsByPercentage } from "@utils/dataModifier"
import isServer from "@utils/fs/isServer"
import { EVENTS } from "@utils/gtmEvents"
import { dataLayerPush } from "@utils/gtmutils"
import { checkForLoginModal, getAssetPrefixUrl, loadGTMPageView } from "@utils/helper"
import { getApplicationPathFromURL } from "@utils/session-util/sessionHandler"
import BackgroundImage from "@utils/setBackgroundImage"
import { encodeString } from "@utils/sha256"
import AES from "crypto-js/aes"
import dynamic from "next/dynamic"
import Router, { useRouter } from "next/router"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { connect, useSelector } from "react-redux"
import { addUser, resetUser, setUserProfile } from "store/actions/ActionCreators"
import { setUserStateGlobally, updatePaywallContent } from "store/actions/CommonActionCreators"
import { getRatingsCount, resetRatings } from "store/actions/RatingsActionCreators"
import { setSession } from "store/actions/SessionCreators"
import { IFlexibleProps } from "./_flexible.interface"
import { getSectionClassName } from "@utils/flexibleUtil"
import _ from "lodash"

// Dynamically import components
const components = {
  AccordionAdvancedComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.AccordionAdvancedComponent),
  ),
  AccordionBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.AccordionBlock)),
  ArticleTeaserBannerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ArticleTeaserBannerBlock),
  ),
  ArticleTeaserCompositionBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ArticleTeaserCompositionBlock),
  ),
  ArticleTeaserGroupBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ArticleTeaserGroupBlock),
  ),
  BreadcrumbsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.BreadcrumbsBlock),
  ),
  ButtonGroupBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ButtonGroupBlock),
  ),
  CareerSearchFilterBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.CareerSearchFilterBlock),
  ),
  CommonSearchBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.CommonSearchBlock),
  ),
  CommonTabGroupBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.CommonTabGroupBlock),
  ),
  CompactStockTickerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.CompactStockTickerBlock),
  ),
  ContactCardsComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ContactCardsComponent),
  ),
  ContactUsBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.ContactUsBlock)),
  CsvProductBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.CsvProductBlock),
  ),
  EducationOverviewBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.EducationOverviewBlock),
  ),
  EventCalendarHorizontalBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.EventCalendarHorizontalBlock),
  ),
  EventVerticalCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.EventVerticalCardBlock),
  ),
  GoogleMapBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.GoogleMapBlock)),
  HeroBannerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.HeroBannerBlock),
  ),
  IconCardsGroupComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.IconCardsGroupComponent),
  ),
  ImageBlockBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ImageBlockBlock),
  ),
  InlineQuotationsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.InlineQuotationsBlock),
  ),
  InterestingTeaserCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.InterestingTeaserCardBlock),
  ),
  LocationsBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.LocationsBlock)),
  MediaLibraryDetailBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.MediaLibraryDetailBlock),
  ),
  MediaLibrarySearchBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.MediaLibrarySearchBlock),
  ),
  MediaPlayerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.MediaPlayerBlock),
  ),
  MediaRelatedItemsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.MediaRelatedItemsBlock),
  ),
  MenuLinkWithIconGroupBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.MenuLinkWithIconGroupBlock),
  ),
  NewsSearchBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.NewsSearchBlock),
  ),
  NewsStoriesCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.NewsStoriesCardBlock),
  ),
  OpinionLeadersTeaserComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.OpinionLeadersTeaserComponent),
  ),
  OtherNewsAndStoriesBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.OtherNewsAndStoriesBlock),
  ),
  OtherNewsAndStoriesFliterBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.OtherNewsAndStoriesFliterBlock),
  ),
  PersonaPatientProfileBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.PersonaPatientProfileBlock),
  ),
  ProductCardDetailBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ProductCardDetailBlock),
  ),
  ProductCardsComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ProductCardsComponent),
  ),
  QuickLinkCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.QuickLinkCardBlock),
  ),
  QuickLinksBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.QuickLinksBlock),
  ),
  RelatedContentBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.RelatedContentBlock),
  ),
  RestDataExportLinkBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.RestDataExportLinkBlock),
  ),
  RightPanelBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.RightPanelBlock),
  ),
  RotatingHeroBannerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.RotatingHeroBannerBlock),
  ),
  ScheduleTabsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ScheduleTabsBlock),
  ),
  ServiceTeaserCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.ServiceTeaserCardBlock),
  ),
  SnackbiteBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.SnackbiteBlock)),
  StockTickerBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.StockTickerBlock),
  ),
  SupportMaterialTeaserComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.SupportMaterialTeaserComponent),
  ),
  TableReferenceColumnBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.TableReferenceColumnBlock),
  ),
  TeaserBlockBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.TeaserBlockBlock),
  ),
  TeaserFeatureCardBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.TeaserFeatureCardBlock),
  ),
  TestimonialBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.TestimonialBlock),
  ),
  TextBlockBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.TextBlockBlock)),
  TherapyCardsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.TherapyCardsBlock),
  ),
  PostCodeLookupBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.PostCodeLookupBlock),
  ),
  VideoCardsComponent: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.VideoCardsComponent),
  ),
  WebformBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.WebformBlock)),
  WebstoreProductSearchBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.WebstoreProductSearchBlock),
  ),
  WebStoreDetails: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.WebstoreDetails),
  ),
  newsLetterSignUp: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.newsLetterSignUpBlock),
  ),
  EmbedGoogleMapsBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.EmbedGoogleMapsBlock),
  ),
  SolarTableBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.SolarTableBlock),
  ),
  PrintButtonBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.PrintButtonBlock),
  ),
  BuyNowButtonBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.BuyNowButtonBlock),
  ),
  DynamicChartBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.DynamicChartBlock),
  ),
  SimpleVideoBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.SimpleVideoBlock),
  ),
  VideoGalleryBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.VideoGalleryBlock),
  ),
  UserQuizBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.UserQuizBlock)),
  IframeWrapperBlock: dynamic(() =>
    import("./flexibleBlocks").then((module) => module.IframeWrapperBlock),
  ),
  PinProtectionBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.PinProtectionBlock)),
  FlexibleCarouselBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.FlexibleCarouselBlock)), 
  MagazineMasonryBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.MagazineMasonryBlock)),
  DecisionTreeBlock: dynamic(() => import("./flexibleBlocks").then((module) => module.DecisionTreeBlock)),
}

interface DispatchProps {
  setSession: (session: string, csrf: string) => void
  addUserData: (user: any) => void
  resetUserData: (user: any) => void
  resetRatings: (ratings: any) => void
  setUserProfile: (profile: any) => void
  resetUserAndProfile: (user: any) => void
  updateRatingsCount: (ratings: any) => void
  updatePaywallContent: (isPaywall: boolean) => void
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) => void
}

type PaywallStyle = {
  paywall?: boolean
  paywallNotice?: boolean
  paywallRoles?: string
  paywallTwoText?: string
  isPaywall3Available: boolean
  paywallText?: string
  reloadContent?: boolean
  paywallContent?: boolean
}

type BookmarksType = {
  nid: number
}

type StateTypes = {
  user: any
  ratings: any
  magazine: any
}

type Props = IFlexibleProps & PaywallStyle & DispatchProps & StateTypes & BookmarksType

const Flexible = (props: Props) => {
  const {
    nid,
    blocks = [],
    isHomepage,
    seoData,
    user,
    paywall,
    paywallNotice,
    isPaywall3Available,
    ratings,
    isInvalidToken,
    setSession,
    addUserData,
    resetUserData,
    resetRatings,
    setUserProfile,
    resetUserAndProfile,
    updateRatingsCount,
    updatePaywallContent,
    setUserStateGlobally,
    isPaywall3Hidden,
    paywallText,
    bgImage,
    bgMobImage,
    bgImageOpacity,
    bgMobImageOpacity,
    reloadContent,
    paywallContent,
  } = props

  const isMobile = useMediaQuery("(max-width: 991px)")
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const [showCredentialsError, setShowCredentialsError] = useState<boolean>(false)
  const { applicationHeaderData, applicationConfiguration } = useContext(ApplicationContext)
  const { applicationTheme } = useContext(ApplicationThemeContext)
  const route = useRouter()
  const referrer = usePreviousRoute()

  const applicationId: any = useSelector((state: any) => state.appSession.applicationId)

  let isThisHome = isHomepage
  if (!isThisHome) {
    if (route.asPath === "/") {
      isThisHome = true
    }
  }

  // *********** Ratings check ***************
  const bookmarksNodeId = useMemo(() => {
    if (!isServer()) {
      if (nid) {
        sessionStorage.setItem("nid", JSON.stringify(nid))
      }
      return JSON.parse(sessionStorage.getItem("nid") as string)
    }
  }, [nid])

  const { fetchUserProfile, fetchUserRatingsCount } = useUserProfile()

  const updateBookMarks = async (id: number, type: string, state: boolean) => {
    const ratingsResponse = await middlewarePostAPI(config.onPrem.RATINGS_API_URL, {
      entity_id: id,
      entity_type: "path_alias",
      flag_id: type,
      // uid: user?.user?.response?.session_data?.user_data?.current_user?.uid,
      flag_action: !state ? "flag" : "unflag",
    })

    // remove ratings data from store
    resetRatings({ id: undefined })

    if (!ratingsResponse.fail) {
      // success update card & update user profile
      const profileResponse = await fetchUserProfile()
      if (!profileResponse.fail) {
        setUserProfile(profileResponse)
        getUserRatingsCount()
      } else {
        resetUserAndProfile({})
      }
    } else {
      // bookmark failed
      console.log("Failed to rate!")
    }
  }

  const getUserRatingsCount = async () => {
    const countResponse = await fetchUserRatingsCount()
    if (!countResponse.fail) {
      updateRatingsCount(countResponse.response)
    } else {
      console.log("Failed to fetch ratings count!")
    }
  }

  useEffect(() => {
    if (ratings.ratings && ratings.ratings?.id) {
      if (user && user.isLoggedIn) {
        // do rating API call
        updateBookMarks(
          ratings.ratings?.id,
          ratings.ratings?.state?.type,
          ratings.ratings?.state.isChecked,
        )
      } else {
        checkForLoginModal(true).then((isShow: boolean) => {
          if (isShow) {
            // show login popup
            setShowLoginPopUp(true)
          }
        })
      }
    }
  }, [ratings, user])

  useEffect(() => {
    // added for sandoz ID
    if (applicationConfiguration?.isSandozIDEnabled && user?.isLoggedIn) {
      setShowLoginPopUp(false)
    }
  }, [applicationConfiguration, user])

  useEffect(() => {
    getUserRatingsCount()
  }, [])

  const handleBookmarks = (state: any) => {
    if (bookmarksNodeId) {
      if (user.isLoggedIn) {
        updateBookMarks(bookmarksNodeId, state?.type, state.isChecked)
      } else {
        setShowLoginPopUp(true)
      }
    } else {
      console.log("Node id is not found!")
    }
  }

  // ****************** Ratings end ***********
  // ************* paywall ***************
  useMemo(async () => {
    if (blocks && blocks.length) {
      const isPaywallEnabled = paywall ?? false
      if (isPaywallEnabled && !reloadContent && !paywallContent) {
        updatePaywallContent(isPaywallEnabled)
      }

      if (paywall) {
        const paywalldata = await getPaywallData(applicationConfiguration)

        blocks.map((bs: Array<any>) =>
          bs.map((b: any) => {
            if (b.type === "paywall") {
              b.data = paywalldata
              b.paywallCause = paywallText
            }
          }),
        )

        route.replace(route.asPath)
      }

      // ************* paywall-notice-2.0 ***************
      const isPaywallNotice = !!paywallNotice
      if (!isPaywallEnabled && !reloadContent && !paywallContent) {
        const paywallFlag = isPaywallNotice || isPaywall3Available
        updatePaywallContent(paywallFlag)
      }
    }
    // *********** end ***************
  }, [paywall, blocks])

  // ******** GTM DataLayer starts ***************
  useEffect(() => {
    const page_location = window.location.href
    const page_referrer = referrer && `${window.location.origin}${referrer}`

    const userdata = user?.user?.response?.session_data?.user_data?.current_user?.name
    const userRole = user?.profile?.response?.profile?.records?.[0]?.SCT_Primary_Specialty__c

    let pageReadyObj = {
      page_location,
      ...(page_referrer && { page_referrer }),
      user_id: userdata ? encodeString(userdata) : "",
      login_status: user.isLoggedIn,
      roles: userRole,
      area_of_expertise: userRole,
    } as { [key: string]: string }
 
    loadGTMPageView().then((isLoaded: boolean) => {
      if (isLoaded) {
        dataLayerPush(EVENTS.PAGE_READY, pageReadyObj)
      }
    })
  }, [route.asPath])
  // ******** GTM DataLayer ends ***************
  
  const handleForgotPassword = () => {
    setShowLoginPopUp(false)
  }

  const handleConfirmation = () => {
    setShowLoginPopUp(false)
  }

  // Login handler
  const handleOnSubmit = async (e: any) => {
    e.preventDefault()
    setShowIndicator(true)
    const loginData = {
      username: e.target?.elements?.email?.value,
      password: e.target?.elements?.password?.value,
    }

    const responseLogin: any = await middlewarePostAPI(config.onPrem.LOGIN_API_URL, loginData)
    if (!responseLogin.fail) {
      setShowCredentialsError(false)

      // GTM track
      const trackLogInEvent = {
        user_id: encodeString(loginData.username),
      }
      dataLayerPush(EVENTS.LOGIN, trackLogInEvent)

      // save response to state
      const updateLoginData = {
        ...responseLogin,
        loginType: LOGIN_TYPES.SOLAR,
      }
      addUserData(updateLoginData)
      setUserStateGlobally(true, LOGIN_TYPES.SOLAR)

      // set user details in cookie
      const csrfToken = responseLogin?.response?.session_data?.csrf_token
      const sessionId = responseLogin?.response?.session_data?.hashedSessionId

      /**
       * save sessionId to cookie & redux (without saving any storage)
       * set httpOnly cookie
       */
      setSession(sessionId, csrfToken)
      const salt = process.env.USER_COOKIE_SALT as string
      const isLocalhost = !!location.hostname.includes("localhost")
      const url = getAssetPrefixUrl(
        `/api/data-provider/?id=user&appid=${applicationId}`,
        isLocalhost,
      )
      const { feDomain } = getApiDomainAndLang()

      const response = await fetch(url, {
        headers: {
          sessionId: AES.encrypt(sessionId, salt).toString(),
          csrfToken: AES.encrypt(csrfToken, salt).toString(),
          domainPath: getApplicationPathFromURL(),
          fedomain: feDomain,
        },
      })
      await response.json()

      // fetch user profile
      const profileResponse = await fetchUserProfile()
      if (!profileResponse.fail) {
        setUserProfile(profileResponse)
      } else {
        console.log("Failed to fetch profile response!")
      }

      Router.reload()
    } else {
      resetUserData(responseLogin)
      setShowCredentialsError(true)
    }
    setShowIndicator(false)
  }

  type BlockType =
    | "rotating_hero_banner"
    | "hero_banner"
    | "video_cards"
    | "quick_links"
    | "webform_block"
    | "quick_link_card"
    | "breadcrumb"
    | "article_teaser_banner"
    | "text"
    | "article_teaser_composition"
    | "education_overview_block"
    | "teaser_block"
    | "productcards"
    | "contact_cards"
    | "icon_cards_group"
    | "support_material_teaser"
    | "opinion_leaders_teaser"
    | "google_map"
    | "article_teaser"
    | "service_teaser_cards"
    | "interesting_cards"
    | "schedule_tabs"
    | "accordion"
    | "latest_nas"
    | "o_nas"
    | "event_horizontal_cards"
    | "event_vertical_cards"
    | "snackbite"
    | "therapy_snackbite"
    | "education_product_card"
    | "product_card"
    | "common_tab_group"
    | "button_group"
    | "therapy_cards"
    | "other_news_and_stories"
    | "right_side_panel"
    | "table_reference_column"
    | "testimonial"
    | "media_library_detail"
    | "media_library_search"
    | "media_related_items"
    | "location_list"
    | "news_search"
    | "share_monitor"
    | "image"
    | "csv_product"
    | "rest_data_export_link"
    | "sandoz_contact_us"
    | "post_code_look_up"
    | "content_block_inline_quotation"
    | "menu_link_with_icon_group"
    | "persona_patient_profile"
    | "job_search"
    | "compact_stock_ticker"
    | "search_bar"
    | "teaser_feature_block"
    | "related_content"
    | "flex_accordion"
    | "media_container"
    | "webstore_card_list"
    | "newsletter_signup"
    | "webstore_dp"
    | "map_inframe_integration"
    | "solar_table_block"
    | "print_version_configuration"
    | "buy_now_button_block"
    | "chart"
    | "video_gallery"
    | "quiz"
    | "iframe_block"
    | "pin_protection"
    | "carousel"
    | "magazine_masonry_component"
    | "decision_tree"

  type BlockComponentMap = { [key in BlockType]: React.ComponentType<any> }

  const blockComponentMap: BlockComponentMap = {
    rotating_hero_banner: components.RotatingHeroBannerBlock,
    hero_banner: components.HeroBannerBlock,
    video_cards: components.VideoCardsComponent,
    // "paywall-notice": "paywall-notice",
    quick_links: components.QuickLinksBlock,
    webform_block: components.WebformBlock,
    quick_link_card: components.QuickLinkCardBlock,
    breadcrumb: components.BreadcrumbsBlock,
    article_teaser_banner: components.ArticleTeaserBannerBlock,
    text: components.TextBlockBlock,
    article_teaser_composition: components.ArticleTeaserCompositionBlock,
    education_overview_block: components.EducationOverviewBlock,
    teaser_block: components.TeaserBlockBlock,
    productcards: components.ProductCardsComponent,
    contact_cards: components.ContactCardsComponent,
    icon_cards_group: components.IconCardsGroupComponent,
    support_material_teaser: components.SupportMaterialTeaserComponent,
    opinion_leaders_teaser: components.OpinionLeadersTeaserComponent,
    google_map: components.GoogleMapBlock,
    article_teaser: components.ArticleTeaserGroupBlock,
    service_teaser_cards: components.ServiceTeaserCardBlock,
    interesting_cards: components.InterestingTeaserCardBlock,
    schedule_tabs: components.ScheduleTabsBlock,
    accordion: components.AccordionBlock,
    flex_accordion: components.AccordionAdvancedComponent,
    latest_nas: components.NewsStoriesCardBlock,
    o_nas: components.OtherNewsAndStoriesFliterBlock,
    event_horizontal_cards: components.EventCalendarHorizontalBlock,
    event_vertical_cards: components.EventVerticalCardBlock,
    snackbite: components.SnackbiteBlock,
    therapy_snackbite: components.SnackbiteBlock,
    education_product_card: components.ProductCardDetailBlock,
    product_card: components.ProductCardDetailBlock,
    common_tab_group: components.CommonTabGroupBlock,
    button_group: components.ButtonGroupBlock,
    therapy_cards: components.TherapyCardsBlock,
    other_news_and_stories: components.OtherNewsAndStoriesBlock,
    right_side_panel: components.RightPanelBlock,
    table_reference_column: components.TableReferenceColumnBlock,
    testimonial: components.TestimonialBlock,
    media_container: components.MediaPlayerBlock,
    media_library_detail: components.MediaLibraryDetailBlock,
    media_library_search: components.MediaLibrarySearchBlock,
    media_related_items: components.MediaRelatedItemsBlock,
    location_list: components.LocationsBlock,
    news_search: components.NewsSearchBlock,
    share_monitor: components.StockTickerBlock,
    image: components.ImageBlockBlock,
    csv_product: components.CsvProductBlock,
    rest_data_export_link: components.RestDataExportLinkBlock,
    sandoz_contact_us: components.ContactUsBlock,
    // paywall: "paywall",
    // star_rating: StarsRatingBlock,
    post_code_look_up: components.PostCodeLookupBlock,
    content_block_inline_quotation: components.InlineQuotationsBlock,
    menu_link_with_icon_group: components.MenuLinkWithIconGroupBlock,
    persona_patient_profile: components.PersonaPatientProfileBlock,
    job_search: components.CareerSearchFilterBlock,
    compact_stock_ticker: components.CompactStockTickerBlock,
    search_bar: components.CommonSearchBlock,
    teaser_feature_block: components.TeaserFeatureCardBlock,
    related_content: components.RelatedContentBlock,
    webstore_card_list: components.WebstoreProductSearchBlock,
    newsletter_signup: components.newsLetterSignUp,
    webstore_dp: components.WebStoreDetails,
    map_inframe_integration: components.EmbedGoogleMapsBlock,
    solar_table_block: components.SolarTableBlock,
    print_version_configuration: components.PrintButtonBlock,
    buy_now_button_block: components.BuyNowButtonBlock,
    chart: components.DynamicChartBlock,
    video_gallery: components.VideoGalleryBlock,
    quiz: components.UserQuizBlock,
    iframe_block: components.IframeWrapperBlock,
    pin_protection: components.PinProtectionBlock,
    carousel: components.FlexibleCarouselBlock,
    magazine_masonry_component: components.MagazineMasonryBlock,
    decision_tree: components.DecisionTreeBlock,
  }

  const getComponentByBlockType = (block: any, key: number, index: number) => {
    const colBackground = block.column === SECTION_LAYOUT.ONE_COL ? block.background : ""
    if (!user?.isLoggedIn && block.type === "paywall-notice" && !isPaywall3Available) {
      return (
        <>
          {applicationConfiguration?.paywallNotice && (
            <Container background={block?.background} key={`${index}-${key}`}>
              <Row isTemplateWrapper>
                <PaywallNotification
                  paywallNotice={applicationConfiguration?.paywallNotice}
                  roles={block?.paywallRoles}
                  loginHandler={() => setShowLoginPopUp(true)}
                />
              </Row>
            </Container>
          )}
        </>
      )
    }

    if (!user?.isLoggedIn && block.type === "paywall-field-notice" && !isPaywall3Hidden) {
      return (
        <>
          {applicationConfiguration?.paywallNotice && (
            <Container background={block?.background} key={`${index}-${key}`}>
              <Row isTemplateWrapper>
                <PaywallNotification
                  paywallNotice={applicationConfiguration?.paywallNotice}
                  roles={block.allFieldPaywallRolesText}
                  loginHandler={() => setShowLoginPopUp(true)}
                />
              </Row>
            </Container>
          )}
        </>
      )
    }

    if (block.type === "paywall" && block.data) {
      const args: Paywallprops = block.data
      return (
        <Paywall
          {...args}
          form={myProps}
          showCredentialsError={showCredentialsError}
          wrongCredentialsText={
            applicationHeaderData?.headerData?.loginFormData?.formLogin?.wrongCredentialsText
          }
          onSubmit={handleOnSubmit}
          specificDescriptionText={block.paywallCause}
          isInvalidToken={isInvalidToken}
        />
      )
    }

    if (
      block.type === "star_rating" &&
      applicationConfiguration.isRatingEnabled &&
      !block?.data?.isParentPaywalled
    ) {
      return (
        <Container background={colBackground} key={`${index}-${key}`}>
          <Row isTemplateWrapper>
            <StarsRating {...block.data} user={user} setShowLoginPopUp={setShowLoginPopUp} />
          </Row>
        </Container>
      )
    }

    const Component = blockComponentMap[block.type as keyof BlockComponentMap]
    if (Component) {
      return (
        <Component
          sectionId={block?.secId}
          block={block}
          colBackground={
            applicationTheme !== "hexal" && applicationTheme !== "rebranding" ? colBackground : ""
          }
          index={index}
          key={key}
          internalLayout={!isThisHome}
          applicationConfiguration={applicationConfiguration}
          bookmarksNodeId={bookmarksNodeId}
          handleBookmarks={handleBookmarks}
        />
      )
    }

    return null // Render default or fallback component if needed};
  }

  const extractUniqueValues = (input: any) => {
    const uniqueValues = new Set()
    input?.forEach((obj: any) => obj?.stickyStyle?.forEach((value: any) => uniqueValues.add(value)))

    return Array.from(uniqueValues)
  }

  const isRegionSticky = (regionObj: any, regionIndex: any) => {
    let stickyArr = [] as any
    Object.keys(regionObj).forEach((regionKey: any) => {
      stickyArr.push(extractUniqueValues(regionObj[regionKey]))
    })
    stickyArr = _.uniq(_.flattenDeep(stickyArr))

    return (
      (stickyArr?.includes(STICKY_STYLE?.[1]) &&
        (regionIndex === Object.keys(regionObj).length - 1 ||
          regionIndex === Object.keys(regionObj).length - 2)) ||
      ((stickyArr?.includes(STICKY_STYLE?.[2]) || stickyArr?.includes(STICKY_STYLE?.[0])) &&
        regionIndex === Object.keys(regionObj).length - 1)
    )
  }

  const getColumns = (columns: any, index: number) => {
    // return null if no components present
    if (!columns?.length) {
      return null
    }
    // single column layout components
    if (columns.length > NUMBERS.ZERO && columns?.[0]?.column === SECTION_LAYOUT.ONE_COL) {
      return (
        <>
          {columns
            .map((col: any) => col.component)
            ?.sort((col1: any, col2: any) => (col1?.weight || 0) - (col2?.weight || 0))}
        </>
      )
    }
    const regions = {} as any
    columns.forEach((col: any) => {
      if (!regions[col.region]) {
        regions[col.region] = []
      }
      regions[col.region].push(col)
    })

    const background = columns?.find((col: any) => !!col.background)?.background ?? null

    const regionConfigs = Object.keys(regions).map((regionKey) => {
      return {
        size: getColsByPercentage(regions[regionKey]?.[0]?.width || NUMBERS.HUNDRED),
      }
    })

    if (isMobile) {
      return (
        <>
          {Object.keys(regions).map((regionKey) =>
            regions[regionKey].map((col: any) => col.component),
          )}
        </>
      )
    }

    return (
      <Container key={`con-${index}`} background={background}>
        <Row className={columns?.[0]?.column} isTemplateWrapper>
          {Object.keys(regions).map((regionKey, regionIndex) => (
            <Column
              key={`col-${index}-${regionIndex}`}
              mobile={12}
              tablet={12}
              desktop={regionConfigs[regionIndex].size}
              className={`col-no-gutter-top col-${
                regionIndex === 0
                  ? "left"
                  : regionIndex === Object.keys(regions).length - 1
                  ? "right"
                  : regionIndex
              }`}>
              <div className={isRegionSticky(regions, regionIndex) ? "col-sticky" : ""}>
                {regions[regionKey]
                  .slice()
                  .sort((col1: any, col2: any) => (col1?.weight || 0) - (col2?.weight || 0))
                  .map((col: any, key: number) => (
                    <div key={key}>{col.component}</div>
                  ))}
              </div>
            </Column>
          ))}
        </Row>
      </Container>
    )
  }

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  const myProps = applicationHeaderData?.headerData?.loginFormData

  const PopupProps: IPopup = {
    showPopup: showLoginPopUp,
    showOverlay: showLoginPopUp,
    children: "",
    spinnerIcon: getAssetPrefixUrl("/assets/icons/spinner.svg"),
    closeIcon: {
      url: getAssetPrefixUrl("/assets/icons/close.svg"),
      altText: "close icon",
    },
    handlePopupShow: setShowLoginPopUp,
  }

  const bannerCTAProps: IArticleTeaserBannerProps = {
    banner: {
      heading: "New Customer",
      content: "<p>Relationships matter.</p><p>We are happy to connect with you</p>",
      buttonLabel: "Sign up",
      link: "#",
      linkExternal: true,
    },
    articleBannerImage: {
      desktopImage: {
        src: getAssetPrefixUrl("/assets/images/Desktop_Quiz_Article_Teaser_Image.jpg"),
        alt: "Sandoz Logo Desktop",
        width: 1440,
        height: 800,
      },
      mobileImage: {
        src: getAssetPrefixUrl(
          "/assets/images/Sandoz_Images/Article Teaser (Quiz)/Mobile_Quiz_Article_Teaser_Image.jpg",
        ),
        alt: "Sandoz Logo Mobile",
        width: 390,
        height: 280,
      },
    },
  }

  return (
    <>
      {seoData && <SEO {...seoData} />}
      <main className="main main-bg-wrapper">
        {(bgImage || bgMobImage) && (
          <BackgroundImage
            bgimg={isMobile && bgMobImage ? bgMobImage : bgImage || ""}
            opacity={isMobile && bgMobImage ? bgMobImageOpacity : bgImageOpacity}
          />
        )}
        {showIndicator && overlayElement()}
        {blocks &&
          blocks.map((blockList: any, index: number) => {
            if (Array.isArray(blockList)) {
              const cols = blockList?.map((block: any, idx: number) => ({
                type: block.type,
                column: block.column,
                region: block.region,
                weight: block.weight,
                background: block.background,
                bgImage: block.secBgImage,
                bgMobImage: block.secBgMobImage,
                bgOpacity: block.secBgImageOpacity,
                bgMobImageOpacity: block.secBgMobImageOpacity,
                stickyStyle: block.stickyStyle,
                width: block.width,
                id: block.secId ?? null,
                component: getComponentByBlockType(block, idx, index),
              }))
              let bgOctagonStyle
              if (
                cols?.[0]?.background?.includes("left") ||
                cols?.[0]?.background?.includes("right")
              ) {
                const classes = cols?.[0]?.background.split(/(?<=^[^_]+)_/)
                bgOctagonStyle =
                  applicationTheme === "hexal" || applicationTheme === "rebranding"
                    ? classes.join(" ")
                    : classes[0]
              } else {
                bgOctagonStyle = cols?.[0]?.background ?? "white"
              }
              return (
                <div
                  id={cols?.[0]?.id ?? "sec_" + Math.floor(Math.random() * 10000 + 1)}
                  className={getSectionClassName(cols, bgOctagonStyle)}
                  key={`sectionID-${index}`}>
                  {(blockList?.[0]?.secBgMobImage || blockList?.[0]?.secBgImage) && (
                    <BackgroundImage
                      bgimg={
                        isMobile && blockList?.[0]?.secBgMobImage
                          ? blockList?.[0]?.secBgMobImage
                          : blockList?.[0]?.secBgImage || ""
                      }
                      opacity={
                        isMobile && blockList?.[0]?.secBgMobImage
                          ? blockList?.[0]?.secBgMobImageOpacity
                          : blockList?.[0]?.secBgImageOpacity
                      }
                    />
                  )}
                  {getColumns(cols, index)}
                </div>
              )
            }
          })}

        <LoginPopup
          popup={PopupProps}
          form={myProps}
          bannerCTA={myProps?.banner}
          showPopup={showLoginPopUp}
          handlePopupShow={(popupShow, event: any) => {
            resetRatings({ id: undefined })
            setShowLoginPopUp(popupShow)
          }}
          handleForgotPasswordShow={handleForgotPassword}
          loginConfirmation={handleConfirmation}
        />
      </main>
    </>
  )
}

const mapDispatch = {
  setSession: (session: string, csrf: string) => setSession(session, csrf),
  addUserData: (user: any) => addUser(user),
  resetUserData: (user: any) => resetUser(user),
  resetRatings: (ratings: any) => resetRatings(ratings),
  setUserProfile: (profile: any) => setUserProfile(profile),
  resetUserAndProfile: (user: any) => resetUser(user),
  updateRatingsCount: (ratings: any) => getRatingsCount(ratings),
  updatePaywallContent: (isPaywall: boolean) => updatePaywallContent(isPaywall),
  setUserStateGlobally: (loggedIn: boolean, loggedInType: string) =>
    setUserStateGlobally(loggedIn, loggedInType),
}

const mapState = (state: any) => ({
  user: state.user,
  ratings: state.ratings,
  reloadContent: state.common.reloadContent,
  paywallContent: state.common.paywallContent
})

export default connect(mapState, mapDispatch)(Flexible)
