import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy } from "."
import getSpecialityFilterData from "./getSpecialityFilterData"
import getConfigDisplayData from "./getConfigDisplayData"
import getAlphabeticOrderData from "./getAlphabeticOrderData"
import getArchieveYearData from "./getArchieveYearData"
import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"
import getNestedObject from "@utils/nestedObjects"

const getProductFilterData = async (serverData?: any) => {
  let productData = await getConfigDisplayData(serverData)
  productData = productData?.data
  if (productData) {
    const productLabelData = {
      searchLabel: productData.product_list_search_cta_text,
      AlphaLabel: productData.product_list_alphabethical_text,
      FormLabel: productData.product_list_from_text,
      clearLabel: productData.product_list_search_clear_all_text,
      other_category_text: productData.product_list_category_text,
      product_title: productData.product_list_configuration_title_text,
      product_select_option: productData.news_n_stories_select_option_text,
    }
    return productLabelData
  }

  return {
    searchLabel: "",
    AlphaLabel: "",
    FormLabel: "",
    clearLabel: "",
    other_category_text: "",
    product_title: "",
    product_select_option: "",
  }
}

const getFormattedObj = (objectList: any[]) => {
  const options: any = []
  objectList?.map((option: any, key: number) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

const getwhichformFilterData = async (serverData?: any) => {
  let productformFilterData = await getArchieveYearData(serverData)
  productformFilterData = productformFilterData?.data
  return getFormattedObj(productformFilterData)
}

const getAlphabeticalData = async (serverData?: any) => {
  let alphabeticalData = await getAlphabeticOrderData(serverData)
  alphabeticalData = alphabeticalData?.data
  return getFormattedObj(alphabeticalData)
}
const getProductListCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const productcationCategoryFilterData = await getProductFilterData(serverData)
  const fieldPaywallRoles = getAllRoles(productcationCategoryFilterData)
  const specialityFilterData = await getSpecialityFilterData(serverData)
  const productFormData = await getwhichformFilterData(serverData)
  const alphabeticalData = await getAlphabeticalData(serverData)
  const filterData = {
    advanceFilterData: {
      placeholderText: productcationCategoryFilterData?.searchLabel ?? "",
      buttonText: productcationCategoryFilterData?.searchLabel ?? "",
      isCleared: true,
      filtersOptions: [
        {
          label: productcationCategoryFilterData?.AlphaLabel ?? "",
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: alphabeticalData,
            ariaLabel: "Select Element",
            name: "select",
          },
        },
        {
          label: productcationCategoryFilterData?.other_category_text ?? "",
          selectOptions: {
            isCleared: true,
            options: specialityFilterData,
            ariaLabel: "Select Element",
            name: "select",
            isMulti: true,
            placeHolder: productcationCategoryFilterData?.product_select_option,
          },
        },
        {
          label: productcationCategoryFilterData?.FormLabel ?? "",
          selectOptions: {
            isCleared: true,
            options: productFormData,
            ariaLabel: "Select Element",
            name: "select",
            isMulti: true,
          },
        },
      ],
    },
    filterTitle: productcationCategoryFilterData?.product_title ?? "",
    clearAllText: productcationCategoryFilterData?.clearLabel ?? "",
    labelItemsSelected: " selected",
  }
  let cards = await getBlockData(url, false, serverData)
  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang
  cards = cards?.data
  cards = await Promise.all(
    cards?.map(async (card: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(card, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(card?.relationships?.field_product_image)
        ? null
        : card?.relationships?.field_product_image?.data?.[0]?.id
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta({ data: card?.relationships?.field_product_image?.data?.[0] })

      const taxonomyURL = card?.relationships?.field_category?.links?.related?.href
      const taxonomies = taxonomyURL ? await getTaxonomy(taxonomyURL, true, serverData) : []

      const specialityId =
        card?.relationships?.field_product_specialty?.data?.[0]?.meta?.drupal_internal__target_id ??
        null
      const createdDate = moment(card?.attibutes?.created).locale(language).format("YYYY") ?? null
      return {
        productCardContent: {
          id: card?.attributes?.drupal_internal__nid ?? "",
          content: card.attributes.body.value ?? null,
          heading: card?.attributes?.field_display_title?.value ?? null,
          buttonLabel: card?.attributes?.field_secondary_cta?.title ?? null,
          taxonomies: taxonomies ?? null,
          link: filterURL(card?.attributes?.field_secondary_cta?.uri) ?? null,
          isLinkExternal: false,
          href: filterURL(card?.attributes?.field_secondary_cta?.uri) ?? null,
          bookmark: {
            addText: "Add Bookmark",
            removeText: "Remove Bookmark",
            bookmarkStatus: false,
            isButton: false,
          },
        },
        productCardImage: {
          src: imageUrl ?? null,
          alt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
        },
        specialityId,
        createdDate: isPaywallProtected(card?.attibutes?.created).isPaywallProtectedFlag
          ? null
          : createdDate,
      }
    }),
  )
  return {
    filterData,
    cards: filterNullValues(cards),
    pagination: {
      itemsPerPage: 9,
      itemsPerPageMobile: 9,
    },
    fieldPaywallRoles,
  }
}

export default getProductListCardData
