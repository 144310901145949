import * as actionType from "../ActionTypes"

const initialCommonData: any = {
  accordianProductFilterIndex: 0,
  paywallContent: false,
  isLoggedIn: false,
  userType: "",
  sessionToken: "",
  cmsUrl: "",
  feUrl: "",
  language: "",
  errorPageData: null,
  productCatalogTitle: "",
  paywall3Roles: null,
  refAppId: "",
}

const CommonReducer = (state: any = initialCommonData, action: CommonAction): any => {
  switch (action.type) {
    case actionType.ACCORDIAN_PRODUCT_FILTER_INDEX:
      return {
        ...state,
        accordianProductFilterIndex: action.accordianProductFilterIndex,
      }

    case actionType.PRODUCT_CATALOG_TITLE:
      return {
        ...state,
        productCatalogTitle: action.productCatalogTitle,
      }

    case actionType.PAYWALL_CONTENT:
      return {
        ...state,
        paywallContent: action.isPaywallContent,
      }

    case actionType.IS_RELOADED:
      return {
        ...state,
        reloadContent: action.isReloadedContent,
      }

    case actionType.USER_CATEGORY:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
        userType: action.userType,
        cmsUrl: action.cmsUrl,
        feUrl: action.feUrl,
        language: action.language,
      }
    case actionType.APPLICATION_ERROR_PAGE:
      return {
        ...state,
        language: action.language,
        errorPageData: action.errorPageData,
      }
    case actionType.RESET_PAYWALL_3_ROLES:
      return {
        ...state,
        paywall3Roles: null,
      }
    case actionType.SET_PAYWALL_3_ROLES:
      return {
        ...state,
        paywall3Roles: state.paywall3Roles
          ? [...state.paywall3Roles, ...action.paywall3Roles]
          : action.paywall3Roles,
      }
    case actionType.SET_REF_APP_ID:
      return {
        ...state,
        refAppId: action.refAppId,
      }
    default:
      return state
  }
}

export default CommonReducer
