import { Anchor, Icon } from "@atoms"
import { IRightPanelProps, RightSidePanelInfo } from "./_right-panel.interface"

const getLinkData = (rightSidePanelInfo: RightSidePanelInfo[]) => (
  <ul className="link-list">
    {rightSidePanelInfo?.map((childItem, index) => {
      if (childItem.referencePath && childItem.referenceTitle) {
        return (
          <li key={index}>
            <Anchor href={childItem.referencePath} tabindex={index} noUnderline>
              <span className="link-list-text">
                {" "}
                {childItem.referenceTitle}
              </span>

              <Icon iconName="ChevronRightIcon5" className="link-list-icon arrow" width={32} height={32} />
            </Anchor>
            <hr className="link-list-bar" />
          </li>
        )
      }
      return <></>
    })}
  </ul>
)

const RightPanel = ({ rightPanelTitle, rightSidePanelInfo }: IRightPanelProps) => (
  <div className="right-panel">
    <h3 className="header">{rightPanelTitle}</h3>
    {rightPanelTitle && <hr className="bar" />}
    {!!rightSidePanelInfo?.length && getLinkData(rightSidePanelInfo)}
  </div>
)

export default RightPanel
