import { useContext, useEffect, useState } from "react"
import { createPortal } from "react-dom"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import FocusLock from "react-focus-lock"
import { IModalPortalProps } from "./_modalPortal.interface"

const ModalPortal = (props: IModalPortalProps) => {
  const {
 wrapperId, modalContent, tabIndex, className = "", isHCP,
} = props
  const [wrapperElement, setWrapperElement] = useState(null as HTMLElement | null)
  const { pageTheme } = useContext(ApplicationThemeContext)

  useEffect(() => {
    // assure there is always a container for the portal
    let element = document.getElementById(wrapperId)
    let systemCreated = false
    if (!element) {
      systemCreated = true
      element = createWrapperAndAppendToBody(wrapperId, pageTheme)
    }
    setWrapperElement(element)
    return () => {
      if (systemCreated && element && element.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [])

  const getClasses = () => {
    const classes = ["modal", className].filter(Boolean)
    isHCP && classes.push("hcp")
    return classes.join(" ")
  }

  if (wrapperElement === null) return null

  const content = (
    <div className={getClasses()} tabIndex={tabIndex} role="dialog">
      <FocusLock>
        <div className="modal-container">{modalContent}</div>
      </FocusLock>
      <div className="modal-background" />
    </div>
  )

  return createPortal(content, wrapperElement)
}

const createWrapperAndAppendToBody = (wrapperId: string, pageTheme:string) => {
  const wrapperElement = document.createElement("div")
  wrapperElement.setAttribute("id", wrapperId)
  if (pageTheme) {
    wrapperElement.setAttribute("data-theme-color", pageTheme)
  }
  document.body.appendChild(wrapperElement)
  return wrapperElement
}

export default ModalPortal
