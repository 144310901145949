import { middlewareGETAPI, middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { useCallback } from "react"

const useUserProfile = () => {
  const fetchUserProfile = useCallback(async () => {
    const profileResponse = await middlewarePostAPI(config.onPrem.GET_PROFILE_URL, {})
    return profileResponse
  }, [])

  const fetchUserRatingsCount = useCallback(async () => {
    const ratingCount = await middlewareGETAPI(config.onPrem.RATINGS_COUNT_API_URL)
    return ratingCount
  }, [])

  const checkForDCR = useCallback(async (userEmail: string, app_id?: string) => {
    const dcrResponse = await middlewarePostAPI(config.onPrem.CHECK_DCR_URL, {
      email: userEmail,
      status: "Pending Feedback",
      app_id,
    })

    if (!dcrResponse.fail) {
      const dcrData = (dcrResponse.response as any)?.dcr_data?.records
      const dcrId = dcrData && dcrData.length ? dcrData[0].Id : 0

      return {
        status: dcrData && dcrData.length ? true : false,
        dcrId,
      }
    } else {
      return {
        status: false,
      }
    }
  }, [])

  return {
    fetchUserProfile,
    fetchUserRatingsCount,
    checkForDCR,
  }
}

export default useUserProfile
