import React, { useContext } from "react"
import { Anchor, Column, Container, Img, RichText, Row } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import { getHomepageurl } from "@utils/helper"
import { IFooterLite } from "./_footerLite.interface"
import moment from "moment"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApiDomainAndLang } from "@utils/baseApi"

const FooterLite = (props: IFooterLite) => {
  const { footerBottomSection, footerSecondaryMenu } = props
  const isMobile = useMediaQuery("(max-width: 767px)")
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { lang } = getApiDomainAndLang()

  const footerLogoLink = applicationConfiguration?.siteConfig?.footer_logo_link

  return (
    <Container fluid className="footerLite">
      <Container>
        <Row className="no-gap">
          <Column mobile={12} tablet={3} desktop={3} className="logo-area">
            <Anchor href={footerLogoLink?.length > 1 ? footerLogoLink : getHomepageurl()} tabindex={0} noUnderline={true} className="logo">
              <Img
                alt={footerBottomSection?.footer_logo_alt}
                src={footerBottomSection?.logoLink}
                width={168}
                height={20}
                className="img-fluid"
                type="icon"
              />
            </Anchor>
          </Column>
          <Column mobile={12} tablet={9} desktop={9} className="quicklink-area">
            <div className="footerLite-top">
              {footerSecondaryMenu?.map((item, index: number) => (
                <Anchor href={item.link} tabindex={0} key={index} target={item.targetType} noUnderline={true}>
                  {item.title}
                </Anchor>
              ))}
            </div>
          </Column>
          {!isMobile ? <hr className="footerLite-bar" /> : <></>}
          <div className="footerLite-bottom">
            {footerBottomSection?.copyRightText && (
              <RichText className="footerLite-first" content={footerBottomSection?.copyRightText} />
            )}
            {(footerBottomSection?.date || footerBottomSection?.footerText) && (
              <RichText
                className="footerLite-second"
                content={
                  footerBottomSection?.footerText +
                  moment().locale(lang).format(applicationConfiguration?.siteConfig?.date_format)}
              />
            )}
          </div>
        </Row>
      </Container>
    </Container>
  )
}

export default FooterLite
