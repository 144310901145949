import React, { useContext } from "react"
import { Anchor, Button, Seperator } from "@atoms"
import { useSelector } from "react-redux"
import { isDocCheckUser } from "@utils/helper"
import { IUserMenu, UserMenuType } from "./_userMenu.interface"
import useSandozIDAuth from "@helpers/hooks/useSandozIDAuth"
import ApplicationContext from "@utils/application-context/applicationContext"
// import _ from "lodash"
// import { getApiDomainAndLang } from "@utils/baseApi"

const getUserMenuDetails = (
  menu: UserMenuType[],
  getUrlWithParams: (url: string) => string,
): JSX.Element => (
  <ul className="user-menu-list" role="menu" aria-label="menulist">
    {menu
      ?.filter((item, index) => index <= 6)
      .map((list: UserMenuType, index) => {
        if (list.title) {
          // if configured link is global site Url then append current site app_id on link
          const redirectionLink = list?.isGlobalLink ? getUrlWithParams(list.link) : list.link
          return (
            <li key={index} role="menuitem" tabIndex={index + 1}>
              <Anchor
                className="user-menu-list-nav-item"
                href={redirectionLink}
                tabindex={index + 1}
                noUnderline
                isExternal={list.link_type}
                aria-label={list.title}>
                <h5 className="link-list-text bold">{list.title}</h5>
              </Anchor>
              <Seperator className="separator" aria-hidden="true" />
            </li>
          )
        }
        return null
      })}
  </ul>
)

const UserMenu = ({
  userName,
  userMenuDetails,
  makeUserLogout,
  isBtnPrimary = false,
}: IUserMenu): JSX.Element => {
  const { user_menu_logged_in, ctaMenuLink = [], user_menu_logged_out } = userMenuDetails
  const user: any = useSelector((state: UserState) => state.user)
  const isSolarLogin = !isDocCheckUser(user)

  /**
   * @todo
   * Remove once code is stable
   * add language in global account path (eg. en)
   */
  // const menuLinks = useMemo(() => {
  //   const menuLinksTemp = _.clone(ctaMenuLink)
  //   const { lang } = getApiDomainAndLang()
  //   menuLinksTemp?.push({
  //     title: "My Account nav local",
  //     link: `http://localhost:5000/${lang}/my-profile`,
  //     link_type: true,
  //     weight: "10",
  //     isGlobalLink: true,
  //   })
  //   return menuLinksTemp
  // }, [ctaMenuLink])

  const { applicationConfiguration } = useContext(ApplicationContext)
  const { getUrlWithParams } = useSandozIDAuth(applicationConfiguration)

  return (
    <div className="user-menu" aria-expanded={true}>
      {isSolarLogin && (
        <div
          className="user-menu-login cursor-default"
          aria-labelledby="user-menu-login"
          onClick={(e) => e.stopPropagation()}>
          <h5 className="user-menu-greetings" id="user-menu-login">
            {user_menu_logged_in}
          </h5>
          <h5 className="user-menu-username">{userName}</h5>
        </div>
      )}
      {Array.isArray(ctaMenuLink) && ctaMenuLink.length > 0 && isSolarLogin && (
        <nav className="mt-4" aria-label="menulist">
          {getUserMenuDetails(ctaMenuLink, getUrlWithParams)}
        </nav>
      )}
      <Button
        className="mb-0"
        tabindex={0}
        isSecondary={!isBtnPrimary}
        onClick={makeUserLogout}
        aria-label={user_menu_logged_out}>
        <span className="user-menu-logoff">{user_menu_logged_out}</span>
      </Button>
    </div>
  )
}

export default UserMenu