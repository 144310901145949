import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getImageMeta } from "."

const getImageBlockData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const rawData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const imagePaywallData = isPaywallProtected(rawData?.data?.relationships?.field_images)
  const imageDatas = rawData?.data?.relationships?.field_images?.data ?? []
  const mobileImagePaywallData = isPaywallProtected(rawData?.data?.relationships?.field_image_mobile_images)
  const mobileImageDatas = rawData?.data?.relationships?.field_image_mobile_images?.data ?? []

  let images: any = []
  images = imagePaywallData.isPaywallProtectedFlag
    ? [getPaywalledImgData(imagePaywallData.errorObj)]
    : await Promise.all(
        imageDatas.map(async (image: any) => {
          const { imageUrl, imageStyledUrl, alt, width, height } = getImageMeta({ data: image })
          return {
            desktopImage: {
              src: imageUrl ?? null,
              alt: alt ?? "",
              width: width ?? null,
              height: height ?? null,
              styledimage: imageStyledUrl ?? null
            },
          }
        }),
      )

  let mobileImages: any = []
  mobileImages = mobileImagePaywallData.isPaywallProtectedFlag
    ? [getPaywalledImgData(mobileImagePaywallData.errorObj)]
    : await Promise.all(
        mobileImageDatas.map(async (image: any) => {
            const { imageUrl, imageStyledUrl, alt, width, height } = getImageMeta({ data: image })
            return {
              mobileImage: {
                src: imageUrl ?? null,
                alt: alt ?? "",
                width: width ?? null,
                height: height ?? null,
                styledimage: imageStyledUrl ?? null
              },
            }
        }),
      )
  const updatedImgArr = images.map((obj1: any, index: number) => {
    const obj2 = mobileImages[index]
    return { ...obj1, ...obj2 }
  })
  return {
    images: updatedImgArr,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getImageBlockData