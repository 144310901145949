import {
  Button,
  CheckBox,
  Column,
  DatePicker,
  DropDown,
  Img,
  Recaptcha,
  RichText,
  SuggestionBar,
  Tooltip,
} from "@atoms"
import { IProductData, IProductsTableProps } from "@atoms/productsTable/_productsTable.interface"
import RadioButton from "@atoms/radioButton"
import TimePicker from "@atoms/timePicker"
import NUMBERS from "@helpers/constants/numbers"
import useUserProfile from "@helpers/hooks/useUserProfile"
import DisplayMessage from "@molecules/displayMessage"
import InputBoxEmailValidation from "@molecules/inputBoxEmailValidation"
import InputBoxWithLabel from "@molecules/inputBoxWithLabel"
import WebFormButton from "@molecules/webFormButton"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApi, getApiDomainAndLang, middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { EVENTS } from "@utils/gtmEvents"
import { handleDataLayerError } from "@utils/gtmUtilsHelpers"
import { dataLayerPush } from "@utils/gtmutils"
import { getAssetPrefixUrl, getProfileDetails } from "@utils/helper"
import { useRouter } from "next/router"
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import {
  IFieldsData,
  IGenericWebFormProps,
  IOptionData,
  IValidationsData,
} from "./_genericWebForm.interface"

const GenericWebForm = ({
  fields,
  notificationMessages,
  initFormValues = null,
  webformAlignment,
  handleOnSubmit,
  handleOnCancel,
  categoryType,
  redirectUrl,
  scrollToEle,
  theme,
}: IGenericWebFormProps) => {
  const [errors, setErrors] = useState<any>({})
  const [formValues, setFormValues] = useState<any>({})
  const [formSubmitValues, setFormSubmitValues] = useState<any>({})
  const [showIndicator, setShowIndicator] = useState<boolean>(false)
  const [isFormCleared, setIsFormCleared] = useState(false)
  const [clearedFields, setClearedFields] = useState<string[]>([])
  const [showToast, setShowToast] = useState<"success" | "error" | "">("")
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(true)
  const [recaptchaKey, setRecaptchaKey] = useState("")
  const [productTableData, setProductTableData] = useState<IProductsTableProps>({
    asset_attributes_list: [],
    product_data: [],
    total_product_count: 0,
    productFamilyName: "",
    attributeData: [],
  })
  const [selectedProducts, setSelectedProducts] = useState<IProductData[]>([])
  const [logoImage, setLogoImage] = useState<any>()
  const [isClear, clearText] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isSmartCountryReadOnly, setIsSmartCountryReadOnly] = useState<boolean>(false)
  const multiSelectRef = useRef<any>(null)

  const formName = fields?.[0]?.formName
  let submitUrl = config?.onPrem?.WEBFORM_SUBMIT
  const user = useSelector((state: UserState) => state?.user)
  const router = useRouter()
  const { applicationConfiguration } = useContext(ApplicationContext)
  const { feDomain } = getApiDomainAndLang()

  const stateAppId = useSelector((state: any) => state?.common?.refAppId)

  // states specific to DCR form type
  const { checkForDCR } = useUserProfile()
  enum Action {
    EDIT = "edit",
    VIEW = "view",
  }
  const [isPending, setIsPending] = useState(false)
  const [pendingMessage, setPendingMessage] = useState("")

  const [mode, setMode] = useState<string>(Action.EDIT)
  // end
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [channelOptions, setChannelOptions] = useState([])

  useEffect(() => {
    const fetchChannelOptions = async () => {
      if (selectedCountry) {
        try {
          const response = await getApi(`/restapi/pim/sendout-channel?country=${selectedCountry}`)
          const formattedChannelOptions = response.map((channel: any, index: number) => ({
            id: index,
            label: channel.name,
            value: channel.id,
          }))
          setChannelOptions(formattedChannelOptions)
        } catch (error) {
          console.error(error)
        }
      }
    }
    fetchChannelOptions()
    return () => {
      setChannelOptions([])
    }
  }, [selectedCountry])

  useEffect(() => {
    if (!!initFormValues) {
      const newFormValues = {
        ...formValues,
      }
      fields?.forEach((f: IFieldsData) => {
        if (f.key && Object.keys(initFormValues).indexOf(f.key) >= 0) {
          newFormValues[f.key] = initFormValues[f.key]
        }
      })
      setFormValues(newFormValues)
      setFormSubmitValues(newFormValues)
    }
    let path = feDomain?.includes("/") ? feDomain?.split("/")?.[1] : null
    path = path?.split("-")?.reverse()?.join("-")?.toLowerCase() ?? null
    const smartCountry =
      fields?.find((field: any) => field?.key === "smart_mi_country") ?? null
    const isCountryPrefilled =
      smartCountry?.options?.find((opt: any) => opt?.countryCode?.toLowerCase()?.includes(path)) ?? null
    if (!!isCountryPrefilled) {
      const newFormValues = {
        ...formValues,
        smart_mi_country: [isCountryPrefilled?.value],
      }
      setIsSmartCountryReadOnly(true)
      setFormValues(newFormValues)
      setFormSubmitValues(newFormValues)
    }
  }, [])

  useEffect(() => {
    if (showIndicator === false) {
      fields?.map((field: any) => {
        if (field.type === "checkbox") {
          setFormValues((prevData: any) => ({
            ...prevData,
            [`${field.key}`]: field.isChecked,
          }))
          setFormSubmitValues((prevData: any) => ({
            ...prevData,
            [`${field.key}`]: field.isChecked,
          }))
        }
      })
    }
  }, [isFormCleared])

  const getClasses = () => {
    if (categoryType === "sample_ordering") {
      return "sample_ordering"
    }
    return `free-web-form ${webformAlignment ? `free-web-form-${webformAlignment}` : ""} ${
      theme === "tyruko" ? "free-web-form-full-bleed" : ""
    }`
  }

  const getRecaptchaSiteKey = async () => {
    const { location } = window
    const isLocalhost = !!location.hostname.includes("localhost")
    const url = getAssetPrefixUrl("/api/data-provider/?id=recaptcha", isLocalhost)
    const response = await fetch(url)
    const variables = response.json()
    return variables
  }

  useEffect(() => {
    const recaptcha = fields?.find((f: any) => f.type === "custom_recaptcha_element")
    if (recaptcha && !recaptcha.isDisabled) {
      if (recaptcha.recaptchaSiteKey) {
        setRecaptchaKey(recaptcha.recaptchaSiteKey)
      } else {
        getRecaptchaSiteKey().then((variables) => {
          setRecaptchaKey(variables.recaptchaSiteKey || "")
        })
      }
      setRecaptchaVerified(false)
    }
  }, [])

  const prefillUserData = () => {
    const userProfileData = getProfileDetails(user)
    const { first_name, last_name, email_address } = userProfileData
    const fieldsToRemove: string[] = ["first_name", "last_name", "email_address"]

    const tempFormVals = {} as any
    const tempFormSubmitVals = {} as any
    // Pre populate input fields with Scout values for dcr and hcp_callback form type
    if (categoryType === "dcr" || categoryType === "hcp_callback") {
      fields?.map((f: IFieldsData) => {
        if (f.scoutFieldName && f.key) {
          const opt = userProfileData?.[f.scoutFieldName]
          switch (f.type) {
            case "select":
            case "webform_term_select":
              const val = opt ? f?.options?.find((op: IOptionData) => f.type === "select" ? op.value == opt : op.label == opt) : null
              if (val) {
                tempFormVals[f.key as string] = [val]
                tempFormSubmitVals[f.key as string] = f.isMulti ? [val.value] : val.value
              }
              break
            default:
              tempFormVals[f.key as string] = opt
              tempFormSubmitVals[f.key as string] = opt
          }
          fieldsToRemove.push(f.key)
        }
      })
      setFormValues({ ...tempFormVals })
      setFormSubmitValues({ ...tempFormSubmitVals })
    } else {
      setFormValues({
        ...formValues,
        first_name,
        last_name,
        email_address,
      })
      setFormSubmitValues({ ...formSubmitValues, first_name, last_name, email_address })
    }

    setErrors({
      ...errors,
      first_name: undefined,
      last_name: undefined,
      email_address: undefined,
    })
    const errorObj = { ...errors }
    fieldsToRemove.forEach((field) => {
      if (field in errorObj) {
        delete errorObj[field]
      }
    })
    setErrors({
      ...errorObj,
    })
  }
  const checkForDCRUpdate = () => {
    // check for DCR
    const userProfileDetails = user.profile?.response?.profile?.records?.[0] ?? {}
    const warningMsg = notificationMessages?.warning_message || "warning"
    if (userProfileDetails?.PersonEmail && categoryType === "dcr") {
      const isGlobalSite = applicationConfiguration?.isGlobalSite || false
      const appId: any = isGlobalSite ? router?.query?.app_id || stateAppId : undefined
      checkForDCR(userProfileDetails.PersonEmail, appId).then((dcr: any) => {
        if (dcr.status) {
          setMode(Action.VIEW)

          const responseMessage = `${warningMsg} ${dcr.dcrId}`
          setPendingMessage(responseMessage)
          setIsPending(true)
        }
      })
    }
  }

  useEffect(() => {
    if (user && user.isLoggedIn && !!categoryType) {
      prefillUserData()
      if (categoryType === "dcr") {
        checkForDCRUpdate()
      }
    }
  }, [user])

  const excludedTypes: string[] = ["processed_text", "button", "webform_actions"]
  const getDependentFieldNames = (fieldName: string) => {
    const dependents = fields?.filter((field: IFieldsData) => {
      if (!field?.conditionalShow?.conditions || excludedTypes.includes(field.type)) {
        return false
      }
      return !!field?.conditionalShow?.conditions?.filter(
        (cond: any) => cond?.selector?.indexOf(fieldName) >= NUMBERS.ZERO,
      )?.length
    })
    return dependents.map((d: any) => d.key)
  }

  const dependencyTree: any = fields?.reduce((acc, curr: any) => {
    let newAcc = { ...acc }
    const dependents = getDependentFieldNames(curr.key)
    Object.entries(acc).forEach(([key, value]: [string, any]) => {
      const deps = value
      if (value.includes(curr.key)) {
        dependents?.forEach((newDep: string) => !deps.includes(newDep) && deps.push(newDep))
      }
      newAcc = {
        ...newAcc,
        [key]: deps,
      }
    })
    return {
      ...newAcc,
      [curr.key]: dependents,
    }
  }, {})

  const clearDependents = (
    fieldName: string,
    newFormValues: any,
    newSubmitValues: any,
    updatedErr?: object,
  ): any => {
    const deps = dependencyTree[fieldName]
    if (!deps?.length) {
      return
    }
    const tempFormValues = { ...newFormValues }
    const tempSubValues = { ...newSubmitValues }
    const tempErrors = !!updatedErr ? { ...updatedErr } : { ...errors }
    const clearFields: string[] = []
    deps.forEach((key: string) => {
      delete tempFormValues[key]
      delete tempSubValues[key]
      delete tempErrors[key]
      clearFields.push(key)
    })
    setClearedFields(clearFields)
    setFormValues(tempFormValues)
    setFormSubmitValues(tempSubValues)
    setErrors(tempErrors)
  }

  const checkForError = (fieldValue: any, validations: IValidationsData) => {
    const { mandatory, pattern } = validations
    if (fieldValue?.length <= NUMBERS.ZERO && mandatory.required) {
      return mandatory.message
    }
    if (
      fieldValue.length > NUMBERS.ZERO &&
      pattern?.required &&
      pattern?.regEx &&
      !RegExp(pattern?.regEx).test(fieldValue)
    ) {
      return pattern.message
    }
    return null
  }

  const handleEmailError = (fieldName: string, error: string | null) => {
    const newErrors = { ...errors }
    if (error) {
      newErrors[fieldName] = error
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)
  }

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement> | React.FocusEvent<HTMLTextAreaElement, Element>,
    validations: any,
  ) => {
    e.preventDefault()
    const fieldName = `${e.target.name}`

    const fieldValue = e.target.value.trim()
    const newErrors: any = { ...errors }
    const err = checkForError(fieldValue, validations)
    if (err) {
      newErrors[fieldName] = err
    } else {
      delete newErrors[fieldName]
    }
    setErrors(newErrors)
    clearDependents(fieldName, formValues, formSubmitValues, newErrors)
  }

  const handleProductTableChange = (updatedProducts: IProductData[]) => {
    setSelectedProducts(updatedProducts)
  }
  const handleChange = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
    validations = null,
  ) => {
    const fieldName = `${e.target.name}`
    const fieldValue = e.target.value.trim()
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldValue
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    if (validations) {
      const newErrors: any = { ...errors }
      const err = checkForError(fieldValue, validations)
      if (err) {
        newErrors[fieldName] = err
      } else {
        delete newErrors[fieldName]
      }
      setErrors(newErrors)
    }
  }
  const handleTextareaChange = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fieldName = `${e.target.name}`
    const fieldValue = e.target.value
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldValue.trim()
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    clearDependents(fieldName, newFormValues, newSubmitFormValue)
  }

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string | null,
    fieldName: string,
    optionName = "",
    validations = null,
  ) => {
    let fieldValue
    let fieldSubmitValue
    if (type === "group") {
      fieldValue = formValues[fieldName]
        ? {
            ...formValues[fieldName],
            [optionName]: e.target.checked,
          }
        : {
            [optionName]: e.target.checked,
          }
      if (e.target.checked) {
        fieldSubmitValue = formSubmitValues[fieldName]
          ? [...formSubmitValues[fieldName], optionName]
          : [optionName]
      } else {
        fieldSubmitValue = formSubmitValues[fieldName].filter((val: string) => val !== optionName)
      }
    } else {
      fieldValue = e.target.checked
      fieldSubmitValue = e.target.checked
    }
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldSubmitValue
    const newErrors: any = { ...errors }
    if (validations) {
      const err = checkForError(
        type !== "group" && !fieldSubmitValue ? [] : fieldSubmitValue,
        validations,
      )
      if (err) {
        newErrors[fieldName] = err
      } else {
        delete newErrors[fieldName]
      }
      setErrors(newErrors)
    }
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    clearDependents(fieldName, newFormValues, newSubmitFormValue, newErrors)
  }

  const handleDateChange = (newDate: string, fieldName: string) => {
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = newDate
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = newDate
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
  }

  const clearSelection = (targetId: string) => {
    if (multiSelectRef.current) {
      const clearIcon = multiSelectRef?.current?.querySelector(
        `#${targetId} .clear-selected-button`,
      )
      if (clearIcon) {
        clearIcon.click()
      }
    }
  }

  const handleDropdownChange = async (val: any, fieldName: string, isMulti: boolean) => {
    const fieldValue = val?.length ? val?.map((a: any) => a?.value) : []
    const newFormValues: any = { ...formValues }
    if (!isSmartCountryReadOnly || (isSmartCountryReadOnly  && fieldName !== "smart_mi_country")) {
      newFormValues[fieldName] = fieldValue
    }
    const newSubmitFormValue: any = { ...formSubmitValues }
    if ((fieldName === "soi_2_country" || fieldName === "country")) {
      setSelectedCountry(fieldValue)
      newFormValues["soi_2_channel"] = []
      newSubmitFormValue["soi_2_channel"] = []
      newFormValues["channel"] = []
      newSubmitFormValue["channel"] = []
      const targetId = fieldName === "soi_2_country" ? "soi_2_channel" : "channel"
      clearSelection(targetId)
    }
    if (isMulti) {
      newSubmitFormValue[fieldName] = fieldValue
    } else if (fieldValue?.length) {
      newSubmitFormValue[fieldName] = fieldValue[NUMBERS.ZERO]
    } else {
      delete newSubmitFormValue[fieldName]
    }
    setFormValues(newFormValues)
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    delete errors[fieldName]
    clearDependents(fieldName, newFormValues, newSubmitFormValue)
    const logoData = fields?.filter((item: any) => {
      if (item?.logoFieldValue?.length > 0) {
        return item
      } else return null
    })
    const logoImageData = logoData?.[0]?.logoFieldValue?.filter(
      (item: any) => item?.label === val?.[0]?.label,
    )
    setLogoImage(logoImageData?.[0]?.field_logo)
  }

  const handleConfirmEmailChange = (fieldName: string, fieldValue: any) => {
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldValue?.email
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    clearDependents(fieldName, newFormValues, newSubmitFormValue)
  }

  const recaptchaOnChangeHandler = (token: string | null) => {
    if (token) {
      setRecaptchaVerified(true)
    } else {
      setRecaptchaVerified(false)
    }
  }

  const handleCancel = (invokeCancel = true) => {
    setShowIndicator(false)
    setClearedFields([])
    if (handleOnCancel && invokeCancel) {
      handleOnCancel()
    }
    if (!handleOnCancel) {
      let newFormValues
      let newSubmitFormValue
      if (isSmartCountryReadOnly) {
        const { smart_mi_country } = formValues
        newFormValues = { smart_mi_country }
        newSubmitFormValue = { smart_mi_country: smart_mi_country?.[0] }
      } else {
        newFormValues = {}
        newSubmitFormValue = {}
      }
      setFormValues(newFormValues)
      setFormSubmitValues(newSubmitFormValue)
      setIsFormCleared(true)
      clearText((prevIsClear) => !prevIsClear)
      setProductTableData({
        asset_attributes_list: [],
        product_data: [],
        total_product_count: 0,
        productFamilyName: "",
        attributeData: [],
      })
    }
    if (categoryType === "dcr" || categoryType === "hcp_callback") {
      prefillUserData()
      if (categoryType === "dcr") {
        checkForDCRUpdate()
      }
    }
    setErrors({})
    setTimeout(() => {
      if (scrollToEle) {
        document?.getElementsByClassName(scrollToEle)[0]?.scrollIntoView()
      } else {
        window?.scrollTo({ left: NUMBERS.ZERO, top: NUMBERS.ZERO, behavior: "smooth" })
      }
    }, NUMBERS.HUNDRED)
  }

  const handleSearchText = (text: string, id: string, name: string, productFoundText: string) => {
    const fieldName = name
    const fieldValue = {
      id: id,
      value: text,
      productFoundText: productFoundText,
    }
    const newFormValues: any = { ...formValues }
    newFormValues[fieldName] = fieldName === "smart_med_enq_product" ? text : fieldValue
    setFormValues(newFormValues)
    const newSubmitFormValue: any = { ...formSubmitValues }
    newSubmitFormValue[fieldName] = fieldName === "smart_med_enq_product" ? text : fieldValue
    setFormSubmitValues(newSubmitFormValue)
    setIsFormCleared(false)
    clearDependents(fieldName, newFormValues, newSubmitFormValue)
  }

  const handleSearchAction = async (familyID: string, country: string, channel: string) => {
    setIsLoading(true)
    try {
      const productsResponse = await getApi(
        `/restapi/pim/csv-product-listing-sendout?pim_brand=${familyID}&country=${country}&channel=${channel}&page=all`,
      )
      setProductTableData(productsResponse)
      setIsLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error)
      setProductTableData({
        asset_attributes_list: [],
        product_data: [],
        total_product_count: 0,
        productFamilyName: "",
        attributeData: [],
      })
    }
  }

  const showToastMessage = (val: "success" | "error" | "") => {
    setShowToast(val)
    setTimeout(() => {
      setShowToast("")
    }, NUMBERS.TEN_THOUSAND)
  }

  const getAllFieldErrors = (e: any) => {
    e.preventDefault()
    const newErrors = { ...errors }
    fields?.forEach((item: any) => {
      const key: string = item.key ?? ""
      const value = formValues[key] ?? ""
      if (item.validations && !errors[key]) {
        let err
        if (item.type === "checkbox" && !item.conditionalShow) {
          err = checkForError(!value ? [] : value, item.validations)
       } else if (item?.type === "webform_email_confirm" && value?.email !== value?.confirmEmail) {
          err = item?.validationErrorMessage;
        } else {
          // if item is conditionally shown, ignore hidden mandatory fields
          err =
            !item.conditionalShow ||
            (item.conditionalShow && shouldRenderFormField(item.conditionalShow, item.key))
              ? checkForError(value, item.validations)
              : false
        }

        if (err) {
          newErrors[key] = err
        } else {
          delete newErrors[key]
        }
      }
    })

    const captchaElement = document.getElementsByClassName("generic-webform-recaptcha")
    if (!isRecaptchaVerified) {
      captchaElement?.[0]?.classList.add("invalid")
    } else {
      captchaElement?.[0]?.classList.remove("invalid")
    }
    const errLength = Object.keys(newErrors).length
    if (errLength || isRecaptchaVerified === false) {
      setErrors(newErrors)
      if (errLength && Object.keys(newErrors)?.[0]) {
        return Object.keys(newErrors)?.[0] ?? "window"
      }
      return "window"
    }
    return false
  }

  const updateSubmitValues = (inputObject: any, valueArray: IProductData[]) => {
    // Create a copy of the input object
    const updatedObject = { ...inputObject }
    // Remove the "soi_2_search_autocomplete" key from the object
    delete updatedObject["soi_2_search_autocomplete"]
    // Add the "product_data" key with the given array value if array value exists
    if (valueArray.length > 0) {
      updatedObject["product_data"] = valueArray
    }
    return updatedObject
  }

  const handleSubmit = async (
    e:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault()
    let callCancelHandler = true
    const err = getAllFieldErrors(e)

    if (err) {
      const ele = err !== "window" ? document.getElementById(err) : null
      const leftPos = ele ? ele.offsetLeft : NUMBERS.ZERO
      const topPos =
        ele && ele.offsetTop > NUMBERS.FORTY ? ele.offsetTop - NUMBERS.FORTY : NUMBERS.ZERO

      setTimeout(() => {
        window?.scrollTo({ left: leftPos, top: topPos, behavior: "smooth" })
      }, NUMBERS.HUNDRED)
    } else {
      try {
        setShowIndicator(true)
        const submitValues =
          formName === "pim_sendout_interface_two"
            ? updateSubmitValues({ ...formSubmitValues }, selectedProducts)
            : { ...formSubmitValues }
        if (categoryType === "dcr" || categoryType === "hcp_callback") {
          const userProfileData = getProfileDetails(user)
          submitValues.scout_id = userProfileData?.scout_id ?? ""
          submitValues.app_id = router?.query?.app_id || ""
        }
         
        const wde_number = fields?.find((f: any) => f.key === "wde_number")

        if(wde_number && router?.query?.wde){
            submitValues.wde_number = window.location.href
        }
       const { fail, error } = await middlewarePostAPI(submitUrl, {
          webform_id: formName,
          ...submitValues,
        })
        setShowIndicator(false)
        callCancelHandler = false
        if (fail) {
          showToastMessage("error")
        } else {
          if (handleOnSubmit) {
            handleOnSubmit(notificationMessages.success_message ?? "success")
          }
          if (redirectUrl) {
            router.push(redirectUrl)
            return redirectUrl
          }
          showToastMessage("success")
          setProductTableData({
            asset_attributes_list: [],
            product_data: [],
            total_product_count: 0,
            productFamilyName: "",
            attributeData: [],
          })
        }
        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: fail ? `${Object.keys(error?.data?.error)?.join(", ")} missing` ?? "" : "",
          form_status: fail ? "error" : "submitted",
        }
        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)
      } catch (error) {
        setShowIndicator(false)
        if (error) {
          showToastMessage("error")
        }
        const trackFormSubmissionEvent = {
          form_name: formName,
          form_error: error,
          form_status: "error",
        }
        dataLayerPush(EVENTS.FORM_SUBMISSION, trackFormSubmissionEvent)
        handleDataLayerError(`${formName} form submission failed`, `${formName} Form`)
      }
      handleCancel(callCancelHandler)
    }
  }

  const getDescriptionAndHelpText = (item: any) => (
    <>
      {item?.description && (
        <div className="description">
          <RichText content={item.description} />
        </div>
      )}
      {item?.helpText && (
        <div className="help-text">
          <RichText content={item.helpText} />
        </div>
      )}
      {item?.helpDescription && (
        <div className="help-text">
          <RichText content={item.helpDescription} />
        </div>
      )}
    </>
  )

  const shouldRenderFormField = (checks: any, key: string) => {
    const { isMultiCheck = false, multiCheckOperator = null, conditions = [] } = checks
    const result = conditions.map((condition: any) => {
      const selector = condition?.selector
      try {
        const isNestedSelector = selector?.indexOf("[") > NUMBERS.ZERO
        const nestedSelectors = selector?.split("[") ?? []
        const value = condition?.value
        const operator = condition?.operator
        const fieldValue = isNestedSelector
          ? formValues[nestedSelectors[NUMBERS.ZERO]]
          : formValues[selector]
        if (fieldValue) {
          switch (operator) {
            case "equal":
              if (typeof fieldValue === "object") {
                return fieldValue.indexOf(value) >= NUMBERS.ZERO
              }
              if (fieldValue === value) {
                return true
              }
              delete formValues[key]
              return false
            case "checked":
              if (isNestedSelector) {
                if (fieldValue?.[nestedSelectors[1]]) {
                  return true
                }
                delete formValues[key]
                return false
              }
              return true
            default:
              delete formValues[key]
              return false
          }
        }
      } catch (error) {
        console.log("something went wrong")
        return false
      }
      return false
    })
    if (isMultiCheck && multiCheckOperator === "and") {
      // check if any value in result is false
      return !result.filter((val: boolean) => !val).length
    }
    return !!result.filter(Boolean).length
  }

  const getErrors = (fieldName: string) => {
    return Object.entries(errors)
      .map(([key, value]: any): string => (fieldName === key ? value : ""))
      .join("")
  }

  const getFields = (item: any, key: number) => {
    if (item?.type === "button" && item.role === "submit") {
      submitUrl = item.url
    }

    return (
      <>
        {item?.type === "processed_text" && (
          <div className="plain-text" key={key}>
            <RichText content={item?.text} />
          </div>
        )}
        {(item?.type === "textfield" ||
          item?.type === "tel" ||
          item?.type === "email" ||
          item?.type === "number") && (
          <>
            {item?.toolTipText && (
              <div className="tooltip-icon">
                <Tooltip tooltipContent={item?.toolTipText} />
              </div>
            )}
            <div id={item.key}>
              <InputBoxWithLabel
                {...item}
                value={formValues[item.inputField.name]}
                key={key}
                name={item.key}
                disabled={item.isDisabled || mode === Action.VIEW}
                onBlurhandler={(e: React.FocusEvent<HTMLInputElement, Element>) =>
                  handleBlur(e, item.validations)
                }
                onChangehandler={handleChange}
                error={getErrors(item.inputField.name)}
                helpDescriptionText={
                  item.description || item.helpText ? getDescriptionAndHelpText(item) : ""
                }
                maxLength={item.maxLength}
              />
            </div>
          </>
        )}
        {item?.type === "webform_email_confirm" && (
          <div id={item.key}>
            <InputBoxEmailValidation
              key={key}
              name={item.key}
              {...item}
              isDisabled={item.isDisabled || mode === Action.VIEW}
              isRequired={item.validations.mandatory.required}
              value={formValues[item.key]}
              onChange={handleConfirmEmailChange}
              validationErrorMessage={item.validationErrorMessage}
              handleError={handleEmailError}
              checkForError={checkForError}
              error={errors[item.key]}
              helpDescriptionText={
                item.description || item.helpText ? getDescriptionAndHelpText(item) : ""
              }
            />
          </div>
        )}
        {item?.type === "checkbox" && (
          <div className="input-checkbox" key={key} id={item.key}>
            {(item?.label && (
              <div className="inputCheckBox-withLabel">
                <RichText content={item?.isRequired ? `${item?.label}*` : item?.label} />
              </div>
            )) ||
              (item.key === "smart_med_enq_consent" && item?.title && (
                <div className="inputCheckBox-withLabel">
                  <RichText content={item?.isRequired ? `${item?.title}*` : item?.title} />
                </div>
              ))}
            <CheckBox
              label={
                item.key === "smart_med_enq_consent" ? (
                  <RichText content={item?.description} />
                ) : item?.label ? (
                  item?.title
                ) : item?.isRequired ? (
                  `${item?.title}*`
                ) : (
                  item?.title
                )
              }
              id={item.key}
              required={item.isRequired}
              isDisabled={item.isDisabled || mode === Action.VIEW}
              value={item.value}
              error={errors[item.key]}
              isChecked={formValues[item.key] ?? false}
              onChange={(e) => handleCheckboxChange(e, null, item.key, "", item.validations)}
            />
            {item.key !== "smart_med_enq_consent" && (
              <div className="checkbox-description">{getDescriptionAndHelpText(item)}</div>
            )}
          </div>
        )}
        {item?.type === "webform_time" && (
          <div className="input-timepicker" key={key} id={item.key}>
            <TimePicker
              inputLabel={item.isRequired ? `${item.label}*` : item.label}
              value={item.value}
              id={item.key}
              isDisabled={item.isDisabled || mode === Action.VIEW}
              onChange={(newTime: string) => handleDateChange(newTime, item.key)}
            />
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </div>
        )}
        {item?.type === "radios" && (
          <fieldset
            className="input-radio-group"
            aria-labelledby={item.key}
            aria-required={item.validations.mandatory.required}
            key={key}
            id={item.key}>
            <label
              htmlFor={item.key}
              aria-required={item.validations.mandatory.required}
              className="inputWith-label">
              {item.label}
              {item.validations.mandatory.required && "*"}
            </label>
            {item?.options?.map((opt: any) => (
              <RadioButton
                label={opt.label}
                id={`${item.key}-${opt.id}`}
                value={opt.value}
                name={item.key}
                key={`${item.key}-${opt.id}`}
                isChecked={formValues?.[item.key] === opt.value}
                isDisabled={item.isDisabled || mode === Action.VIEW}
                required={item.validations.mandatory.required}
                onChange={(e) => handleChange(e, item.validations)}
              />
            ))}
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </fieldset>
        )}
        {item?.type === "checkboxes" && (
          <fieldset
            className="input-checkbox-group"
            key={key}
            aria-required={item.validations.mandatory.required}
            id={item.key}>
            <label
              htmlFor={item.key}
              aria-required={item.validations.mandatory.required}
              className="inputWith-label">
              {item.label}
              {item.validations.mandatory.required && "*"}
            </label>
            {item?.options?.map((opt: any) => (
              <CheckBox
                label={opt.label}
                id={`${item.key}-${opt.id}`}
                value={opt.value}
                name={item.key}
                key={`${item.key}-${opt.id}`}
                isDisabled={item.isDisabled || mode === Action.VIEW}
                isChecked={formValues?.[item.key]?.[opt.value] ?? false}
                onChange={(e) =>
                  handleCheckboxChange(e, "group", item.key, opt.value, item.validations)
                }
              />
            ))}
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </fieldset>
        )}
        {item?.type === "date" && (
          <div className="input-datepicker" key={key} id={item.key}>
            {item.label && (
              <label
                htmlFor={`date-${item.key}`}
                aria-required={item.validations.mandatory.required}
                className="inputWith-label">
                {item.label}
                {item.validations.mandatory.required && "*"}
              </label>
            )}
            <DatePicker
              key={`date-${item.key}`}
              className={!errors[item.key] ? "invalid" : ""}
              name={item.key}
              isDisabled={item.isDisabled || mode === Action.VIEW}
              value={formValues[item.key] ?? ""}
              setValue={(newDate: string) => handleDateChange(newDate, item.key)}
            />
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </div>
        )}

        {((item?.type === "select" && !item?.isBtnStyled) ||
          item?.type === "webform_term_select") && (
          <div
            ref={multiSelectRef}
            className={`input-select${errors[item.key] ? " invalid-select" : ""}${
              item.key === "smart_mi_country" && isSmartCountryReadOnly ? " smart-readonly" : ""
            }`}
            key={key}
            id={item.key}>
            {item.label && (
              <label
                htmlFor={`dropdown-${item.key}`}
                aria-required={item.validations.mandatory.required}
                className="inputWith-label">
                {item.label}
                {item.validations.mandatory.required && "*"}
              </label>
            )}
            <DropDown
              id={`dropdown-${item.key}`}
              ariaLabel={item.label || ""}
              options={(item.key === "soi_2_channel" || item.key === "channel") 
              ? channelOptions
              : item.options || []}
              name={item.key}
              placeHolder={item.placeholder}
              isCleared={
                item.key === "smart_mi_country" && isSmartCountryReadOnly
                  ? false
                  : isFormCleared || clearedFields?.indexOf(item.key) >= NUMBERS.ZERO
              }
              showPlaceholder={!!item.placeholder}
              intialValue={
                formValues?.[item.key] &&
                Array.isArray(formValues[item.key]) &&
                typeof formValues[item.key]?.[0] !== "string"
                  ? formValues[item.key]
                  : item.options.filter((obj: any) => formValues[item.key]?.includes(obj?.value)) ??
                    []
              }
              isDisabled={
                item.isDisabled ||
                mode === Action.VIEW ||
                (item.key === "smart_mi_country" ? isSmartCountryReadOnly : false)
              }
              shouldToggleOnHover={item.key === "smart_mi_country" ? !isSmartCountryReadOnly : true}
              isRequired={item?.validations?.mandatory?.required || false}
              onValueChange={(val: any) => handleDropdownChange(val, item.key, item.isMulti)}
              isMulti={item.isMulti || false}
              resetWithInitVal={
                categoryType === "dcr" || categoryType === "hcp_callback" ? true : false
              }
            />
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </div>
        )}
        {item?.type === "select" && item?.isBtnStyled && (
          <div
            className={`select-styled input-select${errors[item.key] ? " invalid-select" : ""}`}
            key={key}
            id={item.key}>
            {item.label && (
              <label
                htmlFor={`dropdown-${item.key}`}
                aria-required={item.validations.mandatory.required}
                className="inputWith-label">
                {item.label}
                {item.validations.mandatory.required && "*"}
              </label>
            )}
            <WebFormButton
              key={item.key}
              options={item.options || []}
              isCleared={isFormCleared}
              isDisabled={item.isDisabled || mode === Action.VIEW}
              isMulti={item.isMulti || false}
              initialValue={[]}
              onSelect={(val: any) => handleDropdownChange(val, item.key, item.isMulti)}
            />
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </div>
        )}
        {item?.type === "textarea" && (
          <div className="input-textarea" key={key} id={item.key}>
            {item.label && (
              <label
                htmlFor={`textarea-${item.key}`}
                aria-required={item.validations.mandatory.required}
                className="inputWith-label">
                {item.label}
                {item.validations.mandatory.required && "*"}
              </label>
            )}
            <textarea
              id={`textarea-${item.key}`}
              name={item.key}
              value={formValues[item.key] ?? ""}
              disabled={item.isDisabled || mode === Action.VIEW}
              className={errors[item.key] ? "invalid" : ""}
              maxLength={item.maxLength ?? undefined}
              required={item.validations.mandatory.required || false}
              onBlur={(e) => handleBlur(e, item.validations)}
              onChange={handleTextareaChange}
            />
            {getDescriptionAndHelpText(item)}
            {errors[item.key] && (
              <div className="error">
                <RichText content={errors[item.key]} />
              </div>
            )}
          </div>
        )}
        {item?.type === "custom_recaptcha_element" && !item?.isDisabled && recaptchaKey && (
          <div className="generic-webform-recaptcha" key={key}>
            <Recaptcha sitekey={recaptchaKey} onChange={recaptchaOnChangeHandler} />
          </div>
        )}

        {item?.type === "button" && item.role === "submit" && (
          <Button
            key={key}
            type={item.role}
            tabindex={NUMBERS.TWO}
            isDisabled={
              !!Object.keys(errors).length || !isRecaptchaVerified || mode === Action.VIEW
            }
            className="submit-btn"
            onClick={handleSubmit}>
            {item.text}
          </Button>
        )}

        {item?.type === "button" && item?.role === "reset" && (
          <Button
            key={key}
            type={item.role}
            tabindex={NUMBERS.TWO}
            isSecondary={true}
            onClick={() => handleCancel()}
            isDisabled={mode === Action.VIEW}
            className="reset-btn">
            {item.text}
          </Button>
        )}
        {item?.type === "webform_autocomplete" && (
          <SuggestionBar
            {...item}
            fieldsData={formValues}
            handleSearchText={handleSearchText}
            handleSearchAction={handleSearchAction}
            productTableData={productTableData}
            handleProductTableChange={handleProductTableChange}
            isClear={isClear}
            isLoading={isLoading}
          />
        )}
      </>
    )
  }

  const overlayElement = () => (
    <div className="popup-spinner-overlay">
      <div className="popup-spinner-icon">
        <img src={getAssetPrefixUrl("/assets/icons/spinner.svg")} alt="" />
      </div>
    </div>
  )

  return (
    <div className="container">
      {showIndicator && overlayElement()}
      {isPending && pendingMessage ? (
        <DisplayMessage displayMessageType="warning" displayTxt={pendingMessage} />
      ) : null}
      <form className={getClasses()} autoComplete="off" onSubmit={handleSubmit}>
        {!!showToast && (
          <DisplayMessage
            displayMessageType={showToast}
            displayTxt={notificationMessages?.[`${showToast}_message`] ?? showToast}
          />
        )}
        {logoImage?.src && (
          <div className="field-logo">
            <Img src={logoImage?.src} width={80} height={80} alt={"logoImage"} type="logo" />
          </div>
        )}
        {fields?.map((item: any, key: number) => {
          if (theme === "tyruko") {
            if (item?.type === "checkbox" || item?.type === "processed_text") {
              return (
                <Column desktop={10} tablet={12} mobile={12} key={key}>
                  <>
                    {item.conditionalShow
                      ? shouldRenderFormField(item.conditionalShow, item.key) &&
                        getFields(item, key)
                      : getFields(item, key)}
                  </>
                </Column>
              )
            }

            return (
              <Column desktop={4} tablet={8} mobile={12} key={key}>
                <>
                  {item.conditionalShow
                    ? shouldRenderFormField(item.conditionalShow, item.key) && getFields(item, key)
                    : getFields(item, key)}
                </>
              </Column>
            )
          }

          return item.conditionalShow
            ? shouldRenderFormField(item.conditionalShow, item.key) && getFields(item, key)
            : getFields(item, key)
        })}
      </form>
    </div>
  )
}
export default GenericWebForm
