import { consoleError } from "@utils/error"
import { getApi } from "@utils/baseApi"
import { fetchTabContent } from "./getAccordionAdvancedData"
import { filterURL, isExternalLink } from "."
import getNestedObject from "@utils/nestedObjects"

const getUserQuizData = async (url: string) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getUserQuizData.name}.`)
  }

  let quizData = await getApi(url)

  const quizQuestions = quizData?.questions?.map((question: any) => ({
    options: question?.options ?? [],
    correctAnswerIndex: question?.correct_answer_index ?? [],
    correct_message: question?.correct_message ?? [],
    incorrect_message: question?.incorrect_message ?? [],
    answer_type: question?.answer_type ?? null,
    question_flexible: fetchTabContent([question?.question_references]) ?? null,
  }))

  const introFlexibleDescriptionContent = fetchTabContent([quizData?.description_references]) // title + description
  const introFlexibleContent = fetchTabContent([quizData?.introduction_references]) // media multiple components
  return {
    introPageContent: {
      introFlexibleDescriptionBlocks: introFlexibleDescriptionContent ?? null,
      introFlexibleBlocks: introFlexibleContent ?? null,
      startQuizLabel: quizData?.start_quiz_cta_label ?? null,
    },
    title: quizData?.quiz_qus_count_msg ?? null,
    questions: quizQuestions ?? null,
    resultPageContent: {
      congrats_message: quizData?.quiz_result_message ?? [],
    },
    restartQuizLabel: quizData?.quiz_restart_cta_label ?? null,
    ctaPrevious: quizData?.previous_cta_label ?? null,
    ctaNext: quizData?.next_cta_label ?? null,
    ctaShowResult: quizData?.show_result_cta_label ?? null,
    ctaSubmit: quizData?.submit_cta_label ?? null,
    takeAnotherQuizBtnLabel: quizData?.another_quiz_cta?.title ?? null,
    takeAnotherQuizBtnLink: filterURL(getNestedObject(quizData, "another_quiz_cta.uri")) ?? "",
    linkExternal: quizData?.options?.attributes?.target
      ? isExternalLink(quizData?.options?.attributes?.target)
      : null,
    quiz_count_symbol: quizData?.quiz_count_symbol ?? null,
    message_type: quizData?.message_type ?? null,
    quiz_result_message: quizData?.quiz_result_message ?? [],
  }
}
export default getUserQuizData
