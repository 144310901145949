import { BrandingPattern, Container, SEO as Seo } from "@atoms"
import { IFourZeroFourProps } from "./_fourzerofourpage.interface"
import ErrorFourZeroFour from "../../organisms/errorfourzerofour"

const FourZeroFourPage = (props: IFourZeroFourProps) => {
  const seoData = props.seo
  const errorData = props.errorData

  return (
    <>
      <Seo
        canonical={seoData?.canonical}
        title={seoData?.title}
        description={seoData?.description}
        structuredData={seoData?.structuredData}
        og={seoData?.og}
        twitter={seoData?.twitter}
      />
      <Container>
        <BrandingPattern />
        <Container>
          <ErrorFourZeroFour {...errorData} />
        </Container>
      </Container>
    </>
  )
}

export default FourZeroFourPage
