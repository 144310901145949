import {
  CarouselWrapper,
  EducationCard,
  EventCardVertical,
  MagazineVerticalCard,
  NewsStoriesCard,
  ProductCard,
  TherapyCard,
} from "@organisms"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ProductCardListViewContent from "@molecules/productCardListViewContent"
import { middlewareGETAPI } from "@utils/baseApi"
import { Container, Row } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { getRelatedContentData } from "@helpers/dataFunctions/getRelatedContent"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import { ERelatedContent, IRelatedContent } from "./_relatedContent.interface"

const getSliderInfo = (slide: any, _key: number) => {
  // eslint-disable-next-line default-case
  switch (slide.type) {
    case ERelatedContent.EVENT: {
      return (
        <EventCardVertical
          eventCardVerticalContent={slide?.eventCardVerticalContent}
          eventCardVerticalImage={slide?.eventCardVerticalImage}
          eventCardVDesktopColumn={slide?.eventCardVDesktopColumn}
          bookmarkCallback={() => false}
          key={_key}
          relatedContentFlag={true}
        />
      )
    }

    case ERelatedContent.EDUCATION:
      return <EducationCard {...slide} key={_key} relatedContentFlag={true} />

    case ERelatedContent.THERAPY:
      return (
        <TherapyCard
          {...slide}
          key={_key}
          bookmarkCallback={() => false}
          relatedContentFlag={true}
        />
      )

    case ERelatedContent.NEWS:
      return (
        <NewsStoriesCard
          {...slide}
          key={_key}
          bookmarkCallback={() => false}
          relatedContentFlag={true}
        />
      )

    case ERelatedContent.PRODUCT:
      return <ProductCard key={_key} {...slide} bookmark={false} relatedContentFlag={true} />

    case ERelatedContent.CSV_PRODUCT:
      return (
        <ProductCardListViewContent
          key={_key}
          {...slide}
          bookmark={false}
          relatedContentFlag={true}
        />
      )

    case ERelatedContent.MAGAZINE:
      return <MagazineVerticalCard key={_key} {...slide} />
  }
}
const RelatedContentSlides = ({ slide }: any, _key: number) => {
  return <div className="relatedContent-slide">{getSliderInfo(slide, _key)}</div>
}
const RelatedContent = ({ url, background }: IRelatedContent) => {
  const user: any = useSelector((state: UserState) => state.user)

  const [content, setContent] = useState([])
  const BackgdColor = "white"
  const slidesToDisplay = NUMBERS.THREE

  const processLink = (link: string, userData:any): string => {
    const userid = userData?.user?.response?.session_data?.user_data?.current_user?.uid

    // Check if the link contains "solar_related_content"
    // Since No Support for querystring
    if (link.includes("solar_related_content") && userid) {
      // Remove "/0" from the link
      link = link.replace("/0", "")

      // Append the userid to the modified link
      link += `/${userid}`
    }
    return link
  }

  const fetchData = async (url: string) => {
    const response = await middlewareGETAPI(url)
    return response.response
  }

  // const getRelatedContent = async () => {
  //     try {
  //       const userid = user?.user?.response?.session_data?.user_data?.current_user?.uid
  //       const relatedContentResponse = await fetchData(`/jsonapi${userid ? processLink(url, userid) : url}`)
  //       setRelatedContentDetails(relatedContentResponse as any)
  //     } catch (error) {
  //       console.log(error)
  //     }
  // }

  const { data } = useSWRRequest(`/jsonapi${processLink(url, user)}`, async () => {
    try {
       return await fetchData(`/jsonapi${processLink(url, user)}`)
    } catch (error) {
      console.log(error)
    }
  })

//   useEffect(() => {
//    getRelatedContent()
// }, [user?.user])

  useEffect(() => {
    if (!data) {
      return
    }
    const contentData = getRelatedContentData(data?.content)
    setContent(contentData)
  }, [data])

  return (
    content?.length > 0
    ? <Container background={background || (data?.background_color || BackgdColor)} bleed={true}>
      <Row isTemplateWrapper>
        <div className="relatedContent-row">
          {user?.isLoggedIn ? (
            <h2 className="relatedContent-title bold">{data?.title_auth_user}</h2>
          ) : (
            <h2 className="relatedContent-title bold">{data?.title_anon_user}</h2>
          )}

          {content?.length > slidesToDisplay ? (
            <CarouselWrapper
              slideToShow={slidesToDisplay}
              className={
                data?.background_color === BackgdColor
                  ? "relatedContent-caraouselWhite"
                  : "relatedContent-caraousel"
              }
            >
              {content?.map((slide: any, _key: number) => slide && <RelatedContentSlides key={_key} slide={slide} _key={_key} />)}
            </CarouselWrapper>
          ) : (
            <div className="relatedContent-grid">
              {content?.map((slide: any, _key: number) => (
              slide && <RelatedContentSlides key={_key} slide={slide} _key={_key} />
              ))}
            </div>
          )}
        </div>
      </Row>
      </Container> : <></>
  )
}

export default RelatedContent
