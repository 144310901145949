import { Icon, AnchorButton, Container, Row } from "@atoms"
import React from "react"
import { IGoBackButton } from "./_goBackButton.interface"

const GoBackButton = ({
  refererUrl,
  goback_details_button_label_text,
  goback_details_target_url,
  className,
}: IGoBackButton) => {
  const getClasses = () => {
    const classes = []
    classes.push("goBackButton")
    if (className) {
      classes.push(className)
    }
    return classes.join(" ")
  }

  const { host } = window?.location
  let isWhiteListed = false
  const whiteListedLinks = goback_details_target_url?.split(",")
//to get the host details from url
  const getHostname = (url:string) => {
    try{
      const urlObj= new URL(url)
      return urlObj.host 
    }
    catch{
      return null
    }
  }
  const updatedwhitelistedLinks:any=[]
  // updatedwhitelistedLinks will return host list for url with and without protocol
  whiteListedLinks.map((link) => {
    if(link){
      const checklink= getHostname(link)
      if(checklink){
        updatedwhitelistedLinks.push(checklink)
      }
     else{
      updatedwhitelistedLinks.push(link)
     } 
    }
  })

  const hostname= new URL(refererUrl).host
  isWhiteListed= updatedwhitelistedLinks?.some((domain:string) => domain === hostname)

  if (isWhiteListed && hostname !== host) {
    return (
      <Container>
        <Row>
          <div className={getClasses()}>
            <AnchorButton
              href={refererUrl ?? ""}
              tabindex={0}
              isSecondary
              isExternal={false}
              icon={<Icon iconName="chevronLeftIconGo" />}>
              {goback_details_button_label_text}
            </AnchorButton>
          </div>
        </Row>
      </Container>
    )
  }
  return <></>
}
export default GoBackButton
