import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { getApi } from "@utils/baseApi"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getImageMeta } from "."
import { PAGE_NID_FILTER } from "@helpers/constants/generic"

const _getRowData = async (ID: string, serverData?: any) => {
  let rows = await getApi(`/jsonapi/block_content/table_add_column/${ID}`, true, serverData)
  rows = rows?.data
  const imagePaywallData = isPaywallProtected(rows?.relationships?.field_table_row_image)
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(rows?.relationships?.field_table_row_image)

  return {
    coltitle: rows?.attributes?.field_column_title?.value ?? "",
    rowColumnValue: rows?.attributes?.field_column_value?.data ?? [],
    rowtitle: rows?.attributes?.field_row_title?.value ?? "",
    rowimage: imageUrl,
    imagealt: alt,
    styledimage: imageStyledUrl ?? null,
  }
}
const getCompareTableData = async (url: string, ...args: any) => {
  const pageNid = args?.[0]
  const serverData = args?.[3] // accepting serverData only
  let rowArray: any = []
  let rawData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const discription = rawData?.data?.attributes?.body?.value ?? ""
  rawData = rawData?.data?.relationships?.field_table_column_reference?.data ?? []
  rowArray = await Promise.all(
    rawData?.map(
      async (row: any) =>
        await _getRowData(
          `${row?.id}${pageNid ? `?${PAGE_NID_FILTER}${pageNid}` : ""}`,
          serverData,
        ),
    ),
  )
  return {
    discription,
    tableData: rowArray,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getCompareTableData
