import { Label } from "@atoms"
import { ILabelGroupProps } from "./_labelgroup.interface"

const LabelGroup = (props: ILabelGroupProps) => {
  const { className } = props
  const getClasses = () => {
    const classes = []
    classes.push("label-group")
    if (className) classes.push(className)
    return classes.join(" ")
  }

  return (
    <div className={getClasses()} role="group">
      <div className="label-group-row">
        <div className="label-group-column">
          <Label className="label-group-left-col" htmlFor="name">
            Age:
          </Label>
        </div>
        <div className="label-group-column">
          <span className="label-group-right-col">Abc</span>
        </div>
      </div>
      <div className="label-group-row">
        <div className="label-group-column">
          <Label className="label-group-left-col" htmlFor="name">
            Disease:
          </Label>
        </div>
        <div className="label-group-column ">
          <span className="label-group-right-col">44</span>
        </div>
      </div>
      <div className="label-group-last-row">
        <div className="label-group-column ">
          <Label className="label-group-left-col" htmlFor="name">
            Medication:
          </Label>
        </div>
        <div className="label-group-column ">
          <span className="label-group-right-col">wGSK + SABA</span>
        </div>
      </div>
    </div>
  )
}

export default LabelGroup
