import React, { useState } from "react"
import { Button, Icon } from "@atoms"
import SocialShareModal from "@organisms/socialShareModal"
import NUMBERS from "@helpers/constants/numbers"
import { ISocialShareProps } from "./_socialShare.interface"

const SocialShare = ({
  btnLabel,
  sharingPlatforms,
  pageLinkLabel,
  copiedLabel,
  tabIndex = NUMBERS.ZERO,
}: ISocialShareProps) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className="social-share">
      <Button
        className="social-share-cta"
        tabindex={tabIndex}
        icon={<Icon iconName="ShareIcon" />}
        isSecondary
        onClick={() => setShowModal(true)}>
        {btnLabel}
      </Button>
      {showModal && (
        <SocialShareModal
          modalTitle={btnLabel}
          sharingPlatforms={sharingPlatforms}
          pageLinkLabel={pageLinkLabel}
          copiedLabel={copiedLabel}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  )
}

export default SocialShare
