import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData } from "."

const getCompactStockTickerData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getCompactStockTickerData.name}.`)
  const serverData = args?.[3] // accepting serverData only

  const cstBlock = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(cstBlock, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const cstBlockData = cstBlock?.data ?? []
  const fieldPaywallRoles = getAllRoles(cstBlockData)

  return {
    fieldCstKeyAttrValues: isPaywallProtected(
      getNestedObject(cstBlockData, "attributes.field_cst_key_attr_values"),
    ).isPaywallProtectedFlag
      ? ""
      : getNestedObject(cstBlockData, "attributes.field_cst_key_attr_values") ?? [],
    cstXMLLink: getNestedObject(cstBlockData, "attributes.field_cst_xml_link.value"),
    fieldPaywallRoles,
    isParentPaywalled
  }
}

export default getCompactStockTickerData
