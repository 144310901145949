export enum EVENTS {
  PAGE_READY = "page_ready",
  SCROLL = "scroll",
  SCROLL_25 = "scroll_25",
  SCROLL_50 = "scroll_50",
  SCROLL_75 = "scroll_75",
  SCROLL_100 = "scroll_100",
  NAVIGATION_HEADER = "navigation_header",
  NAVIGATION_MENU = "navigation_menu",
  NAVIGATION_FOOTER = "navigation_footer",
  NAVIGATION_CLICK = "navigation_click",
  VIDEO_START = "video_start",
  VIDEO_PROGRESS = "video_progress",
  VIDEO_COMPLETE = "video_complete",
  VIDEO_PLAY_25 = "video_play_25",
  VIDEO_PLAY_50 = "video_play_50",
  VIDEO_PLAY_75 = "video_play_75",
  VIDEO_PLAY_100 = "video_play_100",
  DOWNLOAD = "download",
  PRINT = "print",
  RATE_CONTENT = "rate_content",
  EXIT_LINKS = "exit_links",
  SEARCH = "search",
  MAIL_TO_CLICK = "mail_to_click",
  TELEPHONE = "telephone",
  FORM_SUBMISSION = "form_submission",
  ACCOUNT_ACTVATION = "account_activation",
  PASSWORD_RESET = "password_reset",
  REGISTRATION = "registration",
  REGISTRATION_SUBMIT = "registration_submit",
  SUBMIT_BUTTON_LINK = "submit_button_click",
  LOGIN = "login",
  LOGOUT = "logout",
  SOCIAL_SHARES = "social_shares",
  ERROR = "error",
  SURVEY_STARTED = "survey_started",
  SURVEY_COMPLETED = "survey_completeted",
  WEBINAR_REGISTRATION = "webinar_registrations",
  QUIZ_STARTED = "quiz_started",
  QUIZ_RETAKE = "quiz_retake",
  QUIZ_PRINTED = "quiz_printed",
  QUIZ_COMPLETED = "quiz_completed",
  QUIZ_RESULT = "quiz_result",
  REP_MEETING_REQUEST = "rep_meeting_request",
  WEBSHOP_IMPRESSIONS = "webshop_impressions",
  WEBSHOP_PRODUCT_VIEWS = "webshop_product_views",
  WEBSHOP_ADD = "webshop_cart_add",
  WEBSHOP_REMOVE = "webshop_cart_remove",
  WEBSHOP_CHECKOUT = "webshop_checkout",
  PODCAST_PLAY = "podcast_play",
  PODCAST_PAUSE = "podcast_pause",
  PODCAST_START = "podcast_start",
  PODCAST_PLAY_25 = "podcast_play_25",
  PODCAST_PLAY_50 = "podcast_play_50",
  PODCAST_PLAY_75 = "podcast_play_75",
  PODCAST_PLAY_100 = "_pay_100",
  BOOKMARK_ITEM = "bookmark_item",
  FAX = "fax"
}
