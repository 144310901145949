import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."

const getSupportMaterialCardData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  let supportMaterialCards = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(supportMaterialCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(supportMaterialCards)
  supportMaterialCards = supportMaterialCards?.included ?? []

  supportMaterialCards = await Promise.all(
    supportMaterialCards?.map(async (supportMaterialCard: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(supportMaterialCard, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const imagePaywallData = isPaywallProtected(
        supportMaterialCard?.relationships?.field_article_image,
      )
      const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
        ? getPaywalledImgData(imagePaywallData?.errorObj)
        : getImageMeta(supportMaterialCard?.relationships?.field_article_image)
      const taxonomies = await getTaxonomy(
        supportMaterialCard?.relationships?.field_article_tags?.links?.related?.href,
        false,
        serverData,
      )

      const bookmark = isPaywallProtected(supportMaterialCard?.attributes?.status)
        .isPaywallProtectedFlag
        ? null
        : supportMaterialCard?.attributes?.status ?? null

      const heading = isPaywallProtected(supportMaterialCard?.attributes?.title)
        .isPaywallProtectedFlag
        ? null
        : supportMaterialCard?.attributes?.title ?? null

      return {
        materialTeaserCardImage: {
          src: imageUrl ?? null,
          alt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
        },
        materialTeaserCardContent: {
          id: supportMaterialCard?.attributes?.drupal_internal__nid ?? null,
          taxonomies: taxonomies ?? null,
          bookmark,
          heading,
          text: supportMaterialCard?.attributes?.body?.processed ?? null,
          numberOfFavorite: 200,
          buttonLabel: supportMaterialCard?.attributes?.field_secondary_cta?.title
            ? supportMaterialCard?.attributes?.field_secondary_cta?.title
            : null,
          buttonUrl: supportMaterialCard?.attributes?.field_secondary_cta?.uri
            ? filterURL(supportMaterialCard?.attributes?.field_secondary_cta?.uri)
            : null,
          buttonUrlExternal: supportMaterialCard?.attributes?.field_secondary_cta?.options
            ?.attributes?.target
            ? isExternalLink(
                supportMaterialCard?.attributes?.field_secondary_cta?.options?.attributes?.target,
              )
            : null,
        },
      }
    }),
  )

  return {
    supportMaterialCards: filterNullValues(supportMaterialCards) ?? null,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getSupportMaterialCardData
