import { SearchGroup } from "@molecules"
import { useRouter } from "next/router"
import { ISearchDataInterface } from "./_commonSearch.interface"

const CommonSearch = (searchData: ISearchDataInterface) => {
  const router = useRouter()

  const handleTextSearch = (text: string, e?: React.MouseEvent<Element, MouseEvent>) => {
    if (e) e.preventDefault()
    if (searchData?.field_search_cta_link) {
      router.push({
        pathname: searchData?.field_search_cta_link,
        query: { search: `${text}` },
      })
    }
  }
  const searchBarConfiguration = {
    inputDataContent: {
      type: "primary",
      placeholder: searchData?.field_seach_input,
    },
    buttonText: searchData?.field_search_cta_text,
    handleTextSearch,
  }
  return (
    <div>
      <SearchGroup
        inputDataContent={searchBarConfiguration.inputDataContent}
        buttonText={searchBarConfiguration.buttonText}
        handleTextSearch={handleTextSearch}
        isDisabled={true}
      />
    </div>
  )
}

export default CommonSearch
