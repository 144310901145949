import * as actionTypes from "../ActionTypes"

export const addISIBanner = (isiBannerData: any) => {
  const action: ISIBannerAction = {
    type: actionTypes.ADD_ISI_BANNER,
    isiBannerData
  }
  return performRequest(action)
}

const performRequest = (action: ISIBannerAction) => {
  return (dispatch: ISIBannerDispatchType) => {
    dispatch(action)
  }
}
