import React, { useEffect, useRef, useState } from "react"
import { useInView } from "react-intersection-observer"
import { Icon, RichText } from "@atoms"
import { useMediaQuery } from "@helpers/hooks"
import PropTypes from "prop-types"
import { ILegalBannerModuleData, ILegalBannerModule } from "./_legalBannerModule.interface"

const LegalBannerModule = ({ data, className }: ILegalBannerModule) => {
  const {
    title,
    expandedCoverageNumber,
    bannerType,
    legalBannerMobileCtaText,
    disclaimerBannerText,
    nonStickyTitle,
    disclaimerBannerScreenOccupancy,
  }: ILegalBannerModuleData = data || {}
  const headerRef = useRef<any>(null)
  const nonsticky = useRef<HTMLDivElement | null>(null)
  const [isOutsideViewAtTheBottom, setIsOutsideViewAtTheBottom] = useState(false)

  const isMobile = useMediaQuery("(max-width: 767px)")
 
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  const goToBottom = () => {
    headerRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    })
  }

  const { ref: ref1, inView } = useInView({
    threshold: 0,
  })

  const {
    ref: ref2,
    inView: inView2,
    entry,
  } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (entry) {
      if (entry.isIntersecting) {
        setIsOutsideViewAtTheBottom(true)
      } else {
        setIsOutsideViewAtTheBottom(false)
      }
    }
  }, [inView, inView2])

  return (
    <>
      {((bannerType === "legal_banner" && title && nonStickyTitle) ||
        (bannerType === "disclaimer_banner" && disclaimerBannerText)) && (
        <>
          <div
            className="legal-banner-module sticky show"
            tabIndex={0}
            style={{ display: inView2 ? "none" : "block" }}>
            <div className="header">
              <div
                className={
                  bannerType === "disclaimer_banner"
                    ? "header-content legal-banner-module-width legal-banner-module-width-expand"
                    : "header-legal-content legal-banner-module-width legal-banner-module-width-expand"
                }
                style={{
                  maxHeight:
                    bannerType === "legal_banner"
                      ? `${expandedCoverageNumber}vh`
                      : `${disclaimerBannerScreenOccupancy}vh`,
                  display:  bannerType === "disclaimer_banner" ? "block" : ""
                }}>
                {/* Sticky text */}
                <div className="legal_content">
                  <div ref={ref1}>
                    <div>
                      {bannerType === "legal_banner" && inView && (
                        <>
                          {isMobile ? (
                            ""
                          ) : (
                            <div className="stickyContent">
                              <RichText
                                className="legal-banner-module-description"
                                content={title}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Disclaimer banner */}
                  {bannerType === "disclaimer_banner" && (
                    <div className="legal-banner-title">
                      {disclaimerBannerText && (
                        <RichText
                          className="legal-banner-module-description"
                          content={disclaimerBannerText}
                        />
                      )}
                    </div>
                  )}
                </div>

                {bannerType !== "disclaimer_banner" && (
                  <>
                    {isMobile && (
                      <div onClick={goToBottom} className="view-more-txt">
                        {legalBannerMobileCtaText}
                      </div>
                    )}
                    <div className="legal-banner-module-icon-wrapper" tabIndex={0}>
                      <div className="legal-banner-module-seperator" />
                      <div className="header-icon-container">
                        <Icon iconName="ChevronDown" className="header-icon" onClick={goToBottom} />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          {/*  Non sticky text */}
          <div className="legal-banner-module" tabIndex={0}>
            <div className="header" ref={headerRef}>
              <div
                className={
                  isMobile
                    ? "legal-banner-module-text"
                    : "header-legal-content legal-banner-module-width legal-banner-module-width-expand"
                }
                ref={headerRef}>
                <div className="legal_content">
                  <div ref={ref2} id="inview-block">
                    {bannerType === "legal_banner" && inView2 && (
                      <div className="nonsticky" ref={nonsticky}>
                        {nonStickyTitle && (
                          <RichText
                            className="legal-banner-module-description"
                            content={nonStickyTitle}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  {bannerType === "disclaimer_banner" && (
                    <div className="legal-banner-title">
                      {disclaimerBannerText && (
                        <RichText
                          className="legal-banner-module-description"
                          content={disclaimerBannerText}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="legal-banner-module-icon-content">
                  {bannerType !== "disclaimer_banner" && (
                    <>
                      {isMobile && (
                        <div onClick={goToBottom} className="view-more-txt">
                          {legalBannerMobileCtaText}
                        </div>
                      )}
                      <div className="legal-banner-module-icon-wrapper" tabIndex={0}>
                        <div className="legal-banner-module-seperator" />
                        <div className="header-icon-container">
                          <Icon iconName="ChevronUp" className="header-icon" onClick={goToTop} />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

LegalBannerModule.propTypes = {
  data: PropTypes.object,
}

export default LegalBannerModule
