import * as actionTypes from "../ActionTypes"

export const addUser = (user: any) => {
    const action1: UserAction = {
        type: actionTypes.USER_DATA,
        user,
    }
    return performRequest([action1])
}

export const resetUser = (user: any) => {
    const action1: UserAction = {
        type: actionTypes.RESET_USER,
        user,
    }
    const action2: WebshopAction = {
        type: actionTypes.EMPTY_CART,
    }
    return performRequest([action1, action2])
}

export const setUserProfile = (profile: any) => {
    const action1: UserAction = {
        type: actionTypes.SET_USER_PROFILE,
        profile,
    }
    return performRequest([action1])
}

export const getUserProfile = () => {
    const action: UserAction = {
        type: actionTypes.GET_USER_PROFILE,
    }
    return performRequest([action])
}

const performRequest = (actions: (UserAction | WebshopAction)[]) => {
    return (dispatch: DispatchType) => {
        actions.forEach((action) => {
            dispatch(action)
        })
    }
}
