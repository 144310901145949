const logoIcons = [
  "iCalendarIcon",
  "circleSuccess",
  "GoogleCalendarIcon",
  "Office365Icon",
  "OutlookIcon",
  "YahooIcon",
  "CartActiveIcon",
  "Notification",
  "userProfilePicture",
]
export default logoIcons
