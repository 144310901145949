import { Anchor, AnchorButton, Container, Img, Row } from "@atoms"
import { IModuleInfoCard } from "./_moduleInfoCard.interface"
import ModuleInfoIconCard from "@atoms/moduleInfoIconCard"
import { IModuleInfoIconCard } from "@atoms/moduleInfoIconCard/_moduleInfoIconCard.interface"

const ModuleInfoCard = ({
  title,
  titleLink,
  altText,
  imageSrc,
  moduleInfoCardData,
  courseDetailsCTALink,
  courseDetailsCTALabel,
}: IModuleInfoCard) => {
  return (
    <Container>
      <Row isTemplateWrapper>
      <div className="moduleInfoCard">
        {title && titleLink && (
          <Anchor className="moduleInfoCard-title" href={titleLink} tabindex={0}>
            <h2 className="bold">{title}</h2>
          </Anchor>
        )}
        {moduleInfoCardData.length && (
          <div className="moduleInfoCard-iconcard">
            {moduleInfoCardData?.map((card: IModuleInfoIconCard,index:number) => (
              <ModuleInfoIconCard key={index} {...card} />
            ))}
          </div>
        )}
        {courseDetailsCTALabel && courseDetailsCTALink && (
          <AnchorButton
            href={courseDetailsCTALink}
            isExternal={false}
            tabindex={0}
            className="moduleInfoCard-cta">
            {courseDetailsCTALabel}
          </AnchorButton>
        )}

        <Img alt={altText} src={imageSrc} type="image_block" width={1440} height={405} />
      </div>
      </Row>
    </Container>
  )
}

export default ModuleInfoCard
