import { AnchorButton, Column, Img } from "@atoms"
import ButtonGroup from "../../molecules/button-group"
import EventCardHorizontalContent from "../../molecules/eventCardHorizontalContent"
import AddToCalendar from "../addToCalendar"
import { IEventCardHorizontalProps } from "./_eventCardHorizontal.interface"
import { EventCardScoutButton } from "@molecules"
import { EVENT_TYPE } from "@helpers/constants/generic"

const EventCardHorizontal = (props: IEventCardHorizontalProps) => {
  const eventCardHorizontalContentData = props.eventCardHorizontalContent
  const {
    primaryButtonUrl,
    primaryButtonLabel,
    primaryIsExternalUrl,
    secondaryButtonLabel,
    secondaryButtonUrl,
    secondaryIsExternalUrl,
    heading,
    text,
    startDate,
    endDate,
    contentText,
    eventType,
    scoutData,
  } = eventCardHorizontalContentData
  const { eventCardHorizontalImage } = props
  return (
    <div className="event-card-horizontal">
      <Column
        desktop={props.eventCardHDesktopColumn}
        tablet={
          props.eventCardHTabletColumn
            ? props.eventCardHTabletColumn
            : props.eventCardHDesktopColumn
        }
        mobile={props.eventCardHMobileColumn ? props.eventCardHMobileColumn : 12}
        className="card">
        <div className="event-card-horizontal-block">
          <div className="event-card-horizontal-col">
            <Column
              desktop={9}
              tablet={12}
              mobile={12}
              className="event-card-horizontal-content-left">
              <EventCardHorizontalContent {...eventCardHorizontalContentData} />
            </Column>
            <Column desktop={3} tablet={12} mobile={12} className="event-card-horizontal-image">
              <Img
                className="event-card-horizontal-image-content"
                type="card"
                styledimage={eventCardHorizontalImage?.styledimage}
                src={eventCardHorizontalImage.src}
                alt={eventCardHorizontalImage.alt}
                width={368}
                height={220}
              />
            </Column>
          </div>
          <Column
            desktop={12}
            tablet={12}
            mobile={12}
            className="event-card-horizontal-content-button">
            <ButtonGroup>
              {eventType && eventType === EVENT_TYPE.SCOUT_EVENT && scoutData ? (
                <EventCardScoutButton {...scoutData} />
              ) : primaryButtonUrl && primaryButtonLabel ? (
                <AnchorButton
                  tabindex={1}
                  href={primaryButtonUrl}
                  isExternal={primaryIsExternalUrl}>
                  {primaryButtonLabel}
                </AnchorButton>
              ) : (
                <></>
              )}
              {(secondaryButtonUrl || secondaryIsExternalUrl) && secondaryButtonLabel ? (
                <AnchorButton
                  tabindex={2}
                  href={secondaryButtonUrl}
                  isExternal={secondaryIsExternalUrl}
                  isSecondary>
                  {secondaryButtonLabel}
                </AnchorButton>
              ) : (
                <></>
              )}
              <>
                <AddToCalendar
                  eventTitle={heading}
                  eventDescription={text}
                  startDate={startDate}
                  endDate={endDate}
                  location={contentText}
                />
              </>
            </ButtonGroup>
          </Column>
        </div>
      </Column>
    </div>
  )
}

export default EventCardHorizontal
