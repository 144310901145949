import { useState } from "react"
import { ILanguageSwitchProp } from "./_languageSwitch.interface"

const LanguageSwitch = (props: ILanguageSwitchProp) => {
  const [isActive, setIsActive] = useState(false)
  const getClasses = () => {
    const classes = []
    classes.push("language-switch")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  const handleClick = () => {
    setIsActive((current) => !current)
    props.onClick && props.onClick(isActive)
  }

  return (
    <>
      <div className={"desktop-view " + getClasses()} onClick={handleClick}>
        <a href={props.href} className="language-switch-elem" aria-label={props.ariaLabel}>
          <span className="globe-icon">{props.regionicon}</span>
          <span className="country-info">{props.country}</span>
          <span className="seperator">|</span>
          <span className="language-info">{props.lang}</span>
          {!props.displayActive ? (
            <span className="icon">{props.stateIcon}</span>
          ) : (
            <span className="close-icon">{props.closeIcon}</span>
          )}
        </a>
      </div>
      <div className={"mobile-view " + getClasses()} onClick={handleClick}>
        <a href={props.href} aria-label={props.ariaLabel}>
          {!props.displayActive ? (
            <span className="globe-icon">{props.regionicon}</span>
          ) : (
            <span className="close-icon">{props.closeIcon}</span>
          )}
        </a>
      </div>
    </>
  )
}

export default LanguageSwitch
