import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { consoleError } from "@utils/error"
import { getMediaFile } from "@utils/mediaLibrary"
import getNestedObject from "@utils/nestedObjects"
import { getBlockData, getFileDetails, getImage } from "."
import renderMediaLibraryConfig from "./getMediaLibraryConfig"

const getMediaData = async (url: string, nid: number, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getMediaData.name}.`)

  const serverData = args?.[3] // accepting serverData only
  const hasQueryParams = url.includes("?")
  const { data } = await getBlockData(`${url}${hasQueryParams ? "&" : "?"}views-argument[0]=${nid}`, false, serverData)
  const rawData = data.length ? data[0] : {}
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rawData, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rawData)
  const title = isPaywallProtected(rawData?.attributes?.title).isPaywallProtectedFlag
    ? ""
    : rawData?.attributes?.title ?? ""
  const date = isPaywallProtected(rawData?.attributes?.published_date).isPaywallProtectedFlag
    ? null
    : rawData?.attributes?.published_date ?? null
  const body = rawData?.attributes?.body?.processed ?? ""
  const fieldMediaSubHeading =
    getNestedObject(rawData, "attributes.field_media_sub_heading.processed") ?? ""
  const fieldSubHeadingDescription =
    getNestedObject(rawData, "attributes.field_sub_heading_description.processed") ?? ""
  const imagePaywallData = isPaywallProtected(
    getNestedObject(rawData, "relationships.field_media_image"),
  )
  const imgUUID = getNestedObject(rawData, "relationships.field_media_image.data.id") ?? ""
  const mediaLibConfig = await renderMediaLibraryConfig(serverData)
  const {
    imageUrl: imgUrl,
    alt,
    caption,
    imageStyledUrl,
  } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : await getImage(imgUUID, "", "", serverData)
  const imageArray = imgUrl
    ? [
        {
          alt,
          src: imgUrl,
          caption: caption ?? null,
          styledimage: imageStyledUrl ?? null,
        },
      ]
    : []
  const newsAndStories = { imageUrl: imageArray }
  const downloadUUID = getNestedObject(rawData, "relationships.field_media_download.data.id")
  const downloadType = getNestedObject(rawData, "relationships.field_media_download.data.type")

  const fileType = downloadType?.replace("media--", "")

  const mediaFile = getMediaFile(fileType)

  const { fileSize, downloadFileLink } = await getFileDetails(downloadUUID, fileType, mediaFile)

  return {
    date,
    title,
    body,
    alignment: data.length ? data[0]?.attributes?.field_alignment : "center",
    fieldMediaSubHeading,
    fieldSubHeadingDescription,
    newsAndStories,
    taxonomyLabel: downloadFileLink,
    mediaLibConfig,
    fileSize,
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getMediaData
