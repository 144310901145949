import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import moment from "moment"
import { filterURL, getBlockData, getImageMeta, getTaxonomy, isExternalLink } from "."
import getConfigDisplayData from "./getConfigDisplayData"
import getSpecialityFilterData from "./getSpecialityFilterData"
import getAlphabeticOrderData from "./getAlphabeticOrderData"
import getArchieveYearData from "./getArchieveYearData"
import { getApiDomainAndLang } from "@utils/baseApi"
import isServer from "@utils/fs/isServer"

const getArchiveYearData = async (serverData?: any) => {
  const archiveYear = await getArchieveYearData(serverData)
  const options: Array<any> = []

  archiveYear?.data?.map((option: any) => {
    options.push({
      id: option?.attributes?.drupal_internal__tid,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

const getNewsCategoryFilterData = async (serverData?: any) => {
  let CategoryData = await getConfigDisplayData(serverData)
  CategoryData = CategoryData?.data
  if (CategoryData) {
    const CategoryLabelData = {
      searchLabel: CategoryData?.education_search_cta_text ?? null,
      AlphaLabel: CategoryData?.education_all_category_text ?? null,
      FormLabel: CategoryData?.education_from_text ?? null,
      clearLabel: CategoryData?.education_search_clear_all_text ?? null,
      other_category_text: CategoryData?.education_other_category_text ?? null,
      buttonLabel: CategoryData?.news_n_stories_cta_button ?? null,
    }
    return CategoryLabelData
  }
  return null
}

const getAlphabeticalData = async (serverData?: any) => {
  let alphabeticalData = await getAlphabeticOrderData(serverData)
  alphabeticalData = alphabeticalData?.data
  const options: any = []
  alphabeticalData?.map((option: any, key: number) => {
    options.push({
      id: key,
      value: option?.attributes?.name,
      label: option?.attributes?.name,
    })
  })
  return options
}

const getLatestNewsStoriesListData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const specialityVocabularyoptions = await getSpecialityFilterData(serverData)
  const alphabeticalData = await getAlphabeticalData(serverData)
  const archiveYearData = await getArchiveYearData(serverData)
  const newsCategoryFilterData = await getNewsCategoryFilterData(serverData)
  const nasFilterData = {
    advanceFilterData: {
      buttonText: newsCategoryFilterData?.searchLabel,
      filtersOptions: [
        {
          label: newsCategoryFilterData?.AlphaLabel,
          selectOptions: {
            isCleared: true,
            isMulti: true,
            options: alphabeticalData,
            ariaLabel: "Select Element",
            name: "select",
          },
        },
        {
          label: newsCategoryFilterData?.other_category_text,
          selectOptions: {
            options: specialityVocabularyoptions,
            ariaLabel: "Select Element",
            name: "select",
            isMulti: true,
            placeHolder: "Select Option",
          },
        },
        {
          label: newsCategoryFilterData?.FormLabel,
          selectOptions: {
            isCleared: true,
            options: archiveYearData,
            ariaLabel: "Select Element",
            name: "select",
            isMulti: true,
          },
        },
      ],
    },
    filterTitle: "Filter through the courses",
    clearAllText: newsCategoryFilterData?.clearLabel ?? "",
    labelItemsSelected: " selected",
  }

  let latestNewsStoriesCards = await getBlockData(url, false, serverData)
  const { lang } = getApiDomainAndLang()
  const language = isServer() ? serverData?.language : lang

  latestNewsStoriesCards = latestNewsStoriesCards?.data ?? []
  const fieldPaywallRoles = getAllRoles(latestNewsStoriesCards)
  if (latestNewsStoriesCards) {
    latestNewsStoriesCards = await Promise.all(
      latestNewsStoriesCards?.map(async (newsStoriesCard: any) => {
        const isChildPaywalled = isPaywallProtected(
          getNestedObject(newsStoriesCard, "attributes.enable_paywall_block_level"),
        )?.isPaywallProtectedFlag

        if (isChildPaywalled) {
          return null
        }
        const imagePaywallData = isPaywallProtected(newsStoriesCard?.relationships?.field_nas_image)
        const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
          ? getPaywalledImgData(imagePaywallData?.errorObj)
          : getImageMeta(newsStoriesCard?.relationships?.field_nas_image)
        const taxonomies = await getTaxonomy(
          newsStoriesCard?.relationships?.field_category?.links?.related?.href,
          true,
          serverData,
        )
        const specialityId = await getNestedObject(
          newsStoriesCard,
          "relationships.field_news_category.data.meta.drupal_internal__target_id",
        )
        const createdDate =
          moment(newsStoriesCard?.attibutes?.created).locale(lang).format("YYYY") ?? null
        const date = newsStoriesCard?.attributes?.field_news_published_date
          ? moment(newsStoriesCard?.attributes?.field_news_published_date)
              .locale(language)
              .format("MMM DD, YYYY") ?? ""
          : null
        return {
          newsStoriesContent: {
            id: newsStoriesCard?.attributes?.drupal_internal__nid ?? "",
            taxonomies: taxonomies ?? null,
            heading: isPaywallProtected(newsStoriesCard?.attributes?.title).isPaywallProtectedFlag
              ? null
              : newsStoriesCard?.attributes?.title ?? "",
            text: newsStoriesCard?.attributes?.body?.processed
              ? newsStoriesCard?.attributes?.body?.processed
              : null,
            date: isPaywallProtected(newsStoriesCard?.attributes?.field_news_published_date)
              .isPaywallProtectedFlag
              ? null
              : date,
            buttonLabel:
              getNestedObject(newsStoriesCard, "attributes.field_cta_nas.title") ||
              newsCategoryFilterData?.buttonLabel,
            buttonUrl: newsStoriesCard?.attributes?.path?.alias
              ? filterURL(newsStoriesCard?.attributes?.path?.alias)
              : getNestedObject(newsStoriesCard, "attributes.field_cta_nas.uri"),
            bookmark: {
              addText: "Add Bookmark",
              removeText: "Remove Bookmark",
              bookmarkStatus: false,
              isButton: false,
            },
            isButtonUrlExternal: newsStoriesCard?.attributes?.field_cta_nas?.options?.attributes
              ?.target
              ? isExternalLink(
                  newsStoriesCard?.attributes?.field_cta_nas?.options?.attributes?.target,
                )
              : null,
          },
          specialityId,
          createdDate: isPaywallProtected(newsStoriesCard?.attibutes?.created)
            .isPaywallProtectedFlag
            ? null
            : createdDate,
          imageSrc: imageUrl ?? null,
          imgAlt: alt ?? null,
          styledimage: imageStyledUrl ?? null,
        }
      }),
    )
  }
  return {
    nasFilterData,
    latestNewsStoriesCards: filterNullValues(latestNewsStoriesCards),
    pagination: {
      itemsPerPage: 9,
      itemsPerPageMobile: 9,
    },
    fieldPaywallRoles,
  }
}

export default getLatestNewsStoriesListData
