import { Anchor, Column, Icon, Img, RichText, Taxonomy } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import BookmarkWithButton from "@molecules/bookmarkWithButton"
import IconWithLabel from "@molecules/iconWithLabel"
import StarsRating from "@molecules/starsRating"
import { trimText } from "@utils/stringparsing"
import { Dispatch, useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { addRatings } from "store/actions/RatingsActionCreators"
import IFlexibleCard from "./_flexibleCard.interface"

const FlexibleCard = (props: IFlexibleCard) => {
  const {
    desktopColumns = 3,
    tabletColumns = 6,
    title,
    id,
    desc,
    className,
    cardImage,
    taxonomies,
    bookmarkContent,
    isRatingsAvailable = false,
    avgRating,
    difficultyLevel,
    courseDuration,
    credits,
    courseCategory,
    courseSuggestion,
    subTitle,
    fromDate,
    fromLabel,
    toDate,
    toLabel,
  } = props

  const dispatch: Dispatch<any> = useDispatch()
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const stateHandler = (state: any) => {
    updateRatings({
      id: props?.id,
      state,
    })
  }

  const CardBody = () => {
    return (
      <>
        <div className="flex-card-body">
          <div className="flex-card-body-head">
            <div className="flex-card-body-head-star-taxonomy">
              <Column desktop={8} tablet={8} mobile={8}>
                {isRatingsAvailable && avgRating ? (
                  <StarsRating
                    avgRating={avgRating}
                    setShowLoginPopUp={() => setShowLoginPopUp(true)}
                    title=""
                  />
                ) : (
                  <></>
                )}
              </Column>
              <Column desktop={4} tablet={4} mobile={4} className="flex-card-body-head-credit">
                {credits?.length ? <Taxonomy taxonomies={credits} /> : <></>}
              </Column>
            </div>
          </div>
          <div className="flex-card-body-recommand">
            {courseSuggestion?.icon && courseSuggestion?.label ? (
              <IconWithLabel icon={<Icon iconName={courseSuggestion?.icon} />}>
                {courseSuggestion?.label}
              </IconWithLabel>
            ) : (
              <></>
            )}
            {subTitle ? (
              <h6 className="flex-card-body-recommand-subTitle bold">{subTitle}</h6>
            ) : (
              <></>
            )}
          </div>
          {title && <h4 className="flex-card-body-title">{trimText(title, CHARLIMIT.TITLE)}</h4>}
          <div className="flex-card-body-head-fromToText">
            {fromDate && fromLabel && <span>{`${fromLabel} ${fromDate} `}</span>}
            {toDate && toLabel && <span>{`${toLabel} ${toDate}`}</span>}
          </div>

          {desc && <RichText content={desc} className="flex-card-body-desc" />}
          <div className="flex-card-body-bottom">
            {difficultyLevel ? (
              <IconWithLabel icon={<Icon iconName={difficultyLevel?.icon} />}>
                {difficultyLevel?.label}
              </IconWithLabel>
            ) : (
              <></>
            )}
            {courseDuration ? (
               <IconWithLabel icon={<Icon iconName={courseDuration?.icon} />}>
               {courseDuration?.label}
             </IconWithLabel>
      
            ) : (
              <></>
            )}
            {bookmarkContent ? (
              <div className="flex-card-body-head-icon-bookmark">
                <BookmarkWithButton id={id} {...bookmarkContent} bookmarkCallback={stateHandler} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Column desktop={desktopColumns} tablet={tabletColumns} mobile={12} className={className}>
        <div className="flex-card">
          {cardImage?.src && (
            <>
              <div className="flex-card-img-section">
                <div className="flex-card-img">
                  <Anchor noUnderline tabindex={0} href={""} isExternal={false}>
                    <Img
                      type="card"
                      styledimage={cardImage?.styledimage}
                      src={cardImage?.src}
                      alt={cardImage?.alt}
                      width={344}
                      height={246}
                      islazyloaded={false}
                    />
                  </Anchor>
                </div>

                <div className="flex-card-img-wrapper">
                  <div className="flex-card-img-overlay">
                    <div className="flex-card-img-tax-icon">
                      {taxonomies?.length ? <Taxonomy taxonomies={taxonomies} className="start-0"/> : <></>}
                      {courseCategory ? (
                        <div className="icon-bg end-0">
                          <Icon iconName={courseCategory} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="flex-card-body-section">
            <CardBody />
          </div>
        </div>
      </Column>
    </>
  )
}

export default FlexibleCard
