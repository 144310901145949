import AnchorButton from "@atoms/anchorButton"
import Column from "@atoms/column"
import RichText from "@atoms/richText"
import Row from "@atoms/row"

interface ChangePasswordConfig {
  title: string;
  description: any;
  cta_text: string;
  link: string;
}

const ChangePassword = (props: ChangePasswordConfig) => {
  const {
 title, description, link, cta_text,
} = props

  return (
    <div className="change-password">
      <Row>
        <Column mobile={12} tablet={12} desktop={6} className="form-section">
          <div className="content">
            {title && <RichText className="title" content={title || ""} />}
            {description && (
              <RichText className="description" content={description?.value} />
            )}
          </div>

          <div className="changepassword-submit">
            <AnchorButton href={link} isExternal={true} tabindex={2}>
              {cta_text}
            </AnchorButton>
          </div>
        </Column>
      </Row>
    </div>
  )
}

export default ChangePassword
