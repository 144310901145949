import { getApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"
import { IWebFormData } from "./_api.interface"
import getConfigDisplayData from "./getConfigDisplayData"
import { WEBFORM } from "@helpers/constants/webform"

const getWebFormData = async (url: string, ...args: any) => {
  if (!url) throw consoleError(`URL parameter is undefined in ${getWebFormData.name}.`)
  const serverData = args?.[3] // accepting serverData only

  const rawHeaderData = await getApi(url, false, serverData)
  const configData = await getConfigDisplayData(serverData)

  const notificationMessages =
    configData?.flash_num_msg?.filter(
      (item: { title: string }) => item?.title?.toLowerCase() === WEBFORM.CONTACT_US,
    ) ?? null
  const getSectionValues = (val: IWebFormData) => {
    switch (val["#type"]) {
      case "select":
        return [
          {
            type: "text",
            title: val["#description"] ?? "",
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
          },
          {
            type: val["#type"],
            formName: val["#webform"] ?? "",
            key: val["#webform_key"] ?? "",
            options:
              Object.entries(val["#options"] || {}).map(([key, value], index) => ({
                key,
                value,
                id: index,
              })) ?? [],
          },
        ]
      case "textfield":
      case "tel":
      case "email": {
        const title = { type: "text", title: val["#description"] ?? "" }
        const data = {
          type: val["#type"] ?? "text",
          label: val["#title"] ?? "",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
          validations: {
            mandatory: {
              required: val["#required"] ?? false,
              message: val["#required_error"] ?? "",
            },
            pattern: {
              required: !!val["#pattern"] ?? false,
              regEx: val["#pattern"] ?? "",
              message: val["#pattern_error"] ?? "",
            },
          },
          validationErrorMessage: val["#required_error"] ?? "",
          inputField: {
            name: val["#webform_key"],
            placeholder: val["#placeholder"] ?? "",
            required: val["#required"] ?? "",
          },
        }
        return val["#description"] ? [title, data] : data
      }
      case "checkbox": {
        const data = {
          type: val["#type"],
          label: val["#title"] ?? "",
          id: val["#webform_id"],
          value: val["#title"] ?? "",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
          isDisabled: false,
          isChecked: false,
          isRequired: val["#required"] ?? false,
        }
        return data
      }
      case "webform_actions":
        return {
          text: val["#submit__label"] ?? "",
          url: "#",
          type: "button",
          role: "submit",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
        }
      case "label":
        return {
          text: val["#title"] ?? "",
          url: "#",
          type: "button",
          role: "reset",
          formName: val["#webform"] ?? "",
          key: val["#webform_key"] ?? "",
        }
      default:
        return {
          type: val["#type"] ?? "text",
        }
    }
  }
  const webFormData = Object.entries(rawHeaderData).reduce((accumulator, [, value]) => {
    const sectionNumber = accumulator.length
    if (value && typeof value === "object") {
      const returnValue = getSectionValues(value as IWebFormData)
      if (Object.keys(value).includes("#description")) {
        return [...accumulator, returnValue]
      }
      const tempAcc = [...accumulator[sectionNumber - 1], returnValue]
      accumulator[sectionNumber - 1] = tempAcc
      return accumulator
    }
  }, [] as any)
  return { sections: webFormData, notificationMessages }
}

export default getWebFormData
