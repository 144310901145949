import React from "react"
import { Icon, Img, ModalBody } from "@atoms"

import ModalPortal from "@molecules/modalPortal"
import { IWebStoreModalProps } from "./_webStoreModal.interface"

const WebStoreImageModal = ({ src, imageStyledUrl, onClose }: IWebStoreModalProps) => {
  const modalContent = (
    <>
      <span className="close" onClick={onClose}>
        <Icon iconName="CloseFloatingbanner" />
      </span>
      <ModalBody
        content={
          <Img
            src={src || ""}
            alt="imgInfo.alt"
            width={704}
            height={704}
            styledimage={imageStyledUrl}
            type="product_catalogue"
          />
        }
      />
    </>
  )

  return (
    <ModalPortal
      className="webstore-image-modal"
      modalContent={modalContent}
      wrapperId="react-portal-wrapper"
    />
  )
}

export default WebStoreImageModal
