import React, { useContext, useEffect, useState } from "react"
import { TaxonomyProps } from "./_taxonomy.interface"
import { trimText } from "@utils/stringparsing"
import NUMBERS from "@helpers/constants/numbers"
import ApplicationContext from "@utils/application-context/applicationContext"
import { useMediaQuery } from "@helpers/hooks"

// Function to generate class names for the component
const getClasses = (className: string | undefined): string =>
  ["taxonomy", className || ""].filter(Boolean).join(" ")

const Taxonomy = ({ taxonomies, className, itemsToDisplay, limitChar = 0 }: TaxonomyProps) => {
  // State to manage tooltip visibility
  const [showTooltip, setShowTooltip] = useState(false)
  // State to store configuration data
  const [configData, setConfigData] = useState<any>(null)

  const tagsCounter = itemsToDisplay || 1

  const applicationConfigContext = useContext(ApplicationContext)

  const isMobile = useMediaQuery("(max-width: 767px)")

  // Fetch the config data on component mount
  useEffect(() => {
    function fetchConfigData() {
      setConfigData(applicationConfigContext?.applicationConfiguration?.siteConfig)
    }
    fetchConfigData()
  }, [applicationConfigContext?.applicationConfiguration?.siteConfig])

  useEffect(() => {
    const setOverflowStyle = (selector: string) => {
      const elements = document.querySelectorAll(selector)
      elements.forEach((element) => {
      const htmlElement = element as HTMLElement
      htmlElement.style.overflow = showTooltip ? "visible" : "hidden"
      })
    };

    setOverflowStyle(".videoBanner");
    setOverflowStyle(".card");
    setOverflowStyle(".switch-view-button-spaceContainer");
    setOverflowStyle(".heroBanner")
  }, [showTooltip])

  // Event handlers for mouse enter, mouse leave, and toggle tooltip
  const handleMouseEnter = () => setShowTooltip(true)
  const handleMouseLeave = () => setShowTooltip(false)
  const toggleTooltip = () => setShowTooltip(!showTooltip)


  // Main rendering logic
  if (taxonomies && taxonomies?.length > 0) {
    let renderedTags
    let [firstTag, secondTag, ...remainingTags] = taxonomies

    if (tagsCounter === 1) {
      const singleTag = taxonomies[0]
      if (taxonomies?.length === 1 && taxonomies?.[0]?.taxonomyLabel) {
        if(taxonomies?.[0]?.taxonomyLabel.length > NUMBERS.FOURTEEN) {
          renderedTags = (
            <h6 className={getClasses(className)}>
              {trimText(singleTag?.taxonomyLabel, limitChar ? limitChar + NUMBERS.TWO : NUMBERS.TEN)}
            </h6>
          )  
        } else {
          renderedTags = <h6 className={getClasses(className)}>{singleTag?.taxonomyLabel}</h6>
        }

      } else {
        renderedTags = singleTag?.taxonomyLabel && (
          <h6 className={getClasses(className)}>
            {trimText(singleTag?.taxonomyLabel, limitChar ? limitChar : NUMBERS.EIGHT)}
          </h6>
        )
      }
      remainingTags = taxonomies.slice(1)
    } else if (tagsCounter === 2) {
      if (isMobile) {
        renderedTags = (
          <>
            {firstTag?.taxonomyLabel && (
              <h6 className={getClasses(className)}>{trimText(firstTag?.taxonomyLabel, NUMBERS.TWENTY_TWO)}</h6>
            )}
          </>
        )
        remainingTags = taxonomies.slice(1)
      } else {
        renderedTags = (
          <>
            {firstTag?.taxonomyLabel && (
              <h6 className={getClasses(className)}>{trimText(firstTag?.taxonomyLabel, NUMBERS.THIRTY)}</h6>
            )}
            {secondTag?.taxonomyLabel?.length > 0 && (
              <h6 className={getClasses(className)}>{trimText(secondTag?.taxonomyLabel, NUMBERS.THIRTY)}</h6>
            )}
          </>
        )
      }
    }
    {
      // Extract the remaining taxonomy items for the tooltip
      const tooltipContent = remainingTags?.map(({ taxonomyLabel }) => taxonomyLabel)

      // Render the tooltip container if there are more taxonomy items
      const tooltipContainer = (title: string) => (
        <div
          aria-expanded={true}
          className="tooltip-container"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={toggleTooltip}>
          {/* Display the count of remaining taxonomy items */}
          <h6 className={getClasses(className) + " remainingTaxonomies"}>
            {remainingTags?.length}
          </h6>
          {showTooltip && <span className="triangle" />}
          {/* Render the tooltip content if the tooltip is visible */}
          {showTooltip && (
            <div className="tooltip">
              <h4 className="tooltip-list"><strong>{title}</strong></h4>
              <div className="tooltip-display">
                {/* Render the remaining taxonomy items in the tooltip */}
                <p className="text">{tooltipContent?.join(", ")}</p>
              </div>
            </div>
          )}
        </div>
      )

      renderedTags = (
        <>
          {renderedTags}
          {remainingTags?.length > 0 && tooltipContainer(configData?.cards_multi_tags_title ?? "")}
        </>
      )
    }

    // Wrap rendered tags in the taxonomy parent container
    return (
      <div className="taxonomy-parent">
        {renderedTags && renderedTags}
      </div>
    )
  }

  // Return null if no taxonomies are provided
  return null
}

export default Taxonomy
