import React from "react"
import { AnchorButton, Column, Img, RichText } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import getDirection from "@utils/direction"
import { useMediaQuery } from "@helpers/hooks"
import { trimText } from "../../../utils/stringparsing"
import { ITeaserFeatureCardProps } from "./_teaserFeatureCard.interface"

const TeaserFeatureCard = ({
  styledimage,
  imageSrc,
  imageAlt,
  title,
  paragraph,
  link,
  linkIsExternal,
  linkText,
  gridType,
  columnTablet,
  columnDesktop,
  isSingleCard = false,
  cardAlignment,
}: ITeaserFeatureCardProps) => {
  const isMobile = useMediaQuery("(max-width: 991px)")
  const imgWidth = isMobile ? 324 : 176
  return (
    <Column
      desktop={columnDesktop || 4}
      tablet={columnTablet || 6}
      mobile={12}
      className="teaser-feature-card-wrapper">
      <div
        className={gridType ? "teaser-feature-card grid-view" : "teaser-feature-card " + cardAlignment}
        dir={getDirection()}>
        <div
          className={`teaser-feature-card-image${
            isSingleCard ? " single-teaser-feature-card" : ""
          }`}>
          {imageSrc && (
            <Img
              type="card"
              styledimage={styledimage}
              src={imageSrc}
              width={imgWidth}
              height={200}
              alt={imageAlt}
            />
          )}
        </div>
        <div className="card-body">
          <div className="teaser-feature-card-info">
            {title && (
              <h4 className="teaser-feature-card-title bold">{trimText(title, CHARLIMIT.TITLE)}</h4>
            )}
            {paragraph && (
              <RichText
                className="card-text teaser-feature-card-desc"
                content={paragraph}
                characterLimit={CHARLIMIT.DESCRIPTION}
              />
            )}
            {link && (
              <div className="teaser-feature-card-action">
                <AnchorButton
                  isSecondary={true}
                  tabindex={0}
                  href={link}
                  isExternal={linkIsExternal}>
                  {linkText}
                </AnchorButton>
              </div>
            )}
          </div>
        </div>
      </div>
    </Column>
  )
}

export default TeaserFeatureCard
