import { Column, Container, Icon, Img } from "@atoms"
import React, { useMemo } from "react"
import getDirection from "@utils/direction"
import ArticleTeaserContent from "../../molecules/articleTeaserContent"
import {
  EArticleTeaserCompositionType,
  EArticleTeaserContainerDefault,
  EArticleTeaserImagePosition,
  IArticleTeaserProps,
} from "./_article-teaser.interface"
import { useMediaQuery } from "@helpers/hooks"

const ArticleTeaser = ({
  articleTeaserContent,
  articleTeaserImage,
  compositionType,
  imagePosition,
  containerDefault,
  className,
}: IArticleTeaserProps) => {
  const isContactTeaser = compositionType === EArticleTeaserCompositionType.CONTACT_TEASER
  const isImageRight = imagePosition === EArticleTeaserImagePosition.IMAGE_RIGHT
  const imageProperties = {
    width: containerDefault === EArticleTeaserContainerDefault.CONTAINER_NARROW ? "374" : "568",
    height: containerDefault === EArticleTeaserContainerDefault.CONTAINER_NARROW ? "255" : "379",
  }
  const classes = className ? `article-teaser ${className}` : "article-teaser"
  Object.assign(articleTeaserImage?.desktopImage ?? {}, { ...imageProperties })
  const isMobile = useMediaQuery("(max-width: 767px)")
  const shouldRenderDesktopImage = useMemo(() => {
    return !isMobile || (isMobile && !articleTeaserImage?.mobileImage?.src)
  }, [articleTeaserImage?.mobileImage, isMobile])

  const renderImage = useMemo(() => {
    const deskStyleImg = articleTeaserImage?.desktopImage?.styledimage
    const mobStyleImg = articleTeaserImage?.mobileImage?.styledimage
    const imgType = isMobile ? "article_Teaser_product_range_plp" : "article_teaser_latest_articles"
    const src = (
      Boolean(mobStyleImg)
        ? !articleTeaserImage?.desktopImage?.src?.includes("isPaywall=true")
        : articleTeaserImage?.mobileImage?.src?.includes("isPaywall=true")
    )
      ? articleTeaserImage?.mobileImage?.src
      : articleTeaserImage?.desktopImage?.src
    const alt = Boolean(mobStyleImg) ? articleTeaserImage?.mobileImage?.alt : articleTeaserImage?.desktopImage?.alt
    const { height: desktopHeight, width: desktopWidth } = articleTeaserImage?.desktopImage
    const width = isMobile ? articleTeaserImage?.mobileImage?.width : desktopWidth
    const height = isMobile ? articleTeaserImage?.mobileImage?.height : desktopHeight

  const imgComp = (
    <Column desktop={6} tablet={6} mobile={12} className="article-teaser-image">
      <Img
        className="article-teaser-image-content"
        type={imgType}
        styledimage={
          !shouldRenderDesktopImage &&
          (Boolean(mobStyleImg) || articleTeaserImage?.mobileImage?.src?.includes("isPaywall=true"))
            ? mobStyleImg
            : deskStyleImg
        }
        src={src ?? null}
        alt={alt ?? ""}
        width={width ?? 358}
        height={height ?? 220}
      />
    </Column>
  )
  if(deskStyleImg || mobStyleImg) {
    return imgComp
  }

  return (
    <Column desktop={6} tablet={6} mobile={12} className="article-teaser-fluid-image">
      <Img 
        className="article-teaser-image-content"
        type="default"
        width={358}
        height={220}
        {...articleTeaserImage?.desktopImage} />
    </Column>
  )
}, [articleTeaserImage, shouldRenderDesktopImage, isMobile])




  return (
    <Container className="no-section-padding" narrow={containerDefault === EArticleTeaserContainerDefault.CONTAINER_NARROW}>
      <div className={classes} dir={getDirection()}>
        <div className="row">
          {!isImageRight && articleTeaserImage ? renderImage : <></>}
          <Column desktop={6} tablet={6} mobile={12} className={`article-teaser-content-${isImageRight ? "left" : "right"}`}>
            <ArticleTeaserContent {...articleTeaserContent} buttonIcon={isContactTeaser && <Icon iconName="MapIcon" />} />
          </Column>
          {isImageRight && articleTeaserImage ? renderImage : <></>}
        </div>
      </div>
    </Container>
  )
}

export default ArticleTeaser
