import { Dispatch } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import { AnchorButton, Column, RichText, Row, Taxonomy } from "@atoms"
import FavoriteIcon from "@atoms/favourite"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import BookmarkWithButton from "../bookmarkWithButton"
import { IMaterialTeaserCardContentProps } from "./_materialTeaserCardContent.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const MaterialTeaserCardContent = ({
  id,
  taxonomies,
  text,
  heading,
  buttonUrl,
  isButtonUrlExternal,
  buttonLabel,
  bookmark,
}: IMaterialTeaserCardContentProps) => {
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const applicationConfigContext = useContext(ApplicationContext)

  let totalFavoriteCount = 0
  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const handleRatings = (state: any) => {
    // add id details into store
    updateRatings({
      id,
      state,
    })
  }
  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  return (
    <div className="material-teaser-card-content">
      <Row>
        <Column desktop={8} tablet={8} mobile={8}>
          {taxonomies && <Taxonomy taxonomies={taxonomies} />}
        </Column>
        <Column
          desktop={4}
          tablet={4}
          mobile={4}
          className="material-teaser-card-content-icon wrap">
          <Row>
            {bookmark && (
              <Column
                desktop={4}
                tablet={4}
                mobile={4}
                className="material-teaser-card-content-icon">
                <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={handleRatings} />
              </Column>
            )}
            <Column desktop={8} tablet={8} mobile={8} className="material-teaser-card-content-icon">
              <div>
                <div className="material-teaser-card-content-favorite-section">
                  <span className="favorite-icon">
                    {(text || heading || taxonomies[0].taxonomyLabel) &&
                      applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                        <FavoriteIcon
                          id={id}
                          totalFavoriteCount={totalFavoriteCount}
                          favouriteStatus={isFavouriteSelected}
                          favoriteHandlerCallback={favouriteHandler}
                        />
                      )}
                  </span>
                </div>
              </div>
            </Column>
          </Row>
        </Column>
      </Row>
      <h4 className="material-teaser-card-content-header bold">
        {trimText(heading, CHARLIMIT.TITLE)}
      </h4>
      {text && (
        <RichText
          className="material-teaser-card-content-text"
          content={text}
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      )}
      {(buttonUrl || isButtonUrlExternal) && buttonLabel ? (
        <div className="material-teaser-card-content-btn-group">
          <AnchorButton href={buttonUrl} isSecondary tabindex={1} isExternal={isButtonUrlExternal}>
            {buttonLabel}
          </AnchorButton>
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}

export default MaterialTeaserCardContent
