export interface ITheme {
  [key: string]: string
}
export interface IThemes {
  [key: string]: ITheme
}
export const switchTheme = (theme: string) => {
  const currentTheme = themes[theme]

  for (const key in currentTheme) {
    document.documentElement.style.setProperty(key, currentTheme[key])
  }
}

const themes: IThemes = {
  default: {
    "--primary-brand-color": "#023761",
    "--primary-brand-hover-color": "#007acc",
    "--primary-brand-normal-color": "#0460a9",
    "--primary-brand-light-color": "#5291dd",
    "--secondary-brand-color": "#ffffff",
    "--primary-text-color": "#023761",
    "--body-light-text-color": "#ffffff",
    "--body-text-color": "#000000",
    "--primary-border-radius": "2px",
    "--primary-font": "Futura Now Text",
  },
  blue: {
    "--primary-brand-color": "#023761",
    "--primary-brand-hover-color": "#007acc",
    "--primary-brand-normal-color": "#0460a9",
    "--primary-brand-light-color": "#5291dd",
    "--secondary-brand-color": "#ffffff",
    "--primary-text-color": "#023761",
    "--body-light-text-color": "#ffffff",
    "--body-text-color": "#000000",
    "--primary-border-radius": "2px",
    "--primary-font": "Futura Now Text",
  },
  red: {
    "--primary-brand-color": "#a70000",
    "--primary-brand-hover-color": "#ff0000",
    "--primary-brand-normal-color": "#ff5252",
    "--primary-brand-light-color": "#ffbaba",
    "--secondary-brand-color": "#ffffff",
    "--primary-text-color": "#a70000",
    "--body-light-text-color": "#ffffff",
    "--body-text-color": "#000000",
    "--primary-border-radius": "0",
    "--primary-font": "sans-serif",
  },
  green: {
    "--primary-brand-color": "#607c3c",
    "--primary-brand-hover-color": "#809c13",
    "--primary-brand-normal-color": "#809c13",
    "--primary-brand-light-color": "#b5e550",
    "--secondary-brand-color": "#ffffff",
    "--primary-text-color": "#607c3c",
    "--body-light-text-color": "#ffffff",
    "--body-text-color": "#000000",
    "--primary-border-radius": "6px",
    "--primary-font": "Georgia, serif",
  },
}
