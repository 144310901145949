import { Row } from "@atoms"
import React from "react"
import {
  CarouselWrapper,
  FeatureCards,
  IconCard,
  TeaserCard,
  TeaserFeatureCard,
  TeaserSnackBite,
} from "@organisms"
import { ITeaserBlockProps } from "./_teaser-block.interface"

const TeaserBlock = (props: ITeaserBlockProps) => {
  const {
    displayOption,
    cardType,
    teaserData,
    columnDesktop,
    columnTablet,
    cardCountInDesk,
    cardCountInTab,
  } = props

  const cardTypeSwitcher = (
    cardTypeArg: any,
    teaserDataArg: any,
    columnDesktopArg: any,
    columnTabletArg: any,
  ) => {
    switch (cardTypeArg) {
      case "icon_cards":
        return teaserDataArg.map((teaserItem: any, key: number) => (
          <IconCard
            key={key}
            columnDesktop={columnDesktopArg}
            columnTablet={columnTabletArg}
            {...teaserItem}
            cardAlignment={teaserItem?.cardAlignment}
          />
        ))
      case "teaser_description_card":
        return teaserDataArg.map((teaserItem: any, key: number) => (
          <FeatureCards
            key={key}
            columnDesktop={columnDesktopArg}
            columnTablet={columnTabletArg}
            {...teaserItem}
          />
        ))
      case "teaser_snacbite":
        return teaserDataArg.map((teaserItem: any, key: number) => (
          <TeaserSnackBite
            key={key}
            columnDesktop={columnDesktopArg}
            columnTablet={columnTabletArg}
            {...teaserItem}
          />
        ))
      case "teaser_block_card":
        return teaserDataArg.map((teaserItem: any, key: number) => (
          <TeaserCard
            key={key}
            columnDesktop={columnDesktopArg}
            columnTablet={columnTabletArg}
            {...teaserItem}
          />
        ))
      case "teaser_feature_block":
        return teaserDataArg.map((teaserItem: any, key: number) => (
          <TeaserFeatureCard
            key={key}
            columnDesktop={columnDesktopArg}
            columnTablet={columnTabletArg}
            {...teaserItem}
          />
        ))
      default:
        return <></>
    }
  }

  switch (displayOption) {
    case "carousel": {
      const classes =
        cardCountInDesk === 5 || cardCountInTab === 5
          ? "teaser-block-carousel pow-of-five"
          : "teaser-block-carousel"
      return (
        <CarouselWrapper
          slideToShow={cardCountInDesk}
          slideToShowTablet={cardCountInTab}
          className={classes}>
          {cardTypeSwitcher(cardType, teaserData, columnDesktop, columnTablet)}
        </CarouselWrapper>
      )
    }
    case "rail": {
      return (
        <Row className="teaser-block-rail">
          {cardTypeSwitcher(cardType, teaserData, columnDesktop, columnTablet)}
        </Row>
      )
    }
    case "tile": {
      return (
        <Row className="teaser-block-tile">
          {cardTypeSwitcher(cardType, teaserData, columnDesktop, columnTablet)}
        </Row>
      )
    }
    default:
      return <></>
  }
}
export default TeaserBlock
