import React, { useContext, useEffect, useState } from "react"
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table"
import { useMediaQuery } from "@helpers/hooks"
import { Anchor, Icon, RichText } from "@atoms"
import _ from "lodash"
import SearchFilterCard from "@organisms/searchFilterCard"
import moment from "moment"
import { COLUMN_KEYS } from "@helpers/constants/generic"
import {
  ISearchFilterContent,
  ISearchFilterInterface,
  searchFilterType,
} from "./_searchFilterTable.interface"
import { getApiDomainAndLang } from "@utils/baseApi"
import ApplicationContext from "@utils/application-context/applicationContext"


export const SearchFilterContent = (data: ISearchFilterContent) => {
  const { applicationConfiguration } = useContext(ApplicationContext)
  let desc = data?.desc
  if (data?.type === "date") {
    const { lang } = getApiDomainAndLang()
    desc = !data?.isNotCareerSearch
      ? moment(desc).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
      : moment(desc).locale(lang).format(`${applicationConfiguration?.siteConfig?.date_format}_HMS`)
  }
  if (data?.type === "actions") {
     return data?.linkIcon ?? <></>
  }
  return (
    <div>
      {data?.title ? (
        <Anchor
          className="search-filter-title"
          href={data?.link}
          tabindex={0}
          noUnderline={true}
          icon={data?.linkIcon}
          isExternal={true}
          download={data?.type === "download"}
          target={data?.type === "download" || data?.type === "actionUrl" ?  "_blank" : "_self"}
          >
          {data.type !== "download" ? data?.title : ""}
        </Anchor>
      ) : null}
      { data.type !== "richtext" ? <p className="search-filter-desc">{desc}</p> : <RichText className="search-filter-desc" content={desc} /> }
    </div>
  )
}

const columnHelper = createColumnHelper<searchFilterType>()

const SearchFilterTable = (props: ISearchFilterInterface) => {
  const { filterData, columnsList, tableColumns, handleSort, sortValue, isNotCareerSearch = false, disableSortColumnList = [] } = props
  const [data, setData] = useState<searchFilterType[]>(filterData)
  const [highlightColumn, setHighlightColumn] = useState<string>("")
  const isMobile = useMediaQuery("(max-width: 991px)")

  const columns =
    columnsList && columnsList.length
      ? columnsList.map((header: string) =>
          columnHelper.accessor(_.camelCase(header), {
            header: () => header,
            cell: (info) => (
              <SearchFilterContent
                title={info.getValue()?.title}
                desc={info.getValue()?.desc}
                link={info.getValue()?.link}
                type={info.getValue()?.type}
                isNotCareerSearch={info.getValue()?.isNotCareerSearch}
                linkIcon={info.getValue()?.linkIcon}
              />
            ),
            sortingFn: (rowA: any, rowB: any, columnId) => {
              handleSort(columnId)
              let orderKey = "desc"
              if (columnId === "jobTitle") {
                orderKey = "title"
              }
              const valA = rowA.getValue(columnId)[orderKey]
              const valB = rowB.getValue(columnId)[orderKey]
              if (valA < valB) {
                return 1
              }
              if (valA > valB) {
                return -1
              }
              return 0
            },
          }),
        )
      : []
  useEffect(() => {
    setData(filterData)
  }, [filterData])

  const disableSort = (column: string) => disableSortColumnList?.includes(column)

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    manualPagination: true,
  })
  // @ts-ignore
  const sortIcon = (id: string, order: string) =>
    id === sortValue?.column && order === "DESC" ? <Icon iconName="SortZA2" /> : <Icon iconName="SortAZ2" />
  const headerClass = (headerColumn: any) => (headerColumn.getCanSort() ? "search-filter-sort" : "")
  const getDesktopView = () => (
    <table>
      <thead className="search-filter-header">
        {table?.getHeaderGroups()?.map((headerGroup) => (
          <tr key={headerGroup.id} className="search-filter-row">
            {headerGroup?.headers?.map((header) => (
              <th
                key={header.id}
                colSpan={header.colSpan}
                onMouseEnter={() => setHighlightColumn(header.column.id)}
                onMouseLeave={() => setHighlightColumn("")}
                className={`search-filter-hd-row ${
                  header.column.id === highlightColumn ? "column-highlight" : ""
                }`}
                onClick={() =>
                  disableSort(header.column.id) ? null : handleSort(header.column.id)
                }>
                {header.isPlaceholder ? null : (
                  <div
                    aria-hidden="true"
                    className={headerClass(header.column)}
                    onClick={header.column.getToggleSortingHandler()}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {!disableSort(header.column.id) && (
                      <span>
                        {sortValue?.column &&
                        (!isNotCareerSearch
                          ? header.column.id
                          : COLUMN_KEYS[header?.column?.id as keyof typeof COLUMN_KEYS]) ===
                          sortValue?.column ? (
                          sortIcon(
                            !isNotCareerSearch
                              ? header.column.id
                              : COLUMN_KEYS[header?.column?.id as keyof typeof COLUMN_KEYS],
                            sortValue?.column ? sortValue?.order : "ASC",
                          )
                        ) : (
                          <Icon iconName="SortZA2" />
                        )}
                      </span>
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table?.getRowModel().rows?.map((row) => (
          <tr key={row.id} className="search-filter-row-detail">
            {row?.getVisibleCells()?.map((cell) => (
              <td
                key={cell.id}
                className={`search-filter-row-detail-td ${
                  cell.column.id === highlightColumn ? "column-highlight" : ""
                }`}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )

  const getMobileView = () =>
    data?.map((value: searchFilterType, index: number) => {
      return (
        <div className="mobile-view" key={index}>
          <SearchFilterCard values={value} columnsList={tableColumns} />
        </div>
      )
    })

  return <div className="search-filter">{isMobile ? getMobileView() : getDesktopView()}</div>
}
export default SearchFilterTable
