import { RichText } from "@atoms"
import React from "react"
import { ITextBlockProps } from "./_textBlock.interface"

const TextBlockProfile = (props: ITextBlockProps) => {
  const { alignment, paragraph, title } = props
  let classes = "text-block-profile"
  if(alignment) {
    classes = classes + " " + alignment
  }
  return (
    <div className={classes}>
      {title && <div className="text-block-profile-subdiv" />}
      {paragraph && <RichText content={paragraph} />}
    </div>
  )
}

export default TextBlockProfile
