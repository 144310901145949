export enum ERelatedContent {
  EVENT = "event",
  PRODUCT = "product",
  EDUCATION = "education",
  NEWS = "news_and_stories",
  THERAPY = "therapy_areas_disease_group",
  CSV_PRODUCT = "csv_product",
  MAGAZINE = "magazine",
}

export interface IRelatedContent {
  url: string
  background?: string
}
