import ButtonGroup from "@molecules/button-group"
import Button from "@atoms/button"
import { IModalFooterProp } from "./_modalFooter.interface"
import { AnchorButton } from "@atoms"

const ModalFooter = (props: IModalFooterProp) => {
  const { closeBtn, actionBtn, className = "", onClose = () => null, onAction = () => null, isHCP} = props
  const handleClose = () => {
    onClose()
  }
  const handleAction = () => {
    onAction()
  }
  const getClasses = () => {
    const classes = ["modal-footer modal-buttons-container", className].filter(Boolean)
    return classes.join(" ")
  }

  const isExternal = closeBtn?.isExternal as boolean
  return (
    <div className={getClasses()} role="group">
      <ButtonGroup className={`${isHCP ? "modal-footer-HCP-buttons-group" : "modal-buttons-group"}`}>
        <>
          {closeBtn && closeBtn?.children &&(
            <AnchorButton
              href={closeBtn?.href}
              isExternal={isExternal}
              tabindex={closeBtn?.tabindex}
              isSecondary
              onClick={handleClose}
              target={closeBtn?.target}>
              {closeBtn?.children}
            </AnchorButton>
          )}
          {actionBtn && (
            <Button
              className="modal-buttons-action-btn"
              {...actionBtn}
              onClick={() => handleAction()}>
              {actionBtn.children}
            </Button>
          )}
        </>
      </ButtonGroup>
    </div>
  )
}

export default ModalFooter
