import { useEffect, useState } from "react"
import { ISearchBox } from "./searchBox.interface"

const SearchBox = (props: ISearchBox) => {
  const {
    placeholderText,
    setSearchText,
    isCleared,
    currentSearchText = "",
    useStateVal = true,
    handleSearch,
    type = "prodSearch",
  } = props
  const [text, setText] = useState(currentSearchText)

  const handleChange = (event: any) => {
    const searchText = event.target.value || ""
    setText(searchText)
    setSearchText(searchText)
    if (!searchText) {
      handleSearch && handleSearch(searchText)
    }
  }

  useEffect(() => {
    if (isCleared) {
      setText("")
      setSearchText("")
      const input = document
        ? (document.getElementById("searchBarInputId") as HTMLInputElement)
        : null
      if (input) input.value = ""
    }
  }, [isCleared])

  function handleClick(e: any) {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSearch && handleSearch(text)
    }
  }

  return (
    <input
      id="searchBarInputId"
      type={type}
      placeholder={placeholderText}
      value={useStateVal ? text : currentSearchText}
      onChange={handleChange}
      onKeyDown={handleClick}
      className={`search-box ${text === "" ? "search-box-searchicon" : ""}`}
    />
  )
}

export default SearchBox
