import { getApi, getApiDomainAndLang } from "../../utils/baseApi"
import { IComponents, ITaxonomyData, TPageBlocks } from "./_api.interface"
import { consoleError } from "../../utils/error"
import { ISeoProps } from "../../components/atoms/seo/_seo.interface"
import getNestedObject from "../../utils/nestedObjects"

// DEPRECATED
export const getPageBlocks: TPageBlocks = async (blockEndPointAPI: string) => {
  if (!blockEndPointAPI) {
    consoleError(`URL parameter is undefined in ${getPageBlocks.name}.`)
  }
  console.log(`${getPageBlocks.name} is deprecated. Please do not use it.`)
  const pageData = await getApi(blockEndPointAPI)
  const seoData: ISeoProps = await _traverseSeo(pageData?.data?.attributes?.metatag)
  const defaultTheme = await getApi(
    pageData?.data?.relationships?.field_default_theme?.links?.related?.href,
  )
  const currentTheme = defaultTheme?.data?.attributes?.field_style_mapping
    ? defaultTheme?.data?.attributes?.field_style_mapping.toLowerCase()
    : "default"

  const layoutBuilder = pageData?.data?.attributes?.layout_builder__layout
  const allComponents: IComponents = {}
  let num = 1

  layoutBuilder?.map((layout: any, index: number) => {
    const components = layout?.components
    components?.map((component: any) => {
      const type = component?.configuration?.type
      const uuid = component?.configuration?.uuid
      const url = component?.configuration?.url
      const label = component?.configuration?.label

      if (type) {
        if (type in allComponents) {
          allComponents[`${type}_${num++}`] = {
            uuid,
            sequence: index,
            url,
            label,
          }
        } else {
          allComponents[type] = {
            uuid,
            sequence: index,
            url,
            label,
          }
        }
      }
    })
  })

  return {
    pageBlocks: allComponents,
    seoData,
    currentTheme,
  }
}

// DEPRECATED
const _traverseSeo = async (seo: any) => {
  let canonical = ""
  let title = ""
  let description = ""
  seo?.map((meta: any) => {
    if (meta?.tag === "link") {
      if (meta?.attributes?.rel === "canonical") { 
        canonical = meta?.attributes?.href
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "title") {
        title = meta?.attributes?.content
      }
    }
    if (meta?.tag === "meta") {
      if (meta?.attributes?.name === "description") {
        description = meta?.attributes?.content
      }
    }
  })
  return {
    canonical,
    title,
    description,
  }
}

export const getBlockData = async (url: string, isCache = true, serverData?: any) => {
  if (!url) {
    console.log(`URL parameter is undefined in ${getBlockData.name}.`)
    return
  }
  const blockdata = await getApi(`/jsonapi${url}`, isCache, serverData)
  return blockdata || null
}

export const getFileTypeExtension = (filename: string) => {
  const extension = filename?.split(".")?.pop()?.toLowerCase() || ""
  return extension
}

export const isAnimatedImage = (filename: string) => {
  const extension = getFileTypeExtension(filename)
  return extension === "svg" || extension === "gif"
}

export const getImageMeta = (objImageMeta: any) => {
  const imageUrl = objImageMeta?.data?.meta?.absolute_uri ?? null
  const caption = objImageMeta?.data?.meta?.image_caption ?? null
  const imageStyledUrl = objImageMeta?.data?.meta?.image_style?.image_style_uri ?? null
  const alt = objImageMeta?.data?.meta?.img_alt ?? null
  const width = objImageMeta?.data?.meta?.width ?? null
  const height = objImageMeta?.data?.meta?.height ?? null

  return {
    imageUrl,
    imageStyledUrl,
    alt,
    caption,
    width,
    height,
  }
}

export const getImage = async (
  uuid: string,
  mobileuuid?: string,
  logouuid?: string,
  serverData?: any,
) => {
  let mobileImageData = null
  let logoImageData = null
  let imageData = null
  let imageStyledUrl = null

  if (uuid) {
    imageData = await getApi(
      `/jsonapi/media/image/${uuid}?include=field_media_image`,
      true,
      serverData,
    )
  }
  if (mobileuuid) {
    mobileImageData = await getApi(
      `/jsonapi/media/image/${mobileuuid}?include=field_media_image`,
      true,
      serverData,
    )
  }
  if (logouuid) {
    logoImageData = await getApi(
      `/jsonapi/media/image/${logouuid}?include=field_media_image_1`,
      true,
      serverData,
    )
  }

  let imageUrl: Array<string> | string = imageData?.included
    ?.filter((imgObj: any) => imgObj.type === "file--file")
    ?.map((img: any) => {
      return img.attributes.uri.url
    })
  imageUrl = imageUrl && imageUrl.length ? (imageUrl as Array<string>)?.[0] : ""

  if (!isAnimatedImage(imageUrl)) {
    // ignore styled images for animated files
    imageStyledUrl = imageData?.included
      ?.filter((imgObj: any) => imgObj.type === "file--file")
      ?.map((img: any) => {
        return img.attributes.image_style_uri
      })

    imageStyledUrl =
      imageStyledUrl && imageStyledUrl.length ? (imageStyledUrl as Array<any>)?.[0] : {}
  }

  let mobileImageUrl = mobileImageData?.included
    ?.filter((imgObj: any) => imgObj.type === "file--file")
    ?.map((img: any) => {
      return img.attributes.uri.url
    })
  mobileImageUrl =
    mobileImageUrl && mobileImageUrl.length ? (mobileImageUrl as Array<string>)?.[0] : ""

  let logoImageUrl = logoImageData?.included
    ?.filter((imgObj: any) => imgObj.type === "file--file")
    ?.map((img: any) => {
      return img.attributes.uri.url
    })
  logoImageUrl = logoImageUrl && logoImageUrl.length ? (logoImageUrl as Array<string>)?.[0] : ""

  return {
    imageUrl: imageUrl || "",
    imageStyledUrl,
    alt: imageData?.data?.relationships?.field_media_image?.data?.meta?.alt ?? null,
    width: imageData?.data?.relationships?.field_media_image?.data?.meta?.width ?? null,
    height: imageData?.data?.relationships?.field_media_image?.data?.meta?.height ?? null,
    mobileImageUrl: mobileImageUrl || "",
    mobileImageAlt:
      mobileImageData?.data?.relationships?.field_media_image?.data?.meta?.alt ?? null,
    logoImageUrl: logoImageUrl || "",
    logoImageAlt: getNestedObject(
      logoImageData,
      "data.relationships.field_media_image_1.data.meta.alt",
    ),
    caption: imageData?.data?.attributes?.field_media_image_caption ?? null,
  }
}

export const getProductBlockData = async (url: string, serverData?: any) => {
  if (!url) {
    throw consoleError(`Url parameter is undefined in ${getProductBlockData.name}.`)
  }

  const response = await getApi(url, false, serverData)
  return response
}

export const filterURL = (url: string) => {
  if (!url) {
    consoleError(`Url parameter is undefined in ${filterURL.name}.`)
  }
  url = url?.startsWith("internal:") ? url?.replace("internal:", "") : url
  return url || ""
}

export const getTaxonomy = async (
  url: string,
  type?: boolean,
  serverData?: any,
): Promise<ITaxonomyData[]> => {
  // Check if URL is provided
  if (!url) {
    return []
  }

  // Fetch taxonomy data from the API
  let taxonomyData = await getApi(url, false, serverData)
  taxonomyData = taxonomyData?.data

  /**
   * Helper function to process taxonomy data and create ITaxonomyData object.
   * @param {any} taxonomy - Raw taxonomy data from the API.
   * @returns {ITaxonomyData} - Processed ITaxonomyData object.
   */
  const processTaxonomy = (taxonomy: any): ITaxonomyData => {
    const obj: ITaxonomyData = {
      taxonomyLabel: taxonomy?.attributes?.name || null,
      taxonomyID: taxonomy?.attributes?.drupal_internal__tid || null,
    }
    return obj
  }

  // Process taxonomy data based on type and return the result
  if (Array.isArray(taxonomyData)) {
    if (type) {
      // If type is true, return an array of ITaxonomyData objects
      return taxonomyData.map(processTaxonomy)
    } else {
      // If type is false, return an array of objects with only taxonomyLabel
      return taxonomyData.map((taxonomy) => ({
        taxonomyLabel: taxonomy?.attributes?.name || null,
      }))
    }
  }

  // If not an array, process single taxonomy object and return as an array
  return [processTaxonomy(taxonomyData)]
}

export const getBookmarkData = async (url: string) => {
  let bookmarkData = await getBlockData(`${url}`)
  bookmarkData = {
    bookmarkStatus: bookmarkData?.data?.attributes?.status || false,
    isButton: true,
    addText: bookmarkData?.data?.attributes?.field_bookmark_cta_text?.value
      ? bookmarkData?.data?.attributes?.field_bookmark_cta_text?.value
      : "Add to Bookmarks",
    removeText:
      bookmarkData?.data?.attributes?.field_remove_bookmark_cta_text?.value ||
      "Remove from Bookmarks",
  }
  return bookmarkData
}

export const getAnchorCTAFileUrl = async (file_id: string) => {
  if (!file_id) {
    consoleError(`File ID parameter is undefined in ${getAnchorCTAFileUrl.name}.`)
  }
  const ctaFileUrl = await getApi(`/jsonapi/block_content/anchor_cta/${file_id}/field_file_upload`)
  return {
    fileurl: ctaFileUrl?.data?.attributes?.uri?.url || "",
  }
}

export const getFileByUrl = async (url: string, serverData?: any) => {
  if (!url) {
    consoleError(`Url is undefined in ${getFileByUrl.name}.`)
  }
  const fileData = await getApi(url, false, serverData)
  const fileUrl = fileData?.data?.attributes?.uri?.url

  return {
    fileUrl: fileUrl ?? null,
  }
}

export const getPrintDownloadUrl = (drupalNid: number) => {
  if (!drupalNid) {
    consoleError(`Drupal ID is undefined in ${getPrintDownloadUrl.name}.`)
  }

  const { apiDomain } = getApiDomainAndLang() || ""
  return {
    printUrl: `https://${apiDomain}/node/${drupalNid}/printable/print`,
    downloadUrl: `https://${apiDomain}/node/${drupalNid}/printable/pdf`,
  }
}

export const isExternalLink = (target: string) => target === "_blank"

export const getFileDetails = async (
  downloadUUID: string,
  fileType: string,
  mediaFile: string,
  serverData?: any,
) => {
  if (!downloadUUID) {
    consoleError(`downloadUUID is undefined in ${getFileDetails.name}.`)
  }
  const fileData = await getApi(
    `/jsonapi/media/${fileType}/${downloadUUID}/${mediaFile}`,
    true,
    serverData,
  )

  const fileUrl = getNestedObject(fileData, "data.attributes.uri.url") ?? ""

  return {
    fileSize: getNestedObject(fileData, "data.attributes.filesize") ?? null,
    downloadFileLink: fileUrl || "",
  }
}

export const getHeroVideoData = async (url: string) => {
  if (!url) {
    console.log("URL parameter is undefined in Herobanner video.")
    return
  }
  const videoData = await getApi(`${url}`, true)
  const videoURL =
    getNestedObject(videoData, "data.relationships.field_media_video_file.links.related.href") ?? ""
  const mp4Data = await getApi(`${videoURL}`, true)
  return mp4Data || null
}

export const getHeightByClass = (className: string) => {
  let height = 0
  const element = document.querySelector(className)
  if (element) {
    height = parseInt(window.getComputedStyle(element).getPropertyValue("height"))
  }
  return height
}
