import React, { useState } from "react"
import { IPinProtectionProps } from "./_pinProtection.interface"
import { Button, Container, RichText } from "@atoms"
import GenericWebForm from "@organisms/genericWebForm"
import router from "next/router"

const PinProtection = ({
  fieldTitle,
  fieldSubtitle,
  sectionTitle,
  webFormData,
}: IPinProtectionProps) => {
  const [showForm, setShowForm] = useState<string | null>(null)

  const handleOnSubmit = (redirectionUrl: string) => {
    if (showForm !== null) {
      sessionStorage.setItem("pin_protection_for", JSON.stringify(showForm))
      router.push(redirectionUrl)
    }
  }
  return (
    <Container>
      <div className="pin-protection" id="pin-protection">
        <div className="field-section">
          {fieldTitle && <RichText className="field-title bold" content={fieldTitle} />}
          {fieldSubtitle && (
            <RichText className="field-subtitle-paragraph" content={fieldSubtitle} />
          )}
        </div>
        <div className="role-section">
          <div className="vl" />
          {sectionTitle && <RichText className="section-title-paragraph" content={sectionTitle} />}
          <div className="button-container">
            {webFormData?.map(({ id, buttonLabels, webFormContent, redirectionUrl }, index) => (
              <>
                <Button
                  className="patient-button"
                  tabindex={index}
                  key={id}
                  onClick={() => setShowForm((prev) => (prev === id ? null : id))}>
                  {buttonLabels}
                </Button>
                {id === showForm && (
                  <div key={id} className="patient-webform">
                    <GenericWebForm
                      {...webFormContent}
                      handleOnSubmit={() => handleOnSubmit(redirectionUrl)}
                      scrollToEle="message-box"
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </Container>
  )
}
export default PinProtection
