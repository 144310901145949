import { Anchor, AnchorButton, Column, Img } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "../../../utils/stringparsing"
import { Dispatch } from "redux"
import { IOurProductCardProps } from "./_ourProductCard.interface"
import { updateProductCatalogTitle } from "store/actions/CommonActionCreators"
import { useCallback } from "react"
import { useDispatch } from "react-redux"

const OurProductCard = (props: IOurProductCardProps) => {
  const { ourProductCardImage } = props

  const dispatch: Dispatch<any> = useDispatch()

  const updateProductCatalog: any = useCallback(
    (value: any) => dispatch(updateProductCatalogTitle(value)),
    [dispatch],
  )

  const productListingNav = (): any => {
    updateProductCatalog(props.path)
  }

  return (
    <Column desktop={4} tablet={6} mobile={12} className="our-product-card">
      <div className="card">
        {ourProductCardImage.src && (
          <Anchor
            href={props.path ?? "#"}
            tabindex={1}
            noUnderline={true}
            onClick={productListingNav}
            className="our-product-card-hyperlink"
            isExternal={false}>
            <Img
              type="card"
              src={ourProductCardImage.src}
              alt={ourProductCardImage.alt}
              styledimage={ourProductCardImage.styledimage}
              width={368}
              height={220}
              className="our-product-card-image"
            />
          </Anchor>
        )}
        <div className="card-body">
          {props.title && (
            <Anchor
              href={props.path ?? "#"}
              tabindex={1}
              onClick={productListingNav}
              noUnderline={true}
              isExternal={false}
              className="our-product-card-hyperlink">
              <h5 className="our-product-card-header">{trimText(props.title, CHARLIMIT.TITLE)}</h5>
            </Anchor>
          )}
        </div>
        <div className="card-footer">
          <AnchorButton
            tabindex={1}
            href={props.path}
            isExternal={false}
            onClick={productListingNav}
            isSecondary>
            {props.buttonLabel}
          </AnchorButton>
        </div>
      </div>
    </Column>
  )
}
export default OurProductCard
