import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { getBlockData, getImage, getImageMeta } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"
import { ISlideInfo } from "./_api.interface"

const getSlideInfo = async (slide: any): Promise<ISlideInfo | null> => {
  const isChildPaywalled = isPaywallProtected(
    getNestedObject(slide, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  if (isChildPaywalled) {
    return null
  }
  const imagePaywallData = isPaywallProtected(
    getNestedObject(slide, "relationships.field_testimonial_image"),
  )
  const logoPaywallData = isPaywallProtected(
    getNestedObject(slide, "relationships.field_brand_logo"),
  )
  const imgUUID = getNestedObject(slide, "relationships.field_testimonial_image.data.id")
  const logoUUID = getNestedObject(slide, "relationships.field_brand_logo.data.id")

  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(slide?.relationships?.field_testimonial_image)

  const { logoImageUrl, logoImageAlt } = logoPaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(logoPaywallData?.errorObj)
    : await getImage(imgUUID, undefined, logoUUID)

  const name = isPaywallProtected(getNestedObject(slide, "attributes.field_author_name"))
    .isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.field_author_name") ?? null

  const designation = isPaywallProtected(
    getNestedObject(slide, "attributes.field_author_designation"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.field_author_designation") ?? null

  const summary = isPaywallProtected(getNestedObject(slide, "attributes.field_testimonial_summary"))
    .isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.field_testimonial_summary") ?? null

  return {
    testimonialContent: {
      name,
      designation,
      summary,
    },
    image: imageUrl ?? null,
    alt,
    logoImageUrl: logoImageUrl ?? "",
    logoImageAlt: logoImageAlt ?? null,
    styledimage: imageStyledUrl ?? null,
  }
}

const getTestimonialData = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getTestimonialData.name}.`)
  }
  const serverData = args?.[3] // accepting serverData only
  const testimonialData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(testimonialData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const slides = testimonialData?.included ?? []
  const fieldPaywallRoles = getAllRoles(slides)
  const slidesInfo = await Promise.all(slides.map(async (slide: any) => getSlideInfo(slide)))

  return {
    slidesInfo: filterNullValues(slidesInfo),
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getTestimonialData
