import React, { useContext, useEffect, useState } from "react"
import Carousel from "react-simply-carousel"
import { useMediaQuery } from "@helpers/hooks"
import NUMBERS from "@helpers/constants/numbers"
import { Icon } from "@atoms"
import { IRotatingTabProps } from "./_rotatingTab.interface"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"

const RotatingTab = ({ links, topPosition, selectedRotatatingTabCallback }: IRotatingTabProps) => {
  const [updatedLinks, setUpdatedLinks] = useState<Array<any>>(links)
  const [activeSlide, setActiveSlide] = useState(0)
  const isMobile = useMediaQuery("(max-width: 767px)")
  const isTablet = useMediaQuery("(min-width: 768px) and (max-width: 991px)")
  const itemsPerScroll = NUMBERS.ONE
  const { applicationTheme } = useContext(ApplicationThemeContext)
  let itemsPerSlide
  if (isTablet) {
    itemsPerSlide = 6
  } else if (isMobile) {
    itemsPerSlide = 2
  } else {
    itemsPerSlide = 8
  }

  useEffect(() => {
    setUpdatedLinks(links)
  }, [links])

  const handleOnClick = (title: string) => {
    const link = links?.map((l) => {
      if (l.id === title) {
        l.selected = true
      } else {
        l.selected = false
      }
      return l
    })
    setUpdatedLinks(link)
    selectedRotatatingTabCallback(title)
  }

  return (
    <div className="rotating-tab" style={{ top: `${topPosition}px` }}>
      <div className="rotating-tab-container">
        <Carousel
          responsiveProps={[
            {
              itemsToShow: itemsPerSlide,
              itemsToScroll: itemsPerScroll,
              minWidth: 992,
              maxWidth: 2560,
            },
            {
              itemsToShow: itemsPerSlide,
              itemsToScroll: itemsPerScroll,
              minWidth: 768,
              maxWidth: 991,
            },
            {
              itemsToShow: itemsPerSlide,
              itemsToScroll: itemsPerScroll,
              minWidth: 375,
              maxWidth: 767,
            },
          ]}
          containerProps={{
            style: {
              width: "100%",
              justifyContent: "space-evenly",
              flexFlow: "inherit",
              backgroundColor:
                applicationTheme === "rebranding" ||
                applicationTheme === "rebranding-japan" ||
                applicationTheme === "jubbonti" ||
                applicationTheme === "wyost" ||
                applicationTheme === "biosimilarsinbone" ||
                applicationTheme === "vagidonna"
                  ? "var(--brand-grey-color-one)"
                  : undefined,
            },
          }}
          preventScrollOnSwipe
          swipeTreshold={60}
          activeSlideIndex={activeSlide}
          activeSlideProps={{
            style: {},
          }}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            "aria-label": "scroll right",
            children: <Icon iconName="ChevronRightIcon4" />,
            style: {
              width: 26,
              height: 24,
              minWidth: 24,
              marginLeft: 9,
              paddingLeft: 0,
              alignSelf: "center",
            },
            show: activeSlide <= links.length - itemsPerSlide,
          }}
          backwardBtnProps={{
            "aria-label": "scroll left",
            children: <Icon iconName="ChevronLeftIcon1" />,
            style: {
              width: 24,
              height: 24,
              minWidth: 24,
              marginRight: 16,
              alignSelf: "center",
            },
            show: activeSlide > 0,
          }}
          itemsToShow={4}
          speed={400}
          infinite={false}
          disableNavIfEdgeVisible={false}
          disableNavIfEdgeActive={false}>
          {updatedLinks?.map((item: any, index: number) => (
            <h4
              className={`rotating-tab-item ${item.selected ? "tab-active bold" : ""}`}
              key={`rt-${index}`}
              onClick={() => handleOnClick(item?.id)}>
              {item.name}
            </h4>
          ))}
        </Carousel>
      </div>
    </div>
  )
}
export default RotatingTab
