import { IArticleTeaserContentProps } from "../../molecules/articleTeaserContent/_articleTeaserContent.interface"

export interface IArticleTeaserProps {
  articleTeaserContent: IArticleTeaserContentProps
  articleTeaserImage: any
  buttonLink: string
  imagePosition: EArticleTeaserImagePosition
  compositionType?: EArticleTeaserCompositionType
  containerDefault?: EArticleTeaserContainerDefault
  className?: string
  buttonIcon?: JSX.Element
}
export enum EArticleTeaserCompositionType {
  ARTICLE_TEASER = "article_teaser_composition",
  CONTACT_TEASER = "contact_teaser_composition",
}

export enum EArticleTeaserImagePosition {
  IMAGE_RIGHT = "image_right",
  IMAGE_LEFT = "image_left",
}

export enum EArticleTeaserContainerDefault {
  CONTAINER_NARROW = "narrow",
  CONTAINER_FLUID = "fluid",
  CONTAINER_NORMAL = "default",
}
