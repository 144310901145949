import { useCallback, useContext, useEffect, useState } from "react"
import { useRouter } from "next/router"
import { Button, Icon, Input } from "@atoms"
import NUMBERS from "@helpers/constants/numbers"
import { getApi } from "@utils/baseApi"
import debounce from "lodash.debounce"
import { dataLayerPush } from "@utils/gtmutils"
import { EVENTS } from "@utils/gtmEvents"
import Loader from "@atoms/loader"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IAutoSearchBarProps } from "./_autoSearchBar.interface"

const AutoSearchBar = (props: IAutoSearchBarProps) => {
  const [resultData, setResultData] = useState<any>({})
  const applicationConfigContext = useContext(ApplicationContext)

  useEffect(() => {
    const renderNoResultText = () => {
      const noResultData = applicationConfigContext.applicationConfiguration.siteConfig
      setResultData({
        auto_no_result_text: noResultData?.search_no_results_text_autosuggest ?? null,
        search_title: noResultData?.search_title ?? null,
        search_help_text: noResultData?.search_help_text ?? null,
      })
    }
    renderNoResultText()
  }, [applicationConfigContext.applicationConfiguration.siteConfig])
  const { className, isDisabled, handleTextSearch } = props
  const [textInput, setTextInput] = useState("")
  const [loading, setLoading] = useState<boolean>(true)
  const [displaySuggestions, setDisplaySuggestions] = useState(false)
  const [suggestionList, setSuggestionList] = useState<Array<string>>([])

  const route = useRouter()

  const MIN_SEARCH_TEXT_LENGTH = NUMBERS.ONE
  const MAX_SEARCH_RESULTS = NUMBERS.FIVE

  const getSearchSuggestions = async (value: string) => {
    if (value.length > MIN_SEARCH_TEXT_LENGTH) {
      const searchSuggestions = await getApi(
        `solarapi/search/autosuggest/search_autocomplete?display=suggester&filter=search&q=${value}`,
      )
      setSuggestionList(
        searchSuggestions?.length > MAX_SEARCH_RESULTS
          ? searchSuggestions.slice(NUMBERS.ZERO, MAX_SEARCH_RESULTS)
          : searchSuggestions,
      )
    } else {
      setSuggestionList([])
    }
    setLoading(false)
  }

  const debounceSearch = useCallback(
    debounce((searchText: string) => getSearchSuggestions(searchText), 500),
    [],
  )

  useEffect(() => {
    if (textInput) {
      setLoading(true)
      debounceSearch(textInput)
    }
  }, [textInput])

  const getHighlightedText = (text: string) => {
    const resultTexts = text.split(new RegExp(`(${textInput})`, "gi"))

    return (
      <span>
        {resultTexts.map((part: string, index: number) =>
          part.toLowerCase() === textInput.toLowerCase() ? part : <b key={index}>{part}</b>,
        )}
      </span>
    )
  }

  const formatText = (text: string) => {
    if (textInput !== "" && text) {
      return getHighlightedText(text)
    }
    return text
  }

  const closeList = () => {
    setDisplaySuggestions(false)
  }

  const handleSearch = (e: any, text: string) => {
    setDisplaySuggestions(false)
    if (text !== textInput) {
      setTextInput(text)
    }
    handleTextSearch(text || textInput, e)
    const trackSearchEvent = {
      search_term: textInput,
    }
    dataLayerPush(EVENTS.SEARCH, trackSearchEvent)

    route.push({
      pathname: "/search",
      query: { search: text },
    })
  }
  const handleFocus = () => {
    setDisplaySuggestions(true)
  }
  return (
    <div className={`auto-search-group-no-padding ${className}`}>
      <form className="auto-search-group" onSubmit={(e) => handleSearch(e, textInput)}>
        <div className="search-content-bar">
          <Input
            className={`search-group-input-align search-box ${
              textInput === "" ? "search-group-input-align search-box-searchicon" : ""
            }`}
            value={textInput}
            useStateValue={false}
            type="search"
            placeholder={resultData.search_help_text}
            setTextInput={setTextInput}
            onFocus={handleFocus}
            onChange={handleFocus}
          />
          {displaySuggestions && textInput.length > MIN_SEARCH_TEXT_LENGTH && (
            <>
              {" "}
              <div className="search-output" id="suggestion-list">
                <ul>
                  {loading && (
                    <li>
                      <Loader display={loading} />
                    </li>
                  )}
                  {!loading &&
                    (suggestionList?.length > 0 ? (
                      <>
                        {suggestionList.map((item, key) => (
                          <li className="search-output-item" key={`${key + item}`}>
                            <button onClick={(e) => handleSearch(e, item)}>
                              {formatText(item)}
                            </button>
                          </li>
                        ))}
                      </>
                    ) : (
                      <li>{resultData.auto_no_result_text}</li>
                    ))}
                </ul>
              </div>
              <div className="blanket" onClick={closeList} />
            </>
          )}
        </div>
        <div className="search-group-space-between" />
        <Button
          className="search-group-button-align"
          tabindex={1}
          type="submit"
          isDisabled={isDisabled ? textInput?.trim().length < NUMBERS.THREE : false}
          icon={
            props?.showIconOnCTA ? (
              <Icon iconName="Search1" color="currentColor" className="search-group-button-icon" />
            ) : (
              false
            )
          }
          iconPosition="right">
          {resultData.search_title}
        </Button>
      </form>
    </div>
  )
}

export default AutoSearchBar
