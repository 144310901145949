import { Button } from "@atoms"
import { getAssetPrefixUrl, isAzureFormDependent } from "@utils/helper"
import { AES, enc } from "crypto-js"
import { useEffect, useState } from "react"
import { ISheildProps } from "./_shield.interface"
import styles from "./_shield.module.scss"

const AppShield = ({ children, isServer, isDisabled }: ISheildProps) => {
  const salt = process.env.USER_COOKIE_SALT as string
  const storageShiledPassword = (!isServer) && localStorage?.getItem("c")?.toString() || ""

  const [enteredPassword, setEnteredPassword] = useState(
    storageShiledPassword ? AES.decrypt(storageShiledPassword, salt).toString(enc.Utf8) : null
  )


  const [isValid, checkPassword] = useState(false)
  const [isClicked, setClicked] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const checkCredential = async () => {
    setIsLoading(true)
    const { location } = window
    const isLocalhost = location.hostname.includes("localhost") ? true : false
    const url = getAssetPrefixUrl("/api/shield/", isLocalhost)

    const res = await fetch(url, {
      method: "POST",
      body: JSON.stringify({ password: enteredPassword })
    })
    const result = await res.json()

    checkPassword(result?.result)

    const encryptedShiledPassword = AES.encrypt(enteredPassword || "", salt).toString()
    if (result?.result) localStorage.setItem("c", encryptedShiledPassword)

    setIsLoading(false)
  }

  const clickHandler = () => {
    checkCredential()
    setClicked(true)
  }

  useEffect(() => {
    if ((!isServer &&
      !isDisabled) || !isAzureFormDependent(window.location.href)) {
      checkCredential()
    }
  }, [])

  if (isLoading) return <></>

  if (!isClicked && !isValid && !isDisabled) {
    return (
      <div className={styles.shield}>
        <label>Please enter a password to continue</label>
        <input
          id="shield_password"
          type="password"
          placeholder="Password"
          onChange={(e) => setEnteredPassword(e.target.value.trim())}
        />
        <Button className={styles.actionBtn} tabindex={1} onClick={clickHandler}>
          Submit
        </Button>
      </div>
    )
  }

  if (isClicked && !isValid && !isDisabled) {
    return <div className={styles.unauthorized}>Sorry, You are unauthorized!!</div>
  }

  return <div>{children}</div>
}

export default AppShield
