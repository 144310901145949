import * as actionTypes from "../ActionTypes"

export const addRatings = (ratings: any) => {
    const action: RatingsAction = {
        type: actionTypes.ADD_RATINGS,
        ratings
    }
    return performRequest(action)
}

export const resetRatings = (ratings: any) => {
    const action: RatingsAction = {
        type: actionTypes.RESET_RATINGS,
        ratings,
    }

    return performRequest(action)
}

export const getRatingsCount = (liveCount: any) => {
    const action: RatingsAction = {
        type: actionTypes.RATINGS_COUNT,
        liveCount,
    }

    return performRequest(action)
}

const performRequest = (action: RatingsAction) => {
    return (dispatch: RatingsDispatchType) => {
        dispatch(action)
    }
}
