import LanguageOptions from "@atoms/languageOptions"
import { middlewarePostAPI } from "@utils/baseApi"
import { config } from "@utils/baseApi/config.external"
import { getLanguageByCountry } from "@utils/helper"
import { useCallback, useState } from "react"

const useLanguageSwitcher = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [tabOptions, setTabOptions] = useState<Array<any>>([])
    const [tabHeadItems, setTabHeadItems] = useState<Array<any>>([])
    const [tabContents, settabContents] = useState<Array<any>>([])

    const fetchLanguageDataSource = useCallback(async () => {
        setLoading(true)
        const cmsResponse: any = await middlewarePostAPI(config.onPrem.LANGUAGE_SWITCHER_URL, { data: "abc" }, true)
        let cmsData = cmsResponse?.response?.language_data
        cmsData = Array.isArray(cmsData) ? cmsData[0] : cmsData
        setLoading(false)
        if (cmsData) {
            const tOpt = [] // set tab options (mobile dropdown)
            const tHeaderItem = []
            const tContent = []
            let index = 0
            for (const key in cmsData) {
                // @ts-ignore
                const element = cmsData[key]
                tOpt.push({
                    id: index++,
                    value: element?.name,
                    label: element?.name
                })
                tHeaderItem.push({
                    children: element?.name,
                    itemID: `pills-${element?.name}`,
                    active: index === 0 ? true : false,
                    tabDesign: "pills", // 'pills' or 'navs'
                })

                // iterating to get countries and languages
                const countryWiseLanguages: Array<any> = getLanguageByCountry(element)
                const childTabComponents = countryWiseLanguages?.map((c: any) => {
                    return (
                        <>
                            <LanguageOptions 
                                country={c?.country}
                                langs={
                                    c?.languages && c?.languages?.length ?
                                    (
                                     c?.languages?.map((l: any) => (
                                        <>
                                            <li className="language-item">
                                                <a href={l?.url} className="lang-item">{l?.language}</a>
                                            </li>
                                        </>
                                     ))   
                                    ) :
                                    (
                                        <>
                                            <li className="language-item">
                                                <a href={c?.url} className="lang-item">{c?.language}</a>
                                            </li>
                                        </>
                                    )
                                }
                            />
                        </>
                    )
                })
                tContent.push(childTabComponents)
            }

            setTabOptions(tOpt)
            setTabHeadItems(tHeaderItem)
            settabContents(tContent)
        }
    }, [])

    return {
        languageLoading: loading,
        fetchLanguageDataSource,
        tabOptions,
        tabHeadItems,
        tabContents,
    }
}

export default useLanguageSwitcher