const getFormattedISIData = (bannerData: any) => {
    if(!bannerData) {
      return [] as any[]
    }
    const formattedISIArray = [] as any[]
    bannerData?.isi_details?.map((item:any)=>{
      const arrayObject = {
        border: item?.border?.[0]?.value === "1"? true : false,
        description: {
          content: item?.isi_desc?.[0]?.value ?? "",
          summary: item?.isi_desc?.[0]?.summary ?? "",
          format: item?.isi_desc?.[0]?.format ?? "",
        },
        title: {content: item?.isi_title?.[0]?.value ?? ""},
        weight: item?.isi_weight?.[0]?.value ?? null,
        type: item?.isi_type?.[0]?.value ?? "",
        megaTitle: item?.mega_title?.[0]?.value ?? "",
        isAccordian: false,
    }
      formattedISIArray.push(arrayObject)
    })
    const accordion_details = bannerData?.accordion_details
  
    if (accordion_details && accordion_details?.accordion_title?.length && accordion_details?.accordion_description?.length)
      {formattedISIArray.push({
        border: accordion_details?.accordion_border?.[0]?.value === "1",
        description: {
          content: accordion_details?.accordion_description?.[0]?.value ?? "",
          summary: accordion_details?.accordion_description?.[0]?.summary ?? "",
          format: accordion_details?.accordion_description?.[0]?.format ?? "",
        },
        title: {content: accordion_details?.accordion_title?.[0]?.value ?? ""},
        weight: accordion_details?.accordion_weight?.[0]?.value ?? null,
        isAccordian: true
      })}
  
    formattedISIArray.sort((a, b) => {
      return a?.weight - b?.weight
    })

    return {
      bannerData: formattedISIArray ?? [],
      title: bannerData?.isi_banner_header_title?.[0]?.value ?? "",
      nodeTitle: bannerData?.node_title?.[0]?.value ?? "",
      expandCoverage: parseInt(bannerData?.complete_expand_percentage?.[0]?.value ?? "100", 10) ?? 100,
      preExpandCoverage: parseInt(bannerData?.pre_expand_percentage?.[0]?.value ?? "25", 10) ?? 25,
      nodeRibbionColor: bannerData?.node_ribbon_colour?.[0]?.value ?? "default"
    }
  }

  export default getFormattedISIData;