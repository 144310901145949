import { getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"
import { filterURL, getBlockData } from "."

const getCommonSearch = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`Common search Api URL parameter is undefined in ${getCommonSearch.name}.`)
  }

  const serverData = args?.[3] // accepting serverData only
  const searchRawData = await getBlockData(`${url}`, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(searchRawData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(searchRawData)
  const slide = searchRawData?.data ?? {}
  const field_seach_input = isPaywallProtected(
    getNestedObject(slide, "attributes.field_seach_input"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.field_seach_input")
  const field_search_cta_link = filterURL(
    getNestedObject(slide, "attributes.field_search_cta_link.uri"),
  )
  const field_search_cta_text = isPaywallProtected(
    getNestedObject(slide, "attributes.field_search_cta_text"),
  ).isPaywallProtectedFlag
    ? null
    : getNestedObject(slide, "attributes.field_search_cta_text")

  return {
    field_seach_input,
    field_search_cta_link,
    field_search_cta_text,
    fieldPaywallRoles,
    isParentPaywalled
  }
}

export default getCommonSearch
