import React, { useContext, useEffect, useRef, useState } from "react"
import { MultiSelect } from "react-multi-select-component"
import { useMediaQuery } from "@helpers/hooks"
import { ICustomItemRendererProps, IDropDownProps, IOption, IOptions } from "./_dropDown.interface"
import ApplicationContext from "@utils/application-context/applicationContext"

const CustomItemRenderer = ({ checked, option, onClick, disabled }: ICustomItemRendererProps) => (
  <div className={`item-renderer ${disabled && "disabled"}`}>
    <label className="container">
      <input
        type="checkbox"
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span className="checkmark" />
    </label>
    <span>{option.label}</span>
  </div>
)

const CartDropDown = ({
  options,
  isMulti,
  isCleared,
  simpleDropDownLabel,
  placeHolder,
  onValueChange,
  isDisabled,
  intialValue = [],
  showPlaceholder,
  ariaLabel,
  shouldToggleOnHover = true,
  showValueWithPlaceholder = false,
  ...rest
}: IDropDownProps) => {
  const [selOption, setSelOption] = useState<IOptions>(intialValue)
  const { applicationConfiguration } = useContext(ApplicationContext)
  const selectPlaceholder =
    applicationConfiguration?.siteConfig?.webstore_list_filters_placeholder_text

  const isFirstRender = useRef(true)
  const isMobile = useMediaQuery("(max-width: 991px)")
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else if (!isMobile) {
      setSelOption(intialValue)
    }
    if (isMobile) {
      setSelOption(intialValue)
    }
  }, [intialValue, selOption])

  const changeSelected = (value: any) => {
    if (isMulti) {
      setSelOption(value)
      onValueChange?.(value)
    } else {
      const previoslySelected = [...selOption]
      const newSelected =
        previoslySelected.length > 0
          ? value.filter((item: IOption) => item.value !== previoslySelected[0].value)
          : [...value]

      setSelOption(newSelected)
      onValueChange?.(newSelected)
    }
  }

  const placeholderValueForSingleItemSelcted = (label: string) => {
    if (simpleDropDownLabel) {
      if (isMulti) {
        return `${placeHolder} (1)`
      }
      if (showValueWithPlaceholder) {
        return `${placeHolder}.  ${label}`
      }
      return label
    }
    if (showValueWithPlaceholder) {
      return `${placeHolder}.  ${label}`
    }

    return "Option (1)"
  }

  const customValueRenderer = (selectedOptions: any[], _options: any[]) => {
    switch (selectedOptions.length) {
      case 0:
        return simpleDropDownLabel || showPlaceholder ? (
          <p>{placeHolder}</p>
        ) : (
          <p>{selectPlaceholder}</p>
        )
      case 1:
        return placeholderValueForSingleItemSelcted(selectedOptions[0]?.label)
      case _options.length:
        return "All"
      default:
        return simpleDropDownLabel
          ? `${placeHolder} (${selectedOptions.length})`
          : `Options (${selectedOptions.length})`
    }
  }


  useEffect(() => {
    setSelOption([])
  }, [isCleared])

  return (
    <>
      <span
        id={ariaLabel ?? "input_select"}
        aria-hidden="true"
        className="item-renderer-hidden-label">
        <p>{simpleDropDownLabel || showPlaceholder ? placeHolder : selectPlaceholder}</p>
      </span>
      <MultiSelect
        options={options}
        value={selOption}
        onChange={changeSelected}
        labelledBy={ariaLabel ?? "input_select"}
        ItemRenderer={CustomItemRenderer}
        hasSelectAll={isMulti}
        disableSearch={true}
        shouldToggleOnHover={shouldToggleOnHover}
        overrideStrings={{ selectAll: "All" }}
        valueRenderer={customValueRenderer}
        disabled={isDisabled}
        closeOnChangedValue={!isMulti}
        {...rest}
      />
    </>
  )
}

export default CartDropDown
