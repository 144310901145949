import {Img, RichText, AnchorButton, Row, Column} from "@atoms"
import {ButtonGroup} from "@molecules"
import { IFormStateProps } from "./_formState.interface"


const FormState = ({ imgsrc, cta, ctaSecondary, header, paragraph}: IFormStateProps | any) => {
  const imgData = imgsrc
  const ctalabel = cta
  return (
    <Row className="form-state">
      <Column mobile={2} tablet={1} desktop={1} className="form-error-img">
        {imgData?.src && (
          <Img
            className={imgData?.className}
            src={imgData?.src}
            alt={imgData?.alt}
            height={"48"}
            width={"48"}
            type="icon"
          />
        )}
      </Column>
      <Column mobile={12} tablet={12} desktop={12} className="form-state-txt">
        <div className="form-state-txt-wrapper">
          {header && <h2>{header}</h2>} 
          <RichText className="form-state-desc" content={paragraph} />
          <ButtonGroup className="btn-alignment">
           {ctaSecondary && (<AnchorButton className="form-state-cta" href={ctaSecondary.href} tabindex={ctaSecondary.tabindex} isExternal={false} isSecondary={true}>
              {ctaSecondary.children}
            </AnchorButton>)}
            {ctalabel && (<AnchorButton className="form-state-cta" href={ctalabel.href} tabindex={ctalabel.tabindex} isExternal={false}>
            {ctalabel.children}
            </AnchorButton>)}
           </ButtonGroup>
        </div>
      </Column>
    </Row>
  )
}

export default FormState
