import { getApi } from "@utils/baseApi"
import { consoleError } from "@utils/error"
import getNestedObject from "@utils/nestedObjects"

export const getFormattedOptions = (objectList: Object) => {
  return Object?.entries(objectList).map(([key, value]) => ({
      id: key,
      value: value,
      label: value ?? "",
  }))
}

export const getInterfaceFilterLabelData = async (data: any) => {
  if (data) {
    const CategoryLabelData = {
      searchLabel: getNestedObject(data?.filterConfig, "search_box_title"),
      searchPlaceholder: getNestedObject(data?.filterConfig, "search_box_placeholder"),
      channelLabel: getNestedObject(data?.filterLabels, "channel"),
      channelPlaceholderText: getNestedObject(data?.filterConfig, "select_option_text"),
      attributesLabel: getNestedObject(data?.filterLabels, "attributes"),
      attributesPlaceholderText: getNestedObject(data?.filterConfig, "select_option_text"),
      searchCTALabel: getNestedObject(data?.filterConfig, "search_button_label"),
      sentEmailLabel: getNestedObject(data?.filterLabels, "sent_email"),
      startDateLabel: getNestedObject(data?.filterLabels, "start_date"),
      endDateLabel: getNestedObject(data?.filterLabels, "end_date"),
      clearLabel: getNestedObject(data?.filterConfig, "clear_all_filters"),
      countryLabel: getNestedObject(data?.filterLabels, "country"),
      tableColumns: [
        `${getNestedObject(data?.filterLabels, "record_number")}`,
        `${getNestedObject(data?.filterLabels, "channel")}`,
        `${getNestedObject(data?.filterLabels, "country")}`,
        `${getNestedObject(data?.filterLabels, "attributes")}`,
        `${getNestedObject(data?.filterLabels, "sent_email")}`,
        `${getNestedObject(data?.filterLabels, "created")}`,
      ],
      resultsLabel: getNestedObject(data?.filterConfig, "showing_results"),
      noResultsLabel: getNestedObject(data?.filterConfig, "no_results_found"),
      dropdownPlaceHolder: getNestedObject(data?.filterConfig, "select_option_text"),
    }
    return CategoryLabelData
  }

  return {
    searchLabel: "",
    searchPlaceholder: "",
    channelLabel: "",
    channelPlaceholderText: "",
    attributesLabel: "",
    attributesPlaceholderText: "",
    searchCTALabel: "",
    sentEmailLabel: "",
    startDateLabel: "",
    endDateLabel: "",
    clearLabel: "",
    tableColumns: [],
    resultsLabel: "",
    noResultsLabel: "",
    paginationCount: 0,
    dropdownPlaceHolder: "",
    countryLabel: "",
  }
}
export const getInterfaceTwoFilterLabelData = async (data: any) => {
  if (data) {
    const CategoryLabelData = {
      searchLabel: getNestedObject(data?.filterConfig, "search_box_title"),
      searchPlaceholder: getNestedObject(data?.filterConfig, "search_box_placeholder"),
      channelLabel: getNestedObject(data?.filterLabels, "channel"),
      channelPlaceholderText: getNestedObject(data?.filterConfig, "select_option_text"),
      searchCTALabel: getNestedObject(data?.filterConfig, "search_button_label"),
      sentEmailLabel: getNestedObject(data?.filterLabels, "sent_email"),
      startDateLabel: getNestedObject(data?.filterLabels, "start_date"),
      endDateLabel: getNestedObject(data?.filterLabels, "end_date"),
      clearLabel: getNestedObject(data?.filterConfig, "clear_all_filters"),
      countryLabel: getNestedObject(data?.filterLabels, "country"),
      tableColumns: [
        `${getNestedObject(data?.filterLabels, "record_number")}`,
        `${getNestedObject(data?.filterLabels, "channel")}`,
        `${getNestedObject(data?.filterLabels, "country")}`,
        `${getNestedObject(data?.filterLabels, "sent_email")}`,
        `${getNestedObject(data?.filterLabels, "created")}`,
      ],
      resultsLabel: getNestedObject(data?.filterConfig, "showing_results"),
      noResultsLabel: getNestedObject(data?.filterConfig, "no_results_found"),
      dropdownPlaceHolder: getNestedObject(data?.filterConfig, "select_option_text"),

    }
    return CategoryLabelData
  }

  return {
    searchLabel: "",
    searchPlaceholder: "",
    channelLabel: "",
    channelPlaceholderText: "",
    searchCTALabel: "",
    sentEmailLabel: "",
    startDateLabel: "",
    endDateLabel: "",
    clearLabel: "",
    tableColumns: [],
    resultsLabel: "",
    noResultsLabel: "",
    paginationCount: 0,
    dropdownPlaceHolder: "",
    countryLabel: "",
  }
}

export const fetchTableData = (response: any, labelData: any) => {
  const tableData = response?.map((item: any) => {
  return {
    recordNumber: {
      title: "",
      desc: item?.attributes?.serial ?? "",
      type: "text",
      link: "",
    },
    channel: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.channel ?? "",
      type: "text",
    },
    country: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.country ?? "",
      type: "text",
    },
    attributes: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.attributes ?? "",
      type: "text",
    },
    sentToEmailAddress: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.email ?? "",
      type: "text",
    },
    dateOfSubmission: {
      title: "", 
      link: "",
      type: "date",
      desc: item?.attributes?.created ?? "",
      isNotCareerSearch: true,
    }
  }
}) ?? {}
  return tableData

}

export const fetchTableDataInterfaceTwo = (response: any, labelData: any) => {
  const tableData = response?.map((item: any) => {
  return {
    recordNumber: {
      title: "",
      desc: item?.attributes?.serial ?? "",
      type: "text",
      link: "",
    },
    channel: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.soi_2_channel ?? "",
      type: "text",
    },
    country: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.soi_2_country ?? "",
      type: "text",
    },
    sentToEmailAddress: {
      title: "",
      link: "",
      desc: item?.attributes?.submission_data?.soi_2_email ?? "",
      type: "text",
    },
    dateOfSubmission: {
      title: "", 
      link: "",
      type: "date",
      desc: item?.attributes?.created ?? "",
      isNotCareerSearch: true,
    }
  }
}) ?? {}
  return tableData

}
export const sortDataAsc = (data: any) =>
  data.sort((a: any, b: any) => a.label.localeCompare(b.label))

export const getTableResponse = async (url: any) => {
  if (!url) {
    throw consoleError(`Url parameter is undefined in ${getTableResponse.name}.`)
  }

  const response = await getApi(url, false)

  return response
}