import { Column, Img, RichText, Row } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { ITeaserSnackBiteProps } from "./_teaserSnackBite.interface"

const TeaserSnackBite = (props: ITeaserSnackBiteProps) => {
  const { styledimage, imageSrc, imageAlt, imgCaption, title, description, cardAlignment } = props
  return (
    <Row className={"teaser-snackbite " + cardAlignment}>
      <Column desktop={12} tablet={12} mobile={12} className="teaser-snackbite-img">
        <Img type="teaser_block_carousel" styledimage={styledimage} src={imageSrc} alt={imageAlt} caption={imgCaption} width={768} height={432} />
      </Column>
      <Column desktop={12} tablet={12} mobile={12} className="teaser-snackbite-info">
        {title && (
          <h3 className="teaser-snackbite-title">{title}</h3>
        )}
        <RichText content={description} characterLimit={CHARLIMIT.DESCRIPTION} className="teaser-snackbite-desc" />
      </Column>
    </Row>
  )
}

export default TeaserSnackBite
