import { ExternalLinkModal } from "@organisms"
import { useEffect, useState } from "react"
import { URL_TARGET } from "@helpers/constants/generic"
import { getApiDomainAndLang } from "./baseApi"

const ExternalModal = (props: any) => {
  const [isExternalLink, setIsExternalLink] = useState(false)
  const [externalLink, setExternalLink] = useState("")

  const [isInternalLink, setIsInternalLink] = useState(false)
  const [internalLink, setInternalLink] = useState("")
  const [popupData, setPopupData] = useState<any>(null)
  const [targetWindow, setTargetWindow] = useState("")

  const { whiteListed } = props
  const { host } = window.location

  let isWhiteListed = false
  const whiteListedLinks = String(whiteListed)?.split(/\r?\n/) ?? []
  const currentDomain = `${host}`
  whiteListedLinks?.push(currentDomain)

  const externalModalEnabled = props?.externalModelEnabled
  const internalModelEnabled = props?.internalModelEnabled

  const getHostnameFromUrl = (url: string) => {
    // @ts-ignore
    const domain = (url && url?.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n]+)/im)[1]) || ""
    return domain
  }

  const checkIfLinkExists = (clickedLink: string) => {
    const { feDomain } = getApiDomainAndLang()
    const filterMatchedLink = props?.internalModalData?.links?.find((link: any) => {
      return !link.url?.startsWith("http")
        ? window.location.protocol + "//" + feDomain + link.url === clickedLink
        : link.url === clickedLink
    })

    if (filterMatchedLink) {
      setPopupData(filterMatchedLink?.popup_data)
      return true
    }
    return false
  }

  const handleAnchorClick = (event: any) => {
    let targetElement = event.target
    if (targetElement.tagName !== "A") {
      targetElement = findParentByTagName(targetElement, "A")
    }
    setTargetWindow(targetElement?.target)
    if (targetElement && targetElement.href) {
      const hostname = getHostnameFromUrl(targetElement.href)
      isWhiteListed = whiteListedLinks.some((val: any) => getHostnameFromUrl(val) === hostname)
      const isExternal =
        targetElement.href &&
        targetElement.getAttribute("href").startsWith("http") &&
        !targetElement.href.includes("mailto") &&
        !targetElement.href.includes("tel") &&
        !(targetElement.getAttribute("download") === "download") &&
        !isWhiteListed &&
        externalModalEnabled

      const isInternal =
        internalModelEnabled &&
        targetElement.href &&
        checkIfLinkExists(targetElement.href.split(/[?#]/)[0])

      if (externalModalEnabled || internalModelEnabled) {
        if (isInternal) {
          event.preventDefault()
          setInternalLink(targetElement.href)
          setIsInternalLink(true)
          setIsExternalLink(false)
        } else if (isExternal) {
          event.preventDefault()
          setExternalLink(targetElement.href)
          setIsExternalLink(true)
          setIsInternalLink(false)
          setInternalLink("")
        } else if (isWhiteListed) {
          setIsExternalLink(false)
          setIsInternalLink(false)
          setInternalLink("")
        } else {
          setIsExternalLink(false)
          setIsInternalLink(false)
          setInternalLink("")
        }
      }
    }
  }

  const findParentByTagName = (element: any, tagName: string) => {
    let parent = element
    while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
      parent = parent.parentNode
    }
    return parent
  }

  const handleClose = () => {
    setIsExternalLink(false)
    setExternalLink("")
    setIsInternalLink(false)
    setInternalLink("")
  }

  const handleAction = () => {
    if (externalLink) {
      window.open(externalLink, URL_TARGET.BLANK, "noopener,noreferrer")
    } else if (internalLink) {
      window.open(internalLink, targetWindow === "_blank" ? URL_TARGET.BLANK : URL_TARGET.SELF, "noopener,noreferrer")
    }
    handleClose()
  }

  useEffect(() => {
    document.addEventListener("click", handleAnchorClick)
  })

  if (isExternalLink) {
    return <ExternalLinkModal {...props} onClose={handleClose} onAction={handleAction} />
  } else if (isInternalLink) {
    return <ExternalLinkModal {...popupData} onClose={handleClose} onAction={handleAction} />
  }

  return <></>
}

export default ExternalModal
