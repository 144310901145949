import React, { useEffect, useRef, useState } from "react"
import { IUserQuizProps } from "./_userQuiz.interface"
import RadioButton from "@atoms/radioButton"
import { AnchorButton, Button, CheckBox, Container, Row } from "@atoms"
import { ButtonGroup } from "@molecules"
import useSWRRequest from "@helpers/hooks/useSWRRequest"
import getUserQuizData from "@helpers/dataFunctions/getUserQuizData"
import Loader from "@atoms/loader"
import { getAllFleixbleBlocksData } from "@helpers/flexibleApi"
import Flexible from "@templates/flexible"
import NUMBERS from "@helpers/constants/numbers"

const UserQuizContentBlocks = ({ cardContent, children }: any) => {
  /** calling swr here to get flexible child component data of single tab */
  const { data: data, isLoading } = useSWRRequest(cardContent, async () => {
    try {
      const response = await getAllFleixbleBlocksData(cardContent)
      return { blocks: response }
    } catch (error) {
      console.error("Intro content error", error)
    }
  })

  return (
    <>
      {isLoading ? (
        <div className="load-container">
          <Loader display={true} />
        </div>
      ) : (
        <>
          <Flexible {...data} isPaywall3Hidden={true} />
          {children}
        </>
      )}
    </>
  )
}

const UserQuiz = (props: IUserQuizProps) => {
  const { url } = props

  const { data, isLoading } = useSWRRequest(url, async () => {
    try {
      const res = await getUserQuizData(url)
      return res
    } catch (error) {
      console.error("Error in fetching user quiz data")
    }
  })

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1)
  const [showResult, setShowResult] = useState<boolean>(false)
  const [selectedOptions, setSelectedOptions] = useState<any[]>([])
  const [submittedOptions, setSubmittedOptions] = useState<boolean[]>([])
  const [score, setScore] = useState<number>(0)

  const totalQuestions = data?.questions?.length
  const titleRef = useRef<any>(null)
  useEffect(() => {
    if (data?.questions) {
      setSelectedOptions(Array(data.questions.length).fill([]))
      setSubmittedOptions(Array(data.questions.length).fill(false))
    }
  }, [data?.questions])

  const handleStartQuiz = () => {
    setCurrentQuestionIndex(0)
    handleScroll()
  }
  const handleScroll = () => {
    setTimeout(() => {
      titleRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      })
    }, NUMBERS.FIVE * NUMBERS.HUNDRED)
  }

  const handleNextQuestion = () => {
    if (submittedOptions[currentQuestionIndex]) {
      if (currentQuestionIndex < data?.questions?.length - 1) {
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
      } else {
        setShowResult(true)
      }
    }
    handleScroll()
  }

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prevIndex) => prevIndex - 1)
    }
    handleScroll()
  }

  const handleOptionSelect = (optionIndex: number) => {
    if (!submittedOptions[currentQuestionIndex]) {
      const newSelectedOptions = [...selectedOptions]
      if (data?.questions[currentQuestionIndex]?.answer_type === "multiple") {
        newSelectedOptions[currentQuestionIndex] = newSelectedOptions[
          currentQuestionIndex
        ].includes(optionIndex)
          ? newSelectedOptions[currentQuestionIndex].filter(
              (index: number) => index !== optionIndex,
            )
          : [...newSelectedOptions[currentQuestionIndex], optionIndex]
      } else {
        newSelectedOptions[currentQuestionIndex] = [optionIndex]
      }
      setSelectedOptions(newSelectedOptions)
    }
  }

  const handleSubmitAnswer = () => {
    if (!submittedOptions[currentQuestionIndex]) {
      const newSubmittedOptions = [...submittedOptions]
      newSubmittedOptions[currentQuestionIndex] = true
      setSubmittedOptions(newSubmittedOptions)

      const correctAnswers = data?.questions[currentQuestionIndex]?.correctAnswerIndex
      const userAnswers = selectedOptions[currentQuestionIndex]

      if (
        correctAnswers.every((val: number) => userAnswers.includes(val)) &&
        correctAnswers.length === userAnswers.length
      ) {
        setScore((prevScore) => prevScore + 1)
      }
    }
  }

  const handleRestartQuiz = () => {
    handleScroll()
    setCurrentQuestionIndex(0)
    setSelectedOptions(Array(data?.questions?.length).fill([]))
    setSubmittedOptions(Array(data?.questions?.length).fill(false))
    setShowResult(false)
    setScore(0)
  }

  const UserQuizAnswerContentBlocks = ({ content }: any) => {
    const answerType = data?.questions[currentQuestionIndex]?.answer_type
    const answer = content.map((option: any, index: number) => {
      const isCorrect = data?.questions[currentQuestionIndex]?.correctAnswerIndex.includes(index)
      const isSelected = selectedOptions[currentQuestionIndex].includes(index)
      const isSubmitted = submittedOptions[currentQuestionIndex]
      if (answerType === "multiple") {
        return (
          <div
            className={`user-quiz-checkBox ${!isSelected && isSubmitted ? "submitted" : ""}`}
            key={index}>
            <CheckBox
              className={
                isSubmitted
                  ? isCorrect
                    ? "user-quiz-correct"
                    : isSelected
                    ? "user-quiz-incorrect"
                    : ""
                  : ""
              }
              id={`${index}`}
              name={index.toString()}
              value={option}
              label={option}
              isChecked={isSelected}
              onChange={() => handleOptionSelect(index)}
            />
            {isSubmitted && (
              <span
                className={
                  isSelected
                    ? isCorrect
                      ? "user-quiz-checkbox-correct"
                      : "user-quiz-checkbox-incorrect"
                    : isCorrect
                    ? "user-quiz-checkbox-correct"
                    : "" //  If the option is correct but not selected, it still assigns the class
                }>
                {isSelected
                  ? isCorrect
                    ? data?.questions[currentQuestionIndex]?.correct_message[index]
                    : data?.questions[currentQuestionIndex]?.incorrect_message[0]
                  : isCorrect
                  ? data?.questions[currentQuestionIndex]?.correct_message[index]
                  : ""}
              </span>
            )}
          </div>
        )
      } else {
        return (
          <div
            className={`user-quiz-radioButton ${!isSelected && isSubmitted ? "submitted" : ""}`}
            key={index}>
            <RadioButton
              id={`${index}`}
              name={index.toString()}
              value={option}
              label={option}
              isChecked={isSelected}
              onChange={() => handleOptionSelect(index)}
            />
            {submittedOptions[currentQuestionIndex] && (
              <span
                className={
                  isSelected
                    ? isCorrect
                      ? "user-quiz-correct"
                      : "user-quiz-incorrect"
                    : isCorrect
                    ? "user-quiz-correct"
                    : "" //  If the option is correct but not selected, it still assigns the class
                }>
                {isSelected
                  ? isCorrect
                    ? data?.questions[currentQuestionIndex]?.correct_message[0]
                    : data?.questions[currentQuestionIndex]?.incorrect_message[0]
                  : isCorrect
                  ? data?.questions[currentQuestionIndex]?.correct_message[0]
                  : ""}
              </span>
            )}
          </div>
        )
      }
    })
    return answer
  }

  if (isLoading) {
    return <Loader display={true} />
  }

  const getResultMessage = () => {
    try {
      const scoreMsg = `${score}${data?.quiz_count_symbol}${data?.questions.length}`
      if (data?.message_type === "simple") {
        return data?.quiz_result_message[0].replace("@quiz_result", scoreMsg)
      } else if (data?.message_type === "range" || data?.message_type === "percentage") {
        let percentage = (score / totalQuestions) * NUMBERS.HUNDRED
        percentage = parseFloat(percentage.toFixed(2))
        const percentageStr = percentage % 1 === 0 ? percentage.toFixed(0) : percentage.toFixed(2)

        const resultMessage = data?.quiz_result_message.find((msg: any) => {
          const minScore = parseInt(msg.min_score)
          const maxScore = parseInt(msg.max_score)

          const isScoreType = data?.message_type === "range"
          const valueToCheck = isScoreType ? score : percentage

          const isInRange = valueToCheck >= minScore && valueToCheck <= maxScore
          return isInRange
        })

        if (resultMessage) {
          const resultValue = data?.message_type === "percentage" ? percentageStr : scoreMsg
          return resultMessage.message.replace("@quiz_result", `${resultValue}`)
        } else {
          return "No matching result message found."
        }
      }
    } catch (error) {
      console.error("Error in getResultMessage:", error)
      return "An error occurred while generating the result message."
    }
  }

  const handleIntroPageTitle = () => {
    return (
      <>
        {!isLoading && (
          <UserQuizContentBlocks
            cardContent={data?.introPageContent?.introFlexibleDescriptionBlocks}
          />
        )}
      </>
    )
  }

  return (
    <div className="user-quiz">
      <Container>
        <Row className="user-quiz-questions-wrapper">
          {!showResult ? (
            <>
              {data?.introPageContent && (
                <div className="user-quiz-intro">
                  {handleIntroPageTitle()}
                  {currentQuestionIndex === -1 && (
                    <div>
                      {!isLoading && (
                        <UserQuizContentBlocks
                          cardContent={data?.introPageContent?.introFlexibleBlocks}>
                          <div className="user-quiz-start-btn">
                            <Button onClick={handleStartQuiz} tabindex={0}>
                              {data?.introPageContent?.startQuizLabel}
                            </Button>
                          </div>
                        </UserQuizContentBlocks>
                      )}
                    </div>
                  )}
                </div>
              )}
              {currentQuestionIndex >= 0 && (
                <>
                  <h1 className="user-quiz-title bold" ref={titleRef}>
                    {data?.title?.replace(
                      "@qus_count",
                      `${currentQuestionIndex + 1}${data?.quiz_count_symbol}${totalQuestions}`,
                    )}
                  </h1>
                  <UserQuizContentBlocks
                    cardContent={data?.questions[currentQuestionIndex]?.question_flexible}>
                    <UserQuizAnswerContentBlocks
                      content={data?.questions[currentQuestionIndex]?.options}
                    />
                    <div className="user-quiz-navigation">
                      <ButtonGroup className="user-quiz-navigation-button">
                        {currentQuestionIndex > 0 ? (
                          <Button onClick={handlePreviousQuestion} tabindex={0}>
                            {data?.ctaPrevious}
                          </Button>
                        ) : (
                          <></>
                        )}
                        <Button
                          isDisabled={
                            selectedOptions[currentQuestionIndex].length === 0 ||
                            submittedOptions[currentQuestionIndex]
                          }
                          onClick={handleSubmitAnswer}
                          tabindex={0}>
                          {data?.ctaSubmit}
                        </Button>
                        <Button
                          isDisabled={!submittedOptions[currentQuestionIndex]}
                          onClick={handleNextQuestion}
                          tabindex={0}>
                          {currentQuestionIndex < data?.questions.length - 1
                            ? data?.ctaNext
                            : data?.ctaShowResult}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </UserQuizContentBlocks>
                </>
              )}
            </>
          ) : (
            <>
              {data?.introPageContent && (
                <div className="user-quiz-intro">{handleIntroPageTitle()}</div>
              )}
              <div className="user-quiz-score">
                {data?.resultPageContent && (
                  <h1 className="bold user-quiz-score" ref={titleRef}>
                    {getResultMessage()}
                  </h1>
                )}
              </div>
              <div className="user-quiz-actions">
                <ButtonGroup className="user-quiz-results-button">
                  {data?.restartQuizLabel ? (
                    <Button onClick={handleRestartQuiz} tabindex={0}>
                      {data?.restartQuizLabel}
                    </Button>
                  ) : (
                    <></>
                  )}
                  {data?.takeAnotherQuizBtnLabel ? (
                    <AnchorButton
                      href={data?.takeAnotherQuizBtnLink}
                      tabindex={0}
                      isExternal={data?.linkExternal}>
                      {data?.takeAnotherQuizBtnLabel}
                    </AnchorButton>
                  ) : (
                    <></>
                  )}
                </ButtonGroup>
              </div>
            </>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default UserQuiz
