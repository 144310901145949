import React from "react"
import { IComparisonColumnProps } from "./_comparisionColumn.interface"
import { Anchor, Icon } from "@atoms"

const ComparisonColumn = (props: IComparisonColumnProps) => {
  const renderTextValue = (title: string, i: number) => {
    if (title?.toUpperCase() === "TRUE") {
      return (
        <span>
          <Icon className="table-check-icon" iconName="CheckIcon" />
        </span>
      )
    }
    if (title?.toUpperCase() === "FALSE") {
      return (
        <span>
          <Icon iconName="CloseIcon" />
        </span>
      )
    }
    return <span key={`${title}-${i}`}>{title}</span>
  }

  const formatColumnData = (column: any) => {
    const { field_table_cell_link, field_cell_alignment = "center" } = column

    let columnData = field_table_cell_link
    columnData = columnData?.map((item: any) => {
      const obj = {} as any
      obj.title = item?.title
      obj.value = item?.uri ?? null
      obj.isLink = !item?.uri?.includes("nolink")
      return obj
    })
    return {
      columnData,
      fieldCellAlignment: field_cell_alignment?.toLowerCase(),
    }
  }

  const renderColumnValues = (data: any) => {
    const { columnData, fieldCellAlignment } = formatColumnData(data)
    return (
      <div style={{ textAlign: fieldCellAlignment }}>
        {columnData &&
          columnData?.map((item: any, index: number) =>
            item?.isLink ? (
              <span key={`${item?.title}-${index}`}>
                <Anchor href={item?.value ?? "#"} tabindex={1} noUnderline={true} isExternal={true}>
                  {item?.title}
                </Anchor>
              </span>
            ) : (
              renderTextValue(item?.title, index)
            ),
          )}
      </div>
    )
  }

  return (
    <tr>
      <td>{props?.rowtitle}</td>
      {props?.rowColumnValue &&
        props?.rowColumnValue?.map((column: any, j: any) =>
          j <= 3 ? (
            <td className="table-col" key={j}>
              {renderColumnValues(column)}
            </td>
          ) : null,
        )}
    </tr>
  )
}

export default ComparisonColumn
