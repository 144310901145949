import { Anchor, AnchorButton, Column, Icon, RichText, Row, Taxonomy } from "@atoms"
import FavoriteIcon from "@atoms/favourite"
import CHARLIMIT from "@helpers/constants/charLimit"
import EventCardScoutButton from "@molecules/eventCardScoutButton"
import ApplicationContext from "@utils/application-context/applicationContext"
import { getApiDomainAndLang } from "@utils/baseApi"
import moment from "moment"
import { useCallback, useContext, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Dispatch } from "redux"
import { addRatings } from "store/actions/RatingsActionCreators"
import { trimText } from "../../../utils/stringparsing"
import BookmarkWithButton from "../bookmarkWithButton"
import ButtonGroup from "../button-group"
import { IEventCardVerticalContentProps } from "./_eventCardVerticalContent.interface"
import { EVENT_TYPE } from "@helpers/constants/generic"

const EventCardVerticalContent = ({
  id,
  text,
  startDate,
  endDate,
  contentText,
  heading,
  taxonomies,
  bookmark,
  time,
  primaryButtonLabel,
  primaryButtonUrl,
  primaryIsExternalUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
  secondaryIsExternalUrl,
  eventType,
  scoutData,
}: IEventCardVerticalContentProps) => {
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const applicationConfigContext = useContext(ApplicationContext)
  const { applicationConfiguration } = useContext(ApplicationContext)

  const dispatch: Dispatch<any> = useDispatch()
  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const handleBookmark = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  let totalFavoriteCount = 0
  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)

  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }
  const extractDate = (date: string) => {
    const { lang } = getApiDomainAndLang()

    return moment(date).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
  }

  return (
    <div className="event-card-vertical-content">
      <Row>
        <Column desktop={9} tablet={9} mobile={9}>
          {taxonomies && <Taxonomy taxonomies={taxonomies} limitChar={16} />}
        </Column>
        <Column
          desktop={3}
          tablet={3}
          mobile={3}
          className="material-teaser-card-content-icon-container">
          {bookmark && (
            <div className="event-card-vertical-content-icon">
              <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={handleBookmark} />
            </div>
          )}
          <div className="event-card-vertical-content-icon">
            <div className="material-teaser-card-content-favorite-section">
              <span className="favorite-icon">
                {(taxonomies || bookmark || heading || text || startDate || contentText || time) &&
                  applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                    <FavoriteIcon
                      id={id}
                      totalFavoriteCount={totalFavoriteCount}
                      favouriteStatus={isFavouriteSelected}
                      favoriteHandlerCallback={favouriteHandler}
                    />
                  )}
              </span>
            </div>
          </div>
        </Column>
      </Row>
      {heading && (
        <Anchor href={primaryButtonUrl} tabindex={0} noUnderline isExternal={primaryIsExternalUrl}>
          <h4 className="event-card-vertical-content-header bold">
            {trimText(heading, CHARLIMIT.TITLE)}
          </h4>
        </Anchor>
      )}
      {text && (
        <RichText
          className="event-card-vertical-content-text event-card-vertical-content-text-h"
          content={text}
          characterLimit={CHARLIMIT.DESCRIPTION}
        />
      )}
      <div className="event-card-vertical-content-bottom">
        {startDate && (
          <div className="event-card-vertical-content-event-date">{extractDate(startDate)}</div>
        )}
        <div className="event-card-vertical-content-line-divider" />
        <div className="event-card-vertical-content-bottom-container">
          <span className="event-card-vertical-content-bottom-icon">
            <Icon iconName="mapIcon" />
          </span>
          {contentText && (
            <span className="event-card-vertical-content-bottom-icon-text">{contentText}</span>
          )}
        </div>
      </div>
      <div className="event-card-vertical-content-time-section">
        <Icon iconName="TimeIcon" />

        {time && <div className="event-card-vertical-content-time-section-time">{time}</div>}
      </div>
      <div className="event-card-vertical-content-button">
        <ButtonGroup>
          {eventType && eventType === EVENT_TYPE.SCOUT_EVENT && scoutData ? (
            <EventCardScoutButton {...scoutData} />
          ) : primaryButtonUrl && primaryButtonLabel ? (
            <AnchorButton tabindex={1} href={primaryButtonUrl} isExternal={primaryIsExternalUrl}>
              {primaryButtonLabel}
            </AnchorButton>
          ) : (
            <></>
          )}
          {(secondaryButtonUrl || secondaryIsExternalUrl) && secondaryButtonLabel ? (
            <AnchorButton
              tabindex={2}
              href={secondaryButtonUrl}
              isExternal={secondaryIsExternalUrl}
              isSecondary>
              {secondaryButtonLabel}
            </AnchorButton>
          ) : (
            <></>
          )}
        </ButtonGroup>
      </div>
    </div>
  )
}

export default EventCardVerticalContent
