import * as actionType from "../ActionTypes"

const userSession = {
  session: "",
  csrf: "",
  applicationId: "",
}

const SessionReducer = (state: any = userSession, action: SessionAction): any => {
  switch (action.type) {
    case actionType.SET_SESSION:
      return {
        ...state,
        session: action.session,
        csrf: action.csrf,
      }

    case actionType.CLEAR_SESSION:
      return {
        ...state,
        session: action.session,
        csrf: action.csrf,
      }

    case actionType.SET_APPLICATION_ID:
      return {
        ...state,
        applicationId: action.applicationId,
      }

    default:
      return state
  }
}

export default SessionReducer
