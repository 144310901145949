import { CheckBox } from "@atoms"
import React, { useState } from "react"
import { IProductData, IProductsTableProps } from "./_productsTable.interface"

const ProductsTable = ({
  product_data,
  asset_attributes_list,
  handleProductTableChange,
  productFamilyName,
  attributeData,
}: IProductsTableProps) => {
  // State to store the selected products
  const [selectedProducts, setSelectedProducts] = useState<IProductData[]>([])

  // Function to handle checkbox change
  const handleCheckBoxChange = (nid: string, attribute: string, checked: boolean) => {
    // If the attribute is empty, return early
    if (!nid || !attribute) {
      return
    }
    // Copy the current selected products
    const updatedProducts = [...selectedProducts]
    // Find the index of the product in the selected products array
    const productIndex = updatedProducts?.findIndex(
      (product) => product?.drupal_internal__nid === nid,
    )
    // If the checkbox is checked
    if (checked) {
      // If the product is not already selected, add it to the array
      if (productIndex === -1) {
        updatedProducts?.push({ drupal_internal__nid: nid, asset_attributes_val: [attribute] })
      } else {
        // If the product is already selected, add the attribute to its array of selected attributes
        updatedProducts[productIndex]?.asset_attributes_val?.push(attribute)
      }
    } else {
      // If the checkbox is unchecked
      if (productIndex !== -1) {
        // Find the index of the attribute in the product's array of selected attributes
        const attributeIndex =
          updatedProducts[productIndex]?.asset_attributes_val?.indexOf(attribute)
        // If the attribute is found, remove it from the array
        if (attributeIndex !== -1) {
          updatedProducts[productIndex]?.asset_attributes_val?.splice(attributeIndex, 1)
          // If the product has no selected attributes left, remove it from the array of selected products
          if (updatedProducts[productIndex]?.asset_attributes_val?.length === 0) {
            updatedProducts.splice(productIndex, 1)
          }
        }
      }
    }
    // Check if handleProductTableChange is defined before invoking it
    if (handleProductTableChange) {
      handleProductTableChange(updatedProducts)
    }
    // Update the state with the updated array of selected products
    setSelectedProducts(updatedProducts)
  }

  return (
    <div className="table-container">
      <table className="products-table">
        <thead>
          <tr>
            <th className={attributeData?.length > 0 ? "fixed-column" : "fixed-column single-column"}>
              <p>{productFamilyName}</p>
            </th>
            {asset_attributes_list?.map((attribute, index) => (
              <th key={index}>
                <p>{attribute}</p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {product_data?.map((product, rowIndex) => (
            <tr key={rowIndex}>
              <td className={attributeData?.length > 0 ? "fixed-column" : "fixed-column single-column"}>
                <a href={"#"}>
                  <p>{product?.title}</p>
                </a>
              </td>
              {asset_attributes_list?.map((attribute, colIndex) => (
                <td key={colIndex} className="center-cell">
                  <CheckBox
                    label=""
                    value={attribute}
                    isChecked={selectedProducts.some(
                      (p) =>
                        p.drupal_internal__nid === product?.drupal_internal__nid &&
                        p.asset_attributes_val?.includes(attribute),
                    )}
                    isDisabled={false}
                    onChange={(e) =>
                      handleCheckBoxChange(
                        product?.drupal_internal__nid,
                        attribute,
                        e?.target?.checked,
                      )
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default ProductsTable
