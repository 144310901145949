import React, { useState } from "react"
import Loader from "@atoms/loader"
import { IIFrame } from "./_iframe.interface"

const Iframe = ({ iframeURL, width, height, title, classes,scroll=false}: IIFrame) => {
  const [loading, setLoading] = useState(true)

  const handleLoad = () => {
    setLoading(false)
  }

  const getClasses = () => {
    const frameClasses = [loading ? "iframe-container-no-show" : "iframe-container-show"]
    let cssClass: string = frameClasses.join(" ")
    cssClass = classes ? `${cssClass} ${classes}` : cssClass
    return cssClass
  }
  return (
    <div className="iframe-container">
      {title && <h2 className="iframe-container-title">{title}</h2>}
      {loading && (
        <div className="iframe-container-loader">
          <p>
            <Loader display={loading} />
          </p>
        </div>
      )}
      <iframe
        src={iframeURL}
        title={title}
        className={getClasses()}
        onLoad={handleLoad}
        allowFullScreen
        width={width}
        height={height}
        scrolling={scroll?"yes":"no"}
      />
    </div>
  )
}

export default Iframe
