import { Anchor, Column, FavoriteIcon, Icon, Img, RichText, Row, Taxonomy } from "@atoms"
import moment from "moment"
import { BookmarkWithButton } from "@molecules"
import { useDispatch, useSelector } from "react-redux"
import CHARLIMIT from "@helpers/constants/charLimit"
import { Dispatch, memo, useCallback, useContext, useMemo, useState } from "react"
import { addRatings } from "store/actions/RatingsActionCreators"
import ApplicationContext from "@utils/application-context/applicationContext"
import { IMagazineCardProps } from "./_magazineCard.interface"
import { useMediaQuery } from "@helpers/hooks"
import { getApiDomainAndLang } from "@utils/baseApi"

const MagazineCard = ({
  id,
  magImage,
  isVideo,
  taxonomies,
  title,
  subtitle,
  desc,
  createdDate = "",
  time = "",
  bookmark,
  href,
  row,
}: IMagazineCardProps) => {
  const isMobile = useMediaQuery("(max-width: 767px)")
  const isTablet = useMediaQuery("(max-width: 991px)")
  const applicationConfigContext = useContext(ApplicationContext)
  const [isFavouriteSelected, setIsFavouriteSelected] = useState<boolean>(false)
  const dispatch: Dispatch<any> = useDispatch()
  const bookmarkHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  const updateRatings: any = useCallback((value: any) => dispatch(addRatings(value)), [dispatch])

  const favourite: any = useSelector((state: any) => state?.ratings?.liveCount?.favourite)
  const userSpecificFav = useSelector((state: any) => state?.user?.profile?.response?.favourite)
  let totalFavoriteCount = 0
  const selectedCards = favourite?.filter((f: any) => parseInt(f.node_id) === id)
  totalFavoriteCount = selectedCards?.length > 0 ? selectedCards?.[0]?.count : 0
  const { applicationConfiguration } = useContext(ApplicationContext)

  useMemo(() => {
    for (const iterator in userSpecificFav) {
      if (parseInt(userSpecificFav[iterator].node_id) === id) {
        setIsFavouriteSelected(true)
      }
    }
  }, [userSpecificFav])

  const favouriteHandler = (state: any) => {
    updateRatings({
      id,
      state,
    })
  }

  const getImageType = (): any => {
    let imageType = ""
    if (row === 1) {
      imageType = "magazine_one_column"
    } else if (row === 2) {
      imageType = "magazine_two_column"
    } else if (row === 3) {
      imageType = "card"
    }
    return isMobile ? "card" : imageType
  }

  const extractDate = (date: string) => {
    const { lang } = getApiDomainAndLang()
    return moment(date).locale(lang).format(applicationConfiguration?.siteConfig?.date_format)
  }

  const minHeightForCard = () => {
    if (isMobile) return "276px"
    else if (isTablet) {
      if (row > 2) return "280px"
      else if (row === 2) return "278px"
      else return "290px"
    } else {
      if (row > 2) return "278px"
      else return "322px"
    }
  }
  const removeMinHeight = (elemId: string): any => {
    const cardelement = document.getElementById(elemId)
    if (cardelement) {
      cardelement.style.minHeight = "initial"
      const imageElement = cardelement.children[0].firstElementChild as HTMLElement
      if (imageElement) {
        imageElement.style.display = "flex"
        imageElement.style.height = minHeightForCard()
      }
    }
  }

  return (
    <div
      className="mag-card"
      id={id.toString()}
      style={{
        minHeight: minHeightForCard()
      }}>
      <div className="mag-card-image">
        {magImage?.src && (
          <Img
            className="event-card-horizontal-image-content"
            layout="fill"
            type={getImageType()}
            styledimage={magImage?.styledimage}
            src={magImage?.src}
            alt={magImage?.alt}
            width={1440}
            height={720}
            onLoad={removeMinHeight(id.toString())}
          />
        )}
      </div>
      <span className="mag-card-shader" />
      <div className="mag-card-content">
        {taxonomies && (
          <div className="mag-card-taxonomy">
            <Taxonomy taxonomies={taxonomies} />
          </div>
        )}
        <Row className="mag-card-content-info">
          <Column desktop={8} tablet={12} mobile={12} className="mag-card-content-info-text">
            <Anchor href={href} tabindex={2}>
              <>
                {subtitle ? (
                  <RichText
                    content={subtitle}
                    className="mag-card-content-info-text-subtitle"
                    characterLimit={CHARLIMIT.TITLE}
                  />
                ) : (
                  <></>
                )}
                {title ? (
                  <RichText
                    content={title}
                    className="mag-card-content-info-text-title"
                    characterLimit={CHARLIMIT.TITLE}
                  />
                ) : (
                  <></>
                )}
              </>
              {desc ? (
                <RichText
                  content={desc}
                  className="mag-card-content-info-text-desc"
                  characterLimit={CHARLIMIT.DESCRIPTION}
                />
              ) : (
                <></>
              )}
            </Anchor>
          </Column>
          <Column desktop={4} tablet={12} mobile={12} className="mag-card-content-info-meta">
            {createdDate && (
              <div className="mag-card-content-info-meta-date">{extractDate(createdDate)}</div>
            )}
            {time && (
              <div className="mag-card-content-info-meta-time">
                <Icon iconName="TimeIcon" />
                {time}
              </div>
            )}
            <div className="mag-card-content-info-meta-bookmark-favorite">
              {applicationConfigContext?.applicationConfiguration?.isBookmarkEnabled &&
                bookmark && (
                  <BookmarkWithButton id={id} {...bookmark} bookmarkCallback={bookmarkHandler} />
                )}
              {applicationConfigContext?.applicationConfiguration?.isFavouriteEnabled && (
                <FavoriteIcon
                  id={id}
                  totalFavoriteCount={totalFavoriteCount}
                  favouriteStatus={isFavouriteSelected}
                  favoriteHandlerCallback={favouriteHandler}
                />
              )}
            </div>
          </Column>
        </Row>
      </div>
      {isVideo && (
        <Anchor href={href} tabindex={2}>
          <div className="mag-card-playicon-overlay">
            <Icon iconName="PlayIcon" />
          </div>
        </Anchor>
      )}
    </div>
  )
}

export default memo(MagazineCard)
