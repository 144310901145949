import { IButtonGroup } from "./_button-group.interface"

const ButtonGroup = (props: IButtonGroup) => {
  const getClasses = () => {
    const classes = []
    classes.push("btn-group")
    if (props?.className) classes.push(props?.className)
    return classes.join(" ")
  }
  return (
    <div className={getClasses()} role="group">
      {props?.children}
    </div>
  )
}

export default ButtonGroup
