import { IWrapperContainer } from "./_wrapperContainer.interface"

const WrapperContainer = ({ subTheme, children }: IWrapperContainer) => {
  if (subTheme) {
    return <div className={`wrapper-container ${subTheme}`}>{children}</div>
  }
  return <>{children}</>
}

export default WrapperContainer
