import * as actionTypes from "../ActionTypes"

export const setSession = (session: string, csrf: string) => {
  const action: SessionAction = {
    type: actionTypes.SET_SESSION,
    session,
    csrf,
  }
  return performRequest(action)
}

export const clearSession = () => {
  const action: SessionAction = {
    type: actionTypes.CLEAR_SESSION,
    session: "",
    csrf: "",
  }
  return performRequest(action)
}

export const setApplicationID = (applicationId: string) => {
  const action: SessionAction = {
    type: actionTypes.SET_APPLICATION_ID,
    applicationId,
  }
  return performRequest(action)
}

const performRequest = (action: SessionAction) => {
  return (dispatch: RatingsDispatchType) => {
    dispatch(action)
  }
}
