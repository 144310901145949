import { AnchorButton, Icon } from "@atoms"
import CHARLIMIT from "@helpers/constants/charLimit"
import { trimText } from "@utils/stringparsing"
import { ICTAButtonGroup } from "./_cta-button-group.interface"
import { handleClick } from "@utils/navigationHelper"
import { useRouter } from "next/router"
import isLogoIcon from "@utils/isLogoIcon"

const CTAButtonGroup = ({
  fieldButtonAlignment,
  fieldButtonTitle,
  fieldButtonUri,
  tabIndex = 0,
  isInline = false,
  iconAlignment,
  iconType,
  referenceId,
}: ICTAButtonGroup) => {
  const getClasses = () => {
    const classes = ["cta-btn"]
    if (isInline) return "cta-inline"
    if (fieldButtonAlignment === "centre") classes.push("cta-btn-center")
    else if (fieldButtonAlignment === "left") classes.push("cta-btn-left")
    else if (fieldButtonAlignment === "right") classes.push("cta-btn-right")
    return classes.join(" ")
  }

  const getIconClasses = (iconName: string) => {
    const classes = [iconAlignment === "Right" ? "iconRight" : "iconLeft"]
    if(isLogoIcon(iconName)) classes.push("logoIcon")
    return classes.join(" ")
  }
  const router = useRouter()
  return (
    <div className={getClasses()} role="group">
      <AnchorButton
        href={fieldButtonUri === "route:<nolink>" ? "" : fieldButtonUri}
        tabindex={tabIndex}
        isSecondary
        isExternal={false}
        {...{
          children: (
            <>
              {iconType && <Icon className={getIconClasses(iconType)} iconName={iconType} />}
              {trimText(fieldButtonTitle, CHARLIMIT.CTA)}
            </>
          ),
        }}
        onClick={() => handleClick(router, referenceId || fieldButtonUri)}
      />
    </div>
  )
}

export default CTAButtonGroup
