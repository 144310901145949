import { Column, Container, Icon, Img } from "@atoms"
import React from "react"
import { CarouselWrapper } from "@organisms"
import { RelatedItemContent } from "@molecules"
import relatedItems from "@helpers/constants/relatedItem"
import { IRelatedItemProps, RSlidesInfo } from "./_relatedItems.interface"

const RelatedItemSlide = ({ relatedItemImage, relatedItemContent }: RSlidesInfo) => (
  <Container narrow>
    <div className="related-item">
      <div className="row">
        {relatedItemImage?.src && (
          <Column desktop={6} tablet={6} mobile={12} className="related-item-image">
            <Img
              className="related-item-image-content"
              src={relatedItemImage.src}
              alt={relatedItemImage.alt}
              width={relatedItems.imgWidth}
              height={relatedItems.imgHeight}
            />
          </Column>
        )}
        <Column
          desktop={relatedItemImage?.src ? 6 : 12}
          tablet={relatedItemImage?.src ? 6 : 12}
          mobile={12}
          className="related-item-content-right">
          <RelatedItemContent
            {...relatedItemContent}
            buttonIcon={<Icon iconName="DownloadIcon" />}
          />
        </Column>
      </div>
    </div>
  </Container>
)

const RelatedItem = ({ slidesInfo, relatedItemsTitle }: IRelatedItemProps) => {
  return (
    <>
      <div className="related-items-title container container-narrow">
        <h2 className="bold">{relatedItemsTitle}</h2>
      </div>
      <CarouselWrapper
        className="related-item-container testimonial-caraousel"
        slideToShow={1}
        slideToShowTablet={1}>
        {slidesInfo.map((slides: RSlidesInfo, index: number) => (
          <RelatedItemSlide key={index} {...slides} />
        ))}
      </CarouselWrapper>
    </>
  )
}

export default RelatedItem
