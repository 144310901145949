import { useState } from "react"
import NUMBERS from "@helpers/constants/numbers"
import Img from "@atoms/img"
import { IIcontagProps } from "./_icontag.interface"

const IconTag = ({
  iconFile,
  tooltip,
  className
}: IIcontagProps) => {
  const [showTooltip, setShowTooltip] = useState(false)
  
  const getClasses = () => {
    const classes = ["icon-tag"]
    if (className) classes.push(className)
    return classes.join(" ")
  }

  const getTooltip = () => {
    setShowTooltip(true)
    setTimeout(() => setShowTooltip(false), NUMBERS.TWO_THOUSAND)
  }

  return (
    <div className={getClasses()}>
        <span className="icon" onClick={getTooltip}>
          <Img src={iconFile} alt={tooltip} height={64} width={64} type="icon" />
          </span>
        
        { showTooltip && <div className="icon-tag-tooltip">
          <span className="icon-tag-tooltip-text">{tooltip}</span>
        </div> }
    </div>
  )
}

export default IconTag
