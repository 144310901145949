import { getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import {
 filterURL, getBlockData, getImageMeta, isExternalLink,
} from "."
const getArticleBannerData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const articleBannerData = await getBlockData(url, false, serverData)
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(articleBannerData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(articleBannerData)
  const imagePaywallData = isPaywallProtected(
    articleBannerData?.data?.relationships?.field_banner_image,
  )
  const mobileImagePaywallData = isPaywallProtected(
    articleBannerData?.data?.relationships?.field_banner_mobile_image,
  )

  const { imageUrl, alt, imageStyledUrl } = imagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(articleBannerData?.data?.relationships?.field_banner_image)

  const { imageUrl: mobileImageUrl, alt: mobileImageAlt,imageStyledUrl:mobileStyledImageUrl } = mobileImagePaywallData.isPaywallProtectedFlag
    ? getPaywalledImgData(mobileImagePaywallData?.errorObj)
    : getImageMeta(articleBannerData?.data?.relationships?.field_banner_mobile_image)
    
  return {
    banner: {
      heading: articleBannerData?.data?.attributes?.field_banner_title?.value
        ? articleBannerData?.data?.attributes?.field_banner_title?.value
        : null,
      content: articleBannerData?.data?.attributes?.body?.processed
        ? articleBannerData?.data?.attributes?.body?.processed
        : null,
        isOverlay: articleBannerData?.data?.attributes?.field_overlay_on_off ?? null,
        isOverlayFontType: articleBannerData?.data?.attributes?.field_font_dark_light ?? null,
      buttonLabel: articleBannerData?.data?.attributes?.field_banner_primary_cta?.title
        ? articleBannerData?.data?.attributes?.field_banner_primary_cta?.title
        : null,
      link: articleBannerData?.data?.attributes?.field_banner_primary_cta?.uri
        ? filterURL(articleBannerData?.data?.attributes?.field_banner_primary_cta?.uri)
        : null,
      linkExternal: articleBannerData?.data?.attributes?.field_banner_primary_cta?.options
        ?.attributes?.target
        ? isExternalLink(
            articleBannerData?.data?.attributes?.field_banner_primary_cta?.options?.attributes
              ?.target,
          )
        : null,
      buttonLabel2: articleBannerData?.data?.attributes?.field_cta_button_1?.title
        ? articleBannerData?.data?.attributes?.field_cta_button_1?.title
        : null,
      link2: articleBannerData?.data?.attributes?.field_cta_button_1?.uri
        ? filterURL(articleBannerData?.data?.attributes?.field_cta_button_1?.uri)
        : null,
      linkExternal2: articleBannerData?.data?.attributes?.field_cta_button_1?.options
        
        ? isExternalLink(
            articleBannerData?.data?.attributes?.field_cta_button_1?.options
              
          )
        : null,
      disableShader: isPaywallProtected(articleBannerData?.data?.attributes?.field_disable_shader)
        .isPaywallProtectedFlag
        ? null
        : articleBannerData?.data?.attributes?.field_disable_shader ?? null,
      className: articleBannerData?.data?.attributes?.field_invert_color ? "inverted" : "",
      bannerContentValue: articleBannerData?.data?.attributes?.field_content_alignment ?? "",
    },
    articleBannerImage: {
    
      desktopImage: {
        src: imageUrl ?? null,
        alt: alt ?? null,
        width: 1440,
        height: 350,
        styledimage: imageStyledUrl ?? null,
      },
      mobileImage: {
        src: mobileImageUrl ?? null,
        alt: mobileImageAlt ?? null,
        width: 390,
        height: 280,
        styledimage: mobileStyledImageUrl ?? null
      },
    },
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getArticleBannerData