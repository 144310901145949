import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { consoleError } from "@utils/error"
import { getMediaFile } from "@utils/mediaLibrary"
import { groupBy } from "@utils/dataModifier"
import { getBlockData, getFileDetails, getImageMeta, getTaxonomy } from "."
import { IMediaCardInfo } from "./_api.interface"
import renderMediaLibraryConfig from "./getMediaLibraryConfig"

const getCardInfo = async (card: any): Promise<IMediaCardInfo | null> => {
  const isChildPaywalled = isPaywallProtected(
    getNestedObject(card, "attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag

  if (isChildPaywalled) {
    return null
  }
  const imagePaywallData = isPaywallProtected(
    getNestedObject(card, "relationships.field_media_image"),
  )
  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(card?.relationships?.field_media_image)

  const categories =
    (await getTaxonomy(
      getNestedObject(card, "relationships.field_media_category.links.related.href") ?? "",
    )) ?? null
  const specialities =
    (await getTaxonomy(
      getNestedObject(card, "relationships.field_media_specialty.links.related.href") ?? "",
    )) ?? null

  const downloadUUID = getNestedObject(card, "relationships.field_media_download.data.id")
  const downloadType = getNestedObject(card, "relationships.field_media_download.data.type")

  const fileType = downloadType?.replace("media--", "")

  const mediaFile = getMediaFile(fileType)

  const { fileSize, downloadFileLink } = await getFileDetails(downloadUUID, fileType, mediaFile)

  return {
    taxonomyLabel: Array.isArray(categories) ? categories?.[0]?.taxonomyLabel ?? "" : "",
    fieldMediaSpecialties: specialities,
    title: isPaywallProtected(getNestedObject(card, "attributes.title")).isPaywallProtectedFlag
      ? null
      : getNestedObject(card, "attributes.title") ?? null,
    changedDate: isPaywallProtected(getNestedObject(card, "attributes.corrected_date"))
      .isPaywallProtectedFlag
      ? null
      : getNestedObject(card, "attributes.corrected_date") ?? null,
    readMore: getNestedObject(card, "attributes.path.alias") ?? null,
    body:
      getNestedObject(card, "attributes.body.processed") ??
      getNestedObject(card, "attributes.body.value") ??
      null,
    imageUrl: imageUrl ?? "",
    alt: alt ?? null,
    styledimage: imageStyledUrl ?? null,
    downloadFileLink: downloadFileLink ?? null,
    fileSize: fileSize ?? null,
  }
}

const getMediaLibData = async (url: string, ...args: any) => {
  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getMediaLibData.name}.`)
  }
  const serverData = args?.[3] // accepting serverData only
  const mediaListing = await getBlockData(`${url}`, false, serverData)
  const mediaLibConfig = await renderMediaLibraryConfig(serverData)
  const mediaCardData = mediaListing?.data ?? []
  const fieldPaywallRoles = getAllRoles(mediaCardData)

  const mediaCategoryData = await Promise.all(
    mediaCardData.map(async (card: any) => getCardInfo(card)),
  )
  const groupedData = groupBy<IMediaCardInfo>(mediaCategoryData, "taxonomyLabel")
  const groupedDataKeys = Object.keys(groupedData)
  const searchResultsArr: any[] = []
  groupedDataKeys.forEach((key) => {
    const obj = {} as any
    obj.category = key
    obj.results = groupedData[key]
    searchResultsArr.push(obj)
  })

  return {
    searchedPhrase: null,
    itemsPerPage: 9,
    allCards: filterNullValues(mediaCategoryData),
    searchResults: searchResultsArr,
    mediaLibConfig,
    fieldPaywallRoles,
  }
}

export default getMediaLibData
