import { LOG_TYPES } from "@helpers/constants/logger"

const constructUrlWithDomain = (url?: string, domain?: string) =>
  !domain || (url && url?.includes(domain)) ? url : `${domain}${url}`

const logMessage = (
  isServer: boolean,
  type: string,
  statusCode?: string | number,
  url?: string,
  errMsg?: string,
  cmsDomain?: string,
  reqDuration?: any,
  cacheStatus?: string,
) => {
  try {
    let message = new Date().toISOString() + " [" + statusCode + "] λ⟵ ♞ " + constructUrlWithDomain(url, cmsDomain) + " ⛃ " + isServer
    if (errMsg) message = message + " ⚠ " + errMsg
    if (reqDuration) message = message + " 🕓 " + reqDuration / 1000
    if (cacheStatus) message = message + " ☁ " + cacheStatus
    const loggingLevel = isServer
      ? process.env.SOLAR_ENABLE_LOG
      : JSON.parse(sessionStorage.getItem("loggingLevel") || "0")
    switch (loggingLevel as string) {
      case "1":
        if (isServer && type === LOG_TYPES.ERROR) {
          console.error("%c" + message, "color: red")
        }
        break
      case "2":
        if (isServer && type === LOG_TYPES.ERROR) {
          console.error("%c" + message, "color: red")
        } else if (isServer && type === LOG_TYPES.LOG) {
          console.log("%c" + message, "color: green")
        }
        break
      case "3":
        if (type === LOG_TYPES.ERROR) {
          console.error("%c" + message, "color: red")
        }
        break
      case "4":
        if (type === LOG_TYPES.ERROR) {
          console.error("%c" + message, "color: red")
        } else if (type === LOG_TYPES.LOG) {
          console.log("%c" + message, "color: green")
        }
        break
      default:
        break
    }
  } catch (error) {}
}

export default logMessage
