export const getWave1ProductDataLabels = (siteConfig: any) => {
  return {
    hideViewCTA: siteConfig?.product_list_view_cta_toggle !== 1,
    label: {
      list_view_text: siteConfig?.product_list_view_text ?? null,
      grid_view_text: siteConfig?.product_grid_view_text ?? null,
      default_view: siteConfig?.product_list_default_view ?? null,
    },
    title: siteConfig?.product_list_configuration_title_text ?? "",
    searchLabels: {
      inputDataContent: {
        type: "primary",
        placeholder: siteConfig?.product_list_search_placeholder_text ?? "",
      },
      buttonText: siteConfig?.product_list_search_cta_text ?? "",
    },
    filterLabels: {
      clearText: siteConfig?.product_list_search_clear_all_text ?? "",
      applyText: siteConfig?.product_list_apply_filter_text ?? "",
    },
    resultLabels: {
      resultsFoundText: siteConfig?.product_list_search_results_found ?? "",
      noResultsFoundText: siteConfig?.product_list_no_results_found_title ?? "",
    },
    specifications: siteConfig?.show_in_card ?? [],
    itemsPerPage: Number(siteConfig?.fes_default_item_count ?? "0"),
  }
}

export const getCartPageLabels = (siteConfig: any) => {
  return {
    title: siteConfig?.webstore_list_cart_title ?? "",
    empty_cart_title: siteConfig?.webstore_list_when_cart_empty ?? "",
    empty_cart_desc: siteConfig?.webstore_list_cart_description ?? "",
    back_to_product_list_label: siteConfig?.webstore_list_back_to_product_listing_cta_text ?? "",
    back_to_product_list_url: siteConfig?.webstore_list_back_to_product_listing_cta_link ?? "",
    cancel_label: siteConfig?.webstore_list_cancel_cta_label ?? "",
    checkout_cta_label: siteConfig?.webstore_list_goto_checkout_cta_label ?? "",
    cart_page_label: siteConfig?.webstore_list_breadcrumb_cart_label ?? "",
    homeTitle: siteConfig?.webstore_list_breadcrumb_home_label ?? "",
    homeLink: siteConfig?.header_logo_link || "/",
    listingPageTitle: siteConfig?.webstore_list_breadcrumb_order_materials_label ?? "",
    checkoutCartTitle: siteConfig?.webstore_list_checkout_cart_title ?? "",
    checkoutLabel: siteConfig?.webstore_list_breadcrumb_checkout_label ?? "",
    editLabel: siteConfig?.webstore_list_edit_cta_link ?? "",
    quantityTextLabel: siteConfig?.webstore_list_quantity_label ?? "",
    shopOverviewLabel: siteConfig?.webstore_list_shop_overview_cta_label ?? "",
    shopOverviewLink: siteConfig?.webstore_list_shop_overview_cta_link ?? "/",
    orderSuccessFlashMsg: siteConfig?.message_text_8 ?? "",
    orderSuccessTitle: siteConfig?.webstore_list_order_successful_title ?? "",
    orderSuccessDesc: siteConfig?.webstore_list_order_successful_description?.value ?? "",
    quantityErrorMessage: siteConfig?.webstore_list_error_text?.value ?? "",
    quantityLabel: siteConfig?.webstore_list_quantity_cta_label ?? "",
  }
}

export const getWebstoreLabels = (siteConfig: any) => {
  return {
    initialView:
      siteConfig?.webstore_list_default_view === siteConfig?.webstore_list_view_text
        ? "list"
        : "grid",
    alphabeticLabel: siteConfig?.webstore_list_alphabetical_filter_label ?? "",
    diseaseAreaLabel: siteConfig?.webstore_list_disease_area_filter_label ?? "",
    specialityLabel: siteConfig?.webstore_list_specialty_filter_label ?? "",
    paginationCount: "9",
    searchLabel: siteConfig?.webstore_list_search_bar_label ?? "",
    searchPlaceholder: siteConfig?.webstore_list_search_placeholder_text ?? "",
    searchCTALabel: siteConfig?.webstore_list_search_cta_label ?? "",
    clearLabel: siteConfig?.webstore_list_clear_filter_cta ?? "",
    resultsLabel: siteConfig?.webstore_list_items_available_text ?? "",
    noResultsLabel: siteConfig?.webstore_list_no_results_found_text ?? "",
    noResultsDesc: siteConfig?.webstore_list_no_results_found_recommendation_text ?? "",
    applyFiltersCTALabel: siteConfig?.webstore_list_apply_filter_cta ?? "",
    unSelectItemsLabel: siteConfig?.webstore_list_unselect_item ?? "",
    selectPlaceholder: siteConfig?.webstore_list_filters_placeholder_text ?? "",
    downloadLabel: siteConfig?.webstore_list_download_cta_label ?? "",
    quantityLabel: siteConfig?.webstore_list_quantity_cta_label ?? "",
    orderNowLabel: siteConfig?.webstore_list_login_to_order_cta_text ?? "",
    addToCartLabel: siteConfig?.webstore_list_add_to_cart_cta_label ?? "",
    quantityErrorMessage: siteConfig?.webstore_list_error_text?.value ?? "",
    addedToCartText: siteConfig?.webstore_list_add_to_cart_notification?.value ?? "",
    outOfStock: siteConfig?.wsi_out_of_stock_msg ?? "",
    webstore_search_default_config: siteConfig?.webstore_search_default_config ?? "",
    webstore_label_treatment_area: siteConfig?.webstore_label_treatment_area ?? "",
    webstore_label_profession: siteConfig?.webstore_label_profession ?? "",
    webstore_label_time_period: siteConfig?.webstore_label_time_period ?? "",
    webstore_search_facets: siteConfig?.webstore_search_facets ?? [],
    enableDownloadAnonymous: siteConfig?.webstore_list_enable_download_for_anonymous ?? "",
    filter_toggle_label: siteConfig?.filter_toggle_label ?? "",
    webstore_list_view_text: siteConfig?.webstore_list_view_text ?? "",
    webstore_grid_view_text: siteConfig?.webstore_grid_view_text ?? "",
  }
}

export const getNewsLetterVerification = (siteConfig: any) => {
  return {
    visitHomePage: siteConfig?.visit_homepage_text ?? "",
    visitHomePageLink: siteConfig?.visit_homepage_link ?? "/",
    doubleOptinTitle: siteConfig?.double_optin_title ?? "Your subscription to the newsletter",
    doubleOptinDesc:
      siteConfig?.double_optin_desc ??
      "Click here to continue your subscription to the newsletter.",
    doubleOptinSubmitLabel: siteConfig?.double_optin_submit_btn_text ?? "Submit",
    doubleOptinSubmitLink: siteConfig?.double_optin_submit_btn_link ?? "/",
    doubleOptinCancelLabel: siteConfig?.double_optin_cancel_btn_text ?? "Cancel",
    doubleOptinCancelLink: siteConfig?.double_optin_cancel_btn_link ?? "/",

    linkExpiryTitle: siteConfig?.link_expiry_title ?? "Link Expired",
    linkExpiryDesc:
      siteConfig?.link_expiry_desc ?? "Sorry, the time limit for clicking the link has expired.",
    linkExpiryLabel: siteConfig?.link_expiry_link_text ?? "Retry",
    linkExpiryUrl: siteConfig?.link_expiry_link_url ?? "/",

    doubleOptinSuccessTitle: siteConfig?.double_optin_success_title ?? "Success",
    doubleOptinSuccessDesc:
      siteConfig?.double_optin_success_desc ??
      "You have been successfully subscribed to the newsletter.",

    submissionSuccessTitle: siteConfig?.submission_success_title ?? "Success",
    submissionSuccessDesc:
      siteConfig?.submission_success_desc ??
      "You have successfully requested to the subscribe to the newsletter. Check your email to verify the link for your subscription. This link would be accessible for only 24 hours.",

    tokenInvalidTitle: siteConfig?.token_invalid_title ?? "",
    tokenInvalidDesc: siteConfig?.token_invalid_desc ?? "",
  }
}

export const getLoginTimeoutData = (siteConfig: any) => {
  const {
    login_timeout_enable,
    idle_logout_time,
    login_timeout_popup_countdown_time,
    login_timeout_popup_title,
    login_timeout_popup_countdown_text_above,
    login_timeout_popup_countdown_text_below,
    login_timeout_popup_logout_cta_label,
    login_timeout_popup_stay_login_cta_label,
    login_timeout_popup_logged_out_notification,
    login_timeout_browser_tab_title,
  } = siteConfig

  return {
    login_timeout_enable: login_timeout_enable === 1,
    idle_logout_time,
    login_timeout_popup_countdown_time,
    login_timeout_popup_title,
    login_timeout_popup_countdown_text_above,
    login_timeout_popup_countdown_text_below,
    login_timeout_popup_logout_cta_label,
    login_timeout_popup_stay_login_cta_label,
    login_timeout_popup_logged_out_notification,
    login_timeout_browser_tab_title,
  }
}

export const getPostcodeSearchData = (siteConfig: any) => {
  const {
    postcodesearch_title,
    postcodesearch_subtitle,
    postcodesearch_searchplaceholder,
    postcodesearch_button_details,
    postcodesearch_default_placeholder_image_url,
    postcode_image_alt,
  } = siteConfig

  return {
    postcodesearch_title,
    postcodesearch_subtitle,
    postcodesearch_searchplaceholder,
    postcodesearch_button_details,
    postcodesearch_default_placeholder_image_url,
    postcode_image_alt,
  }
}
