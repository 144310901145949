import { LOGIN_TYPES } from "@helpers/constants/generic"
import { LinkType } from "@organisms/jobDescriptionBlock/_jobDescriptionBlock.interface"
import { localeArray } from "@utils/baseApi/config.external"
import isServer from "@utils/fs/isServer"
import cookie from "cookie"
import memoryCache, { CacheClass } from "memory-cache"

const memCache: CacheClass<string, string> = new memoryCache.Cache()

export function parseCookies(req: any) {
  return cookie.parse(req ? req.headers.cookie || "" : document.cookie)
}

export function isAzureFormDependent(browserRoute: string) {
  if (
    browserRoute.includes("oauth2/v2.0/authorize") ||
    browserRoute.includes("/register") ||
    browserRoute.includes("/sign-up") ||
    browserRoute.includes("/my-registration") ||
    browserRoute.includes("/invited-registration")
  ) {
    return true
  }

  return false
}

export const getSelfHost = () => {
  const { location } = window
  const hostname = location.hostname.includes("localhost")
    ? `${location.hostname}:${location.port}`
    : location.hostname
  return `${location.protocol}//${hostname}`
}

export const getAssetPrefixUrl = (assetUrl: string, isLocalhost?: boolean) => {
  if (isLocalhost) {
    return `${getSelfHost()}${assetUrl}`
  }

  try {
    let environment = process.env.NEXT_PUBLIC_ENV
    if (assetUrl.includes("/api/")) {
      return assetUrl
    }

    environment = environment === "development" ? "dev" : environment
    return environment ? `https://${environment}.solar.my-sandoz.com${assetUrl}` : `${assetUrl}`
  } catch (error) {
    return assetUrl
  }
}

let languageArray: any[]
let index
export const getLanguageByCountry = (element: any): any => {
  if (typeof element === "object" && element !== null) {
    languageArray = []
    index = 0

    for (const key in element) {
      const child = element[key]

      if (typeof child === "object" && child !== null) {
        if (child.region_name === element.name) {
          const { country_name, domain_url, lang_name, lang_code } = child
          languageArray.push({
            id: index,
            country: country_name,
            url: domain_url,
            language: lang_name,
            languageCode: lang_code,
            languages: [],
          })
        }

        for (const ckey in child) {
          const gc = child[ckey]
          if (typeof gc === "object" && gc !== null) {
            const { country_name, domain_url, lang_name, lang_code } = gc
            const languageForCountry = languageArray[index]
            languageForCountry?.languages.push({
              country: country_name,
              language: lang_name,
              url: domain_url,
              languageCode: lang_code,
            })
          }
        }
        index++
      }
    }

    return languageArray
  }
}

export const checkForLoginModal = (isShow: boolean): Promise<boolean> => {
  return new Promise((resolve) => {
    if (isShow && memCache.get("login") === null) {
      memCache.put("login", "1", 3000)
      resolve(true)
    }

    resolve(false)
  })
}

export const getFrontendLocalePath = (): string => {
  try {
    const frontendPath = JSON.parse(sessionStorage.getItem("feDomainData") as string)
    if (frontendPath) {
      const feURLPaths = frontendPath.split("/")
      const filterDataArray = feURLPaths.filter((p: string) => {
        return localeArray.some((l: string) => {
          return l === p
        })
      })
      if (filterDataArray && filterDataArray.length) {
        return `/${filterDataArray[0]}`
      }
    }
  } catch (error) {
    return ""
  }

  return ""
}
export const getHomepageurl = (): string => {
  const frontendPath = JSON.parse(sessionStorage.getItem("feDomainData") as string)
  let result = "/"
  if (frontendPath) {
    const check_ccll = frontendPath.split("/")
    if (check_ccll.length > 1) {
      result = `/${check_ccll[1]}`
    }
  }
  return result
}
export const getDomainLang = (): string => {
  let result = ""
  if (!isServer()) {
    const domainLang = sessionStorage.getItem("domainLang")
      ? JSON.parse(sessionStorage.getItem("domainLang") as string)
      : ""
    if (domainLang) {
      result = `country-${domainLang}`
    }
  }
  return result
}
export const uniqueArray = (a: any) =>
  // @ts-ignore
  [...new Set(a.map((o: any) => JSON.stringify(o)))].map((s: any) => JSON.parse(s))

export const uniqueLocaleArray = (urlArr: string[], localeArr: string[]) => {
  const check = [...localeArr, "jsonapi"]
  return urlArr.filter((segment: string, idx: number, arr: string[]) => !check.includes(segment) || arr.indexOf(segment) === idx) 
}

export const sortItems = (data: any) => data.sort((a: any, b: any) => a.itemID - b.itemID)

export const loadGTMPageView = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (memCache.get("gtm_execute") === null) {
      memCache.put("gtm_execute", "1", 5000)
      resolve(true)
    }

    resolve(false)
  })
}

export const isDocCheckUser = (user: any) => {
  const userLoginType = user?.user?.loginType || ""
  return userLoginType === LOGIN_TYPES.DOC_CHECK
}

export const prepareMagazineWithLayout = (
  startIndex: number,
  endIndex: number,
  row: string,
  dataSource: Array<any>,
) => {
  const magazineWithColDetails = []
  for (let i = startIndex, j = 0; i < endIndex; i++, j++) {
    let data = dataSource[i]
    if (data) {
      data = { ...data, col: `${row} - ${j}` }
      magazineWithColDetails.push(data)
    }
  }

  return magazineWithColDetails
}

export const getProfileDetails = (user: any) => {
  const userProfileDetails = user?.profile?.response?.profile?.records?.[0] || {}
  const userScoutId = user?.profile?.response?.profile?.scout_id || ""
  const first_name = userProfileDetails?.FirstName || ""
  const last_name = userProfileDetails?.LastName || ""
  const email_address = userProfileDetails?.PersonEmail || ""
  return {
    first_name,
    last_name,
    email_address,
    scout_id: userScoutId,
    ...userProfileDetails,
  }
}

export const getDropdownSpanElement = (node: any): any => {
  if (node?.hasChildNodes()) {
    return getDropdownSpanElement(node?.childNodes?.[0])
  } else {
    return node?.parentElement
  }
}

export const formatFinePrintData = (finePrintArray: any, insertLinkFlag?: boolean) => {
  let formattedFinePrint = [] as any

  finePrintArray?.forEach((item: any) => {
    let otcObj = {
      id: `${item?.id}_otc`,
      title: item?.title_otc ?? null,
      description: item?.description_otc ?? null,
      productId: item?.product_id ?? null,
    }

    let hcpObj = {
      id: `${item?.id}_hcp`,
      title: item?.title_hcp ?? null,
      description: item?.description_hcp ?? null,
      productId: item?.product_id ?? null,
    }
    formattedFinePrint?.push(otcObj, hcpObj)
  })

  return insertLinkFlag ? insertLinkObjects(formattedFinePrint) : formattedFinePrint
}

export const insertLinkObjects = (inputArray: any) => {
  return inputArray
    ?.flatMap((obj: any, index: number) => [
      obj,
      index === inputArray?.length - 1 ||
      (index < inputArray?.length - 1 && obj.productId !== inputArray?.[index + 1]?.productId)
        ? {
            type: "link",
            productId: obj?.productId,
            title: obj?.title,
            description: obj?.description,
          }
        : null,
    ])
    ?.filter(Boolean)
}

export const isFinePrintPaywalled = (finePrintData: any) => {
  return finePrintData?.every((obj: any) => {
    return (
      (obj?.title === null || (obj?.title && obj?.title?.error_code === 410)) &&
      (obj?.description === null || (obj?.description && obj?.description?.error_code === 410))
    )
  })
}

export const isSomeFinePrintPaywalled = (finePrintData: any) => {
  return finePrintData?.some(
    (obj: any) =>
      (obj?.title && obj?.title?.error_code === 410) ||
      (obj?.description && obj?.description?.error_code === 410),
  )
}

export const getJobLink = (
  btnLinks: LinkType,
  reqHeaders: any,
  fieldJobCountryName?: string,
  workdayJobsCountry?: any,
  isWorkdayJob?: boolean,
) => {
  if (!isWorkdayJob) {
    return btnLinks?.applyLink || ""
  }

  if (reqHeaders && reqHeaders["cf-ipcountry"] === "RU") {
    return btnLinks?.alternativeApplyLink || ""
  }

  const workdayJobsConfig = workdayJobsCountry?.country_filter_field_value
    ?.split(",")
    ?.map((item: any) => item?.trim())
  if (workdayJobsConfig?.includes(fieldJobCountryName)) {
    return btnLinks?.alternativeApplyLink || ""
  }

  return btnLinks?.applyLink || ""
}
