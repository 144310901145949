import * as actionType from "../ActionTypes"

const initialFilterData: any = {
  userActionFilters: [] as any,
  currentPage: 0,
  searchText: "",
  sort: {
    column: "",
    order: "ASC",
  },
}

const UserActionReducer = (state: any = initialFilterData, action: UserActionFilterAction): any => {
  switch (action.type) {
    case actionType.ADD_UPDATE_USER_ACTION_FILTERS:
      return {
        ...state,
        userActionFilters: action.userActionFilters,
        currentPage: 0,
        sort: {
          column: "",
          order: "ASC",
        },
      }
      case actionType.UPDATE_USER_ACTION_PAGE_NUMBER:
        return {
          ...state,
          currentPage: action.currentPage,
        }
      case actionType.UPDATE_USER_ACTION_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          currentPage: 0,
          sort: {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_ALL_USER_ACTION_FILTERS:
        return {
          ...state,
          userActionFilters: action.userActionFilters,
          currentPage: action.currentPage,
          searchText: action.searchText,
          sort: action?.sort || {
            column: "",
            order: "ASC",
          },
        }
      case actionType.UPDATE_USER_ACTION_SORT:
      return {
        ...state,
        sort: action.sort,
      }
    default:
      return state
  }
}

export default UserActionReducer