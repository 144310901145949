/* eslint-disable @typescript-eslint/no-extra-semi */
import React, {useEffect, useState} from "react"
import {SearchableDropdown, TitleDescription} from "@molecules"
import {ILocationGroupData, ILocationOption} from "@helpers/dataFunctions/_api.interface"
import AccordionFlex from "@organisms/accordionFlex"
import {IAccordionFlexProps} from "@organisms/accordionFlex/_accordionFlex.interface"
import LocationAddressBlock from "@organisms/locationAddressBlock"
import NUMBERS from "@helpers/constants/numbers"
import groupBy from "@utils/dataModifier"
import Loader from "@atoms/loader"
import {ILocations} from "./_locations.interface"

const Locations = (props: ILocations) => {

  const {
 allCountry, allDivision, locationGroupData, locationConfig
} = props
  const [addressData, setAddressData] = useState(locationGroupData)
  const [isLoading, setIsLoading] = useState(true)
  const [selectedCountry, setSelectedCountry] = useState<ILocationOption | null>()
  const [selectedDivision, setSelectedDivision] = useState<ILocationOption | null>()

  const handleDropdownChange = (value: ILocationOption | null, type: string) => {

    if (type === "country") {

      setSelectedCountry(value)

} else {

      setSelectedDivision(value)

}

}

  useEffect(() => {

    const getFilterData = () => {

      setIsLoading(true)
      const newData = locationGroupData.filter((location) => {

        const countryMatches = selectedCountry?.value && location.countryName === selectedCountry.value
        const divisionMatches = selectedDivision?.value && location.divisionName === selectedDivision.value

        return (selectedCountry?.value && selectedDivision?.value) ? (countryMatches && divisionMatches) : (countryMatches || divisionMatches)

})
      setAddressData(newData)
      setIsLoading(false)

}

    getFilterData()

}, [selectedCountry, selectedDivision])

  const accordionArgs = {
    accordionArray: [] as IAccordionFlexProps[]
  }

  const formatMatchString = (arrayLength: number) =>
    (arrayLength > NUMBERS.ZERO
      ? locationConfig?.results_found_text.replace("@count_loc_val", arrayLength.toString())
      : locationConfig?.no_results_text)

  const groupedData = groupBy<ILocationGroupData>(addressData, "divisionName")
  const groupedDataKeys = Object.keys(groupedData)
  let noOfResults = NUMBERS.ZERO
  groupedDataKeys.forEach((key) => {

    const obj = {} as IAccordionFlexProps
    const data = groupedData[key]
    noOfResults += data.length
    obj.title = allDivision?.find((division: ILocationOption) => division?.value === Number(key))?.label
    obj.content = (
        <LocationAddressBlock
            addressData={data}
            addressLabels={locationConfig.address_labels}
        />
    )
    accordionArgs.accordionArray.push(obj)

})

  let divisionList = allDivision
  if (locationConfig.division_dropdown.default) {

    divisionList = [
      {id: 0, value: 0, label: locationConfig.division_dropdown.default},
      ...allDivision
    ]

}

  return (
      <div className="location-filter">
          {locationConfig?.display_division_dropdown && <SearchableDropdown
              dropdownData={divisionList}
              handleDropdownChange={handleDropdownChange}
              type="division"
              labels={locationConfig.division_dropdown}
                                                        />}
          <SearchableDropdown
              dropdownData={allCountry}
              handleDropdownChange={handleDropdownChange}
              type="country"
              labels={locationConfig.country_dropdown}
              defaultValue={locationConfig.default_country}
          />
          <div className="location-container">
              {isLoading ? (
          /* need to update with loader */
                  <div className="locations-loader">
                      <p>
                          <Loader display={isLoading} />
                      </p>
                  </div>
        ) : (
            <>
                <TitleDescription
                    title={formatMatchString(noOfResults) ?? ""}
                    description=""
                    searchKey=""
                    className="location-filter-result-text"
                />
                <AccordionFlex {...accordionArgs} />
            </>
        )}
          </div>
      </div>
  )

}

export default Locations
