import { Anchor, Icon, RichText } from "@atoms"
import { IEmpty } from "./_emptyCart.interface"

const EmptyCart = (props: IEmpty) => {
  const {
    empty_cart_title,
    empty_cart_desc,
    back_to_product_list_label,
    back_to_product_list_url,
  } = props
  return (
    <div className="empty-cart">
      <div>
        <span className="first-line">{empty_cart_title}</span>
      </div>
      <div>
        <RichText className="second-line" content={empty_cart_desc?.value} />
      </div>
      <div className="bottom-section">
        <Icon iconName="ArrowLeftIcon" className="arrow-icon" />
        <Anchor href={back_to_product_list_url} tabindex={0} noUnderline={false}>
          <span className="text-with-arrow">{back_to_product_list_label}</span>
        </Anchor>

        <div />
      </div>
    </div>
  )
}

export default EmptyCart
