import { Anchor, Img } from "@atoms"
import { IGoogleMapsProps } from "./_googleMaps.interface"

const GoogleMaps = (props: IGoogleMapsProps) => {
  if (!props.href) {
    return <></>
  }

  return (
    <Anchor
      href={props.href}
      tabindex={0}
      noUnderline={true}
      isExternal={true}
      className="google-map">
      {props.image ? <Img type="default" width={1727} height={264} src={props.image} alt={props.alt} /> : <></>}
    </Anchor>
  )
}
export default GoogleMaps
