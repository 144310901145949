import { filterNullValues, getAllRoles, isPaywallProtected } from "@utils/paywallUtilities"
import getNestedObject from "@utils/nestedObjects"
import { hexalSubThemeConfig } from "@utils/hexalSubThemeUtil"
import { filterURL, getBlockData, getTaxonomy, isExternalLink } from "."

const getArticleTeaserGroupData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const hasQueryParams = url.includes("?")
  let articleCards = await getBlockData(
    `${url}${hasQueryParams ? "&" : "?"}include=field_article_content,field_sub_theme,field_article_teaser_specialty`,
    false,
    serverData,
  )
  const isParentPaywalled = isPaywallProtected(
    getNestedObject(articleCards, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(articleCards)

  const containerStyle = articleCards?.data?.attributes?.field_block_container || null

  const subThemeId = getNestedObject(
    articleCards,
    "data.relationships.field_sub_theme.data.meta.drupal_internal__target_id",
  )
  const taxonomies = await getTaxonomy(
    getNestedObject(
      articleCards,
      "data.relationships.field_article_teaser_specialty.links.related.href",
    ),
    false,
    serverData,
  )

  const subTheme = articleCards?.included?.filter(
    (items: any) => items.type === "taxonomy_term--microsites",
  )

  articleCards = articleCards?.included?.filter(
    (items: any) => items.type === "node--article_composition",
  )

  const subThemeValue = subTheme?.map((data: any) => {
    if (data.attributes.drupal_internal__tid === subThemeId) {
      return data.attributes.name
    }
  })

  articleCards = articleCards?.map((articleCard: any) => {
    const isChildPaywalled = isPaywallProtected(
      getNestedObject(articleCard, "attributes.enable_paywall_block_level"),
    )?.isPaywallProtectedFlag

    if (isChildPaywalled) {
      return null
    }
    return {
      heading: isPaywallProtected(articleCard?.attributes?.title).isPaywallProtectedFlag
        ? null
        : articleCard?.attributes?.title ?? null,
      text: articleCard?.attributes?.body?.processed ?? null,
      buttonLabel: articleCard?.attributes?.field_secondary_cta?.title ?? null,
      link: filterURL(articleCard?.attributes?.field_secondary_cta?.uri) ?? null,
      linkExternal:
        isExternalLink(articleCard?.attributes?.field_secondary_cta?.options?.attributes?.target) ??
        null,
      taxonomies: taxonomies ?? null,
    }
  })

  return {
    articleTeaserSingleContentGroup: filterNullValues(articleCards) ?? null,
    container: containerStyle,
    // @ts-ignore
    subTheme: hexalSubThemeConfig?.[subThemeValue] ?? "",
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getArticleTeaserGroupData
