import React, { useContext } from "react"
import { Anchor, Column, Container, Img, RichText, Row } from "@atoms"
import { handleDataLayerNavigationFooter } from "@utils/gtmUtilsHelpers"
import { MicrositeLinks } from "@molecules"
import { getHomepageurl } from "@utils/helper"
import { IFooter } from "./_footer.interface"
import SocialIcon from "../socialIcon"
import { IFooterNavigation } from "../../molecules/footerNavigation/_footerNavigation.interface"
import FooterNavigation from "../../molecules/footerNavigation"
import ApplicationContext from "@utils/application-context/applicationContext"

const Footer = (props: IFooter) => {
  const applicationConfigContext = useContext(ApplicationContext)
  const mlrID = applicationConfigContext?.applicationConfiguration?.mlrID
    ? applicationConfigContext?.applicationConfiguration?.mlrID
    : ""

  const hasImageData = props?.footerBottomSection?.logoLink

  const footerLogoLink = applicationConfigContext?.applicationConfiguration?.siteConfig?.footer_logo_link

  return (
    <div className="footer">
      <Container>
        <Row>
          <div className="row footer-top">
            <Column mobile={12} tablet={12} desktop={3}>
              <Anchor
                href={footerLogoLink?.length > 1 ? footerLogoLink : getHomepageurl()}
                tabindex={0}
                noUnderline={true}
                className="logo"
                dataLayerOnClick={() => handleDataLayerNavigationFooter("logo")}>
                {hasImageData && (
                  <Img
                    alt="logo"
                    src={props?.footerBottomSection?.logoLink}
                    width={200}
                    height={100}
                    className="img-fluid"
                    type="icon"
                  />
                )}
              </Anchor>
              <ul className="list-unstyled small">
                <li className="description">
                  <RichText
                    className={`paragraph footer-text ${!hasImageData ? "no-img" : ""}`}
                    content={props?.footerBottomSection?.paragraph}
                  />
                </li>
                <li>
                  <SocialIcon
                    socialIconData={props?.footerBottomSection?.socialIconData}
                    followUs={props?.footerBottomSection?.follow_us}
                  />
                </li>
              </ul>
            </Column>
            <Column
              mobile={12}
              tablet={9}
              desktop={9}
              className="row footer-footerNavigation footer-desktop">
              {props.footerNavigation?.map((item: IFooterNavigation, index: number) => (
                <FooterNavigation title={item.title} child={item.child} key={index} />
              ))}
            </Column>
          </div>

          <div className="accordion accordion-flush  footer-mobile" id="accordionFlushFooterMobile">
            {props.footerNavigation?.map((item, key) => (
              <div className="accordion-item" key={key}>
                <h2 className="accordion-header" id={`footer-flush-headingOne${key}`}>
                  <button
                    className="accordion-button collapsed "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#footer-flush-collapseOne${key}`}
                    aria-expanded="false"
                    aria-controls={`footer-flush-collapseOne${key}`}>
                    {item.title}
                  </button>
                </h2>
                <div
                  id={`footer-flush-collapseOne${key}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`footer-flush-headingOne${key}`}
                  data-bs-parent="#accordionFlushFooterMobile">
                  <div className="accordion-body">
                    <FooterNavigation title={item.title} child={item.child} key={key} />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {props?.footerMicrosite?.microFooterFlag ? (
            <div className="micrositeFooter">
              <MicrositeLinks
                microData={props.footerMicrosite.microFooterData}
                title={props?.footerMicrosite?.microFooterTitle}
              />
            </div>
          ) : (
            <></>
          )}
          <>{mlrID}</>
          <div className="footer-bottom">
            <RichText
              className="footer-first footer-text"
              content={props?.footerBottomSection?.copyRightText}
            />
            <span className="footer-middle">
              {props?.footerSecondaryMenu?.map((item, index: number) => (
                <Anchor
                  className="footer-text"
                  href={item.link}
                  tabindex={0}
                  key={index}
                  noUnderline={true}
                  target={item?.targetType}
                  dataLayerOnClick={() => handleDataLayerNavigationFooter(item.title)}>
                  <p>{item.title}</p>
                </Anchor>
              ))}
            </span>
            <RichText
              className="footer-last footer-text"
              content={props.footerBottomSection.footerText}
            />
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default Footer
