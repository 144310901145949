import Icon from "@atoms/icon"
import { IBookmarkProps } from "./_bookmark.interface"

const Bookmark = (props: IBookmarkProps) => {
  const getClasses = () => {
    const classes = []
    classes.push("bookmark")
    if (props.className) classes.push(props.className)
    return classes.join(" ")
  }

  return (
    <div className={getClasses()}>
      {!props.bookmarkStatus ? <Icon iconName="BookmarkIcon" /> : <Icon iconName="BookmarkFilledIcon" />}
    </div>
  )
}

export default Bookmark
