import PropTypes from "prop-types"
import React, { useContext } from "react"
import { ApplicationThemeContext } from "@utils/application-theme-context/applicationThemeContext"
import IconLibraryComponent from "./_iconsLib"
import { IIcon } from "./_icon.interface"

const Icon = ({
 iconName, height, width, color, ...otherProps
}: IIcon) => {
  const { applicationTheme } = useContext(ApplicationThemeContext)
  const getAppTheme = (theme: string) => {
    switch (theme) {
      case "rebranding":
      case ("rebranding-japan"):
      case ("jubbonti"):
      case ("wyost"):
      case ("biosimilarsinbone"):
      case ("vagidonna"):
        return "rebranding"
      default:
        return "default"
    }
  }
  const appTheme = getAppTheme(applicationTheme ?? "default")
  const themeIcon = IconLibraryComponent(appTheme, iconName, width, height, color)
  return (themeIcon && <>{React.cloneElement(themeIcon, { ...otherProps })}</>
  )
}

Icon.defaultProps = {
  width: 24,
  height: 24,
  color: "",
}

Icon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  iconName: PropTypes.string.isRequired,
}

export default Icon
