import { DropDown } from "@atoms"
import SimpleDropDown from "@atoms/simpleDropDown"
import React from "react"
import { ICareerOptionsProps } from "./_careerOptions.interface"

const CareerSearchOptions = ({
  filterOptions,
  isMobile,
  showFilterText,
  handleAdditionalFilters,
  selectedFilters = {},
  clearFilters,
}: ICareerOptionsProps) => (
  <div className="careerOptions">
    {isMobile ? (
      <div>
        <p className="careerOptions-show-filter">{showFilterText}</p>
        {filterOptions && filterOptions.length
          ? filterOptions.map((item: any, index: any) => (
            <div className="careerOptions-gap" key={index}>
              <DropDown
                key={`career-${index}-${item?.label}`}
                id={`career-${index}-${item?.label}`}
                {...item.selectOptions}
                isCleared={clearFilters}
                simpleDropDownLabel={true}
                placeHolder={item?.label}
                isMulti={item?.selectOptions.isMulti}
                intialValue={selectedFilters?.[item.filterName]}
                onValueChange={(val) => handleAdditionalFilters?.(item.filterName, val)}
              />
            </div>
          )) : <div />}
      </div>
    ) : (
      <div className="careerOptions-dropdown">
        {!!filterOptions.length
          && filterOptions.map((item: any, index: any) => (
            <SimpleDropDown
              key={`career-${index}-${item?.label}`}
              id={`career-${index}-${item?.label}`}
              {...item.selectOptions}
              simpleDropDownLabel={true}
              placeHolder={item?.label}
              isCleared={clearFilters}
              isMulti={item?.selectOptions.isMulti}
              intialValue={selectedFilters?.[item.filterName]}
              onValueChange={(val) => handleAdditionalFilters?.(item.filterName, val)}
            />
          ))}
      </div>
    )}
  </div>
)

export default CareerSearchOptions
