import { filterNullValues, getAllRoles, getPaywalledImgData, isPaywallProtected } from "@utils/paywallUtilities"
import { filterURL, getBlockData, getImageMeta, isExternalLink } from "."
import { consoleError } from "../../utils/error"
import getNestedObject from "../../utils/nestedObjects"
import NUMBERS from "../constants/numbers"

const getHeroBannerContent = (banner: any) => ({
  title: getNestedObject(banner, "attributes.field_hero_banner_title.value") ?? null,
  paragraph: getNestedObject(banner, "attributes.body.processed") ?? null,
  titleSuperscript: getNestedObject(banner, "attributes.field_title_superscript.value") ?? null,
  linkText: getNestedObject(banner, "attributes.field_hero_banner_cta_link.title") ?? null,
  isOverlay: getNestedObject(banner, "attributes.field_overlay_on_off") ?? null,
  isOverlayFontType: getNestedObject(banner, "attributes.field_font_dark_light") ?? null,
  link: banner?.attributes?.field_hero_banner_cta_link
    ? filterURL(getNestedObject(banner, "attributes.field_hero_banner_cta_link.uri"))
    : null,
  isExternalLink: isExternalLink(
    getNestedObject(banner, "attributes.field_hero_banner_cta_link.options.attributes.target"),
  ),
  cta_two_label: getNestedObject(banner, "attributes.field_hero_banner_cta_link_2.title") ?? null,
  cta_two_uri:
    filterURL(getNestedObject(banner, "attributes.field_hero_banner_cta_link_2.uri")) ?? null,
  cta_two_externalLink: getNestedObject(
    banner,
    "attributes.field_hero_banner_cta_link_2.options.attributes.target",
  )
    ? isExternalLink(
        getNestedObject(
          banner,
          "attributes.field_hero_banner_cta_link_2.options.attributes.target",
        ),
      )
    : null,
  cta_three_label: getNestedObject(banner, "attributes.field_hero_banner_cta_link_3.title") ?? null,
  cta_three_uri:
    filterURL(getNestedObject(banner, "attributes.field_hero_banner_cta_link_3.uri")) ?? null,
  cta_three_externalLink: getNestedObject(
    banner,
    "attributes.field_hero_banner_cta_link_3.options.attributes.target",
  )
    ? isExternalLink(
        getNestedObject(
          banner,
          "attributes.field_hero_banner_cta_link_3.options.attributes.target",
        ),
      )
    : null,
})

const getHeroBannerImageContent = async (banner: any) => {
  const imagePaywallData = isPaywallProtected(
    getNestedObject(banner, "relationships.field_hero_banner_desktop_image"),
  )
  const imageMobilePaywallData = isPaywallProtected(
    getNestedObject(banner, "relationships.field_hero_banner_mobile_image"),
  )

  const { imageUrl, alt, imageStyledUrl } = imagePaywallData?.isPaywallProtectedFlag
    ? getPaywalledImgData(imagePaywallData?.errorObj)
    : getImageMeta(banner?.relationships?.field_hero_banner_desktop_image)
  const { imageUrl: mobileImageUrl, alt: mobileImageAlt,imageStyledUrl:mobileImageStyledUrl } =
    imageMobilePaywallData.isPaywallProtectedFlag
      ? getPaywalledImgData(imageMobilePaywallData?.errorObj)
      : getImageMeta(banner?.relationships?.field_hero_banner_mobile_image)

  return {
   
    desktopImage: {
      src: imageUrl ?? "",
      alt: alt ?? "",
      width: 1440,
      height: 540,
      styledimage: imageStyledUrl ?? null,
    },
    mobileImage: {
      src: mobileImageUrl ?? "",
      alt: mobileImageAlt ?? "",
      width: 390,
      height: 240,
      styledimage:mobileImageStyledUrl
    },
  }
}

const getRotatingHeroBannerData = async (url: string, ...args: any) => {
  const serverData = args?.[3] // accepting serverData only
  const preFetchedData = args?.[2] 

  if (!url) {
    throw consoleError(`URL parameter is undefined in ${getRotatingHeroBannerData.name}.`)
  }
  const hasQueryParams = url.includes("?")

  let rotatingHeroBannerData =
    preFetchedData && Object.keys(preFetchedData)?.length > 0
      ? preFetchedData
      : await getBlockData(`${url}${hasQueryParams ? "&" : "?"}include=field_hero_banner`, false, serverData)

  const isParentPaywalled = isPaywallProtected(
    getNestedObject(rotatingHeroBannerData, "data.attributes.enable_paywall_block_level"),
  )?.isPaywallProtectedFlag
  const fieldPaywallRoles = getAllRoles(rotatingHeroBannerData)
  const duration = Number(
    getNestedObject(rotatingHeroBannerData, "data.attributes.field_duration.value") ?? NUMBERS.ZERO,
  )

  const slides = rotatingHeroBannerData?.included ?? []
  const slidesInfo = await Promise.all(
    slides.map(async (slide: any) => {
      const isChildPaywalled = isPaywallProtected(
        getNestedObject(slide, "attributes.enable_paywall_block_level"),
      )?.isPaywallProtectedFlag

      if (isChildPaywalled) {
        return null
      }
      const heroBannerImage = await getHeroBannerImageContent(slide)
      return {
        heroBannerContent: getHeroBannerContent(slide),
        eventBannerContent: null,
        heroBannerImage,
        uploadType: getNestedObject(slide, "attributes.field_hero_banner_upload_type") ?? null,
        referenceField: getNestedObject(slide, "attributes.field_reference_field.value") ?? null,
        isImageClickable: getNestedObject(slide, "attributes.field_image_clickable") ?? null,
      }
    }),
  )
  return {
    duration,
    slidesInfo: filterNullValues(slidesInfo),
    fieldPaywallRoles,
    isParentPaywalled,
  }
}

export default getRotatingHeroBannerData