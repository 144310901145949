import Icon from "@atoms/icon"
import { IModuleInfoIconCard } from "./_moduleInfoIconCard.interface"
import { StarsRating } from "@molecules"
import { useState } from "react"

const ModuleInfoIconCard = ({ title, iconName, description, avgRating }: IModuleInfoIconCard) => {
  const [showLoginPopUp, setShowLoginPopUp] = useState<boolean>(false)

  const renderDescription = (desc: any) => {
    return desc
  }

  return (
    <div className="card moduleInfoIconCard">
      {iconName && <Icon iconName={iconName} className="moduleInfoIconCard-icon" />}
      <div className="card-body">
        <h3 className="bold moduleInfoIconCard-title">{title}</h3>
        {description && (
          <h4 className="bold moduleInfoIconCard-desc">{renderDescription(description)}</h4>
        )}
        {avgRating && (
          <div className="moduleInfoIconCard-icondesc">
            <StarsRating
              avgRating={avgRating}
              setShowLoginPopUp={() => setShowLoginPopUp(true)}
              title=""
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ModuleInfoIconCard
