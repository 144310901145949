import { RichText, Taxonomy } from "@atoms"
import { ITextBlockProps } from "./_textBlock.interface"

const TextBlock = (props: ITextBlockProps) => {
  const {
    alignment,
    paragraph,
    className,
    subTitle,
    taxonomies,
    title,
    id,
    finePrint,
    characterLimit,
  } = props

  const getTextAlignment = () => {
    if (alignment === "left") {
      return "left-align"
    }
    if (alignment === "center") {
      return "center-align"
    }
    if (alignment === "right") {
      return "right-align"
    }
    return "center-align"
  }

  const getClasses = () => {
    const classes = []
    classes.push("text-block")
    classes.push(getTextAlignment())
    if (finePrint) {
      classes.push("fine-print")
    }
    if (className) {
      classes.push(className)
    }
    return classes.join(" ")
  }

  return (
    <div className={getClasses()} id={id}>
      {subTitle && <p className="text-block-subTitle">{subTitle}</p>}
      {taxonomies && <Taxonomy itemsToDisplay={2} taxonomies={taxonomies}/>}
      {title && <h2>{title}</h2>}
      {paragraph && <RichText content={paragraph} characterLimit={characterLimit} />}
    </div>
  )
}

export default TextBlock
